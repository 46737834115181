import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CduiBaseService } from '@app/auth/cdui-base.service';

@Injectable({
  providedIn: 'root',
})
export class NewSolutionEndpointService extends CduiBaseService {
  constructor(private injector: Injector, private http: HttpClient) {
    super(injector);
  }

  /**
   * Gets calendar events
   * @param payload
   * @returns
   */
  getCalendarEvents(payload: any, isForDropdown: boolean) {
    /* istanbul ignore next */
    const url = isForDropdown
      ? `${this.api?.txnSearch}?configEntityName=&entityNameMapping=`
      : `${this.api?.txnSearch}?configEntityName=${payload?.outputEntityName}_Configuration&entityNameMapping=${payload?.outputEntityName}`;
    return this.http.post(url, payload);
  }

  //Scheduler API
  scheduleCalendarEvent(payload: any) {
    const url = `${this.api?.calendarScheduler}`;
    return this.http.post(url, payload);
  }

  updateCalendarEvent(payload: any) {
    const url = `${this.api?.calendarScheduler}`;
    return this.http.put(url, payload);
  }

  deleteCalendarEvent(payload: any) {
    const url = `${this.api?.calendarScheduler}`;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: payload,
    };
    return this.http.delete(url, options);
  }

  getCalendarAvailability(payload: any) {
    const url = `${this.api?.calendarSlots}?configEntityName=${payload?.outputEntityName}_Configuration&entityNameMapping=${payload?.outputEntityName}`;
    return this.http.post(url, payload);
  }

  // saveCU(cu: any) {
  //   return this.http.post(environment.tfCoreUrl + '/CU', cu);
  // }

  // getAllCus(pagenum: number, searchCriteria: string) {
  //   return this.http.get(
  //     environment.tfCoreUrl + '/CU/matching?pageNo=' + pagenum + '&pageSize=10&searchCriteria=' + searchCriteria
  //   );
  // }

  // saveGsi(body: any) {
  //   return this.http.post(environment.tfCoreUrl + '/GSI', body);
  // }
}

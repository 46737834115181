import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LibraryFacadeService } from '@app/create/shared/services/library/library-facade.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-dynamic-confirmation-dialog',
  templateUrl: './dynamic-confirmation-dialog.component.html',
  styleUrls: ['./dynamic-confirmation-dialog.component.scss'],
})
export class DynamicConfirmationDialogComponent implements OnInit, OnDestroy {
  title: string;
  message: string;
  buttonName1: any;
  buttonName2: string;
  typeOfDialog: string;
  ngUnsubscribe = new Subject();
  leftTimetoAutoLogout = '';

  constructor(
    private libraryFacadeService: LibraryFacadeService,
    public dialogRef: MatDialogRef<DynamicConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel
  ) {
    this.libraryFacadeService.sessionTimeLeft$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore next */
      if (this.typeOfDialog === 'SessionTimeOut') {
        this.leftTimetoAutoLogout = `${res} Sec`;
      }
    });

    // Update view with given values
    this.title = data.title;
    this.message = data.message;
    /* istanbul ignore next */
    this.buttonName1 = data.buttonName1 === undefined ? 'No' : data.buttonName1;
    /* istanbul ignore next */
    this.buttonName2 = data.buttonName2 === undefined ? 'Yes' : data.buttonName2;
    /* istanbul ignore next */
    this.typeOfDialog = data.typeOfDialog === undefined ? '' : data.typeOfDialog;
  }

  ngOnInit(): void {}

  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }
  ngOnDestroy() {
    this.leftTimetoAutoLogout = '';
    this.typeOfDialog = '';
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
export interface ConfirmDialogModel {
  title: string;
  message: string;
  buttonName1: string;
  buttonName2: string;
  typeOfDialog?: string;
}

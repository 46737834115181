import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CduiBaseService } from '@app/auth/cdui-base.service';
@Injectable({
  providedIn: 'root',
})
export class ChatbotEndpointService extends CduiBaseService {
  constructor(public injector: Injector, private http: HttpClient) {
    super(injector);
  }

  sendFeedback(body: any) {
    console.log('endpoint');

    return this.http.post(this.api?.chatBotFeedBack, body);
  }

  getGreetMessage() {
    return this.http.get(this.api?.chatBotGreetMessage);
  }

  getChatBotAnswers(body: any) {
    return this.http.post(this.api?.chatBot, body);
  }

  sendVoiceInput(body: any) {
    return this.http.post(this.api?.chatBotVoiceInput, body);
  }
}

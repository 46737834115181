import { entityMapper } from '../entity/entity-mapper';

let transform = require('node-json-transform').transform;

export function solutionLogicMapper(data: any) {
  var map = {
    /*
      node json mapper default item
      used to map one key to another
    */
    item: {
      name: 'name',
      id: 'dsdId',
      tfId: 'id',
      agents: 'agents',
      actionLabel: 'actionLabel',
      layers: 'layers',
      gsiList: 'gsiList',
      membershipList: 'membershipList',
      isReserved: 'isReserved',
      status: 'status',
      designTimeRights: 'designTimeRights',
      txnTimeRights: 'txnTimeRights',
      displayName: 'displayName',
      description: 'description',
      ontology: 'ontology',
      keywords: 'keywords',
      attachments: 'attachments',
      nextTriggerSet: 'nextTriggerSet',
      referencedChangeUnit: 'dsdReferencedChangeUnit',
      tfReferencedChangeUnit: 'referencedChangeUnit',
      masterId: 'masterId',
      reservedCUType: 'reservedCUType',
      index: 'index',
      mindCUList: 'mindCUList',
      exceptionCUList: 'exceptionCUList',
      cuSystemProperties: 'cuSystemProperties',
      source: 'source',
      eventCUList: 'eventCUList',
      constraint: 'constraint',
      tCUConditionalPotentiality: 'tCUConditionalPotentiality',
      tCUConditionalPotentialityNames: 'tCUConditionalPotentialityNames',
      constraintName: 'constraintName',
      slotItemProperties: 'slotItemProperties',
      specialFeatureProperties: 'specialFeatureProperties',
      dcd: 'dcd',
      isDld: 'isDld',
      cuType: 'cuType',
      condition: 'condition',
      conditionName: 'conditionName',
      ownerId: 'ownerId',
      uiProperties: 'uiProperties',
      estimatedTime: 'estimatedTime',
      pathwaysCountFromCurrentCU: 'pathwaysCountFromCurrentCU',
      sentenceTags: 'tCUsentenceName',
      solutionLogic: 'solutionLogic',
      mileStoneId: 'mileStoneId',
      contextualId: 'contextualId',
      tCUShadesOfPotentiality: 'tCUShadesOfPotentiality',
      version: 'version',
      isParallel: 'isParallel',
      attributeMapping: 'attributeMapping',
      nestedOutgoingDCDs: 'nestedOutgoingDCDs',
    },
    operate: [
      {
        run: function (val: any) {
          /*istanbul ignore next*/
          if (val?.length > 0) {
            /*istanbul ignore next*/
            return val?.map((y: any) => {
              return {
                nextCUName: y.nextCUName,
                index: y.index,
                nextCUId: y.dsdNextCUId,
                isParallel: y?.isParallel,
              };
            });
          } else {
            return [];
          }
        },
        on: 'nextTriggerSet',
      },
      {
        run: function (val: any) {
          return transform(val, nestedMap);
        },
        on: 'layers',
      },
      {
        run: (val: any) => {
          if (data?.isDld) {
            /* istanbul ignore next */
            return val?.map((x: any) => {
              return {
                event: x.event,
                formulaName: x.formula,
                sourceContextualName: x.sourceContextualId,
                targetContextualName: x.targetContextualId,
              };
            });
          } else {
            return val;
          }
        },

        on: 'dcd',
      },
      {
        run: (val: any) => {
          if (val !== undefined && Object.keys(val).length) {
            return val;
          } else {
            return {};
          }
        },
        on: 'uiProperties',
      },
      {
        run: (val: any[]): any => {
          return val?.map((x: any) => {
            return solutionLogicMapper({ ...x, isDld: true });
          });
        },
        on: 'solutionLogic',
      },
      {
        run: (val: any) => {
          if (val !== undefined && typeof val === 'string') {
            return JSON.parse(val);
          } else {
            if (val === undefined && data.querySentence !== undefined && typeof data.querySentence === 'string') {
              return JSON.parse(data.querySentence);
            }
            return val;
          }
        },
        on: 'sentenceTags',
      },
    ],

    // operate: [
    //   {
    //   run: function(val : any){

    //     if(val?.length>0){
    //       val?.map((x : any) => { return {...x }})
    //     }else{
    //       return [];
    //     }
    //    },
    //   on: 'nextTriggerSet'
    // }]
  };

  const nestedMap = {
    item: {
      label: 'label',
      participatingItems: 'participatingItems',
      type: 'type',
    },
    operate: [
      {
        run: function (val: any) {
          return val?.map((x: any) => {
            if (data?.isDld) {
              return entityMapper(x);
            } else {
              return {
                ...entityMapper(x?.item?.DATA),
                participatingItemId: x?.id,
                isMultiValue: x.isMultiValue,
                changeDriverMetaData: x.changeDriverMetaData,
              };
            }
          });
        },
        on: 'participatingItems',
      },
    ],
  };
  return transform(data, map);
}

//import { NgxMatDatetimePickerModule } from '@angular-material-components/datetime-picker';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//import { ApplicationPipesModule } from '@app/@shared/pipes/application-pipes.module';
import { MaterialModule } from '@app/material-module';
import { PaginatorComponent } from './paginator.component';

@NgModule({
  declarations: [PaginatorComponent],
  imports: [MaterialModule, CommonModule, FormsModule, ReactiveFormsModule],
  exports: [PaginatorComponent],
})
export class PaginatorModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QrcodeScannerModule } from '@app/@shared/components/qrcode-scanner/qrcode-scanner.module';
import { MaterialModule } from '@app/material-module';
import { QrScannerControlComponent } from './qr-scanner-control.component';

@NgModule({
  declarations: [QrScannerControlComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule, QrcodeScannerModule],
  exports: [QrScannerControlComponent],
})
export class QrScannerControlModule {
  static entry = {
    qrscanner: QrScannerControlComponent,
  };
}

import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Entity } from '@app/@shared/models/entity';
import { pluck } from 'rxjs/operators';
import { Librarytypes } from '@app/@shared/enums/library-types.enum';
import { CduiBaseService } from '@app/auth/cdui-base.service';

@Injectable({
  providedIn: 'root',
})
export class EntityEndpointService extends CduiBaseService {
  constructor(private http: HttpClient, private injector: Injector) {
    super(injector);
  }

  saveEntity(body: Entity) {
    return this.http.post(this.environment.dsdOrchUrl + '/dsd-bets-store/tenant/entity', body);
  }

  getValidations() {
    return this.http.get(this.api?.attrTypes).pipe(pluck('result'));
  }
  getAllEntities(pagenum: number, pageSize: number, searchCriteria: string, isPublished: boolean, layerType?: any) {
    const ontology = '';
    const publisherId = '';
    if (layerType === Librarytypes.ReservedActions) {
      return this.http
        .get(this.api?.reservedEntities + `?query=${searchCriteria}&page=${pagenum}&limit=${pageSize}`)
        .pipe(pluck('result'));
    } else {
      return this.http
        .get(
          this.api?.getAllEntities +
            `?query=${searchCriteria}&publisherIdOrName=${publisherId}&ontology=${ontology}&page=${pagenum}&limit=${pageSize}&isPublished=${isPublished}`
        )
        .pipe(pluck('result'));
    }
  }

  getExactEntityByName(searchCriteria: string, layerType?: any) {
    const ontology = '';
    const publisherId = '';
    if (layerType === Librarytypes.ReservedActions) {
      return this.http.get(this.api?.reservedEntities).pipe(pluck('result'));
    } else {
      return this.http.get(this.api?.getExactEntityByName + `${searchCriteria}`).pipe(pluck('result'));
    }
  }

  getAllEntitiesCount(pagenum: number, pageSize: number, searchCriteria: string) {
    return this.http
      .get(this.api?.entityCount + '?pageNo=' + pagenum + '&pageSize=' + pageSize + '&searchCriteria=' + searchCriteria)
      .pipe(pluck('result', 'total'));
  }

  loadAttributesByEntId(id: any, isReserved?: boolean, reservedActions?: any) {
    if (reservedActions) {
      return this.http.get<any>(this.api?.getAttributeByEntityIdForReservedActions + `${id}`);
    } else {
      isReserved = isReserved ? isReserved : false;
      return this.http.get<any>(this.api?.getAttributeByEntityId + `/${id}?isReserved=${isReserved}`);
    }
  }

  getCloneDetails(id: number, isPublished: boolean, type: any, data: any) {
    if (type === 'gsi') {
      return this.http.post(this.api?.betsStoreTenant + `${type}/clone/${id}`, { name: data.name });
    } else {
      return this.http.post(this.api?.betsStoreTenant + `${type}/clone/${id}?isPublished=${isPublished}`, data);
    }
  }
  //Elastic search - FE757
  // ------------------------------ START -------------------------

  // Fetch contextual search data for typeahead attribute while creating soln based on Gsi Id
  getContextualSearchData(gsiId: number) {
    return this.http.get<any>(this.api?.saveTypeAheadSearchConfiguration + `?gsiId=${gsiId}`);
  }

  // Save contextual search data for typeahead attribute while creating soln
  postContextualSearchData(data: any) {
    return this.http.post<any>(this.api?.saveTypeAheadSearchConfiguration + '/save', data);
  }

  // ------------------------------ END -------------------------
}

import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

/**
 * Prefixes all requests not starting with `http[s]` with `environment.serverUrl`.
 */
@Injectable({
  providedIn: 'root',
})
export class ApiPrefixInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    /* istanbul ignore next */
    let lang: any = localStorage.locale ? localStorage.locale : 'en';
    /* istanbul ignore next */
    lang =
      request.url.includes('/dsd-orch/translator/language') ||
      request.url.includes('/dsd-orch/translator/tags/Global/') ||
      request.url.includes('/dsd-orch/nsl-iam/api/user/update/user') ||
      request.url.includes('/dsd-orch/nsl-iam/api/user/getActiveUser')
        ? 'en'
        : lang;
    request = request.clone({
      setHeaders: {
        'Accept-Language': lang,
      },
    });
    return next.handle(request);
  }
}

import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CduiBaseService } from '@app/auth/cdui-base.service';

@Injectable({
  providedIn: 'root',
})
export class EventsEndpointService extends CduiBaseService {
  constructor(public injector: Injector, private http: HttpClient) {
    super(injector);
  }

  setChangeUnitEvents(data: any, cuId: any) {
    return this.http.post(this.api?.setChangeUnitEvents + cuId, data);
  }

  getDependentDropdownDetails(payload: any, eventType: string, changeDriver: any) {
    return this.http.post(
      this.api?.getDependentDropdownDetails + `changeDriverId=${changeDriver}&eventType=${eventType}`,
      payload
    );
  }

  // Elastic Search - FE757
  // Fetch values for typeahead attribute at transaction side
  fetchSearchData(data: any) {
    return this.http.post(this.api?.fetchTypeAheadAttributeValues, data);
  }
}

import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpClient } from '@angular/common/http';
import { Observable, empty, throwError } from 'rxjs';
import { exhaustMap, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AlertService } from '../toaster/alert.service';
import { LoaderService } from '@app/@shared/services/loader/loader.service';
import { ReauthenticationService } from '../services/reauthentication.service';
import { timeDiff_CalculateInMinutes } from '../generic-functions';

import { GsiFacadeService } from '@app/create/shared/services/gsi/gsi-facade.service';
import { ErrorHandlerServiceService } from '@app/@core/http/error-handler-service.service';
import { NodeGsiFacadeService } from '@app/node-system-canvas/services/node-gsi-facade.service';
import { SelfOnboardingSignupComponent } from '@app/auth/self-onboarding-signup/self-onboarding-signup.component';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  tenantName: any;
  bookId: any;
  book: any;
  private selfOnboardSignUp: SelfOnboardingSignupComponent;

  constructor(
    private router: Router,
    private alertService: AlertService,
    private reAuthenticationService: ReauthenticationService,
    private gsiFacadeService: GsiFacadeService,
    private loader: LoaderService,
    private http: HttpClient,
    private errorHandler: ErrorHandlerServiceService,
    private nodeGsiFacadeService: NodeGsiFacadeService
  ) {
    this.tenantName = localStorage.getItem('TenantName');
    this.tokenExpiryCheckObservable();
  }

  IsJsonString(str: any) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    /* istanbul ignore next */
    if (
      request?.url.endsWith('.well-known/openid-configuration') ||
      request?.url.endsWith('/GSI-News') ||
      request?.url.includes('/Entities-News') ||
      request?.url.includes('/CU-News?GsiId=') ||
      request?.url.includes('/Attribute-News') ||
      request?.url.includes('/agents') ||
      request?.url.includes('www.googleapis.com/customsearch')
    ) {
    } /* istanbul ignore if */ else if (
      // request?.url.indexOf('/openid-connect/token') < 0 &&
      localStorage.getItem('credentials')
    ) {
      let credentials;
      let onboardCredentials;
      /* istanbul ignore next */
      if (this.IsJsonString(localStorage.getItem('credentials'))) {
        credentials = JSON.parse(localStorage.getItem('credentials'));
      }
      /* istanbul ignore next */
      if (this.IsJsonString(localStorage.getItem('onboardCredentials'))) {
        onboardCredentials = JSON.parse(localStorage.getItem('onboardCredentials'));
      }
      const isOnboardTraining = localStorage.getItem('isOnboardTraining');

      /** Making changes to Header to update and send BookID as part of HTTP headers
       * if the tenant is B2C and send normal headers if it's Other tenants starts
       */
      let token = isOnboardTraining
        ? credentials?.token
        : onboardCredentials?.token
        ? onboardCredentials?.token
        : credentials?.token;
      var headerObj: any = {
        setHeaders: {
          Authorization: `Bearer ` + token,
          // 'Content-Type': 'application/json',
        },
      };
      const bookId = this.gsiFacadeService?.bookIDb2c?.toString();
      const bookIdNode = this.nodeGsiFacadeService?.bookIDb2c?.toString();
      /* fetching tenantName in constructor somehow was fetching prev tenant 
      though localstorage set b2c */
      this.tenantName = localStorage.getItem('TenantName');

      if (this.tenantName === 'b2c' || this.tenantName === 'b2cdev') {
        if (sessionStorage.bookData && sessionStorage.bookData !== '') {
          this.book = JSON.parse(sessionStorage.bookData);
        }
        if (bookId || (this.book && this.book?.id)) {
          headerObj.setHeaders.bookId = bookId ? bookId : this.book.id.toString();
        }
      }
      if (this.tenantName.includes('--')) {
        if (location.pathname.includes('/transaction/view')) {
          if (sessionStorage.bookData && sessionStorage.bookData !== '') {
            this.book = JSON.parse(sessionStorage.bookData);
          }
          if (bookId || (this.book && this.book?.id)) {
            headerObj.setHeaders.bookId = bookId ? bookId : this.book.id.toString();
          }
        }
      }
      if (
        (this.tenantName === 'b2c' || this.tenantName === 'b2cdev') &&
        bookIdNode &&
        location.pathname !== '/cdui/mylibrary-dashboard'
      ) {
        headerObj.setHeaders.bookId = bookIdNode;
      }
      request = request?.clone(headerObj);

      /** Making changes to Header to update and send BookID as part of HTTP headers
       * if the tenant is B2C and send normal headers if it's Other tenants ends
       */
    }
    let _this = this;
    /*istanbul ignore next */
    this.nodeGsiFacadeService?.setTotalErrorsCount(0);
    this.errorHandler.errorMesssages.next({ isSuccessful: true });
    /* istanbul ignore next */
    return next?.handle(request).pipe(catchError((error) => this.handleError(error, _this)));
  }
  public handleError(errors: any, scope: any) {
    /* istanbul ignore else */
    const tenantName = localStorage.getItem('TenantName');
    /* istanbul ignore next */
    if (errors && errors.status === 401 && tenantName != 'b2c') {
      let credentials;
      /* istanbul ignore next */
      if (this.IsJsonString(localStorage.getItem('credentials'))) {
        credentials = JSON.parse(localStorage.getItem('credentials'));
      }
      /* istanbul ignore else */
      if (credentials) {
        /* istanbul ignore next */
        scope?.loader.show();
        /* istanbul ignore next */
        scope?.reAuthenticationService?.reLogin(credentials, true).subscribe(
          (res: any) => {
            scope?.loader.hide();
          },
          (error: any) => {
            scope?.loader.hide();
          }
        );
      } else {
        /* istanbul ignore next */
        this.alertService?.showToaster('Unauthorized', 'Invalid Login', 'error');

        const carnivalPersona = localStorage.getItem('carnivalPersona');
        sessionStorage.clear();
        localStorage.clear();
        /* istanbul ignore next */
        this.loader?.hide();
        // if carnival is on, redirect to carnival login
        /* istanbul ignore if */
        if (carnivalPersona) {
          this.router.navigate([`/carnival/category/${carnivalPersona}/login`]);
          localStorage.setItem('carnivalPersona', carnivalPersona);
        } else {
          this.router.navigate(['/']);
        }
      }
    } else {
      /* istanbul ignore next */
      if (errors.error?.result) {
        /* istanbul ignore next */
        this.errorHandler.errorMesssages.next(errors?.error?.result);
      } else if (errors.error?.output) {
        /* istanbul ignore next */
        this.errorHandler.errorMesssages.next(errors?.error?.output[0]?.result);
      } else if (errors && errors.error) {
        /* istanbul ignore next */
        if (!this.selfOnboardSignUp?.errorToaster) {
          /* istanbul ignore next */
          scope?.alertService.showToaster(`${errors?.error?.message}`, '', 'error');
        } else {
          this.selfOnboardSignUp.errorToaster = false;
        }
      }
      /* istanbul ignore next */
      scope?.loader?.hide();
    }
    return throwError(errors);
  }

  tokenExpiryCheckObservable(): any {
    /* istanbul ignore next */
    setInterval(() => {
      empty().pipe(exhaustMap(this.tokenExpiryCheck()));
    }, 10000);
    /* istanbul ignore next */
    setInterval(() => {
      empty().pipe(exhaustMap(this.tokenExpiryCheckOnboard()));
    }, 10000);
  }

  tokenExpiryCheck(): any {
    const isSocialLogin = localStorage.getItem('isSocialLogin');
    /* istanbul ignore next */
    if (!isSocialLogin) {
      const prefix_Time = 2;
      let loginDetails;
      /* istanbul ignore next */
      if (this.IsJsonString(localStorage.getItem('credentials'))) {
        loginDetails = JSON.parse(localStorage.getItem('credentials'));
      }
      if (localStorage.length > 0) {
        const lastLoginTime = loginDetails?.last_loginTime !== undefined && loginDetails?.last_loginTime;
        const tokenExpiryTime = loginDetails?.expires_in !== undefined && parseInt(loginDetails?.expires_in) / 60;
        let credentials;
        /* istanbul ignore next */
        if (this.IsJsonString(localStorage.getItem('credentials'))) {
          credentials = JSON.parse(localStorage.getItem('credentials'));
        }
        if (lastLoginTime !== null && tokenExpiryTime !== null && credentials !== null) {
          const diffTime = timeDiff_CalculateInMinutes(new Date(), new Date(lastLoginTime));
          if (diffTime >= tokenExpiryTime - prefix_Time) {
            this.reAuthenticationService?.reLogin(credentials, true).subscribe((res) => {});
          }
        }
      }
    }
  }

  tokenExpiryCheckOnboard(): any {
    const isSocialLogin = localStorage.getItem('isSocialLogin');
    /* istanbul ignore next */
    if (!isSocialLogin) {
      const prefixTime = 2;
      let loginDetails;
      /* istanbul ignore next */
      if (this.IsJsonString(localStorage.getItem('onboardCredentials'))) {
        loginDetails = JSON.parse(localStorage.getItem('onboardCredentials'));
      }
      if (localStorage.length > 0 && loginDetails) {
        const lastLoginTime = loginDetails?.last_loginTime !== undefined && loginDetails?.last_loginTime;
        const tokenExpiryTime = loginDetails?.expires_in !== undefined && parseInt(loginDetails?.expires_in) / 60;
        let onboardCredentials;
        /* istanbul ignore next */
        if (this.IsJsonString(localStorage.getItem('onboardCredentials'))) {
          onboardCredentials = JSON.parse(localStorage.getItem('onboardCredentials'));
        }
        if (lastLoginTime !== null && tokenExpiryTime !== null && onboardCredentials !== null) {
          const diffTime = timeDiff_CalculateInMinutes(new Date(), new Date(lastLoginTime));
          if (diffTime >= tokenExpiryTime - prefixTime) {
            this.reAuthenticationService?.reLogin(onboardCredentials, true, true).subscribe((res) => {});
          }
        }
      }
    }
  }
}

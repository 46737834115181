import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CduiBaseService } from '@app/auth/cdui-base.service';
@Injectable({
  providedIn: 'root',
})
export class RolesEndpointService extends CduiBaseService {
  constructor(private injector: Injector, private http: HttpClient) {
    super(injector);
  }

  getAllRoles() {
    return this.http.get(this.api?.getAllRoles);
  }
  getPaginateRoles(pageNumber: number, pageSize: number, searchString: string, isActive?: boolean) {
    const payload = {
      isActive: isActive ? isActive : 'all',
      roleName: searchString || '',
    };
    return this.http.post(this.api?.getPaginateRoles + `pageNumber=${pageNumber}&pageSize=${pageSize}`, payload);
  }

  // All MCC Roles

  getMccPaginateRoles(pageNumber: number, pageSize: number, searchString: string, isActive?: boolean) {
    const payload = {
      isActive: isActive ? isActive : 'all',
      roleName: searchString || '',
    };
    return this.http.get(
      this.api?.mccRoles + `config/all?pageNumber=${pageNumber}&pageSize=${pageSize}&roleName=${searchString}`
    );
  }

  // All MCC Roles

  getMccConfigRoles(configData: any) {
    return this.http.post(this.api?.mccRoles + `config`, configData);
  }

  //All b2c roles
  getAllb2cRoles(pageNumber: number, pageSize: number, searchString: string, isActive?: boolean) {
    const payload = {
      isActive: isActive ? isActive : 'all',
      roleName: searchString || '',
    };
    return this.http.post(this.api?.getB2cRoles + `?pageNumber=${pageNumber}&pageSize=${pageSize}`, payload);
  }
  //All books
  getPaginateBooks(pageNumber: number, pageSize: number, searchString: string) {
    return this.http.get(
      this.api?.getPaginateBooks + `?pageNo=${pageNumber}&pageSize=${pageSize}&searchCriteria=${searchString}`
    );
  }
  // all roles realted to book
  getRolesforBook(bookId: any, pageIndex: number, pageSize: number, searchString: string, isActive?: boolean) {
    const payload = {
      isActive: isActive ? isActive : 'all',
      roleName: searchString || '',
    };
    return this.http.post(
      this.api?.getRolesforBook + `?bookId=${bookId}&pageNumber=${pageIndex}&pageSize=${pageSize}`,
      payload
    );
  }
  // all roles realted to book
  getRolesAttachedToBook(bookId: any) {
    return this.http.get(this.api?.getRolesAttachedToBook + `?bookId=${bookId}`);
  }
  //all books realted to role
  getBooksforRoleb2c(roleId: any) {
    return this.http.get(this.api?.getRoleWithBooks + `?roleId=${roleId}`);
  }
  // create b2c role
  createRoleb2c(postData: any, bookList: any) {
    let allbooks: string = '';
    let bklist = 'bklist=';
    let lastIndex = bookList.length;
    let currentIndex = 0;
    for (let ele of bookList) {
      allbooks += bklist;
      allbooks += ele;
      currentIndex += 1;
      if (currentIndex != lastIndex) {
        allbooks += '&';
      }
    }
    if (bookList.length > 0) {
      return this.http.post(this.api?.createRoleb2c + `?${allbooks}`, postData);
    } else {
      return this.http.post(this.api?.createRoleb2c + `?${bklist}`, postData);
    }
  }
  createRole(postData: any) {
    return this.http.post(this.api?.createRole, postData);
  }
  updateRole(updateData: any) {
    return this.http.post(this.api?.updateRole, updateData);
  }
  // delete b2c role
  deleteRoleb2c(roleId: number) {
    return this.http.delete(`${this.api?.deleteRoleb2c}/${roleId}`);
  }
  deleteRole(roleId: number) {
    return this.http.delete(`${this.api?.deleteRole}${roleId}`);
  }

  importRoleFromCSV(postData: any) {
    return this.http.post(this.api?.importRoleFromCSV, postData);
  }

  getRolesCount() {
    return this.http.get(this.api?.getRolesCount);
  }
  unAssignUserToRole(roleId: number, userId: number) {
    return this.http.post(this.api?.unAssignUserToRole + `${roleId}/${userId}`, {});
  }
  unAssignGroupToRole(roleId: number, groupId: number) {
    return this.http.post(this.api?.unAssignGroupToRole + `${roleId}/${groupId}`, {});
  }
  assignUserToRole(roleId: any, postData: any) {
    return this.http.post(this.api?.assignUserToRole, {
      roleId: roleId,
      userIds: postData,
    });
  }
  assignGroupToRole(roleId: any, postData: any) {
    return this.http.post(this.api?.assignGroupToRole, {
      roleId: roleId,
      groupIds: postData,
    });
  }

  // manage roles by bookId
  getAllRolesByBookId(pageNumber: number, pageSize: number, searchString: string, bookid: number) {
    return this.http.get(
      this.api?.getAllRolesByBookId +
        `bookId=${bookid}&pageNo=${pageNumber}&pageSize=${pageSize}&searchCriteria=${searchString}`
    );
  }

  getAllRolesBySolutionId(pageNumber: number, pageSize: number, searchString: string, solutionId: number) {
    return this.http.get(
      this.api?.getAllRolesBySolutionId +
        `gsiId=${solutionId}&pageNo=${pageNumber}&pageSize=${pageSize}&searchCriteria=${searchString}`
    );
  }

  getInformationLayer(roleId: number) {
    return this.http.get(this.api?.getInformationLayer);
  }
  getDetailsByTargetRoleId(fromRoleId: number, toRoleId: number) {
    return this.http.get(this.api?.getDetailsByTargetRoleId + `${fromRoleId}/${toRoleId}`);
  }
  getEntitiesForDelegation(bookId: number, pageNumber: number, pageSize: number, searchString: string) {
    return this.http.get(
      this.api?.getEntitiesForDelegation +
        `bookId=${bookId}&pageNo=${pageNumber}&pageSize=${pageSize}&searchCriteria=${searchString}`
    );
  }
  getAttributesForDelegation(entityName: string, pageNumber: number, pageSize: number, searchString: string) {
    return this.http.get(
      this.api?.getAttributesForDelegation +
        `entityName=${entityName}&pageNo=${pageNumber}&pageSize=${pageSize}&searchCriteria=${searchString}`
    );
  }
  createDelegation(postData: any) {
    return this.http.post(this.api?.createDelegation, postData);
  }

  loadRoleDelegations(pageNumber: number, pageSize: number, searchString: string, roleId: any) {
    return this.http.get(
      this.api?.loadRoleDelegations +
        `pageNo=${pageNumber}&pageSize=${pageSize}&searchCriteria=${searchString}&roleId=${roleId}`
    );
  }

  deleteDelegation(delegationId: any) {
    return this.http.delete(this.api?.deleteDelegation + `id=${delegationId}`);
  }

  getDelegationById(delegationId: any) {
    return this.http.get(this.api?.getDelegationById + `id=${delegationId}`);
  }

  getAllUnassignedLDAPGroupsForRoles(pageNumber: number, pageSize: number, searchString: string, roleId: any) {
    return this.http.get(
      this.api?.getAllUnassignedLDAPGroupsForRoles +
        `pageNo=${pageNumber}&pageSize=${pageSize}&searchCriteria=${searchString}&roleId=${roleId}`
    );
  }

  getAllAssignedLDAPGroupsForRoles(pageNumber: number, pageSize: number, searchString: string, roleId: any) {
    return this.http.get(
      this.api?.getAllAssignedLDAPGroupsForRoles +
        `pageNo=${pageNumber}&pageSize=${pageSize}&searchCriteria=${searchString}&roleId=${roleId}`
    );
  }

  unassignLdapGroupForRoles(roleId: number, groupId: number) {
    return this.http.get(this.api?.unassignLdapGroupForRoles + `ldapGroupId=${groupId}&roleId=${roleId}"`, {});
  }

  assignLdapGroupsForRoles(roleId: any, postData: any) {
    return this.http.put(this.api?.assignLdapGroupsForRoles, {
      roleId: roleId,
      ldapGroupIds: postData,
    });
  }

  /**
   * Gets local roles
   * @param pageNumber
   * @param pageSize
   * @param searchString
   * @param bookId
   * @returns
   */
  getLocalRoles(pageNumber: number, pageSize: number, searchString: string, bookId: any) {
    return this.http.post(this.api?.getLocalRoles + `bookId=${bookId}&pageNumber=${pageNumber}&pageSize=${pageSize}`, {
      isActive: true,
      roleName: searchString,
      bookId: bookId,
    });
  }

  /**
   * Gets all book tree pageable
   * @param pageNumber
   * @param pageSize
   * @param searchString
   * @returns
   */
  getAllBookTreePageable(pageNumber: number, pageSize: number, searchString: string) {
    return this.http.get(
      this.api?.getAllBookTreePageable + `pageNo=${pageNumber}&pageSize=${pageSize}&searchCriteria=${searchString}`
    );
  }

  /**
   * Gets book tree by id
   * @param bookId
   * @returns
   */
  getBookTreeById(bookId: number, roleId: any, searchCriteria: string) {
    if (roleId) {
      return this.http.get(
        this.api?.getBookTreeById + `id=${bookId}&roleId=${roleId}&searchCriteria=${searchCriteria}`
      );
    } else {
      return this.http.get(this.api?.getBookTreeById + `id=${bookId}&searchCriteria=${searchCriteria}`);
    }
  }

  /**
   * Saves book role
   * @param postData
   * @returns
   */
  saveBookRole(postData: any) {
    return this.http.post(this.api?.saveBookRole, postData);
  }
  /**
   * Deletes book role
   * @param roleId
   * @returns
   */
  deleteBookRole(roleId: any) {
    return this.http.delete(this.api?.deleteBookRole + `${roleId}?wishToContinue=false`);
  }

  /**
   * Gets assigned owners pageable
   * @param pageNumber
   * @param pageSize
   * @param searchCriteria
   * @param payload
   * @returns
   */
  getAssignedOwnersPageable(pageNumber: number, pageSize: number, searchCriteria: string, payload: any) {
    return this.http.put(
      this.api?.getAssignedOwnersPageable +
        `pageNo=${pageNumber}&pageSize=${pageSize}&searchCriteria=${searchCriteria}`,
      payload
    );
  }

  /**
   * Assigns book owners
   * @param payload
   * @returns
   */
  assignBookOwners(payload: any) {
    return this.http.post(this.api?.assignBookOwners, payload);
  }
  /**
   * Gets all roles hierarchy
   * @returns
   */
  getAllRolesHierarchy() {
    return this.http.get(this.api?.getAllRolesHierarchy);
  }

  /**
   * Gets book role tree
   * @param bookId
   * @returns
   */
  getBookRoleTree(bookId: any) {
    return this.http.get(this.api?.getBookRoleTree + `${bookId}`);
  }

  /**
   * Gets books for role
   * @param bookId
   * @returns
   */
  getBooksforRole(bookId: any) {
    return this.http.get(this.api?.getRoleWithBooks + `${bookId}`);
  }

  /**
   * Saves irdr
   * @param postData
   * @returns
   */
  saveIRDR(postData: any) {
    return this.http.post(this.api?.saveIRDR, postData);
  }

  /**
   * Gets irdrfor book or solution
   * @param bookId
   * @param gsiId
   * @returns
   */
  getIRDRForBookOrSolution(bookId: any, gsiId: any) {
    return this.http.get(this.api?.getIRDRForBookOrSolution + `bookId=${bookId}&gsiId=${gsiId}`);
  }

  /**
   * Gets irdrfor role pageable
   * @param pageNumber
   * @param pageSize
   * @param roleId
   * @param searchText
   * @returns
   */
  getIRDRForRolePageable(pageNumber: number, pageSize: number, roleId: any, searchText: string) {
    return this.http.get(
      this.api?.getIRDRForRolePageable +
        `pageNo=${pageNumber}&pageSize=${pageSize}&roleId=${roleId}&searchCriteria=${searchText}`
    );
  }

  /**
   * Gets all owners
   * @param pageNumber
   * @param pageSize
   * @param searchText
   * @param payload
   * @returns
   */
  getAllOwners(pageNumber: number, pageSize: number, searchText: string, payload: any) {
    return this.http.put(
      this.api?.getAllOwners + `pageNo=${pageNumber}&pageSize=${pageSize}&searchCriteria=${searchText}`,
      payload
    );
  }

  /**
   * Gets book tree with IRDR's
   * @param pageNumber
   * @param pageSize
   * @param roleId
   * @param searchText
   * @returns
   */
  getBookTreePageable(pageNumber: number, pageSize: number, roleId: any, searchText: string) {
    return this.http.get(
      this.api?.getBookTreePageable +
        `pageNo=${pageNumber}&pageSize=${pageSize}&roleId=${roleId}&searchCriteria=${searchText}`
    );
  }

  getPublicRoles(tenant: any) {
    return this.http.get(this.api?.getPublicRoles + `tenantId=${tenant}`);
  }
  deleteCustomRole(siteLayoutId: any) {
    return this.http.delete(`${this.api?.deleteCustomLayout}${siteLayoutId}`);
  }
  loadUnassigneSubtenant(id: any) {
    return this.http.get(this.api?.createClient + `book/notreceived/${id}?keyword=`);
  }
  onAssigningSubTenant(payload: any) {
    return this.http.post(this.api?.onAssigningSubTenant, payload);
  }
  mappingRoles(roleId: any, subTenants: any) {
    return this.http.get(this.api?.mappingRoles + `parentRoleId=${roleId}&subtenant=${subTenants}`);
  }
  mapRoleToParent(payload: any) {
    return this.http.post(this.api?.mapRoleToParent, payload);
  }

  getAdminsList() {
    let envType = localStorage.getItem('envType');
    return this.http.get(this.api?.getAdminsList + `/${envType}/envadmins/`);
  }
}

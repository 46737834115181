import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
  Type,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { checkTokenExistsorNot, getEnvClientId } from '@app/@core/generic-functions';
import { AlertService } from '@app/@core/toaster/alert.service';
import { Librarytypes } from '@app/@shared/enums/library-types.enum';
import { LibraryType } from '@app/@shared/models/library-type';
import { ChatbotFacadeService } from '@app/@shared/services/chatbot/chatbot-facade.service';
import { NotificationFacadeService } from '@app/@shared/services/notification/notification-facade.service';
import { SharedFacadeService } from '@app/@shared/shared-facade.service';
import { RolesFacadeService } from '@app/admin/services/roles/roles-facade.service';
import { UsersFacadeService } from '@app/admin/services/users/users-facade.service';
import { AuthenticationService, CredentialsService } from '@app/auth';
import { CarnivalFacadeService } from '@app/carnival/services/carnival/carnival-facade.service';
import { NewSolutionFacadeService } from '@app/create/create-solution-facade.service';
import { LayoutSolutionFacadeService } from '@app/create/layout-solution/layout-solution-facade.service';
import { NotificationsComponent } from '@app/create/shared/components/notifications/notifications.component';
import { SolutionVersioningFacadeService } from '@app/create/shared/services/versioning/solution-versioning-facade.service';
import { KeycloakService } from 'keycloak-angular';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { LibraryFacadeService } from '../../create/shared/services/library/library-facade.service';
import { TenantCustomUiFacadeService } from '../../tenant-cus/services/tenant-custom-ui-facade.service';
import { TranslatorService } from '../translator.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit, OnDestroy {
  toppings = new FormControl();
  navBarwithSearchForDSD: boolean = false;
  currentTab: string = '';
  NodeClick: boolean = false;
  showHomeComponent: boolean = false;
  loginBtnType: boolean = true;
  hideCreateButton: boolean = false;
  showUserName: boolean = false;
  private ngUnsubscribe = new Subject();
  userDetails: string;
  status: boolean = false;
  userInfo: any;
  language: any;
  enablePreferences: boolean = false;
  availableLanguages: any = [];
  defaultLanguage = 'EN';
  tenantExists: boolean;
  // headerDesignData: any;
  headerDesignData: any = {
    logo: { desktop: '../../../assets/images/nhlogo-icon.svg' },
    header: { backgroundColor: { color: '#FFF' }, font: { family: '' } },
  };
  fontUrl: string;
  tenantName: any = getEnvClientId();
  logoPosition: any;
  enableFilterDropdown: boolean = false;
  currentUrl: string;

  selectedGroup: any;
  isBrfLogin: boolean = false;
  staticLabels: any = { tagName: 'Global', labels: {} };
  labels: any;
  _checkTokenExistsorNot: any = false;
  activeSideMenu: string = 'nslLibrary';
  isOpenNavigation: boolean = false;
  carnival: boolean = false;
  onClickHideMenu: boolean = false;
  showNotification: boolean = false;
  isEnvDev: boolean = false;
  isCurrentAcc: string = '';
  name = 'Angular';
  @ViewChild('toggleButton') toggleButton: ElementRef;
  @ViewChild('menu') menu: ElementRef;
  unreadNotifCount: number = 0;
  roles: any[];
  stepType: string = '';

  openDropdown: boolean = false;
  specialfeatureDropdown: boolean = false;
  myLearningDropdown: boolean = false;
  isSubTenant: boolean = false;
  isSaasTenant: boolean = false;

  collaborate: boolean = false;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private credentialsService: CredentialsService,
    private libraryFacadeService: LibraryFacadeService,
    private userFacade: UsersFacadeService,
    public translator: TranslatorService,
    private layoutFacadeService: LayoutSolutionFacadeService,
    private notificationFacadeService: NotificationFacadeService,
    private tenantCustomUiFacadeService: TenantCustomUiFacadeService,
    private sharedFacadeService: SharedFacadeService,
    private readonly keycloak: KeycloakService,
    private chatBotFacadeService: ChatbotFacadeService,
    private newSolutionFacade: NewSolutionFacadeService,
    private alertService: AlertService,
    private rolesFacade: RolesFacadeService,
    private carnivalFacadeService: CarnivalFacadeService,
    private versioningFacade: SolutionVersioningFacadeService
  ) {
    router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((event: NavigationEnd) => {
        this.status = false;
        setTimeout(() => {
          this.detectChangeOfRoute(event.urlAfterRedirects);
        }, 1000);

        /*istanbul ignore next */
        this.currentUrl = event?.urlAfterRedirects;
        this.getLoginAuthentication();
        this.getUserInfo();
        const userData = localStorage.getItem('credentials');
        this.defaultLanguage = localStorage.getItem('locale') || 'EN';
        /*istanbul ignore next */
        if (userData && !this.userDetails) {
          this.userDetails = JSON.parse(userData).username || '';
        }
        if (
          event.urlAfterRedirects == '/mylibrary' ||
          event.urlAfterRedirects == '/cdui/mylibrary-dashboard' ||
          event.urlAfterRedirects.includes('cdui/custom-mylibrary')
        ) {
          this.layoutFacadeService.getLibraryTemplateData();
          this.NodeClick = false;
        }
        if (event.urlAfterRedirects == '/admin' || event.urlAfterRedirects == '/cdui/admin') {
          this.NodeClick = false;
        }
        if (
          event.urlAfterRedirects.substring(0, 9) == '/carnival' ||
          event.urlAfterRedirects.substring(0, 14) == '/cdui/carnival'
        ) {
          this.carnival = true;
        } else {
          this.carnival = false;
        }

        if (event.urlAfterRedirects == '/collaborate' || event.urlAfterRedirects == '/cdui/collaborate') {
          this.collaborate = true;
        } else {
          this.collaborate = false;
        }
      });
    this.getLoginAuthentication();
    this.detectMenuType();
    this.detectTokenExistsorNot();
    this.detectLanguagesList();
    this.detectLanguageChange();
    this.detectActiveUserInfo();
    this.detectUserType();
    this.adminHeaderSettings();
    this.detectTenantUi();
    this.updateUnreadNotificationCount();
    this.isBrfLogin = this.authenticationService.getBrfLoginState();

    this.detectLibraryPanelType();
    this.getLibraryDataCount();
    this.detectNotificationsPageCount();
    this.detectIsBookSubject();
    this.detectClearNotificationComponent();
    this.detectDcdScreenStatus();
    this.detectIsDashboard();
    this.detectIsImport();
    this.detectCarnivalPlatform();
    this.detectLibraryLayoutconfig();
    this.detectStep();
    this.tenantName = localStorage.getItem('TenantName');
  }

  // clickoutside //
  outsideClick(hasClickedOutside: any) {
    console.log(hasClickedOutside);
    /*istanbul ignore next*/
    if (hasClickedOutside == true && this.currentLibrary !== this.libraryEnums.Notifications) {
      this.isOpenNavigation = false;
    }
    this.name = hasClickedOutside ? 'clicked outside' : 'clicked inside';
  }

  detectMenuType() {
    this.carnivalFacadeService.carnivalMenu$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore next */
      this.carnival = res;
    });
  }

  @HostListener('document:click', ['$event'])
  onClickEvent(event: MouseEvent) {
    if (this.isOpenNavigation && !this.onClickHideMenu) {
      this.onClickHideMenu = true;
    } else if (this.isOpenNavigation && this.onClickHideMenu) {
      /*istanbul ignore next*/
      if (!this.showNotification) {
        // this.isOpenNavigation = false;
        this.onClickHideMenu = false;
        /*istanbul ignore next*/
        if (this.currentLibrary != 'nslLibrary') {
          this.currentLibrary = '';
        }
        this.notificationLazyComponent = undefined;
      }
      /*istanbul ignore next*/
      if (this.showNotification) {
        this.onClickHideMenu = false;
      }
      this.showNotification = false;
    }
  }

  accordian(acc: string) {
    this.isCurrentAcc = acc;
    if (acc == 'profile') {
      this.openDropdown = !this.openDropdown;
      if (this.openDropdown) {
        this.getUnreadNotificationCount();
      }
    }
    if (acc == 'splFeature') {
      this.specialfeatureDropdown = !this.specialfeatureDropdown;
    }
    if (acc == 'myLearning') {
      this.myLearningDropdown = !this.myLearningDropdown;
    }
  }

  ngOnInit(): void {
    this.getActivePlan();
    this.getEnvType();
    /*istanbul ignore next*/
    const body = document.querySelector('body');
    /*istanbul ignore next*/
    if (localStorage.getItem('canDesign') === 'true') {
      this.canDesign = true;
    }

    /*istanbul ignore next*/
    const button = document.querySelector('.menu-trigger');
    /*istanbul ignore next*/
    button?.addEventListener('click', (e) => {
      e?.preventDefault();
      body?.classList?.toggle('menu-fixed');
    });
    /*istanbul ignore next*/
    if (window.screen.width <= 768) {
      // 768px portrait
      this.step = 1;
    }

    // this.isPanelVisible$ = this.focusMonitor.monitor(this.inputEl).pipe(map((focused) => !!focused));
    localStorage.setItem('defaultLanguage', 'EN');
    let userInfo = localStorage.getItem('ActiveUserInfo');
    /*istanbul ignore next*/
    if (userInfo) {
      this.userInfo = JSON.parse(userInfo);
      /*istanbul ignore next */
      this.selectedGroup =
        this.userInfo && this.userInfo.groups && this.userInfo.groups.length > 0 && this.userInfo.groups[0].name
          ? this.userInfo.groups[0].name
          : 'user';
      /*istanbul ignore next*/
      this.sharedFacadeService.changeGroup(this.selectedGroup);
    }
    /*istanbul ignore next*/
    if (localStorage.getItem('tenantDesignData') && JSON.parse(localStorage.getItem('tenantDesignData')) !== '') {
      this.applyHeaderDesign();
    }
    /*istanbul ignore next */
    if (checkTokenExistsorNot()) {
      this.translator.getLanguages();
    }

    var lastScrollTop = 0;
    /*istanbul ignore next*/
    let language = localStorage.getItem('locale') ? localStorage.getItem('locale') : 'EN';

    /*istanbul ignore next */
    if (this.translator.isTranslatedFlag && checkTokenExistsorNot()) {
      this.changeLang(language, false);
    } else {
      this.translator.getStaticJsonFromLocale();
    }

    /*istanbul ignore next*/
    window?.addEventListener(
      'scroll',
      function () {
        var body = document.getElementsByTagName('body')[0];
        var st = window.pageYOffset || document.documentElement.scrollTop;
        if (st > lastScrollTop) {
          body.classList.add('fixed');
        } else {
          body.classList.remove('fixed');
        }
        lastScrollTop = st <= 0 ? 0 : st;
      },
      false
    );
    /*istanbul ignore next*/
    this.selectedGroup = localStorage.getItem('ActiveUserFlag');
  }

  getEnvType() {
    const environmentType = localStorage.getItem('environmentType');
    const envType = localStorage.getItem('envType');
    const env = environmentType ? environmentType : envType;
    if (env === 'development' || env === 'b2c') {
      this.isEnvDev = true;
    }
  }
  ///// Get data page onload ////
  adminHeaderSettings() {
    this.tenantName = localStorage.getItem('TenantName');
    /*istanbul ignore next */
    if (this.tenantName !== null && !this.loginBtnType) {
      //this.tenantCustomUiFacadeService.fetchTenantData(this.tenantName);
      this.tenantCustomUiFacadeService.fetchTenantDataAfterlogin(this.tenantName);
    }
  }

  ///// Getting data page onload ////
  detectTenantUi() {
    this.tenantCustomUiFacadeService.savedTemplate$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data?: any) => {
      if (this.headerDesignData && data) {
        /*istanbul ignore next */
        document.documentElement.style.setProperty('--font-color', data?.result?.canvas?.color?.primary);
        /*istanbul ignore next */
        this.headerDesignData.logo.desktop = data?.result?.header?.logo?.desktop;
        /*istanbul ignore next */
        this.headerDesignData.header.backgroundColor.color = data?.result?.header?.color;
        /*istanbul ignore next */
        this.logoPosition = data?.result?.header?.logoPosition?.desktop;
        /*istanbul ignore next */
        document.documentElement.style.setProperty('--primary-font', data?.result['font']);
      }
    });
  }

  // For Node system
  OnNodeClick() {
    this.NodeClick = true;
  }

  detectTokenExistsorNot() {
    this.notificationFacadeService.pushNotificationTokenValidator$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /*istanbul ignore next*/
        if (!res) {
          this._checkTokenExistsorNot = checkTokenExistsorNot();
          /*istanbul ignore next */
          if (this._checkTokenExistsorNot) {
            this.notificationFacadeService.updateTokenValidateForPushNotification(true);
          }
        }
      });
  }

  detectActiveUserInfo() {
    this.userFacade.activeUserInfo$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      if (res) {
        this.userInfo = res;
        localStorage.setItem('userInfo', JSON.stringify(this.userInfo));
        /*istanbul ignore next */
        this.selectedGroup = this.userInfo?.groups[0]?.name;
        // this.userInfo.userPreferences = {
        //   language: this.language,
        // };
        this.getActivePlan();
        this.getEnvType();
        const lang = localStorage.getItem('locale');
        /*istanbul ignore next*/
        if (this.userInfo?.userPreferences?.language && this.userInfo?.userPreferences?.language !== lang) {
          localStorage.setItem('locale', this.userInfo.userPreferences.language);
          let langCode = localStorage.getItem('locale');
          this.translator.getStaticLabels(langCode.toUpperCase());
        }
      }
    });
  }

  detectTenantDesign() {
    this.userFacade.tenantDesign$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      /*istanbul ignore next*/
      if (res) {
        console.log('headerDesignData', res);
        document.documentElement.style.setProperty('--font-color', res['canvas']['color']['primary']);
        document.documentElement.style.setProperty('--font-color', res['header']['fontColor']['color']);

        document.documentElement.style.setProperty('--bg-color', res['header']['backgroundColor']['color']);
        /*istanbul ignore next*/
        if (res['header']['font']['fontType'] == 'Bold') {
          document.documentElement.style.setProperty('--font-Type', res['header']['font']['fontType']);
        } else {
          document.documentElement.style.setProperty('--font-Style', res['header']['font']['fontType']);
        }

        this.headerDesignData = res;
        localStorage.setItem('tenantDesignData', JSON.stringify(res));
        /*istanbul ignore next */
        this.fontUrl =
          'assets/fonts/' +
          this.headerDesignData?.header?.font?.family?.replace(/_/g, '').toLocaleLowerCase() +
          '-font.css';
      }
    });
  }

  applyHeaderDesign() {
    this.headerDesignData = JSON.parse(localStorage.getItem('tenantDesignData'));
    /*istanbul ignore next */
    this.fontUrl =
      'assets/fonts/' +
      this.headerDesignData?.header?.font?.family?.replace(/_/g, '').toLocaleLowerCase() +
      '-font.css';
  }

  detectLanguagesList() {
    this.translator.languages$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /*istanbul ignore next*/
      if (res) {
        this.availableLanguages = res;
        let languages: any = [];
        /*istanbul ignore next */
        res?.forEach((lang: any) => {
          languages.push(lang.languageCode);
        });
        languages.join('|');
        let browserLang;
        /*istanbul ignore next */
        if (localStorage.getItem('locale')) {
          browserLang = localStorage.getItem('locale').toLocaleUpperCase();
        } else {
          browserLang = 'EN';
          localStorage.setItem('locale', 'EN');
        }
        console.log(browserLang);
        setTimeout(() => {
          /*istanbul ignore next */
          if (!this.translator.isTranslatedFlag && checkTokenExistsorNot()) {
            this.translator.getStaticLabels(localStorage.getItem('locale'));
          }
        }, 1000);
      }
    });
  }

  changeLang(langCode: any, flag: any) {
    this.defaultLanguage = langCode;
    this.language = langCode;
    const lang = langCode;
    const htmlTag = document.getElementsByTagName('html')[0] as HTMLHtmlElement;
    /*istanbul ignore next */
    htmlTag.dir = lang === 'AR' ? 'rtl' : 'ltr';
    localStorage.setItem('locale', langCode);
    localStorage.setItem('defaultLanguage', langCode);

    /*istanbul ignore next*/
    if (this.userInfo) {
      /*istanbul ignore next*/
      if (this.userInfo?.userPreferences) {
        this.userInfo.userPreferences.language = this.language;
      } else {
        /*istanbul ignore next*/
        this.userInfo.userPreferences = {
          language: this.language,
        };
      }
    }
    /*istanbul ignore next*/
    if (this.userInfo && this.userInfo?.id && flag) {
      this.layoutFacadeService.updateUserData(this.userInfo.id, this.userInfo);
    }
    this.changeCssFile(lang);
    this.translator.getStaticLabels(langCode.toUpperCase());
  }

  changeCssFile(lang: string) {
    /*istanbul ignore next */
    const headTag = document.getElementsByTagName('head')[0] as HTMLHeadElement;
    /*istanbul ignore next */
    let existingLink = document.getElementById('langCss') as HTMLLinkElement;
    /*istanbul ignore next */
    let bundleName = lang === 'AR' ? 'arabicStyle.css' : 'englishStyle.css';
    /*istanbul ignore next */
    if (existingLink) {
      /*istanbul ignore next */
      existingLink.href = bundleName;
    } else {
      /*istanbul ignore next */
      let newLink = document.createElement('link');
      /*istanbul ignore next */
      newLink.rel = 'stylesheet';
      /*istanbul ignore next */
      newLink.type = 'text/css';
      /*istanbul ignore next */
      newLink.id = 'langCss';
      /*istanbul ignore next */
      newLink.href = bundleName;
      /*istanbul ignore next */
      headTag?.appendChild(newLink);
    }
  }

  detectChangeOfRoute(currentUrl: string) {
    this.currentUrl = currentUrl;
    this.hideCreateButton = false;
    this.showUserName = true;

    if (currentUrl !== '/newsolution') {
      this.showMysolutionPageMenu = false;
      if (currentUrl === '/mylibrary' || currentUrl === '/cdui/mylibrary-dashboard') {
        this.currentLibrary = this.libraryEnums.MyLibrary;
      }
      if (currentUrl.substring(0, 20) === '/solution-dictionary') {
        this.currentLibrary = 'nslLibrary';
      }
    } else {
      this.showMysolutionPageMenu = true;
    }

    /*istanbul ignore next*/
    if (currentUrl === '/') {
      if (localStorage.getItem('TenantName')) {
        this.showHomeComponent = true;
        this.tenantExists = true;
      } else {
        this.showHomeComponent = false;
        this.tenantExists = false;
      }
      this.navBarwithSearchForDSD = false;
    } else if (currentUrl.includes('/solution-dictionary' || '/tenant' || '/footer-templates')) {
      this.navBarwithSearchForDSD = true;
      this.showHomeComponent = false;
      this.tenantExists = true;
    } else if (
      currentUrl === '/mylibrary' ||
      currentUrl === '/newsolution' ||
      currentUrl === '/mylibrary/pending-transactions' ||
      currentUrl.includes('/admin') ||
      currentUrl.includes('/secmcc') ||
      currentUrl.includes('/admin') ||
      currentUrl.includes('/mylibrary/secmcc') ||
      currentUrl.includes('/transaction') ||
      currentUrl === '/node-solutions' ||
      currentUrl === '/solution-dictionary' ||
      currentUrl === '/tenant' ||
      currentUrl === '/footer-templates' ||
      currentUrl.includes('/mylibrary/book-details') ||
      currentUrl.includes('/mylibrary/solution-details') ||
      currentUrl.includes('/mylibrary/bet-configuration') ||
      currentUrl.includes('/mylibrary/publishtonsl')
    ) {
      this.tenantExists = true;
      this.navBarwithSearchForDSD = false;
      this.showHomeComponent = false;
      this.hideCreateButton = currentUrl === '/mylibrary' || currentUrl === '/cdui/mylibrary-dashboard' ? true : false;
      this.showUserName = true;
    } else {
      this.navBarwithSearchForDSD = false;
      const activeUserFlag = localStorage.getItem('ActiveUserFlag');
      /*istanbul ignore next */
      if (
        activeUserFlag == 'Tenant_Admin' ||
        activeUserFlag == 'Platform_Admin' ||
        activeUserFlag == 'selfonboard_Admin'
      ) {
        this.hideCreateButton = true;
      } else {
        this.showHomeComponent = false;
      }
      this.showUserName = true;
    }
  }

  getLoginAuthentication() {
    // loginBtnType is false means user logged in the application
    this.loginBtnType = !this.credentialsService.isAuthenticated();
    /*istanbul ignore next*/
    if (checkTokenExistsorNot()) {
      this.translator.getLanguages();
    }
  }

  selectGroup(name: any) {
    this.selectedGroup = name;
    this.changeGroup(name);
  }

  changeGroup(e: any) {
    /*istanbul ignore next */
    if (e) {
      this.sharedFacadeService.changeGroup(e);
    }
    this.router.navigate(['/cdui/admin']);
  }

  resetLocalStorage() {
    let tenantName: string;
    let envName: string;
    let environmentType: string;
    let envClientId: string;
    let canDesign: any;
    let tenantId: string;
    let canUsersSelfSignUp: any;
    this.chatBotFacadeService.resetStore();
    this.tenantCustomUiFacadeService.isTenant(null);
    this.router.navigate(['/'], { replaceUrl: true });
    this.notificationFacadeService.updateTokenValidateForPushNotification(false);
    if (localStorage.getItem('TenantName')) {
      tenantName = localStorage.getItem('TenantName');
    }
    if (localStorage.getItem('environmentName')) {
      envName = localStorage.getItem('environmentName');
    }
    /*istanbul ignore else */
    if (localStorage.getItem('environmentType')) {
      environmentType = localStorage.getItem('environmentType');
    }
    if (localStorage.getItem('envClientId')) {
      envClientId = localStorage.getItem('envClientId');
    }
    /*istanbul ignore next */
    if (localStorage.getItem('canDesign')) {
      canDesign = localStorage.getItem('canDesign');
    }
    if (localStorage.getItem('TenantId')) {
      tenantId = localStorage.getItem('TenantId');
    }
    if (localStorage.getItem('canUsersSelfSignUp')) {
      canUsersSelfSignUp = localStorage.getItem('canUsersSelfSignUp');
    }
    localStorage.clear();
    localStorage.setItem('locale', 'EN');
    /* istanbul ignore next */
    if (canDesign !== undefined && canDesign !== null) {
      localStorage.setItem('canDesign', canDesign);
    }
    localStorage.setItem('environmentName', envName);
    localStorage.setItem('environmentType', environmentType);
    localStorage.setItem('TenantName', tenantName);
    localStorage.setItem('TenantId', tenantId);
    localStorage.setItem('envClientId', envClientId);
    localStorage.setItem('canUsersSelfSignUp', canUsersSelfSignUp);

    this.credentialsService.setCredentials();
    sessionStorage.clear();
  }

  logOut() {
    this.userInfo = null;
    this.currentLibrary = '';
    this.userFacade.updateActiveUserInfo(null);
    this.loginBtnType = true;
    this.showUserName = false;
    // this.notificationFacadeService.deRegisterToken();
    const isSocialLogin = localStorage.getItem('isSocialLogin');
    /*istanbul ignore next */
    if (isSocialLogin) {
      this.resetLocalStorage();
      if (this.keycloak?.getKeycloakInstance()?.authenticated) {
        this.keycloak?.logout();
      }
    } else {
      this.authenticationService
        .logout()
        ?.pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res: any) => {
          this.resetLocalStorage();
        });
    }

    this.isTenantAdmin = false;
    this.enableSaasFlag = false;
    this.enableB2C = false;
    this.isPlatformAdmin = false;
    this.isSelfOnboardingAdmin = false;
    this.isUser = false;
    this.enableSaasFlag = false;
    this.canDesign = false;
    this.isPublisher = false;
    this.isAdvertiser = false;
    this.isTenantBraneadsadminqa3 = false;
  }

  gotoSolutions() {
    const activeUserFlag = localStorage.getItem('ActiveUserFlag');
    if (
      activeUserFlag == 'Tenant_Admin' ||
      activeUserFlag == 'Environment_Admin' ||
      activeUserFlag == 'selfonboard_Admin'
    ) {
      this.router.navigate(['/cdui/admin']);
    } else this.router.navigate(['/cdui/mylibrary-dashboard']);
  }

  getUserInfo() {
    this.userFacade.activeUserDetails$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.userDetails = res;
    });
  }

  detectUserType() {
    this.userFacade.activeUserFlag$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      const activeUserFlag = res;
      if (activeUserFlag == 'user') {
        this.enablePreferences = true;
      } else {
        this.enablePreferences = false;
      }
      this.detectActiveUserFlag();
    });
  }

  goToPreferences() {
    this.router.navigateByUrl('/cdui/preferences');
  }

  // Determines whether logo click on and based upon the b2b2c state navigates the user
  onLogoClick() {
    /*istanbul ignore if */
    //if (!this.authenticationService.getB2B2CState() || !this.authenticationService.getBrfLoginState()) {
    //   this.router.navigateByUrl('/');
    // }
    if (!this.authenticationService.getB2B2CState()) {
      this.router.navigateByUrl('/');
    }
  }
  toggleNav() {
    this.isOpenNavigation = !this.isOpenNavigation;
  }

  getActivePlan() {
    this.activeUser = JSON.parse(localStorage.getItem('ActiveUserInfo'));

    if (this.activeUser) {
      /*istanbul ignore next*/
      if (this.activeUser['planNFeatureMapping']?.planName == 'AdBasedPlan') {
        this.isPublisher = true;
      }
      /*istanbul ignore next*/
      if (this.activeUser['planNFeatureMapping']?.planName == 'Advertiser') {
        this.isAdvertiser = true;
      }
      /*istanbul ignore next*/
      this.activeUser = JSON.parse(localStorage.getItem('ActiveUserInfo'));
      /*istanbul ignore next*/
      if (this.activeUser['planNFeatureMapping']?.planName == 'AdPlatformAdmin') {
        this.isTenantBraneadsadminqa3 = true;
      }
    }
  }

  // -----------------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------------

  libraryEnums: LibraryType = Librarytypes;
  notificationLazyComponent: Promise<Type<NotificationsComponent>>;
  selector: string = '.main-panel';
  step: number = 0;
  importLibraryComponent = '';
  pageIndex: number = 0;
  pageSize: number = 15;
  searchText: string = '';
  cuCount: string | number;
  entityCount: string | number;
  totalRecords: number;
  rolesCount: string | number;
  gsiCount: string | number;
  booksCount: string | number;
  currentLibrary: string;
  showMysolutionPageMenu: boolean = false;
  inputFlag: boolean = false;
  importExportFlag: string = '';
  @Output() emitevent: EventEmitter<any> = new EventEmitter();
  isDashboard: boolean = false;
  isImport: boolean = false;
  isTenantAdmin: boolean = false;
  isPlatformAdmin: boolean = false;
  isSelfOnboardingAdmin: boolean = false;
  isUser: boolean = false;
  isSolutionCustomizer: boolean = false;
  menuSide: boolean = false;
  pageIndexForNotification: number = 0;
  pageSizeForNotification: number = 5;
  private staticPageSizeForNotification: number = 5;
  totalNotificationsRecords: any;
  enableSaasFlag: boolean = false;
  enableB2C: boolean = false;
  calenderView: boolean;
  attributeCount: string | number;
  isTenant: boolean = true;
  canDesign: boolean = false;
  planName: any;
  activeUser: any;
  isPublisher: boolean = false;
  isAdvertiser: boolean = false;
  isTenantBraneadsadminqa3: boolean = false;
  isDcdScreenStatus: any;
  isMenuOpen: boolean = false;
  isBook: boolean = false;

  detectCarnivalPlatform() {
    let url = this.router.url;
    console.log(url);
  }

  detectIsDashboard() {
    this.rolesFacade.isDashboard$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.isDashboard = res;
    });
  }

  detectIsImport() {
    this.rolesFacade.isImport$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.isImport = res;
    });
  }

  detectClearNotificationComponent() {
    this.notificationFacadeService.resetNotificationComponent$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((flag: any) => {
        /*istanbul ignore else*/
        if (flag) {
          // this.notificationLazyComponent = undefined;
        }
      });
  }

  detectDcdScreenStatus() {
    this.libraryFacadeService.isDCDScreenActive$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((status: any) => {
      this.isDcdScreenStatus = status;
    });
  }

  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
    this.detectActiveUserFlag();
  }

  detectIsBookSubject() {
    this.rolesFacade.isBookSubject$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.isBook = res;
    });
  }

  detectNotificationsPageCount() {
    this.notificationFacadeService.notificationsData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /*istanbul ignore else*/
      if (res) {
        this.totalNotificationsRecords = Math.ceil((Math.floor(res.totalElements) * 1.1) / 5) * 5;
      }
    });
  }

  //enables edit gsi by solution customizer role
  checksolutioncustomizer() {
    let userinfo = localStorage.getItem('userInfo');
    let UserInfo = JSON.parse(userinfo);
    /*istanbul ignore else*/
    if (UserInfo) {
      /*istanbul ignore next*/
      for (let i = 0; i < UserInfo?.roles?.length; i++) {
        /*istanbul ignore next*/
        if (UserInfo?.roles[i]?.name == 'SolutionCustomizer') {
          this.isSolutionCustomizer = true;
          break;
        }
      }
    }
  }

  //goes to next page
  gotonextpage() {
    this.checksolutioncustomizer();
    if (this.isSolutionCustomizer) {
      this.router.navigate(['/mylibrary/editgsi']);
    } else {
      alert('You are not a "SolutionCustomizer"');
    }
  }

  detectLibraryPanelType() {
    this.libraryFacadeService.currentLibraryType$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((libraryPanelType: any) => {
        if (libraryPanelType !== undefined) {
          this.currentLibrary = libraryPanelType;
        }
      });
  }

  // expand collapse by default
  setStep(index: number) {
    this.step = index;
  }

  gotoSolutionsFromMenuPanel() {
    this.libraryFacadeService.openImportSolution(false);
    this.router.navigate(['/cdui/mylibrary-dashboard']);
    this.currentLibrary = 'mylibrary';
  }

  openLibrary(libraryType: string) {
    if (this.isDcdScreenStatus !== true) {
      if (libraryType == 'gsi' || libraryType == 'entity' || libraryType == 'cu')
        this.libraryFacadeService.selectAllBETs(true);

      this.currentLibrary = libraryType;
      // this.libraryFacadeService.updateCurrentLibraryType(libraryType);

      // left side bar opening //
      this.libraryFacadeService.detectLibrary(true);
      if (libraryType == 'gsi' && this.stepType == 'gsinested') {
        libraryType = 'gsinested';
      }

      switch (libraryType) {
        case this.libraryEnums.Chapter:
        case this.libraryEnums.Collaborator:
          if (this.notificationLazyComponent) {
            this.showNotificationComponent();
          }
          this.alertService.showToaster(this.labels.Coming_Soon, '', 'info');
          break;
        case this.libraryEnums.MailBox:
          if (this.notificationLazyComponent) {
            this.showNotificationComponent();
          }
          this.router.navigate(['/mylibrary/pending-transactions']);
          break;

        case this.libraryEnums.Users:
          if (this.notificationLazyComponent) {
            this.showNotificationComponent();
          }
          this.router.navigate(['/mylibrary/collaborating-users']);
          break;

        case this.libraryEnums.Import:
          if (this.notificationLazyComponent) {
            this.showNotificationComponent();
          }
          this.import();
          // hide sidebar toggle
          /* instanbul ignore else*/
          if (window.screen.width <= 768) {
            this.setStep(1);
          }
          break;

        case this.libraryEnums.Export:
          if (this.notificationLazyComponent) {
            this.showNotificationComponent();
          }
          this.export();
          // hide sidebar toggle
          /* instanbul ignore else*/
          if (window.screen.width <= 768) {
            this.setStep(1);
          }
          break;

        case this.libraryEnums.Dashboard:
          if (this.notificationLazyComponent) {
            this.showNotificationComponent();
          }
          this.router.navigate(['/admin/dashboard']);

          break;

        case this.libraryEnums.MyDashboard:
          if (this.notificationLazyComponent) {
            this.showNotificationComponent();
          }
          this.router.navigate(['/admin/my-dashboard']);
          break;

        case this.libraryEnums.Admin:
          if (this.notificationLazyComponent) {
            this.showNotificationComponent();
          }
          /* instanbul ignore else*/
          if (!this.isTenantAdmin && !this.isPlatformAdmin && !this.isSelfOnboardingAdmin) {
            localStorage.setItem('bookIrdrFlag', 'irdr_book');
            this.router.navigateByUrl(`cdui/admin`);
            // this.isPlatformAdmin = !this.isPlatformAdmin;
            // this.isTenantAdmin = !this.isTenantAdmin;
          } else {
            this.router.navigateByUrl(`cdui/admin`);
            // this.isPlatformAdmin = false;
            // this.isTenantAdmin = false;
          }
          break;

        case this.libraryEnums.Notifications:
          this.showNotification = true;
          this.showNotificationComponent();
          break;

        case this.libraryEnums.AdDashboard:
          if (this.notificationLazyComponent) {
            this.showNotificationComponent();
          }
          this.router.navigate(['/admin/Ad-dashboard']);
          // this.isPlatformAdmin = false;
          // this.isTenantAdmin = false;
          break;

        case this.libraryEnums.FileExplorer:
          if (this.notificationLazyComponent) {
            this.showNotificationComponent();
          }
          this.router.navigate(['/admin/file-explorer']);
          // this.isPlatformAdmin = false;
          // this.isTenantAdmin = false;
          break;

        case this.libraryEnums.AdvertiserDashboard:
          if (this.notificationLazyComponent) {
            this.showNotificationComponent();
          }
          this.router.navigate(['/admin/Advertiser-ad-dashboard']);
          // this.isPlatformAdmin = false;
          // this.isTenantAdmin = false;
          break;

        case this.libraryEnums.SpecialEvent:
          if (this.notificationLazyComponent) {
            this.showNotificationComponent();
          }
          this.redirectToCarnival();
          break;

        case this.libraryEnums.PublisherDashboard:
          if (this.notificationLazyComponent) {
            this.showNotificationComponent();
          }
          this.router.navigate(['/admin/Publisher-ad-dashboard']);
          break;

        case this.libraryEnums.NslLibrary:
          if (this.notificationLazyComponent) {
            this.showNotificationComponent();
          }
          this.router.navigate(['/cdui/solution-dictionary/book']);
          this.currentLibrary = this.libraryEnums.NslLibrary;
          // this.isPlatformAdmin = false;
          // this.isTenantAdmin = false;
          break;

        case this.libraryEnums.MileStone:
          if (this.notificationLazyComponent) {
            this.showNotificationComponent();
          }
          this.router.navigate(['/cdui/admin/milestone-dashboard']);
          // this.isPlatformAdmin = false;
          // this.isTenantAdmin = false;
          break;
        case this.libraryEnums.MyProfile:
          this.router.navigate(['/cdui/transaction/myprofile']);
          break;

        case this.libraryEnums.Collaborate:
          if (this.notificationLazyComponent) {
            this.showNotificationComponent();
          }
          this.versioningFacade.sendFilterNotifications();
          this.router.navigate(['/cdui/collaborate']);
          break;

        default:
          this.libraryFacadeService.openImportSolution(false);
          /*istanbul ignore next*/
          if (libraryType === this.libraryEnums.Search) {
            this.inputFlag = !this.inputFlag;
            if (this.inputFlag) {
              this.libraryFacadeService.homePageBookSearch(true);
            } else {
              this.libraryFacadeService.homePageBookSearch(false);
            }
          } else {
            this.libraryFacadeService.changeOfCurrentLibraryType(
              libraryType,
              this.pageIndex,
              this.pageSize,
              this.searchText
            );
          }
          break;
      }
    } else {
      this.alertService.showToaster('Sorry Menu Panel is Disabled, Kindly Select DCD Source/Target', '', 'info');
    }
  }

  import() {
    if (!this.showMysolutionPageMenu) {
      this.router.navigate(['/cdui/mylibrary-dashboard']);
    }
    this.libraryFacadeService.openImportSolution('import');
    this.importExportFlag = 'import';
  }

  export() {
    if (!this.showMysolutionPageMenu) {
      this.router.navigate(['/cdui/mylibrary-dashboard']);
    }
    this.libraryFacadeService.openImportSolution('export');
    this.importExportFlag = 'export';
  }

  getLibraryDataCount() {
    this.libraryFacadeService.libraryDataCount$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.cuCount = this.kFormatter(res.cuCount);
      this.entityCount = this.kFormatter(res.entityCount);
      this.rolesCount = this.kFormatter(res.roleCount);
      this.gsiCount = this.kFormatter(res.gsiCount);
      this.booksCount = this.kFormatter(res.bookCount);
      this.attributeCount = this.kFormatter(res.customAttributeCount);
    });
  }

  kFormatter(num: number) {
    /*istanbul ignore next*/
    return Math.abs(num) > 999 ? (num / 1000).toFixed(1) + 'K' : Math.sign(num) * Math.abs(num);
  }

  detectActiveUserFlag() {
    const activeUserFlag = localStorage.getItem('ActiveUserFlag');
    const tenantType = localStorage.getItem('TenantType');
    /* istanbul ignore else */
    if (activeUserFlag == 'Tenant_Admin' || activeUserFlag == 'Environment_Admin') {
      this.isTenantAdmin = true;
      /* istanbul ignore else */
      if (tenantType == 'saas') {
        this.enableSaasFlag = true;
      }
    }
    /* istanbul ignore else */
    if (sessionStorage.TenantId == 'b2cdev' || sessionStorage.TenantId == 'b2c') {
      this.enableB2C = true;
    }
    /* istanbul ignore else */
    if (activeUserFlag == 'Platform_Admin') {
      this.isPlatformAdmin = true;
    }
    /* istanbul ignore else */
    if (activeUserFlag == 'selfonboard_Admin') {
      this.isSelfOnboardingAdmin = true;
    }
    /* istanbul ignore else */
    if (activeUserFlag == 'user') {
      this.isUser = true;
      /* istanbul ignore else */
      if (tenantType == 'saas') {
        this.enableSaasFlag = true;
      }
      /* istanbul ignore else */
      if (tenantType == 'subtenant') {
        this.isSubTenant = true;
      }
    }
    /*istanbul ignore next */
    if (localStorage.getItem('canDesign') === 'true') {
      this.canDesign = true;
    }
  }

  showNotificationComponent() {
    this.currentLibrary = this.libraryEnums.Notifications;
    /*istanbul ignore next */
    if (!this.notificationLazyComponent) {
      this.notificationLazyComponent = import(
        '@app/create/shared/components/notifications/notifications.component'
      ).then(({ NotificationsComponent }) => NotificationsComponent);
      this.getNotificationDataOnLoad();
    } else {
      /*istanbul ignore next */
      this.notificationLazyComponent = undefined;
      /*istanbul ignore next */
      this.pageSizeForNotification = this.staticPageSizeForNotification;
      /*istanbul ignore next */
      this.currentLibrary = this.libraryEnums.MyLibrary;
    }
  }

  getNotificationDataOnLoad() {
    this.notificationFacadeService.changeLoaderStatus(true);
    this.notificationFacadeService.getAllNotifications({
      pageIndex: this.pageIndexForNotification,
      pageSize: this.pageSizeForNotification,
    });
  }

  getUnreadNotificationCount() {
    this.notificationFacadeService.getUnreadNotificationCount();
  }
  updateUnreadNotificationCount() {
    this.notificationFacadeService.unreadNotificationCount$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.unreadNotifCount = res?.result;
      });
  }

  // redirect to carnival
  redirectToCarnival() {
    const carnivalPersona = localStorage.getItem('carnivalPersona');
    if (carnivalPersona) {
      this.router.navigate([`/carnival/category/${carnivalPersona}/ref_pg/1`]);
    } else {
      this.alertService.showToaster('No events are going on!', '', 'info');
    }
  }

  // Increment the Page Size by 5
  // Load Notification Data By latest Page Size
  getNotificationDataOnScrollDown() {
    if (this.pageSizeForNotification < this.totalNotificationsRecords) {
      this.pageSizeForNotification = this.pageSizeForNotification + this.staticPageSizeForNotification;
      this.getNotificationDataOnLoad();
    }
  }

  // Determines whether calendar click on
  onCalendarClick(value: string) {
    this.currentLibrary = value;
    this.calenderView = !this.calenderView;
    this.newSolutionFacade.setCalenderView(this.calenderView);
    this.router.navigateByUrl('admin/calendar');
  }

  scheduledjobs() {
    this.router.navigate(['/mylibrary/scheduled-job-details']);
  }

  // detect current route and fet current qnslist and current question
  detectCurrentRoute() {
    /* istanbul ignore next */
    const urlLocation: any = this.router.url.split('/');
    /* istanbul ignore next */
    const label = urlLocation[urlLocation?.length - 1];
    /*istanbul ignore next*/
    if (label == 'notepad') {
      this.activeSideMenu = label;
    }
  }

  comingSoonToaster() {
    this.alertService.showToaster(this.labels.Coming_Soon, '', 'info');
  }

  onClickMenuItem(item: string) {
    this.activeSideMenu = item;
    /*istanbul ignore next*/
    if (item === 'myCart') {
      this.router.navigateByUrl('mylibrary/my-cart');
    }
  }

  detectLibraryLayoutconfig() {
    this.layoutFacadeService.getLayoutConfig$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      console.log(res);
      this.roles = res;
    });
  }

  changeRole(role: any) {
    // this.layoutFacadeService.getLibraryLayoutById(role?.siteLayoutId);
    if (role?.siteLayoutId) {
      this.router.navigateByUrl(`cdui/custom-mylibrary?siteLayoutId=${role?.siteLayoutId}`);
    } else {
      this.router.navigateByUrl('cdui/mylibrary-dashboard');
    }
  }

  detectStep() {
    this.libraryFacadeService.stepType$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore next */
      this.stepType = res;
    });
  }

  get _roles() {
    if (!this.roles && localStorage.getItem('userRoles')) {
      this.roles = JSON.parse(localStorage.getItem('userRoles'));
    }
    return this.roles;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslatorService } from '@app/shell/translator.service';
import { AuthenticationService } from '../authentication.service';
import { LoaderService } from '@app/@shared/services/loader/loader.service';
import { AlertService } from '@app/@core/toaster/alert.service';
import { AnimationOptions } from 'ngx-lottie';
@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.scss'],
})
export class ForgotpasswordComponent implements OnInit, OnDestroy {
  realmname: string;
  email: any;
  private ngUnsubscribe = new Subject();
  lottieOptions: AnimationOptions = {
    path: '../../../assets/json/login earth.json',
  };
  env: string;
  customLoginTenants = ['vpnhsl', 'ngdev', 'nggroupuat'];
  lmsTenants = ['ngdev', 'nggroupuat'];
  isLmsTenant: boolean = false;
  constructor(
    private router: Router,
    public translator: TranslatorService,
    private authenticationService: AuthenticationService,
    private loader: LoaderService,
    private alertService: AlertService
  ) {
    this.detectLanguageChange();
  }
  labels: any;
  isCustomLoginTenant: boolean = false;

  ngOnInit(): void {
    this.realmname = localStorage.getItem('TenantName');
    this.env = localStorage.getItem('environmentName');
    /* istanbul ignore next */
    if (this.customLoginTenants.includes(this.realmname)) {
      this.isCustomLoginTenant = true;
    }
    /* istanbul ignore next */
    if (this.lmsTenants?.includes(this.realmname)) {
      this.isLmsTenant = true;
    }
  }

  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }

  /** Function to go back to login page when we click on Cancel Btn  on forgot paswd page */
  cancelClick() {
    this.router.navigate(['/login'], { replaceUrl: true });
  }

  /** Function to make API call with required Body Obj to forgot password API when we click on Submit btn */
  clickSubmit() {
    let obj: any = {};
    obj.realm = this.realmname;
    obj.email = this.email;
    obj.environmentName = this.env;
    this.loader.show();
    this.authenticationService
      .forgotPassword(obj)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        if (res) {
          this.alertService.showToaster(res.message, '', res.status == '200' ? 'success' : 'error');
        }
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Attribute } from '@app/@shared/models/attribute';
import { CduiBaseService } from '@app/auth/cdui-base.service';
import { pluck } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class AttributeEndpointService extends CduiBaseService {
  constructor(private injector: Injector, private http: HttpClient) {
    super(injector);
  }

  getAllLibraryDataCount() {
    return this.http.get(`${this.api?.dsdBetsStoreUrl}/tenant/count`);
  }

  saveAttribute(body: Attribute) {
    return this.http.post(`${this.environment.dsdOrchUrl}` + '/dsd-bets-store/custom-attribute/upsert', body);
  }

  getAllAttributes(pagenum: number, pageSize: number, searchCriteria: string) {
    return this.http
      .get(this.api?.getAllAttributes + `?query=${searchCriteria}&page=${pagenum}&limit=${pageSize}`)
      .pipe(pluck('result'));
  }

  getAllAttributesById(dsdId: string) {
    return this.http.get(this.api?.getAttributesByID + `${dsdId}`).pipe(pluck('result'));
  }

  getAllAttributesByName(name: string) {
    return this.http.get(this.api?.getAttributesByName + `${name}`).pipe(pluck('result'));
  }
  getAllAttributeTypes(searchString: string, pageIndex: number, pageSize: number, type?: any) {
    type = type == undefined || type == 'autoIdentifier' ? '' : type;
    return this.http
      .get(
        `${this.api?.customTenantUI}/ui-elem?limit=${pageSize}&page=${pageIndex}&query=${searchString}&dataType=${type}`
      )
      .pipe(pluck('result'));
  }
}

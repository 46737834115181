import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { AcronymPipe } from './acronym.pipe';
import { FilterPipes } from './appFilter.pipe';
import { AttSymbolPipe } from './attsymbol.pipe';
import { CdnUrlRewritePipe } from './cdnUrlRewrite.pipe';
import { CustomdatePipe } from './customdate.pipe';
import { DatetimePipe } from './datetime.pipe';
import { DcdLabelPipe } from './dcd-label.pipe';
import { DcdPipe } from './dcd.pipe';
import { DLDAttributePipe } from './dld-attribute.pipe';
import { DLDChangeUnitPipe } from './dld-changeunit.pipe';
import { DLDEntityPipe } from './dld-entity.pipe';
import { FilterPipe } from './filter.pipe';
import { FormatTimePipe } from './format-time.pipe';
import { SafeResourceUrlPipe } from './safeurl.pipe';
import { UseHttpImageSourcePipe } from './securesrc.pipe';
import { SplitPipe } from './split.pipe';
import { StrReplacePipe } from './strReplace.pipe';

const customPipes = [
  DcdPipe,
  DcdLabelPipe,
  DatetimePipe,
  SplitPipe,
  FilterPipe,
  StrReplacePipe,
  CustomdatePipe,
  SafeResourceUrlPipe,
  DLDChangeUnitPipe,
  DLDEntityPipe,
  DLDAttributePipe,
  FilterPipes,
  UseHttpImageSourcePipe,
  CdnUrlRewritePipe,
  AcronymPipe,
  FormatTimePipe,
  AttSymbolPipe,
];

@NgModule({
  imports: [
    // dep modules
  ],
  declarations: [...customPipes],
  exports: [...customPipes],
  providers: [DatePipe],
})
export class ApplicationPipesModule {}

export class Flat2Flat {
  /**
   * batch Flat2Flat API body
   */
  constructor(public cu: CUFlat, public agents: Agents, public entities: Entities) {}
}
export class CUFlat {
  constructor(public name: string, public dsd_id: string, public tf_id: string, public isReserved?: string) {}
}
export class Agents {
  constructor(public name: string, public dsd_id: string, public tf_id: string) {}
}
export class Entities {
  [key: string]: EntityFlatKey;
}
export class EntityFlatKey {
  [key: string]: EntityFlat;
}
export class EntityFlat {
  constructor(public tf_id: string, public dsd_id: string, public attribute_dict: AttributeDict) {}
}
export class AttributeDict {
  [key: string]: AttributeFlat;
}
export class AttributeFlat {
  constructor(public dsd_id: string, public tf_id: string) {}
}

// "information": {
//   "info_entity": {
//       "tf_id": "<entity1 TF ID>",
//       "dsd_id": "<entity1 DSD ID>",
//       "attribute_dict": {
//           "info1": {
//               "dsd_id": "<2.4 DSD ID>",
//               "tf_id": "<2.4 TF ID>"
//           },
//           "info2": {
//               "dsd_id": "<2.4 DSD ID>",
//               "tf_id": "<2.4 TF ID>"
//           }
//       }
//   }
// },

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material-module';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { TooltipModule } from 'primeng/tooltip';
import { QrcodeScannerComponent } from './qrcode-scanner.component';

@NgModule({
  declarations: [QrcodeScannerComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule, ZXingScannerModule],
  exports: [QrcodeScannerComponent],
})
export class QrcodeScannerModule {}

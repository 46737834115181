<div class="demo-full-width" [formGroup]="group">
  <mat-label *ngIf="!field?.hideLabels"
    >{{ field.label }}: <span *ngIf="field?.isRequired" class="mat-form-field-required-marker">*</span>
  </mat-label>
  <app-qrcode-scanner [formControlName]="field.attribute.name" id="{{ field.attribute.name }}" [field]="field">
  </app-qrcode-scanner>
  <mat-icon matSuffix
    ><span
      *ngIf="field?.nslAttributeProperties?.triggerSubTransaction == 'true'"
      class="sub-cu"
      (click)="openSubPopUp()"
    ></span>
  </mat-icon>
</div>

import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { RolesFacadeService } from '@app/admin/services/roles/roles-facade.service';
import { Subject } from 'rxjs/internal/Subject';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslatorService } from '@app/shell/translator.service';
import { UsersFacadeService } from '@app/admin/services/users/users-facade.service';
import { DatePipe } from '@angular/common';
import { takeUntil, map, debounceTime } from 'rxjs/operators';
import { EventsFacadeService } from '@app/@shared/services/events/events-facade.service';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
@Component({
  selector: 'app-list-delegation',
  templateUrl: './list-delegation.component.html',
  styleUrls: ['./list-delegation.component.scss'],
  providers: [DatePipe],
})
export class ListDelegationComponent extends MatPaginatorIntl implements OnInit, OnDestroy {
  bookId: any;
  solutionId: any;
  primaryId: any;
  pageSize = 10;
  pageNumber = 0;
  displayedColumns: string[] = ['sourceName', 'targetName', 'time', 'delegationType', 'action'];
  delegationData: any = [];
  dataSource = new MatTableDataSource(this.delegationData);
  totalCount = 0;
  searchString = '';
  labels: any;
  openSearch: any;
  searchOpen = false;
  activeUserId: any;
  roleId: any;
  isRoleDelegation = false;
  isUserDelegation = false;
  searchInput = new Subject();

  private unsubscribe$: Subject<any> = new Subject<any>();
  private ngUnsubscribe: Subject<any> = new Subject<any>();
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private rolesFacadeService: RolesFacadeService,
    private usersFacadeService: UsersFacadeService,
    private router: Router,
    private actRoute: ActivatedRoute,
    private translator: TranslatorService,
    private datePipe: DatePipe,
    private eventsService: EventsFacadeService
  ) {
    super();
    this.detectActiveUser();
    this.getUserDelegationsByStream();
    this.getRolesDelegationsByStream();
    this.detectLanguageChange();
    this.detectIsUserForDelegationStream();
    this.detectDeleteDelegationStream();
    this.detectRoleStream();
    this.detectSearchInput();
  }

  ngOnInit(): void {
    this.dataSource.sort = this.sort;
    if (this.isUserDelegation) {
      this.getUserDelegations();
      this.rolesFacadeService.saveSingleRoleData(null);
    }
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    /* istanbul ignore next */
    this.dataSource.sortingDataAccessor = (item: any, property: string): string | number => {
      if (property) {
        return item[property];
      }
    };
  }

  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
      this.getAndInitTranslations();
    });
  }

  menuOpened() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('panel-opened');
    const overlay = document.getElementsByClassName('cdk-overlay-container')[0];
    /* istanbul ignore next */
    if (overlay) {
      overlay.addEventListener('click', function () {
        body.classList.remove('panel-opened');
      });
    }
  }

  menuClosed() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('panel-opened');
  }

  getAndInitTranslations() {
    /* istanbul ignore next */
    this.itemsPerPageLabel = this.labels?.items_per_page;
    this.nextPageLabel = 'Next';
    this.previousPageLabel = 'Prev';
    this.changes.next();
  }

  // getRangeLabel = (page: number, pageSize: number, length: number) => {
  //   if (length === 0 || pageSize === 0) {
  //     return `0 / ${length}`;
  //   }
  //   length = Math.max(length, 0);
  //   const startIndex = page * pageSize;
  //   const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
  //   return `${startIndex + 1} - ${endIndex} ${this.labels.of} ${length}`;
  // };

  detectActiveUser() {
    const activeUser = JSON.parse(localStorage.getItem('ActiveUserInfo'));
    this.activeUserId = activeUser['id'];
  }

  getUserDelegations() {
    this.usersFacadeService.loadUserDelegations(this.pageNumber, this.pageSize, this.searchString, this.activeUserId);
  }

  getUserDelegationsByStream() {
    this.usersFacadeService.usersDelegationList$.pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      if (res) {
        this.getDelegationData(res);
      }
    });
  }
  getTheDelegationData(a: any) {
    this.getDelegationData(a);
  }

  getRolesDelegationsByStream() {
    this.rolesFacadeService.rolesDelegationList$.pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      if (res) {
        this.getTheDelegationData(res);
      }
    });
  }

  detectIsUserForDelegationStream() {
    const listDel = localStorage.getItem('ListDelegation');
    if (listDel == 'true') {
      this.isUserDelegation = true;
    }
  }

  detectDeleteDelegationStream() {
    this.usersFacadeService.deleteDelegation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      if (res) {
        this.getUserDelegations();
      }
    });
    this.rolesFacadeService.deleteDelegation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      if (res) {
        this.rolesFacadeService.loadRoleDelegations(this.pageNumber, this.pageSize, this.searchString, this.roleId);
      }
    });
  }

  getDelegationData(res: any) {
    this.delegationData = [];
    this.totalCount = 0;
    /* istanbul ignore next */
    if (res?.content && res?.content?.length > 0) {
      /* istanbul ignore next */
      this.totalCount = res?.totalElements;
      res.content.map((x: any) => {
        if (x != 'no record found') {
          this.delegationData.push({
            ...x,
            sourceName: x.sourceName ? x.sourceName : '-',
            targetName: x.targetName ? x.targetName : '-',
            time: `${x.condition?.fromDateTime ? this.datePipe.transform(x.condition?.fromDateTime, 'MMM d, y') : ''} - 
                ${x.condition?.toDateTime ? this.datePipe.transform(x.condition?.toDateTime, 'MMM d, y') : ''}`,
          });
        }
      });
    }
    this.dataSource.data = this.delegationData;
  }

  detectRoleStream() {
    this.rolesFacadeService.singleRoleById$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      if (res) {
        this.isRoleDelegation = true;
        /* istanbul ignore next */
        this.roleId = res?.id;
        this.rolesFacadeService.loadRoleDelegations(this.pageNumber, this.pageSize, this.searchString, this.roleId);
      }
    });
  }

  // applyFilter(event: Event) {
  //   const filterValue = (event.target as HTMLInputElement).value;
  //   this.dataSource.filter = filterValue.trim().toLowerCase();
  // }

  addDelegation() {
    this.eventsService.setDelegationId(null);
    if (this.isUserDelegation) {
      this.usersFacadeService.listDelegationForUser(true);
      this.router.navigateByUrl('preferences/delegation');
    } else {
      this.router.navigateByUrl('admin/delegation');
    }
  }

  updateDelegation(item: any) {
    /* istanbul ignore next */
    this.eventsService.setDelegationId(item?.id);
    if (this.isUserDelegation) {
      this.usersFacadeService.listDelegationForUser(true);
      this.router.navigateByUrl('preferences/delegation');
    } else {
      this.router.navigateByUrl(`admin/delegation`);
    }
  }

  deleteDelegation(item: any) {
    if (this.isRoleDelegation) {
      /* istanbul ignore next */
      this.rolesFacadeService.deleteDelegation(item?.id);
    } else if (this.isUserDelegation) {
      /* istanbul ignore next */
      this.usersFacadeService.deleteDelegation(item?.id);
    }
  }

  changePage(event: any) {
    this.pageNumber = event.pageIndex;
    this.pageSize = event.pageSize;
  }

  searchRoles() {
    this.pageNumber = 0;
  }

  detectSearchInput() {
    this.searchInput
      .pipe(
        map((event: any) => event),
        debounceTime(500),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((event) => {
        if (this.searchString && this.searchString !== '') {
          this.pageNumber = 0;
          this.getUserDelegations();
        }
      });
  }

  searchClose() {
    this.searchOpen = false;
    this.searchString = '';
    this.getUserDelegations();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dcdLabel',
  pure: true,
})
export class DcdLabelPipe implements PipeTransform {
  //this pipe separates a contextual id with sach for dcd label display.
  transform(item: any, onlyGsi: boolean): string {
    let splitItems = [];
    let output = '';

    if (item) {
      splitItems = item.split('.');
      if (onlyGsi) {
        return splitItems[0];
      }
      if (splitItems.length > 4) {
        output = output + splitItems[0] + ' /';
        output = output + splitItems[1].split(':')[0] + ' /';
        output = output + splitItems[2] + ' /';
        output = output + splitItems[4];

        if (splitItems[5]) {
          output = output + ' /' + splitItems[5];
        }
        if (splitItems[6]) {
          output = output + ' /' + splitItems[6];
        }
      } else {
        output = output + splitItems[2] + ' / ';
        output = output + splitItems[3];
      }
    }
    return output;
  }
}

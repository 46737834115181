import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Gsi } from '@app/@shared/models/gsi';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { NewSolutionEndpointService } from './create-solution-endpoint.service';

@Injectable({
  providedIn: 'root',
})
export class NewSolutionFacadeService {
  gsi$: Observable<Gsi[]>;
  // private changeUnitSource = new BehaviorSubject('');
  // cuList = this.changeUnitSource.asObservable();
  private selectedBookSubject = new BehaviorSubject('');
  selectedBook = this.selectedBookSubject.asObservable();
  private ngUnsubscribe = new Subject();
  searchSidebar$ = new BehaviorSubject(null);
  calenderView = new Subject();

  alertPopUpState = new Subject();

  constructor(private newsolutionendpoint: NewSolutionEndpointService, private http: HttpClient) {}
  /**
   * Sets calender view
   * @param value
   */
  setCalenderView(value: boolean) {
    this.calenderView.next(value);
  }

  /**
   * Gets calender view value
   * @returns
   */
  getCalendarViewValue() {
    return this.calenderView.asObservable();
  }

  /**
   * Gets calendar events
   * @returns
   */
  getCalendarEvents(payload: any, isForDropdown: boolean) {
    return this.newsolutionendpoint.getCalendarEvents(payload, isForDropdown);
  }

  //scheduler API
  scheduleCalendarEvent(payload: any) {
    return this.newsolutionendpoint.scheduleCalendarEvent(payload);
  }

  updateCalendarEvent(payload: any) {
    return this.newsolutionendpoint.updateCalendarEvent(payload);
  }

  deleteCalendarEvent(payload: any) {
    return this.newsolutionendpoint.deleteCalendarEvent(payload);
  }

  getCalendarAvailability(payload: any) {
    return this.newsolutionendpoint.getCalendarAvailability(payload);
  }

  /**
   * Sets alert pop up state
   * @param state
   */
  setAlertPopUpState(state: boolean) {
    this.alertPopUpState.next(state);
  }

  /**
   * Gets alert pop up state
   * @returns
   */
  getAlertPopUpState() {
    return this.alertPopUpState.asObservable();
  }

  /**
   * Resets alert pop up state
   */
  resetAlertPopUpState() {
    setTimeout(() => {
      this.alertPopUpState.next(false);
    }, 500);
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  // saveCU(cu: any) {
  //   this.newsolutionendpoint
  //     .saveCU(cu)
  //     .pipe(untilDestroyed(this))
  //     .subscribe((res) => {
  //       // this.toastr.success('Chnage unit created successfully');
  //       return res;
  //     });
  // }

  // updateBookData(book: any) {
  //   this.selectedBookSubject.next(book);
  // }

  // getAllCus(pagenum: number, searchCriteria: string) {
  //   this.newsolutionendpoint
  //     .getAllCus(pagenum, searchCriteria)
  //     .pipe(untilDestroyed(this))
  //     .subscribe((response: any) => {
  //       console.log(response);
  //       this.changeUnitSource.next(response.result.content);
  //     });
  // }

  // saveGsi(body: any) {
  //   this.newsolutionendpoint
  //     .saveGsi(body)
  //     .pipe(untilDestroyed(this))
  //     .subscribe((res: any) => {
  //       console.log(res);
  //     });
  // }
}

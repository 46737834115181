import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '@app/@core/toaster/alert.service';
import { LoaderService } from '@app/@shared/services/loader/loader.service';
import { TranslatorService } from '@app/shell/translator.service';
import { AnimationOptions } from 'ngx-lottie';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthenticationService } from '../authentication.service';

@Component({
  selector: 'app-otp-validation',
  templateUrl: './otp-validation.component.html',
  styleUrls: ['./otp-validation.component.scss'],
})
export class OtpValidationComponent implements OnInit, OnDestroy {
  fileotp: any;
  lottieOptions: AnimationOptions = {
    path: '../../../assets/json/login earth.json',
  };
  private ngUnsubscribe = new Subject();
  labels: any;
  data: any;
  fileUrl: string;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loader: LoaderService,
    private alertService: AlertService,
    private translator: TranslatorService,
    private authenticationService: AuthenticationService
  ) {
    this.detectLanguageChange();
  }
  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }
  ngOnInit(): void {
    /* istanbul ignore next */
    this.data = this.activatedRoute?.queryParams['_value'];
  }
  cancelClick() {
    this.router.navigate(['/login'], { replaceUrl: true });
  }

  /** Function to make API call with required OTP on on Submit btn */
  clickSubmit() {
    this.data = { ...this.data, otp: this.fileotp };
    //dsd-file-store/secureotp?uuid=123456&otp=2345
    this.loader.show();
    this.authenticationService
      .fileOtpValidation(this.data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          /* istanbul ignore else */
          if (res) {
            this.exportBlobFile(res);
            /* istanbul ignore next */
            this.alertService.showToaster(res.message, '', res.status == '200' ? 'success' : 'error');
            this.cancelClick();
          }
        },
        (error) => {
          this.alertService.showToaster(error.message, '', 'error');
          console.log(error);
        }
      );
  }
  /* istanbul ignore next */
  exportBlobFile(res: any) {
    const data: any = JSON.stringify(res);
    const blob = new Blob([data], { type: 'application/octet-stream' });
    // this.fileUrl = window.URL.createObjectURL(blob);
    this.fileUrl = res.url;
    const a = document.createElement('a');
    a.href = this.fileUrl;
    a.click();
    window.URL.revokeObjectURL(this.fileUrl);
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}

import { Component, OnInit } from '@angular/core';
import { FieldConfig } from '@app/@shared/models/field.interface';
import { FormGroup } from '@angular/forms';
import { EventsFacadeService } from '@app/@shared/services/events/events-facade.service';
import { MatDialog } from '@angular/material/dialog';
import { SubtransactionalCuDialogComponent } from '@app/@shared/components/dynamic-form/subtransactional-cu/subtransactional-cu-dialog/subtransactional-cu-dialog.component';

@Component({
  selector: 'app-qr-scanner-control',
  templateUrl: './qr-scanner-control.component.html',
  styleUrls: ['./qr-scanner-control.component.scss'],
})
export class QrScannerControlComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;
  constructor(public dialog: MatDialog, private eventsService: EventsFacadeService) {}

  ngOnInit(): void {}

  openSubPopUp() {
    this.dialog.open(SubtransactionalCuDialogComponent, {
      width: '600px',
      height: '230px',
      data: {
        data: this.field,
      },
    });
  }
}

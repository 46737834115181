import { Injectable } from '@angular/core';
import { UploadEndpointService } from './upload-endpoint.service';
import { Subject } from 'rxjs';
import { AlertService } from '@app/@core/toaster/alert.service';
import { LoaderService } from '@app/@shared/services/loader/loader.service';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UploadFacadeService {
  private ngUnsubscribe = new Subject();
  allExtractedEntity = new Subject();
  allExtractedEntity$ = this.allExtractedEntity.asObservable();

  viewExtractedEntitySubject = new Subject();
  viewExtractedEntitySubject$ = this.viewExtractedEntitySubject.asObservable();

  deleteExtracedEntitySubject = new Subject();
  deleteExtracedEntitySubject$ = this.deleteExtracedEntitySubject.asObservable();

  constructor(
    private uploadEndpointService: UploadEndpointService,
    private alertService: AlertService,
    private loader: LoaderService
  ) {}
  // previousFiles: any;
  dynamicFormUploadFiles(files: any, fileLength: any) {
    if (fileLength == 1) {
      return this.uploadEndpointService.singleFileUpload(files);
    } else {
      return this.uploadEndpointService.multipleFileUpload(files);
    }
  }

  // previousFiles: any;
  importERDFile(files: any) {
    // uploading file and taking entities
    return this.uploadEndpointService.singleERDFileUpload(files);
  }
  saveImportedEntity(res: any) {
    // saving entities
    return this.uploadEndpointService.saveImportedERDEntity(res);
  }

  loadAllExtractedEntity(pageNumber: any, pageSize: any, searchString: any) {
    this.loader.show();
    this.uploadEndpointService
      .getAllExtracedEntity(pageNumber, pageSize, searchString)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.allExtractedEntity.next(res);
        if (!res) {
          this.alertService.showToaster('There are no matching records found', '', 'error');
        }
      });
  }

  getAllExtracedEntity(data: any) {
    this.allExtractedEntity.next(data);
  }

  deleteExtracedEntity(extractionId: number) {
    this.loader.show();
    this.uploadEndpointService
      .deleteExtractedEntity(extractionId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.alertService.showToaster(res.status, '', 'success');
        this.deleteExtracedEntitySubject.next(res);
      });
  }
  viewExtractedEntity(extractionId: number) {
    this.loader.show();
    this.uploadEndpointService
      .viewExtractedEntity(extractionId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.viewExtractedEntitySubject.next(res);
      });
  }

  getViewExtractedEntitySubject(data: any) {
    this.viewExtractedEntitySubject.next(data);
  }

  getDeleteExtracedEntitySubject(data: any) {
    this.deleteExtracedEntitySubject.next(data);
  }

  /**
   * Uploads base64 file
   * @param payload
   * @returns
   */
  uploadBase64File(payload: any) {
    return this.uploadEndpointService.uploadBase64File(payload);
  }

  /**
   * Updates base64 file
   * @param url
   * @param file
   * @returns
   */
  updateBase64File(url: any, file: any) {
    return this.uploadEndpointService.updateBase64File(url, file);
  }
}

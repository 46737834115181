import { Injectable, Injector, OnDestroy } from '@angular/core';
import { Observable, of, Subject, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Credentials, CredentialsService } from './credentials.service';
import { getLocalStorageValue, getEnvClientId, getTenantName } from '@app/@core/generic-functions';
import { AlertService } from '@app/@core/toaster/alert.service';
import { map, catchError, pluck } from 'rxjs/operators';
import { LoaderService } from '@app/@shared/services/loader/loader.service';
import { CduiBaseService } from './cdui-base.service';

export interface LoginContext {
  tenantName: string;
  userName: string;
  encryptedPassword: string;
  remember?: boolean;
  clientId?: string;
}

/**
 * Provides a base for authentication workflow.
 * The login/logout methods should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService extends CduiBaseService implements OnDestroy {
  isBrfLogin: boolean;
  isB2b2cLogin: boolean;
  onboardingTenant: any[] = ['selfonboard', 'systemintegrator', 'b2b', 'sdp', 'bdp', 'freelancer'];
  constructor(
    public injector: Injector,
    private credentialsService: CredentialsService,
    private http: HttpClient,
    private alertService: AlertService,
    private loaderService: LoaderService
  ) {
    super(injector);
  }

  /**
   * Authenticates the user.
   * @param context The login parameters.
   * @return The user credentials.
   */
  login(context: LoginContext, remember?: any, isOnboarding?: boolean): Observable<Object> {
    /* istanbul ignore next */
    return this.http.post(this.api?.tokenGenerateV2, context).pipe(
      map((res: any) => {
        if (res?.result && res?.status === 200) {
          const data: Credentials = {
            username: context.userName,
            token: res.result?.access_token,
            client_id: context?.clientId,
            refresh_token: res.result?.refresh_token,
            expires_in: res.result?.expires_in,
            last_loginTime: new Date(),
            tenant: context.tenantName,
          };
          if (isOnboarding) {
            this.credentialsService.setOnboardingCredentials(data, remember);
          } else {
            this.credentialsService.setCredentials(data, remember);
          }
        }
        return res;
        // return of(true);
      }),
      catchError((error: any) => {
        console.log('login error', error);
        return throwError(error);
      })
    );
  }

  /**
   * Logs out the user and clear credentials.
   * @return True if the user was logged out successfully.
   */
  logout(): any {
    const envClientId = getEnvClientId();
    const tenantName = getTenantName();
    /* istanbul ignore next */
    if (getLocalStorageValue('credentials')) {
      const token = JSON.parse(getLocalStorageValue('credentials'));
      const refresh_token: any = token.refresh_token;
      const payload = {
        tenantName: tenantName,
        clientId: envClientId,
        refreshToken: refresh_token,
      };
      this.loaderService.show();
      /* istanbul ignore next */
      return this.http.post(this.api?.logOut, payload).pipe(
        map(
          (res: any) => {
            this.loaderService.hide();
            this.credentialsService.setCredentials();
            return res;
          },
          catchError((error: any) => {
            this.alertService.showToaster('Logout Failed', '', 'error');
            return throwError(error);
          })
        )
      );
    }
  }

  getTenantList() {
    return this.http.get(this.api?.getAllTenants);
  }

  getTenantInfo(tenantName: any) {
    return this.http.get(this.api?.tenantInfo + `/${tenantName}`).pipe(pluck('result'));
  }

  getEvnByName(tenantName: any) {
    return this.http.get(`${this.api?.envByName}${tenantName}`).pipe(pluck('result'));
  }

  getIdentityProvidersList(tenantName: string) {
    return this.http.get(`${this.api?.getIdentityProvidersList}${tenantName}`);
  }

  /** API Call for Forgot Password Serice  */

  forgotPassword(urlParamsTenantName: any) {
    return this.http.put(this.api?.forgotPassword, urlParamsTenantName);
  }

  forgotCustomerId(payload: any) {
    return this.http.post(this.api?.forgotCustomerId, payload);
  }

  /** API Call for Generating OTP */

  generateOtp(urlParamsTenantName: any, mobile: any) {
    return this.http.get(this.api?.generateOTP + `${mobile}&tenantName=${urlParamsTenantName}`, urlParamsTenantName);
  }

  validateOTP(urlParamsTenantName: any, mobile: any, OTP: any) {
    return this.http.get(
      this.api?.validateOTP + `${OTP}&mobileNumber=${mobile}&tenantName=${urlParamsTenantName}`,
      urlParamsTenantName
    );
  }

  public getTestJSONData(): Observable<any> {
    return this.http.get('assets/testData.json');
  }

  /**
   * Sets b2b2c state
   * @param value
   */
  setB2B2Cstate(value: boolean) {
    this.isB2b2cLogin = value;
  }

  /**
   * Gets b2b2c state
   * @returns
   */
  getB2B2CState() {
    return this.isB2b2cLogin;
  }

  setBrfLoginState(value: boolean) {
    this.isBrfLogin = value;
  }

  getBrfLoginState() {
    return this.isBrfLogin;
  }

  selfSignup(payload: any) {
    if (this.onboardingTenant.includes(payload?.realm)) {
      return this.http.post(this.api?.selfonboardSignup, payload);
    } else {
      return this.http.post(this.api?.selfSignup, payload);
    }
  }
  fileOtpValidation(payload: any) {
    /*istanbul ignore next*/
    const otp = payload.otp ? payload.otp : '';
    /*istanbul ignore next*/
    const fileId = payload.fileId ? payload.fileId : '';
    return this.http.get(this.api?.fileOtpValidation + `?fileId=${fileId}&otp=${otp}`, {
      responseType: 'blob',
      observe: 'response',
    });
  }

  otpSignup(number: any, tenant: any) {
    return this.http.get(this.api?.selfSignupOtp + `?mobileNumber=${number}&tenantName=${tenant}`);
  }

  verifySignupOtp(number: any, tenant: any, selectedRole: any, otp: any) {
    return this.http.post(
      this.api?.verifySignupOtp + `?mobileNumber=${number}&otpNumber=${otp}&tenantName=${tenant}`,
      selectedRole
    );
  }
  ngOnDestroy() {}
}

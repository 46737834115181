import { Injectable } from '@angular/core';
import { A2sendpointService } from './a2a-endpoint.service';
import { AlertService } from '@app/@core/toaster/alert.service';
import { LoaderService } from '@app/@shared/services/loader/loader.service';
import { LibraryFacadeService } from '../library/library-facade.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { gsiMapper } from '@app/@core/mappers/gsi/gsi-mapper';

@Injectable({
  providedIn: 'root',
})
export class A2aFacadeService {
  constructor(
    private a2aEndpoint: A2sendpointService,
    private alertService: AlertService,
    private loader: LoaderService,
    private libraryFacadeService: LibraryFacadeService,
    private router: Router
  ) {}

  private ngUnsubscribe = new Subject();
  isExtractionVisible$ = new Subject();
  updateGsi$ = new Subject();

  public extractionListSubject = new Subject();
  extractionList$ = this.extractionListSubject.asObservable();

  extractedSolListSubject = new Subject();
  extractedSolList$ = this.extractedSolListSubject.asObservable();

  extractedSolListNodeSubject = new BehaviorSubject('');
  extractedSolListNode$ = this.extractedSolListNodeSubject.asObservable();

  public searchText = new BehaviorSubject('');
  searchText$ = this.searchText.asObservable();

  public extractedGsiListSubject = new BehaviorSubject(null);
  extractedGsiList$ = this.extractedGsiListSubject.asObservable();

  extractContent(payLoad: any) {
    this.loader.show();
    this.a2aEndpoint.processExtraction(payLoad).subscribe(
      (res: any) => {
        this.loader.hide();
        if (res.trackID) {
          this.updateGsi$.next(res);

          this.router.navigate(['/cdui/mylibrary-dashboard']);
        } else {
          this.isExtractionVisible$.next(false);
          this.libraryFacadeService.updateGSIdetails(res);
        }

        this.alertService.showToaster('Success', 'Extraction Started!', 'success');
      },
      (error) => {
        const { err } = error.message;
        this.loader.hide();
        this.alertService.showToaster(`${err}`, '', 'error');
      }
    );
  }

  getExtractionList(pageNumber: number, pageSize: number, searchText: string) {
    this.loader.show();
    this.a2aEndpoint
      .getExtractionList(pageNumber, pageSize, searchText)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.extractionListSubject.next(res);
      });
  }

  getExtractedSolutionList(trackId: any, canvas: string) {
    this.loader.show();
    this.a2aEndpoint
      .getExtractedSolutionList(trackId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        /* istanbul ignore next */
        if (res?.status == 'COMPLETED') {
          if (canvas == 'hybrid') {
            this.extractedSolListSubject.next(res);
          } else if (canvas == 'node') {
            let data = gsiMapper(res.data);
            this.extractedSolListNodeSubject.next(data);
            this.router.navigateByUrl('/canvas');
          }
        } else {
          /* istanbul ignore next */
          this.alertService.showToaster('GSIs are not yet completed', '', 'info');
        }
      });
  }

  setExtractedSolutionList(data: any) {
    this.extractedGsiListSubject.next(data);
  }

  setSearchText(text: string) {
    this.searchText.next(text);
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringReplace',
})
export class StrReplacePipe implements PipeTransform {
  transform(value: any): any {
    if (value && value !== null && value !== undefined) {
      return value.toString().replace(/[^A-Za-z0-9]/g, '_');
    }
  }
}

import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { LoaderService } from '../services/loader/loader.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TranslatorService } from '@app/shell/translator.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit, OnDestroy {
  @Input() isLoading = false;
  @Input() message: string | undefined = '';
  ngUnsubscribe = new Subject();
  loaderData: any = [];
  labels: any;
  // count =0;
  constructor(private loaderService: LoaderService, private translator: TranslatorService) {
    this.detectLanguageChange();
    this.detectChange();
  }

  ngOnInit() {}

  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }

  detectChange() {
    this.loaderService.loader$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      const { showLoader, message }: any = res;
      if (showLoader === true) {
        // this.count++;
        this.loaderData.push(showLoader);
      } else {
        // if(this.count>0){
        //   this.count--;
        // }
        this.loaderData.pop();
      }
      // console.warn(this.count);
      // console.trace();
      this.isLoading = this.loaderData.length > 0 ? true : false;
      this.message = message;
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}

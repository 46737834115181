import { Injectable, OnDestroy } from '@angular/core';
import { SolutionVersioningService } from './solution-versioning-endpoint.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoaderService } from '@app/@shared/services/loader/loader.service';
import { AlertService } from '@app/@core/toaster/alert.service';
import { entityDeMapper } from '@app/@core/mappers/entity/entity-demapper';
import { changeUnitDeMapper } from '@app/@core/mappers/change-unit/cu-demapper';
import { gsiDeMapper } from '@app/@core/mappers/gsi/gsi-demapper';
import { changeUnitMapper } from '@app/@core/mappers/change-unit/cu-mapper';
import { gsiMapper } from '@app/@core/mappers/gsi/gsi-mapper';
import { entityMapper } from '@app/@core/mappers/entity/entity-mapper';
import { Router } from '@angular/router';
import { ChangeUnitFacadeService } from '../change-unit/change-unit-facade.service';
import { EntityFacadeService } from '../entity/entity-facade.service';
import { LibraryFacadeService } from '../library/library-facade.service';
import { NewSolutionFacadeService } from '@app/create/create-solution-facade.service';
import { OCRFacadeService } from '@app/@shared/services/ocr/ocr-facade.service';
@Injectable({
  providedIn: 'root',
})
export class SolutionVersioningFacadeService implements OnDestroy {
  private ngUnsubscribe = new Subject();
  private versionsList = new Subject();
  getVersionsList$ = this.versionsList.asObservable();

  public versionsDiff = new Subject();
  getVersionsDiff$ = this.versionsDiff.asObservable();

  private onfinsih = new Subject();
  onFinishRes$ = this.onfinsih.asObservable();

  /* versioning */
  reviewersListSubject = new Subject();
  reviewersList$ = this.reviewersListSubject.asObservable();
  public screenTypeSubject = new Subject();
  screenType$ = this.screenTypeSubject.asObservable();

  // private postnotifications = new Subject();
  // postNotifications$ = this.postnotifications.asObservable();

  postFilterNotifications = new Subject();
  postFilterNotifications$ = this.postFilterNotifications.asObservable();

  finishSubject = new BehaviorSubject(null);
  finish$ = this.finishSubject.asObservable();

  finishNslSubject = new BehaviorSubject(null);
  finishNsl$ = this.finishNslSubject.asObservable();

  private publishSubject = new BehaviorSubject(null);
  publish$ = this.publishSubject.asObservable();

  finishNotificationSubject = new BehaviorSubject(null);
  finishNotification$ = this.finishNotificationSubject.asObservable();

  selectSubmitterIdSubject = new BehaviorSubject(null);
  selectSubmitterId$ = this.selectSubmitterIdSubject.asObservable();

  approveOrRejectSubject = new Subject();
  approveOrReject$ = this.approveOrRejectSubject.asObservable();

  pendingReviewExists = new Subject();
  pendingReviewExists$ = this.pendingReviewExists.asObservable();

  reviewStatus = new Subject();
  reviewStatus$ = this.reviewStatus.asObservable();

  reviewCancelled = new Subject();
  reviewCancelled$ = this.reviewCancelled.asObservable();
  /* ./versioning */

  constructor(
    private versioningFacadeEndPoint: SolutionVersioningService,
    private loader: LoaderService,
    private alertService: AlertService,
    private router: Router,
    private changeUnitFacadeService: ChangeUnitFacadeService,
    private entityFacadeService: EntityFacadeService,
    private libraryFacadeService: LibraryFacadeService,
    private newSolutionService: NewSolutionFacadeService,
    private ocrFacadeService: OCRFacadeService
  ) {}

  ngOnDestroy() {}

  loadSolutionVErsions(data: any) {
    this.loader.show();
    this.versioningFacadeEndPoint
      .versionsList(data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        if (res) {
          this.loader.hide();
          this.versionsList.next(res);
        }
      });
  }

  postSolutionDiff(data: any, type?: any) {
    this.loader.show();
    this.versioningFacadeEndPoint
      .solutionDiffApi(data, type)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        if (res) {
          this.loader.hide();
          this.versionsDiff.next(res);
        }
      });
  }

  publishSolution(gsiID: any) {
    this.loader.show();
    this.versioningFacadeEndPoint
      .gsiPublished(gsiID)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        if (res) {
          this.loader.hide();
          console.log(res);
        }
      });
  }

  finishSolution(gsiID: any) {
    this.loader.show();
    this.versioningFacadeEndPoint
      .gsiFinished(gsiID)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        if (res) {
          this.loader.hide();
          this.onfinsih.next(res);
        }
      });
  }

  /* versioning */
  finish(res: any, finishType?: any, isFlat?: any) {
    this.loader.show();
    let data;
    if (finishType === 'gsi') {
      data = gsiDeMapper(res);
      if (data.layers?.length === 0) {
        data.layers = null;
      }
    } else {
      data = finishType === 'cu' ? changeUnitDeMapper(res) : entityDeMapper(res);
    }

    this.versioningFacadeEndPoint
      .finish(data, finishType)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (result: any) => {
          this.loader.hide();

          this.alertService.showToaster(result?.message, '', 'success');

          const info = { ...result?.result, finishType: finishType };
          let response;
          if (finishType === 'gsi') {
            response = gsiMapper(info);
          } else {
            response = finishType === 'cu' ? changeUnitMapper(info) : entityMapper(info);
          }
          if (isFlat) {
            response.isFlat = true;
          } else {
            response.hybrid = true;
          }

          this.finishSubject.next(response);
        },
        (error: any) => {
          this.loader.hide();
        }
      );
  }

  /* versioning nsl */
  finishNsl(res: any, finishType?: any, isFlat?: any) {
    this.loader.show();
    let data;
    if (finishType === 'gsi') {
      data = gsiDeMapper(res);
      if (data.layers?.length === 0) {
        data.layers = null;
      }
    } else {
      data = finishType === 'cu' ? changeUnitDeMapper(res) : entityDeMapper(res);
    }

    this.versioningFacadeEndPoint
      .finish(data, finishType)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (result: any) => {
          this.loader.hide();

          this.alertService.showToaster(result?.message, '', 'success');

          const info = { ...result?.result, finishType: finishType };
          let response;
          if (finishType === 'gsi') {
            response = gsiMapper(info);
          } else {
            response = finishType === 'cu' ? changeUnitMapper(info) : entityMapper(info);
          }
          if (isFlat) {
            response.isFlat = true;
          } else {
            response.hybrid = true;
          }

          this.finishNslSubject.next(response);
        },
        (error: any) => {
          this.loader.hide();
        }
      );
  }

  getVersion(data: any) {
    this.finishSubject.next(data);
  }
  publish(res: any, finishType?: any, skipReview?: any, isb2c?: any) {
    this.loader.show();
    let data;
    if (finishType === 'gsi') {
      data = gsiDeMapper(res);
    } else {
      data = finishType === 'cu' ? changeUnitDeMapper(res) : entityDeMapper(res);
    }
    this.versioningFacadeEndPoint
      ?.publish(data, finishType, skipReview)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (response: any) => {
          this.loader.hide();
          this.newSolutionService.setAlertPopUpState(true);
          if (isb2c == null) {
            this.redirectionAfterPublish(finishType);
          }
          this.publishSubject.next(res);
          this.alertService.showToaster(response.message, '', 'success');
        },
        (error: any) => {
          this.loader.hide();
          this.publishSubject.next('reset');
        }
      );
  }

  redirectionAfterPublish(finishType: any) {
    const resumeTransaction = localStorage.getItem('resumeTransactionUrl');
    if (resumeTransaction && finishType === 'gsi') {
      this.router.navigateByUrl(`/transaction/view/${resumeTransaction}`);
    } else {
      this.router.navigateByUrl(`/mylibrary-dashboard`);
    }
  }

  getFinishData(res: any) {
    this.finishSubject.next(res);
  }

  getfinishNslData(res: any) {
    this.finishNslSubject.next(res);
  }

  resetFinishData() {
    this.finishSubject.next(null);
  }

  getFinishDataForNotification(res: any) {
    this.finishNotificationSubject.next(res);
  }

  loadReviewers(id: any, finishType?: any, pageNo?: number, pageSize?: number, inputReviewer?: string) {
    this.loader.show();
    if (inputReviewer) {
      this.versioningFacadeEndPoint
        .getReviewers(id, finishType, pageNo, pageSize, inputReviewer)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res) => {
          if (res) {
            this.loader.hide();
            this.reviewersListSubject.next(res);
          }
        });
    } else {
      this.versioningFacadeEndPoint
        .getReviewers(id, finishType, pageNo, pageSize)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res) => {
          if (res) {
            this.loader.hide();
            this.reviewersListSubject.next(res);
          }
        });
    }
  }

  sendNotifications(data: any) {
    this.loader.show();
    this.versioningFacadeEndPoint
      .sendNotifications(data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          /* istanbul ignore next */
          this.alertService.showToaster(res?.message, '', 'success');
          this.newSolutionService.setAlertPopUpState(true);
          this.router.navigateByUrl(`/mylibrary-dashboard`);
        },
        (err: any) => {
          this.loader.hide();
        }
      );
  }

  approveOrRejectBET(data: any, type: any, betType: any, comment: any) {
    this.loader.show();
    this.versioningFacadeEndPoint
      .approveOrRejectBET(data, type, betType, comment)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        /* istanbul ignore next */
        this.alertService.showToaster(res?.message, '', 'success');
        this.approveOrRejectSubject.next(res);
      });
  }

  getScreenType(type: any) {
    this.screenTypeSubject.next(type);
  }

  sendFilterNotifications() {
    this.loader.show();
    this.versioningFacadeEndPoint
      .sendfilterNotification()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        if (res) {
          this.loader.hide();
          this.postFilterNotifications.next(res);
        }
      });
  }

  setSelectedSubmitterId(id: any) {
    this.selectSubmitterIdSubject.next(id);
  }

  editPublishedBET(id: any, betType: any, CuIndex?: number) {
    this.loader.show();
    this.versioningFacadeEndPoint
      .editPublishedBET(id, betType)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        if (res) {
          this.loader.hide();
          if (betType == 'gsi') {
            /* istanbul ignore next */
            this.libraryFacadeService.fetchGSIdetails(res?.result);
          } else if (betType == 'change-unit') {
            /* istanbul ignore next */
            let updatedData = { ...res?.result, oldVersionId: id, index: CuIndex };
            this.changeUnitFacadeService.updateDraftCU(updatedData);
            /* istanbul ignore next */
            if (updatedData?.uiProperties?.cdecId) {
              this.ocrFacadeService.callSetMappings(updatedData?.uiProperties?.cdecId);
            }
            // this.libraryFacadeService.cuSourcefetch(res?.result);
          } else {
            /* istanbul ignore next */

            this.entityFacadeService.getEntityDetails(res?.result);
          }
        }
        /* istanbul ignore next */
        this.alertService.showToaster(res?.message, '', 'success');
      });
  }

  detectReviewInfo(data: any) {
    this.loader.show();
    this.versioningFacadeEndPoint
      .detectReviewInfo(data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        if (res) {
          this.pendingReviewExists.next(res.result);
        }
      });
  }
  showReviewStatus(data: any) {
    this.versioningFacadeEndPoint
      .showReviewStatus(data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        if (res && res.result) {
          this.reviewStatus.next(res.result);
        }
      });
  }
  cancelReview(data: any, betType: any) {
    let cancelData;
    if (betType === 'gsi') {
      cancelData = gsiDeMapper(data);
    } else {
      cancelData = betType === 'cu' ? changeUnitDeMapper(data) : entityDeMapper(data);
    }
    if (betType === 'cu') {
      betType = 'change-unit';
    }
    this.loader.show();
    this.versioningFacadeEndPoint
      .cancelReview(cancelData, betType)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        if (res.result) {
          this.reviewCancelled.next(res.result);
        }
      });
  }
  /* ./versioning */
}

import { Injector, Pipe, PipeTransform } from '@angular/core';
import { CduiBaseComponent } from '@app/cdui-base.component';

@Pipe({
  name: 'CDNUrlRewritePipe',
})
export class CdnUrlRewritePipe extends CduiBaseComponent implements PipeTransform {
  cdnUrl: string = '';
  assetsPath: string = '/assets';
  constructor(private injector: Injector) {
    super(injector);
  }
  transform(value: any): unknown {
    /*istanbul ignore next */
    if (this.environment && this.environment.cdnUrl) {
      this.cdnUrl = this.environment.cdnUrl + this.assetsPath;
    }
    if (this.cdnUrl && value && value.indexOf('assets') > -1) {
      const folderPath: any[] = value.split('assets');
      return this.cdnUrl + folderPath[1];
    }
    return value;
  }
}

import { Component, ElementRef, Injector, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SafeHtml } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { GlobalSearchTypeaheadFacadeService } from '@app/@shared/services/global-search-typeahead/global-search-typeahead-facade.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslatorService } from '@app/shell/translator.service';
import { environment } from '@env/environment';
import { TenantCustomUiFacadeService } from '@app/tenant-cus/services/tenant-custom-ui-facade.service';
import { CduiBaseComponent } from '@app/cdui-base.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent extends CduiBaseComponent implements OnInit {
  @ViewChild('myDialog') dialogTemplate: TemplateRef<any>;
  @ViewChild('toggleButton') toggleButton: ElementRef;
  @ViewChild('globl-search-panel') menu: ElementRef;
  labels: any;
  searchTextAutocomplete: string = '';
  isGSI: boolean = true;
  isCU: boolean = true;
  isEntity: boolean = true;
  isAttribute: boolean = true;
  isAll: boolean = false;
  showGlobalSearchResult: boolean = false;
  globalSearch: boolean = false;
  isSearchMenuOpen: boolean = false;
  searchOptionSelected: boolean = false;
  globalGoogleSearchBar = false;

  autoCompleteData: any[] = [];
  searchGSIdata: any;
  searchCUdata: any;
  searchEntitydata: any;
  searchAttributedata: any;
  autoGSIdata: any;
  autoCUdata: any;
  autoEntitydata: any;
  autoAttributedata: any;
  googleResult: any;
  googleImageResult: any;
  safeHTMLtitle: SafeHtml;
  safeHTMLlink: SafeHtml;
  safeHTMLsnippet: SafeHtml;
  cloudLogo = '../../assets/images/cloud.png';
  googleLogo = './../../../assets/images/google.png';
  book1Logo = '../../assets/images/book1.png';
  private ngUnsubscribe = new Subject();

  constructor(
    private injector: Injector,
    private router: Router,
    public dialog: MatDialog,
    private tenantCustomUiFacadeService: TenantCustomUiFacadeService,
    private globalSearchFacadeService: GlobalSearchTypeaheadFacadeService,
    public translator: TranslatorService
  ) {
    super(injector);
    this.detectLanguageChange();
    this.adminHeadersettings();
  }

  ngOnInit(): void {
    this.addingCDNURL(this.cloudLogo);
    this.addingCDNURL(this.googleLogo);
    this.addingCDNURL(this.book1Logo);
  }

  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.labels = res;
    });
  }

  addingCDNURL(staticPath: any) {
    /*istanbul ignore next */
    if (this.environment.cdnUrl) {
      let staticUrl = staticPath.split('assets');
      if (staticPath.includes('cloud')) {
        this.cloudLogo = this.environment.cdnUrl + '/assets' + staticUrl[1];
      } else if (staticPath.includes('google')) {
        this.googleLogo = this.environment.cdnUrl + '/assets' + staticUrl[1];
      } else if (staticPath.includes('book1')) {
        this.book1Logo = this.environment.cdnUrl + '/assets' + staticUrl[1];
      }
    }
  }
  /*opens dialog box on Google search */
  openDialog(templateRef: TemplateRef<any>) {
    this.googleSearch();
    this.dialog.closeAll();
    this.dialog.open(templateRef, { width: '900px' });
  }

  adminHeadersettings() {
    let tname = localStorage.getItem('TenantName');
    /* istanbul ignore else */
    if (tname !== null) {
      this.tenantCustomUiFacadeService.fetchTenantData(tname);
    }
  }

  /*changes tab to Google or Global search*/
  onChangeTab(event: any) {
    console.log(event);
    const tab = event.tab.textLabel;
    if (tab === 'All') {
      this.googleSearch();
    } else {
      this.imageGoogle();
    }
  }

  /*On click opens a new tab*/
  goToLink(url: string) {
    window.open(url, '_blank');
  }

  /*selects Google dialog box or Global search*/
  searchOnSelect() {
    if (!this.searchOptionSelected) {
      this.openDialog(this.dialogTemplate);
    } else {
      this.getGlobalSearch();
    }
  }
  /*API call to google web search*/
  googleSearch() {
    this.globalSearchFacadeService.googleApi(this.searchTextAutocomplete);
    this.globalSearchFacadeService.googleData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /*istanbul ignore else */
      if (res) {
        this.googleResult = res;
      }
    });
  }
  /*API call to Global image search*/
  imageGoogle() {
    this.globalSearchFacadeService.googleImageApi(this.searchTextAutocomplete);
    this.globalSearchFacadeService.googleImageData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /*istanbul ignore else */
      if (res) {
        this.googleImageResult = res;
      }
    });
  }
  /*API call for autocomplete result*/
  getAutoCompleteSearch() {
    this.isSearchMenuOpen = true;
    /*istanbul ignore else */
    // if (this.isGSI) {
    //   this.autoGSIdata = [];
    //   this.globalSearchFacadeService.getAutoCompleteSearch(this.searchTextAutocomplete, 'GSI');
    //   this.globalSearchFacadeService.autocompleteData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
    //     /*istanbul ignore next */
    //     if (res && res?.result && res?.result[0]?.type === 'GSI') {
    //       this.autoGSIdata = res.result;
    //     }
    //   });
    // }
    // /*istanbul ignore else */
    // if (this.isCU) {
    //   this.autoCUdata = [];
    //   this.globalSearchFacadeService.getAutoCompleteSearch(this.searchTextAutocomplete, 'CHANGE_UNIT');
    //   this.globalSearchFacadeService.autocompleteData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
    //     /*istanbul ignore next */
    //     if (res && res?.result && res?.result[0]?.type === 'CHANGE_UNIT') {
    //       this.autoCUdata = res.result;
    //     }
    //   });
    // }
    // /*istanbul ignore else */
    // if (this.isEntity) {
    //   this.autoEntitydata = [];
    //   this.globalSearchFacadeService.getAutoCompleteSearch(this.searchTextAutocomplete, 'ENTITY');
    //   this.globalSearchFacadeService.autocompleteData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
    //     /*istanbul ignore next */
    //     if (res && res?.result && res?.result[0]?.type === 'ENTITY') {
    //       this.autoEntitydata = res.result;
    //     }
    //   });
    // }
    // /*istanbul ignore else */
    // if (this.isAttribute) {
    //   this.autoAttributedata = [];
    //   this.globalSearchFacadeService.getAutoCompleteSearch(this.searchTextAutocomplete, 'ATTRIBUTE');
    //   this.globalSearchFacadeService.autocompleteData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
    //     /*istanbul ignore next */
    //     if (res && res?.result && res?.result[0]?.type === 'ATTRIBUTE') {
    //       this.autoAttributedata = res.result;
    //     }
    //   });
    // }
  }
  /*API call for global search result*/
  getGlobalSearch() {
    this.isSearchMenuOpen = false;
    /*istanbul ignore else */
    // if (this.isGSI) {
    //   this.globalSearchFacadeService.getGlobalSearch(this.searchTextAutocomplete, 'GSI');
    //   this.globalSearchFacadeService.searchData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
    //     /*istanbul ignore next */
    //     if (res && res?.result && res?.result?.type === 'GSI') {
    //       this.searchGSIdata = res.result;
    //     }
    //   });
    // }
    // /*istanbul ignore else */
    // if (this.isCU) {
    //   this.globalSearchFacadeService.getGlobalSearch(this.searchTextAutocomplete, 'CHANGE_UNIT');
    //   this.globalSearchFacadeService.searchData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
    //     /*istanbul ignore next */
    //     if (res && res?.result && res?.result?.type === 'CHANGE_UNIT') {
    //       this.searchCUdata = res.result;
    //     }
    //   });
    // }
    // /*istanbul ignore else */
    // if (this.isEntity) {
    //   this.globalSearchFacadeService.getGlobalSearch(this.searchTextAutocomplete, 'ENTITY');
    //   this.globalSearchFacadeService.searchData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
    //     /*istanbul ignore next */
    //     if (res && res?.result && res?.result?.type === 'ENTITY') {
    //       this.searchEntitydata = res.result;
    //     }
    //   });
    // }
    // /*istanbul ignore else */
    // if (this.isAttribute) {
    //   this.globalSearchFacadeService.getGlobalSearch(this.searchTextAutocomplete, 'ATTRIBUTE');
    //   this.globalSearchFacadeService.searchData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
    //     /*istanbul ignore next */
    //     if (res && res?.result && res?.result?.type === 'ATTRIBUTE') {
    //       this.searchAttributedata = res.result;
    //     }
    //   });
    // }
    this.globalSearch = true;
  }

  /*opens when clicked on All button*/
  searchAll() {
    this.isAll = true;
    /*istanbul ignore else */
    if (this.isAll) {
      this.isGSI = true;
      this.isCU = true;
      this.isEntity = true;
      this.isAttribute = true;
      //this.getAutoCompleteSearch();
    }
  }
  /*opens when clicked on GSI button*/
  searchGSI() {
    this.isGSI = !this.isGSI;
    if (!this.isGSI) {
      this.autoGSIdata = [];
      this.isAll = false;
    } else {
      //this.getAutoCompleteSearch();
    }
  }
  /*opens when clicked on CU button*/
  searchCU() {
    this.isCU = !this.isCU;
    if (!this.isCU) {
      this.autoCUdata = [];
      this.isAll = false;
    } else {
      //this.getAutoCompleteSearch();
    }
  }
  /*opens when clicked on Entity button*/
  searchEntity() {
    this.isEntity = !this.isEntity;
    if (!this.isEntity) {
      this.autoEntitydata = [];
      this.isAll = false;
    } else {
      //this.getAutoCompleteSearch();
    }
  }
  /*opens when clicked on attribute button*/
  searchAttribute() {
    this.isAttribute = !this.isAttribute;
    if (!this.isAttribute) {
      this.autoAttributedata = [];
      this.isAll = false;
    } else {
      this.getAutoCompleteSearch();
    }
  }
  /*clears all global search text box*/
  clearSearch() {
    this.searchTextAutocomplete = '';
    this.isSearchMenuOpen = false;
    this.isAll = true;
    this.isGSI = true;
    this.isCU = true;
    this.isEntity = true;
    this.isAttribute = true;
  }
  /*updates result on tab change */
  updateSearchData(data: any) {
    /*istanbul ignore next */
    this.searchTextAutocomplete = data?.name;
    //this.getGlobalSearch();
  }
}

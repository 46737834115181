import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { ChatBotIcons } from '@app/@shared/enums/chatbot-icons.enums';
import { IFeedback, Registration } from '@app/@shared/services/livechat/livechat-endpoint.service';
import { LivechatFacadeService } from '@app/@shared/services/livechat/livechat-facade.service';
import { RecordingFacadeService } from '@app/@shared/services/recording/recording-facade.service';
import { ManageConfigurationFacadeService } from '@app/admin/services/manage-configuration/manage-configuration-facade.service';
import { UsersFacadeService } from '@app/admin/services/users/users-facade.service';
import { TranslatorService } from '@app/shell/translator.service';
import { post } from 'cypress/types/jquery';
import { Guid } from 'guid-typescript';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import Speech from 'speak-tts'; // es6
import { ChatBotMediaDialog, MediaTypes } from '../chatbot-smart/chatbot-smart.component';

@Component({
  selector: 'app-livechat-smart',
  templateUrl: './livechat-smart.component.html',
  styleUrls: ['./livechat-smart.component.scss'],
})
export class LivechatSmartComponent implements OnInit, OnChanges {
  isShown: boolean = false;
  labels: any;
  ngUnsubscribe: Subject<any> = new Subject();
  @Input() iconType: string;
  chatBotIcons = ChatBotIcons;
  messageList: any[] = [];
  question: any;
  token: any;
  jwtToken: string;
  userInfo: any;
  roomId: any;
  updatedChatData: any;
  openPrompt: boolean = false;
  registered: boolean = false;
  mediaTypes = MediaTypes;
  speech = new Speech();
  isAudio: boolean = false;
  @ViewChild('videoPlayerMini') videoPlayerMini: ElementRef;
  @ViewChild('target') target: ElementRef;
  @ViewChild('scroll') scrollElement: ElementRef;
  feedback: IFeedback = {
    token: '',
    roomId: '',
    rating: 0,
    comments: '',
    jwtToken: '',
  };
  toggleFeedback: boolean = false;
  comments: string = '';
  unreadMessages: number = 0;
  isRecordingEnabled: boolean = false;
  moreTopicsButton: boolean = false;
  notListedButton: boolean = false;
  emojiFeedbackFlag: boolean = false;
  emojiePickerFlag: boolean = false;
  botName: string = 'Kia';
  setWelcome: boolean = true;
  isOnboarding: any;

  constructor(
    private translator: TranslatorService,
    private livechatFacade: LivechatFacadeService,
    public dialog: MatDialog,
    private recordingFacadeService: RecordingFacadeService,
    private manageconfigurationservice: ManageConfigurationFacadeService,
    private router: Router
  ) {
    this.detectLastChatData();
    this.detectBlobFileForRecording();
    this.recordingFacadeService.voiceToTextInit();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.fetchDetails();
    this.setDepartment();
  }

  ngOnInit(): void {
    this.detectLanguageChange();
    this.getCredentials();
    this.updatedChatData = {
      messagesList: [],
    };
    /*istanbul ignore next*/
    this.isOnboarding = localStorage.getItem('onBoarding') == 'true' ? true : false;
  }

  getCredentials() {
    const credentials = JSON.parse(localStorage.getItem('credentials'));
    /*istanbul ignore next*/
    this.jwtToken = credentials?.token;
    let data = this.decodeJwtoken(this.jwtToken);
    console.log(data);
    /*istanbul ignore next*/
    const userInfo = {
      name: data?.data?.name ? data?.data?.name : data?.data?.email,
      email: data?.data?.email,
    };
    this.userInfo = userInfo;
  }

  detectLastChatData() {
    this.livechatFacade.updatedChatData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      console.log(res);
      /*istanbul ignore next*/
      if (res) {
        this.updatedChatData = res;
        this.updatedChatData?.messagesList?.forEach((data: any) => {
          if (data?.answer?.read == false) {
            this.unreadMessages++;
          }
        });
        this.textToSpeech();
      }
    });
  }

  connect(flag?: string) {
    this.roomId = Guid.create();
    this.token = Guid.create();
    /*istanbul ignore next*/
    this.livechatFacade.connect(this.token?.value, this.roomId, this.userInfo, this.jwtToken, flag);
    this.registered = true;
  }

  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }

  toggleShow() {
    this.isShown = !this.isShown;
    this.livechatFacade.changeIsShown(this.isShown);
    /*istanbul ignore else*/
    if (this.isShown === true) {
      this.livechatFacade.resumeChatbotState();
      this.unreadMessages = 0;
      /*istanbul ignore next*/
      this.updatedChatData?.messagesList?.forEach((data: any) => {
        if (data?.answer) {
          data.answer.read = true;
        } else if (data?.question) {
          data.question.read = true;
        }
      });
    }
    /*istanbul ignore next*/
    if (this.updatedChatData?.messagesList?.length === 0 && this.registered === false) {
      this.connect();
    }
  }

  sendQuestions(el?: HTMLElement) {
    /*istanbul ignore next*/
    if (el !== undefined) {
      el.scrollIntoView();
    }
    /*istanbul ignore next*/
    this.livechatFacade.send(this.token?.value, this.roomId, this.question);
    this.question = '';
  }

  endChat() {
    /*istanbul ignore next*/
    if (this.updatedChatData?.messagesList?.length === 0) {
      this.toggleShow();
      return;
    }
    this.openPrompt = true;
    /*istanbul ignore next*/
    this.livechatFacade.disconnect(this.token?.value, this.roomId);
  }

  sendTranscript(flag: boolean, el?: HTMLElement) {
    /*istanbul ignore next*/
    if (el !== undefined) {
      el.scrollIntoView();
    }
    /*istanbul ignore else*/
    if (flag) {
      this.livechatFacade.transcript(this.token?.value, this.roomId, this.userInfo);
    }
    this.livechatFacade.resetStore();
    this.toggleFeedback = true;
    this.openPrompt = false;
    this.registered = false;
  }

  /*istanbul ignore next*/
  enableAudio() {
    this.isAudio = !this.isAudio;
    if (this.isAudio === false) {
      this.speech.cancel();
    }
  }

  textToSpeech() {
    /*istanbul ignore else*/
    if (this.isAudio) {
      /*istanbul ignore next*/
      const lastElement = this.updatedChatData?.messagesList[this.updatedChatData?.messagesList?.length - 1];
      let messageToSpeak;

      /*istanbul ignore next*/
      if (lastElement?.answer) {
        if (lastElement?.answer?.response_type === 'text') {
          messageToSpeak = lastElement?.answer?.ans?.text;
        } /*istanbul ignore else*/ else if (lastElement?.answer?.response_type === 'suggest') {
          messageToSpeak = 'We got Suggestions for you ';
        }
      }
      console.log(messageToSpeak, this.speech.init());

      /*istanbul ignore else*/
      if (messageToSpeak) {
        this.speech.speak({
          text: messageToSpeak,
          queue: false,
        });
      }
    }
  }

  /*istanbul ignore next*/
  openMediaDialog(typeOfMedia: string, sourceUrl: string) {
    if (typeOfMedia === this.mediaTypes.Video) {
      this.videoPlayerMini.nativeElement.pause();
    }
    this.speech.cancel();

    const dialogRef = this.dialog.open(ChatBotMediaDialog, {
      width: '1000px',
      height: '650px',
      data: { typeOfMedia: typeOfMedia, src: sourceUrl },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      console.log('The dialog was closed');
    });
  }

  sendButtonAction(button: IButton) {
    if (button.displayText.includes('Rephrase')) {
      return;
    }
    this.question = button.value;
    this.sendQuestions();
  }

  /*istanbul ignore next*/
  makeCall(data: any) {
    // let src = this.domSanitizer.bypassSecurityTrustResourceUrl(data.url);
    window.open(data?.url);
    // let conCall: VideoConData = {
    //   type: 'video_conference',
    //   src: src,
    // };
    // const videoCall = this.dialog.open(VideoConferencingDialogBox, {
    //   width: '100%',
    //   height: '100%',
    //   maxWidth: '1280px',
    //   maxHeight: '720px',
    //   data: conCall,
    // });
    // videoCall.afterClosed().subscribe((res: any) => {
    //   console.log(res, 'dialog closed');
    // });
  }

  ratingAtSessionLevel(rating: number) {
    this.feedback.rating = rating;
  }

  sendFeedback(flag: boolean) {
    if (flag === true) {
      this.feedback.comments = this.comments;
      /*istanbul ignore next*/
      this.feedback.token = this.token?.value;
      /*istanbul ignore next*/
      this.feedback.roomId = this.roomId?.value;
      this.feedback.jwtToken = this.jwtToken;
      this.livechatFacade.feedBack(this.feedback);
    } else {
      this.comments = '';
      this.feedback.comments = this.comments;
      /*istanbul ignore next*/
      this.feedback.token = this.token?.value;
      /*istanbul ignore next*/
      this.feedback.roomId = this.roomId?.value;
      this.feedback.jwtToken = this.jwtToken;
      this.livechatFacade.feedBack(this.feedback);
    }
    this.comments = '';
    console.log(this.feedback, this.isShown, this.toggleFeedback);
    this.toggleFeedback = false;
    this.toggleShow();
    console.log(this.feedback, this.isShown, this.toggleFeedback);
  }

  /*istanbul ignore next*/
  // async toggleRecording() {
  //   if (this.isRecordingEnabled === false) {
  //     this.recordingFacadeService.startRecording();
  //     this.isRecordingEnabled = true;
  //   } else if (this.isRecordingEnabled === true) {
  //     this.recordingFacadeService.stopRecording();
  //     this.isRecordingEnabled = false;
  //   }
  // }

  /*istanbul ignore next*/
  detectBlobFileForRecording() {
    this.recordingFacadeService.getRecordedBlob().subscribe((blob: any) => {
      if (blob) {
        let blobFileUrl = URL.createObjectURL(blob);
        let reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          var base64Data = reader.result;
          console.log(base64Data);
        };
      }
    });
  }

  addEmoji(event: any) {
    /*istanbul ignore next*/
    this.question = `${this.question}${event?.emoji?.native}`;
    this.emojiePickerFlag = false;
  }

  fileUpload(files: FileList) {
    // this.selectedFiles=[];
    // if(files.length===0)return;
    // for(let i=0;i<files.length;i++){
    //   this.selectedFiles.push(files[i]);
    // }
    // if(!this.selectedFiles || this.selectedFiles.length===0)return;
    let newFormData = new FormData();
    //this.selectedFiles.forEach((f)=>newFormData.append('file', f));
    newFormData.append('file', files[0]);
    /*istanbul ignore next*/
    this.livechatFacade.fileUpload(newFormData, this.roomId?.value);
    // .pipe(takeUntil(this.ngUnsubscribe))
    // .subscribe((event: any) => {
    //   console.log(event);
    // });
  }

  filedownload(fileUrl: string) {
    // console.log(fileUrl);
    this.livechatFacade.fileDownload(fileUrl);
  }

  record() {
    if (!this.isRecordingEnabled) {
      this.isRecordingEnabled = true;
      this.startVoiceToText();
    } else {
      this.isRecordingEnabled = false;
      this.stopVoiceToText();
    }
  }

  startVoiceToText() {
    this.recordingFacadeService.startVoiceToText();
  }

  stopVoiceToText() {
    this.recordingFacadeService.stopVoiceToText();
    this.question = this.recordingFacadeService?.tempWords;
    this.recordingFacadeService.tempWords = '';
    this.sendQuestions();
  }

  ngOnDestroy() {
    this.livechatFacade.disconnect(this.token?.value, this.roomId);
  }

  clickedTopics(action: string) {
    this.question = action;
    if (action === 'more topics') {
      this.sendQuestions();
      this.moreTopicsButton = true;
    } else if (action === 'not listed') {
      this.sendQuestions();
      this.notListedButton = true;
    }
  }

  decodeJwtoken(token: string) {
    try {
      if (!token) {
        throw new Error('no token');
      }
      const base64Payload = token.split('.')[1];
      let payload = '';
      try {
        payload = atob(base64Payload);
      } catch (error) {
        /* istanbul ignore next */
        throw new Error('no decoded');
      }
      return {
        data: JSON.parse(payload.toString()),
      };
    } catch (error) {
      console.log(error);
      return {
        error: 'not decoded',
      };
    }
  }

  chatBotEnabled: boolean;
  fetchDetails() {
    this.manageconfigurationservice
      .fetchBotDetails()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        if (res?.result) {
          this.chatBotEnabled = true;
          if (res?.result?.botName) {
            this.botName = res?.result?.botName;
          }
          if (res?.result?.welcomeMessage && this.setWelcome) {
            //this.livechatFacade.addWelcomeMessage(res?.result?.welcomeMessage, this.roomId?.value);
            this.setWelcome = false;
          }
        } else {
          this.chatBotEnabled = false;
        }
      });
  }

  setDepartment() {
    /* istanbul ignore next */
    this.router.events.pipe(takeUntil(this.ngUnsubscribe)).subscribe((event: any) => {
      console.log(event);
      let data;
      /* istanbul ignore next */
      if (event?.url?.includes('transaction')) {
        /* istanbul ignore next */
        this.livechatFacade.setDepartment(data, this.roomId?.value);
        this.connect('TenantSpecific');
      }
    });
  }
}

export interface IButton {
  displayText: string;
  value: string;
}

import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CduiBaseService } from '@app/auth/cdui-base.service';

@Injectable({
  providedIn: 'root',
})
export class A2sendpointService extends CduiBaseService {
  constructor(private injector: Injector, private http: HttpClient) {
    super(injector);
  }

  processExtraction(payLoad: any) {
    let refresh_token = '';
    const lang: any = localStorage.locale ? localStorage.locale : 'en';
    const credentials: any = JSON.parse(localStorage.getItem('credentials'));
    /*istanbul ignore else */
    if (credentials && credentials != null && credentials.refresh_token) {
      refresh_token = credentials.refresh_token;
    }
    const headerDict = {
      'Accept-Language': lang,
      'refresh-token': refresh_token,
    };

    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return this.http.post(this.api?.extractionApi, payLoad, requestOptions);
  }

  getExtractionList(pageNumber: number, pageSize: number, searchText: string) {
    return this.http.get(`${this.api?.extractionList}?limit=${pageSize}&page=${pageNumber}&query=${searchText}`);
  }

  getExtractedSolutionList(trackId: any) {
    return this.http.get(`${this.api?.extractedSolutionListApi}${trackId}`);
  }
}

import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Logger } from '@core';
import { CredentialsService } from './credentials.service';
import { AlertService } from '@app/@core/toaster/alert.service';
import { LoaderService } from '@app/@shared/services/loader/loader.service';
import { AuthenticationService } from './authentication.service';
import { TranslatorService } from '@app/shell/translator.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

const log = new Logger('AuthenticationGuard');

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate {
  private unsubscribe$: Subject<any> = new Subject<any>();
  labels: any;
  constructor(
    private router: Router,
    private credentialsService: CredentialsService,
    private alertService: AlertService,
    private loader: LoaderService,
    private authenticationService: AuthenticationService,
    private translator: TranslatorService
  ) {
    this.detectLanguageChange();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    /* istanbul ignore next */
    if (this.credentialsService.isAuthenticated()) {
      return this.isValidRoute(state?.url);
    }

    log.debug('Not authenticated, redirecting and adding redirect url...');
    this.alertService.showToaster('Unauthorised action. Login to continue', '', 'error');
    this.loader.hide();
    /* istanbul ignore next */
    const isCarnival = route?.data?.isCarnival;
    if (isCarnival) {
      this.router.navigate([`/carnival-login`]);
    } else {
      /* istanbul ignore next */
      this.router.navigate(['/login'], { queryParams: { redirect: state?.url }, replaceUrl: true });
    }
    return false;
  }

  /**
   * Determines whether b2b2c valid route or not
   * @param url
   * @returns
   */
  isValidRoute(url: string) {
    /* istanbul ignore else */
    if (url.indexOf('b2b2c') !== -1) {
      this.authenticationService.setB2B2Cstate(true);
    }
    /* istanbul ignore next */
    if (url?.indexOf('brfLogin') !== -1) {
      this.authenticationService.setBrfLoginState(true);
    }
    /* istanbul ignore next */
    if (
      (this.authenticationService.getB2B2CState() || this.authenticationService.getBrfLoginState()) &&
      url.indexOf('transaction') !== -1
    ) {
      return true;
    } else if (!this.authenticationService.getB2B2CState() || !this.authenticationService?.getBrfLoginState()) {
      return true;
    } else {
      this.alertService.showToaster(
        this.labels.Authorization_error_ensure_that_you_have_access_to_this_feature,
        this.labels.oops,
        'error'
      );
      return false;
    }
  }
  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.unsubscribe$)).subscribe((res: any) => {
      this.labels = res;
    });
  }
}

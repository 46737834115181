<div class="scanner-wrapper">
  <zxing-scanner
    class="scanner-cls"
    [torch]="torchEnabled"
    [device]="deviceCurrent"
    (deviceChange)="onDeviceChange($event)"
    (scanSuccess)="onCodeResult($event)"
    [formats]="formatsEnabled"
    [tryHarder]="tryHarder"
    (permissionResponse)="onHasPermission($event)"
    (camerasFound)="onCamerasFound($event)"
    (torchCompatible)="onTorchCompatible($event)"
  ></zxing-scanner>
</div>

<div class="or-cls">(or)</div>

<div class="container-upload" appDnd (fileDropped)="onFileDropped($event)">
  <input
    [disabled]="field?.readonly"
    [required]="field?.isRequired"
    type="file"
    #fileInput
    id="fileUpload_{{ field?.attribute?.name }}_{{ field?.entityName }}"
    multiple
    (change)="fileBrowseHandler($event.target.files)"
  />
  <h3 class="dropfile-text">{{ labels?.Drop_files_here }}</h3>
  <label
    for="fileUpload_{{ field?.attribute?.name }}_{{ field?.entityName }}"
    id="fileUpload_{{ field?.attribute?.name }}_{{ field?.entityName }}"
    >{{ labels?.BROWSE }}</label
  >
</div>
<div class="files-list">
  <div class="single-file" *ngFor="let file of files; let i = index">
    <div class="info">
      <div class="info-data-cls">
        <h4 class="name" [title]="file && file.originalFileName ? file?.originalFileName : file?.name">
          <span *ngIf="file?.originalFileName; else name">{{ file?.originalFileName }}</span>
          <ng-template #name>{{ file?.name }}</ng-template>
        </h4>
      </div>
    </div>
  </div>
</div>

<mat-dialog-actions class="customclosebtn">
  <button mat-dialog-close>
    <img [src]="'../../../../assets/images/team/close-icon.svg' | CDNUrlRewritePipe" alt="" />
  </button>
</mat-dialog-actions>

<div *ngIf="data.typeOfMedia === mediaTypes.Image " class="chatmedia">
  <figure>
    <img [src]="'data.src' | CDNUrlRewritePipe" alt="chat info" />
  </figure>
</div>

<div *ngIf="data.typeOfMedia === mediaTypes.Video" class="chatmedia">
  <video controls id="mainvideoplayer" autoplay>
    <source [src]="data.src | useHttpImgSrc | async" type="video/mp4" />
    <track label="English" kind="captions" srclang="en" src="" default />
  </video>
</div>

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  loaderSubject = new Subject();
  loader$ = this.loaderSubject.asObservable();
  constructor() {}

  show(message?: string) {
    this.loaderSubject.next({ showLoader: true, message: message });
  }
  hide() {
    this.loaderSubject.next({ showLoader: false });
  }
}

<div class="side-menu" id="side-menu" *ngIf="!carnival">
  <!-- <button class="menu-guide sprite menu-trigger"></button> -->
  <div class="menu-list-wrap">
    <div class="sidemenu-toggle">
      <!-- Menu Starts here -->
      <!-- For NSLHUB Logo -->
      <nav class="main-nav" [ngClass]="{ active: isOpenNavigation }" (appOutsideClick)="outsideClick($event)">
        <div id="side-menu-bar" class="top-nav d-flex align-items-center" (click)="toggleNav()">
          <h1 class="logo" (click)="onLogoClick()">
            <!-- <img
              *ngIf="loginBtnType"
              [src]="'../../../assets/node/logo.svg' | CDNUrlRewritePipe"
              height="32"
              width="32"
              class="d-block"
            /> -->
            <img
              *ngIf="headerDesignData?.logo?.desktop; else nologo"
              [src]="headerDesignData?.logo?.desktop"
              height="32"
              width="32"
              class="d-block branding-logo"
            />
            <ng-template #nologo>
              <img [src]="'../../../assets/node/logo.svg' | CDNUrlRewritePipe" height="32" width="32" class="d-block" />
            </ng-template>
          </h1>
          <div class="nav-bar" id="nav-bar">
            <i class="bi bi-list"></i>
            <!-- <img [src]="'../../../assets/node/hamburger-icon.svg' | CDNUrlRewritePipe" height="24" width="24" class="d-block" /> -->
          </div>
          <div class="nav-arrow" id="nav-bar-arrow">
            <i class="bi bi-caret-right-fill"></i>
          </div>
        </div>

        <!-- For Icons list -->
        <section class="main-menu row no-gutters">
          <a href="javascript:;" class="closeNavMenu" id="closeNavMenu" (click)="isOpenNavigation = false"></a>
          <div class="col-12">
            <ul class="left-menu main-sidebar" *ngIf="currentLibrary !== 'nslLibrary'">
              <li id="logout" (click)="logOut()" *ngIf="!loginBtnType">
                <a class="logout-icon menu-icon">
                  <!-- <i class="bi bi-lock mr-3"></i> -->
                  {{ labels?.Logout }}
                </a>
              </li>

              <li *ngIf="loginBtnType && !isBrfLogin" id="loginBtn">
                <a class="myprofile menu-icon" [routerLink]="['/login']" id="login">
                  {{ labels?.Log_In }}
                </a>
              </li>
              <!-- For Username and Logout Btn -->
              <li *ngIf="!loginBtnType" id="userDropdownClick">
                <a
                  id="profile"
                  (click)="accordian('profile')"
                  class="myprofile menu-icon"
                  [ngClass]="{ active: isCurrentAcc == 'profile' }"
                >
                  <!-- <i class="bi bi-person-circle mr-3"></i> -->
                  {{ labels?.Profile }}
                  <i
                    class="bi bi-chevron-down acc-arrow"
                    [ngClass]="{ 'bi-chevron-up': openDropdown, 'bi-chevron-down': !openDropdown }"
                  ></i>
                </a>
                <ul *ngIf="isCurrentAcc == 'profile' && openDropdown">
                  <li class="pl-4">
                    <a class="pl-5">{{ userDetails }}</a>
                  </li>
                  <li
                    class="pl-4"
                    *ngIf="!loginBtnType && !isTenantAdmin && !isPlatformAdmin && currentLibrary !== 'nslLibrary'"
                    id="mailBoxMenu"
                    [ngClass]="{ active: currentLibrary === libraryEnums.MailBox }"
                  >
                    <a (click)="openLibrary(libraryEnums.MailBox)" class="mailbox menu-icon pl-5">
                      {{ labels?.My_Transactions }}
                    </a>
                  </li>
                  <li
                    class="pl-4"
                    *ngIf="
                      !loginBtnType &&
                      !isTenantAdmin &&
                      !isPlatformAdmin &&
                      currentLibrary !== 'nslLibrary' &&
                      tenantName !== 'b2c'
                    "
                    id="MyProfile"
                    [ngClass]="{ active: currentLibrary === libraryEnums.MyProfile }"
                  >
                    <a (click)="openLibrary(libraryEnums.MyProfile)" class="mailbox menu-icon pl-5">
                      {{ labels?.My_Profile }}
                    </a>
                  </li>
                  <li
                    class="pl-4"
                    *ngIf="!loginBtnType && currentLibrary !== 'nslLibrary'"
                    [ngClass]="{ active: currentLibrary === libraryEnums.Notifications }"
                    [matMenuTriggerFor]="afterMenu"
                  >
                    <a
                      (click)="openLibrary(libraryEnums.Notifications)"
                      id="notification-button"
                      class="notification menu-icon pl-5"
                    >
                      {{ labels?.Notifications }}
                      <span class="unread-msg">{{ unreadNotifCount }}</span>
                    </a>
                    <mat-menu #afterMenu="matMenu" xPosition="after">
                      <div
                        (click)="showNotification = true; $event.stopPropagation()"
                        class="main-panel q"
                        *ngIf="notificationLazyComponent"
                        id="notification-container"
                      >
                        <div
                          infiniteScroll
                          [infiniteScrollDistance]="2"
                          [infiniteScrollThrottle]="50"
                          [infiniteScrollContainer]="selector"
                          [immediateCheck]="true"
                          [fromRoot]="true"
                          (scrolled)="getNotificationDataOnScrollDown()"
                          id="notification-scroll"
                        >
                          <ng-template
                            *ngIf="notificationLazyComponent"
                            id="notification-smart-component"
                            [ngComponentOutlet]="notificationLazyComponent | async"
                          >
                          </ng-template>
                        </div>
                      </div>
                    </mat-menu>
                  </li>

                  <li
                    class="pl-4"
                    *ngIf="
                      !loginBtnType &&
                      currentLibrary !== 'nslLibrary' &&
                      (currentUrl == '/mylibrary' || currentUrl == '/cdui/mylibrary-dashboard')
                    "
                    [ngClass]="{ active: currentLibrary === libraryEnums.Calendar }"
                    id="calendar"
                  >
                    <a (click)="onCalendarClick(libraryEnums.Calendar)" class="calendar menu-icon pl-5">
                      {{ labels?.Calendar }}
                    </a>
                  </li>
                </ul>
              </li>

              <!-- For My Library -->
              <li
                *ngIf="isUser && !loginBtnType"
                id="mySolutions"
                [ngClass]="{ active: currentLibrary === libraryEnums.MyLibrary }"
              >
                <a (click)="gotoSolutionsFromMenuPanel()" class="mylibrary menu-icon">
                  {{ labels?.My_Library }}
                </a>
              </li>

              <li *ngIf="!loginBtnType" id="myCart">
                <a (click)="onClickMenuItem('myCart')" class="myCart menu-icon">
                  {{ labels?.My_Cart }}
                </a>
              </li>

              <!-- For counts of Books, Chapter, GSI, CU, Agent, Entity, Attribute, Nslhub_Kit, Organisation Unit -->
              <ng-container *ngIf="!loginBtnType && currentLibrary !== 'nslLibrary'">
                <div
                  *ngIf="
                    showMysolutionPageMenu && !isTenantAdmin && !isPlatformAdmin && !enableSaasFlag && !isSubTenant
                  "
                >
                  <li id="Books" [ngClass]="{ active: currentLibrary === libraryEnums.Book }">
                    <a (click)="openLibrary(libraryEnums.Book)" class="books menu-icon">
                      {{ labels?.Books }} {{ booksCount }}
                    </a>
                  </li>

                  <li id="Chapters" [ngClass]="{ active: currentLibrary === libraryEnums.Chapter }">
                    <a (click)="openLibrary(libraryEnums.Chapter)" class="chapter menu-icon">
                      {{ labels?.Chapter }}
                    </a>
                  </li>

                  <li
                    id="gsiMenu"
                    [ngClass]="{
                      active: currentLibrary === libraryEnums.GSI || currentLibrary === libraryEnums.EventGsi
                    }"
                  >
                    <a (click)="openLibrary(libraryEnums.GSI)" class="gsi menu-icon">
                      {{ labels?.GSI }} {{ gsiCount }}
                    </a>
                  </li>

                  <li id="changeUnitMenu" [ngClass]="{ active: currentLibrary === libraryEnums.ChangeUnit }">
                    <a id="cuClickPanel" (click)="openLibrary(libraryEnums.ChangeUnit)" class="cu menu-icon">
                      {{ labels?.Change_Unit }} {{ cuCount }}
                    </a>
                  </li>

                  <li id="rolesMenu" [ngClass]="{ active: currentLibrary === libraryEnums.Agent }">
                    <a (click)="openLibrary(libraryEnums.Agent)" class="agents menu-icon">
                      {{ labels?.Agent }} {{ rolesCount }}
                    </a>
                  </li>

                  <li id="entityMenuPanel" [ngClass]="{ active: currentLibrary === libraryEnums.Entity }">
                    <a id="entiyClickPanel" (click)="openLibrary(libraryEnums.Entity)" class="entity menu-icon">
                      {{ labels?.Entity }} {{ entityCount }}
                    </a>
                  </li>

                  <li id="attributeMenuPanel" [ngClass]="{ active: currentLibrary === libraryEnums.Attributes }">
                    <a
                      id="attributeClickPanel"
                      (click)="openLibrary(libraryEnums.Attributes)"
                      class="attribt menu-icon"
                    >
                      {{ labels?.Attribute }} {{ attributeCount }}
                    </a>
                  </li>

                  <li id="nslHubKitMenu" [ngClass]="{ active: currentLibrary === libraryEnums.NSLKitCU }">
                    <a (click)="openLibrary(libraryEnums.NSLKitCU)" class="nk menu-icon">
                      {{ labels?.Nslhub_Kit }}
                    </a>
                  </li>

                  <li id="orgUnitsMenu" [ngClass]="{ active: currentLibrary === libraryEnums.OrgUnit }">
                    <a (click)="openLibrary(libraryEnums.OrgUnit)" class="orgunits menu-icon">
                      {{ labels?.Organisation_Unit }}
                    </a>
                  </li>
                </div>
              </ng-container>

              <!-- For Dashboard -->
              <li
                *ngIf="!loginBtnType && isTenantAdmin && currentLibrary !== 'nslLibrary'"
                id="dashboardMenu"
                [ngClass]="{ active: currentLibrary === libraryEnums.Dashboard }"
              >
                <a (click)="openLibrary(libraryEnums.Dashboard)">
                  {{ labels?.Dashboard }}
                </a>
              </li>

              <!-- For My Dashboard -->
              <li
                *ngIf="!loginBtnType && currentLibrary !== 'nslLibrary'"
                id="dashboardMenu"
                [ngClass]="{ active: currentLibrary === libraryEnums.MyDashboard }"
              >
                <a (click)="openLibrary(libraryEnums.MyDashboard)" class="mydashboard menu-icon">
                  {{ labels?.My_Dashboard }}
                </a>
              </li>

              <!-- For Main Box -->
              <!-- For Users -->
              <li
                *ngIf="!loginBtnType && enableB2C && currentLibrary !== 'nslLibrary'"
                id="UsersMenu"
                [ngClass]="{ active: currentLibrary === libraryEnums.Users }"
              >
                <a (click)="openLibrary(libraryEnums.Users)" class="users menu-icon">
                  {{ labels?.Users }}
                </a>
              </li>

              <!-- For Notifications -->
              <!-- <li
                *ngIf="!loginBtnType && currentLibrary !== 'nslLibrary'"
                [ngClass]="{ active: currentLibrary === libraryEnums.Notifications }"
                [matMenuTriggerFor]="afterMenu"
              >
                <a
                  (click)="openLibrary(libraryEnums.Notifications)"
                  id="notification-button"
                  class="notification menu-icon"
                >
                  {{ labels?.Notifications }}
                  <span class="unread-msg">99+</span>
                </a>
                <mat-menu #afterMenu="matMenu" xPosition="after">
                  <div
                    (click)="showNotification = true; $event.stopPropagation()"
                    class="main-panel q"
                    *ngIf="notificationLazyComponent"
                    id="notification-container"
                  >
                    <div
                      infiniteScroll
                      [infiniteScrollDistance]="2"
                      [infiniteScrollThrottle]="50"
                      [infiniteScrollContainer]="selector"
                      [immediateCheck]="true"
                      [fromRoot]="true"
                      (scrolled)="getNotificationDataOnScrollDown()"
                      id="notification-scroll"
                    >
                      <ng-template
                        *ngIf="notificationLazyComponent"
                        id="notification-smart-component"
                        [ngComponentOutlet]="notificationLazyComponent | async"
                      >
                      </ng-template>
                    </div>
                  </div>
                </mat-menu>
              </li> -->

              <!-- For File Explorer -->
              <li
                *ngIf="!loginBtnType && (isTenantAdmin || isPlatformAdmin) && currentLibrary !== 'nslLibrary'"
                [ngClass]="{ active: currentLibrary === libraryEnums.FileExplorer }"
              >
                <a (click)="openLibrary(libraryEnums.FileExplorer)" class="filex menu-icon">
                  {{ labels?.File_Explorer }}
                </a>
              </li>

              <!-- For Calendar -->
              <!-- <li
                *ngIf="
                  !loginBtnType &&
                  currentLibrary !== 'nslLibrary' &&
                  (currentUrl == '/mylibrary' || currentUrl == '/cdui/mylibrary')
                "
                [ngClass]="{ active: currentLibrary === libraryEnums.Calendar }"
              >
                <a (click)="onCalendarClick(libraryEnums.Calendar)" class="calendar menu-icon">
                  {{ labels?.Calendar }}
                </a>
              </li> -->

              <!-- For Special Event -->
              <li
                *ngIf="!loginBtnType && currentLibrary !== 'nslLibrary'"
                [ngClass]="{ active: currentLibrary === libraryEnums.SpecialEvent }"
              >
                <a
                  (click)="accordian('splFeature')"
                  class="special-feacture menu-icon"
                  [ngClass]="{ active: isCurrentAcc == 'splFeature' }"
                >
                  <!-- <i class="bi bi-file-bar-graph mr-3"></i> -->
                  {{ labels?.Special_Feature }}
                  <i
                    class="bi bi-chevron-down acc-arrow"
                    [ngClass]="{ 'bi-chevron-up': specialfeatureDropdown, 'bi-chevron-down': !specialfeatureDropdown }"
                  ></i>
                </a>
                <ul *ngIf="specialfeatureDropdown">
                  <!-- For Milestone Dashboard -->
                  <li
                    class="pl-4"
                    *ngIf="!loginBtnType && !isPlatformAdmin && currentLibrary !== 'nslLibrary'"
                    id="milestoneMenu"
                    [ngClass]="{ active: currentLibrary === libraryEnums.MileStone }"
                  >
                    <a (click)="openLibrary(libraryEnums.MileStone)" class="pl-5">
                      {{ labels?.Milestone }}
                    </a>
                  </li>

                  <!-- For Edit GSI -->
                  <li class="pl-4" *ngIf="!loginBtnType && currentLibrary !== 'nslLibrary'">
                    <a (click)="gotonextpage()" class="pl-5">
                      {{ labels?.EditGSI }}
                    </a>
                  </li>
                  <li class="pl-4" *ngIf="!loginBtnType && currentLibrary !== 'nslLibrary'">
                    <a (click)="scheduledjobs()" class="pl-5 schedular-dashb menu-icon">
                      {{ labels?.Schedular_Dashboard }}
                    </a>
                  </li>

                  <!-- For Schedular Dashboard -->
                  <!-- <li *ngIf="!loginBtnType && currentLibrary !== 'nslLibrary'">
                    <a (click)="scheduledjobs()" class="schedular-dashb menu-icon">
                      {{ labels?.Schedular_Dashboard }}
                    </a>
                  </li> -->
                </ul>
              </li>

              <!-- For Special Event -->
              <li
                *ngIf="!loginBtnType && currentLibrary !== 'nslLibrary'"
                [ngClass]="{ active: currentLibrary === libraryEnums.SpecialEvent }"
              >
                <a (click)="openLibrary(libraryEnums.SpecialEvent)" class="spl-event menu-icon">
                  {{ labels?.Special_Event }}
                </a>
              </li>

              <!-- For Ad Dashboard -->
              <li
                *ngIf="
                  !loginBtnType &&
                  !isTenantAdmin &&
                  !isPlatformAdmin &&
                  isTenantBraneadsadminqa3 &&
                  currentLibrary !== 'nslLibrary'
                "
                [ngClass]="{ active: currentLibrary === libraryEnums.AdDashboard }"
              >
                <a (click)="openLibrary(libraryEnums.AdDashboard)" class="adDashboard menu-icon">
                  {{ labels?.Ad_Dashboard }}
                </a>
              </li>

              <!-- For NSL Library -->
              <li
                *ngIf="currentLibrary !== 'nslLibrary'"
                [ngClass]="{ active: currentLibrary === libraryEnums.NslLibrary }"
              >
                <a (click)="openLibrary(libraryEnums.NslLibrary)" class="nsl-library menu-icon">
                  {{ labels?.NSL_Library }}
                </a>
              </li>

              <!-- For Library Noteapad -->
              <li
                id="notepad"
                *ngIf="currentLibrary !== 'nslLibrary'"
                [ngClass]="{ active: currentLibrary == 'notepad' }"
                (click)="onClickMenuItem('notepad')"
              >
                <a
                  [routerLink]="['/solution-dictionary/notepad']"
                  routerLinkActive="active"
                  class="notebook-icon menu-icon"
                >
                  {{ labels?.Notepad }}
                </a>
              </li>

              <!-- For Special Event -->
              <li
                *ngIf="!loginBtnType && currentLibrary !== 'nslLibrary'"
                [ngClass]="{ active: currentLibrary === libraryEnums.SpecialEvent }"
              >
                <a
                  class="my-learning menu-icon"
                  [ngClass]="{ active: isCurrentAcc == 'myLearning' }"
                  (click)="accordian('myLearning')"
                >
                  <!-- <i class="bi bi-mortarboard mr-3"></i> -->
                  {{ labels?.My_Learning }}
                  <i
                    class="bi bi-chevron-down acc-arrow"
                    [ngClass]="{ 'bi-chevron-up': myLearningDropdown, 'bi-chevron-down': !myLearningDropdown }"
                  ></i>
                </a>
                <ul *ngIf="myLearningDropdown">
                  <!-- For Milestone Dashboard -->
                  <li class="pl-4" id="milestoneMenu">
                    <a class="pl-5">
                      {{ labels?.My_Courses }}
                    </a>
                  </li>

                  <li class="pl-4" id="milestoneMenu">
                    <a class="pl-5">
                      {{ labels?.Learning_Videos }}
                    </a>
                  </li>

                  <li class="pl-4" id="milestoneMenu">
                    <a class="pl-5">
                      {{ labels?.Certificate }}
                    </a>
                  </li>
                </ul>
              </li>

              <!-- For isAdvertiser Ad Dashboard -->
              <li
                *ngIf="
                  !loginBtnType && !isTenantAdmin && !isPlatformAdmin && isAdvertiser && currentLibrary !== 'nslLibrary'
                "
                [ngClass]="{ active: currentLibrary === libraryEnums.AdvertiserDashboard }"
              >
                <a (click)="openLibrary(libraryEnums.AdvertiserDashboard)" class="adDashboard menu-icon">
                  {{ labels?.Ad_Dashboard }}
                </a>
              </li>

              <!-- For isPublisher Ad Dashboard -->
              <li
                *ngIf="
                  !loginBtnType && !isTenantAdmin && !isPlatformAdmin && isPublisher && currentLibrary !== 'nslLibrary'
                "
                [ngClass]="{ active: currentLibrary === libraryEnums.PublisherDashboard }"
              >
                <a (click)="openLibrary(libraryEnums.PublisherDashboard)" class="adDashboard menu-icon">
                  {{ labels?.Ad_Dashboard }}
                </a>
              </li>

              <!-- For Edit GSI -->
              <!-- <li *ngIf="!loginBtnType && currentLibrary !== 'nslLibrary'">
                <a (click)="gotonextpage()" class="edit-gsi menu-icon">
                  {{ labels?.EditGSI }}
                </a>
              </li> -->

              <!-- For Schedular Dashboard -->
              <!-- <li *ngIf="!loginBtnType && currentLibrary !== 'nslLibrary'">
                <a (click)="scheduledjobs()" class="schedular-dashb menu-icon">
                  {{ labels?.Scheduler_Dashboard }}
                </a>
              </li> -->

              <!-- For Node System -->
              <li
                *ngIf="
                  isUser &&
                  !showMysolutionPageMenu &&
                  !isBrfLogin &&
                  isEnvDev &&
                  !loginBtnType &&
                  !enableSaasFlag &&
                  !isSubTenant
                "
              >
                <a [routerLink]="['/cdui/canvas']" (click)="OnNodeClick()" class="node-system menu-icon" id="canvas">
                  {{ labels?.Canvas }}
                </a>
              </li>

              <!-- For Hybrid Canvas -->
              <li *ngIf="isUser && !loginBtnType && !showMysolutionPageMenu && !enableSaasFlag && !isSubTenant">
                <a [routerLink]="['/newsolution']" (click)="OnNodeClick()" class="gsi menu-icon" id="hybridCanvas">
                  {{ labels?.Hybrid_Canvas }}
                </a>
              </li>

              <!-- For Email Template Editor -->
              <li *ngIf="isUser && !loginBtnType">
                <a
                  [routerLink]="['/cdui/admin/templateeditor']"
                  class="emaileditoricon menu-icon"
                  id="EmailTemplateEditor"
                >
                  {{ labels?.Email_Template_Editor }}
                </a>
              </li>

              <li *ngIf="isUser && !loginBtnType">
                <a
                  (click)="openLibrary(libraryEnums.Collaborate)"
                  class="collaborate menu-icon"
                  id="sideMenuCollabIcon"
                >
                  Collaborate
                </a>
              </li>

              <!-- For NSL Grammar -->
              <!-- <li *ngIf="!isBrfLogin">
                <a [routerLink]="['/nodesystem']" (click)="OnNodeClick()" class="nsl-grammar menu-icon">
                  NSL Grammar
                </a>
              </li> -->
            </ul>
            <ul *ngIf="currentLibrary === 'nslLibrary'" class="left-menu">
              <li id="logout" (click)="logOut()" *ngIf="!loginBtnType">
                <a class="logout-icon menu-icon">
                  <!-- <i class="bi bi-lock mr-3"></i> -->
                  {{ labels?.Logout }}
                </a>
              </li>
              <li *ngIf="loginBtnType && !isBrfLogin" id="loginBtn">
                <a class="myprofile menu-icon" [routerLink]="['/login']" id="login">
                  {{ labels?.Log_In }}
                </a>
              </li>
              <li
                *ngIf="isUser && !loginBtnType"
                id="mySolutions"
                [ngClass]="{ active: currentLibrary === libraryEnums.MyLibrary }"
              >
                <a (click)="gotoSolutionsFromMenuPanel()" class="mylibrary menu-icon">
                  {{ labels?.My_Library }}
                </a>
              </li>

              <li [ngClass]="{ active: currentLibrary === libraryEnums.NslLibrary }">
                <a (click)="openLibrary(libraryEnums.NslLibrary)" class="nsl-library menu-icon">
                  {{ labels?.NSL_Library }}
                </a>
              </li>

              <li id="Books">
                <a (click)="comingSoonToaster()" class="book-icon menu-icon">
                  {{ labels?.Books }}
                </a>
              </li>

              <li id="Chapters">
                <a (click)="comingSoonToaster()" class="chapter-icon menu-icon">
                  {{ labels?.Chapter }}
                </a>
              </li>
              <li id="mailBoxMenu">
                <a (click)="comingSoonToaster()" class="mailBox-icon menu-icon">
                  {{ labels?.Mail_Box }}
                </a>
              </li>
              <li id="notepad" [ngClass]="{ active: activeSideMenu == 'notepad' }" (click)="onClickMenuItem('notepad')">
                <a
                  [routerLink]="['/solution-dictionary/notepad']"
                  routerLinkActive="active"
                  class="notebook-icon menu-icon"
                >
                  {{ labels?.Notepad }}
                </a>
              </li>
              <li id="myCartInsideNslLib">
                <a (click)="onClickMenuItem('myCart')" class="myCart menu-icon">
                  {{ labels?.My_Cart }}
                </a>
              </li>
            </ul>
          </div>

          <div class="col-12 align-self-end">
            <ul class="unique-menu">
              <!-- For Preferences -->
              <li *ngIf="!loginBtnType && (enablePreferences || isUser)">
                <a class="settings menu-icon" id="preference" (click)="goToPreferences()">
                  {{ labels?.Preferences }}
                </a>
              </li>

              <!-- For Import -->
              <li
                *ngIf="!loginBtnType && isTenantAdmin && !enableSaasFlag"
                id="importSolution"
                [ngClass]="{ active: isImport }"
              >
                <a class="import menu-icon" (click)="openLibrary(libraryEnums.Import)">
                  {{ labels?.Import }}
                </a>
              </li>

              <!-- For Export -->
              <!-- <li
                *ngIf="!loginBtnType && showMysolutionPageMenu && !isTenantAdmin && !isPlatformAdmin && !enableSaasFlag"
                id="exportSolution"
                [ngClass]="{ active: currentLibrary === libraryEnums.Export }"
              >
                <a class="export menu-icon" (click)="openLibrary(libraryEnums.Export)">
                  {{ labels?.Export }}
                </a>
              </li> -->

              <!-- For Language selection -->
              <li *ngIf="!loginBtnType && availableLanguages.length > 0">
                <a class="language menu-icon"> {{ labels?.Language }} <mat-icon>keyboard_arrow_right</mat-icon> </a>
                <ul class="lang-dropdwn">
                  <li class="lang-dropdown-list">
                    <a *ngFor="let lang of availableLanguages" (click)="changeLang(lang.languageCode, true)">
                      <mat-icon *ngIf="defaultLanguage === lang.languageCode">done</mat-icon>{{ lang.languageName }}
                    </a>
                  </li>
                </ul>
              </li>

              <!-- For Group users -->
              <li *ngIf="userInfo?.groups?.length > 0 && !isBrfLogin" id="groupsSelection">
                <a>
                  {{ selectedGroup.replace('_', ' ') }}
                  <mat-icon style="font-size: 22px; color: #666; float: right;">keyboard_arrow_right</mat-icon>
                </a>
                <ul>
                  <li>
                    <a *ngFor="let group of userInfo?.groups" (click)="selectGroup(group.name)" id="{{ group.name }}">
                      {{ group.name.replace('_', ' ') }}
                    </a>
                  </li>
                </ul>
              </li>

              <!-- For Admin -->

              <li class="">
                <a class="support-icon menu-icon">{{ labels?.Support }}</a>
              </li>

              <li
                *ngIf="!loginBtnType && (canDesign || isTenantAdmin || isPlatformAdmin || isSelfOnboardingAdmin)"
                id="admin"
                [ngClass]="{ active: currentLibrary === libraryEnums.Admin }"
              >
                <a class="admin menu-icon" (click)="openLibrary(libraryEnums.Admin)">
                  {{ labels?.Admin }}
                </a>
              </li>
              <!-- For Username and Logout Btn -->
              <!-- <li *ngIf="!loginBtnType" id="userDropdownClick">
                <a class="profile menu-icon">{{ labels?.Profile }}</a>
                <ul>
                  <li>
                    <a>{{ userDetails }}</a>
                  </li>
                  <li id="logout" (click)="logOut()">
                    <a>{{ labels?.Logout }}</a>
                  </li>
                </ul>
              </li> -->

              <!-- For Login Btn -->
              <!-- <li *ngIf="loginBtnType && !isBrfLogin" id="loginBtn">
                <a class="profile menu-icon" [routerLink]="['/login']" id="login">
                  {{ labels?.Log_In }}
                </a>
              </li> -->
              <!-- for role selection in normal users -->
              <li *ngIf="roles && roles?.length > 0" id="role-selection">
                <a class="roles menu-icon"> {{ labels?.Roles }} <mat-icon>keyboard_arrow_right</mat-icon> </a>
                <ul class="roles-dropdwn">
                  <li class="roles-dropdown-list">
                    <a *ngFor="let role of _roles" (click)="changeRole(role)">
                      {{ role?.roleName }}
                    </a>
                  </li>
                </ul>
              </li>

              <li class="lang-dropdown">
                <div class="no-label no-space menu-select"></div>
              </li>
            </ul>
          </div>
        </section>
      </nav>
    </div>
  </div>
</div>
<!-- <div *ngIf="showHomeComponent">
  <app-home></app-home>
</div> -->

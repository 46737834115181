import { entityDeMapper } from '../entity/entity-demapper';

let transform = require('node-json-transform').transform;

export function transactionChangeUnitDeMapper(data: any) {
  var map = {
    /*
      node json mapper default item
      used to map one key to another
    */
    item: {
      dsdId: 'id',
      id: 'tfId',
      name: 'name',
      agents: 'agents',
      cuType: 'cuType',
      layers: 'layers',
      gsiList: 'gsiList',
      membershipList: 'membershipList',
      isReserved: 'isReserved',
      status: 'status',
      dsdStatus: 'dsdStatus',
      designTimeRights: 'designTimeRights',
      txnTimeRights: 'txnTimeRights',
      displayName: 'displayName',
      description: 'description',
      ontology: 'ontology',
      keywords: 'keywords',
      attachments: 'attachments',
      nextTriggerSet: 'nextTriggerSet',
      dsdReferencedChangeUnit: 'referencedChangeUnit',
      color: 'color',
      secondaryColor: 'secondaryColor',
      activeCuIndex: 'activeCuIndex',
      index: 'index',
      masterId: 'masterId',
      cuSystemProperties: 'cuSystemProperties',
      constraint: 'constraint',
      constraintName: 'constraintName',
      author: 'author',
      ownerId: 'ownerId',
      version: 'version',
      slotItemProperties: 'slotItemProperties',
      source: 'source',
      tCUConditionalPotentiality: 'tCUConditionalPotentiality',
      tCUConditionalPotentialityNames: 'tCUConditionalPotentialityNames',
      reservedCUType: 'reservedCUType',
      isNameUpdated: 'isNameUpdated',
      uiProperties: 'uiProperties',
      isFinal: 'isFinal', //  Usedfor final cu save
      oldVersionId: 'oldVersionId',
      estimatedTime: 'estimatedTime',
      gsiMasterId: 'gsiMasterId',
      triggerCUIndex: 'triggerCUIndex',
      cuMasterId: 'cuMasterId',
      gsiName: 'gsiName',
      cuName: 'cuName',
    },
    operate: [
      {
        run: function (val: any) {
          return val ? val : null;
        },
        on: 'dsdId',
      },
      {
        run: function (val: any) {
          return transform(val, nestedMap);
        },
        on: 'layers',
      },
      {
        run: function (val: any) {
          return val ? val : undefined;
        },
        on: 'constraint',
      },
      {
        run: function (val: any) {
          return val ? val : undefined;
        },
        on: 'constraintName',
      },
      {
        run: (val: any) => {
          if (val !== undefined && Object.keys(val).length) {
            return val;
          } else {
            return {};
          }
        },
        on: 'uiProperties',
      },
    ],
  };

  const nestedMap = {
    item: {
      label: 'label',
      participatingItems: 'participatingItems',
      type: 'type',
    },
    operate: [
      {
        run: function (val: any) {
          return val?.map((x: any) => {
            return {
              changeDriverMetaData: x.changeDriverMetaData, /// optional changes drivers data ///
              id: x.participatingItemId,
              isMultiValue: x.isMultiValue,
              item: {
                DATA: entityDeMapper(x),
                TYPE: 'GeneralEntity',
              },
            };
          });
        },
        on: 'participatingItems',
      },
    ],
  };

  const result = transform(data, map);
  console.log(result);
  return result;
}

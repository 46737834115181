import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigModel, endPoints } from './app-config.service.model';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private configuration: AppConfigModel;

  constructor(private httpClient: HttpClient) {}

  setConfig(): Promise<AppConfigModel> {
    return this.httpClient
      .get<AppConfigModel>('./assets/app-config/app-config.json')
      .toPromise()
      .then((config: any): any => {
        this.configuration = config;
        return config;
      });
  }

  readConfig(): AppConfigModel {
    if (!this.configuration) {
      this.configuration = {} as AppConfigModel;
      this.configuration.serverIp = '';
      this.configuration.dsdOrchUrl = '/dsd-orch';
      this.configuration.cdmServerAPI = '/cdm';
    }
    return this.configuration;
  }

  getUrls(environment: AppConfigModel) {
    return endPoints(environment);
  }
}

import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

/**
 * Dialog Data Interface
 */
export interface DialogData {
  typeOfMedia: string;
  src: string;
}

/**
 * Enums for Media
 */
export enum MediaTypes {
  Image = 'image',
  Video = 'video',
  Audio = 'audio',
}

@Component({
  selector: 'chatbot-media-dialog',
  templateUrl: 'chatbot-media-dialog.html',
  styleUrls: ['chatbot-smart.component.scss'],
})
export class ChatBotMediaDialog {
  mediaTypes = MediaTypes;
  constructor(public dialogRef: MatDialogRef<ChatBotMediaDialog>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}

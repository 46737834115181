import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { CduiBaseService } from '@app/auth/cdui-base.service';

@Injectable({
  providedIn: 'root',
})
export class ManageConfigurationEndpointService extends CduiBaseService {
  constructor(private injector: Injector, private http: HttpClient) {
    super(injector);
  }

  getConfigurableCPEntities(pageSize?: any) {
    pageSize = pageSize == undefined ? 100 : pageSize;
    return this.http.get(
      `${this.api?.configurableCPEntities}?criteria="isConditionalPotentialityEntity":"true"&pageNo=&pageSize=${pageSize}`
    );
  }
  getConfigurableEntityData(generalEntityID: any) {
    return this.http.get(this.api?.configurableEntityData + `/${generalEntityID}`);
  }
  saveConfigurableEntityData(postData: any) {
    return this.http.post(this.api?.configurableSaveEntity, postData);
  }
  tenantBotBasicSave(payload: any) {
    let tenantId = localStorage.getItem('TenantName');
    return this.http.post(this.api?.botbasic + tenantId, payload);
  }
  fetchBotDetails() {
    let tenantId = localStorage.getItem('TenantName');
    return this.http.get(this.api?.botbasic + tenantId);
  }

  faqSave(formData: any) {
    let tenantId = localStorage.getItem('TenantName');
    return this.http.post(this.api?.faqSave + `faq/tenantDocuments/${tenantId}`, formData);
  }

  fetchSignupConfig() {
    let tenantId = localStorage.getItem('TenantName');
    return this.http.get(this.api?.getSignupConfig + `${tenantId}`);
  }

  saveSignupConfig(payload: any) {
    let tenantId = localStorage.getItem('TenantName');
    return this.http.post(this.api?.getSignupConfig, payload);
  }

  deleteSignupConfig() {
    let tenantId = localStorage.getItem('TenantName');
    return this.http.delete(this.api?.getSignupConfig + `${tenantId}`);
  }

  fetchSignupProperties() {
    /* istanbul ignore next */
    let clientId = localStorage.getItem('envClientId')
      ? localStorage.getItem('envClientId')
      : sessionStorage.getItem('TenantId');
    return this.http.get(this.api?.fetchSignupProperties + `clientId=${clientId}`);
  }
  fetchGsiMappings() {
    return this.http.get(this.api?.fetchGsiMappings);
  }
  deleteGsiMapping(role: any) {
    return this.http.delete(this.api?.deleteGsiMappings + role);
  }
  // atqSave(formData:any){
  //   let tenantId= localStorage.getItem('TenantName');
  //   return this.http.post('',formData);
  // }
}

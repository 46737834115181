let transform = require('node-json-transform').transform;

export function entityMapper(data: any) {
  var map = {
    /*
      node json mapper default item
      used to map one key to another
    */
    item: {
      name: 'name',
      id: 'dsdId',
      tfId: 'id',
      isMultiValue: 'isMultiValue',
      nslAttributes: 'nslAttributes',
      isReserved: 'isReserved',
      designTimeRights: 'designTimeRights',
      txnTimeRights: 'txnTimeRights',
      status: 'status',
      dsdStatus: 'dsdStatus',
      displayName: 'displayName',
      ontology: 'ontology',
      attachments: 'attachments',
      keywords: 'keywords',
      publisher: 'publisher',
      masterId: 'masterId',
      version: 'version',
      author: 'author',
      description: 'description',
      ownerId: 'ownerId',
      finishType: 'finishType',
      configuration: 'configuration',
      changeDriverMetaData: 'changeDriverMetaData',
      isNameUpdated: 'isNameUpdated',
      entityProperties: 'entityProperties',
      source: 'source',
      dsdMetadataId: 'dsdMetadataId',
      isArEntity: 'isArEntity',
      uiProperties: 'uiProperties',
    },
    operate: [
      {
        run: function (val: any) {
          /*istanbul ignore next*/
          if (val?.length > 0) {
            /*istanbul ignore next*/
            return val?.map((x: any) => {
              if (x.generalEntity) {
                return {
                  ...x,
                  tfId: x.id,
                  id: x.dsdId,
                  generalEntity: {
                    ...x.generalEntity,
                    nslAttributeMetaData: x.nslAttributeMetaData,
                    conditionalPotentiality: x.conditionalPotentiality,
                    conditionalPotentialityName: x.conditionalPotentialityName,
                  },
                };
              } else {
                return {
                  ...x,
                  tfId: x.id,
                  id: x.dsdId,
                  nslAttributeMetaData: x.nslAttributeMetaData,
                  // code to test uielement functionality
                  // attributeType: {properties: {sourceValues: "[]"}, type: "boolean", uiElement: {
                  //   name: "Current Date Time",
                  //   dataType: "datetime",
                  //   uiElement: "currentdatetime",
                  //   isMulti: false,
                  //   properties: []
                  // }}
                };
              }
            });
          } else {
            return [];
          }
        },
        on: 'nslAttributes',
      },
    ],
  };
  return transform(data, map);
}

import { Injectable, OnDestroy } from '@angular/core';
import { LayoutSolutionEndpointService } from './layout-solution-endpoint.service';
import { BehaviorSubject, Subject, throwError } from 'rxjs';
import { AlertService } from '@app/@core/toaster/alert.service';
import { LoaderService } from '@app/@shared/services/loader/loader.service';
import { catchError } from 'rxjs/internal/operators/catchError';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class LayoutSolutionFacadeService implements OnDestroy {
  ngUnsubscribe = new Subject();

  booksListSubject = new Subject();
  booksList$ = this.booksListSubject.asObservable();

  clearBooks = new Subject();
  clearBooks$ = this.clearBooks.asObservable();

  designSystemData = new Subject();
  designSystemData$ = this.designSystemData.asObservable();

  layoutBookDesign = new Subject();
  layoutBookDesign$ = this.layoutBookDesign.asObservable();
  getIndustryList = new Subject();
  getIndustryList$ = this.getIndustryList.asObservable();

  getBookCoversList = new Subject();
  getBookCoversList$ = this.getBookCoversList.asObservable();

  public getGsiIconsList = new Subject();
  getGsiIconsList$ = this.getGsiIconsList.asObservable();

  public getEntityIconsList = new Subject();
  getEntityIconsList$ = this.getEntityIconsList.asObservable();

  public getCuIconsList = new Subject();
  getCuIconsList$ = this.getCuIconsList.asObservable();

  templateDesign = new Subject();
  templateDesign$ = this.templateDesign.asObservable();

  layoutChangeUnitDesign = new Subject();
  layoutChangeUnitDesign$ = this.layoutChangeUnitDesign.asObservable();

  changeUnitUIControls = new Subject();
  changeUnitUIControls$ = this.changeUnitUIControls.asObservable();

  layoutBookSearch = new Subject();
  layoutBookSearch$ = this.layoutBookSearch.asObservable();

  bookSearchText = new Subject();
  bookSearchText$ = this.bookSearchText.asObservable();

  updateUser = new Subject();
  updateUser$ = this.updateUser.asObservable();

  savedTemplate = new Subject();
  savedTemplate$ = this.savedTemplate.asObservable();

  bookDetails = new Subject();
  bookDetails$ = this.bookDetails.asObservable();

  gsiDetails = new Subject();
  gsiDetails$ = this.gsiDetails.asObservable();

  cuDetails = new Subject();
  cuDetails$ = this.cuDetails.asObservable();

  entityDetails = new Subject();
  entityDetails$ = this.entityDetails.asObservable();

  storeIdsOfGsi = new BehaviorSubject('');
  storeIdsOfGsi$ = this.storeIdsOfGsi.asObservable();

  storeIdsOfCus = new BehaviorSubject('');
  storeIdsOfCus$ = this.storeIdsOfCus.asObservable();

  getLayoutConfig = new Subject();
  getLayoutConfig$ = this.getLayoutConfig.asObservable();

  getSiteLayoutData = new Subject();
  getSiteLayoutData$ = this.getSiteLayoutData.asObservable();

  LayoutGsisList = new Subject();
  LayoutGsisList$ = this.LayoutGsisList.asObservable();
  weekWiseList = new BehaviorSubject(false);
  weekWiseList$ = this.weekWiseList.asObservable();

  constructor(
    private layoutEndPoint: LayoutSolutionEndpointService,
    private alertService: AlertService,
    private loader: LoaderService
  ) {}

  // getAllBooks(pageNo: number, pageSize: number, searchText: string) {
  //   return this.layoutEndPoint.getAllBooks(pageNo, pageSize, searchText);
  // }
  getBookDetails(bookId: any) {
    this.loader.show();
    return this.layoutEndPoint.getBookDetails(bookId).subscribe(
      (res: any) => {
        this.bookDetails.next(res); // return response of book details api
        this.loader.hide();
      },
      (error: Response) => {
        console.log(error);
        this.bookDetails.next('false'); //when book details api fails return false
      }
    );
  }
  getGsisInBook(bookId: any) {
    this.loader.show();
    this.layoutEndPoint
      .getGsisInBook(bookId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.gsiDetails.next(res);
        this.loader.hide();
      });
  }

  onEditOrTransactClearBooks(str: string) {
    this.clearBooks.next(str);
  }
  getCusInBook(bookId: any, gsiIds: any) {
    this.loader.show();
    this.layoutEndPoint
      .getCusInBook(bookId, gsiIds)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.cuDetails.next(res);
        this.loader.hide();
      });
  }
  getEntitiesInBook(bookId: any, cuIds: any) {
    this.loader.show();
    this.layoutEndPoint
      .getEntitiesInBook(bookId, cuIds)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.entityDetails.next(res);
        this.loader.hide();
      });
  }
  getColors(limit: number, bgPage: number) {
    return this.layoutEndPoint.getColors(limit, bgPage);
  }

  getWallpapers(bgsLimit: number, bgPage: number) {
    return this.layoutEndPoint.getWallpapers(bgsLimit, bgPage);
  }

  getAllBooksWithDesignData(
    pageNo: number,
    pageSize: number,
    searchText: string,
    userRights?: string,
    approvalStatus?: string,
    selectedWeek?: number
  ) {
    // this.loader.show();
    sessionStorage.removeItem('bookData');
    if (approvalStatus) {
      if (selectedWeek) {
        const reqTime = moment()
          .utc()
          .subtract(7 * selectedWeek, 'days')
          .format();
        let epochTime = new Date(reqTime).getTime();

        this.layoutEndPoint
          .getAllBooksWithDesignData(pageNo, pageSize, searchText, userRights, approvalStatus, epochTime)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((res: any) => {
            /*istanbul ignore else*/
            if (res) {
              /*istanbul ignore next */
              if (res?.result?.content?.length == 0) {
                this.alertService.showToaster('No Book Found', '', '');
              } else {
                this.alertService.showToaster(res.message, '', '');
              }
              this.booksListSubject.next(res.result);
              // this.loader.hide();
            }
          });
      } else {
        this.layoutEndPoint
          .getAllBooksWithDesignData(pageNo, pageSize, searchText, userRights, approvalStatus)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((res: any) => {
            /*istanbul ignore else*/
            if (res) {
              if (res?.result?.content?.length == 0) {
                this.alertService.showToaster('No Book Found', '', '');
              } else {
                this.alertService.showToaster(res.message, '', '');
              }
              this.booksListSubject.next(res.result);
              // this.loader.hide();
            }
          });
      }
    } else {
      if (selectedWeek) {
        const reqTime = moment()
          .utc()
          .subtract(7 * selectedWeek, 'days')
          .format();
        let epochTime = new Date(reqTime).getTime();

        this.layoutEndPoint
          .getAllBooksWithDesignData(pageNo, pageSize, searchText, userRights, undefined, epochTime)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((res: any) => {
            /*istanbul ignore else*/
            if (res) {
              if (res?.result?.content?.length == 0) {
                this.alertService.showToaster('No Book Found', '', '');
              } else {
                this.alertService.showToaster(res.message, '', '');
              }
              this.booksListSubject.next(res.result);
              // this.loader.hide();
            }
          });
      } else {
        this.layoutEndPoint
          .getAllBooksWithDesignData(pageNo, pageSize, searchText, userRights, undefined)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((res: any) => {
            /*istanbul ignore else*/
            if (res) {
              if (res?.result?.content?.length == 0) {
                this.alertService.showToaster('No Book Found', '', '');
              } else {
                this.alertService.showToaster(res.message, '', '');
              }
              this.booksListSubject.next(res.result);
              // this.loader.hide();
            }
          });
      }
    }
  }

  fetachDesignSystemData(data: any) {
    this.designSystemData.next(data);
  }
  getIndustries() {
    this.layoutEndPoint
      .getIndustries()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.getIndustryList.next(res);
      });
  }
  storeGsiIds(ids: any) {
    this.storeIdsOfGsi.next(ids);
  }

  storeCuIds(ids: any) {
    this.storeIdsOfCus.next(ids);
  }

  ////////////Fetching Indusry List////////////
  getBookCovers(ontologyId: any, limit: any, page: any) {
    this.layoutEndPoint
      .getBookCovers(ontologyId, limit, page)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.getBookCoversList.next(res);
      });
  }
  getGsiIcons(ontologyId: any, limit: any, page: any) {
    this.layoutEndPoint
      .getGsiIcons(ontologyId, limit, page)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.getGsiIconsList.next(res);
      });
  }

  getEntityIcons(ontologyId: any, limit: any, page: any) {
    this.layoutEndPoint
      .getEntityIcons(ontologyId, limit, page)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.getEntityIconsList.next(res);
      });
  }
  getCuIcons(ontologyId: any, limit: any, page: any) {
    this.layoutEndPoint
      .getCuIcons(ontologyId, limit, page)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.getCuIconsList.next(res);
      });
  }

  getAllChapters() {
    return this.layoutEndPoint.getAllChapters();
  }

  storeBookDetails(bookDetails: any) {
    this.layoutEndPoint.storeBookData(bookDetails);
  }

  saveDesign(bookDetails: any) {
    this.loader.show();
    this.layoutEndPoint
      .saveDesign(bookDetails)
      .pipe(
        catchError((error) => {
          console.log(error.message);
          this.bookDetails.next('false'); // when deign api fails return false
          this.alertService.showToaster(error.error.message, '', 'error');
          return throwError(error);
        })
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.alertService.showToaster('Design Got Saved Successfully', '', 'success');
        this.loader.hide();
        this.savedTemplate.next(res.templateId);
      });
  }

  updatedTemplateId(templateId: any) {
    this.savedTemplate.next(templateId);
  }

  fetchBookDetails(bookId: any) {
    this.layoutEndPoint
      .fetchBookData(bookId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.layoutBookDesign.next(res);
      });
  }

  getListOfBooks(searchText: string) {
    this.layoutBookSearch.next(searchText);
  }

  clearBookSearchText(searchText: string) {
    this.bookSearchText.next(searchText);
  }

  storeChangeUnitDetails(bookDetails: any) {
    this.layoutEndPoint.storeChangeUnitData(bookDetails);
  }

  fetchChangeUnitDetails(bookId: any) {
    this.layoutEndPoint
      .fetchBookData(bookId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.layoutChangeUnitDesign.next(res);
      });
  }

  fetchUiControls(cuId: any) {
    this.layoutEndPoint
      .fetchBookData(cuId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.changeUnitUIControls.next(res);
      });
  }

  fetchLayoutDesign(bookId: any) {
    this.layoutEndPoint
      .getAllLayoutDesigns(bookId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.templateDesign.next(res);
      });
  }

  updateUserData(userId: any, body: any) {
    this.layoutEndPoint
      .updateUserData(userId, body)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.updateUser.next(res);
      });
  }

  weekWiseListUpdate(x: boolean) {
    this.weekWiseList.next(x);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getLibraryTemplateData() {
    this.layoutEndPoint
      .getLibraryTemplateData()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        localStorage.setItem('userRoles', JSON.stringify(res));
        this.getLayoutConfig.next(res);
      });
  }

  getLibraryLayoutById(layoutId: string) {
    this.layoutEndPoint
      .getLibraryTemplateDataById(layoutId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.getSiteLayoutData.next(res);
      });
  }

  getBookDataWithGsis(bookId: any) {
    this.layoutEndPoint
      .getGSIsByBookId(bookId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        res.bookId = bookId;
        this.LayoutGsisList.next(res);
      });
  }
}

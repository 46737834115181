import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(private toastr: ToastrService) {}

  showToaster(message: string, title: string, typeOfAlert: string, time?: any) {
    const alertType = typeOfAlert?.toLowerCase();
    if (message && message !== 'undefined') {
      switch (alertType) {
        case 'success':
          this.toastr.success(title, message, time ? { timeOut: time } : null);
          break;
        case 'error':
          this.toastr.error(title, message, time ? { timeOut: time } : null);
          break;
        case 'warning':
          this.toastr.warning(title, message, time ? { timeOut: time } : null);
          break;
        case 'info':
          this.toastr.info(title, message, time ? { timeOut: time } : null);
          break;
        default:
          this.toastr.info(title, message, time ? { timeOut: time } : null);
          break;
      }
    }
  }

  closeToaster() {
    this.toastr.clear();
  }
}

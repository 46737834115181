<div class="container">
  <div class="row home-page-content">
    <div class="search-relt-wrap">
      <div class="relts-row">
        <p>
          Search Results
          {{
            (searchGSIdata ? searchGSIdata.totalHits : 0) +
              (searchCUdata ? searchCUdata.totalHits : 0) +
              (searchEntitydata ? searchEntitydata.totalHits : 0) +
              (searchAttributedata ? searchAttributedata.totalHits : 0)
          }}
        </p>
      </div>

      <div class="relts-wrap">
        <div class="relts-left-pane">
          <h6>Filters</h6>
          <mat-form-field>
            <mat-select
              class="form-control cstm-selt"
              (selectionChange)="changeOption($event)"
              ngDefaultControl
              [(ngModel)]="searchSelected"
            >
              <mat-option value="All">{{ labels?.All }}</mat-option>
              <mat-option value="GSI">{{ labels?.GSI }}</mat-option>
              <mat-option value="CHANGE_UNIT">{{ labels?.Change_Unit }}</mat-option>
              <mat-option value="Entity">{{ labels?.Entity }}</mat-option>
              <mat-option value="Attribute">{{ labels?.Attribute }}</mat-option>
            </mat-select>
          </mat-form-field>
          <div class="relt-status">
            <h6>{{ labels?.Status }}</h6>
            <div class="d-flex">
              <button>{{ labels?.All }}</button>
              <button>{{ labels?.Drafts }}</button>
              <button>{{ labels?.Published }}</button>
            </div>
          </div>
          <!-- <div class="relt-input-wrap">
            <ul>
              <li><input type="text" placeholder="Date Range" class="reslt-inpt form-control" /></li>
              <li><input type="text" placeholder="By Designer" class="reslt-inpt form-control" /></li>
              <li><input type="text" placeholder="By Industry" class="reslt-inpt form-control" /></li>
            </ul>
            <button>More Filters</button>
          </div> -->
        </div>
        <div class="relts-right-pane" *ngIf="screen === 1">
          <div *ngIf="searchGSIdata?.totalHits > 0">
            <div class="relt-right-row">
              <button>{{ searchGSIdata.type }}</button>
              <span>{{ searchGSIdata.totalHits }} {{ labels?.Result }}</span>
              <button class="viewall-btn" (click)="viewAllSearchOption = 'GSI'; changeSearchOption()">View All</button>
            </div>
            <div class="relt-list-wrap" *ngFor="let hit of searchGSIdata?.hits">
              <ul *ngIf="searchGSIdata.type === 'GSI'">
                <li>
                  <div class="relt-list-item">
                    {{ hit.name }}
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="searchCUdata?.totalHits > 0">
            <div class="relt-right-row">
              <button>{{ searchCUdata.type }}</button>
              <span>{{ searchCUdata.totalHits }} {{ labels?.Result }}</span>
              <button class="viewall-btn" (click)="viewAllSearchOption = 'CHANGE_UNIT'; changeSearchOption()">
                View All
              </button>
            </div>
            <div class="relt-list-wrap cu-data" *ngFor="let hit of searchCUdata?.hits">
              <ul *ngIf="searchCUdata.type === 'CHANGE_UNIT'">
                <li>
                  <div class="relt-list-item">
                    {{ hit.name }}
                  </div>
                  <div class="relt-list-bredcrum">
                    <p>{{ hit.gsiName }}</p>
                    <mat-icon>chevron_right</mat-icon>
                    <span>{{ hit.name }}</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="searchEntitydata?.totalHits > 0">
            <div class="relt-right-row">
              <button>{{ searchEntitydata.type }}</button>
              <span>{{ searchEntitydata.totalHits }} {{ labels?.Result }}</span>
              <button class="viewall-btn" (click)="viewAllSearchOption = 'ENTITY'; changeSearchOption()">
                View All
              </button>
            </div>
            <div class="relt-list-wrap entity-data" *ngFor="let hit of searchEntitydata?.hits">
              <ul *ngIf="searchEntitydata.type === 'ENTITY'">
                <li>
                  <div class="relt-list-item">
                    {{ hit.name }}
                  </div>
                  <div class="relt-list-bredcrum">
                    <p>{{ hit.gsiName }}</p>
                    <mat-icon>chevron_right</mat-icon>
                    <p>{{ hit.cuName }}</p>
                    <mat-icon>chevron_right</mat-icon>
                    <span>{{ hit.name }}</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="searchAttributedata?.totalHits > 0">
            <div class="relt-right-row">
              <button>{{ searchAttributedata.type }}</button>
              <span>{{ searchAttributedata.totalHits }} {{ labels?.Result }}</span>
              <button class="viewall-btn" (click)="viewAllSearchOption = 'ATTRIBUTE'; changeSearchOption()">
                View All
              </button>
            </div>
            <div class="relt-list-wrap att-data" *ngFor="let hit of searchAttributedata?.hits">
              <ul *ngIf="searchAttributedata.type === 'ATTRIBUTE'">
                <li>
                  <div class="relt-list-item">
                    {{ hit.name }}
                  </div>
                  <div class="relt-list-bredcrum">
                    <p>{{ hit.gsiName }}</p>
                    <mat-icon>chevron_right</mat-icon>
                    {{ hit.cuName }}>
                    <mat-icon>chevron_right</mat-icon>
                    <p>{{ hit.entityName }}</p>
                    <mat-icon>chevron_right</mat-icon>
                    <span>{{ hit.name }}</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="relts-right-pane" *ngIf="screen === 2">
          <div *ngIf="viewAllSearchOption === 'GSI'">
            <div class="relt-right-row">
              <button>{{ paginatedGSI?.type }}</button>
              <span>{{ paginatedGSI?.totalHits }} {{ labels?.Result }}</span>
            </div>
            <div class="relt-list-wrap" *ngFor="let hit of paginatedGSI?.hits">
              <ul *ngIf="paginatedGSI.type === 'GSI'">
                <li>
                  <div class="relt-list-item">
                    {{ hit.name }}
                  </div>
                </li>
              </ul>
            </div>
            <mat-paginator
              class="mt-4"
              [length]="totalGSIcount"
              [pageSize]="pageSize"
              [pageSizeOptions]="[10, 25, 100]"
              (page)="changePage($event)"
            >
            </mat-paginator>
          </div>
          <div *ngIf="viewAllSearchOption === 'CHANGE_UNIT'">
            <div class="relt-right-row">
              <button>{{ paginatedCU?.type }}</button>
              <span>{{ paginatedCU?.totalHits }} {{ labels?.Result }}</span>
            </div>
            <div class="relt-list-wrap cu-data" *ngFor="let hit of paginatedCU?.hits">
              <ul *ngIf="paginatedCU.type === 'CHANGE_UNIT'">
                <li>
                  <div class="relt-list-item">
                    {{ hit.name }}
                  </div>
                  <div class="relt-list-bredcrum">
                    <p>{{ hit.gsiName }}</p>
                    <mat-icon>chevron_right</mat-icon>
                    <span>{{ hit.name }}</span>
                  </div>
                </li>
              </ul>
            </div>
            <mat-paginator
              class="mt-4"
              [length]="totalCUcount"
              [pageSize]="pageSize"
              [pageSizeOptions]="[10, 25, 100]"
              (page)="changePage($event)"
            >
            </mat-paginator>
          </div>
          <div *ngIf="viewAllSearchOption === 'ENTITY'">
            <div class="relt-right-row">
              <button>{{ paginatedEntity?.type }}</button>
              <span>{{ paginatedEntity?.totalHits }} {{ labels?.Result }}</span>
            </div>
            <div class="relt-list-wrap entity-data" *ngFor="let hit of paginatedEntity?.hits">
              <ul *ngIf="paginatedEntity.type === 'ENTITY'">
                <li>
                  <div class="relt-list-item">
                    {{ hit.name }}
                  </div>
                  <div class="relt-list-bredcrum">
                    <p>{{ hit.gsiName }}</p>
                    <mat-icon>chevron_right</mat-icon>
                    <p>{{ hit.cuName }}</p>
                    <mat-icon>chevron_right</mat-icon>
                    <span>{{ hit.name }}</span>
                  </div>
                </li>
              </ul>
            </div>
            <mat-paginator
              class="mt-4"
              [length]="totalEntitycount"
              [pageSize]="pageSize"
              [pageSizeOptions]="[10, 25, 100]"
              (page)="changePage($event)"
            >
            </mat-paginator>
          </div>
          <div *ngIf="viewAllSearchOption === 'ATTRIBUTE'">
            <div class="relt-right-row">
              <button>{{ paginatedAttribute?.type }}</button>
              <span>{{ paginatedAttribute?.totalHits }} {{ labels?.Result }}</span>
            </div>
            <div class="relt-list-wrap att-data" *ngFor="let hit of paginatedAttribute?.hits">
              <ul *ngIf="paginatedAttribute.type === 'ATTRIBUTE'">
                <li>
                  <div class="relt-list-item">
                    {{ hit.name }}
                  </div>
                  <div class="relt-list-bredcrum">
                    <p>{{ hit.gsiName }}</p>
                    <mat-icon>chevron_right</mat-icon>
                    {{ hit.cuName }}>
                    <mat-icon>chevron_right</mat-icon>
                    <p>{{ hit.entityName }}</p>
                    <mat-icon>chevron_right</mat-icon>
                    <span>{{ hit.name }}</span>
                  </div>
                </li>
              </ul>
            </div>
            <mat-paginator
              class="mt-4"
              [length]="totalAttributecount"
              [pageSize]="pageSize"
              [pageSizeOptions]="[10, 25, 100]"
              (page)="changePage($event)"
            >
            </mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';

import { TranslatorService } from '@app/shell/translator.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bread-crumb',
  templateUrl: './bread-crumb.component.html',
})
export class BreadCrumbComponent implements OnInit, OnChanges, OnDestroy {
  @Input() type: any;
  @Output() resetCarnivalBets: any = new EventEmitter();
  labels: any;
  private ngUnsubscribe = new Subject();
  breadCrumbList: any = [];

  constructor(private router: Router, private translator: TranslatorService) {
    this.detectLanguageChange();
  }

  ngOnChanges(change: SimpleChanges) {
    setTimeout(() => {
      /* istanbul ignore next */
      this.loadBreadCrumbs(change?.type?.currentValue);
    }, 200);
  }

  ngOnInit(): void {}

  loadBreadCrumbs(type: any) {
    switch (type) {
      case 'generatereport':
        /* istanbul ignore next */
        this.breadCrumbList = [{ label: this.labels?.My_Library, value: 'mylibrary' }];
        break;

      case 'managetenant':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: this.labels?.Manage_Tenants, value: 'admin/transactions' },
        ];
        break;

      case 'translationconfig':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: this.labels?.Translation_Config, value: 'admin/transactions' },
        ];
        break;

      case 'uicustomization':
        /* istanbul ignore next */
        this.breadCrumbList = [{ label: this.labels?.Admin, value: 'admin' }];
        break;

      case 'manageSelfOnboardtenant':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: this.labels?.Manage_Tenants, value: 'admin/transactions' },
        ];
        break;

      case 'admin':
        /* istanbul ignore next */
        this.breadCrumbList = [{ label: this.labels?.Admin, value: 'admin' }];
        break;

      case 'adminroles':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: this.labels?.Manage_Roles, value: 'admin/transactions' },
        ];
        break;

      case 'adminorgUnit':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: this.labels?.Manage_Organisation_Unit, value: 'admin/transactions' },
        ];
        break;

      case 'adminusers':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: this.labels?.Manage_Users, value: 'admin/transactions' },
        ];
        break;

      case 'adminconfigration':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: this.labels?.Manage_Configuration, value: 'admin/transactions' },
        ];
        break;

      case 'admingroups':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: this.labels?.Manage_Groups, value: 'admin/transactions' },
        ];
        break;

      case 'adminldap':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: this.labels?.Manage_ID_Providers, value: 'admin/transactions' },
        ];
        break;

      case 'sfadmindashboard':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: this.labels?.Manage_Configurations, value: 'admin/transactions' },
        ];
        break;

      case 'addadapter':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: this.labels?.Manage_Integrations, value: 'admin/transactions' },
        ];
        break;
      case 'manage_sub_tenant':
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: this.labels?.Manage_Sub_Tenants, value: 'admin/transactions' },
        ];
        break;
      case 'roledelegation':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.My_Library, value: 'mylibrary' },
          { label: this.labels?.Delegation, value: 'admin/transactions' },
        ];
        break;

      case 'userdelegation':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.My_Library, value: 'mylibrary' },
          { label: this.labels?.Preferences, value: 'preferences' },
        ];
        break;

      case 'exportsolutions':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.My_Library, value: 'mylibrary' },
          { label: this.labels?.Preferences, value: 'preferences' },
          { label: this.labels?.Manage_Solutions, value: 'transaction/my-collection' },
        ];
        break;

      case 'managedata':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: this.labels?.Manage_Data, value: 'admin/transactions' },
        ];
        break;
      case 'manageconfiguration':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: 'manageconfiguration', value: 'admin/transactions' },
        ];
        break;
      case 'managesolutions':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: this.labels?.Manage_Solutions, value: 'admin/transactions' },
        ];
        break;

      case 'manageenv':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: this.labels?.Manage_Environments, value: 'admin/transactions' },
        ];
        break;

      case 'manageaudit':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: this.labels?.Trace_Audit, value: 'admin/transactions' },
        ];
        break;

      case 'managegeirdr':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: this.labels?.Manage_GE_IRDR, value: 'admin/transactions' },
        ];
        break;

      case 'manageSubscription':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: 'Manage Subscription', value: 'admin/transactions' },
        ];
        break;
      case 'manageBookRoles':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: 'Manage IRDR(Book)', value: 'admin/transactions' },
        ];
        break;
      case 'managePasswordPolicy':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: this.labels?.Manage_Password_Policy, value: 'admin/transactions' },
        ];
        break;
      case 'manageEnvironments':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: this.labels?.Manage_Environments, value: 'admin/transactions' },
        ];
        break;
      case 'managesaasapplication':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: 'Manage Saas Applications', value: 'admin/transactions' },
        ];
        break;
      case 'carnivalroles':
        /* istanbul ignore next */
        this.breadCrumbList = [{ label: this.labels?.Manage_Roles, value: 'carnival' }];
        break;
      case 'carnivalusers':
        /* istanbul ignore next */
        this.breadCrumbList = [{ label: this.labels?.Manage_Users, value: 'carnival' }];
        break;
      case 'authenticationPolicy':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: this.labels?.Authentication_Policy, value: 'admin/transactions' },
        ];
        break;
      case 'addKey':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: this.labels?.Add_Key, value: 'admin/transactions' },
        ];
        break;
      case 'viewKey':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: this.labels?.View_Key, value: 'admin/transactions' },
        ];
        break;
      case 'tenantsetupandcustomisation':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: this.labels?.Tenant_Setup_and_Customisation, value: 'admin/transactions' },
        ];
        break;
      case 'viewpermissions':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: 'Manage Permission Sets', value: 'admin/transactions' },
        ];
        break;
      case 'bookownership':
        /* istanbul ignore next */
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: 'Book Ownership', value: 'admin/transactions' },
        ];
        break;
      case 'Bot_Configuration':
        this.breadCrumbList = [
          { label: this.labels?.Admin, value: 'admin' },
          { label: this.labels?.Manage_Configurations, value: 'admin/transactions' },
        ];
    }
  }

  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }

  openGsis(type: any) {
    if (type === 'mylibrary') {
      this.router.navigateByUrl(`mylibrary`);
    } else if (type === 'admin') {
      this.router.navigateByUrl(`admin`);
    } else if (type === 'admin/transactions') {
      this.router.navigateByUrl(`admin/transactions`);
    } else if (type === 'preferences') {
      this.router.navigateByUrl(`preferences`);
    } else if (type === 'carnival') {
      this.resetCarnivalBets.emit(true);
    } else if (type === 'transaction/my-collection') {
      this.router.navigateByUrl(`transaction/my-collection`);
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}

import { Injectable } from '@angular/core';
import { ObservableStore } from '@codewithdan/observable-store';
import { EntityList } from '@app/@shared/models/entity-list';

export interface StoreState {
  entityList: EntityList[];
}
@Injectable({
  providedIn: 'root',
})
export class EntityStoreService extends ObservableStore<StoreState> {
  constructor() {
    super({ trackStateHistory: true, logStateChanges: false });
  }

  getStateData() {
    return this.getState();
  }
  setStateData(data: any) {
    this.setState({ entityList: data }, EntityStoreActions.SetEntities);
  }
}
export enum EntityStoreActions {
  SetEntities = 'SET_Entities',
}

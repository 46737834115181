import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { getEnvClientId } from '@app/@core/generic-functions';
import { CduiBaseService } from '@app/auth/cdui-base.service';
import { UserIdleModule } from 'angular-user-idle';

@Injectable({
  providedIn: 'root',
})
export class UsersEndpointService extends CduiBaseService {
  tenantName: string;

  constructor(private injector: Injector, private http: HttpClient) {
    super(injector);
  }

  getAllUser() {
    /* istanbul ignore next */
    return this.http.get(this.api?.getAllUser);
  }

  getAllCdmUser() {
    /* istanbul ignore next */
    return this.http.get(this.api?.getAllCdmUsers);
  }

  getPaginateUser(pageNumber: number, pageSize: number) {
    /* istanbul ignore next */
    return this.http.post(this.api?.getPaginateUser + `pageNumber=${pageNumber}&pageSize=${pageSize}`, {});
  }

  getAllBooks(pagenum: number, pageSize: number, searchCriteria: string) {
    searchCriteria = searchCriteria ? searchCriteria : '';
    this.tenantName = getEnvClientId();
    if (this.tenantName === 'b2c') {
      /* istanbul ignore next */
      return this.http.get(
        `${this.api?.getAllTenantBookB2c}/books?pageNumber=${pagenum}&pageSize=${pageSize}&searchCriteria=${searchCriteria}`
      );
    } else {
      /* istanbul ignore next */
      return this.http.get(
        `${this.api?.getAllTenantBooks}/matching?pageNumber=${pagenum}&pageSize=${pageSize}&searchCriteria=${searchCriteria}`
      );
    }
  }

  getPaginateUsers(pageNumber: number, pageSize: number, searchString: string, isActive?: boolean) {
    isActive = isActive ? isActive : false;
    /* istanbul ignore next */
    return this.http.post(
      this.api?.getPaginateUsers +
        `pageNumber=${pageNumber}&pageSize=${pageSize}&searchTerm=${searchString}&isActive=${isActive}`,
      {}
    );
  }
  getTimezone() {
    /* istanbul ignore next */
    return this.http.get(this.api?.getTimeone);
  }

  getPaginateUsersTenantAdmin(pageNumber: number, pageSize: number, searchString: string) {
    /* istanbul ignore next */
    return this.http.get(
      `${this.api?.getPaginateUsersTenantAdmin}?pageNo=${pageNumber}&pageSize=${pageSize}&searchCriteria=${searchString}`,
      {}
    );
  }

  getUsersByRights(triggetCuId: any) {
    /* istanbul ignore next */
    return this.http.get(this.api?.getUsersByRights + `triggerCuId=${triggetCuId}`);
  }

  createUser(postData: any) {
    /* istanbul ignore next */
    return this.http.post(this.api?.createUser, postData);
  }

  updateUser(updateData: any, userId: number) {
    /* istanbul ignore next */
    return this.http.post(`${this.api?.updateUser}type=graph&userId=${userId}`, updateData);
  }

  updateUserAsTenantAdmin(updateData: any, userId: number) {
    /* istanbul ignore next */
    return this.http.post(`${this.api?.updateUserAsTenantAdmin}?userId=${userId}`, updateData);
  }

  updateUserPassword(updateData: any) {
    /* istanbul ignore next */
    return this.http.post(`${this.api?.updateUserPassword}type=graph`, updateData);
  }

  deleteUser(deleteUserData: any, userId: any) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: deleteUserData,
    };
    /* istanbul ignore next */
    return this.http.delete(`${this.api?.deleteUser}type=graph&userId=${userId}`, httpOptions);
  }

  confirmDeleteModal(deleteUserData: any, userId: any, wishToContinue: boolean) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: deleteUserData,
    };
    /* istanbul ignore next */
    return this.http.delete(
      `${this.api?.confirmDeleteModal}type=graph&userId=${userId}&wishToContinue=${true}`,
      httpOptions
    );
  }

  getApplicateJsonHeaders() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }

  importUserFromCSV(postData: any, env?: any) {
    return this.http.post(this.api?.importUserFromCSV, postData);
  }

  getActiveUser() {
    /* istanbul ignore next */
    return this.http.get(this.api?.getActiveUser);
  }

  getUsersCount() {
    /* istanbul ignore next */
    return this.http.get(this.api?.getUsersCount);
  }

  loadUnassignedRoleUsers(pageNumber: number, pageSize: number, searchString: string, roleId: number) {
    /* istanbul ignore next */
    return this.http.post(
      this.api?.loadUnassignedRoleUsers +
        `pageNumber=${pageNumber}&pageSize=${pageSize}&roleId=${roleId}&searchTerm=${searchString}`,
      {}
    );
  }

  loadAssignedRoleUsers(pageNumber: number, pageSize: number, searchString: string, roleId: number) {
    /* istanbul ignore next */
    return this.http.get(
      this.api?.loadAssignedRoleUsers +
        `pageNumber=${pageNumber}&pageSize=${pageSize}&roleId=${roleId}&searchTerm=${searchString}`
    );
  }

  loadUnassignedGroupUsers(pageNumber: number, pageSize: number, searchString: string, groupId: number) {
    /* istanbul ignore next */
    return this.http.post(
      this.api?.loadUnassignedGroupUsers +
        `groupId=${groupId}&pageNumber=${pageNumber}&pageSize=${pageSize}&searchTerm=${searchString}`,
      {}
    );
  }

  loadAssignedGroupUsers(pageNumber: number, pageSize: number, searchString: string, groupId: number) {
    /* istanbul ignore next */
    return this.http.get(this.api?.loadAssignedGroupUsers + `type=graph&group=${groupId}`);
  }

  // delegation
  loadUserDelegations(pageNumber: number, pageSize: number, searchString: string, userId: any) {
    /* istanbul ignore next */
    return this.http.get(
      this.api?.loadUserDelegations +
        `pageNo=${pageNumber}&pageSize=${pageSize}&searchCriteria=${searchString}&userId=${userId}`
    );
  }

  createDelegation(postData: any) {
    /* istanbul ignore next */
    return this.http.post(this.api?.createDelega, postData);
  }

  getDetailsByTargetUserId(fromUserId: number, toUserId: number, delegationRule: any) {
    /* istanbul ignore next */
    return this.http.get(this.api?.getDetailsByTargetUserId + `${fromUserId}/${toUserId}/${delegationRule}`);
  }

  deleteDelegation(delegationId: any) {
    /* istanbul ignore next */
    return this.http.delete(this.api?.deleteDelegat + `id=${delegationId}`);
  }

  getDelegationById(delegationId: any) {
    /* istanbul ignore next */
    return this.http.get(this.api?.getDelegatById + `id=${delegationId}`);
  }

  /*getTenantDesignData(tenantName: string) {
    // return this.http.get(this.env.dsdOrchUrl + `/design/tenant/design/basic/${tenantName}`).pipe(pluck('result'));
    return this.http.get(this.api?.getTenantDesignData + `${tenantName}`).pipe(pluck('result'));
  }*/

  /**
   * Gets password policy by based upon the tenant details
   * @param tenantName
   * @returns
   */
  getPasswordPolicyByAdmin(tenantName: string) {
    /* istanbul ignore next */
    return this.http.get(this.api?.getPasswordPolicyByAdmin + `tenantName=${tenantName}`);
  }

  /**
   * Gets account locked by based upon the tenant details
   * @param tenantName
   * @returns
   */
  getAccountLockParameter(tenantName: string) {
    /* istanbul ignore next */
    return this.http.get(this.api?.getAccountLockParameter + `realmName=${tenantName}`);
  }

  /**
   * Sets password policy by tenant admin
   * @param payload
   * @returns
   */
  setPasswordPolicyByAdmin(payload: any) {
    /* istanbul ignore next */
    return this.http.post(this.api?.setPasswordPolicyByAdmin, payload);
  }

  /**
   * Sets account locked by tenant admin
   * @param payload
   * @returns
   */
  setAccountLockParameter(payload: any) {
    /* istanbul ignore next */
    return this.http.post(this.api?.setAccountLockParameter, payload);
  }

  getInvitationHistory() {
    /* istanbul ignore next */
    return this.http.get(this.api?.getInvitationHistory);
  }

  invitingUsers(postData: any) {
    /* istanbul ignore next */
    return this.http.post(this.api?.invitingUsers, postData);
  }

  getGSIDetails(id: number) {
    /* istanbul ignore next */
    return this.http.get(this.api?.getGsiDetail + id);
  }

  createSocialUser(postData: any) {
    /* istanbul ignore next */
    return this.http.post(this.api?.createSocialUser, postData);
  }

  /* carnival userprofile */
  addUserProfile(data: any) {
    /* istanbul ignore next */
    return this.http.post(this.api?.addUserProfile, data);
  }

  getUserProfile() {
    /* istanbul ignore next */
    return this.http.get(this.api?.addUserProfile);
  }

  getMatchingFeatureflag(flag: any) {
    /* istanbul ignore next */
    return this.http.get(`${this.api?.getCarnivalMatchingFeatureFlag}${flag}`);
  }

  /* ./carnival userprofile */
  fetchFeatureDetail(pageIndex: number, pageSize: number, tenantId: any) {
    /* istanbul ignore next */
    return this.http.get(
      this.api?.fetchPermissionSets + `pageNumber=${pageIndex}&pageSize=${pageSize}&tenantId=${tenantId}`
    );
  }

  addPermissionSet(payload: any) {
    /* istanbul ignore next */
    return this.http.post(this.api?.addPermissionSets, payload);
  }

  getAllFeatureFlags() {
    /* istanbul ignore next */
    return this.http.get(this.api?.getAllFeatureFlags);
  }

  assignUserAsTenant(email: any) {
    /* istanbul ignore next */
    return this.http.post(this.api?.assignAsTenant + email, {});
  }

  assignUserAsEnv(email: any, environmentName: any) {
    /* istanbul ignore next */
    return this.http.post(this.api?.assignAsEnv + `envAdminUser?email=${email}&envName=${environmentName}`, {});
  }

  unassignUserAsTenant(email: any) {
    /* istanbul ignore next */
    return this.http.post(this.api?.unassignAsTenant + email, {});
  }

  unassignUserAsEnv(email: any, environmentName: any) {
    /* istanbul ignore next */
    return this.http.post(this.api?.unassignAsEnv + `envAdminUser?email=${email}&envName=${environmentName}`, {});
  }

  updatePersonal(payload: any, email: any) {
    /* istanbul ignore next */
    return this.http.post(this.api?.updateProfile + email, payload);
  }

  passwordReseting(payload: any) {
    /* istanbul ignore next */
    return this.http.post(this.api?.passwordReseting, payload);
  }

  getPaginateManager(pageNumber: number, pageSize: number, searchString: string) {
    /* istanbul ignore next */
    return this.http.post(
      this.api?.getPaginateUsers + `pageNumber=${pageNumber}&pageSize=${pageSize}&searchTerm=${searchString}`,
      {}
    );
  }
  getPaginateUnAssignedManager(pageNumber: number, pageSize: number, searchString: string, managerID: any) {
    return this.http.post(
      this.api?.getPaginateUnAssignUsers +
        `pageNumber=${pageNumber}&pageSize=${pageSize}&searchTerm=${searchString}&managerID=${managerID}`,
      {}
    );
  }

  assignUsersToManager(payload: any, id: any) {
    /* istanbul ignore next */
    return this.http.post(this.api?.assignUsersToManager + id, payload);
  }
  unassignUserToManager(managerID: any, userID: any) {
    return this.http.post(this.api?.unAssignUserToManager + `${managerID}/${userID}`, {});
  }

  assignedUsersList(id: any) {
    /* istanbul ignore next */
    return this.http.get(this.api?.assignedUsersList + id);
  }
  getActiveSessionsCount(id: any) {
    /* istanbul ignore next */
    return this.http.get(this.api?.getActiveSessionsCount + id);
  }
  getActiveUserSessionDetails(id: any, pageSize: number, pageIndex: number) {
    /* istanbul ignore next */
    return this.http.get(this.api?.getActiveUserSessionDetails + `${id}&pageNumber=${pageIndex}&pageSize=${pageSize}`);
  }
  getUnassignedOwnerList(pageNumber: number, pageSize: number, searchString: string, payload: any) {
    /* istanbul ignore next */
    return this.http.put(
      this.api?.availableUser + `?pageNo=${pageNumber}&pageSize=${pageSize}&searchTerm=${searchString}`,
      payload
    );
  }

  getBooksByOwner(pageNumber: number, pageSize: number, searchString: string) {
    /* istanbul ignore next */
    return this.http.get(
      this.api?.getBooksByOwner +
        `?pageNo=${pageNumber}&pageSize=${pageSize}&searchTerm=${searchString}&statusList=DRAFT,PUBLISHED`
    );
  }

  getSolutionsByOwner(pageNumber: number, pageSize: number, searchString: string) {
    /* istanbul ignore next */
    return this.http.get(
      this.api?.getSolutionsByOwner +
        `?pageNo=${pageNumber}&pageSize=${pageSize}&searchTerm=${searchString}&statusList=PUBLISHED`
    );
  }

  assignOwnersToBookSol(payload: any) {
    /* istanbul ignore next */
    return this.http.post(this.api?.assignOwnerToBookSol, payload);
  }

  unassignOwnersToBookSol(payload: any) {
    /* istanbul ignore next */
    return this.http.post(this.api?.unassignOwnerToBookSol, payload);
  }

  assignedOwnersList(pageNumber: number, pageSize: number, searchString: string, payload: any) {
    /* istanbul ignore next */
    return this.http.put(
      this.api?.assignedOwnerToBookSol + `?pageNo=${pageNumber}&pageSize=${pageSize}&searchTerm=${searchString}`,
      payload
    );
  }
}

import { HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
// import { AngularFireModule } from '@angular/fire';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from '@core';
import { environment } from '@env/environment';
import { SharedModule } from '@shared';
import { UserIdleModule } from 'angular-user-idle';
// import * as firebase from 'firebase/app';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { TranslatorService } from '../app/shell/translator.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ConfigService } from './auth/app-config.service';
import { AppConfigModel } from './auth/app-config.service.model';
import { AppInitService } from './auth/appInit.service';
import { HttpCacheService } from './cache/cache.service';
import { MaterialModule } from './material-module';
import { ShellModule } from './shell/shell.module';

//this function initializes keycloak service
// Added app-config to avoid env specific files
export function initializer(
  keycloak: KeycloakService,
  appInitService: AppInitService,
  httpClient: HttpClient,
  configService: ConfigService
): () => Promise<any> {
  return (): Promise<any> => {
    return new Promise<void>(async (resolve, reject) => {
      configService.setConfig().then(
        (appConfigData: AppConfigModel) => {
          const localStorageTenant = localStorage.getItem('TenantName');
          const localStorageEnvClientId = localStorage.getItem('envClientId');
          const urlLocation = window.location.host;
          const b2b2cState = window.location.href.indexOf('b2b2c') !== -1 ? true : false;
          let tenantName: any;
          // if (appConfigData.firebase) {
          //   firebase.initializeApp(appConfigData.firebase);
          // }
          if (urlLocation.includes('localhost') || urlLocation.includes('10.')) {
            tenantName = appConfigData.defaultTenantName;
          } else {
            tenantName = urlLocation.split('.')[0];
          }
          const keyCloakOptions: any = {
            config: {
              url: `${appConfigData.loginAuth}`,
            },
            loadUserProfileAtStartUp: true,
            initOptions: {
              onLoad: 'check-sso',
              checkLoginIframe: false,
              flow: 'implicit',
              enableLogging: true,
              silentCheckSsoRedirectUri: window?.location?.origin + '/assets/silent-check-sso.html',
            },
            bearerExcludedUrls: [],
          };
          if (
            localStorageEnvClientId &&
            localStorageTenant &&
            tenantName.toLocaleLowerCase() === localStorageTenant.toLocaleLowerCase()
          ) {
            keyCloakOptions.config.realm = localStorageTenant;
            keyCloakOptions.config.clientId = localStorageEnvClientId;
            keycloak.init(keyCloakOptions);
            resolve();
          } else {
            /* istanbul ignore next */
            // if (tenantName === 'b2c' || b2b2cState) {
            //setting realm for b2b2c
            if (b2b2cState) {
              let decodedUrl = decodeURIComponent(window.location.href);
              tenantName = new URL(decodedUrl).searchParams.get('tenantId');
            }
            try {
              let tenantParameter: string;
              /*istanbul ignore next*/
              if (urlLocation.includes('localhost') || urlLocation.includes('10.')) {
                tenantName = appConfigData.defaultTenantName;
                tenantParameter = `name=${tenantName}`;
              } else {
                let urlArray = urlLocation.split('.');
                if (urlArray.length === 5) {
                  tenantParameter = 'appName=' + urlArray[0] + '&name=' + urlArray[1];
                  tenantName = urlArray[1];
                } else {
                  tenantParameter = 'name=' + urlArray[0];
                  tenantName = urlArray[0];
                }
              }
              appInitService.getEnvBasicInfo(tenantParameter).subscribe((res: any) => {
                if (res) {
                  const { result } = res;
                  if (result && result?.tenantName && res.status == 'OK') {
                    localStorage.setItem('canDesign', result.canDesign);
                    localStorage.setItem('environmentName', result.environmentName);
                    localStorage.setItem('environmentType', result.environmentType);
                    localStorage.setItem('TenantName', result.tenantName);
                    localStorage.setItem('TenantId', result.tenantId);
                    localStorage.setItem('envClientId', result.envClientId);
                    localStorage.setItem('canUsersSelfSignUp', result.canUsersSelfSignUp);
                    // localStorage.setItem('canCreateSubTenants', result.canCreateSubTenants);
                    localStorage.setItem('loginApiKey', appConfigData.loginApiKey);
                    localStorage.setItem('TenantType', result.tenantType);
                    keyCloakOptions.config.realm = result.tenantName;
                    keyCloakOptions.config.clientId = result.tenantName;
                    keycloak.init(keyCloakOptions);
                  }
                }
              });

              resolve();
            } catch (error) {
              reject(error);
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
    });
  };
}

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    SharedModule,
    ShellModule,
    MaterialModule,
    environment.production ? BrowserAnimationsModule : NoopAnimationsModule,
    MatTableModule,
    MatSortModule,
    UserIdleModule.forRoot({ idle: 7200, timeout: 250 }),
    KeycloakAngularModule,
    // AngularFireModule.initializeApp({}),
    AppRoutingModule, // must be imported as the last module as it contains the fallback route
  ],
  providers: [
    TranslatorService,
    HttpCacheService,
    AppInitService,
    KeycloakService,
    ConfigService,
    HttpClient,
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      multi: true,
      deps: [KeycloakService, AppInitService, HttpClient, ConfigService],
    },
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}

import { Routes, Route } from '@angular/router';

import { AuthenticationGuard } from '@app/auth';
import { ShellComponent } from './shell.component';

/**
 * Provides helper methods to create routes.
 */

export class Shell {
  //
  /**
   * Creates routes using the shell component and authentication.
   * @param routes The routes to add.
   * @return The new route using shell as the base.
   */

  static addGaurds(routes: Routes): Routes {
    const noGuardPaths = [
      'solution-dictionary',
      'nsl-hub',
      'carnival',
      'onboarding',
      'carnival-login',
      'carnival-signup',
    ];
    routes = routes.map((x) => (noGuardPaths.includes(x.path) ? x : { ...x, canActivate: [AuthenticationGuard] }));
    return routes;
  }
}

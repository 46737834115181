import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CduiBaseService } from '@app/auth/cdui-base.service';
@Injectable({
  providedIn: 'root',
})
export class OrganisationUnitsEndpointService extends CduiBaseService {
  constructor(private injector: Injector, private http: HttpClient) {
    super(injector);
  }

  getAllOrgUnit() {
    return this.http.get(this.api?.getAllOrgUnits);
  }

  createOrgUnit(postOrgUnit: any) {
    return this.http.post(this.api?.createOrgUnit, postOrgUnit);
  }

  updateOrgUnit(postOrgUnit: any) {
    return this.http.post(this.api?.createOrgUnit, postOrgUnit);
  }

  loadAllOrgUnits() {
    return this.http.get(this.api?.getAllOrgUnits);
  }

  getPaginateOrgUnit(pageNumber: number, pageSize: number, searchString: string) {
    searchString = searchString ? searchString : '';
    const payload: any = [];
    return this.http.post(
      this.api?.getPaginateOrgUnits + `pageNumber=${pageNumber}&pageSize=${pageSize}&searchCriteria=${searchString}`,
      payload
    );
  }

  getAllOrgUnitById(orgUnitid: number) {
    return this.http.get(this.api?.getOrgUnitById + `${orgUnitid}`);
  }

  getAllOrgUnitByName(orgUnitName: any) {
    return this.http.get(this.api?.getOrgUnitByName + `/${orgUnitName}`);
  }

  addUserInOrgUnit(orgUnitData: any, orgUnitId: number) {
    return this.http.post(this.api?.addUserInOrgUnit + `orgUnitId=${orgUnitId}`, orgUnitData);
  }
  deleteUserInOrgUnit(orgUnitData: any, orgUnitId: number) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: [orgUnitData],
    };
    return this.http.delete(this.api?.deleteUserInOrgUnit + `orgUnitId=${orgUnitId}`, options);
  }

  loadUnassignUsersInOrgUnit(orgUnitId: number, pageNumber: number, pageSize: number, searchString?: string) {
    if (searchString) {
      return this.http.get(
        this.api?.getUnassignUsersInOrgUnit +
          `orgUnitId=${orgUnitId}&pageNo=${pageNumber}&pageSize=${pageSize}&searchCriteria=${searchString}`
      );
    }
    return this.http.get(
      this.api?.getUnassignUsersInOrgUnit + `orgUnitId=${orgUnitId}&pageNo=${pageNumber}&pageSize=${pageSize}`
    );
  }

  loadAssignUsersInOrgUnit(pageNumber: number, pageSize: number, searchString: string, orgUnitId: number) {
    return this.http.get(
      this.api?.getAssignUsersInOrgUnit +
        `orgUnitId=${orgUnitId}&pageNo=${pageNumber}&pageSize=${pageSize}&searchCriteria=${searchString}`
    );
  }

  loadUnassignRolesInOrgUnit(pageNumber: number, pageSize: number, searchString: string, orgUnitId: number) {
    return this.http.get(
      this.api?.getUnassignRolesInOrgUnit +
        `orgUnitId=${orgUnitId}&pageNo=${pageNumber}&pageSize=${pageSize}&searchCriteria=${searchString}`
    );
  }

  loadAssignRolesInOrgUnit(pageNumber: number, pageSize: number, searchString: string, orgUnitId: number) {
    return this.http.get(
      this.api?.getAssignRolesInOrgUnit +
        `orgUnitId=${orgUnitId}&pageNo=${pageNumber}&pageSize=${pageSize}&searchCriteria=${searchString}`
    );
  }

  loadAllUsersInOrgUnit(orgUnitid: number) {
    return this.http.get(this.api?.getUsersInOrgUnit + `orgUnitId=${orgUnitid}`);
  }
}

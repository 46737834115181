import { Injectable } from '@angular/core';
import { TenantCustomEndpointService } from './tenant-custom-endpoint.service';
import { AlertService } from '@app/@core/toaster/alert.service';
import { BehaviorSubject, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/internal/operators/catchError';
import { LoaderService } from '@app/@shared/services/loader/loader.service';
@Injectable({
  providedIn: 'root',
})
export class TenantCustomUiFacadeService {
  savedTemplate = new BehaviorSubject('');
  savedTemplate$ = this.savedTemplate.asObservable();

  savedLayouts = new BehaviorSubject('');
  savedLayouts$ = this.savedLayouts.asObservable();

  adminHeader = new Subject();
  adminHeader$ = this.adminHeader.asObservable();

  editTemplate = new Subject();
  editTemplate$ = this.editTemplate.asObservable();

  createTemplate = new Subject();
  createTemplate$ = this.createTemplate.asObservable();

  htmlDataTemplate = new Subject();
  htmlDataTemplate$ = this.htmlDataTemplate.asObservable();

  tenantName = new Subject();
  tenantName$ = this.tenantName.asObservable();

  savedLayoutTemplates = new BehaviorSubject('');
  savedLayoutTemplates$ = this.savedLayoutTemplates.asObservable();

  templateData = new BehaviorSubject('');
  templateData$ = this.templateData.asObservable();

  constructor(
    private tenantCustomEndpointService: TenantCustomEndpointService,
    private alertService: AlertService,
    private loader: LoaderService
  ) {}

  //// Fetch Tenant ///
  isTenant(tenant: any) {
    this.tenantName.next(tenant);
  }

  ///// upload image ////
  dynamicFormUploadFiles(files: any, fileLength: number) {
    if (fileLength === 1) {
      return this.tenantCustomEndpointService.singleFileUpload(files);
    } else {
      return this.tenantCustomEndpointService.multipleFileUpload(files);
    }
  }

  ///// upload content ////
  dynamicContentUpload(content: any) {
    return this.tenantCustomEndpointService
      .uploadContent(content)
      .pipe(
        catchError((error) => {
          this.alertService.showToaster(error.error.message, '', 'error');
          return throwError(error);
        })
      )
      .subscribe((res: any) => {
        this.alertService.showToaster(res.message, '', 'success');
        this.loader.hide();
        this.createTemplate.next(res);
      });
  }

  ///// edit footer dynamic page ////
  dynamicContentEdit(content: any, updatedLink: any) {
    return this.tenantCustomEndpointService
      .editFooterContent(content, updatedLink)
      .pipe(
        catchError((errors) => {
          this.alertService.showToaster(errors.error.message, '', 'error');
          return throwError(errors);
        })
      )
      .subscribe((res: any) => {
        this.alertService.showToaster(res.message, '', 'success');
        this.loader.hide();
        this.editTemplate.next(res);
      });
  }

  ///// Get data page onload ////
  fetchTenantData(tenantName: string) {
    this.tenantName.next(tenantName);
    this.loader.show();
    this.tenantCustomEndpointService
      .getTenantDesign(tenantName)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      )
      .subscribe((res: any) => {
        this.loader.hide();
        this.savedTemplate.next(res);
      });
  }

  ///// Get data footer data onload ////
  fetchTenantDataAfterlogin(tenantName: string) {
    this.tenantName.next(tenantName);
    this.loader.show();
    this.tenantCustomEndpointService
      .fetchTenantDataAfterlogin()
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      )
      .subscribe((res: any) => {
        this.loader.hide();
        this.savedTemplate.next(res);
      });
  }

  fetchHtmlData(editFooterLink: any) {
    this.tenantCustomEndpointService.getHtmlDataFromURL(editFooterLink).subscribe((res: any) => {
      this.alertService.showToaster(res.message, '', 'success');
      this.htmlDataTemplate.next(res);
    });
  }

  ///// Save changes  ////
  saveDesign(bookDetails: any) {
    this.loader.show();
    this.tenantCustomEndpointService
      .sendTenantDesign(bookDetails)
      .pipe(
        catchError((geterror) => {
          this.loader.hide();
          this.alertService.showToaster(geterror.error.message, '', 'error');
          return throwError(geterror);
        })
      )
      .subscribe((res: any) => {
        this.alertService.showToaster(res.message, '', 'success');
        this.loader.hide();
        this.savedTemplate.next(res);
      });
  }

  ///// Save changes  ////
  saveLayout(layout: any) {
    this.loader.show();
    this.tenantCustomEndpointService
      .createLayout(layout)
      .pipe(
        catchError((geterror) => {
          this.loader.hide();
          this.alertService.showToaster(geterror.error.message, '', 'error');
          return throwError(geterror);
        })
      )
      .subscribe((res: any) => {
        this.alertService.showToaster(res.message, '', 'success');
        this.loader.hide();
        this.savedLayouts.next(res);
      });
  }

  ///// Get template data page onload ////
  fetchTemplateData() {
    this.loader.show();
    this.tenantCustomEndpointService
      .getLayoutAll()
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      )
      .subscribe((res: any) => {
        this.loader.hide();
        this.savedLayoutTemplates.next(res);
      });
  }

  ///// Get template data page onload ////
  fetchTemplateInfo(id: any) {
    this.loader.show();
    this.tenantCustomEndpointService
      .getTemplateInfo(id)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      )
      .subscribe((res: any) => {
        this.loader.hide();
        this.templateData.next(res);
      });
  }

  dummyCallForsavedTemplate(res: any) {
    this.savedTemplate.next(res);
  }
}

import { Injectable } from '@angular/core';
import { OrganisationUnitsEndpointService } from './organisation-units-endpoint.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { LoaderService } from '@app/@shared/services/loader/loader.service';
import { AlertService } from '@app/@core/toaster/alert.service';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class OrganisationUnitsFacadeService {
  ngUnsubscribe = new Subject();

  orgUnitsData: any = [];
  orgUnitsDataSubject = new BehaviorSubject(this.orgUnitsData);
  orgUnitsData$ = this.orgUnitsDataSubject.asObservable();
  getOrgUnitSubject = new Subject();
  getOrgUnitSubject$ = this.getOrgUnitSubject.asObservable();
  updateOrgUnitSubject = new Subject();
  updateOrgUnitSubject$ = this.updateOrgUnitSubject.asObservable();

  orgUnitPaginateSubject = new Subject();
  orgUnitPaginateSubject$ = this.orgUnitPaginateSubject.asObservable();
  updateSingleOrgUnitSubject = new BehaviorSubject('');
  updateSingleOrgUnit$ = this.updateSingleOrgUnitSubject.asObservable();
  orgUnitAllPaginateSubject = new Subject();
  orgUnitAllPaginate$ = this.orgUnitAllPaginateSubject.asObservable();
  allOrgUnitsByName = new Subject();
  allOrgUnitsByName$ = this.allOrgUnitsByName.asObservable();
  allUsersInOrgUnit = new Subject();
  allUsersInOrgUnit$ = this.allUsersInOrgUnit.asObservable();

  unassignUsersInOrgUnitSubject = new Subject();
  unassignUsersInOrgUnitSubject$ = this.unassignUsersInOrgUnitSubject.asObservable();
  assignUsersInOrgUnitSubject = new Subject();
  assignUsersInOrgUnitSubject$ = this.assignUsersInOrgUnitSubject.asObservable();
  unassignRolesInOrgUnitSubject = new Subject();
  unassignRolesInOrgUnitSubject$ = this.unassignRolesInOrgUnitSubject.asObservable();
  assignRolesInOrgUnitSubject = new Subject();
  assignRolesInOrgUnitSubject$ = this.assignRolesInOrgUnitSubject.asObservable();
  addUsersInOrgUnitSubject = new Subject();
  addUsersInOrgUnitSubject$ = this.addUsersInOrgUnitSubject.asObservable();
  deleteUsersInOrgUnitSubject = new Subject();
  deleteUsersInOrgUnitSubject$ = this.deleteUsersInOrgUnitSubject.asObservable();
  addRolesInOrgUnitSubject = new Subject();
  addRolesInOrgUnitSubject$ = this.addRolesInOrgUnitSubject.asObservable();
  deleteRolesInOrgUnitSubject = new Subject();
  deleteRolesInOrgUnitSubject$ = this.deleteRolesInOrgUnitSubject.asObservable();

  private assignedUsers = new Subject();
  assignedUsers$ = this.assignedUsers.asObservable();
  private assignedRoles = new Subject();
  assignedRoles$ = this.assignedRoles.asObservable();

  constructor(
    private alertService: AlertService,
    private router: Router,
    private loader: LoaderService,
    private organisationUnitsEndpointService: OrganisationUnitsEndpointService
  ) {}

  //loads all Org units
  loadAllOrgUnits() {
    this.loader.show();
    this.organisationUnitsEndpointService
      .loadAllOrgUnits()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.orgUnitsDataSubject.next(res);
      });
  }

  //creates new Org unit
  createOrgUnit(postOrgUnit: any) {
    this.loader.show();
    this.organisationUnitsEndpointService
      .createOrgUnit(postOrgUnit)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.alertService.showToaster(res.message, '', 'success');
        this.getOrgUnitSubject.next(res);
      });
  }

  //updates existing Org unit
  updateOrgUnit(postOrgUnit: any) {
    this.loader.show();
    this.organisationUnitsEndpointService
      .updateOrgUnit(postOrgUnit)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.alertService.showToaster(res.message, '', 'success');
        this.updateOrgUnitSubject.next(res);
      });
  }

  //loads all paginated Org units
  getPaginateOrgUnit(pageNumber: number, pageSize: number, searchString: string) {
    this.loader.show();
    this.organisationUnitsEndpointService
      .getPaginateOrgUnit(pageNumber, pageSize, searchString)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.orgUnitPaginateSubject.next(res);
        if (!res) {
          this.alertService.showToaster('There are no matching records found', '', 'error');
        }
      });
  }

  //loads all unassigned users in Org unit
  loadUnassignUsersInOrgUnit(orgUnitId: number, pageNumber: number, pageSize: number, searchString?: string) {
    this.loader.show();
    this.organisationUnitsEndpointService
      .loadUnassignUsersInOrgUnit(orgUnitId, pageNumber, pageSize, searchString)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.unassignUsersInOrgUnitSubject.next(res);
      });
  }

  //loads all assigned users in Org unit
  loadAssignUsersInOrgUnit(pageNumber: number, pageSize: number, searchString: string, orgUnitId: number) {
    this.loader.show();
    this.organisationUnitsEndpointService
      .loadAssignUsersInOrgUnit(pageNumber, pageSize, searchString, orgUnitId)
      ?.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.assignUsersInOrgUnitSubject.next(res);
      });
  }

  //adds users to Org unit
  addUserInOrgUnit(orgUnitData: any, orgUnitId: any) {
    this.loader.show();
    this.organisationUnitsEndpointService
      .addUserInOrgUnit(orgUnitData, orgUnitId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.alertService.showToaster(res.message, '', 'success');
        this.addUsersInOrgUnitSubject.next(res);
      });
  }

  //deletes users from Org unit
  deleteUserInOrgUnit(orgUnitData: any, orgUnitId: any) {
    this.loader.show();
    this.organisationUnitsEndpointService
      .deleteUserInOrgUnit(orgUnitData, orgUnitId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.alertService.showToaster(res.message, '', 'success');
        this.deleteUsersInOrgUnitSubject.next(res);
      });
  }

  //gets all Org units by name
  getAllOrgUnitByName(orgUnitName: any) {
    this.loader.show();
    this.organisationUnitsEndpointService
      .getAllOrgUnitByName(orgUnitName)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.allOrgUnitsByName.next(res);
      });
  }

  //loads all users in Org unit
  loadAllUsersInOrgUnit(orgUnitId: any) {
    this.loader.show();
    this.organisationUnitsEndpointService
      .loadAllUsersInOrgUnit(orgUnitId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.allUsersInOrgUnit.next(res);
      });
  }

  //updates Org unit data
  updateSingleOrgUnitData(orgUnitName: any) {
    this.updateSingleOrgUnitSubject.next(orgUnitName);
  }

  assignedUsersInfo(data: any) {
    this.assignedUsers.next(data);
  }

  assignedRolesInfo(data: any) {
    this.assignedRoles.next(data);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}

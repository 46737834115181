export enum Librarytypes {
  MyLibrary = 'mylibrary',
  Book = 'books',
  Chapter = 'chapter',
  GSI = 'gsi',
  GSInested = 'gsinested',
  GSIexception = 'gsiexception',
  ChangeUnit = 'cu',
  Agent = 'agents',
  OrgUnit = 'orgunits',
  Entity = 'entity',
  NSLKitCU = 'nslhubKitCU',
  NSLKitEntity = 'nslhubKitEntity',
  Collaborator = 'collaborators',
  Collaborate = 'collaborate',
  MailBox = 'mailBox',
  Import = 'import',
  Export = 'export',
  Search = 'search',
  DataType = 'dataTypes',
  DcdAttributes = 'dcdAttributes',
  Attributes = 'Attributes',
  EventGsi = 'EventGsi',
  Dashboard = 'Dashboard',
  Admin = 'Admin',
  MyDashboard = 'My Dashboard',
  DataResources = 'Data Resources',
  Notifications = 'notifications',
  GSIEmbedded = 'gsiEmbedded',
  SpecialFeatures = 'SpecialFeatures',
  ReservedActions = 'reservedActions',
  Users = 'users',
  EQL = 'eql',
  EQLsubQuery = 'EQLsubQuery',
  AddNodeActionMenu = 'addNodeActionMenu',
  Graph = 'graph',
  ReservedActionEntity = 'reservedActionEntity',
  MileStone = 'mileStone',
  FileExplorer = 'fileExplorer',
  Calendar = 'calendar',
  SpecialEvent = 'specialEvent',
  AdDashboard = 'AdDashboard',
  NslLibrary = 'nslLibrary',
  AdvertiserDashboard = 'AdvertiserDashboard',
  PublisherDashboard = 'publisherDashboard',
  IndividualCU = 'individualCU',
  IndividualEntity = 'individualEntity',
  VerticalGraph = 'verticalGraph',
  SubAttribute = 'subAttribute',
  NSLGsi = 'nslGsi',
  ShadesOfPotentiality = 'ShadesOfPotentiality',
  Parallel = 'parallel',
  MyProfile = 'MyProfile',
  OCR = 'ocr',
}

import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CduiBaseService } from '@app/auth/cdui-base.service';
@Injectable({
  providedIn: 'root',
})
export class TenantCustomEndpointService extends CduiBaseService {
  constructor(private injector: Injector, private http: HttpClient) {
    super(injector);
  }

  ///// Get data page onload ////
  getTenantDesign(tenantName: string) {
    return this.http.get(this.api?.customTenantUi + `${tenantName}`);
  }

  ///// upload single image ////
  singleFileUpload(files: any) {
    return this.http.post(this.api?.fileUpload, files);
  }

  ///// upload multiple image ////
  multipleFileUpload(files: any) {
    return this.http.post(this.api?.multipleFileUpload, files);
  }

  ///// Save changes ////
  sendTenantDesign(sendingData: any) {
    return this.http.post(this.api?.sendTenantDesign, sendingData);
  }

  ///// footer details  ////
  fetchTenantDataAfterlogin() {
    return this.http.get(this.api?.sendTenantDesign);
  }
  ///// footer dynamic page  ////
  uploadContent(content: any) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http.post(this.api?.uploadContent, content, options);
  }

  ///// create Layout  ////
  createLayout(layout: any) {
    return this.http.post(this.api?.mccRoles + `layout`, layout);
  }

  ///// Get data page onload ////
  getLayoutAll() {
    return this.http.get(this.api?.mccRoles + `layout/all`);
  }

  ///// Get data page onload ////
  getTemplateInfo(id: any) {
    return this.http.get(this.api?.mccRoles + `layout/${id}`);
  }

  /////edit footer dynamic page  ////
  editFooterContent(content: any, updatedLink: any) {
    return this.http.put(updatedLink, content);
  }

  getHtmlDataFromURL(editFooterLink: any) {
    return this.http.get(editFooterLink, { responseType: 'text' });
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslatorService } from '@app/shell/translator.service';
import { AuthenticationService } from '../authentication.service';
import { LoaderService } from '@app/@shared/services/loader/loader.service';
import { AlertService } from '@app/@core/toaster/alert.service';
import { AnimationOptions } from 'ngx-lottie';
@Component({
  selector: 'app-forgot-customerid',
  templateUrl: './forgot-customerid.component.html',
  styleUrls: ['./forgot-customerid.component.scss'],
})
export class ForgotCustomerIdComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  lottieOptions: AnimationOptions = {
    path: '../../../assets/json/login earth.json',
  };
  forgotPass: any = {
    mobileNumber: '',
    realm: '',
  };

  constructor(
    private router: Router,
    public translator: TranslatorService,
    private authenticationService: AuthenticationService,
    private loader: LoaderService,
    private alertService: AlertService
  ) {
    this.detectLanguageChange();
  }
  labels: any;
  isVpnhslTenant: boolean = false;

  ngOnInit(): void {}

  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }

  cancelClick() {
    this.router.navigate(['/login'], { replaceUrl: true });
  }

  clickSubmit() {
    this.loader.show();
    this.forgotPass.realm = localStorage.getItem('TenantName');
    this.authenticationService
      .forgotCustomerId(this.forgotPass)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          if (res) {
            this.alertService.showToaster(res.message, '', res.status == '200' ? 'success' : 'error');
          }
          this.router.navigateByUrl('/login');
        },
        (error: any) => {
          const { err } = error;
          this.alertService.showToaster(`${err}`, 'Please try again', 'error');
        }
      );
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}

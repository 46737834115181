import { Injectable } from '@angular/core';
import { LoaderService } from '@app/@shared/services/loader/loader.service';
import { Subject, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OCREndpointService } from './ocr-endpoint.service';

@Injectable({
  providedIn: 'root',
})
export class OCRFacadeService {
  public ocrFileUpload = new Subject();
  srcfileUrl$ = this.ocrFileUpload.asObservable();

  public entityData = new Subject();
  entityData$ = this.entityData.asObservable();

  public activeLayerIndex = new Subject();
  activeLayerIndex$ = this.activeLayerIndex.asObservable();

  // Update Change Unit State
  public updateCUWithOCR = new Subject();
  updateCUWithOCR$ = this.updateCUWithOCR.asObservable();

  /**
   * Attribute Mapped Details
   */
  public mappedDetails = new Subject();
  recentMappedDetails$ = this.mappedDetails.asObservable();

  public fileMappedDetails = new Subject();
  fileMappedDetails$ = this.fileMappedDetails.asObservable();

  transactionfileMappedDetails = new Subject();
  transactionfileMappedDetails$ = this.transactionfileMappedDetails.asObservable();

  // public OCRMappingStatus = new Subject();
  // OCRMappingStatus$ = this.OCRMappingStatus.asObservable();

  showOCRModalFlag = new Subject();
  showOCRModalFlag$ = this.showOCRModalFlag.asObservable();

  latestCreatedAttribute = new Subject();
  latestCreatedAttribute$ = this.latestCreatedAttribute.asObservable();

  public resetData = new Subject();
  resetData$ = this.resetData.asObservable();

  public rightPanelExpanded = new BehaviorSubject({ call: true, expandRightPanel: false });
  rightPanelExpanded$ = this.rightPanelExpanded.asObservable();

  cuDetailsForOCR = new BehaviorSubject('');
  cuDetailsForOCR$ = this.cuDetailsForOCR.asObservable();

  editCaseOfOcr: boolean = false;
  editOCRData: any;

  unlinkedDataForOCR = new BehaviorSubject('');
  unlinkedDataForOCR$ = this.unlinkedDataForOCR.asObservable();

  public ngUnsubscribe = new Subject();

  public manualMappedAttribute = new Subject();
  manualMappedAttribute$ = this.manualMappedAttribute.asObservable();

  setMappingCall = new BehaviorSubject('');
  setMappingCall$ = this.setMappingCall.asObservable();

  constructor(public ocrEndpoint: OCREndpointService, public loader: LoaderService) {}

  updateCUDetailsForOCR(changeUnitData: any) {
    this.cuDetailsForOCR.next(changeUnitData);
  }

  onRightPanelExpand(flag: any) {
    this.rightPanelExpanded.next(flag);
  }
  /**
   *
   * @param data
   * unlinked data from Cu to Unlink
   */

  updateUnlinkedForOCR(data: any) {
    this.unlinkedDataForOCR.next(data);
  }

  fileUpload(file: any, changeUnit?: any) {
    this.loader.show();
    this.ocrEndpoint
      .fileUpload(file)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.ocrFileUpload.next({ ...res, changeUnit: changeUnit });
      });
  }

  /**
   * Attribute Mapped Details
   */
  updateEntityData(data: any) {
    this.entityData.next(data);
  }

  updateLastedMappedFields(data: any) {
    this.mappedDetails.next(data);
  }

  /**
   *
   * @param data
   */

  updateCUStateWithOCR(data: any) {
    this.updateCUWithOCR.next(data);
  }

  /**
   *
   * @param data
   * used the gett the mapping with cdec id
   */

  extractMappingInTransactionView(data: any) {
    this.loader.show();
    this.ocrEndpoint
      .getOCRMappings(data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        if (res) {
          this.loader.hide();
          this.editOCRData = res;
          if (this.editCaseOfOcr) {
            this.editOCRData = res;
          }
          const trans_call = data?.cdec;
          if (trans_call) {
            this.transactionfileMappedDetails.next(res);
          } else {
            this.fileMappedDetails.next(res);
          }
        }
      });
  }

  /**
   *
   * @param data
   * Saved s3 Link map with the gsi and cu and ist extraction call
   */

  saveOCRGSIandFileMapping(data: any) {
    this.loader.show();
    this.ocrEndpoint
      .saveOCRGSIandFileMapping(data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        if (res) {
          this.loader.hide();
          if (this.editCaseOfOcr && this.editOCRData != undefined) {
            for (let i = 0; i < res.mappings?.length; i++) {
              for (let j = 0; j < this.editOCRData.mappings?.length; j++) {
                if (
                  this.editOCRData.mappings[j]?.attribute_name == res.mappings[i]?.attribute_name &&
                  this.editOCRData.mappings[j]?.solution_attribute?.length != 0
                ) {
                  if (this.editOCRData.mappings[j]?.solution_attribute[0]?.attributes.tf_id) {
                    delete this.editOCRData.mappings[j].solution_attribute[0].attributes['tf_id'];
                  }
                  res.mappings[i].solution_attribute = this.editOCRData.mappings[j]?.solution_attribute;
                }
              }
            }
          }
          this.fileMappedDetails.next(res);
        }
      });
  }

  /**
   *
   * @param data saving or setting the mappings
   */

  saveOCRMappingData(ocrData: any) {
    this.loader.show();
    this.ocrEndpoint
      .saveOCRMappingData(ocrData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
      });
  }

  /**
   *
   * @param flag Used to show and hide the component
   */

  showOCRModal(flag: boolean) {
    this.showOCRModalFlag.next(flag);
  }

  updateLatestCreatedAttribute(data: any) {
    this.latestCreatedAttribute.next(data);
  }

  resetOCRData(flag?: boolean) {
    /* istanbul ignore next */
    if (flag === undefined) {
      flag = true;
    }
    this.resetData.next(flag);
  }

  manualMap(data: any) {
    this.loader.show();
    this.ocrEndpoint
      .manualMappedAttribute(data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.manualMappedAttribute.next(res);
        this.loader.hide();
      });
  }

  callSetMappings(cdecId: any) {
    this.setMappingCall.next(cdecId);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}

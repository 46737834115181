import { Injectable, Injector, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { ChangeUnitEndpointService } from './change-unit-endpoint.service';
import { ChangeUnitStoreService } from '@app/@shared/stores/change-unit-store';
import { ChangeUnit } from '@app/@shared/models/change-unit';
import { ChangeUnitList } from '@app/@shared/models/change-unit-list';
import { AlertService } from '@app/@core/toaster/alert.service';
import { LoaderService } from '@app/@shared/services/loader/loader.service';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { changeUnitListMapper } from '@app/@core/mappers/list/change-unit';
import { changeUnitDeMapper } from '@app/@core/mappers/change-unit/cu-demapper';
import { changeUnitMapper } from '@app/@core/mappers/change-unit/cu-mapper';
import { GsiEndpointService } from '../gsi/gsi-endpoint.service';
import { gsiMapper } from '@app/@core/mappers/gsi/gsi-mapper';
import { entityListMapper } from '@app/@core/mappers/list/entity';
import { EntityStoreService } from '@app/@shared/stores/entity-store';
import { RearrangeData } from '../../components/change-unit-list/change-unit-list.component';
import { HttpClient } from '@angular/common/http';
import { CduiBaseService } from '@app/auth/cdui-base.service';

@Injectable({
  providedIn: 'root',
})
export class ChangeUnitFacadeService extends CduiBaseService implements OnDestroy {
  ngUnsubscribe = new Subject();
  changeUnitListIntital: ChangeUnitList[];
  public changeUnitSubject = new Subject();
  changeUnitList$ = this.changeUnitSubject.asObservable();

  public entityAttributes = new Subject();
  entityAttributes$ = this.entityAttributes.asObservable();

  public cuDetails = new Subject();
  cuDetails$ = this.cuDetails.asObservable();

  public cuDetailsForEQL = new BehaviorSubject('');
  cuDetailsForEQL$ = this.cuDetailsForEQL.asObservable();

  public libCuDetails = new Subject();
  libCuDetails$ = this.libCuDetails.asObservable();

  public saveCuSubject = new BehaviorSubject('');
  lastSavedCu$ = this.saveCuSubject.asObservable();

  public draftCuSubject = new BehaviorSubject('');
  draftedCU$ = this.draftCuSubject.asObservable();

  public openDraftSubject = new Subject();
  openDraftCU$ = this.openDraftSubject.asObservable();

  public updateDraftSubject = new Subject();
  updateDraft$ = this.updateDraftSubject.asObservable();

  public cuCount = new Subject();
  cuCount$ = this.cuCount.asObservable();

  public reservedCuList = new Subject();
  reservedCuList$ = this.reservedCuList.asObservable();

  public reservedEntityList = new Subject();
  reservedEntityList$ = this.reservedEntityList.asObservable();

  public changeUnitListByGSI = new Subject();
  changeUnitListByGSI$ = this.changeUnitListByGSI.asObservable();

  // Elastic Search - FE757
  public gsiData: any;

  public relatedChangeUnitListByGSI = new Subject();
  relatedChangeUnitListByGSI$ = this.relatedChangeUnitListByGSI.asObservable();

  public gsiEditResponse = new Subject();
  gsiEditedResponse$ = this.gsiEditResponse.asObservable();

  public changeUnitUIControl = new Subject();
  changeUnitUIControl$ = this.changeUnitUIControl.asObservable();

  public updateCuEvents = new Subject();
  updateCuEventsResponse$ = this.updateCuEvents.asObservable();

  public addAlternateCu = new Subject();
  addAlternateCu$ = this.addAlternateCu.asObservable();

  public changeUnitOptions = new Subject();
  changeUnitOptions$ = this.changeUnitOptions.asObservable();
  public currentLayerIndex = new BehaviorSubject(null);
  currentLayerIndex$ = this.currentLayerIndex.asObservable();

  public focusedInputParentCU = new Subject();
  focusedInputParentCU$ = this.focusedInputParentCU.asObservable();
  public solutionDetails = new Subject();
  solutionDetails$ = this.solutionDetails.asObservable();

  resumedChangeUnit = new Subject();
  resumedChangeUnit$ = this.resumedChangeUnit.asObservable();

  public reservedActions = new Subject();
  reservedActions$ = this.reservedActions.asObservable();
  public generalEntityTuplesData = new Subject();
  generalEntityTuplesData$ = this.generalEntityTuplesData.asObservable();

  public constantChangeDriver = new Subject();
  constantChangeDriver$ = this.constantChangeDriver.asObservable();

  public saveConstantChangeDriverObs = new Subject();
  savedConstantChangeDriver$ = this.saveConstantChangeDriverObs.asObservable();

  public addChangeUnitToHoverComponent = new Subject();
  addChangeUnitToHoverComponentFlag$ = this.addChangeUnitToHoverComponent.asObservable();

  public updateConstantChangeDriverData = new Subject();
  updateConstantChangeDriverData$ = this.updateConstantChangeDriverData.asObservable();

  /** Stream for checking for Published CU
   * changes at Trigger CES level */
  public publshedCUSave = new Subject();
  publishedCUSave$ = this.publshedCUSave.asObservable();

  public layersAgentType = new BehaviorSubject('');
  layersAgentType$ = this.layersAgentType.asObservable();

  public subtransactionalCUCondition = new BehaviorSubject('');
  subtransactionalCUCondition$ = this.subtransactionalCUCondition.asObservable();

  public getPreviewRearrangeGsi = new Subject();
  getPreviewRearrangeGsi$ = this.getPreviewRearrangeGsi.asObservable();

  public getRearrangeGsi = new Subject();
  getRearrangeGsi$ = this.getRearrangeGsi.asObservable();

  public currentEntityDetails = new Subject();
  currentEntityDetails$ = this.currentEntityDetails.asObservable();

  public updatedCU = new Subject();
  updatedCU$ = this.updatedCU.asObservable();

  public GsiDesign = new BehaviorSubject('');
  GsiDesign$ = this.GsiDesign.asObservable();

  constructor(
    private changeUnitEndPoint: ChangeUnitEndpointService,
    private entityStoreService: EntityStoreService,
    private changeUnitStore: ChangeUnitStoreService,
    private alertService: AlertService,
    private loader: LoaderService,
    private gsiEndpointService: GsiEndpointService,
    private router: Router,
    private http: HttpClient,
    private injector: Injector
  ) {
    super(injector);
    this.changeUnitStore.stateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((state: any) => {
      if (state) {
        this.changeUnitSubject.next(state.changeUnitList);
      }
    });
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  changeAgentType(type: string) {
    this.layersAgentType.next(type);
  }

  getReservedEntities() {
    this.loader.show();
    this.changeUnitEndPoint
      .getReservedEntities()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.reservedActions.next(res);
        console.log(res);
      });
  }

  updateLastedFocusedInputParentCU(changeUnit: any) {
    this.focusedInputParentCU.next(changeUnit);
  }
  setDraftChaneUnit(changeUnit: any) {
    this.draftCuSubject.next(changeUnit);
  }

  getAllChangeUnits(pagenum: number, pageSize: number, searchCriteria: string, isPublished: boolean) {
    this.loader.show();
    this.changeUnitEndPoint
      .getAllCU(pagenum + 1, pageSize, searchCriteria, isPublished)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        const cuList = changeUnitListMapper(res);
        this.changeUnitStore.setStateData(cuList);
      });
  }
  getChangeUnitoptions(pagenum: number, pageSize: number, searchCriteria: string, isPublished: boolean) {
    this.loader.show();
    this.changeUnitEndPoint
      .getAllCU(pagenum + 1, pageSize, searchCriteria, isPublished)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        const cuList = changeUnitListMapper(res);
        this.changeUnitOptions.next(cuList);
      });
  }

  getSolutionDetails(data: any, cures?: any) {
    this.http
      .post(this.api?.fat2flat, data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.solutionDetails.next({ res, cures });
        },
        /*istanbul ignore next*/
        (err: any) => {
          const obj = {
            flatApiFail: true,
          };
          this.solutionDetails.next(obj);
          this.alertService.showToaster(err.message, '', 'error');
        }
      );
  }

  getChangeUnitCount(pageSize: number, searchCriteria: string) {
    this.loader.show();
    this.changeUnitEndPoint
      .getCuCount(0, pageSize, searchCriteria)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.loader.hide();
        this.cuCount.next(res);
      });
  }

  saveCU(cu: any, isModified?: boolean) {
    this.loader.show();
    let cuInfo = changeUnitDeMapper(cu);

    this.changeUnitEndPoint
      .saveCU(cuInfo)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          if (res && res.status && res.status == 200) {
            const cuRes = changeUnitMapper(res.result);
            /* istanbul ignore next */
            this.saveCuSubject.next({
              ...cuRes,
              oldVersionId: cu?.oldVersionId,
              isEdit: cu?.isEdit,
              isModified: isModified,
              slotItemProperties: cu?.slotItemProperties,
              source: cu?.source,
              tCUConditionalPotentiality: cu?.tCUConditionalPotentiality,
              tCUConditionalPotentialityNames: cu?.tCUConditionalPotentialityNames,
              constraint: cu?.constraint,
              constraintName: cu?.constraintName,
              estimatedTime: cu?.estimatedTime,
              isReservedTemplate: cu?.isReservedTemplate,
              index: cu?.index,
              eventCUList: cu?.eventCUList,
            });
            this.alertService.showToaster(res.message, '', 'success');
          } else {
            /* istanbul ignore else*/
            if (res && res.message) {
              this.alertService.showToaster(`${res.message}`, '', 'error');
            }
          }
        },
        (error) => {
          const { err } = error;
          this.alertService.showToaster(`${err}`, '', 'error');
        }
      );
  }

  resetlastsavedCU() {
    this.saveCuSubject.next('');
  }

  updateDraftFromEntity() {
    this.updateDraftSubject.next(true);
  }

  updateDraftCU(changeUnit: ChangeUnit) {
    const data = changeUnitMapper(changeUnit);
    this.cuDetails.next(data);
  }

  openDraftedCU() {
    this.openDraftSubject.next(true);
  }

  getCuDetailsById(id: any) {
    /* istanbul ignore else */
    if (id) {
      this.loader.show();
      this.changeUnitEndPoint
        .getCuDetailsById(id)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res: any) => {
          this.loader.hide();
          /* istanbul ignore next */
          const data = changeUnitMapper(res?.result);
          this.cuDetails.next(data);
          this.cuDetailsForEQL.next(data);
        });
    }
  }

  getLibCuDetailsById(id: any) {
    this.loader.show();
    this.changeUnitEndPoint
      .getCuDetailsById(id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        /* istanbul ignore next */
        const data = changeUnitMapper(res?.result);
        this.libCuDetails.next(data);
      });
  }

  getReservedCuDetailsById(id: any, isReservedForDCD?: any, isReservedTemplate?: boolean) {
    /* istanbul ignore else */
    if (id) {
      this.loader.show();
      this.changeUnitEndPoint
        .getReservedCuDetailsById(id)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res: any) => {
          this.loader.hide();
          /* istanbul ignore next */
          const data = changeUnitMapper(res?.result);

          if (isReservedForDCD) {
            this.libCuDetails.next(data);
          } else {
            /* istanbul ignore else */
            if (isReservedTemplate) {
              data.isReservedTemplate = isReservedTemplate;
            }
            this.cuDetails.next(data);
          }
        });
    }
  }
  //calls an api to get the impacted BETs Data
  getRelatedBetsData(id: any, type: any) {
    return this.changeUnitEndPoint.getRelatedBetsData(id, type);
  }

  clearLibraryCuDetails() {
    this.libCuDetails.next(null);
  }

  getCusByGsiId(gsi: any, isDld?: boolean, triggerCuId?: any, bookid?: any) {
    const url = this.router.url;
    this.loader.show();
    if (isDld) {
      this.loader.hide();
      gsi.dldrecommendation = true;
      this.changeUnitListByGSI.next(gsi);
    } else {
      this.changeUnitEndPoint
        .getCusByGsiId(gsi, triggerCuId, bookid)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res: any) => {
          this.loader.hide();
          /* istanbul ignore else */
          if (res.status === 400) {
            this.alertService.showToaster(res.message, '', 'error');
            /* istanbul ignore next */
            if (url === '/transaction/view') {
              this.router.navigate(['/cdui/mylibrary-dashboard']);
            }
          }
          /* istanbul ignore else */
          if (res && res['result'] != 'null') {
            // Elastic Search - FE757
            /* istanbul ignore next */
            if (res?.result?.designUrl) {
              this.gsiData = res['result'];
              this.getDesignByUrl(res?.result?.designUrl);
            }
            /* istanbul ignore next */
          }
        });
    }
  }

  getDesignByUrl(url: string) {
    this.changeUnitEndPoint
      .getDesignByUrl(url)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        if (res && res?.result) {
          /* istanbul ignore next */
          let gsiId = this.gsiData.masterId;
          let gsilayout = res?.result?.gsiLayout[gsiId];
          /* istanbul ignore next */
          if (gsilayout == undefined) {
            res.result.gsiLayout = res.result.gsiLayout?.default || res.result.gsiLayout[0];
          } else {
            res.result.gsiLayout = gsilayout;
          }
          if (this.gsiData.design) {
            this.gsiData.design = res.result;
          } else {
            this.gsiData['design'] = res.result;
          }
          this.changeUnitListByGSI.next(this.gsiData);
          this.GsiDesign.next(res.result);
        }
      });
  }

  getCuDetailsByGSIId(gsi: number, cuId: number) {
    this.loader.show();
    this.changeUnitEndPoint
      .getCuDataByGsiId(gsi, cuId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.resumedChangeUnit.next(res);
      });
  }
  getchangeunitsfromCompositeAPI(data: any) {
    data.compositeSave = true;
    this.changeUnitListByGSI.next(data);
  }

  getrelatedCulistByGsiId(id: any) {
    this.loader.show();
    this.gsiEndpointService
      .getGSIDetailsById(id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        /* istanbul ignore else */
        if (res && res['result'] != 'null') {
          /* istanbul ignore next */
          this.relatedChangeUnitListByGSI.next(gsiMapper(res?.result));
        }
      });
  }

  // getrelatedCulistByGsiId(gsi: any, triggerCuId?: any) {
  //   this.loader.show();
  //   this.changeUnitEndPoint
  //     .getCusByGsiId(gsi, triggerCuId)
  //     .pipe(takeUntil(this.ngUnsubscribe))
  //     .subscribe((res: any) => {
  //       this.loader.hide();
  //       if (res && res['result'] != 'null') {
  //         this.relatedChangeUnitListByGSI.next(res.result.solutionLogic);
  //       }
  //     });
  // }

  updateChangeUnit(cu: any, changeunitData: any, isModified: boolean, isVersionEdit: boolean) {
    this.loader.show();
    const cuInfo = changeUnitDeMapper(changeunitData);
    this.changeUnitEndPoint
      .saveCU(cuInfo)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        /* istanbul ignore else */
        if (res && res.status && res.status == 200) {
          const cuRes = changeUnitMapper(res.result);
          /* istanbul ignore next*/
          if (isVersionEdit && changeunitData?.slotItemProperties) {
            Object.keys(changeunitData.slotItemProperties)?.forEach((slot: any) => {
              /* istanbul ignore next*/
              if (changeunitData.slotItemProperties[slot].propertyData.CONSTANT?.id) {
                delete changeunitData.slotItemProperties[slot].propertyData.CONSTANT.id;
              }
            });
          }
          /* istanbul ignore next*/
          this.saveCuSubject.next({
            ...cuRes,
            oldVersionId: changeunitData?.oldVersionId,
            isModified: isModified,
            slotItemProperties: changeunitData.slotItemProperties,
            tCUConditionalPotentiality: changeunitData.tCUConditionalPotentiality,
            tCUConditionalPotentialityNames: changeunitData.tCUConditionalPotentialityNames,
            constraint: changeunitData?.constraint,
            constraintName: changeunitData?.constraintName,
            estimatedTime: changeunitData?.estimatedTime,
            isReservedTemplate: changeunitData?.isReservedTemplate,
            index: changeunitData?.index,
            eventCUList: changeunitData?.eventCUList,
          });
        }
        this.alertService.showToaster(res.message, '', 'success');
      });
  }

  /*
  
  updateChangeUnit(cu: any, changeunitData: any, constantChangeDriverFlag: any) {
    this.loader.show();
    this.changeUnitEndPoint
      .saveCU(changeunitData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        if (res && res.status && res.status == 200) {
          res.result.constantChangeDriverFlag = constantChangeDriverFlag;
          this.saveCuSubject.next(res.result);
        }
        this.alertService.showToaster(res.message, '', 'success');
      });
  }
  
  */

  getReservedCuList(pagenum: number, pageSize: number, searchCriteria: string) {
    this.loader.show();
    this.changeUnitEndPoint
      .getReservedCuList(pagenum + 1, pageSize, searchCriteria)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        const cuRes = changeUnitListMapper(res);
        this.reservedCuList.next(cuRes);
        this.changeUnitStore.setStateData(cuRes);
      });
  }
  getReservedEntitiesList(pagenum: number, pageSize: number, searchCriteria: string) {
    this.loader.show();
    this.changeUnitEndPoint
      .getReservedEntityList(pagenum + 1, pageSize, searchCriteria)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        const entList = entityListMapper(res);
        this.entityStoreService.setStateData(entList);
        this.reservedEntityList.next(entList);
      });
  }
  updateChangeUnitRights(cu: any, body: any) {
    this.loader.show();
    this.changeUnitEndPoint.updateChangeUnitRights(cu, body).subscribe((res: any) => {
      this.loader.hide();
      /* istanbul ignore else */
      if (res) this.alertService.showToaster(res.message, '', 'success');
    });
  }
  uiELelmentSave(data: any) {
    this.loader.show();
    this.changeUnitEndPoint
      .uiElementSave(data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.generalEntityTuplesData.next(res);
        this.loader.hide();
      });
  }
  saveStaticLabels(staticLabels: any, lang: any) {
    this.changeUnitEndPoint
      .staticLabelsSave(staticLabels, lang)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        console.log(res);
      });
  }

  getStaticLabels(lang: any) {
    this.loader.show();
    this.changeUnitEndPoint
      .getStaticLabels(lang)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        console.log('%%%%%%%%%%%%%%%%%');
        console.log(res);
      });
  }

  loadUIControls(cuId: any) {
    this.loader.hide();
    this.changeUnitEndPoint
      .loadUIControls(cuId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.loader.hide();
        this.changeUnitUIControl.next(res);
      });
  }

  updateAttributeEvents(data: any) {
    this.updateCuEvents.next(data);
  }

  updateCurrentLayerIndex(index: number) {
    this.currentLayerIndex.next(index);
  }

  addAlternateCuToGsi(flag: boolean) {
    this.addAlternateCu.next(flag);
  }

  addConstantChangeDriverToSlot(changeDriver: any) {
    this.constantChangeDriver.next(changeDriver);
  }

  currentEntityToUpdate(entity: any) {
    this.currentEntityDetails.next(entity);
  }

  saveConstantChangeDriver(ccdData: any, triggerCUId: any) {
    this.changeUnitEndPoint.saveConstantChangeDriver(ccdData, triggerCUId).subscribe((constantChangeDriver: any) => {
      constantChangeDriver.triggerCUId = triggerCUId;
      this.saveConstantChangeDriverObs.next(constantChangeDriver);
    });
  }

  updateConstantChangeDriver(data: any) {
    this.updateConstantChangeDriverData.next(data);
  }

  changeUnitAddtoHoverComponent(flag: any, type: any) {
    this.addChangeUnitToHoverComponent.next({ flag: flag, type: type });
  }

  // Doing a Service Call based on CU Status
  publishedCUSaveHit(status: any) {
    this.publshedCUSave.next(status);
  }

  //storing subtransactional cu data
  subtransactionalCU(data: any) {
    this.subtransactionalCUCondition.next(data);
  }

  validateEQLWhiteboardQuery(data: any) {
    return this.changeUnitEndPoint.validateEQLWhiteboardQuery(data);
  }

  validateEQLBuilderQuery(data: any) {
    return this.changeUnitEndPoint.validateEQLBuilderQuery(data);
  }

  previewRearrageCU(rearrangeData: RearrangeData) {
    this.changeUnitEndPoint
      .previewRearrageCU(rearrangeData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.getPreviewRearrangeGsi.next(res);
      });
  }

  rearrangeCU(rearrangeData: RearrangeData, cu: any) {
    this.changeUnitEndPoint
      .rearrangeCU(rearrangeData, cu)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.getRearrangeGsi.next(gsiMapper(res.result));
      });
  }

  fetchCu(id: any) {
    this.changeUnitEndPoint
      .getCuDetailsById(id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          /* istanbul ignore next */
          if (res.status === 200) {
            this.updatedCU.next(changeUnitMapper(res.result));
          }
        },
        (err: any) => {
          this.alertService.showToaster(err, '', 'error');
        }
      );
  }
  fetchTriggerCu(id: any) {
    const url = this.router.url;
    this.changeUnitEndPoint
      .getTriggerCU(id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        /* istanbul ignore next */
        if (res.status === 400) {
          this.alertService.showToaster(res.message, '', 'error');
          /* istanbul ignore next */
          if (url === '/transaction/view') {
            this.router.navigate(['/cdui/mylibrary-dashboard']);
          }
        }
        /* istanbul ignore next */
        if (res && res['result'] != 'null') {
          // Elastic Search - FE757
          /* istanbul ignore next */
          if (res?.result?.designUrl) {
            this.gsiData = res['result'];
            this.getDesignByUrl(res?.result?.designUrl);
          }
        }
      });
  }
}

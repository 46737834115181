import { AgmCoreModule } from '@agm/core';
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';
import { CdkTableModule } from '@angular/cdk/table';
import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { AlertService } from '@app/@core/toaster/alert.service';
import { ChartsComponent } from '@app/@ui-controls/charts/charts.component';
import { FileComponent } from '@app/@ui-controls/file/file.component';
import { DialogComponent } from '@app/@ui-controls/image/dialog/dialog.component';
import { ImageComponent } from '@app/@ui-controls/image/image.component';
import { LocationComponent } from '@app/@ui-controls/location/location.component';
import { QrScannerControlModule } from '@app/@ui-controls/qr-scanner-control/qr-scanner-control.module';
import { SchedulerComponent } from '@app/@ui-controls/scheduler/scheduler.component';
import { TableComponent } from '@app/@ui-controls/table/table.component';
import { BpmnViewerComponent } from '@app/bpmn-view/bpmn-viewer/bpmn-viewer.component';
import { AccordionComponent } from '@app/solution-dictionary/shared/components/accordion/accordion.component';
import { BookDetailsOnHoverComponent } from '@app/solution-dictionary/shared/components/book-details/book-details-hover/book-details-hover.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { AgmDirectionModule } from 'agm-direction';
//import { CalendarModule, DateAdapter } from 'angular-calendar';
//import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { GridsterModule } from 'angular-gridster2';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgScormPlayerModule } from 'ng-scorm-player';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxCaptureModule } from 'ngx-capture';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxPaginationModule } from 'ngx-pagination';
import { InsuranceTemplateComponent } from './components/insurance-template/insurance-template.component';
import { AttSymbolPipe } from './pipes/attsymbol.pipe';
import { ButtonModule } from 'primeng/button';
import { CalendarModule as PCalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { SwiperModule } from 'swiper/angular';
import { MaterialModule } from '../material-module';
import { BreadCrumbModule } from './components/bread-crumb/bread-crumb.module';
/* import { CalendarDayViewSchedulerComponent } from './components/calendar-day-view-scheduler/calendar-day-view-scheduler.component';
import { CalendarEventsComponent } from './components/calendar-events/calendar-events.component';
import { CalendarParentComponent } from './components/calendar-parent/calendar-parent.component';
import { CalendarComponent } from './components/calendar/calendar.component'; */
import { ChatBotMediaDialog } from './components/chatbot-smart/chatbot-smart.component';
import { DelegaionComponent } from './components/delegation/delegation.component';
import { DynamicConfirmationDialogComponent } from './components/dynamic-confirmation-dialog/dynamic-confirmation-dialog.component';
import { EntityRadioDcdComponent } from './components/dynamic-form/entity-radio-dcd/entity-radio-dcd.component';
import { EmailTemplateEditorComponent } from './components/email-template-editor/email-template-editor.component';
import { EntityGridCardComponent } from './components/entity-grid-card/entity-grid-card.component';
import { EntityMapperComponent } from './components/entity-mapper/entity-mapper.component';
import { EntityComponent } from './components/entity/entity.component';
import { ExportWithBpmnComponent } from './components/export-with-bpmn/export-with-bpmn.component';
import { ExportsolutionTableComponent } from './components/exportsolution-table/exportsolution-table.component';
import { FileUploadPreviewComponent } from './components/file-upload-preview/file-upload-preview.component';
import { DragNDropDirective } from './components/file-upload/drag-drop.directive';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { GoogleMapsComponent } from './components/google-maps/google-maps.component';
import { GsiGridCardComponent } from './components/gsi-grid-card/gsi-grid-card.component';
import { ImageMapComponent } from './components/image-map/image-map.component';
import { ImportExportComponent } from './components/import-export/import-export.component';
import { ImportSolutionComponent } from './components/import-solution/import-solution.component';
import { ImportsolutionTableComponent } from './components/importsolution-table/importsolution-table.component';
import { ListDelegationComponent } from './components/list-delegation/list-delegation.component';
import { LivechatSmartComponent } from './components/livechat-smart/livechat-smart.component';
import { AutoIdNestedEntityComponent } from './components/nested-entity/nested-entity.component';
import { OCRModalComponent } from './components/ocr-modal/ocr-modal.component';
import { PaginatorModule } from './components/paginator/paginator.module';
import { SharedErrorHandlerComponent } from './components/shared-error-handler/shared-error-handler.component';
import { UpdateProfileComponent } from './components/update-profile/update-profile.component';
import { LoaderModule } from './loader/loader.module';
import { ApplicationPipesModule } from './pipes/application-pipes.module';
import { ChatBotStore } from './stores/chatbot-state';
import { DLDRecommendationsStore } from './stores/dld-recommendations-state';
import { ECommerceTemplatesComponent } from './components/e-commerce-templates/e-commerce-templates.component';
import { InsuranceExpandPreviewComponent } from './components/insurance-expand-preview/insurance-expand-preview.component';
import { ExpandViewPreviewComponent } from './components/expand-view-preview/expand-view-preview.component';
import { ECommerceExpandPreviewComponent } from './components/e-commerce-expand-preview/e-commerce-expand-preview.component';

@NgModule({
  imports: [
    MatProgressBarModule,
    NgxPaginationModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    CdkTableModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    NgApexchartsModule,
    SwiperModule,
    PaginatorModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDyCSb98Hs5BQQlATNUswc0SSIEEyo6Q9U',
      libraries: ['places'],
    }),
    AgmDirectionModule,
    AngularEditorModule,
    TreeModule,
    GridsterModule,
    NgScormPlayerModule.forChild({ debug: true }),
    MatExpansionModule,
    TableModule,
    ConfirmDialogModule,
    MultiSelectModule,
    ButtonModule,
    TooltipModule,
    CardModule,
    SelectButtonModule,
    TextFieldModule,
    /* CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }), */
    PdfViewerModule,
    OverlayPanelModule,
    MatStepperModule,
    PCalendarModule,
    MatDatepickerModule,
    CarouselModule,
    ImageCropperModule,
    PickerModule,
    NgxCaptureModule,
    ApplicationPipesModule,
    LoaderModule,
    QrScannerControlModule,
    BreadCrumbModule,
  ],
  declarations: [
    GoogleMapsComponent,
    FileUploadComponent,
    FileComponent,
    DragNDropDirective,
    ImageComponent,
    DialogComponent,
    DynamicConfirmationDialogComponent,
    ChartsComponent,
    TableComponent,
    FileUploadPreviewComponent,
    EntityComponent,
    BpmnViewerComponent,
    DelegaionComponent,
    ListDelegationComponent,
    GsiGridCardComponent,
    EntityGridCardComponent,
    LocationComponent,
    ImportExportComponent,
    ImportSolutionComponent,
    ImportsolutionTableComponent,
    ExportsolutionTableComponent,
    OCRModalComponent,
    ImageMapComponent,
    SchedulerComponent,
    AccordionComponent,
    BookDetailsOnHoverComponent,
    ExportWithBpmnComponent,
    ChatBotMediaDialog,
    /* CalendarComponent,
    CalendarParentComponent,
    CalendarEventsComponent,
    CalendarDayViewSchedulerComponent, */
    EmailTemplateEditorComponent,
    LivechatSmartComponent,
    AutoIdNestedEntityComponent,
    SharedErrorHandlerComponent,
    EntityMapperComponent,
    UpdateProfileComponent,
    EntityRadioDcdComponent,
    ECommerceTemplatesComponent,
    InsuranceExpandPreviewComponent,

    ExpandViewPreviewComponent,
    ECommerceExpandPreviewComponent,
    InsuranceTemplateComponent,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    NgxMatDatetimePickerModule,
    NgScormPlayerModule,
    DynamicConfirmationDialogComponent,
    GsiGridCardComponent,
    EntityGridCardComponent,
    DelegaionComponent,
    ListDelegationComponent,
    BpmnViewerComponent,
    ChartsComponent,
    FileUploadComponent,
    ImageMapComponent,
    ImportsolutionTableComponent,
    ExportsolutionTableComponent,
    ImportSolutionComponent,
    ImportExportComponent,
    OCRModalComponent,
    AngularEditorModule,
    AccordionComponent,
    BookDetailsOnHoverComponent,
    ExportWithBpmnComponent,
    AngularEditorModule,
    TextFieldModule,
    ChatBotMediaDialog,
    /* CalendarComponent,
    CalendarParentComponent,
    CalendarDayViewSchedulerComponent, */
    LivechatSmartComponent,
    AutoIdNestedEntityComponent,
    PCalendarModule,
    NgApexchartsModule,
    TooltipModule,
    NgxCaptureModule,
    SharedErrorHandlerComponent,
    EntityMapperComponent,
    UpdateProfileComponent,
    SwiperModule,
    MatStepperModule,
    ApplicationPipesModule,
    LoaderModule,
    PaginatorModule,
    MultiSelectModule,
    NgxPaginationModule,
    TableModule,
    ConfirmDialogModule,
    CarouselModule,
    EntityRadioDcdComponent,
  ],
  providers: [
    AlertService,
    DLDRecommendationsStore,
    ChatBotStore,
    {
      provide: MatPaginatorIntl,
      useClass: ListDelegationComponent,
    },
  ],
  entryComponents: [ChatBotMediaDialog],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}

import { HttpRequest, HttpResponse, HttpInterceptor, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpCacheService } from '@app/cache/cache.service';
import { Observable } from 'rxjs';
import { of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  reqToBeCached = [
    '/dsd-orch/translator/language',
    // '/dsd-orch/design/book/global/design/-1',
    // '/dsd-orch/design/book/global/time/design/',
    '/dsd-files-store/content/cdui/static/assets/covers/',
  ];
  constructor(private cacheService: HttpCacheService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let isSubstring = false;
    let cachedResponse: any;
    this.reqToBeCached.forEach((item: any) => {
      /* istanbul ignore next */
      isSubstring = req?.urlWithParams?.includes(item);
      /* istanbul ignore next */
      if (isSubstring) {
        cachedResponse = this.cacheService[req?.urlWithParams] || null;
      }
    });
    /* istanbul ignore next */
    if (cachedResponse) {
      return of(cachedResponse);
    }
    /* istanbul ignore next */
    let index = this.reqToBeCached.findIndex((url: any) => url === req?.url);
    /* istanbul ignore next */
    if (index != -1) {
      cachedResponse = this.cacheService[req?.urlWithParams] || null;
      if (cachedResponse) {
        return of(cachedResponse);
      }
    }
    /* istanbul ignore next */
    return next?.handle(req).pipe(
      tap(
        (event) => this.handleResponse(req, event),
        (error) => this.handleError(req, error)
      )
    );
  }

  handleError(req: HttpRequest<any>, error: any): void {
    console.log(req.headers + 'is failed because ' + error);
  }

  handleResponse(req: HttpRequest<any>, event: HttpEvent<any>): void {
    /* istanbul ignore next */
    let index = this.reqToBeCached.findIndex((url: any) => url === req?.url);
    let isSubstring = false;
    this.reqToBeCached.forEach((item: any) => {
      /* istanbul ignore next */
      isSubstring = req?.urlWithParams?.includes(item);
      /* istanbul ignore next */
      if (isSubstring) {
        this.cacheService[req?.urlWithParams] = event;
      }
    });
    /* istanbul ignore next */
    if (event instanceof HttpResponse && index != -1) {
      this.cacheService[req?.urlWithParams] = event;
    }
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { CduiBaseService } from './cdui-base.service';

@Injectable()
export class AppInitService extends CduiBaseService {
  constructor(private injector: Injector, private http: HttpClient) {
    super(injector);
  }

  getEnvBasicInfo(urlParamsTenantName: any) {
    return this.http.get(`${this.api.envBasicInfo}${urlParamsTenantName}`);
  }
}

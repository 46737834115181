import { Injectable, OnDestroy } from '@angular/core';
import { attributeDeMapper } from '@app/@core/mappers/attribute/attribute-demapper';
import { attributeMapper } from '@app/@core/mappers/attribute/attribute-mapper';
import { attributeListMapper } from '@app/@core/mappers/list/attribute';
import { AlertService } from '@app/@core/toaster/alert.service';
import { Attribute } from '@app/@shared/models/attribute';
import { LoaderService } from '@app/@shared/services/loader/loader.service';
import { AttributeStoreService } from '@app/@shared/stores/attribute-store';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EntityFacadeService } from '../entity/entity-facade.service';
import { AttributeEndpointService } from './attribute-endpoint.service';

@Injectable({
  providedIn: 'root',
})
export class AttributeFacadeService implements OnDestroy {
  public ngUnsubscribe = new Subject();

  attributeName = new BehaviorSubject(null);
  attributeName$ = this.attributeName.asObservable();

  public saveAttributeResponse = new BehaviorSubject(null);
  saveAttributeResponse$ = this.saveAttributeResponse.asObservable();

  specialFeatureAttribute = new BehaviorSubject(null);
  specialFeatureAttribute$ = this.specialFeatureAttribute.asObservable();

  private attributeSubject = new Subject();
  attributes$ = this.attributeSubject.asObservable();

  public customAttribute = new Subject();
  customAttribute$ = this.customAttribute.asObservable();

  constructor(
    private attributeEndpointService: AttributeEndpointService,
    private loader: LoaderService,
    private alertService: AlertService,
    private attributeStoreService: AttributeStoreService,
    private entityFacadeService: EntityFacadeService
  ) {
    this.attributeStoreService.stateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((state) => {
      if (state) {
        this.attributeSubject.next(state.attributeList);
      }
    });
  }
  attributeSubjectNext(res: any) {
    this.attributeSubject.next(res);
  }
  getAllAttributeTypes(searchString: string, pageIndex: number, pageSize: number, currentAttrType?: any) {
    return this.attributeEndpointService.getAllAttributeTypes(searchString, pageIndex + 1, pageSize, currentAttrType);
  }

  changeSpecialFeatureAttribute(data: any) {
    this.specialFeatureAttribute.next(data);
  }

  getAllAttributes(pagenum: number, pageSize: number, searchCriteria: string, isPublished: any, layerType?: any) {
    this.loader.show();
    this.attributeEndpointService
      .getAllAttributes(pagenum, pageSize, searchCriteria)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        const attList = attributeListMapper(res);
        this.attributeStoreService.setStateData(attList);
      });
  }

  sendCustomAttributeForEntity(data: any) {
    this.attributeEndpointService
      .getAllAttributesByName(data.name)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.customAttribute.next(res);
      });
  }

  saveAttribute(attribute: Attribute) {
    this.loader.show();
    let attrInfo = attributeDeMapper(attribute);
    this.attributeEndpointService
      .saveAttribute(attrInfo)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          this.alertService.showToaster(res.message, '', 'success');

          const attributeMapperResult = attributeMapper(res.result);
          this.saveAttributeResponse.next(attributeMapperResult);
        },
        (error) => {
          const { err } = error.message;
          this.loader.hide();
          this.alertService.showToaster(err, '', 'error');
        }
      );
  }

  setAttibuteInfo(attrName: any) {
    this.attributeName.next(attrName);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}

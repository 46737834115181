import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { CduiBaseService } from '@app/auth/cdui-base.service';
import { pluck } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SoltuionDictionaryEndpointService extends CduiBaseService {
  fileName: any = [];
  constructor(private injector: Injector, private http: HttpClient) {
    super(injector);
  }
  /* istanbul ignore next */
  getOntologyMasterList() {
    /* istanbul ignore next */
    return this.http.get(this.api?.ontologyMaster);
  }

  getSpecificBETByName(filterData: any) {
    /* istanbul ignore next */
    return this.http.post(this.api?.browseDetailed, filterData).pipe(pluck('result'));
  }

  addBETToMylibrary(betType: any, betDetails: any) {
    /* istanbul ignore next */
    return this.http.post(this.api?.betsStoreImport + `${betType}/${betDetails?.dsdId}`, betDetails);
  }

  importAndValidateFromBetStore(betDetails: any, betType: any) {
    /* istanbul ignore next */
    return this.http.post(this.api?.importAndValidateFromBetStore, betDetails.marketplaceId);
  }

  betStoreImport(bookDetails: any, dsdId: any, betType: any) {
    if (betType === 'changeunit') {
      betType = 'change-unit';
    }
    if (betType === 'books') {
      betType = 'book';
    }
    /* istanbul ignore next */
    return this.http.post(this.api?.betsStoreImport + `${betType}` + `/${dsdId}`, bookDetails);
  }

  betStoreBookImport(bookDetails: any, dsdId: any) {
    /* istanbul ignore next */
    return this.http.post(this.api?.betStoreBookImport + `/${dsdId}`, bookDetails);
  }

  publishBETToNSLlibrary(betType: any, betDetails: any) {
    /* istanbul ignore next */
    return this.http.post(this.api?.betsStorePublish + `${betType}/${betDetails?.id}`, betDetails);
  }

  publishBETToNSL(betDetails: any) {
    /* istanbul ignore next */
    return this.http.post(this.api?.betsStorePublishtoNSL + `${betDetails?.id}`, betDetails);
  }

  getBookEntity(bookID: any, betType: string) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    /* istanbul ignore next */
    return this.http.post(this.api?.getBookEntityList + `${betType}/` + `${bookID}`, bookID, options);
  }

  getBookDashboard(bookID: any) {
    /* istanbul ignore next */
    return this.http.get(this.api?.getBookDashboardList + `/${bookID}`);
  }

  loadBookDetails(bookDetails: any) {
    /* istanbul ignore next */
    return this.http.post(
      this.api?.getBooksDetailList + `${bookDetails?.type}/` + `${bookDetails?.dsdId}`,
      bookDetails
    );
  }

  getOverviewDetails(betDetails: any) {
    /* istanbul ignore next */
    return this.http.post(this.api?.nslBookOverview, betDetails);
  }

  getBookDetails(betDetails: any) {
    /* istanbul ignore next */
    return this.http.post(this.api?.nslBookOverviewDetailed, betDetails);
  }

  /* nsl reviews */
  addNslReview(betDetails: any) {
    /* istanbul ignore next */
    return this.http.post(this.api?.nslAddReview, betDetails);
  }
  getNslReview(betDetails: any) {
    /* istanbul ignore next */
    return this.http.post(this.api?.nslGetReviews, betDetails);
  }
  /* ./nsl reviews */

  /* my library overview */
  getMyLibraryOverview(betType: any, id: any) {
    const options = {
      headers: new HttpHeaders({ bookId: `${id}` }),
    };
    /* istanbul ignore next */
    return this.http.get(this.api?.myLibraryOverview + `${id}?betType=${betType}`, options);
  }

  getMyLibraryDetailedOverview(betType: any, id: any) {
    /* istanbul ignore next */
    return this.http.get(this.api?.myLibraryDetailedOverview + `${id}?betType=${betType}`);
  }
  /* ./my library overview */

  //notepad
  getNotepadList(
    betType: string,
    consolidated: boolean,
    pageSize: number,
    pagenum: number,
    searchCriteria: string,
    sortBy: string
  ) {
    /* istanbul ignore next */
    return this.http.get(
      `${this.api?.getNotepadList}?betType=${betType}&combined=${consolidated}&limit=${pageSize}&page=${pagenum}&query=${searchCriteria}&sortBy=${sortBy}`
    );
  }

  getGlobalSearch(searchText: string, betType: string, pageNumber: number, pageSize: number) {
    /* istanbul ignore next */
    return this.http.get(
      `${this.api?.globalSearchforNotepad}?betType=${betType}&limit=${pageSize}&mostDownloaded=false&mostLiked=false&page=${pageNumber}&query=${searchText}`
    );
  }

  saveNotepad(payload: any) {
    payload.content = '<div>' + payload.content + '</div>';
    payload.content = JSON.stringify(payload.content);
    /* istanbul ignore next */
    return this.http.post(`${this.api?.saveNotepad}`, payload);
  }

  uploadFile(payload: any) {
    /* istanbul ignore next */
    return this.http.post(this.api?.singleFileUpload, payload);
  }

  getNotepadContent(dsdId: string) {
    /* istanbul ignore next */
    let response = this.http.get(`${this.api?.getNotepadContent}/${dsdId}`);
    return response;
  }

  getMyCartData() {
    /* istanbul ignore next */
    return this.http.get(`${this.api?.cartDetails}`);
  }

  updateMyCartData(cartId: any, marketplaceId: any, status: string) {
    /* istanbul ignore next */
    return this.http.get(`${this.api?.updateCartDetails}/${cartId}?marketplaceId=${marketplaceId}&status=${status}`);
  }
}

import { Injectable, Injector } from '@angular/core';
import { ChatBotStore, ChatBotStoreActions } from '@app/@shared/stores/chatbot-state';
import { CduiBaseService } from '@app/auth/cdui-base.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IFeedback, LivechatEndpointService, Registration } from './livechat-endpoint.service';

@Injectable({
  providedIn: 'root',
})
export class LivechatFacadeService extends CduiBaseService {
  chatMessage: any[] = [];
  updatedChatData = new Subject();
  updatedChatData$ = this.updatedChatData.asObservable();
  ngUnsubscribe = new Subject();
  isShown: boolean;

  constructor(
    private livechatEndpointService: LivechatEndpointService,
    private chatBotStore: ChatBotStore,
    injector: Injector
  ) {
    super(injector);
    this.detectChatBotStateChange();
  }

  resumeChatbotState() {
    let data = this.chatBotStore.getStateData();
    console.log(data);
    setTimeout(() => {
      this.updatedChatData.next({ ...data, playlastMessage: false });
    });
  }

  detectChatBotStateChange() {
    this.chatBotStore.stateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((updatedStoreData: any) => {
      console.log(updatedStoreData);
      this.updatedChatData.next(updatedStoreData);
    });
  }

  resetStore() {
    this.chatBotStore.setStateData({}, ChatBotStoreActions.ResetStore);
  }

  setChatBotState(body: any, action: any) {
    this.chatBotStore.setStateData(body, action);
  }

  connect(token: string, roomId: any, userInfo: any, jwtToken: string, flag?: string) {
    /*istanbul ignore next*/
    let register: Registration = {
      userName: userInfo?.name,
      email: userInfo?.email,
      token: token,
      jwtToken: jwtToken,
      department: flag === 'TenantSpecific' ? localStorage.getItem('TenantName') : '',
    };
    this.livechatEndpointService.connectSockJS(token, roomId, userInfo, register);
  }

  send(token: string, roomId: any, message: string) {
    let body = {
      quest: message,
      read: true,
    };
    this.setChatBotState(body, ChatBotStoreActions.UpdateQuestion);
    this.livechatEndpointService.sendMessage(token, roomId, message);
  }

  disconnect(token: string, roomId: any) {
    this.livechatEndpointService.disconnect(token, roomId);
  }

  transcript(token: string, roomId: any, userInfo: any) {
    this.livechatEndpointService.transcript(token, roomId, userInfo);
    this.resetStore();
  }

  /*istanbul ignore next*/
  feedBack(data: IFeedback) {
    this.livechatEndpointService
      .sendFeedback(data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          /* istanbul ignore next */
          if (res) {
            console.log('Feedback sent!!!');
          }
        },
        (error: any) => {
          if (error) {
            console.log('Error!!!');
          }
        }
      );
  }

  changeIsShown(flag: boolean) {
    this.isShown = flag;
    this.livechatEndpointService.changeIsShown(this.isShown);
  }

  setDepartment(data: any, roomId: string) {
    this.livechatEndpointService.disconnect(data?.token, roomId);
  }

  addWelcomeMessage(message: string, roomId: string) {
    this.livechatEndpointService.addWelcomeMessage(message, roomId);
  }

  fileUpload(formdata: FormData, roomId: string) {
    /*istanbul ignore else*/
    if (formdata) {
      this.livechatEndpointService.fileUpload(formdata, roomId).subscribe(
        (res: any) => {
          console.log(res);
        },
        /*istanbul ignore next*/
        (err: any) => {
          if (err.status === 200) {
            let filePath: string = err?.error?.text;
            console.log(filePath);
            filePath = `${filePath}`;
            // filePath = `${this.api.paasLivechatUrl}${filePath}`;
            let arr: string[] = filePath.split('.');
            let fileType: string = arr[arr.length - 1];
            let arr1: string[] = filePath.split('/');
            let fileName: string = arr1[arr1.length - 1];
            fileName = `${fileName}`;
            let data = {
              filePath: filePath,
              fileType: fileType,
              fileName: fileName,
              read: true,
            };
            this.setChatBotState(data, ChatBotStoreActions.UpdateFile);
          } else {
            throw new Error(err);
          }
        }
      );
    }
  }

  fileDownload(fileUrl: string) {
    this.livechatEndpointService.fileDownload(fileUrl);
  }
}

<div>
  <div class="subpopup-cu">
    <p>
      Do you want to go with sub transaction solution flow or usual execution flow ?
    </p>
  </div>
  <div class="subpopup-cu">
    <div class="submit">
      <button mat-button mat-dialog-close (click)="upgrade()">{{ labels?.Yes }}</button>
      <button mat-button mat-dialog-close>{{ labels?.No }}</button>
    </div>
  </div>
</div>

import { Component, OnInit, Inject, Input, Optional } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from '@app/@core/toaster/alert.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TranslatorService } from '@app/shell/translator.service';
import * as XLSX from 'xlsx';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TransactionFacadeService } from '@app/transaction/transaction-facade.service';
type AOA = any[][];
@Component({
  selector: 'app-file-upload-preview',
  templateUrl: './file-upload-preview.component.html',
  styleUrls: ['./file-upload-preview.component.scss'],
})
export class FileUploadPreviewComponent implements OnInit {
  @Input() src: any;
  canDownload: boolean = true;
  fileToBeDownloaded: string;
  sheetData: AOA = [
    [1, 2],
    [3, 4],
  ];
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  rawSheetData: any;
  fileName: string = 'SheetJS.xlsx';
  isSheet: boolean = false;
  htmlMimeTypes = ['application/html'];
  audioMimeTypes = ['audio/mpeg', 'audio/wav', 'audio/x-wav', 'audio/x-mpeg-3'];
  imageMimeTypes = ['image/png', 'image/gif', 'image/pjpeg', 'image/jpeg', 'image/svg+xml', 'image/webp'];
  videoMimeTypes: any[] = ['video/x-msvideo', 'video/mp4', 'application/ogg', 'video/webm', 'video/x-ms-asf'];
  docMimeTypes = ['text/plain'];
  pdfMimeType = ['application/pdf', 'text/html'];
  onlyForSheet: any[] = [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ];
  ngSubscribe = new Subject();
  labels: any;

  constructor(
    private alertService: AlertService,
    public dialogRef: MatDialogRef<FileUploadPreviewComponent>,
    private translator: TranslatorService,
    public dialogService: MatDialog,
    private transactionFacadeService: TransactionFacadeService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.detectLanguageChange();
    /* istanbul ignore next */
    if (data?.rawSheetData?.target?.files[0]) {
      this.rawSheetData = data.rawSheetData;
      this.onFileChange(this.rawSheetData);
    }
    this.src = data;
    this.canDownload = data.canDownload;
    /* istanbul ignore else */
    if (data.mimeType) {
      this.checkFileType();
    } else if (!this.rawSheetData) {
      this.src.mimeType = 'text/plain';
    }
    this.fileToBeDownloaded = data?.displayName;
  }
  ngOnInit(): void {}

  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngSubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }
  checkFileType() {
    const allFileTypes = [
      ...this.audioMimeTypes,
      ...this.videoMimeTypes,
      ...this.docMimeTypes,
      ...this.onlyForSheet,
      ...this.imageMimeTypes,
      ...this.pdfMimeType,
      ...this.htmlMimeTypes,
    ];
    let index = allFileTypes.findIndex((fileType: any) => fileType === this.src.mimeType);
    /* istanbul ignore else */
    if (index === -1) {
      this.dialogRef.close();
      /* istanbul ignore next */
      this.alertService.showToaster(this.labels?.Preview_not_supported_for_this_file_format, '', 'error');
      /* istanbul ignore next */
      const download_index = this.src?.contentUrl?.indexOf('download=true');
      let downloadUrl = this.src.contentUrl;
      /* istanbul ignore else */
      if (download_index === -1) {
        downloadUrl = `${downloadUrl}?download=true`;
      }
      window.open(downloadUrl);
    }
  }
  isPdfType() {
    /* istanbul ignore next */
    let index = this.pdfMimeType.findIndex((ele: any) => ele === this.src.mimeType);
    return index === -1 ? false : true;
  }

  isAudioType() {
    /* istanbul ignore next */
    let index = this.audioMimeTypes.findIndex((ele: any) => ele === this.src?.mimeType);
    return index === -1 ? false : true;
  }

  isHtmlType() {
    /* istanbul ignore next */
    let index = this.htmlMimeTypes.findIndex((ele: any) => ele === this.src?.mimeType);
    return index === -1 ? false : true;
  }

  isVideoType() {
    /* istanbul ignore next */
    let index = this.videoMimeTypes.findIndex((ele: any) => ele === this.src?.mimeType);
    return index === -1 ? false : true;
  }

  isImageType() {
    /* istanbul ignore next */
    let index = this.imageMimeTypes.findIndex((ele: any) => ele === this.src?.mimeType);
    return index === -1 ? false : true;
  }

  isFrameSupported() {
    /* istanbul ignore next */
    let index = this.docMimeTypes.findIndex((ele: any) => ele === this.src?.mimeType);
    return index === -1 ? false : true;
  }

  downloadFile() {
    if (this.isSheet) {
      const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.sheetData);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, this.fileName);
    } else {
      this.transactionFacadeService.downloadFile(this?.src?.contentUrl, this.fileToBeDownloaded);
    }
  }
  ngOnDestroy() {
    this.ngSubscribe.next();
    this.ngSubscribe.complete();
  }
  closePopup() {
    this.dialogService.closeAll();
  }

  onFileChange(rawSheetData: any) {
    const target: DataTransfer = <DataTransfer>rawSheetData.target;
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      this.sheetData = <AOA>XLSX.utils.sheet_to_json(ws, { header: 1 });
      this.isSheet = true;
    };
    /* istanbul ignore next */
    reader.readAsBinaryString(target?.files[0]);
  }
}

import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { scan, delay, retryWhen } from 'rxjs/operators';

@Injectable()
export class RetryInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    /* istanbul ignore next */
    return next?.handle(request).pipe(
      retryWhen((e) =>
        e.pipe(
          scan((errorCount, error, errorStatus: any) => {
            /* istanbul ignore else */
            if (errorCount >= 4 || window.navigator.onLine) {
              throw error;
            }
            return errorCount + 1;
          }, 0),
          delay(1000)
        )
      )
    );
  }
}

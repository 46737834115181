<div class="login-container login-main-wrapper">
  <div class="login-box login-wrapper">
    <div class="middle-content">
      <div class="login-left-content">
        <ng-lottie [options]="lottieOptions" class="login-globe"></ng-lottie>
      </div>
      <div class="login-right-content">
        <div class="w-100">
          <div>
            <h4 class="card-title">Provide OTP</h4>
          </div>
          <form>
            <div class="form-field">
              <div class="icon-filed">
                <img [src]="'../../assets/img/login/icon-email.svg' | CDNUrlRewritePipe" />
              </div>
              <input
                type="password"
                class="form-control"
                id="fileotp"
                email
                [(ngModel)]="fileotp"
                name="fileotp"
                required
                placeholder="Enter OTP"
                autocomplete="off"
              />
            </div>
            <div class="footer-cls">
              <button class="btn-custom" id="forgotSubmit" (click)="clickSubmit()">{{ labels?.Submit }}</button>
              <button class="btn-custom btn-cancel" id="forgotPswdCancel" (click)="cancelClick()">
                {{ labels?.Cancel }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

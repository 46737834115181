import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { Gsi } from '@app/@shared/models/gsi';
import { BehaviorSubject } from 'rxjs';
import { ChangeUnitFacadeService } from '@app/create/shared/services/change-unit/change-unit-facade.service';
import { TransactionEndpointService } from './transaction-endpoint.service';
import { LoaderService } from '@app/@shared/services/loader/loader.service';
import { AlertService } from '../@core/toaster/alert.service';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { transactionChangeUnitDeMapper } from '@app/@core/mappers/transaction/transaction-cu-demapper';
import { transactionChangeUnitMapper } from '@app/@core/mappers/transaction/transaction-cu-mapper';
import { EventsEndpointService } from '@app/@shared/services/events/events-endpoint.service';
import { EventsFacadeService } from '@app/@shared/services/events/events-facade.service';
import { paginationDetails } from '@app/solution-dictionary/models/notepad.model';
import { pagination } from '@app/@shared/models/pagination';

@Injectable({
  providedIn: 'root',
})
export class TransactionFacadeService implements OnDestroy {
  gsi: Gsi;
  currentTab = 'myCollection';
  nextChangeUnitData = {};
  eventGsiData: any;
  pendingGsiData = new Array();
  nextEventData: any;
  eventCompletedData: any;
  isCarnival = false;
  paginationDetails = {} as paginationDetails;
  selectedTableRecords: any = [];
  previousPageNumber: any;
  selectedTableAtrributeRecords: any = [];
  presentPageNumber: any = 0;

  /*private gsiSubject = new BehaviorSubject(this.gsi);
  gsiData$ = this.gsiSubject.asObservable();*/
  carnivalScreen = new BehaviorSubject(false);
  carnivalScreen$ = this.carnivalScreen.asObservable();
  bookSubject = new BehaviorSubject('');
  bookData$ = this.bookSubject.asObservable();
  changeOfTabSubject = new BehaviorSubject(this.currentTab);
  currentTab$ = this.changeOfTabSubject.asObservable();
  nextChangeUnitSubject = new Subject();
  nextChangeUnit$ = this.nextChangeUnitSubject.asObservable();
  resumeTransactionSubject = new BehaviorSubject('');
  resumeTransactionData$ = this.resumeTransactionSubject.asObservable();

  public pendingTransactions = new BehaviorSubject('');
  pendingTransactions$ = this.pendingTransactions.asObservable();

  createdTransactions = new BehaviorSubject('');
  createdTransactions$ = this.createdTransactions.asObservable();
  assignedTransactions = new BehaviorSubject('');
  assignedTransactions$ = this.assignedTransactions.asObservable();

  escalatedTransactions = new BehaviorSubject('');
  escalatedTransactions$ = this.escalatedTransactions.asObservable();

  listChangeUnitSubject = new Subject();
  listChangeUnit$ = this.listChangeUnitSubject.asObservable();

  // conditional potentiality
  ConditionalPotentialitySubject = new Subject();
  ConditionalPotentialityAttr$ = this.ConditionalPotentialitySubject.asObservable();

  transactionHistoryCuList = new Subject();
  transactionHistoryCuList$ = this.transactionHistoryCuList.asObservable();

  transactionHistoryEmbeddedCuList = new Subject();
  transactionHistoryEmbeddedCuList$ = this.transactionHistoryEmbeddedCuList.asObservable();

  transactionHistoryEntityList = new Subject();
  transactionHistoryEntityList$ = this.transactionHistoryEntityList.asObservable();

  transactionHistoryEmbeddedEntityList = new Subject();
  transactionHistoryEmbeddedEntityList$ = this.transactionHistoryEmbeddedEntityList.asObservable();

  private reservedActionsList = new Subject();
  reservedActionsList$ = this.reservedActionsList.asObservable();
  // advance search in pending txn
  private advanceSearchSubject = new Subject();
  advanceSearchTxn$ = this.advanceSearchSubject.asObservable();

  private allTransactions = new Subject();
  allTransactions$ = this.allTransactions.asObservable();

  transactionAds = new BehaviorSubject('');
  transactionAds$ = this.transactionAds.asObservable();

  private filteredTransactions = new Subject();
  filteredTransactions$ = this.filteredTransactions.asObservable();

  dynamicFormSubmit = new Subject();
  dynamicFormSubmit$ = this.dynamicFormSubmit.asObservable();

  previousCUlist = new Subject();
  previousCUlist$ = this.previousCUlist.asObservable();

  //Submit button disable
  disbaleSubmitButton = new BehaviorSubject(false);
  disbaleSubmitButton$ = this.disbaleSubmitButton.asObservable();

  locationDetails = new Subject();
  locationDetails$ = this.locationDetails.asObservable();
  // subtransaction entity
  private SubtransactionalEntityList = new Subject();
  SubtransactionalEntityList$ = this.SubtransactionalEntityList.asObservable();

  pendingTransaction = new BehaviorSubject('');
  pendingTransaction$ = this.pendingTransaction.asObservable();

  waitForTransaction = new Subject();
  waitForTransaction$ = this.waitForTransaction.asObservable();

  transactionViewType = new BehaviorSubject(false);
  transactionViewType$ = this.transactionViewType.asObservable();
  //scheduled jobs
  scheduledJobs = new Subject();
  scheduledJobs$ = this.scheduledJobs.asObservable();

  jobTriggers = new Subject();
  jobTriggers$ = this.jobTriggers.asObservable();

  jobExecutions = new Subject();
  jobExecutions$ = this.jobExecutions.asObservable();

  allTriggers = new Subject();
  allTriggers$ = this.allTriggers.asObservable();

  unscheduleJobs = new Subject();
  unscheduleJobs$ = this.unscheduleJobs.asObservable();

  unscheduleTriggers = new Subject();
  unscheduleTriggers$ = this.unscheduleTriggers.asObservable();

  pauseTriggerSubject = new Subject();
  pauseTriggerSubject$ = this.pauseTriggerSubject.asObservable();
  resumeTriggerSubject = new Subject();
  resumeTriggerSubject$ = this.resumeTriggerSubject.asObservable();

  pauseJobSubject = new Subject();
  pauseJobSubject$ = this.pauseJobSubject.asObservable();
  resumeJobSubject = new Subject();
  resumeJobSubject$ = this.resumeJobSubject.asObservable();

  eventGsiDataSubject = new BehaviorSubject('');
  eventGsiData$ = this.eventGsiDataSubject.asObservable();

  stopResumeTransactionSubject = new BehaviorSubject(false);
  stopResumeTransaction$ = this.stopResumeTransactionSubject.asObservable();

  pendingGsiArraySubject = new BehaviorSubject('');
  pendingGsiArray$ = this.pendingGsiArraySubject.asObservable();

  updateTransactionIdData = new BehaviorSubject('');
  updateTransactionIdData$ = this.updateTransactionIdData.asObservable();

  debug = new Subject();
  debug$ = this.debug.asObservable();

  debugValues = new Subject();
  debugValues$ = this.debugValues.asObservable();
  isTransactionCompleted = new BehaviorSubject(false);
  isTransactionCompleted$ = this.isTransactionCompleted.asObservable();

  private unsubscribe$: Subject<any> = new Subject<any>();
  eventAttributeData: any;

  listOfAllComments = new BehaviorSubject([]);
  listOfAllComments$ = this.listOfAllComments.asObservable();

  saveSubtransactionalData = new BehaviorSubject('');
  saveSubtransactionalData$ = this.saveSubtransactionalData.asObservable();

  milestoneToPendingTxn = new BehaviorSubject('');
  milestoneToPendingTxn$ = this.milestoneToPendingTxn.asObservable();

  submitButtonEmit = new Subject();
  submitButtonEmit$ = this.submitButtonEmit.asObservable();

  submitButtonDisabled = new Subject();
  submitButtonDisabled$ = this.submitButtonDisabled.asObservable();

  transactionSmartSolutionData = new Subject();
  transactionSmartSolutionData$ = this.transactionSmartSolutionData.asObservable();

  closeLoopOfAr = new BehaviorSubject('');
  closeLoopOfAr$ = this.closeLoopOfAr.asObservable();
  constructor(
    private router: Router,
    private changeUnitFacadeService: ChangeUnitFacadeService,
    private transactionEndpointService: TransactionEndpointService,
    private alertService: AlertService,
    private loader: LoaderService,
    private eventEndpointService: EventsEndpointService,
    private eventsFacadeService: EventsFacadeService
  ) {
    this.getDesignedBookData();
  }

  updateCarnivalScreen(data: boolean) {
    this.carnivalScreen.next(data);
    this.isCarnival = data;
  }

  //submit button disable
  disableSubmitButton(flage: boolean) {
    this.disbaleSubmitButton.next(flage);
  }
  //close loop of ar
  setArData(data: any) {
    this.closeLoopOfAr.next(data);
  }
  changeOfTab(nextTab: string) {
    this.changeOfTabSubject.next(nextTab);
  }
  getDesignedBookData() {
    const designedBookData = JSON.parse(localStorage.getItem('layout-designSystem'));
    /* istanbul ignore next */
    if (designedBookData != null) {
      this.bookSubject.next(designedBookData);
    }
  }

  changeTransactionViewType(flag: boolean) {
    this.transactionViewType.next(flag);
  }

  saveSubtransaction(data: any) {
    this.saveSubtransactionalData.next(data);
  }

  /*UpdateGsiData(gsiData: Gsi) {
    this.gsiSubject.next(gsiData);
  }*/

  getPreviousCUData(queryParamsData: any, isDebugMode: boolean) {
    return this.transactionEndpointService.getPreviousCUData(queryParamsData, isDebugMode);
  }

  UpdateBookData(bookData: any) {
    this.bookSubject.next(bookData);
  }

  getChangeUnitByGsiId(gsiId: number, triggerCuId?: any, bookid?: any) {
    this.changeUnitFacadeService.getCusByGsiId(gsiId, false, triggerCuId, bookid);
  }
  downloadFile(url: string, fileToBeDownloaded?: string) {
    /* istanbul ignore next */
    const download_index = url.indexOf('download=true');
    let downloadUrl = url;
    /* istanbul ignore next */
    if (download_index === -1) {
      downloadUrl = `${downloadUrl}`;
    }
    this.transactionEndpointService.downloadFile(downloadUrl, fileToBeDownloaded);
  }

  reAssignTxn(txnid: any, userid: any, assignComment: any, payload?: any) {
    this.transactionEndpointService
      .reAssign(txnid, userid, assignComment, payload)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: any) => {
          /* istanbul ignore next */
          if (res && res.status && res.status == 200) {
            this.alertService.showToaster(`${res.message}`, '', 'success');
            this.router.navigate(['/mylibrary/pending-transactions']);
          }
          return res;
        },
        (error) => {
          const { err } = error;
          this.alertService.showToaster(`${err}`, '', 'error');
        }
      );
  }

  showSuccessMessage(res: any) {
    this.alertService.showToaster(`${res.message}`, '', 'success');
    this.router.navigate(['/mylibrary/pending-transactions']);
  }

  escalateTxn(txnid: any, escalateComment?: any, payload?: any) {
    this.transactionEndpointService
      .escalateTxn(txnid, escalateComment, payload)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (res: any) => {
          /* istanbul ignore next */
          if (res && res.status && res.status == 200) {
            this.showSuccessMessage(res);
          }
          return res;
        },
        (error) => {
          const { err } = error;
          this.alertService.showToaster(`${err}`, '', 'error');
        }
      );
  }
  cancelAutoSubmitTxn(txnId: any, payload?: any) {
    this.transactionEndpointService
      .cancelAutoSubmit(txnId, payload)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.getPendingTransactions(0, 10, '');
        this.alertService.showToaster(res.message, '', 'success');
      });
  }

  getNextChangeUnitByGSIId(postData: object) {
    this.loader.show();
    this.transactionEndpointService
      .getNextChangeUnitByGSIId(postData)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.loader.hide();
        this.nextChangeUnitSubject.next(res);
      });
  }
  // Remove '$' from entity name in layers
  removeEntityName(postData: any) {
    /* istanbul ignore next */
    postData?.currentCU?.layers?.forEach((layer: any) => {
      /* istanbul ignore next */
      layer?.entityList?.forEach((ent: any) => {
        /* istanbul ignore next */
        for (let value of ent?.name) {
          if (value == '$') {
            /* istanbul ignore next */
            ent.name = ent?.name?.substring(0, ent?.name.indexOf('$'));
          }
        }
      });
    });
  }

  getListChangeUnitByGSIId(
    postData: any,
    isFromSubmit?: boolean,
    isDebug?: boolean,
    isSubtransaction?: any,
    paginationDetails?: paginationDetails
  ) {
    /* istanbul ignore next */
    if (this.eventGsiData && this.nextEventData) {
      this.loader.show();
      /* istanbul ignore next */
      this.eventEndpointService
        .setChangeUnitEvents(this.nextEventData, this.nextEventData?.currentCU?.id)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((nextEventGSIdata: any) => {
          this.loader.hide();
          this.setNextEventGSIdata(nextEventGSIdata);
        });
      /* istanbul ignore next */
      this.setGsiDraftData(null);
      this.nextEventData = null;
    } else if (paginationDetails?.pageSize) {
      this.loader.show();
      this.transactionEndpointService
        .getPaginatedRecords(postData, paginationDetails.pageNumber, paginationDetails.pageSize)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (res: any) => {
            this.loader.hide();
            /* istanbul ignore else */
            if (res) {
              this.updateTransactionId(res);
              // For event gsi
              if (this.eventGsiData) {
                this.pushPendingGsiData(postData, res);
              } else {
                this.checkFormSubmission(isFromSubmit, postData, res);
              }
              this.fetchAndExecuteMainGSI(res);
            }
          },
          (error) => {
            this.listChangeUnitSubject.next(false);
          }
        );
    } else {
      this.loader.show();
      /* istanbul ignore next */
      if (isSubtransaction) {
        this.removeEntityName(postData);
      }
      /* istanbul ignore next */
      this.transactionEndpointService
        .getListChangeUnitByGSIId(postData, this.eventGsiData, isDebug, isSubtransaction)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (res: any) => {
            this.loader.hide();
            /* istanbul ignore else */
            if (res) {
              this.updateTransactionId(res);
              // For event gsi
              if (this.eventGsiData) {
                // this.resetResumeTransactionData();
                this.pushPendingGsiData(postData);
              } else {
                this.checkFormSubmission(isFromSubmit, postData, res);
              }
              this.getReservedSearchResult(res);
              this.fetchAndExecuteMainGSI(res);

              this.redirectBasedonResMsg(res);
            }
          },
          (error) => {
            this.listChangeUnitSubject.next(false);
          }
        );
    }
  }

  // Resume pending transaction after completing event GSI data
  fetchAndExecuteMainGSI(res: any) {
    /* istanbul ignore next */
    if (res?.result?.triggerState && res?.result?.triggerState === 'COMPLETED') {
      if (this.pendingGsiData?.length > 0) {
        /* istanbul ignore next */
        let transObj = this.pendingGsiData?.pop();
        this.updatePendingGsiArray(this.pendingGsiData);
        this.resumeTransaction(transObj);

        /* istanbul ignore next */
        let crypto: Crypto = window.crypto;
        /* istanbul ignore next */
        let array = new Uint32Array(1);
        /* istanbul ignore next */
        let randomNumber = crypto.getRandomValues(array)[0];
        /* istanbul ignore next */
        randomNumber = Number('0.' + randomNumber);

        /* istanbul ignore next */
        const solutionData = btoa(
          JSON.stringify({
            gsiId: transObj?.gsiId,
            bookId: null,
            isResumeTxn: true,
            txnId: transObj?.transId,
            triggerCuId: transObj?.triggerCuId,
            contextualId: transObj?.gsiContextualId,
            randNum: Math.floor(randomNumber),
          })
        );
        this.router.navigateByUrl('transaction/view/' + solutionData);
      } else if (!this.isCarnival) {
        this.router.navigateByUrl('/cdui/mylibrary-dashboard');
      }
    }
  }

  getReservedSearchResult(res: any) {
    /* istanbul ignore next */
    let layerIndex = res?.result?.currentCU?.layers?.findIndex((layer: any) => layer?.type === 'physical');
    /* istanbul ignore next */
    if (layerIndex !== -1) {
      let reservedEntityFound = res?.result?.currentCU?.layers[layerIndex].entityList.find(
        (res: any) => res.name == 'NSL_GERecordsSize'
      );
      /* istanbul ignore next */
      if (reservedEntityFound) {
        this.paginationDetails.totalNumberOfPage = parseInt(reservedEntityFound.nslAttributes[0].values[0]);
        this.paginationDetails.totalNumberOfRecords = parseInt(reservedEntityFound.nslAttributes[1].values[0]);
        let infoLayerIndex = res?.result?.currentCU?.layers?.findIndex((layer: any) => layer?.type === 'information');
        /* istanbul ignore next */
        if (infoLayerIndex !== -1) {
          let reservedInfoEntityFound = res?.result?.currentCU?.layers[infoLayerIndex].entityList.find(
            (res: any) => res.name == 'NSL_PageMetadata_Input'
          );
          this.paginationDetails.pageNumber = parseInt(reservedInfoEntityFound.nslAttributes[0].values[0]);
          this.paginationDetails.pageSize = parseInt(reservedInfoEntityFound.nslAttributes[1].values[0]);
        }
      }
    }
  }

  setNextEventGSIdata(nextEventGSIdata: any) {
    /* istanbul ignore next */
    if (nextEventGSIdata?.result?.triggerState && nextEventGSIdata?.result?.triggerState === 'COMPLETED') {
      /* istanbul ignore next */
      this.eventCompletedData = nextEventGSIdata;
      this.fetchAndExecuteMainGSI(nextEventGSIdata);
    } else {
      this.listChangeUnitSubject.next(nextEventGSIdata);
    }
  }

  pushPendingGsiData(postData: any, res?: any) {
    // Store event gsi data in pendingGsiData array
    /* istanbul ignore next */
    if (
      this.pendingGsiData?.length === 0 ||
      this.pendingGsiData?.findIndex((data: any) => data?.gsiId === postData?.gsiId) < 0
    ) {
      /* istanbul ignore next */
      this.pendingGsiData?.push({
        transId: postData?.transId,
        gsiId: postData?.gsiId,
        gsiContextualId: null,
        triggerCuId: postData?.currentCU?.id,
      });
      this.updatePendingGsiArray(this.pendingGsiData);
    }
    /* istanbul ignore next */
    if (res?.message && res?.message == 'search GERecord using pagination successfull') {
      this.alertService.showToaster(res.message, '', 'success');
    } else {
      this.alertService.showToaster('Transaction successfully completed', '', 'success');
    }

    let eventData = postData;
    /* istanbul ignore next */
    eventData.gsiId = this.eventGsiData?.gsiId;
    this.nextEventData = eventData;
    /* istanbul ignore next */
    this.transactionSmartSolutionData.next({ gsiId: this.eventGsiData?.gsiId });
    /* istanbul ignore next */
    this.getChangeUnitByGsiId(this.eventGsiData?.gsiId);
  }

  // attribute conditional potentiality
  attrConditionalPotentiality(postData: any, entityDetails: any) {
    this.loader.show();
    this.transactionEndpointService
      .attrConditionalPotentiality(postData)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.loader.hide();
        res.transId = postData.transId;
        this.appendMissingOptions(postData, res);
        this.ConditionalPotentialitySubject.next({ res: res, entityDetails: entityDetails });
      });
  }

  redirectBasedonResMsg(res: any) {
    /* istanbul ignore next */
    if (res?.message && res?.message !== 'Txn Draft is saved successfully') {
      this.alertService.showToaster(res.message, '', 'success');
      setTimeout(() => {
        this.router.navigateByUrl('/cdui/mylibrary-dashboard');
      }, 5000);
    }
  }

  appendMissingOptions(sourceData: any, targetData: any) {
    /* istanbul ignore next */
    if (
      sourceData &&
      targetData &&
      sourceData?.gsiId === targetData?.gsiId &&
      sourceData?.transId === targetData?.transId &&
      sourceData?.currentCU?.id === targetData?.currentCU?.id
    ) {
      /* istanbul ignore next */
      sourceData?.currentCU?.layers?.forEach((sourceDataLayer: any) => {
        /* istanbul ignore next */
        targetData?.currentCU?.layers?.forEach((targetDataLayer: any) => {
          /* istanbul ignore next */
          if (sourceDataLayer?.type === targetDataLayer?.type) {
            this.appendMissingOptionsAtLayerLevel(sourceDataLayer, targetDataLayer);
          }
        });
      });
    }
  }

  appendMissingOptionsAtLayerLevel(sourceLayer: any, targetLayer: any) {
    /* istanbul ignore next */
    if (sourceLayer && targetLayer) {
      /* istanbul ignore next */
      sourceLayer?.entityList?.forEach((sourceEntity: any) => {
        /* istanbul ignore next */
        targetLayer?.entityList?.forEach((targetEntity: any) => {
          /* istanbul ignore next */
          if (sourceEntity?.id === targetEntity?.id) {
            this.appendMissingOptionsAtEntityLevel(sourceEntity, targetEntity);
          }
        });
      });
    }
  }

  checkFormSubmission(isFromSubmit: any, postData: any, res: any) {
    /* istanbul ignore next */
    if (isFromSubmit && this.pendingGsiData?.length === 0) {
      this.isTransactionCompleted.next(true);
      /* istanbul ignore next */
      const data = {
        gsiId: postData?.gsiId,
        transId: res?.result?.transId,
        triggerCuId: res?.result?.currentCU?.id,
        gsiMasterId: postData?.gsiMasterId,
        gsiContextualId: postData?.gsiContextualId,
        bookId: postData?.bookId,
      };
      localStorage.setItem('currentCuData', JSON.stringify(data));
      /* istanbul ignore next */
      if (res?.message && res?.message == 'search GERecord using pagination successfull') {
        this.alertService.showToaster(res.message, '', 'success');
      } else {
        this.alertService.showToaster('Transaction successfully completed', '', 'success');
      }
    }

    /* istanbul ignore next */
    if (res?.result?.triggerState !== 'COMPLETED') {
      this.listChangeUnitSubject.next(res);
    }
  }

  appendMissingOptionsAtEntityLevel(sourceEntity: any, targetEntity: any) {
    /* istanbul ignore next */
    if (sourceEntity?.nslAttributes && targetEntity?.nslAttributes) {
      /* istanbul ignore next */
      sourceEntity?.nslAttributes?.forEach((sourceAttr: any) => {
        /* istanbul ignore next */
        targetEntity?.nslAttributes?.forEach((targetAttr: any) => {
          /* istanbul ignore next */
          if (sourceAttr?.id === targetAttr?.id) {
            this.appendMissingOptionsAtAttrLevel(sourceAttr, targetAttr);
          }
        });
      });
    }
  }

  appendMissingOptionsAtAttrLevel(sourceAttr: any, targetAttr: any) {
    /* istanbul ignore next */
    if (sourceAttr?.options?.length) {
      targetAttr.options = sourceAttr.options;
    }
    /* istanbul ignore next */
    if (sourceAttr?.generalEntity && targetAttr?.generalEntity) {
      this.appendMissingOptionsAtEntityLevel(sourceAttr.generalEntity, targetAttr.generalEntity);
    }
  }

  getResumeChangeUnitByGSIId(postData: object) {
    this.loader.show();
    /* istanbul ignore next */
    this.transactionEndpointService
      .getResumeChangeUnitByGSIId(postData)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.loader.hide();
        this.listChangeUnitSubject.next(res);
        this.getReservedSearchResult(res);
        /* istanbul ignore next */
        if (this.eventCompletedData) {
          this.loader.show();
          /* istanbul ignore next */
          setTimeout(() => {
            /* istanbul ignore next */
            let layerIndex = res?.result?.currentCU?.layers?.findIndex((layer: any) => layer?.type === 'physical');
            /* istanbul ignore next */
            let entityDetails = res?.result?.currentCU?.layers?.[layerIndex]?.entityList?.find(
              (entity: any) =>
                entity?.id === this.eventCompletedData?.result?.currentCU?.layers?.[0]?.entityList?.[0]?.id
            );
            /* istanbul ignore next */
            if (entityDetails) {
              /* istanbul ignore next */
              entityDetails.entityName = entityDetails?.name;
              // nested required for nested entity
              /* istanbul ignore next */
              this.eventsFacadeService?.cuEvents.next({
                res: this.eventCompletedData,
                entityDetails: entityDetails,
              });
            }
            this.eventCompletedData = null;
            this.loader.hide();
          }, 3000);
        }
      });
  }

  getPendingTransactions(pageIndex: any, pageSize: any, searchText: any) {
    this.transactionEndpointService
      .getPendingTransactions(pageIndex, pageSize, searchText)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.pendingTransactions.next(res);
      });
  }
  getCreatedTxn(pageIndex: any, pageSize: any, searchText: any, originatingBookId?: any) {
    this.transactionEndpointService
      .getCreatedTxn(pageIndex, pageSize, searchText, originatingBookId)
      .subscribe((res: any) => {
        this.createdTransactions.next(res);
      });
  }
  assignedTransactionsNext(res: any) {
    this.assignedTransactions.next(res);
  }
  getAssignedTxn(pageIndex: any, pageSize: any, searchText: any, originatingBookId?: any) {
    this.transactionEndpointService
      .getAssignedTxn(pageIndex, pageSize, searchText, originatingBookId)
      .subscribe((res: any) => {
        this.assignedTransactions.next(res);
      });
  }
  escalatedTransactionsNext(res: any) {
    this.escalatedTransactions.next(res);
  }
  getEscalatedTxn(pageIndex: any, pageSize: any, searchText: any, originatingBookId?: any) {
    this.transactionEndpointService
      .getEscalatedTxn(pageIndex, pageSize, searchText, originatingBookId)
      .subscribe((res: any) => {
        this.escalatedTransactions.next(res);
      });
  }

  resumeTransaction(data: any) {
    this.resumeTransactionSubject.next(data);
  }

  getTransactioHistoryCuList(transactionId: string, gsiId: string) {
    this.transactionEndpointService
      .getTransactionHistoryCuList(transactionId, gsiId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.transactionHistoryCuList.next(res);
      });
  }

  getTransactioHistoryEmbeddedCuList(transactionId: string, gsiId: string) {
    this.transactionEndpointService
      .getTransactionHistoryEmbeddedCuList(transactionId, gsiId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.transactionHistoryEmbeddedCuList.next(res);
      });
  }

  transactionHistoryEntityListNext(res: any) {
    this.transactionHistoryEntityList.next(res);
  }
  getCuTransactionHistory(transactionId: string, cuContextualId: string) {
    this.transactionEndpointService
      .getCuTransactionHistory(transactionId, cuContextualId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.transactionHistoryEntityList.next(res);
      });
  }

  getEmbeddedCuTransactionHistory(transactionId: string, cuContextualId: string) {
    this.transactionEndpointService
      .getCuEmbeddedTransactionHistory(transactionId, cuContextualId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.transactionHistoryEmbeddedEntityList.next(res);
      });
  }

  ngOnDestroy() {
    this.pendingTransaction.next('');
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  reservedActionsListNext(res: any) {
    this.reservedActionsList.next(res);
  }
  ///reserved actions get call in transaction time ///
  getReservedActionsList(cuid: any) {
    this.transactionEndpointService
      .getReservedActionsList(cuid)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.reservedActionsList.next(res);
      });
  }
  advanceSearchSubjectNext(res: any) {
    this.advanceSearchSubject.next(res);
  }
  // advanced search in pending txn
  advancedSearchTxn(postData: object) {
    this.loader.show();
    this.transactionEndpointService
      .advancedSearchTxn(postData)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        this.loader.hide();
        this.advanceSearchSubject.next(res);
      });
  }
  allTransactionsNext(res: any) {
    this.allTransactions.next(res);
  }
  // getAllTransactions(pageIndex: any, pageSize: any, searchText: any) {
  //   this.transactionEndpointService.getAllTransactions(pageIndex, pageSize, searchText).subscribe((res: any) => {
  //     this.allTransactions.next(res);
  //   });
  // }
  txnStatusChange(status: any, txnId: any, payload?: any) {
    this.transactionEndpointService
      .txnStatusChange(status, txnId, payload)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.getPendingTransactions(0, 10, '');
        this.alertService.showToaster(res.message, '', 'success');
      });
  }
  filteredTransactionsNext(res: any) {
    this.filteredTransactions.next(res);
  }
  getFilteredTransactions(body: any) {
    this.loader.show();
    this.transactionEndpointService
      .getFilteredTransactions(body)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.loader.hide();
        this.filteredTransactions.next(res);
      });
  }

  /**
   * get transaction ads
   * @param gsiData
   */
  getTransactionAds(gsiData: any) {
    this.transactionEndpointService
      .getTransactionAds(gsiData)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.transactionAds.next(res);
      });
  }

  /**
   * make Impression API call
   * @param impressionData
   */
  makeImpression(impressionData: any) {
    this.transactionEndpointService.makeImpression(impressionData).subscribe();
  }

  /**
   * make click Ad API call
   * @param clickedData
   */
  adClick(clickedData: any) {
    this.transactionEndpointService.adClick(clickedData).subscribe();
  }

  //api call to get reserved actions hostory
  getReservedActionHistory(transactionId: string, cuContextualId: string) {
    return this.transactionEndpointService.getReservedActionsHistory(transactionId, cuContextualId);
  }
  /**
   * to get location details based on IP
   */
  geolocation() {
    this.transactionEndpointService.getLocation().subscribe((res: any) => {
      this.locationDetails.next(res);
    });
  }
  //Subtransactional Entity
  getSubtransactionalEntity(cu: object) {
    this.loader.show();
    const cuInfo = transactionChangeUnitDeMapper(cu);
    this.transactionEndpointService
      .getSubtransactionalEntity(cuInfo)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.loader.hide();
        /* istanbul ignore next */
        const cuRes = transactionChangeUnitMapper(res?.result);
        this.SubtransactionalEntityList.next(cuRes);
      });
  }

  openPendingTransactionModal(txnData: any) {
    this.pendingTransaction.next(txnData);
  }

  triggerWaitForTransaction(transactionData: any) {
    this.waitForTransaction.next(transactionData);
  }
  googleMapSubject = new Subject();
  googleMapSubject$ = this.googleMapSubject.asObservable();

  getLocation(latitude: any, longitude: any) {
    this.transactionEndpointService
      .getGmapLocation(latitude, longitude)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.googleMapSubject.next(res);
      });
  }

  // Pushback transaction API - FE757
  saveTransPushbackData(data: any) {
    this.loader.show();
    this.transactionEndpointService
      .postTransPushbackData(data)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.loader.hide();
        this.listChangeUnitSubject.next(res);
      });
  }
  /*gets all scheduled jobs list */
  getJobdetails(pageIndex: any, pageSize: any, searchText: any) {
    this.loader.show();
    this.transactionEndpointService.getJobdetails(pageIndex, pageSize, searchText).subscribe((res: any) => {
      this.loader.hide();
      this.scheduledJobs.next(res);
    });
  }
  /*gets list of all triggers based on gsiname and gsiId */
  getJobtriggers(gsiId: number, gsiName: any, pageIndex: any, pageSize: any) {
    this.loader.show();
    this.transactionEndpointService.getJobtriggers(gsiId, gsiName, pageIndex, pageSize).subscribe((res: any) => {
      this.loader.hide();
      this.jobTriggers.next(res);
    });
  }

  /*gets list of all triggers based on gsiname and gsiId */
  gsiExecutions(pageIndex: any, pageSize: any, data: any) {
    this.loader.show();
    this.transactionEndpointService.gsiExecutions(pageIndex, pageSize, data).subscribe((res: any) => {
      this.loader.hide();
      this.jobExecutions.next(res);
    });
  }

  /*gets list of all paginated triggers */
  getAlltriggers(pageIndex: any, pageSize: any) {
    this.loader.show();
    this.transactionEndpointService.getAlltriggers(pageIndex, pageSize).subscribe((res: any) => {
      this.loader.hide();
      this.allTriggers.next(res);
    });
  }

  /*unschedules a job */
  getUnscheduleJobs(data: object) {
    this.loader.show();
    this.transactionEndpointService
      .getUnscheduleJobs(data)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.loader.hide();
        this.unscheduleJobs.next(res);
      });
  }
  /*unschedules a trigger */
  getUnscheduleTriggers(data: object) {
    this.loader.show();
    this.transactionEndpointService
      .getUnscheduleTriggers(data)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.loader.hide();
        this.unscheduleTriggers.next(res);
      });
  }

  /*pauses trigger */
  pauseTrigger(data: object) {
    this.loader.show();
    this.transactionEndpointService
      .pauseTrigger(data)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.loader.hide();
        this.pauseTriggerSubject.next(res);
      });
  }

  // Store Event gsi data
  setGsiDraftData(data: any) {
    this.eventGsiData = data;
    this.eventGsiDataSubject.next(data);
  }

  // Reset resume transaction data
  resetResumeTransactionData() {
    this.stopResumeTransactionSubject.next(true);
  }

  // Update pending gsi array
  updatePendingGsiArray(data: any) {
    this.pendingGsiArraySubject.next(data);
  }

  // Update transaction id
  updateTransactionId(data: any) {
    this.updateTransactionIdData.next(data);
  }
  /*resume trigger */
  resumeTrigger(data: object) {
    this.loader.show();
    this.transactionEndpointService
      .resumeTrigger(data)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.loader.hide();
        this.resumeTriggerSubject.next(res);
      });
  }

  savePrevCUs(data: any) {
    this.previousCUlist.next(data);
  }

  /*pauses job */
  pauseJob(data: object) {
    this.loader.show();
    this.transactionEndpointService
      .pauseJob(data)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.loader.hide();
        this.pauseJobSubject.next(res);
      });
  }
  /* resumes job */
  resumeJob(data: object) {
    this.loader.show();
    this.transactionEndpointService
      .resumeJob(data)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.loader.hide();
        this.resumeJobSubject.next(res);
      });
  }
  /**

   * Sets attribute data for events

   * @param transactionData

   */

  setAttributeDataForEvents(transactionData: any) {
    this.eventAttributeData = transactionData;
  }

  /**

   * Gets attribute data for events

   * @returns

   */

  getAttributeDataForEvents() {
    return this.eventAttributeData;
  }
  debugData(payload: any) {
    /* istanbul ignore next */
    this.transactionEndpointService
      .debugData(payload)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.debug.next(res);
      });
  }
  debugValue(payload: any) {
    /* istanbul ignore next */
    this.transactionEndpointService
      .debugValue(payload)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.debugValues.next(res);
      });
  }

  saveTransPushbackCommentData(transId: any, data: any) {
    this.loader.show();
    this.transactionEndpointService
      .postTransPushbackCommentData(transId, data)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        this.loader.hide();
        /* istanbul ignore next */
        if (res?.result && res?.result?.length > 0) {
          this.getAllComments(res.result);
        }
      });
  }

  getAllComments(data: any) {
    this.listOfAllComments.next(data);
  }
  goToPendingTransaction(txndata: any) {
    this.milestoneToPendingTxn.next(txndata);
  }

  onSubmitting(data: any) {
    this.submitButtonEmit.next(data);
  }

  submitButtonDisabling(data: any) {
    this.submitButtonDisabled.next(data);
  }

  getTriggerCu(id: any) {
    this.changeUnitFacadeService.fetchTriggerCu(id);
  }
}

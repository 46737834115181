import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { SelfOnboardingSignupComponent } from './self-onboarding-signup/self-onboarding-signup.component';
import { OtpValidationComponent } from './otp-validation/otp-validation.component';
import { ForgotCustomerIdComponent } from './forgot-customerid/forgot-customerid.component';

const routes: Routes = [
  { path: 'forgotPass', component: ForgotpasswordComponent, data: { title: 'ForgotPassword' } },
  { path: 'forgotCustomerId', component: ForgotCustomerIdComponent, data: { title: 'forgotCustomerId' } },
  { path: 'selfOnboardSignUp', component: SelfOnboardingSignupComponent, data: { title: 'SelfonboardSignup' } },
  { path: 'fileotp', component: OtpValidationComponent, data: { title: 'fileotp' } },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class AuthRoutingModule {}

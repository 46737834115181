<div class="onboard-landing">
  <div class="container">
    <div class="row">
      <div class="col-md-12 justify-content-center">
        <div class="onboard-signup">
          <div class="row onboard-signup-inner">
            <div class="col-md-5">
              <ng-lottie [options]="lottieOptions" class="login-globe"></ng-lottie>
            </div>
            <div class="col-md-7">
              <div class="signup-form">
                <h3>Sign up to get started!</h3>

                <mat-tab-group class="signup-tab-list">
                  <mat-tab
                    *ngIf="signupOptions == 'SignupViaEmail' || signupOptions == 'SignupViaEmailAndMobile'"
                    label="Signup using Email"
                  >
                    <form #signupForm="ngForm" name="form">
                      <div *ngIf="showPublicRoles" class="form-group">
                        <mat-form-field appearance="fill">
                          <mat-label>Select Role</mat-label>
                          <mat-select
                            matNativeControl
                            [(ngModel)]="selectedRole"
                            name="selectedRole"
                            id="selectedRole"
                            [required]="isRoleMandatory && showPublicRoles"
                          >
                            <mat-option *ngFor="let role of rolesList" [value]="role">
                              {{ role?.name }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="form-group" *ngIf="customLabel && customIdGeneration != 'Auto'">
                        <mat-form-field>
                          <mat-label>{{ customLabel }}</mat-label>
                          <input
                            matInput
                            id="customLabel"
                            name="customLabel"
                            type="text"
                            placeholder="{{ customLabel }}"
                            [(ngModel)]="signupPayload.customID"
                            [required]="customLabel"
                          />
                        </mat-form-field>
                      </div>
                      <div class="form-row m-0">
                        <div class="form-group col-md-6 pl-0">
                          <mat-form-field>
                            <input
                              matInput
                              id="firstName"
                              type="text"
                              placeholder="First Name"
                              [(ngModel)]="signupPayload.firstName"
                              name="firstName"
                              required
                            />
                          </mat-form-field>
                        </div>
                        <div class="form-group col-md-6 pr-0">
                          <mat-form-field>
                            <input
                              matInput
                              id="lastName"
                              type="text"
                              placeholder="Last Name"
                              [(ngModel)]="signupPayload.lastName"
                              name="lastName"
                            />
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="form-row m-0">
                        <div class="form-group col-md-6 pl-0">
                          <mat-form-field>
                            <input
                              matInput
                              id="userName"
                              type="text"
                              placeholder="Email"
                              [(ngModel)]="signupPayload.email"
                              name="email"
                              (keyup)="ValidateEmail()"
                              required
                            />
                          </mat-form-field>
                          <div>
                            <small [ngClass]="{ 'myclass-2': emailValidator == false }" *ngIf="!emailValidator">
                              Enter a Valid Email
                            </small>
                          </div>
                        </div>
                        <div class="form-group col-md-6 pr-0">
                          <mat-form-field>
                            <input
                              matInput
                              id="mobile"
                              type="Number"
                              placeholder="Contact Number"
                              onkeydown="return((event.keyCode >=48 && event.keyCode <=57)||event.keyCode ==8)"
                              [(ngModel)]="signupPayload.mobileNumber"
                              (keyup)="ValidateMobileNumber()"
                              (keydown.space)="$event.preventDefault()"
                              name="mobileNumber"
                            />
                          </mat-form-field>
                          <div>
                            <small
                              [ngClass]="{ 'myclass-2': mobileNumberValidator == false }"
                              *ngIf="!mobileNumberValidator"
                            >
                              Must be 10 digits
                            </small>
                          </div>
                        </div>
                      </div>
                      <div class="form-row m-0">
                        <div class="form-group col-md-6 pl-0">
                          <mat-form-field>
                            <input
                              matInput
                              id="password"
                              type="password"
                              placeholder="Password"
                              [(ngModel)]="signupPayload.password"
                              required
                              name="password"
                            />
                          </mat-form-field>
                        </div>
                        <div class="form-group col-md-6 pr-0 passwordpolicy">
                          <mat-form-field>
                            <input
                              matInput
                              id="confirmPassword"
                              type="password"
                              placeholder="Confirm Password"
                              [(ngModel)]="confirmPassword"
                              (keyup)="ValidateConfirmPassword()"
                              required
                              name="confirmPassword"
                            />
                            <div class="cstm-tooltip" href="">
                              <div class="pasword-policy">
                                <span class="info-icon">
                                  <mat-icon matSuffix>info</mat-icon>
                                </span>
                                <span class="policyinfo" *ngIf="password_info">
                                  Password Policy(minimum requirements):<br />
                                  Digits: {{ password_info?.digits }}<br />
                                  forceExpiredPasswordChange:{{ password_info?.forceExpiredPasswordChange }}days<br />
                                  Length: {{ password_info?.length }}<br />
                                  notUsername: {{ password_info?.notUsername }}<br />
                                  specialChars: {{ password_info?.specialChars }}<br />
                                  upperCase: {{ password_info?.upperCase }}<br />
                                </span>
                                <span class="policyinfo" *ngIf="!password_info">
                                  Password Policy(No minimum requirements):<br />
                                </span>
                              </div>
                            </div>
                          </mat-form-field>
                          <div>
                            <small [ngClass]="{ 'myclass-2': passwordCheck == false }" *ngIf="!passwordCheck">
                              Password not matched
                            </small>
                            <small
                              [ngClass]="{ 'myclass-1': passwordCheck == true }"
                              *ngIf="passwordCheck && confirmPassword.length"
                            >
                              Password matched
                            </small>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <button class="btn btn-signup" id="signup" (click)="signup()" [disabled]="!signupForm.valid">
                          Signup
                        </button>
                      </div>
                    </form></mat-tab
                  >
                  <mat-tab
                    *ngIf="
                      !onboarding || signupOptions == 'SignupViaMobile' || signupOptions == 'SignupViaEmailAndMobile'
                    "
                    label="Signup using Mobile"
                  >
                    <div class="form" action="">
                      <div *ngIf="showPublicRoles" class="form-group">
                        <mat-form-field appearance="fill">
                          <mat-label>Select Role</mat-label>
                          <mat-select [(ngModel)]="selectedRole" [ngModelOptions]="{ standalone: true }">
                            <mat-option *ngFor="let role of rolesList" [value]="role">
                              {{ role?.name }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="form-group">
                        <mat-form-field>
                          <input
                            matInput
                            id="mobileNumber"
                            type="text"
                            placeholder="Mobile Number"
                            [(ngModel)]="signupMobile"
                            [ngModelOptions]="{ standalone: true }"
                            (keypress)="acceptInteger($event)"
                            (change)="activateOtp = false"
                          />
                        </mat-form-field>
                      </div>
                      <div class="form-group">
                        <button
                          class="btn btn-signup"
                          id="sendOtpButton"
                          (click)="sendOtp()"
                          [disabled]="timeLeft != 0 && activateOtp"
                        >
                          {{ activateOtp ? 'Resend OTP' : 'Send OTP' }}
                        </button>
                        <p *ngIf="timeLeft != 0 && activateOtp">Re-send OTP in {{ timeLeft }} seconds</p>
                      </div>
                      <div class="form-group" *ngIf="activateOtp">
                        <mat-form-field>
                          <input
                            matInput
                            id="otp"
                            type="text"
                            placeholder="OTP"
                            [(ngModel)]="signupOtp"
                            (keypress)="acceptInteger($event)"
                            [ngModelOptions]="{ standalone: true }"
                          />
                        </mat-form-field>
                      </div>
                      <div class="form-group" *ngIf="activateOtp">
                        <button class="btn btn-signup" id="verifyOtp" (click)="verifyOtp()">
                          Verify and Signup
                        </button>
                      </div>
                    </div>
                  </mat-tab>
                </mat-tab-group>

                <div class="signup-others">
                  <a (click)="forgotPassword()" href="" class="forgot-pwd">Forgot password?</a>
                  <p>
                    or signup via
                    <span>
                      <img [src]="'../../../assets/img/onboard/google-icon.png' | CDNUrlRewritePipe" alt="" />
                    </span>
                    <span>
                      <img [src]="'../../../assets/img/onboard/twitter-cion.png' | CDNUrlRewritePipe" alt="" />
                    </span>
                    <span>
                      <img [src]="'../../../assets/img/onboard/fb-icon.png' | CDNUrlRewritePipe" alt="" />
                    </span>
                  </p>
                  <h4>Already Have an account <a (click)="signin()">Sign In?</a></h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

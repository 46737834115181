let transform = require('node-json-transform').transform;

export function entityDeMapper(data: any) {
  console.log(data);
  var map = {
    /*
      node json mapper default item
      used to map one key to another
    */
    item: {
      dsdId: 'id',
      id: 'tfId',
      name: 'name',
      isMultiValue: 'isMultiValue',
      isReserved: 'isReserved',
      nslAttributes: 'nslAttributes',
      attachments: 'attachments',
      designTimeRights: 'designTimeRights',
      txnTimeRights: 'txnTimeRights',
      displayName: 'displayName',
      keywords: 'keywords',
      masterId: 'masterId',
      ontology: 'ontology',
      publisher: 'publisher',
      status: 'status',
      dsdStatus: 'dsdStatus',
      version: 'version',
      ownerId: 'ownerId',
      author: 'author',
      description: 'description',
      showAgents: 'showAgents',
      showAttributes: 'showAttributes',
      configuration: 'configuration',
      isNameUpdated: 'isNameUpdated',
      entityProperties: 'entityProperties',
      source: 'source',
      dsdMetadataId: 'dsdMetadataId',
      isArEntity: 'isArEntity',
      uiProperties: 'uiProperties',
    },
    operate: [
      {
        run: function (val: any) {
          return val ? val : null;
        },
        on: 'dsdId',
      },
      {
        run: function (val: any) {
          if (val?.length > 0) {
            /* istanbul ignore next */
            return val?.map((x: any) => {
              if (x.generalEntity) {
                return {
                  ...x,
                  dsdId: x.id,
                  id: x.tfId,
                  tfId: undefined,
                  /* istanbul ignore next */
                  nslAttributeMetaData: x.generalEntity?.nslAttributeMetaData,
                  /* istanbul ignore next */
                  conditionalPotentiality: x.generalEntity?.conditionalPotentiality,
                  /* istanbul ignore next */
                  conditionalPotentialityName: x.generalEntity?.conditionalPotentialityName,
                };
              } else {
                return {
                  ...x,
                  dsdId: x.id,
                  id: x.tfId,
                  tfId: undefined,
                  nslAttributeMetaData: x?.nslAttributeMetaData,
                };
              }
            });
          } else {
            return [];
          }
        },
        on: 'nslAttributes',
      },
    ],
  };
  return transform(data, map);
}

<div
  class="chat-wrapper"
  *ngIf="isShown"
  id="divshow"
  [ngClass]="{ 'chat-wrapper-carnival': chatBotIcons.Robot === iconType }"
>
  <div class="chat-contr chat-container">
    <div class="infowrapper">
      <div class="infor-left">
        <p>
          {{ botName }}
          <!-- <span> {{ labels?.Chatbotlabel }} </span> -->
        </p>
      </div>
      <div class="infor-right">
        <span class="material-icons" *ngIf="isAudio" (click)="enableAudio()">
          volume_up
        </span>

        <span class="material-icons" *ngIf="!isAudio" (click)="enableAudio()">
          volume_off
        </span>
        <span class="material-icons" (click)="isShown = false">
          remove
        </span>

        <span class="chatclose" (click)="endChat()"
          ><img [src]="'../../../../assets/img/chatbot/chat-close.png' | CDNUrlRewritePipe" alt=""
        /></span>
      </div>
    </div>
    <div class="chat-sys">
      <div class="message chating" #scroll [scrollTop]="scroll.scrollHeight">
        <ng-container *ngFor="let chat of updatedChatData?.messagesList; let i = index">
          <div class="chat-type" *ngIf="chat?.question">
            <div class="chatype-span">
              <span>{{ chat?.question }}</span>
            </div>
            <span class="boticon"
              ><img [src]="'../../../../assets/img/bubble1.png' | CDNUrlRewritePipe" width="30" alt="user"
            /></span>
          </div>
          <div class="cstmsend" *ngIf="chat?.answer && chat?.answer?.response_type === 'button'">
            <div class="w-100">
              <div class="select-suggestions suggestions-list" *ngIf="chat?.answer?.button">
                <h3>Select from the options</h3>
                <ul>
                  <li *ngFor="let button of chat?.answer?.button" (click)="sendButtonAction(button)">
                    <span> {{ button?.displayText }} </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="cstmsend" *ngIf="chat?.answer && chat?.answer?.response_type === 'suggestions'">
            <div class="w-100">
              <div class="select-suggestions suggestions-list" *ngIf="chat?.answer?.suggestions">
                <h3>Select from the topics</h3>
                <ul>
                  <li *ngFor="let button of chat?.answer?.button" (click)="sendButtonAction(button)">
                    <span> {{ button?.displayText }} </span>
                  </li>
                </ul>
                <p class="text-right">
                  <button
                    class="btn btn-more"
                    [ngClass]="{ active: moreTopicsButton }"
                    (click)="clickedTopics('more topics')"
                  >
                    More Topics
                  </button>
                </p>
                <p class="not-listed">
                  <button
                    class="btn btn-not"
                    [ngClass]="{ active: notListedButton }"
                    (click)="clickedTopics('not listed')"
                  >
                    Not Listed
                  </button>
                </p>
              </div>
            </div>
          </div>
          <div class="voice-input" *ngIf="chat?.voice">
            <audio controls="" [src]="chat?.voice | safeResourceUrl" type="audio/wav"></audio>
            <span class="boticon"
              ><img [src]="'../../../../assets/img/bubble1.png' | CDNUrlRewritePipe" width="30" alt="user"
            /></span>
          </div>
          <ng-container *ngIf="chat?.answer && chat?.answer?.response_type === 'text'">
            <ng-container *ngIf="chat?.answer?.url">
              <ng-container [ngSwitch]="chat?.answer?.url?.type">
                <ng-container *ngSwitchCase="'video_conference'">
                  <div class="chat-replay">
                    <span class="usricon">
                      <img
                        [src]="'../../../../assets/img/chat-kia-circle.svg' | CDNUrlRewritePipe"
                        alt="kia"
                        width="10"
                      />
                    </span>
                    <span class="d-block" *ngIf="chat?.answer?.url?.type === 'video_conference'">
                      <button class="btn send" (click)="makeCall((chat?.answer?.url?.url)[0])">Join the call</button>
                    </span>
                  </div>
                </ng-container>
                <ng-container *ngSwitchDefault>
                  <div class="chat-replay">
                    <span class="usricon" *ngIf="chat?.answer?.user === 'yantr'">
                      <img
                        [src]="'../../../../assets/img/chat-kia-circle.svg' | CDNUrlRewritePipe"
                        alt="kia"
                        width="10"
                      />
                    </span>
                    <span *ngIf="chat?.answer?.user !== 'yantr'" class="usricon">
                      <img
                        [src]="'../../../../assets/img/chatbot/nh_logo.svg' | CDNUrlRewritePipe"
                        width="30"
                        alt="user"
                      />
                    </span>
                    <div class="w-100">
                      <div class="suggestionh2">
                        <h2 class="formatted" [innerHTML]="chat?.answer?.ans?.text"></h2>
                        <h2 class="formatted d-none">{{ chat?.answer?.ans?.text }}</h2>
                        <div
                          class="select-suggestion-wrap"
                          *ngIf="
                            chat?.answer &&
                            chat?.answer?.response_type === 'text' &&
                            chat?.answer?.media?.image?.length > 0
                          "
                        >
                          <div class="select-suggestions suggestion-img">
                            <ul>
                              <li *ngFor="let med of chat?.answer?.media?.image">
                                <figure (click)="openMediaDialog(mediaTypes.Image, med)">
                                  <img [src]="med" alt="chat info" />
                                  <!-- <figcaption>Filename.jpg</figcaption> -->
                                </figure>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div
                          class="select-suggestion-wrap"
                          *ngIf="
                            chat?.answer &&
                            chat?.answer?.response_type === 'text' &&
                            chat?.answer?.media?.video?.length > 0
                          "
                        >
                          <div class="select-suggestions suggestion-img">
                            <ul>
                              <li *ngFor="let med of chat?.answer?.media?.video">
                                <div class="chat-video">
                                  <video #videoPlayerMini controls>
                                    <source [src]="med" type="video/mp4" />
                                  </video>
                                  <mat-icon
                                    class="material-icons-outlined"
                                    (click)="openMediaDialog(mediaTypes.Video, med)"
                                  >
                                    play_circle_outline
                                  </mat-icon>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div
                          class="select-suggestion-wrap"
                          *ngIf="
                            chat?.answer &&
                            chat?.answer?.response_type === 'text' &&
                            chat?.answer?.media?.audio?.length > 0
                          "
                        >
                          <div class="select-suggestions suggestion-img">
                            <ul>
                              <li *ngFor="let med of chat?.answer?.media?.audio">
                                <div class="chat-audio">
                                  <audio controls="">
                                    <source [src]="med" type="audio/wav" />
                                  </audio>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="!chat?.answer?.url">
              <div class="chat-replay">
                <span class="usricon" *ngIf="chat?.answer?.user === 'yantr'">
                  <img [src]="'../../../../assets/img/chat-kia-circle.svg' | CDNUrlRewritePipe" alt="kia" width="10" />
                </span>
                <span *ngIf="chat?.answer?.user !== 'yantr'" class="usricon">
                  <img [src]="'../../../../assets/img/chatbot/nh_logo.svg' | CDNUrlRewritePipe" width="30" alt="user" />
                </span>
                <div class="w-100">
                  <div class="suggestionh2">
                    <h2 class="formatted d-none" [innerHTML]="chat?.answer?.ans?.text"></h2>
                    <h2 class="formatted">{{ chat?.answer?.ans?.text }}</h2>
                    <div
                      class="select-suggestion-wrap"
                      *ngIf="
                        chat?.answer &&
                        chat?.answer?.response_type === 'text' &&
                        chat?.answer?.media?.image &&
                        chat?.answer?.media?.image.length > 0
                      "
                    >
                      <div class="select-suggestions suggestion-img">
                        <ul>
                          <li *ngFor="let med of chat?.answer?.media?.image">
                            <figure (click)="openMediaDialog(mediaTypes.Image, med)">
                              <img [src]="med" alt="chat info" />
                              <!-- <figcaption>Filename.jpg</figcaption> -->
                            </figure>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div
                      class="select-suggestion-wrap"
                      *ngIf="chat?.answer && chat?.answer?.response_type === 'text' && chat?.answer?.media?.video"
                    >
                      <div class="select-suggestions suggestion-img">
                        <ul>
                          <li *ngFor="let med of chat?.answer?.media?.video">
                            <div class="chat-video">
                              <video #videoPlayerMini controls>
                                <source [src]="med" type="video/mp4" />
                              </video>
                              <mat-icon
                                class="material-icons-outlined"
                                (click)="openMediaDialog(mediaTypes.Video, med)"
                              >
                                play_circle_outline
                              </mat-icon>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div
                      class="select-suggestion-wrap"
                      *ngIf="chat?.answer && chat?.answer?.response_type === 'text' && chat?.answer?.media?.audio"
                    >
                      <div class="select-suggestions suggestion-img">
                        <ul>
                          <li *ngFor="let med of chat?.answer?.media?.audio">
                            <div class="chat-audio">
                              <audio controls="">
                                <source [src]="med" type="audio/wav" />
                              </audio>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="emoj-btns" *ngIf="chat?.answer?.isFaqOrAtq">
                <ul>
                  <li (click)="chat.answer.isFaqOrAtq = false">
                    <figure>
                      <img src="../../../../assets/img/chatbot/emoj01.png" alt="" />
                    </figure>
                    <span>Thanks, this is helpful</span>
                  </li>
                  <li
                    (click)="
                      chat.answer.isFaqOrAtq = false; question = 'No, this is NOT helpful'; sendQuestions(scroll)
                    "
                  >
                    <figure>
                      <img src="../../../../assets/img/chatbot/emoj02.png" alt="" />
                    </figure>
                    <span>No, this is NOT helpful</span>
                  </li>
                </ul>
              </div>
            </ng-container>
          </ng-container>
          <div class="chat-type" *ngIf="chat?.file">
            <div class="chatype-span w-100">
              <ng-container>
                <span class="filespan">
                  <p>{{ this.chat?.file?.fileName }}&nbsp;&nbsp;</p>
                  <a><mat-icon (click)="filedownload(this.chat?.file?.filePath)">get_app</mat-icon></a></span
                >
              </ng-container>
            </div>
            <span class="boticon"
              ><img [src]="'../../../../assets/img/bubble1.png' | CDNUrlRewritePipe" width="30" alt="user"
            /></span>
          </div>
        </ng-container>
        <div #target class="chatscript-close">
          <div class="prompt" *ngIf="openPrompt">
            Do you want a transcript sent to your mail?
            <br />
            <div class="cstmsend">
              <button class="btn send" (click)="sendTranscript(true)">Yes</button>
              <button class="btn close" (click)="sendTranscript(false)">No</button>
            </div>
          </div>
        </div>
        <div class="comments-box" *ngIf="toggleFeedback">
          <div class="chat-rating">
            <p>{{ labels?.Chat_Exprience }}</p>
            <div class="rating-stars">
              <mat-icon
                [ngClass]="{ 'rating-active': feedback?.rating >= 1 }"
                class="material-icons-outlined star"
                (click)="ratingAtSessionLevel(1)"
                >star_outline
              </mat-icon>
              <mat-icon
                [ngClass]="{ 'rating-active': feedback?.rating >= 2 }"
                class="material-icons-outlined star"
                (click)="ratingAtSessionLevel(2)"
                >star_outline</mat-icon
              >
              <mat-icon
                [ngClass]="{ 'rating-active': feedback?.rating >= 3 }"
                class="material-icons-outlined star"
                (click)="ratingAtSessionLevel(3)"
                >star_outline</mat-icon
              >
              <mat-icon
                [ngClass]="{ 'rating-active': feedback?.rating >= 4 }"
                class="material-icons-outlined star"
                (click)="ratingAtSessionLevel(4)"
                >star_outline</mat-icon
              >
              <mat-icon
                [ngClass]="{ 'rating-active': feedback?.rating === 5 }"
                class="material-icons-outlined star"
                (click)="ratingAtSessionLevel(5)"
                >star_outline</mat-icon
              >
            </div>
          </div>

          <div class="comments-info">
            <p>{{ labels?.Chat_Comments }}</p>
            <textarea rows="4" [(ngModel)]="comments"></textarea>
            <div class="cstmsend">
              <button class="btn send" (click)="sendFeedback(true)">{{ labels?.Send }}</button>
              <button class="btn comment-btn" (click)="sendFeedback(false)">
                {{ labels?.Chatbot_Nocomments }}
              </button>
            </div>
          </div>
        </div>
        <div class="emoj-btns" *ngIf="false">
          <ul>
            <li (click)="emojiFeedbackFlag = false">
              <figure>
                <img [src]="'../../../../assets/img/chatbot/emoj01.png' | CDNUrlRewritePipe" alt="" />
              </figure>
              <span>Thanks, this is helpful</span>
            </li>
            <li (click)="emojiFeedbackFlag = false; question = 'No, this is NOT helpful'; sendQuestions(scroll)">
              <figure>
                <img [src]="'../../../../assets/img/chatbot/emoj02.png' | CDNUrlRewritePipe" alt="" />
              </figure>
              <span>No, this is NOT helpful</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="chat-user">
      <emoji-mart
        class="emoji-mart"
        *ngIf="emojiePickerFlag"
        (emojiSelect)="addEmoji($event)"
        title="Choose your emoji"
      ></emoji-mart>
      <form class="send-message-form">
        <input
          class="chat-user-text"
          type="text"
          name="question"
          [(ngModel)]="question"
          (keydown.enter)="sendQuestions(scroll)"
          placeholder="Your message"
        />
      </form>
      <div class="upload">
        <div class="chat-send">
          <div>
            <figure>
              <img
                [src]="'../../../../assets/img/chatbot/chat-send.png' | CDNUrlRewritePipe"
                alt=""
                (click)="sendQuestions(scroll)"
              />
            </figure>
          </div>
        </div>
        <div class="chat-voice">
          <mat-icon class="material-icons-outlinede" (click)="record()">mic_none</mat-icon>
        </div>
        <div class="file-upload">
          <div class="custom-file">
            <input type="file" class="custom-file-input" (change)="fileUpload($event.target.files)" />
            <label class="custom-file-label">
              <img [src]="'../../../../assets/img/chatbot/attachment.svg' | CDNUrlRewritePipe" alt="" />
            </label>
          </div>
        </div>
        <div class="emojies">
          <figure (click)="emojiePickerFlag = !emojiePickerFlag">
            <img [src]="'../../../../assets/img/chatbot/emoj01.png' | CDNUrlRewritePipe" alt="" />
          </figure>
        </div>
      </div>
    </div>
    <!-- <div class="cstmsend">
      <button class="btn close" (click)="toggleShow()">{{ labels?.Close }}</button>
      <button class="btn send" [disabled]="!question" (click)="sendQuestions(target)">{{ labels?.Send }}</button>
    </div> -->
  </div>
</div>

<div
  class="chat-click-icon"
  (click)="toggleShow()"
  [ngClass]="{ 'chat-click-icon-carnival': chatBotIcons.Robot === iconType }"
>
  <div class="hovertext">
    <div class="bot-relative">
      <figure *ngIf="chatBotIcons.Messenger === iconType && !isOnboarding">
        <!-- <img [src]="'../../../../assets/images/kiaSmall.png' | CDNUrlRewritePipe" alt="kia" /> -->
        <img src="../../../../assets/images/kiaSmall.png" />
      </figure>
      <figure *ngIf="chatBotIcons.Robot === iconType && !isOnboarding">
        <!-- <img [src]="'../../../../assets/images/kiaSmall.png' | CDNUrlRewritePipe" alt="kia" /> -->
        <img src="../../../../assets/images/kiaSmall.png" />
      </figure>
      <!-- for onboarding -->
      <figure *ngIf="isOnboarding">
        <img src="../../../../assets/img/onboarding/icon-chat.png" />
      </figure>
      <div class="chatcount" *ngIf="unreadMessages > 0">
        <span>{{ unreadMessages }}</span>
      </div>
    </div>
  </div>
</div>

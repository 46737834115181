let transform = require('node-json-transform').transform;

export function attributeDeMapper(data: any) {
  console.log(data);
  var map = {
    item: {
      id: 'dsdId',
      designTimeRights: 'designTimeRights',
      txnTimeRights: 'txnTimeRights',
      status: 'status',
      dsdStatus: 'dsdStatus',
      displayName: 'displayName',
      ontology: 'ontology',
      attachments: 'attachments',
      keywords: 'keywords',
      publisher: 'publisher',
      masterId: 'masterId',
      version: 'version',
      author: 'author',
      description: 'description',
      ownerId: 'ownerId',
      finishType: 'finishType',
      configuration: 'configuration',
      changeDriverMetaData: 'changeDriverMetaData',
      isNameUpdated: 'isNameUpdated',
      entityProperties: 'entityProperties',
      name: 'name',
      attributeClassification: 'attributeClassification',
      attributeType: 'attributeType',
      constraints: 'constraints',
      isReserved: 'isReserved',
      nslAttributeProperties: 'nslAttributeProperties',
    },
    operate: [
      {
        run: function (val: any) {
          /* istanbul ignore next */
          return val ? val : null;
        },
        on: 'dsdId',
      },
    ],
  };
  return transform(data, map);
}

<div>
  <mat-icon style="cursor: pointer;" (click)="downloadFile()" *ngIf="!isHtmlType() && canDownload"
    >file_download</mat-icon
  >
  <mat-icon class="close-btn-modal" style="cursor: pointer;" (click)="closePopup()">close</mat-icon><br />
  <div class="tbl-width file-upload-table" *ngIf="isHtmlType()" [innerHTML]="src.contentUrl"></div>
  <iframe [src]="src.contentUrl | safeResourceUrl" *ngIf="isFrameSupported()" title=""></iframe>
  <div class="pdf-viewer" *ngIf="isPdfType()">
    <iframe [src]="src.contentUrl | safeResourceUrl" title=""></iframe>
  </div>
  <div *ngIf="isSheet" class="table-preview">
    <table>
      <tbody>
        <tr *ngFor="let row of sheetData">
          <td *ngFor="let val of row">
            {{ val }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="audio-video-cls">
    <audio controls *ngIf="isAudioType()">
      <source [src]="src.contentUrl | safeResourceUrl" />
    </audio>
    <img class="file-upload-img" [src]="src.contentUrl | safeResourceUrl" *ngIf="isImageType()" alt="" />
    <video controls *ngIf="isVideoType()">
      <source [src]="src.contentUrl | safeResourceUrl" />
      <track label="English" kind="captions" srclang="en" src="" default />
      Your browser does not support HTML video.
    </video>
  </div>
</div>

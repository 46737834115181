import { Injectable, OnDestroy } from '@angular/core';
import { entityMapper } from '@app/@core/mappers/entity/entity-mapper';
import { gsiMapper } from '@app/@core/mappers/gsi/gsi-mapper';
import { bookListMapper } from '@app/@core/mappers/list/books';
import { AlertService } from '@app/@core/toaster/alert.service';
import { Librarytypes } from '@app/@shared/enums/library-types.enum';
import { LoaderService } from '@app/@shared/services/loader/loader.service';
import { OrganisationUnitsFacadeService } from '@app/admin/services/organisation-units/organisation-units-facade.service';
import { RolesFacadeService } from '@app/admin/services/roles/roles-facade.service';
import { BehaviorSubject, forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AttributeEndpointService } from '../attribute/attribute-endpoint.service';
import { AttributeFacadeService } from '../attribute/attribute-facade.service';
import { ChangeUnitEndpointService } from '../change-unit/change-unit-endpoint.service';
import { ChangeUnitFacadeService } from '../change-unit/change-unit-facade.service';
import { EntityEndpointService } from '../entity/entity-endpoint.service';
import { EntityFacadeService } from '../entity/entity-facade.service';
import { GsiFacadeService } from '../gsi/gsi-facade.service';
@Injectable({
  providedIn: 'root',
})
export class LibraryFacadeService implements OnDestroy {
  dataTyepList: any = [];
  existConfigData: any = [];

  public dataTypes = new BehaviorSubject(this.dataTyepList);
  dataTypes$ = this.dataTypes.asObservable();

  public importdataTypes = new BehaviorSubject('');
  importDataTypes$ = this.importdataTypes.asObservable();

  public entityAttrIndex = new Subject();
  entityAttrIndex$ = this.entityAttrIndex.asObservable();

  public entitiesSubject = new Subject();
  entities$ = this.entitiesSubject.asObservable();

  public gsiSubject = new Subject();
  gsis$ = this.gsiSubject.asObservable();

  public watchAttributeChange = new Subject();
  watchAttributeChange$ = this.watchAttributeChange.asObservable();

  public watchEntityConfigure = new Subject();
  watchEntityConfigure$ = this.watchEntityConfigure.asObservable();

  public closeLibrary = new Subject();
  closeLibrary$ = this.closeLibrary.asObservable();

  roleObject: any = [];
  public rolesSubject = new BehaviorSubject(this.roleObject);
  roles$ = this.rolesSubject.asObservable();

  public entitySource = new Subject();
  currentEntity = this.entitySource.asObservable();

  public eqlEntitySource = new Subject();
  eqlEntity = this.eqlEntitySource.asObservable();

  public cuSource = new BehaviorSubject('');
  currentCu = this.cuSource.asObservable();

  public roleSource = new Subject();
  currentRole = this.roleSource.asObservable();

  public userSource = new Subject();
  currentUser = this.userSource.asObservable();

  enteditSource = new BehaviorSubject('');
  editEntity = this.enteditSource.asObservable();

  public currentcomp = new BehaviorSubject(Librarytypes.GSI);
  currentcontainer = this.currentcomp.asObservable();

  public openSource = new BehaviorSubject('');
  openCu$ = this.openSource.asObservable();

  public selectRoleSource = new BehaviorSubject([]);
  selectRole = this.selectRoleSource.asObservable();

  public gsiSource = new BehaviorSubject('');
  openGsipage = this.gsiSource.asObservable();

  public openAgentSource = new Subject();
  openAgent = this.openAgentSource.asObservable();

  openEntitySource = new Subject();
  openEntity = this.openEntitySource.asObservable();

  public editGsi = new Subject();
  editGSI$ = this.editGsi.asObservable();

  public editGsiForVersion = new Subject();
  editGsiForVersion$ = this.editGsiForVersion.asObservable();

  public dldGsi = new Subject();
  dldGsi$ = this.dldGsi.asObservable();

  public leftSidebar = new Subject();
  leftSidebar$ = this.leftSidebar.asObservable();

  // public nestedGsi = new Subject();
  // nestedGSI$ = this.nestedGsi.asObservable();

  public exceptionGsi = new Subject();
  exceptionGSI$ = this.exceptionGsi.asObservable();

  public cuEditSource = new BehaviorSubject('');
  editCUId$ = this.cuEditSource.asObservable();

  public bookSource = new Subject();
  books$ = this.bookSource.asObservable();

  public attrEntity = new Subject();
  attrEntty$ = this.attrEntity.asObservable();

  public attrEntityForEQL = new Subject();
  attrEnttyForEQL$ = this.attrEntityForEQL.asObservable();

  public attrEntityForEQLReport = new Subject();
  attrEntityForEQLReport$ = this.attrEntityForEQLReport.asObservable();

  attrData = new Subject();
  attrData$ = this.attrData.asObservable();

  public dcdProperties = new Subject();
  dcdProperties$ = this.dcdProperties.asObservable();

  public saveEnt = new BehaviorSubject('');
  saveEntFlag = this.saveEnt.asObservable();

  // alternate CU
  public currentCUType = new BehaviorSubject('');
  currentCUType$ = this.currentCUType.asObservable();

  // add alternate CU
  alternateCUAdd = new Subject();
  alternateCUAdd$ = this.alternateCUAdd.asObservable();

  public addSequentialCU = new Subject();
  addSequentialCU$ = this.addSequentialCU.asObservable();
  // selected Current Alternate Cu to Show Entities and Attributes
  // public currentAlternateCU = new Subject();
  // currentAlternateCU$ = this.currentAlternateCU.asObservable();

  public currentAttributeForAlternateCU = new Subject();
  currentAttributeForAlternateCU$ = this.currentAttributeForAlternateCU.asObservable();

  public currentAttributeForCuPotentiality = new Subject();
  currentAttributeForCuPotentiality$ = this.currentAttributeForCuPotentiality.asObservable();

  public attributeData = new Subject();
  attributeData$ = this.attributeData.asObservable();

  public entityConfigurationData = new Subject();
  entityConfigurationData$ = this.entityConfigurationData.asObservable();
  // Solution Drafting

  private clearallfiles = new BehaviorSubject(false);
  clearallfiles$ = this.clearallfiles.asObservable();

  private solDraft = new Subject();
  solDrafting = this.solDraft.asObservable();
  public addReservedCUSelected = new BehaviorSubject({});
  addReservedCUSelected$ = this.addReservedCUSelected.asObservable();

  public createNew = new Subject();
  createNew$ = this.createNew.asObservable();

  public bookSearch = new BehaviorSubject(false);
  booksSearch$ = this.bookSearch.asObservable();

  public pendingTransaction = new BehaviorSubject('');
  pendingTransaction$ = this.pendingTransaction.asObservable();

  ImportSolution = new BehaviorSubject('');
  ImportSolution$ = this.ImportSolution.asObservable();

  public ImportExportSolution = new BehaviorSubject('');
  ExportSolution$ = this.ImportExportSolution.asObservable();

  public attributeTypes = new Subject();
  attributeTypes$ = this.attributeTypes.asObservable();

  public currentLibraryType = new BehaviorSubject('');
  currentLibraryType$ = this.currentLibraryType.asObservable();

  public currentAgentLevelType = new Subject();
  currentAgentLevelType$ = this.currentAgentLevelType.asObservable();

  public ngUnsubcribe = new Subject();

  public libraryData = new Subject();
  libraryData$ = this.libraryData.asObservable();

  public libraryDataCount = new Subject();
  libraryDataCount$ = this.libraryDataCount.asObservable();

  public cuListDetailsWithLayersInLibrary = new Subject();
  cuListDetailsWithLayersInLibrary$ = this.cuListDetailsWithLayersInLibrary.asObservable();

  public showNestedLibrary = new Subject();
  showNestedLibrary$ = this.showNestedLibrary.asObservable();

  public gsiRecursiveLibrary = new Subject();
  gsiRecursiveLibrary$ = this.gsiRecursiveLibrary.asObservable();

  layersCurrentAttr = new Subject();
  layersCurrentAttr$ = this.layersCurrentAttr.asObservable();

  entityConfData = new Subject();
  entityConfData$ = this.entityConfData.asObservable();

  cuEntityConfData = new Subject();
  cuEntityConfData$ = this.cuEntityConfData.asObservable();

  cuDetails = new Subject();
  cuDetails$ = this.cuDetails.asObservable();

  public dcdmappingtonestedcu = new BehaviorSubject('');
  dcdmappingtonestedcu$ = this.dcdmappingtonestedcu.asObservable();

  resetRelatedCuGsiValue = new BehaviorSubject('');
  resetRelatedCuGsiValue$ = this.resetRelatedCuGsiValue.asObservable();

  orgunitDropDown = new BehaviorSubject(false);
  orgunitDropDown$ = this.orgunitDropDown.asObservable();

  singleAttributeDetailsWithEnt = new Subject();
  singleAttributeDetailsWithEnt$ = this.singleAttributeDetailsWithEnt.asObservable();

  public editAttributeControl = new Subject();
  editAttributeControl$ = this.editAttributeControl.asObservable();

  public currentEntityData = new Subject();
  currentEntityData$ = this.currentEntityData.asObservable();

  public cuSourceForHoverComponent = new Subject();
  currentCuForHoverComponent$ = this.cuSourceForHoverComponent.asObservable();

  public createNewEntityFlag = new Subject();
  createNewEntityFlag$ = this.createNewEntityFlag.asObservable();

  private isDCDScreenActive = new Subject();
  isDCDScreenActive$ = this.isDCDScreenActive.asObservable();

  public allBETsSelected = new BehaviorSubject(false);
  allBETsSelected$ = this.allBETsSelected.asObservable();

  nslhubKitOptions: any = {
    content: [
      { name: 'Reserved - Search', isReserved: true, reservedCUType: 'search' },
      { name: 'Reserved - Create', isReserved: true, reservedCUType: 'create' },
      { name: 'Reserved - List', isReserved: true, reservedCUType: 'culist' },
      {
        name: 'Reserved - Report',
        isReserved: true,
        reservedCUType: 'NSL_ExcelReport1',
        agents: [
          {
            agentType: 'machine',
          },
        ],
      },
    ],
  };

  libraryPanelType: string = '';
  sessionTimeLeft = new Subject();
  sessionTimeLeft$ = this.sessionTimeLeft.asObservable();

  gsiDetails = new Subject();
  gsiDetails$ = this.gsiDetails.asObservable();

  currentEventAttr = new Subject();
  currentEventAttr$ = this.currentEventAttr.asObservable();

  entityAttributes = new Subject();
  entityAttributes$ = this.entityAttributes.asObservable();

  //Elastic search - FE757
  contextualSearchData = new BehaviorSubject({ containsData: false, gsiId: 0, data: {} });
  contextualSearchData$ = this.contextualSearchData.asObservable();

  previousGsiId = new BehaviorSubject({ id: -1 });
  previousGsiId$ = this.previousGsiId.asObservable();

  attributesList = new BehaviorSubject('');
  attributesList$ = this.attributesList.asObservable();

  labelForDropdownSubject = new Subject();
  labelForDropdown$ = this.labelForDropdownSubject.asObservable();

  editedAttributeName = new BehaviorSubject(false);
  editedAttributeName$ = this.editedAttributeName.asObservable();

  stepType = new Subject();
  stepType$ = this.stepType.asObservable();

  postSearchData: any;
  currentAttrType: any;

  constructor(
    private changeUnitFacadeService: ChangeUnitFacadeService,
    private entityFacadeService: EntityFacadeService,
    private gsiFacadeService: GsiFacadeService,
    private loader: LoaderService,
    private attributeEndpointService: AttributeEndpointService,
    private attributeFacadeService: AttributeFacadeService,
    private rolesFacadeService: RolesFacadeService,
    private entityEndPointService: EntityEndpointService,
    private alterService: AlertService,
    private changeUnitEndpointService: ChangeUnitEndpointService,
    private organisationUnitsFacadeService: OrganisationUnitsFacadeService
  ) {}

  ngOnDestroy() {
    this.ngUnsubcribe.next();
    this.ngUnsubcribe.complete();
  }

  updateDCDScreenStatus(flag: boolean) {
    this.isDCDScreenActive.next(flag);
  }

  watchEditAttribute(data: any) {
    this.watchAttributeChange.next(data);
  }
  /// entity configuration in cu or entity level ///
  watchEntityConf(data: any, inCu?: any, layerType?: any) {
    let objdata;
    if (inCu) {
      objdata = { data: data, inCu: inCu, layerType: layerType };
    } else {
      objdata = data;
    }
    this.watchEntityConfigure.next(objdata);
  }

  selectAllBETs(x: boolean) {
    this.allBETsSelected.next(x);
  }

  editAttribute(value: any) {
    this.editAttributeControl.next(value);
  }

  updateCurrentLibraryType(libraryType: any) {
    this.currentLibraryType.next(libraryType);
  }

  clearfiles(val: boolean) {
    this.clearallfiles.next(val);
  }

  // decides which library to show
  changeOfCurrentLibraryType(
    libraryType: string,
    pageIndex?: number,
    pageSize?: number,
    searchText?: string,
    isPublished?: any,
    agentLevelType?: string,
    bookId?: any
  ) {
    /* istanbul ignore next */
    pageIndex = pageIndex === undefined ? 0 : pageIndex;
    /* istanbul ignore next */
    pageSize = pageSize === undefined ? 15 : pageSize;
    /* istanbul ignore next */
    searchText = searchText === undefined ? '' : searchText;
    /* istanbul ignore next */
    isPublished = !isPublished ? '' : isPublished;
    if (libraryType === 'orgunits') {
      this.orgunitDropDown.next(true);
    } else {
      this.orgunitDropDown.next(false);
    }
    this.currentLibraryType.next(libraryType);
    this.libraryPanelType = libraryType;

    if (agentLevelType) {
      this.currentAgentLevelType.next(agentLevelType);
    }
    this.loadLibraryData(libraryType, pageIndex, pageSize, searchText, isPublished, bookId);
  }

  resetLibrary() {
    this.currentAgentLevelType.next(null);
  }

  // Load Data from all Facade
  detectLibraryDataStreams() {
    // Books in the library Facade
    // GSi Latest List
    this.gsiFacadeService.gsi$.pipe(takeUntil(this.ngUnsubcribe)).subscribe((res: any) => {
      if (
        (this.libraryPanelType === Librarytypes.GSI ||
          this.libraryPanelType === Librarytypes.GSIEmbedded ||
          this.libraryPanelType === Librarytypes.GSInested ||
          this.libraryPanelType === Librarytypes.GSIexception ||
          this.libraryPanelType === Librarytypes.EventGsi) &&
        res !== undefined
      ) {
        this.changeOfLibraryData(res);
      }
    });

    // CU Latest List
    this.changeUnitFacadeService.changeUnitList$.pipe(takeUntil(this.ngUnsubcribe)).subscribe((res: any) => {
      if (this.libraryPanelType === Librarytypes.ChangeUnit && res !== undefined) {
        this.changeOfLibraryData(res);
      }
    });

    // Reserved CU Latest List
    this.changeUnitFacadeService.reservedCuList$.pipe(takeUntil(this.ngUnsubcribe)).subscribe((res: any) => {
      if (this.libraryPanelType === Librarytypes.NSLKitCU && res !== undefined) {
        this.changeOfLibraryData(res);
      }
    });
    // Reserved Entity Latest List
    this.changeUnitFacadeService.reservedEntityList$.pipe(takeUntil(this.ngUnsubcribe)).subscribe((res: any) => {
      if (this.libraryPanelType === Librarytypes.NSLKitEntity && res !== undefined) {
        this.changeOfLibraryData(res);
      }
    });
    // Entities Latest List
    this.entityFacadeService.entities$.pipe(takeUntil(this.ngUnsubcribe)).subscribe((res: any) => {
      if (
        (this.libraryPanelType === Librarytypes.Entity || this.libraryPanelType === Librarytypes.ReservedActions) &&
        res !== undefined
      ) {
        this.changeOfLibraryData(res);
      }
    });
    // Attributes Latest List
    this.attributeFacadeService.attributes$.pipe(takeUntil(this.ngUnsubcribe)).subscribe((res: any) => {
      if (this.libraryPanelType === Librarytypes.Attributes && res !== undefined) {
        this.changeOfLibraryData(res);
      }
    });

    // Roles Latest List
    this.rolesFacadeService.rolesPaginateSubject$.pipe(takeUntil(this.ngUnsubcribe)).subscribe((res: any) => {
      if (this.libraryPanelType === Librarytypes.Agent && res !== undefined) {
        /* istanbul ignore next */
        res.rolesList = res?.rolesList ? res?.rolesList : res?.content;
        /* istanbul ignore next */
        res.totalRoles = res?.totalRoles ? res?.totalRoles : res?.totalElements;
        const data = { content: res.rolesList, total: res.totalRoles };
        this.changeOfLibraryData(data);
      }
    });

    // Org Units Latest List
    this.organisationUnitsFacadeService.orgUnitPaginateSubject$
      .pipe(takeUntil(this.ngUnsubcribe))
      .subscribe((res: any) => {
        if (this.libraryPanelType === Librarytypes.OrgUnit && res !== undefined) {
          const data = { content: res.result.content, total: res.result.totalElements };
          this.changeOfLibraryData(data);
        }
      });
  }
  // pass the data current selected Library type to library component
  changeOfLibraryData(data: any) {
    if (data !== undefined) {
      this.libraryData.next(data);
    }
  }

  nkKitData() {
    setTimeout(() => {
      this.changeOfLibraryData(this.nslhubKitOptions);
    });
  }

  // Request to load data in respected Facade
  loadLibraryData(
    libraryType: string,
    pageIndex: number,
    pageSize: number,
    searchText: string,
    isPublished: boolean,
    bookId?: any
  ) {
    switch (libraryType) {
      case Librarytypes.Book:
        this.getAllBooks(pageIndex, pageSize, searchText);
        break;
      case Librarytypes.EventGsi:
        this.getAllGsis(pageIndex, pageSize, searchText, true, false);
        break;
      case Librarytypes.GSI:
        this.getAllGsis(pageIndex, pageSize, searchText, isPublished, false);
        break;
      case Librarytypes.GSIEmbedded:
        this.getAllGsis(pageIndex, pageSize, searchText, true, false);
        break;
      case Librarytypes.GSInested:
        this.getAllGsis(pageIndex, pageSize, searchText, true, false);
        break;

      case Librarytypes.GSIexception:
        this.getAllGsis(pageIndex, pageSize, searchText, true, false);
        break;

      case Librarytypes.ChangeUnit:
        this.getAllChangeUnits(pageIndex, pageSize, searchText, isPublished);
        break;

      case Librarytypes.Entity:
        this.getAllEntities(pageIndex, pageSize, searchText, isPublished);
        break;

      case Librarytypes.Attributes:
        this.getAllAttributes(pageIndex, pageSize, searchText, isPublished);
        break;

      case Librarytypes.EQL:
        this.getAllEntities(pageIndex, pageSize, searchText, isPublished);
        break;

      case Librarytypes.EQLsubQuery:
        this.getAllEntities(pageIndex, pageSize, searchText, isPublished);
        break;

      case Librarytypes.Agent:
        if (bookId == undefined) {
          this.getAllRoles(pageIndex, pageSize, searchText);
        } else {
          this.rolesFacadeService.loadRoleForBook(bookId, pageIndex, pageSize, searchText, true);
        }
        break;
      case Librarytypes.OrgUnit:
        this.getAllOrgUnits(pageIndex, pageSize, searchText);
        break;
      case Librarytypes.DataType:
        this.getAllAttributeTypes(searchText, pageIndex, pageSize);
        break;
      case Librarytypes.NSLKitCU:
        this.getAllReservedChangeUnits(pageIndex, pageSize, searchText);
        break;
      case Librarytypes.NSLKitEntity:
        this.getAllReservedEntities(pageIndex, pageSize, searchText);
        break;
      case Librarytypes.ReservedActions:
        this.getAllEntities(pageIndex, pageSize, searchText, true, Librarytypes.ReservedActions);
        break;
    }
    this.detectLibraryDataStreams();
  }

  detectTotalRecordsData() {
    const booksData$ = this.books$;
    const changeUnitData$ = this.changeUnitFacadeService.changeUnitList$;
    // changeUnitData$, entityData$, rolesData$
    forkJoin(booksData$, changeUnitData$)
      .pipe(takeUntil(this.ngUnsubcribe))
      .subscribe((res: any) => {
        console.log(res);
      });
  }

  async getAllLibraryCounts() {
    this.attributeEndpointService.getAllLibraryDataCount().subscribe((res: any) => {
      this.libraryDataCount.next(res.result);
    });
  }
  UiDataType(value: any) {
    this.currentAttrType = value;
  }
  enableNestedLibraryView(enableView: boolean) {
    this.showNestedLibrary.next(enableView);
  }

  enableGSIRecursiveLibraryView(enableView: boolean) {
    this.gsiRecursiveLibrary.next(enableView);
  }

  dcdmappingtonested(dcdtonested: string) {
    this.dcdmappingtonestedcu.next(dcdtonested);
  }

  resetRelatedCuGsi(reset: string) {
    this.resetRelatedCuGsiValue.next(reset);
  }

  updateCurrentTimeleftForSession(time: any) {
    this.sessionTimeLeft.next(time);
  }

  importAttributeForOCR(data: any, isOCR: boolean) {
    this.attrData.next({ ...data, isDld: false, isOCR: true });
  }

  importAttibute(data: any, isDld?: boolean) {
    this.attrData.next({ ...data, isDld: true });
  }

  importAttrData(attributeData: any) {
    this.attrEntity.next(attributeData);
  }

  importAttrDataForEQL(attributeData: any) {
    this.attrEntityForEQL.next(attributeData);
  }

  importAttrDataForEQLReport(attributeData: any) {
    this.attrEntityForEQLReport.next(attributeData);
  }

  importEntity(entityData: any) {
    this.entitySource.next(entityData);
  }

  importEqlEntity(entityData: any) {
    this.eqlEntitySource.next(entityData);
  }

  changeCu(cu: any, cutype?: any) {
    if (cu?.isLibrary) {
      this.changeUnitEndpointService
        .getCuDetailsById(cu?.id)
        .pipe(takeUntil(this.ngUnsubcribe))
        .subscribe(
          (res: any) => {
            /* istanbul ignore next */
            if (res.status === 200) {
              cu.cuSystemProperties = res.result?.cuSystemProperties;
              this.cuSource.next(cu);
              this.cuDetails.next(res.result);
            }
          },
          (err: any) => {
            this.alterService.showToaster(err, '', 'error');
          }
        );
    } else {
      if (cutype == 'nested') {
        cu.isNested = true;
      }
      this.cuSource.next(cu);
    }
  }

  changeCUForHoverComponent(cu: any) {
    this.cuSourceForHoverComponent.next(cu);
  }
  changeopenCu(message: any) {
    this.openSource.next(message);
  }
  selectedRole(message: any) {
    this.selectRoleSource.next(message);
  }
  changeopenAgents(message: any) {
    this.openAgentSource.next(message);
  }
  changeopenEntities(message: any) {
    this.openEntitySource.next(message);
  }
  changeEditEnt(message: any) {
    this.enteditSource.next(message);
  }
  clearEditEntity() {
    this.enteditSource.next('');
  }
  changetoCurrentComp(message: any) {
    this.currentcomp.next(message);
  }

  addRole(message: any) {
    this.roleSource.next(message);
  }
  addUser(message: any) {
    this.userSource.next(message);
  }

  openGsi(message: any) {
    this.gsiSource.next(message);
  }

  changeEditCu(message: any) {
    this.cuEditSource.next(message);
  }
  clearEditCu() {
    this.cuEditSource.next('');
  }
  saveentFlag(message: any) {
    this.saveEnt.next(message);
  }

  changeDcdProperties(message: any) {
    this.dcdProperties.next(message);
  }

  changedraftVersion(message: any) {
    this.solDraft.next(message);
  }
  addReservedCU(data: any) {
    this.addReservedCUSelected.next(data);
  }

  getAllChangeUnits(pageNumber: number, pageSize: number, searchCriteria: string, isPublished: boolean) {
    this.loader.show();
    this.changeUnitFacadeService.getAllChangeUnits(pageNumber, pageSize, searchCriteria, isPublished);
    this.loader.hide();
  }

  getAllReservedChangeUnits(pageNumber: number, pageSize: number, searchCriteria: string) {
    this.loader.show();
    this.changeUnitFacadeService.getReservedCuList(pageNumber, pageSize, searchCriteria);
    this.loader.hide();
  }
  getAllReservedEntities(pageNumber: number, pageSize: number, searchCriteria: string) {
    this.loader.show();
    this.changeUnitFacadeService.getReservedEntitiesList(pageNumber, pageSize, searchCriteria);
    this.loader.hide();
  }
  getAllEntities(pageNumber: number, pageSize: number, searchCriteria: string, isPublished: any, layerType?: any) {
    this.loader.show();
    this.entityFacadeService.getAllEntities(pageNumber, pageSize, searchCriteria, isPublished, layerType);
    this.loader.hide();
  }
  getAllAttributes(pageNumber: number, pageSize: number, searchCriteria: string, isPublished: any, layerType?: any) {
    this.loader.show();
    this.attributeFacadeService.getAllAttributes(pageNumber, pageSize, searchCriteria, isPublished, layerType);
    this.loader.hide();
  }
  getAllEqlEntities(pageNumber: number, pageSize: number, searchCriteria: string, isPublished: any, layerType?: any) {
    this.loader.show();
    this.entityFacadeService.getAllEqlEntities(pageNumber, pageSize, searchCriteria, isPublished, layerType);
    this.loader.hide();
  }
  getAllGsis(pageNumber: number, pageSize: number, searchCriteria: string, isPublished: boolean, isTXNRights: boolean) {
    this.loader.show();
    this.gsiFacadeService.getAllGsis(pageNumber, pageSize, searchCriteria, isPublished, isTXNRights);
    this.loader.hide();
  }
  getAllBooks(pageNumber: number, pageSize: number, searchCriteria: string) {
    this.loader.show();
    this.gsiFacadeService
      .getAllBooks(pageNumber, pageSize, searchCriteria)
      .pipe(takeUntil(this.ngUnsubcribe))
      .subscribe((res: any) => {
        var bookList = bookListMapper(res);
        this.changeOfLibraryData(bookList);
        this.bookSource.next(res);
      });
    this.loader.hide();
  }

  dashBoardgetAllBooks(pageNumber: number, pageSize: number, searchCriteria: string) {
    this.loader.show();
    this.gsiFacadeService
      .dashBoardgetAllBooks(pageNumber, pageSize, searchCriteria)
      .pipe(takeUntil(this.ngUnsubcribe))
      .subscribe((res: any) => {
        this.bookSource.next(res);
      });
    this.loader.hide();
  }
  getAllRoles(pageIndex: number, pageSize: number, searchCriteria: string) {
    this.loader.show();
    this.rolesFacadeService.loadPaginateRoles(pageIndex, pageSize, searchCriteria, true);
    this.loader.hide();
  }

  getAllUsers(id: any) {
    this.gsiFacadeService.getAllUsers(id);
  }

  getAllOrgUnits(pageIndex: number, pageSize: number, searchCriteria: string) {
    this.loader.show();
    this.organisationUnitsFacadeService.getPaginateOrgUnit(pageIndex, pageSize, searchCriteria);
    this.loader.hide();
  }

  getAllUsersInOrgUnit(id: any) {
    this.gsiFacadeService.getAllUsersInOrgUnit(id);
  }

  currentCuTyeChange(cuType: string) {
    this.currentCUType.next(cuType);
  }

  addAlternateCU(cuType: string) {
    this.alternateCUAdd.next(cuType);
  }

  addSequentialChangeUnit(cuType: string) {
    this.addSequentialCU.next(cuType);
  }

  addAttributetoAlternateCU(data: any) {
    this.currentAttributeForAlternateCU.next(data);
  }
  addAttributetoCuPotentiality(data: any) {
    this.currentAttributeForCuPotentiality.next(data);
  }

  showCUWithDetailsInLibrary(data: any) {
    this.cuListDetailsWithLayersInLibrary.next(data);
  }

  // selectedCurrentAlternateCU(data: any) {
  //   this.currentAlternateCU.next(data);
  // }

  fetchGSIdetails(gsi: any) {
    this.editGsi.next(gsiMapper(gsi));
  }

  importGSIFromDld(gsi: any) {
    this.dldGsi.next(gsi);
  }

  updateGSIdetails(gsi: any, isNotification?: any) {
    /* istanbul ignore next */
    this.gsiFacadeService
      .getGSIDetailsById(gsi?.id)
      .pipe(takeUntil(this.ngUnsubcribe))
      .subscribe(
        (res: any) => {
          console.log(res);
          if (isNotification) {
            this.editGsiForVersion.next(gsiMapper(res?.result)); // subject for notifications
          } else {
            this.editGsi.next(gsiMapper(res?.result));
          }
        },
        (err: any) => {
          if (err?.error?.result) {
            this.editGsi.next(gsiMapper(err?.error?.result));
          }
        }
      );
  }

  addGsitoNestedLayer(gsi: any) {
    this.cuSourceForHoverComponent.next(gsi);
  }
  addGsitoExceptionLayer(gsi: any) {
    this.cuSourceForHoverComponent.next(gsi);
  }

  showDataTypes(body: any) {
    this.dataTypes.next(body);
  }
  importDataTypes(data: any) {
    this.importdataTypes.next(data);
  }

  addEntAttrIndex(data: any) {
    this.entityAttrIndex.next(data);
  }

  getAttrByEntityId(id: any, isReserved?: any) {
    this.entityFacadeService.loadnestedEntityDataByEntId(id, null, null, isReserved);
  }

  getSolDraft(body: any, type: any, bookId: any) {
    this.gsiFacadeService.saveDraftVersion(body, type, bookId);
  }
  closeLibraryPanel() {
    this.closeLibrary.next('true');
  }

  createNewClick() {
    this.clearEditCu();
    this.createNew.next(true);
  }

  homePageBookSearch(data: any) {
    this.bookSearch.next(data);
  }
  openPendingTransactions(data: any) {
    this.pendingTransaction.next(data);
  }
  openImportSolution(data: any) {
    this.ImportSolution.next(data);
  }

  openImportExportSolution(data: any) {
    this.ImportExportSolution.next(data);
  }
  getAllAttributeTypes(searchCriteria: string, pageIndex: number, pageSize: number) {
    this.loader.show();
    this.attributeFacadeService
      .getAllAttributeTypes(searchCriteria, pageIndex, pageSize, this.currentAttrType)
      .pipe(takeUntil(this.ngUnsubcribe))
      .subscribe((res: any) => {
        if (res) {
          this.loader.hide();
          let data = { content: res.data, total: res.totalResults };
          this.changeOfLibraryData(data);
        } else {
          this.loader.hide();
        }
      });
  }

  setGsiDetails(gsiDetails: any) {
    this.gsiDetails.next(gsiDetails);
  }
  emitAttributeInfo(attrData: any) {
    this.attributeData.next(attrData);
  }
  emitEntityConfiguration(attrData: any) {
    this.entityConfigurationData.next(attrData);
  }
  currentEventAttribute(data: any) {
    this.currentEventAttr.next(data);
  }
  updateLayersCurrentAttr(data: any) {
    this.layersCurrentAttr.next(data);
  }
  // entity configuration entity//
  storeEntityConfData(data: any) {
    this.entityConfData.next(data);
  }
  // entity configuration  cu //
  storeCuEntityConfData(data: any, entity?: any, layerType?: any) {
    let entityobj = {};
    if (entity) {
      entityobj = { data: data, entity: entity, layerType: layerType };
    } else {
      entityobj = { data: data };
    }
    this.cuEntityConfData.next(entityobj);
  }
  // entity configuration  flag creating new ent //
  createNewEntity(data: any) {
    this.createNewEntityFlag.next(data);
  }
  getSingleAttributeDetailsWithEnt(data: any) {
    this.singleAttributeDetailsWithEnt.next(data);
  }
  updateCurrentEntityData(data: any) {
    this.currentEntityData.next(data);
  }

  detectLibrary(leftmenu: boolean) {
    this.leftSidebar.next(leftmenu);
  }

  getEntityData(entityId: any, attributeIndex: number) {
    this.entityEndPointService
      .loadAttributesByEntId(entityId, false)
      .pipe(takeUntil(this.ngUnsubcribe))
      .subscribe((res: any) => {
        const entInfo = entityMapper(res.result);
        entInfo.attrIndex = attributeIndex;
        this.entityAttributes.next(entInfo);
      });
  }

  //Elastic search - FE757
  // ---------------------- START -----------------------------
  //Save contextual config
  postContextualSearchData() {
    /* istanbul ignore next */
    if (this.postSearchData) {
      for (
        let i = 0;
        i < this.postSearchData?.ctxEntityConfigs[0]?.searchAttrConfigs[0]?.contextAttrConfigs.length;
        i++
      ) {
        /* istanbul ignore next */
        if (this.postSearchData?.ctxEntityConfigs[0]?.searchAttrConfigs[0]?.contextAttrConfigs[i]?.attrName == '') {
          delete this.postSearchData.ctxEntityConfigs[0].searchAttrConfigs[0].contextAttrConfigs[i];
        }
      }
      this.entityEndPointService
        .postContextualSearchData(this.postSearchData)
        .pipe(takeUntil(this.ngUnsubcribe))
        .subscribe((res: any) => {});
    }
  }

  // Fetch contextual config
  getContextualSearchData(gsiId: number) {
    /* istanbul ignore next */
    let existIndex = this.existConfigData?.findIndex((data: any) => data?.gsiId === gsiId);
    /* istanbul ignore next */
    if (existIndex === -1) {
      let defaultData = {
        containsData: false,
        gsiId: gsiId,
        data: {},
      };
      this.existConfigData.push(defaultData);
      this.contextualSearchData.next(defaultData);
      this.previousGsiId.next({ ...this.previousGsiId.value, id: gsiId });
      this.entityEndPointService
        .getContextualSearchData(gsiId)
        .pipe(takeUntil(this.ngUnsubcribe))
        .subscribe((res: any) => {
          let responseData = {
            containsData: true,
            gsiId: gsiId,
            data: res,
          };
          /* istanbul ignore next */
          let checkData = this.existConfigData?.findIndex((data: any) => data?.gsiId === gsiId);
          this.existConfigData[checkData] = responseData;
          this.contextualSearchData.next(responseData);
        });
    } else {
      /* istanbul ignore next */
      this.contextualSearchData.next(this.existConfigData?.[existIndex]);
    }
  }

  // Receiving data from contextual-search-configuration comp
  postDataForLib(data: any) {
    this.postSearchData = data;
  }
  // ---------------------- END -----------------------------
  getcurrentuser(activeuser: any) {
    this.userSource.next(activeuser);
  }

  // Set data to attributesList for membership criteria
  setAttributesList(attributesList: any) {
    this.attributesList.next(attributesList);
  }

  updateLabelForDropdown(value: any) {
    this.labelForDropdownSubject.next(value);
  }
  nestedLibrary(stepName: string) {
    this.stepType.next(stepName);
  }
  updateAttributeName(value: any) {
    this.editedAttributeName.next(value);
  }
}

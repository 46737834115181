import { Injectable, Injector } from '@angular/core';
import { of } from 'rxjs/internal/observable/of';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { pluck } from 'rxjs/internal/operators/pluck';
import { AlertService } from '@app/@core/toaster/alert.service';
import { Router } from '@angular/router';
import { CduiBaseService } from '@app/auth/cdui-base.service';

@Injectable({
  providedIn: 'root',
})
export class LayoutSolutionEndpointService extends CduiBaseService {
  constructor(
    private injector: Injector,
    private http: HttpClient,
    private alertService: AlertService,
    private router: Router
  ) {
    super(injector);
  }

  // getAllBooks(pagenum: number, pageSize: number, searchText: string) {
  //   return this.http
  //     .get(
  //       environment.tfCoreUrl +
  //         '/CU/books?pageNo=' +
  //         pagenum +
  //         '&pageSize=' +
  //         pageSize +
  //         '&searchCriteria=' +
  //         searchText
  //     )
  //     .pipe(pluck('result', 'content'));
  // }

  getAllBooksWithDesignData(
    pagenum: number,
    pageSize: number,
    searchText: string,
    userRights?: string,
    approvalStatus?: string,
    selectedWeek?: number
  ) {
    let week = selectedWeek ? selectedWeek : '';
    if (approvalStatus) {
      if (userRights) {
        return this.http.get(
          `${this.api?.getBooksWithDesignData}pageNo=${pagenum}&pageSize=${pageSize}&userRight=${userRights}&searchCriteria=${searchText}&approvalStatuses=${approvalStatus}&fromUpdatedTime=${week}`
        );
      } else {
        return this.http.get(
          `${this.api?.getBooksWithDesignData}pageNo=${pagenum}&pageSize=${pageSize}&searchCriteria=${searchText}&approvalStatuses=${approvalStatus}&fromUpdatedTime=${week}`
        );
      }
    } else {
      if (userRights) {
        return this.http.get(
          `${this.api?.getBooksWithDesignData}pageNo=${pagenum}&pageSize=${pageSize}&userRight=${userRights}&searchCriteria=${searchText}&fromUpdatedTime=${week}`
        );
      } else {
        return this.http.get(
          `${this.api?.getBooksWithDesignData}pageNo=${pagenum}&pageSize=${pageSize}&searchCriteria=${searchText}&designMode=false&fromUpdatedTime=${week}`
        );
      }
    }
  }

  getAllChapters() {
    return of([
      {
        label: 'Select Chapter',
        name: 'Select Chapter',
        id: 2233,
        parentId: 1,
        childCount: 1,
      },
    ]);
  }

  storeBookData(bookData: any) {
    return this.http.post(this.api?.uiControls, bookData).subscribe((res: any) => {
      this.alertService.showToaster(res.message, '', 'success');
    });
  }

  storeChangeUnitData(bookData: any) {
    return this.http.post(this.api?.uiControls, bookData).subscribe((res: any) => {
      this.alertService.showToaster(res.message, '', 'success');
    });
  }

  fetchBookData(bookId: any) {
    return this.http.get(`${this.api?.uiControls}?cuId=${bookId}&cuType=CHANGEUNIT`).pipe(pluck('result'));
  }

  saveDesign(bookData: any) {
    return this.http.post(this.api?.storeBookData, bookData).pipe(pluck('result'));
  }

  getIndustries() {
    return this.http.get(this.api?.getIndustries);
  }
  getColors(limit: number, bgPage: number) {
    return this.http.get(`${this.api?.getColors}limit=${limit}&page=${bgPage}`);
  }

  getWallpapers(bgsLimit: number, bgPage: number) {
    return this.http.get(`${this.api?.getWallpapers}limit=${bgsLimit}&page=${bgPage}`);
  }
  getBookDetails(bookId: any) {
    return this.http.get(`${this.api?.getBookDetails}${bookId}`);
  }
  getGsisInBook(bookId: any) {
    const options = {
      headers: new HttpHeaders({ bookId: `${bookId}` }),
    };
    return this.http.get(`${this.api?.getGsiDetails}${bookId}`, options);
  }
  getCusInBook(bookId: any, gsiIds: any) {
    const options = {
      headers: new HttpHeaders({ bookId: `${bookId}` }),
    };
    return this.http.post(`${this.api?.getCuDetails}${bookId}`, gsiIds, options);
  }
  getEntitiesInBook(bookId: any, cuIds: any) {
    const options = {
      headers: new HttpHeaders({ bookId: `${bookId}` }),
    };
    return this.http.post(`${this.api?.getEntityDetails}${bookId}`, cuIds, options);
  }

  ////////////Fetching Indusry List////////////

  getBookCovers(ontologyId: any, limit: any, page: any) {
    if (ontologyId) {
      return this.http.get(`${this.api?.getAllBooks}limit=${limit}&ontologyId=${ontologyId}&page=${page + 1}`);
    } else return this.http.get(`${this.api?.getAllBooks}limit=${limit}&page=${page + 1}`);
  }

  getGsiIcons(ontologyId: any, limit: any, page: any) {
    if (ontologyId) {
      return this.http.get(`${this.api?.getSolutionIcons}limit=${limit}&ontologyId=${ontologyId}&page=${page}`);
    } else return this.http.get(`${this.api?.getSolutionIcons}limit=${limit}&page=${page}`);
  }

  getEntityIcons(ontologyId: any, limit: any, page: any) {
    if (ontologyId) {
      return this.http.get(`${this.api?.getEntityIcons}limit=${limit}&ontologyId=${ontologyId}&page=${page}`);
    } else return this.http.get(`${this.api?.getEntityIcons}limit=${limit}&page=${page}`);
  }
  getCuIcons(ontologyId: any, limit: any, page: any) {
    if (ontologyId) {
      return this.http.get(`${this.api?.getCuIcons}limit=${limit}&ontologyId=${ontologyId}&page=${page}`);
    } else return this.http.get(`${this.api?.getCuIcons}limit=${limit}&page=${page}`);
  }

  getAllLayoutDesigns(bookId: any) {
    return this.http.get(`${this.api?.uiControls}?cuId=${bookId}&cuType=CHANGEUNIT`).pipe(pluck('result'));
  }

  updateUserData(userId: any, body: any) {
    return this.http.post(`${this.api?.updateUser}type=graph&userId=${userId}`, body).pipe(pluck('result'));
  }

  getLibraryTemplateData() {
    return this.http.get(`${this.api?.libraryConfig}/templates`).pipe(pluck('result'));
  }

  getLibraryTemplateDataById(layoutId: string) {
    return this.http.get(`${this.api?.libraryLayout}/${layoutId}`).pipe(pluck('result'));
  }

  getGSIsByBookId(bookId: any) {
    const options = {
      headers: new HttpHeaders({ bookId: `${bookId}` }),
    };
    return this.http
      .get(`${this.api?.saveBook}/${bookId}/gsi?pageNo=0&pageSize=10&searchCriteria=&designMode=false`, options)
      .pipe(pluck('result'));
  }
}

import { Injectable } from '@angular/core';
import { ObservableStore } from '@codewithdan/observable-store';
import { chatBotModelFormat } from '@app/@core/generic-functions';

export interface StoreState {
  messagesList: any[];
}

@Injectable()
export class ChatBotStore extends ObservableStore<StoreState> {
  constructor() {
    super({ trackStateHistory: true, logStateChanges: false });
  }

  /**
   *
   * @returns get the default state if not map with the temporary model
   */
  /* istanbul ignore next */
  getStateData() {
    let state = this.getState();
    /* istanbul ignore next */
    if (Object?.keys(state)?.length > 0) {
      return state;
    } else {
      return {
        messagesList: [],
      };
    }
  }

  /**
   *
   * @param state
   * @param data
   * @param action
   * update the question
   */

  updateQuestion(state: any, data: any, action: any) {
    const newState: any = chatBotModelFormat(state, data, action);
    this.setState(newState, action);
  }

  /**
   *
   * @param state
   * @param data
   * @param action
   * update the latest response
   */

  updateAnswer(state: any, data: any, action: any) {
    const newState: any = chatBotModelFormat(state, data, action);
    this.setState(newState, action);
  }

  updateGreetingMessage(state: any, data: any, action: any) {
    const newState: any = chatBotModelFormat(state, data, action);
    this.setState(newState, action);
  }

  updateVoiceInput(state: any, data: any, action: any) {
    const newState: any = chatBotModelFormat(state, data, action);
    this.setState(newState, action);
  }

  updateProfanityQuestion(state: any, data: any, action: any) {
    const newState: any = chatBotModelFormat(state, data, action);
    this.setState(newState, action);
  }

  updateVoiceWithTextQuestionAndAnswer(state: any, data: any, action: any) {
    const newState: any = chatBotModelFormat(state, data, action);
    this.setState(newState, action);
  }

  updateEvent(state: any, data: any, action: any) {
    const newState: any = chatBotModelFormat(state, data, action);
    this.setState(newState, action);
  }

  updateFile(state: any, data: any, action: any) {
    const newState: any = chatBotModelFormat(state, data, action);
    this.setState(newState, action);
  }

  /**
   * reset the store
   */
  resetStore() {
    ObservableStore.resetState({ messagesList: [] }, true);
  }

  /**
   *
   * @param data
   * @param action
   * @returns
   * used to handle the switch case based on the action type
   *
   */
  setStateData(data: any, action: string) {
    const currentStateData = this.getStateData();
    switch (action) {
      case ChatBotStoreActions.GreetingMessage:
        return this.updateGreetingMessage(currentStateData, data, action);

      case ChatBotStoreActions.UpdateQuestion:
        return this.updateQuestion(currentStateData, data, action);

      case ChatBotStoreActions.UpdateProfanityQuestion:
        return this.updateAnswer(currentStateData, data, action);

      case ChatBotStoreActions.UpdateAnswer:
        return this.updateAnswer(currentStateData, data, action);

      case ChatBotStoreActions.VoiceInput:
        return this.updateVoiceInput(currentStateData, data, action);

      case ChatBotStoreActions.UpdateVoiceWithTextQuestionAndAnswer:
        return this.updateVoiceWithTextQuestionAndAnswer(currentStateData, data, action);

      case ChatBotStoreActions.ResetStore:
        return this.resetStore();

      case ChatBotStoreActions.UpdateEvent:
        return this.updateEvent(currentStateData, data, action);

      case ChatBotStoreActions.UpdateFile:
        return this.updateFile(currentStateData, data, action);
    }
  }
}
export enum ChatBotStoreActions {
  ReadMessages = 'GET_Message',
  UpdateQuestion = 'Update_Question',
  UpdateAnswer = 'Update_Answer',
  ResetStore = 'ResetStore',
  GreetingMessage = 'GreetingMessage',
  VoiceInput = 'Send_Voice_Input',
  SendFeedback = 'SendFeedback',
  UpdateProfanityQuestion = 'Update_ProfanityQuestion',
  UpdateVoiceWithTextQuestionAndAnswer = 'Update_VoiceWithTextQuestionAndAnswer',
  UpdateEvent = 'UpdateEvent',
  UpdateFile = 'UpdateFile',
}

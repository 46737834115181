import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { changeUnitDeMapper } from '@app/@core/mappers/change-unit/cu-demapper';
import { changeUnitMapper } from '@app/@core/mappers/change-unit/cu-mapper';
import { gsiDeMapper } from '@app/@core/mappers/gsi/gsi-demapper';
import { gsiMapper } from '@app/@core/mappers/gsi/gsi-mapper';
import { gsiListMapper } from '@app/@core/mappers/list/gsi';
import { AlertService } from '@app/@core/toaster/alert.service';
import { ChangeUnit } from '@app/@shared/models/change-unit';
import { Gsi } from '@app/@shared/models/gsi';
import { LoaderService } from '@app/@shared/services/loader/loader.service';
import { UploadEndpointService } from '@app/@shared/services/upload/upload-endpoint.service';
import { GsiStoreService } from '@app/@shared/stores/gsi-store.service';
import { SolutionDictionaryFacadeService } from '@app/solution-dictionary/solution-dictionary-facade.service';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { GsiEndpointService } from './gsi-endpoint.service';
@Injectable({
  providedIn: 'root',
})
export class GsiFacadeService implements OnDestroy {
  private ngUnsubscribe = new Subject();
  gsiData: any = [];
  gsiObj: any;

  prevButtonTrigger: any;
  enteredButton = false;
  isMatMenuOpen = false;
  // private gsiSubject = new BehaviorSubject(this.gsiData);
  public gsiSubject = new Subject();
  gsi$ = this.gsiSubject.asObservable();

  private bookDesign = new Subject();
  bookDesign$ = this.bookDesign.asObservable();

  exportGsiSubject = new Subject();
  exportGsi$ = this.exportGsiSubject.asObservable();

  private exportGsiAppSubject = new Subject();
  exportGsiApp$ = this.exportGsiAppSubject.asObservable();

  importGsiSubject = new Subject();
  importGsi$ = this.importGsiSubject.asObservable();

  public rolesSubject = new Subject();
  roles$ = this.rolesSubject.asObservable();
  usersSubject = new Subject();
  usersSubject$ = this.usersSubject.asObservable();

  private orgUnitsSubject = new Subject();
  orgUnits$ = this.orgUnitsSubject.asObservable();
  usersInOrgUnitSubject = new Subject();
  usersInOrgUnitSubject$ = this.usersInOrgUnitSubject.asObservable();

  private allrolesInOrgUnitData = new BehaviorSubject(this.gsiData);
  allrolesInOrgUnit$ = this.allrolesInOrgUnitData.asObservable();

  private allrolesData = new BehaviorSubject(this.gsiData);
  allroles$ = this.allrolesData.asObservable();

  tempGsiObj = new BehaviorSubject('');
  tempGsiObj$ = this.tempGsiObj.asObservable();

  dcd = new Subject();
  dcd$ = this.dcd.asObservable();

  private gsiName = new BehaviorSubject('');
  gsiName$ = this.gsiName.asObservable();

  exportedDetails = new BehaviorSubject('');
  exportedDetails$ = this.exportedDetails.asObservable();

  private bookSubject = new Subject();
  book$ = this.bookSubject.asObservable();

  private bookSaveSubject = new Subject();
  bookSave$ = this.bookSaveSubject.asObservable();

  rolesCount = new Subject();
  rolesCount$ = this.rolesCount.asObservable();

  private gsiCount = new Subject();
  gsiCount$ = this.gsiCount.asObservable();

  private booksCount = new Subject();
  booksCount$ = this.booksCount.asObservable();

  gsiSavedSubject = new Subject();
  gsiSavedInBook$ = this.gsiSavedSubject.asObservable();

  private gsiSaveId = new Subject();
  gsiSaveId$ = this.gsiSaveId.asObservable();

  private draftGSISaved = new BehaviorSubject({});
  draftGSISaved$ = this.draftGSISaved.asObservable();

  // private draftEditGSI = new Subject();
  // draftEditGSI$ = this.draftEditGSI.asObservable();

  private draftApproveReject = new Subject();
  draftApproveReject$ = this.draftApproveReject.asObservable();

  // private saveDraftGSI = new Subject();
  // saveDraftGSI$ = this.saveDraftGSI.asObservable();

  private suggestedGsiData = new Subject();
  suggestedGsiData$ = this.suggestedGsiData.asObservable();

  private stepIndex = new Subject();
  stepIndex$ = this.stepIndex.asObservable();

  draftedCuData = new Subject();
  draftedCuData$ = this.draftedCuData.asObservable();

  openSelectedCU = new Subject();
  openSelectedCU$ = this.openSelectedCU.asObservable();

  selectedCuForLibrary = new Subject();
  selectedCuForLibrary$ = this.selectedCuForLibrary.asObservable();
  versionResponse: any;
  draftId: any;

  // private gsiPublishFailed = new Subject();
  // gsiPublishFail$ = this.gsiPublishFailed.asObservable();

  private gsiNameIdSubject = new BehaviorSubject(null);
  gsiNameId$ = this.gsiNameIdSubject.asObservable();

  private updateGSIData = new Subject();
  updateGSIData$ = this.updateGSIData.asObservable();

  private fileValidation = new BehaviorSubject('');
  fileValidation$ = this.fileValidation.asObservable();

  //To save data of Conditional potentiality in gsi
  private updateCPInGSIData = new Subject();
  updateCPInGSIData$ = this.updateCPInGSIData.asObservable();

  private getBookList = new Subject();
  getBookList$ = this.getBookList.asObservable();

  getEntityList = new Subject();
  getEntityList$ = this.getEntityList.asObservable();

  getEntityListWithRole = new Subject();
  getEntityListWithRole$ = this.getEntityListWithRole.asObservable();

  getEntityCardData = new Subject();
  getEntityCardData$ = this.getEntityCardData.asObservable();

  getEntityData = new Subject();
  getEntityData$ = this.getEntityData.asObservable();

  bpmnDiagramData$ = new BehaviorSubject(null);

  private updatedCUListFromHoverComponent = new Subject();
  updatedCUListFromHoverComponent$ = this.updatedCUListFromHoverComponent.asObservable();

  getSelectCUData = new Subject();
  getSelectedCUData$ = this.getSelectCUData.asObservable();

  /** Observable to Hit Draft GSI call from Change Unit Component */

  private saveDraftGSIinCU = new Subject();
  draftGSIinCU$ = this.saveDraftGSIinCU.asObservable();

  /** Stream Declaration for Passing BookID for B2c Scenario */

  private b2cHeaderSet = new Subject();
  b2csettingHeader$ = this.b2cHeaderSet.asObservable();

  public saveCcd = new Subject();
  saveCcd$ = this.saveCcd.asObservable();

  public alternateCu = new Subject();
  alternateCu$ = this.alternateCu.asObservable();

  toggleParagraph = new Subject();
  toggleParagraph$ = this.toggleParagraph.asObservable();
  lastSavedEntityatGSI = new BehaviorSubject('');
  lastSavedEntityatGSI$ = this.lastSavedEntityatGSI.asObservable();

  currentLayerIndexSubject = new BehaviorSubject(null);
  currentLayerIndex$ = this.currentLayerIndexSubject.asObservable();

  private saveGsiAsDraftSubject = new Subject();
  saveGsiAsDraft$ = this.saveGsiAsDraftSubject.asObservable();

  private draftCuStatus = new Subject();
  draftCuStatus$ = this.draftCuStatus.asObservable();

  getCuDetailsByid = new Subject();
  getCuDetailsByid$ = this.getCuDetailsByid.asObservable();
  publishEditgsi = new Subject();
  publishEditgsi$ = this.publishEditgsi.asObservable();

  publishGsiById = new Subject();
  publishGsiById$ = this.publishGsiById.asObservable();

  private editGsiDtrtSubject = new Subject();
  editGsiDtrtSubject$ = this.editGsiDtrtSubject.asObservable();

  getIdDtrtSubject = new Subject();
  getIdDtrtSubject$ = this.getIdDtrtSubject.asObservable();

  getReviewDtrtSubject = new Subject();
  getReviewDtrt$ = this.getReviewDtrtSubject.asObservable();

  private revertChangesDtrtSubject = new Subject();
  revertChangesDtrt$ = this.revertChangesDtrtSubject.asObservable();

  private applyChangesDtrtSubject = new Subject();
  applyChangesDtrt$ = this.applyChangesDtrtSubject.asObservable();

  getReviewMileStoneDtrtSubject = new Subject();
  getReviewMileStoneDtrt$ = this.getReviewMileStoneDtrtSubject.asObservable();

  private revertMileStoneDtrtSubject = new Subject();
  revertMileStoneDtrt$ = this.revertMileStoneDtrtSubject.asObservable();

  private applyMileStoneDtrtSubject = new Subject();
  applyMileStoneDtrt$ = this.applyMileStoneDtrtSubject.asObservable();

  fetchDtrtIRDRSubject = new Subject();
  fetchDtrtIRDRSubject$ = this.fetchDtrtIRDRSubject.asObservable();

  getReviewDtrtIRDRSubject = new Subject();
  getReviewDtrtIRDRSubject$ = this.getReviewDtrtIRDRSubject.asObservable();

  revertChangesDtrtIRDRSubject = new Subject();
  revertChangesDtrtIRDRSubject$ = this.revertChangesDtrtIRDRSubject.asObservable();

  applyChangesDtrtIRDRSubject = new Subject();
  applyChangesDtrtIRDRSubject$ = this.applyChangesDtrtIRDRSubject.asObservable();

  public getExceptionCategoryList = new Subject();
  getExceptionCategoryList$ = this.getExceptionCategoryList.asObservable();

  // GSI List for RTCT Dashboard  // FE757
  private gsiDataForRTCT = new Subject();
  gsiDataForRTCT$ = this.gsiDataForRTCT.asObservable();

  /* For Milestone - START ------------------ */
  public showmilestone = new Subject();
  showmilestone$ = this.showmilestone.asObservable();

  public saveMilestoneSubject = new Subject();
  saveMilestoneSubject$ = this.saveMilestoneSubject.asObservable();

  public editMilestoneSubject = new Subject();
  editMilestoneSubject$ = this.editMilestoneSubject.asObservable();

  public deleteMilestoneSubject = new Subject();
  deleteMilestoneSubject$ = this.deleteMilestoneSubject.asObservable();

  public getAllMilestoneSubject = new Subject();
  getAllMilestoneSubject$ = this.getAllMilestoneSubject.asObservable();
  /* For Milestone - END ------------------- */

  private sendBookDataForExtraction = new BehaviorSubject(null);
  sendBookDataForExtraction$ = this.sendBookDataForExtraction.asObservable();

  // milestone transaction
  gsiDataForMilestone = new Subject();
  gsiDataForMilestone$ = this.gsiDataForMilestone.asObservable();

  milestoneList = new Subject();
  milestoneList$ = this.milestoneList.asObservable();

  percentmilestoneList = new Subject();
  percentmilestoneList$ = this.percentmilestoneList.asObservable();

  particularMilestone = new Subject();
  particularMilestone$ = this.particularMilestone.asObservable();

  notifyMilestoneSubject = new Subject();
  notifyMilestone$ = this.notifyMilestoneSubject.asObservable();

  private subtransactionalDCD = new BehaviorSubject('');
  subtransactionalDCD$ = this.subtransactionalDCD.asObservable();

  private scheduledjobsData = new BehaviorSubject('');
  scheduledjobsData$ = this.scheduledjobsData.asObservable();

  private savedScheduledjobData = new BehaviorSubject('');
  savedScheduledjobData$ = this.savedScheduledjobData.asObservable();

  private deleteScheduleJob = new BehaviorSubject('');
  deleteScheduleJob$ = this.deleteScheduleJob.asObservable();

  public lastCuid = new Subject();
  lastCuid$ = this.lastCuid.asObservable();

  private validateGsiFileSubject = new Subject();
  validateGsiFile$ = this.validateGsiFileSubject.asObservable();

  importSolutionFile = new Subject();
  importSolutionFile$ = this.importSolutionFile.asObservable();

  bookIDb2c: any;

  adminSolutions: any;
  tempGSIId: any;
  currentCuEventsData: any;
  constructor(
    private gsiEndPointService: GsiEndpointService,
    private gsiSToreService: GsiStoreService,
    private alertService: AlertService,
    private loader: LoaderService,
    private router: Router,
    private uploadEndpointService: UploadEndpointService,
    private solutionDictionaryFacade: SolutionDictionaryFacadeService
  ) {
    this.gsiSToreService.stateChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((state) => {
      if (state) {
        this.gsiSubject.next(state.gsiList);
      }
    });
  }

  addNestedCu() {
    this.gsiEndPointService.addNestedCu().subscribe((res: any) => {
      if (res) {
        this.loader.hide();
        this.alertService.showToaster(res.message, '', 'success');
        let cus: any = [];
        /* istanbul ignore next */
        res?.result?.solutionLogic.forEach((cu: any) => {
          cus.push(cu.DATA);
        });
        res.result.solutionLogic = cus;
      }
    });
  }

  addSeqCu(gsiId: number, index: number, body: any) {
    this.loader.show();
    this.gsiEndPointService.addSeqCu(gsiId, index, body).subscribe((res: any) => {
      if (res) {
        this.loader.hide();
        this.alertService.showToaster(res.message, '', 'success');
        let cus: any = [];
        /* istanbul ignore next */
        res?.result?.solutionLogic.forEach((cu: any) => {
          cus.push(cu.DATA);
        });
        res.result.solutionLogic = cus;
      }
    });
  }

  getAllGsis(pagenum: number, pageSize: number, searchCriteria: string, isPublished: boolean, isTXNRights: boolean) {
    this.loader.show();
    this.gsiEndPointService
      .getAllGsis(pagenum + 1, pageSize, searchCriteria, isPublished, isTXNRights)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        const gsiList = gsiListMapper(res.result);
        this.gsiSToreService.setStateData(gsiList);
      });
  }

  // Fetch GSI list for RTCT Dashboard  // FE757
  /* istanbul ignore next */
  getGsiListForRTCT(pagenum: number, pageSize: number, searchCriteria: string) {
    this.loader.show();
    this.gsiEndPointService
      .getAllGsis(pagenum + 1, pageSize, searchCriteria, false, false)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        const gsiList = gsiListMapper(res.result);
        this.gsiDataForRTCT.next(gsiList);
      });
  }

  // GSI List for Milestone Dashboard
  getGsiListForMilestone(
    pagenum: number,
    pageSize: number,
    searchCriteria: string,
    isPublished: boolean,
    isTXNRights: boolean
  ) {
    this.loader.show();
    this.gsiEndPointService
      .getAllGsis(pagenum + 1, pageSize, searchCriteria, true, true)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.gsiDataForMilestone.next(res.result);
      });
  }

  getMilestoneList(id: any) {
    this.loader.show();
    this.gsiEndPointService
      .getMilestoneList(id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.milestoneList.next(res);
      });
  }

  getpercentMilestoneList(id: any) {
    this.loader.show();
    this.gsiEndPointService
      .getpercentMilestoneList(id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.percentmilestoneList.next(res);
      });
  }

  getParticularMilestone(gsiId: number, milestoneid: number) {
    this.loader.show();
    this.gsiEndPointService
      .getParticularMilestone(gsiId, milestoneid)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.particularMilestone.next(res);
      });
  }

  notifyMilestone(element: any) {
    this.loader.show();
    this.gsiEndPointService
      .notifyMilestone(element)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        console.log(JSON.parse(res.result.body));
        let response = JSON.parse(res.result.body);
        this.alertService.showToaster(response.message, '', 'success');

        this.notifyMilestoneSubject.next(res);
      });
  }

  getGsiSuggestions(pagenum: number, pageSize: number, searchCriteria: string, isPublished: boolean) {
    this.loader.show();
    this.gsiEndPointService
      .getAllGsis(pagenum, pageSize, searchCriteria, isPublished, false)
      .pipe(debounceTime(1000), takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        const gsiList = gsiListMapper(res.result);
        this.suggestedGsiData.next(gsiList);
      });
  }

  // getAllDraftGsis(pagenum: number, pageSize: number, searchCriteria: string) {
  //   this.loader.show();
  //   this.gsiEndPointService
  //     .getAllDraftGSIs(pagenum, pageSize, searchCriteria)
  //     .pipe(takeUntil(this.ngUnsubscribe))
  //     .subscribe((res) => {
  //       this.loader.hide();
  //       this.gsiSToreService.setStateData(res);
  //     });
  // }

  getAllRoles(searchCriteria: string) {
    this.loader.show();
    this.gsiEndPointService
      .getAllRoles(searchCriteria)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.loader.hide();
        this.rolesSubject.next({ content: res });
      });
  }
  getAllUsers(id: any) {
    this.loader.show();
    if (id) {
      this.gsiEndPointService
        .getAllUsers(id)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res) => {
          this.loader.hide();
          this.usersSubject.next(res);
        });
    }
  }

  getAllOrgUnits(searchCriteria: string) {
    this.loader.show();
    this.gsiEndPointService
      .getAllOrgUnits(searchCriteria)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.loader.hide();
        this.orgUnitsSubject.next({ content: res });
      });
  }
  getAllUsersInOrgUnit(id: any) {
    this.loader.show();
    if (id) {
      this.gsiEndPointService
        .getAllUsersInOrgUnit(id)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res) => {
          this.loader.hide();
          this.usersInOrgUnitSubject.next(res);
        });
    }
  }

  getROlesCount() {
    this.loader.show();
    this.gsiEndPointService
      .getAllRoles('')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.rolesCount.next(res.length);
      });
  }

  getRoleNames() {
    this.loader.show();
    this.gsiEndPointService
      .getAllRoles('')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.loader.hide();
        this.allrolesData.next(res);
      });
  }

  getRoleInOrgUnitNames() {
    this.loader.show();
    this.gsiEndPointService
      .getAllRoles('')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.loader.hide();
        this.allrolesInOrgUnitData.next(res);
      });
  }

  getAllBooks(pageNo: number, pageSize: number, searchCriteria: string) {
    return this.gsiEndPointService.getAllBooks(pageNo + 1, pageSize, searchCriteria);
  }

  dashBoardgetAllBooks(pageNo: number, pageSize: number, searchCriteria: string) {
    return this.gsiEndPointService.dashBoardgetAllBooks(pageNo, pageSize, searchCriteria);
  }

  // getBooksCount(pageSize: number, searchCriteria: string) {
  //   this.loader.show();
  //   return this.gsiEndPointService
  //     .getBooksCount(0, pageSize, searchCriteria)
  //     .pipe(takeUntil(this.ngUnsubscribe))
  //     .subscribe((res: any) => {
  //       this.loader.hide();
  //       this.booksCount.next(res);
  //     });
  // }

  saveGsi(body: any, type?: any, bookId?: number) {
    this.loader.show();
    let gsiData = gsiDeMapper(body);
    this.gsiEndPointService
      .saveGsi(gsiData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          console.log(res);
          if (res) {
            /* istanbul ignore next */
            res.result.source = gsiData?.source; //source added to GSI while saving it
            this.loader.hide();
            this.alertService.showToaster(res.message, '', 'success');
            /* istanbul ignore next */
            const gsiRes = gsiMapper(res?.result);
            this.updateGSIObject(gsiRes);
          }
        },
        (error) => {
          console.log('err', error);
          const gsiRes = gsiMapper(gsiData);
          this.updateGSIObject(gsiRes);
          // const { err } = error;
          // this.loader.hide();
          // // this.gsiPublishFailed.next();
          // if (err) {
          //   this.alertService.showToaster(`${err}`, '', 'error');
          // }
          /* istanbul ignore next */
          this.alertService.showToaster(error?.error?.message, '', 'error');
        }
      );
  }
  //to save conditional potemtiality in gsi
  saveCPInGsi(body: any) {
    this.loader.show();
    this.gsiEndPointService
      .saveCPInGsi(body)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          console.log(res);
          if (res) {
            /* istanbul ignore next */
            res.result.source = body?.source;
            this.loader.hide();
            this.alertService.showToaster(res.message, '', 'success');
            /* istanbul ignore next */
            const gsiRes = gsiMapper(res?.result);
            this.updateCPInGSIData.next(gsiRes);
          }
        },
        (err: any) => {
          /* istanbul ignore else */
          if (err.status === 400) {
            this.updateCPInGSIData.next(err);
            this.alertService.showToaster(err.message, '', 'error');
          }
        }
      );
  }

  updateGSIObject(gsiData: any) {
    this.updateGSIData.next(gsiData);
    /* istanbul ignore else */
    if (gsiData?.solutionLogic) {
      this.lastCuid.next(gsiData.solutionLogic[gsiData.solutionLogic.length - 1]?.id);
    }
  }

  updateTempGsiObject(body: any) {
    this.tempGsiObj.next(body);
  }

  // //gets data of impacted bet
  loadSingleLevelDetailsById(id: any, type: string) {
    /* istanbul ignore next */
    if (id && type) {
      return this.gsiEndPointService.loadSingleLevelDetailsById(id, type);
    }
  }

  addNewDcd(dcd: any) {
    this.dcd.next(dcd);
  }
  addGsiToDcd(gsi: any) {
    this.gsiName.next(gsi);
  }

  exportData(data: any) {
    this.exportedDetails.next(data);
  }

  saveBook(book: ChangeUnit, saveCpInBook?: any) {
    this.loader.show();
    this.gsiEndPointService
      .saveBook(book, saveCpInBook)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          // res.result.source=(book?.source)?book.source:res.result?.source;
          this.sendBookDataForExtraction.next(res.result);
          this.loader.hide();
          this.bookSaveSubject.next(res.result);
          this.alertService.showToaster(res.message, '', 'success');
        },
        (err: any) => {
          /* istanbul ignore next */
          if (err.status === 400) {
            this.bookSaveSubject.next(err);
            this.alertService.showToaster(err.message, '', 'error');
          }
        }
      );
  }

  getBookDetailsById(bookId: any, gsiComponent?: boolean) {
    sessionStorage.setItem('bookData', JSON.stringify({ id: bookId, isFromLibraryBook: true }));
    this.bookIDb2c = bookId;
    this.gsiEndPointService
      .getBookDetails(bookId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          if (gsiComponent) {
            this.bookSubject.next(res.result);
          } else {
            this.bookSaveSubject.next(res.result);
          }
        },
        (err: any) => {
          /* istanbul ignore next */
          if (err.status === 400) {
            this.bookSaveSubject.next(err);
          }
        }
      );
  }

  lastesavedEntityResponse(res: any) {
    this.lastSavedEntityatGSI.next(res);
  }
  resetBook() {
    this.bookSaveSubject.next(null);
  }

  saveGsiInBook(bookId: number, gsi: Gsi) {
    this.loader.show();
    this.gsiEndPointService
      .saveGsiInBook(bookId, gsi)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.gsiSavedSubject.next(res);
      });
  }
  //post method for cu.
  editGsiDtrt(data: any) {
    this.loader.show();
    this.gsiEndPointService
      .editGsiDtrt(data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.editGsiDtrtSubject.next(res);
        if (res.status === 200) this.alertService.showToaster(res.message, '', 'success');
        else this.alertService.showToaster(res.message, '', 'error');
      });
  }

  getIdDtrt(data: any) {
    this.loader.show();
    this.gsiEndPointService
      .getIdDtrt(data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        // res.comp = value;
        this.getIdDtrtSubject.next(res);
        if (res.status === 200) this.alertService.showToaster(res.message, '', 'success');
        else this.alertService.showToaster(res.message, '', 'error');
      });
  }

  getReviewOfDtrt() {
    this.loader.show();
    this.gsiEndPointService
      .getReviewOfDtrt()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.getReviewDtrtSubject.next(res);
        if (res.status === 200) this.alertService.showToaster(res.message, '', 'success');
        else this.alertService.showToaster(res.message, '', 'error');
      });
  }

  getRevertChangesDtrt() {
    this.loader.show();
    this.gsiEndPointService
      .getRevertChangesDtrt()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.revertChangesDtrtSubject.next(res);
        if (res.status === 200) this.alertService.showToaster(res.message, '', 'success');
        else this.alertService.showToaster(res.message, '', 'error');
      });
  }

  getApplyChangesDtrt() {
    this.loader.show();
    this.gsiEndPointService
      .getApplyChangesDtrt()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.applyChangesDtrtSubject.next(res);
        if (res.status === 200) this.alertService.showToaster(res.message, '', 'success');
        else this.alertService.showToaster(res.message, '', 'error');
      });
  }

  getReviewMileStoneDtrt() {
    this.loader.show();
    this.gsiEndPointService
      .getReviewMileStoneDtrt()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.getReviewMileStoneDtrtSubject.next(res);
        if (res.status === 200) this.alertService.showToaster(res.message, '', 'success');
        else this.alertService.showToaster(res.message, '', 'error');
      });
  }

  getRevertMileStoneDtrt() {
    this.loader.show();
    this.gsiEndPointService
      .getRevertMileStoneDtrt()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.revertMileStoneDtrtSubject.next(res);
        if (res.status === 200) this.alertService.showToaster(res.message, '', 'success');
        else this.alertService.showToaster(res.message, '', 'error');
      });
  }

  getApplyMileStoneDtrt() {
    this.loader.show();
    this.gsiEndPointService
      .getApplyMileStoneDtrt()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.applyMileStoneDtrtSubject.next(res);
        if (res.status === 200) this.alertService.showToaster(res.message, '', 'success');
        else this.alertService.showToaster(res.message, '', 'error');
      });
  }

  fetchDtrtIRDR(id: any) {
    this.loader.show();
    this.gsiEndPointService
      .fetchDtrtIRDR(id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.fetchDtrtIRDRSubject.next(res);
        if (res.status === 200) this.alertService.showToaster(res.message, '', 'success');
        else this.alertService.showToaster(res.message, '', 'error');
      });
  }

  getReviewDtrtIRDR() {
    this.loader.show();
    this.gsiEndPointService
      .getReviewDtrtIRDR()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.getReviewDtrtIRDRSubject.next(res);
        if (res.status === 200) this.alertService.showToaster(res.message, '', 'success');
        else this.alertService.showToaster(res.message, '', 'error');
      });
  }

  revertChangesDtrtIRDR() {
    this.loader.show();
    this.gsiEndPointService
      .revertChangesDtrtIRDR()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.revertChangesDtrtIRDRSubject.next(res);
        if (res.status === 200) this.alertService.showToaster(res.message, '', 'success');
        else this.alertService.showToaster(res.message, '', 'error');
      });
  }

  applyChangesDtrtIRDR() {
    this.loader.show();
    this.gsiEndPointService
      .applyChangesDtrtIRDR()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.applyChangesDtrtIRDRSubject.next(res);
        if (res.status === 200) this.alertService.showToaster(res.message, '', 'success');
        else this.alertService.showToaster(res.message, '', 'error');
      });
  }

  getReviewDtrtReservedAction() {
    this.loader.show();
    this.gsiEndPointService
      .getReviewDtrtReservedAction()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.getReviewDtrtSubject.next(res);
        if (res.status === 200) this.alertService.showToaster(res.message, '', 'success');
        else this.alertService.showToaster(res.message, '', 'error');
      });
  }

  getRevertChangesDtrtReservedAction() {
    this.loader.show();
    this.gsiEndPointService
      .getRevertChangesDtrtReservedAction()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.revertChangesDtrtSubject.next(res);
        if (res.status === 200) this.alertService.showToaster(res.message, '', 'success');
        else this.alertService.showToaster(res.message, '', 'error');
      });
  }

  getBookGsis(bookId: any, pageSize: number, pageNumber: number, search?: string, isFromLibraryBook?: boolean) {
    this.loader.show();
    this.gsiEndPointService
      .getBookGsis(bookId, pageSize, pageNumber, search, isFromLibraryBook)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        /* istanbul ignore next */
        if (res && res.content && res.content.length && res?.content[0] === 'no record found') {
          this.alertService.showToaster(res?.content[0], '', 'error');
        }
        this.gsiSubject.next(res);
      });
  }
  getBookDesign(bookId: any) {
    this.loader.show();
    this.gsiEndPointService
      .getBookDesign(bookId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        /* istanbul ignore next */
        if (res && res.content && res.content?.length && res?.content[0] === 'no record found') {
          this.alertService.showToaster(res?.content[0], '', 'error');
        }
        this.bookDesign.next(res);
      });
  }
  updateGSI(id: any, index: any, body: any) {
    this.loader.show();
    this.gsiEndPointService
      .updateGSI(id, index, body)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
      });
  }

  selectedBook(book: any) {
    /* istanbul ignore next */
    this.getBookDetailsById(book?.id, true);
    // this.bookSubject.next(book);
  }
  deleteCuFromGsi(gsiId: any, cuId: any) {
    this.loader.show();
    this.gsiEndPointService
      .deleteCuFromGsi(gsiId, cuId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
      });
  }
  updateGsiRights(gsi: any, body: any) {
    this.loader.show();
    this.gsiEndPointService.updateGsiRights(gsi, body).subscribe((res: any) => {
      this.loader.hide();
      if (res) this.alertService.showToaster(res.message, '', 'success');
    });
  }

  // Service API Calls for Versioning Draft API Calls //

  saveDraftVersion(body: any, type: any, bookId: number) {
    this.loader.show();
    this.gsiEndPointService
      .saveDraftVersion(body, type)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        if (res) {
          let resObj = { ...res.result, type: type };
          this.draftGSISaved.next(resObj);
          if (type == 'publish') {
            if (bookId !== null) {
              this.loader.hide();
              this.alertService.showToaster(res.message, '', 'success');
              this.saveGsiInBook(bookId, res.result);
            }
            this.router.navigate(['/cdui/mylibrary-dashboard']);
          } else if (type == 'finish') {
            this.alertService.showToaster(res.message, '', 'success');
          }
        }
      });
  }

  // saveDraftEditVersion(body: any, type: any) {
  //   this.loader.show();
  //   this.gsiEndPointService
  //     .saveDraftEditVersion(body, type)
  //     .pipe(takeUntil(this.ngUnsubscribe))
  //     .subscribe(
  //       (res: any) => {
  //         if (res) {
  //           this.loader.hide();
  //           if (res.status === 400) {
  //             this.alertService.showToaster(res.message, '', 'error');
  //           } else {
  //             this.alertService.showToaster(res.message, '', 'success');
  //           }

  //           this.draftEditGSI.next(res.result);
  //         } else {
  //           console.log(res);
  //           this.alertService.showToaster(res.message, '', 'error');
  //           this.loader.hide();
  //         }
  //       },
  //       (err: any) => {
  //         console.log(err);
  //       }
  //     );
  // }

  // Show milestone based on show value
  setMilestone(show: boolean) {
    this.showmilestone.next(show);
  }

  // Save a milestone
  saveMilestone(payload: any) {
    this.loader.show();
    this.gsiEndPointService
      .saveMilestone(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.saveMilestoneSubject.next(res);
      });
  }

  // Edit a milestone
  editMilestone(payload: any) {
    this.loader.show();
    this.gsiEndPointService
      .editMilestone(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.editMilestoneSubject.next(res);
      });
  }

  // Delete a milestone based on milestone Id
  deleteMilestone(id: any) {
    this.loader.show();
    this.gsiEndPointService
      .deleteMilestone(id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.deleteMilestoneSubject.next(res);
      });
  }

  // Fetch all milestones based on gsi and tenant Id
  getAllMilestone(gsiId: any) {
    this.loader.show();
    this.gsiEndPointService
      .getAllMilestone(gsiId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.getAllMilestoneSubject.next(res);
      });
  }

  saveonAppoveReject(body: any, type: any) {
    this.loader.show();
    this.gsiEndPointService
      .saveApproveReject(body, type)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        if (res) {
          this.loader.hide();
          this.draftApproveReject.next(res.result);
        }
      });
  }

  // saveDraftedGSI(body: any, type: any) {
  //   this.loader.show();
  //   if (body.name && body.name !== '') {
  //     this.gsiEndPointService
  //       .saveDraftGSI(body)
  //       .pipe(takeUntil(this.ngUnsubscribe))
  //       .subscribe(
  //         (res: any) => {
  //           if (res) {
  //             if (res.status === 400) {
  //               res.result.hideAgentStep = true;
  //             } else {
  //               res.result.hideAgentStep = false;
  //             }
  //             this.loader.hide();
  //             this.alertService.showToaster(res.message, '', 'success');
  //             this.saveDraftGSI.next(res.result);
  //           }
  //         },
  //         (error) => {
  //           // this.alertService.showToaster(error.message, '', 'error');
  //           //this.saveDraftGSI.next(error);
  //         }
  //       );
  //   } else {
  //     this.loader.hide();
  //   }
  // }

  exportGsis(gsiId: number) {
    this.loader.show();
    this.gsiEndPointService
      .exportGsis(gsiId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        if (res) {
          this.loader.hide();
          this.exportGsiSubject.next(res);
        }
      });
  }

  exportGsiApp(data: any) {
    return this.gsiEndPointService.exportGsiApp(data);
  }
  exportApplicationToEnv(data: any, envType: string) {
    this.loader.show();
    this.gsiEndPointService
      .exportApplicationToEnv(data, envType)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore else */
        if (res) {
          this.loader.hide();
          console.log(res);
          this.alertService.showToaster(res.message, '', res.status == 200 ? 'success' : 'error');
        }
      });
  }
  exportHistory(searchText: any, pageSize: number, pageNumber: number) {
    return this.gsiEndPointService.exportHistory(searchText, pageSize, pageNumber);
  }

  importGSIs(reqPayload: any) {
    this.loader.show();
    this.gsiEndPointService
      .importGSIs(reqPayload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        if (res.status !== 417) {
          console.log(res);
          this.importGsiSubject.next(res);
          this.alertService.showToaster(res.message, '', 'success');
        } else {
          this.alertService.showToaster(res.message, '', 'error');
        }
      });
  }

  validateGsiFile(reqPayload: any) {
    this.loader.show();
    this.gsiEndPointService
      .validateGsiFile(reqPayload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        if (res?.status == 200) {
          this.validateGsiFileSubject.next(res?.result);
          this.solutionDictionaryFacade.getGSIImportDetails(res?.result);
          this.alertService.showToaster(res.message, '', 'success');
        } else {
          this.alertService.showToaster(res.message, '', 'error');
        }
      });
  }

  getAdminSolutions(type: string, labels: any, isCarnvial?: boolean) {
    const activeUserFlag = localStorage.getItem('ActiveUserFlag');
    this.adminSolutions = [
      {
        type: 'manage_roles',
        solutions: [
          {
            name: labels.View_Roles,
            url: 'admin/roles',
          },
          {
            name: labels.Add_Role,
            url: 'admin/addrole',
          },
          {
            name: labels.Update_Role,
            url: 'admin/updaterole',
          },
          {
            name: labels.Delete_Role,
            url: 'admin/deleterole',
          },
          {
            name: labels.Import_Role,
            url: 'admin/importrole',
          },
        ],
      },
      {
        type: 'manage_mcc',
        solutions: [
          {
            name: 'MCC Customisation',
            url: 'admin/customisemcc',
          },
          // {
          //   name: 'Customise Theme',
          //   url: 'tenant',
          // },
        ],
      },

      {
        type: 'manage_organisation_unit',
        solutions: [
          {
            name: labels.View_Organisation_Unit,
            url: 'admin/manageorganisationunit/vieworganisationUnit',
          },
          {
            name: labels.Add_Organisation_Unit,
            url: 'admin/manageorganisationunit/addorganisationUnit',
          },
          {
            name: labels.Update_Organisation_Unit,
            url: 'admin/manageorganisationunit/updateorganisationUnit',
          },
        ],
      },
      {
        type: 'manage_users',
        solutions: [
          {
            name: labels.View_Users,
            url: 'admin/manage-users/viewuser',
          },
          {
            name: labels.Add_User,
            url: 'admin/manage-users/adduser',
          },
          {
            name: labels.Update_User,
            url: 'admin/manage-users/updateuser',
          },
          {
            name: labels.Delete_User,
            url: 'admin/manage-users/deleteuser',
          },
          {
            name: labels.Import_User,
            url: 'admin/manage-users/importuser',
          },
          {
            name: labels.update_users_under_manager,
            url: 'admin/manage-users/updatemanager',
          },
        ],
      },
      {
        type: 'manage_tenants',
        solutions: [
          {
            name: labels.View_Tenants,
            url: 'admin/manage-tenants/viewtenant',
          },
          {
            name: labels.Add_Tenant,
            url: 'admin/manage-tenants/addtenant',
          },
          {
            name: labels.Update_Tenant,
            url: 'admin/manage-tenants/updatetenant',
          },
        ],
      },
      {
        type: 'translation_config',
        solutions: [
          {
            name: labels.Add_Label,
            url: 'admin/addlabel',
          },
          {
            name: labels.Update_Label,
            url: 'admin/updatelabel',
          },
        ],
      },
      {
        type: 'manage_tenants_selfOnboard',
        solutions: [
          {
            name: labels.View_Tenants,
            url: 'admin/selfOnboardTenant',
          },
          {
            name: labels.Add_Tenant,
            url: 'admin/addSelfOnboardTenant',
          },
          {
            name: labels.Update_Tenant,
            url: 'admin/updateSelfOnboardTenant',
          },
        ],
      },
      {
        type: 'manage_configration',
        solutions: [
          {
            name: labels.Translation_Enhancement,
            url: 'admin/translationenhancement',
          },
        ],
      },
      {
        type: 'gsi_blocking',
        solutions: [
          {
            name: labels.Add_Configuration,
            url: 'admin/gsiblocking/addconfigurations',
          },
          {
            name: labels.View_Configuration,
            url: 'admin/gsiblocking/viewconfigurations',
          },
        ],
      },
      {
        type: 'manage_subscriptions',
        solutions: [
          {
            type: 'manage_features_plans',
            features: [
              {
                name: labels.View_Features,
                url: 'admin/features/viewfeature',
              },
              {
                name: labels.Add_Feature,
                url: 'admin/features/addfeature',
              },
              {
                name: labels.Update_Feature,
                url: 'admin/features/updatefeature',
              },
              {
                name: 'Delete Feature',
                url: 'admin/features/deletefeature',
              },
            ],
            plans: [
              {
                name: labels.View_Plans,
                url: 'admin/plans/viewplan',
              },
              {
                name: labels.Add_Plan,
                url: 'admin/plans/addplan',
              },
              {
                name: labels.Update_Plan,
                url: 'admin/plans/updateplan',
              },
              {
                name: labels.Delete_Plan,
                url: 'admin/plans/deleteplan',
              },
            ],
          },
        ],
      },
      {
        type: 'manage_saasapplications',
        solutions: [
          {
            name: labels.View_SaasApplication,
            // name: 'View SAAS Applications',
            url: 'admin/saasapplications',
          },
          {
            name: labels.Add_SaasApplication,
            // name: 'Add SAAS Applications',
            url: 'admin/addsaasapplication',
          },
        ],
      },
      {
        type: 'manage_subscriptions',
        solutions: [
          {
            type: 'manage_features_plans',
            features: [
              {
                name: labels.View_Features,
                url: 'admin/features/viewfeature',
              },
              {
                name: labels.Add_Feature,
                url: 'admin/features/addfeature',
              },
              {
                name: labels.Update_Feature,
                url: 'admin/features/updatefeature',
              },
              {
                name: labels.Delete_Feature,
                url: 'admin/features/deletefeature',
              },
            ],
            plans: [
              {
                name: labels.View_Plans,
                url: 'admin/plans/viewplan',
              },
              {
                name: labels.Add_Plan,
                url: 'admin/plans/addplan',
              },
              {
                name: labels.Update_Plan,
                url: 'admin/plans/updateplan',
              },
              {
                name: labels.Delete_Plan,
                url: 'admin/plans/deleteplan',
              },
            ],
          },
        ],
      },
      {
        type: 'manage_groups',
        solutions: [
          {
            name: labels.View_Ldap,
            url: 'admin/managegroups/groups',
          },
          // {
          //   name: labels.Add_Group,
          //   url: 'admin/managegroups/addgroup',
          // },
          // {
          //   name: labels.Update_Group,
          //   url: 'admin/managegroups/updategroup',
          // },
        ],
      },
      {
        type: 'manage_id_providers',
        solutions: [
          {
            name: labels.Add_LDAP_Config,
            url: 'admin/addldapconfig',
          },
          {
            name: labels.View_LDAP_Config,
            url: 'admin/viewldapconfig',
          },
        ],
      },
      {
        type: 'manage_data',
        solutions: [
          {
            name: labels.Import,
            url: 'admin/managedata/importentity',
          },
          {
            name: labels.Export,
            url: 'admin/managedata/exportentity',
          },
          {
            name: 'View Import/Export Job Processes',
            url: 'admin/managedata/viewimportexportjobs',
          },
          {
            name: 'Import History',
            url: 'admin/managedata/importhistory',
          },
        ],
      },
      {
        type: 'manage_solutions',
        solutions: [
          {
            name: labels.Import,
            url: 'admin/importsolution',
            isUser: false,
          },
          {
            name: labels.Export,
            url: 'admin/exportsolution',
            isUser: true,
          },
          {
            name: labels.View_Import_History,
            url: 'admin/importsolutiontable',
            isUser: false,
          },
          {
            // adding path for both files
            name: labels.Extract_Entity_from_ERD,
            url: 'admin/managedata/extractentity',
            isUser: false,
          },
          {
            name: labels.View_Extract_Entity,
            url: 'admin/managedata/extractentitytable',
            isUser: false,
          },
          {
            name: labels.View_Export_History,
            url: 'admin/exportsolutiontable',
            isUser: true,
          },
          {
            name: labels.Export_with_BPMN,
            url: 'admin/exportwithbpmn',
            isUser: true,
          },
        ],
      },
      {
        type: 'tenant_UI',
        solutions: [
          {
            name: labels.Customise_UI,
            url: 'admin/customise',
          },
        ],
      },
      {
        type: 'manage_enironments',
        solutions: [
          {
            name: labels.Manage_Environments,
            url: 'admin/manageenv',
          },
        ],
      },
      {
        type: 'delegation',
        solutions: [
          {
            name: labels.List_Delegation,
            url: 'admin/listdelegation',
          },
        ],
      },
      {
        type: 'manage_audit',
        solutions: [
          {
            name: labels.View_Audit_Log,
            url: 'admin/manageaudit',
          },
        ],
      },
      {
        type: 'manage_ge_irdr',
        solutions: [
          {
            name: labels.Manage_GE_IRDR,
            url: 'admin/managegeirdr',
          },
        ],
      },
      {
        type: 'manage_entity_relationship',
        solutions: [
          {
            name: labels?.View_Eql_Entity_Relation,
            url: 'admin/eql/vieweqlentityrelation',
          },
        ],
      },
      {
        type: 'Manage_Configurations',
        solutions: [
          {
            name:
              labels.Special_Features && labels.Scheduled_Jobs
                ? labels.Special_Features + ' ' + labels.Scheduled_Jobs
                : '',
            url: 'admin/specialfeatures',
          },
          {
            name: 'Conditional Potentiality Entity',
            url: 'admin/manageconfiguration/conditionalpotentialityentity',
          },
          {
            name: labels.Translation_Enhancement,
            url: 'admin/manageconfiguration/translationenhancement',
          },
          {
            name: labels.Configure_Bot,
            url: 'admin/manageconfiguration/configureBot',
          },
          {
            name: 'Signup Configuration',
            url: 'admin/manageconfiguration/signupConfig',
          },
        ],
      },
      {
        type: 'Authentication_Policy',
        solutions: [
          {
            name: labels.Authentication_Policy,
            url: 'admin/authenticationpolicy',
          },
        ],
      },
      {
        type: 'irdr_book',
        solutions: [
          {
            type: 'manage_irdr',
            local: [
              {
                name: labels.View_Local_Role,
                url: 'admin/viewLocalRole',
              },
              {
                name: labels.Create_Local_Role,
                url: 'admin/createLocalRole',
              },
              {
                name: labels.Update_Local_Role,
                url: 'admin/updateLocalRole',
              },
            ],
          },
        ],
      },
      {
        type: 'manage_password',
        solutions: [
          {
            name: labels.Password_Reset_Policy,
            url: 'admin/passwordResetPolicy',
          },
          {
            name: labels.Password_Limit_Policy,
            url: 'admin/limitcount',
          },
        ],
      },
      {
        type: 'permission_sets',
        solutions: [
          {
            name: 'Add Permission Set',
            url: 'admin/permissionsets/addpermissionset',
          },
          {
            name: 'View Permission Set',
            url: 'admin/permissionsets/viewpermissionset',
          },
        ],
      },
      {
        type: 'key_management',
        solutions: [
          {
            name: 'Add Key',
            url: 'admin/keymanagement/addkeymanagement',
          },
          {
            name: 'View Key',
            url: 'admin/keymanagement/viewkeymanagement',
          },
        ],
      },
      {
        type: 'Tenant_Setup_and_Customisation',
        solutions: [
          {
            name: 'Tenant Setup and Customisation',
            url: 'admin/tenantsetupandcustomisation',
          },
        ],
      },
      {
        type: 'book_ownership',
        solutions: [
          {
            name: labels.Book_Ownership,
            url: 'admin/bookownership',
          },
        ],
      },
      {
        type: 'tenant_irdr',
        solutions: [
          {
            name: labels?.IR_DR,
            url: 'admin/tenantIrdr',
          },
        ],
      },
      {
        type: 'manage_sub_tenant',
        solutions: [
          {
            name: labels?.Sub_Tenants,
            url: 'admin/manage-sub-tenant',
          },
          {
            name: labels?.Manage_Book_Sharing,
            url: 'admin/manage-book-sharing',
          },
          {
            name: labels?.Map_New_Roles,
            url: 'admin/map-new-roles',
          },
        ],
      },
    ];
    let solutions: any = [];
    isCarnvial = isCarnvial ? isCarnvial : false;
    const environmentType = localStorage.getItem('environmentType');
    const envType = localStorage.getItem('envType');
    /* istanbul ignore next */
    const env = environmentType ? environmentType : envType;
    /* istanbul ignore next */
    if (
      activeUserFlag == 'Environment_Admin' &&
      (activeUserFlag == 'Environment_Admin' || env !== 'development') &&
      !isCarnvial
    ) {
      this.adminSolutions.forEach((sol: any) => {
        if (sol.type == 'manage_users') {
          for (let i = 0; i < sol.solutions.length; i++)
            if (
              sol.solutions[i].url === 'adduser' ||
              sol.solutions[i].url === 'deleteuser' ||
              sol.solutions[i].url === 'importuser'
            ) {
              sol.solutions.splice(i, 1);
              i--;
            }
        }
      });
    }
    if (activeUserFlag == 'Environment_Admin' && env !== 'development' && !isCarnvial) {
      this.adminSolutions.forEach((sol: any) => {
        if (sol.type == 'manage_roles') {
          for (let i = 0; i < sol.solutions.length; i++)
            if (
              sol.solutions[i].url === 'addrole' ||
              sol.solutions[i].url === 'deleterole' ||
              sol.solutions[i].url === 'importrole'
            ) {
              sol.solutions.splice(i, 1);
              i--;
            }
        }
      });
    }
    /* istanbul ignore next */
    this.adminSolutions?.forEach((adminSolution: any) => {
      /* istanbul ignore next */
      if (type === 'manage_solutions' && solutions?.length == 0) {
        if (type === 'manage_solutions' && activeUserFlag === 'user') {
          /* istanbul ignore next */
          solutions = adminSolution.solutions.filter((x: any) => x?.isUser === true && x);
        } else {
          /* istanbul ignore next */
          solutions = adminSolution.solutions.filter((x: any) => x?.isUser === false && x);
        }
      } else if (adminSolution.type === type && solutions?.length == 0) {
        /* istanbul ignore next */
        solutions = adminSolution.solutions;
      }
    });
    return of(solutions);
  }

  screenIndex(position: any) {
    this.stepIndex.next(position);
  }
  saveDraftCu(cu: any, cuSave?: boolean) {
    if (cu && cu.name) {
      this.loader.show();
      const cuInfo = changeUnitDeMapper(cu);
      this.gsiEndPointService
        .saveDraftCu(cuInfo)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
          (res: any) =>
            /* istanbul ignore next */
            {
              this.loader.hide();
              const data = changeUnitMapper(res?.result);
              data.cuDraftSave = cuSave ? cuSave : false;
              data.tCUConditionalPotentiality = cu.tCUConditionalPotentiality;
              data.oldVersionId = cu.oldVersionId;
              data.tCUConditionalPotentialityNames = cu.tCUConditionalPotentialityNames;
              data.constraint = cu?.constraint;
              data.constraintName = cu?.constraintName;
              /* istanbul ignore next */
              data.slotItemProperties = cu?.slotItemProperties;
              /* istanbul ignore next */
              data.isOcr = cu?.isOcr;
              /* istanbul ignore next */
              data.layerType = cu?.layerType;
              if (cu?.eventCUList) {
                data.eventCUList = cu?.eventCUList;
              }
              this.draftedCuData.next(data);
            },
          /* istanbul ignore next */
          (error) => {
            console.log('error', error);
          }
        );
    }
  }
  saveCompositeGsi(gsiData: any, isGsiNew?: boolean) {
    this.loader.show();
    this.gsiEndPointService.saveCompositeGsi(gsiDeMapper(gsiData)).subscribe((res: any) => {
      /* istanbul ignore next */
      this.updateGSIObject({ ...gsiMapper(res?.result), isEdit: isGsiNew });
      this.loader.hide();
    });
  }

  publishEditGsi(pageNumber: number, pageSize: number, searchString: string) {
    this.loader.show();
    this.gsiEndPointService
      .publishEditGsi(pageNumber, pageSize, searchString)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        /*istanbul ignore next */
        if (res && res.content && res.content.length && res?.content[0] === 'no record found') {
          this.alertService.showToaster(res?.content[0], '', 'error');
        }
        this.publishEditgsi.next(res);
      });
  }

  publishgsibyid(id: any) {
    this.loader.show();
    this.gsiEndPointService
      .getpublishGsiById(id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        /*istanbul ignore next */
        if (res && res.content && res?.content?.length && res?.content[0] === 'no record found') {
          this.alertService.showToaster(res?.content[0], '', 'error');
        }
        this.publishGsiById.next(res);
      });
  }
  /* mat menu close on mouse over nad lease on button and menu */
  menuenter() {
    this.isMatMenuOpen = true;
  }

  menuLeave(trigger: any) {
    if (!this.enteredButton) {
      this.isMatMenuOpen = false;
      trigger.closeMenu();
    }
  }
  /*istanbul ignore next */
  menuButtonEnter(trigger: any) {
    if (this.prevButtonTrigger && this.prevButtonTrigger != trigger) {
      this.prevButtonTrigger?.closeMenu();
      this.prevButtonTrigger = trigger;
      this.isMatMenuOpen = false;
      /*istanbul ignore next */
      trigger?.openMenu();
    } else if (!this.isMatMenuOpen) {
      this.enteredButton = true;
      this.prevButtonTrigger = trigger;
      /*istanbul ignore next */
      trigger?.openMenu();
    } else {
      this.enteredButton = true;
      this.prevButtonTrigger = trigger;
    }
  }
  /*istanbul ignore next */
  menuButtonLeave(trigger: any) {
    if (this.enteredButton && !this.isMatMenuOpen) {
      trigger.closeMenu();
    }
    if (!this.isMatMenuOpen) {
      trigger.closeMenu();
    } else {
      this.enteredButton = false;
    }
  }

  exportGsiToBpmnDiagram(gsi: any) {
    this.loader.show();
    this.gsiEndPointService
      .convertGsiToJsonToXml(gsi.gsiData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.bpmnDiagramData$.next(res);
        this.router.navigate(['/', 'newsolution/bpmn-view'], { queryParams: { gsiId: gsi.gsiId } });
        this.loader.hide();
      });
  }

  resetSolution() {
    this.draftGSISaved.next('');
  }

  getAllEntitiesForRole(pagenum: any, pageSize: any, searchString: string) {
    this.loader.show();
    return this.gsiEndPointService
      .getAllEntities(pagenum, pageSize, searchString)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.getEntityList.next(res);
      });
  }

  getAllEntiteWithRole(role: any, pagenum: any, pageSize: any) {
    return this.gsiEndPointService
      .getAllEntitesWithRole(role, pagenum, pageSize)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.getEntityListWithRole.next(res);
      });
  }

  getEntityCardDetails(id: any) {
    if (id) {
      // this.loader.show();
      // this.gsiEndPointService
      //   .getEntityCardDetails(id)
      //   .pipe(takeUntil(this.ngUnsubscribe))
      //   .subscribe((res: any) => {
      //     this.loader.hide();
      //     this.getEntityCardData.next(res);
      //   });
      return this.gsiEndPointService.getEntityCardDetails(id);
    }
  }
  getEntityDataDetails(req: any, pageNum: any, pageSize: any) {
    // this.loader.show();
    return this.gsiEndPointService.getEntityDataDetails(req, pageNum, pageSize);
    // .pipe(takeUntil(this.ngUnsubscribe))
    // .subscribe((res: any) => {
    //   this.loader.hide();
    //   this.getEntityData.next(JSON.parse(JSON.stringify(res)));
    // });
  }
  getSearchRecords(req: any) {
    return this.gsiEndPointService.getSearchRecords(req);
  }

  getAllGsisForDashboard(
    pagenum: any,
    pageSize: any,
    searchCriteria: string,
    isPublished: boolean | any,
    isTXNRights: boolean,
    isDesignMode?: boolean,
    selectedWeek?: number
  ) {
    if (selectedWeek)
      return this.gsiEndPointService
        .getAllGsis(pagenum + 1, pageSize, searchCriteria, isPublished, isTXNRights, isDesignMode, selectedWeek)
        .pipe(
          map((response: any) => {
            if (response?.result?.data?.length == 0) {
              this.alertService.showToaster('No GSI Found', '', '');
            } else {
              this.alertService.showToaster(response.message, '', '');
            }
            return gsiListMapper(response.result);
          })
        );
    else
      return this.gsiEndPointService
        .getAllGsis(pagenum + 1, pageSize, searchCriteria, isPublished, isTXNRights, isDesignMode)
        .pipe(
          map((response: any) => {
            /* istanbul ignore next */
            if (response?.result?.data?.length == 0) {
              this.alertService.showToaster('No GSI Found', '', '');
            } else {
              this.alertService.showToaster(response.message, '', '');
            }
            return gsiListMapper(response.result);
          })
        );
  }

  getAllBookStatus(pagenum: number, pageSize: number, searchCriteria: string, approvalStatuses: any) {
    this.loader.show();
    this.gsiEndPointService
      .getAllBookStatus(pagenum, pageSize, searchCriteria, approvalStatuses)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.getBookList.next(res);
        console.log(res);
      });
  }

  getGsiNameId(gsi: any) {
    this.gsiNameIdSubject.next(gsi);
  }

  openSelectedCu(res: any) {
    this.openSelectedCU.next(res);
  }

  sendCuDataToLibray(res: any) {
    this.selectedCuForLibrary.next(res);
  }
  importHistory(searchText: any, pageSize: number, pageNumber: number) {
    return this.gsiEndPointService.importHistory(searchText, pageSize, pageNumber);
  }
  viewExportSolution(Id: any) {
    return this.gsiEndPointService.viewExportSolution(Id);
  }

  updateCUListByHoverComponentChanges(cuList: any) {
    this.updatedCUListFromHoverComponent.next(cuList);
  }

  getGSIDetailsById(id: any) {
    return this.gsiEndPointService.getGSIDetailsById(id);
  }

  getCuDetailsById(data: any) {
    this.loader.show();
    this.gsiEndPointService
      .getCuDetailsById(data.referencedChangeUnit)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.getCuDetailsByid.next(res);
      });
  }

  setCurrentCuEventsData(data: any) {
    this.currentCuEventsData = data;
  }

  getCurrentCuEventsData() {
    return this.currentCuEventsData;
  }

  getSelectCU(data: any) {
    this.getSelectCUData.next(data);
  }

  // save current layer index
  saveCurrentLayerIndex(index: any) {
    this.currentLayerIndexSubject.next(index);
  }

  /** Method to Subscribe the Stream to call Draft GSI in CU Component */
  draftGSIinCU() {
    this.saveDraftGSIinCU.next();
  }

  saveCcdInGSI(ccd: any) {
    this.saveCcd.next(ccd);
  }
  updateAlternateCu(cu: any) {
    this.alternateCu.next(cu);
  }

  toggleParagraphView() {
    this.toggleParagraph.next('');
  }

  // save gsi as draft
  saveGsiAsDraft(data: any) {
    this.saveGsiAsDraftSubject.next(data);
  }
  //To Fetch exception category List
  getExceptionCategory() {
    this.loader.show();
    this.gsiEndPointService
      .getExceptionCategory()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.getExceptionCategoryList.next(res);
      });
  }
  //UT of special-features-query
  getTempGsi(tempgsi: any) {
    this.tempGsiObj.next(tempgsi);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getAllGsisForTransactionVIew(
    pagenum: any,
    pageSize: any,
    searchCriteria: string,
    isPublished: boolean | any,
    isTXNRights: boolean
  ) {
    return this.gsiEndPointService.getAllGsis(pagenum + 1, pageSize, searchCriteria, isPublished, isTXNRights).pipe(
      map((response: any) => {
        return response.result;
      })
    );
  }

  /* -------------------------- Pushback - FE757 - START -------------------------- */

  // Get all pushbacks for GSI
  fetchPushbacks(data: any) {
    return this.gsiEndPointService.getPushbacks(data);
  }

  // Get all target CUs for any particular CU
  fetchTargetCUsForPushback(data: any) {
    return this.gsiEndPointService.getTargetCUsForPushbacks(data);
  }

  // Save/Update pushback
  savePushback(data: any) {
    return this.gsiEndPointService.postPushback(data);
  }

  // Delete Pushback
  removePushback(data: any) {
    return this.gsiEndPointService.deletePushback(data);
  }

  // Get CUs list for transaction side
  fetchCUsInTransForPushback(data: any) {
    return this.gsiEndPointService.getCUsInTransForPushback(data);
  }

  /* -------------------------- Pushback - FE757 - END-------------------------- */

  //save subtransaction gsi
  /* istanbul ignore next */
  saveSubtransactionalGsi(data: any) {
    this.loader.show();
    this.gsiEndPointService
      .saveSubtransactionalGSI(data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          console.log(res);
          /* istanbul ignore next */
          if (res) {
            this.loader.hide();
            this.alertService.showToaster(res.message, '', 'success');
          }
        },
        (error) => {
          /* istanbul ignore next */
          this.alertService.showToaster(error?.error?.message, '', 'error');
        }
      );
  }

  subtransactionalDCDSave(data: any) {
    this.subtransactionalDCD.next(data);
  }
  getAllscheduledjobs(gsiId: any, gsiMaterId: any, gsiVersion: any) {
    this.loader.show();
    this.gsiEndPointService
      .getAllscheduledjobs(gsiId, gsiMaterId, gsiVersion)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          console.log(res);
          /* istanbul ignore next */
          if (res) {
            this.scheduledjobsData.next(res);
            this.loader.hide();
            this.alertService.showToaster(res.message, '', 'success');
          }
        },
        (error) => {
          /* istanbul ignore next */
          this.alertService.showToaster(error?.error?.message, '', 'error');
        }
      );
  }
  saveSchedulerJob(ScheduleData: any) {
    this.loader.show();
    this.gsiEndPointService
      .saveSchedulerJob(ScheduleData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          console.log(res);
          /* istanbul ignore next */
          if (res) {
            this.savedScheduledjobData.next(res);
            this.loader.hide();
            this.alertService.showToaster(res.message, '', 'success');
          }
        },
        (error: any) => {
          /* istanbul ignore next */
          this.alertService.showToaster(error?.error?.message, '', 'error');
        }
      );
  }
  // Delete Pushback
  removeSchedulerJob(gsiId: any, jobId: any) {
    this.loader.show();
    this.gsiEndPointService
      .deleteSchedulerJob(gsiId, jobId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.deleteScheduleJob.next(res);
      });
  }
  getAllScheduleJobsData(data: any) {
    this.scheduledjobsData.next(data);
  }
  deleteScheduleJobFromData(data: any) {
    this.deleteScheduleJob.next(data);
  }
  saveScheduleJobtoData(data: any) {
    this.savedScheduledjobData.next(data);
  }
  rearrangeEntity(cuId: any, layerType: any, currentSlotIndex: any, newSlotIndex: any, gsiId: any, Data: any) {
    this.gsiEndPointService
      .rearrangeEntity(cuId, layerType, currentSlotIndex, newSlotIndex, gsiId, Data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        const gsiRes = gsiMapper(res?.result);
        this.updateGSIData.next(gsiRes);
      });
  }

  validateFile(postData: any) {
    this.loader.show();
    this.uploadEndpointService
      .singleFileUpload(postData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        if (res.status !== 417) {
          this.fileValidation.next(res);
          this.alertService.showToaster(res.message, '', 'success');
        } else {
          this.alertService.showToaster(res.message, '', 'error');
        }
      });
  }
  getVersionList(id: any) {
    return this.gsiEndPointService.getVersionList(id);
  }
  importSolutionWithFile(data: any) {
    this.importSolutionFile.next(data);
  }
}

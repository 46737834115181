import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '@app/@core/toaster/alert.service';
import { LoaderService } from '@app/@shared/services/loader/loader.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SoltuionDictionaryEndpointService } from './solution-dictionary-endpoint.service';

@Injectable({
  providedIn: 'root',
})
export class SolutionDictionaryFacadeService implements OnDestroy {
  // currentTab: String = 'myCollection';
  // private changeOfTabSubject = new BehaviorSubject(this.currentTab);
  // currentTab$ = this.changeOfTabSubject.asObservable();
  private ngUnsubscribe = new Subject();

  selectedCartItem: any;
  cartId: any;

  public ontologyMasterListSubject = new Subject();
  ontologyMasterList$ = this.ontologyMasterListSubject.asObservable();

  // private relatedBETListSubject = new BehaviorSubject(null);
  // relatedBETList$ = this.relatedBETListSubject.asObservable();

  // private browsedBETListSubject = new BehaviorSubject(null);
  // browsedBETList$ = this.browsedBETListSubject.asObservable();

  // private popularBETListSubject = new BehaviorSubject(null);
  // popularBETList$ = this.popularBETListSubject.asObservable();

  public betListSubject = new BehaviorSubject(null);
  betList$ = this.betListSubject.asObservable();

  public dsdSearchCriteriaSubject = new Subject();
  dsdSearchCriteria$ = this.dsdSearchCriteriaSubject.asObservable();

  public specificBETNameSubject = new Subject();
  specificBETName$ = this.specificBETNameSubject.asObservable();

  public detailedPage = new BehaviorSubject('solution');
  detailedPageData$ = this.detailedPage.asObservable();

  public bookmark = new BehaviorSubject({ color: '#323232', bookmark: false });
  bookmarkData$ = this.bookmark.asObservable();

  public overviewDetailsSubject = new Subject();
  overviewDetails$ = this.overviewDetailsSubject.asObservable();

  public bookDetailsSubject = new Subject();
  bookDetails$ = this.bookDetailsSubject.asObservable();

  public resetBETFilterSubject = new BehaviorSubject(null);
  resetBETFilter$ = this.resetBETFilterSubject.asObservable();

  public sideMenuMyLibrary = new BehaviorSubject(null);
  sideMenuMyLibrary$ = this.sideMenuMyLibrary.asObservable();

  validateBookDetails = new BehaviorSubject(null);
  validateBookDetails$ = this.validateBookDetails.asObservable();

  public bookEntitiesList = new Subject();
  bookEntitiesList$ = this.bookEntitiesList.asObservable();

  public bookDashboardsList = new Subject();
  bookDashboardsList$ = this.bookDashboardsList.asObservable();

  public bookDetailsList = new Subject();
  bookDetailsList$ = this.bookDetailsList.asObservable();

  nslSolutionReviews = new Subject();
  nslSolutionReviews$ = this.nslSolutionReviews.asObservable();

  private nslBookDetails = new BehaviorSubject(null);
  nslBookDetails$ = this.nslBookDetails.asObservable();

  private marketPlaceId = new Subject();
  marketPlaceId$ = this.marketPlaceId.asObservable();

  private notepadList = new Subject();
  notepadList$ = this.notepadList.asObservable();

  public searchData = new Subject();
  searchData$ = this.searchData.asObservable();

  public htmlData = new Subject();
  htmlData$ = this.htmlData.asObservable();

  public payloadForNotedPad = new BehaviorSubject('');
  payloadForNotedPad$ = this.payloadForNotedPad.asObservable();

  public prevPayloadForNotedPad = new BehaviorSubject('');
  prevPayloadForNotedPad$ = this.prevPayloadForNotedPad.asObservable();

  public minimizeWindow = new BehaviorSubject('');
  minimizeWindow$ = this.minimizeWindow.asObservable();

  public notepadHtmldata = new BehaviorSubject('');
  notepadHtmldata$ = this.notepadHtmldata.asObservable();

  public notepadName = new BehaviorSubject('');
  notepadName$ = this.notepadName.asObservable();

  public notepadBookId = new BehaviorSubject('');
  notepadBookId$ = this.notepadBookId.asObservable();

  public uploadedFile = new Subject();
  uploadedFile$ = this.uploadedFile.asObservable();

  private betType = new BehaviorSubject('');
  betType$ = this.betType.asObservable();

  public onboarding_select_solutions_selectedBooksAndSolutions = new Subject();
  onboarding_select_solutions_selectedBooksAndSolutions$ = this.onboarding_select_solutions_selectedBooksAndSolutions.asObservable();

  public myCartData = new Subject();
  myCartData$ = this.myCartData.asObservable();

  showBookmark = new Subject();
  showBookmark$ = this.showBookmark.asObservable();

  showPopup = new Subject();
  showPopup$ = this.showPopup.asObservable();

  constructor(
    private soltuionDictionaryEndpointService: SoltuionDictionaryEndpointService,
    private loader: LoaderService,
    private alertService: AlertService,
    private router: Router
  ) {}

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  setShowPopup(i: number) {
    this.showPopup.next(i);
  }

  pageInfo(data: any) {
    this.detailedPage.next(data);
  }
  bookmarkInfo(color: any, data: any) {
    this.bookmark.next({ color: color, bookmark: data });
  }

  loadOntologyMasterList() {
    this.loader.show();
    this.soltuionDictionaryEndpointService
      .getOntologyMasterList()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.loader.hide();
        this.ontologyMasterListSubject.next(res);
      });
  }

  loadSpecificBETByName(filterData: any) {
    this.loader.show();
    this.soltuionDictionaryEndpointService
      .getSpecificBETByName(filterData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.betListSubject.next(res);
        /* istanbul ignore next */
        if (res?.data?.length == 0) {
          this.alertService.showToaster('No records found', '', 'info');
        }
      });
  }

  getDSDSearchCriteria(searchTerm: any) {
    this.dsdSearchCriteriaSubject.next(searchTerm);
  }

  resetBETFilter(data: any) {
    this.resetBETFilterSubject.next(data);
  }

  loadSpecificBETName(data: any) {
    this.specificBETNameSubject.next(data);
  }

  addBETToMylibrary(betType: any, betDetails: any, currentComponent?: any, isDdsdRhs?: boolean) {
    //let res1: any;
    //let res2: any;
    this.loader.show();
    /*this.soltuionDictionaryEndpointService
      .addBETToMylibrary(betType, betDetails)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.alertService.showToaster(res.message, '', 'success');
        res1 = res;
      });*/
    this.soltuionDictionaryEndpointService
      .importAndValidateFromBetStore(betDetails, currentComponent)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          console.log(res);
          this.alertService.showToaster(res.message, '', 'success');
          this.validateBookDetails.next(res.result);
        },
        /* istanbul ignore next */
        (err: any) => {
          this.loader.hide();
          this.alertService.showToaster(err.message, '', 'error');
          if (!isDdsdRhs) {
            this.router.navigateByUrl('/solution-dictionary/book');
          }
        }
      );
    //return [res1, res2];
  }

  betStoreBookImport(bookDetails: any, dsdId: any) {
    this.loader.show();
    /* istanbul ignore next */
    this.soltuionDictionaryEndpointService
      .betStoreBookImport(bookDetails, dsdId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          this.alertService.showToaster(res.message, '', 'success');
          if (dsdId === this.selectedCartItem?.marketplaceId?.dsdId) {
            this.soltuionDictionaryEndpointService
              .updateMyCartData(this.cartId, dsdId, 'PUBLISHED')
              .pipe(takeUntil(this.ngUnsubscribe))
              .subscribe((result: any) => {
                console.log(result);
              });
          }
        },
        (error: any) => {
          this.loader.hide();
          this.alertService.showToaster(error.message, '', 'error');
          if (dsdId === this.selectedCartItem?.marketplaceId?.dsdId) {
            this.soltuionDictionaryEndpointService
              .updateMyCartData(this.cartId, dsdId, 'FAILED')
              .pipe(takeUntil(this.ngUnsubscribe))
              .subscribe((result: any) => {
                console.log(result);
              });
          }
        }
      );
  }

  betStoreImport(bookDetails: any, dsdId: any, betType: any) {
    this.loader.show();
    /* istanbul ignore next */
    this.soltuionDictionaryEndpointService
      .betStoreImport(bookDetails, dsdId, betType)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          this.alertService.showToaster(res.message, '', 'success');
          if (dsdId === this.selectedCartItem?.marketplaceId?.dsdId) {
            this.soltuionDictionaryEndpointService
              .updateMyCartData(this.cartId, dsdId, 'PUBLISHED')
              .pipe(takeUntil(this.ngUnsubscribe))
              .subscribe((result: any) => {
                console.log(result);
              });
          }
        },
        /* istanbul ignore next */
        (error: any) => {
          this.loader.hide();
          this.alertService.showToaster(error.message, '', 'error');
          if (dsdId === this.selectedCartItem?.marketplaceId?.dsdId) {
            this.soltuionDictionaryEndpointService
              .updateMyCartData(this.cartId, dsdId, 'FAILED')
              .pipe(takeUntil(this.ngUnsubscribe))
              .subscribe((result: any) => {
                console.log(result);
              });
          }
        }
      );
  }

  publishBETToNSLlibrary(betType: any, betDetails: any) {
    this.loader.show();
    this.soltuionDictionaryEndpointService
      .publishBETToNSLlibrary(betType, betDetails)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.alertService.showToaster(res.message, '', 'success');
      });
  }

  publishBETToNSL(betDetails: any) {
    this.loader.show();
    this.soltuionDictionaryEndpointService
      .publishBETToNSL(betDetails)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.alertService.showToaster(res.message, '', 'success');
      });
  }

  getGSIImportDetails(res: any) {
    this.validateBookDetails.next(res);
  }

  getBookEntity(bookID: any, betType: string) {
    this.loader.show();
    this.soltuionDictionaryEndpointService
      .getBookEntity(bookID, betType)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.bookEntitiesList.next(res);
      });
  }

  getBookDashboard(bookID: any) {
    this.loader.show();
    this.soltuionDictionaryEndpointService
      .getBookDashboard(bookID)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.bookDashboardsList.next(res);
      });
  }

  loadBookDetails(bookDetails: any) {
    this.loader.show();
    this.soltuionDictionaryEndpointService
      .loadBookDetails(bookDetails)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          this.bookDetailsList.next(res);
          this.alertService.showToaster(res.message, '', 'success');
        },
        /* istanbul ignore next */
        (error: any) => {
          this.bookDetailsList.next(error);
        }
      );
  }

  getOverviewDetails(betDetails: any) {
    this.loader.show();
    this.soltuionDictionaryEndpointService
      .getOverviewDetails(betDetails)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.alertService.showToaster(res.message, '', 'success');
        this.overviewDetailsSubject.next(res.result);
      });
  }

  getBookDetails(betDetails: any) {
    this.loader.show();
    this.soltuionDictionaryEndpointService
      .getBookDetails(betDetails)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.alertService.showToaster(res.message, '', 'success');
        this.bookDetailsSubject.next(res.result);
      });
  }

  /* nsl reviews */
  addNslReview(betDetails: any) {
    this.loader.show();
    this.soltuionDictionaryEndpointService
      .addNslReview(betDetails)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        /* istanbul ignore else */
        if (res) {
          this.alertService.showToaster(res.message, '', 'success');
          /* istanbul ignore next */
          this.getNslReview(betDetails?.marketplaceId);
        }
      });
  }

  getNslReview(betDetails: any) {
    this.loader.show();
    this.soltuionDictionaryEndpointService
      .getNslReview(betDetails)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.alertService.showToaster(res.message, '', 'success');
        this.nslSolutionReviews.next(res.result);
      });
  }
  /* ./nsl reviews */

  getBookmark(val: boolean) {
    this.showBookmark.next(val);
  }

  /* my library overview */
  getMyLibraryOverview(betType: any, id: any) {
    this.loader.show();
    this.soltuionDictionaryEndpointService
      .getMyLibraryOverview(betType, id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response: any) => {
        this.loader.hide();
        this.alertService.showToaster(response.message, '', 'success');
        this.overviewDetailsSubject.next(response.result);
      });
  }

  setBookDetailsSubject(data: any) {
    this.bookDetailsSubject.next(data);
  }

  getMyLibraryDetailedOverview(betType: any, id: any) {
    this.loader.show();
    this.soltuionDictionaryEndpointService
      .getMyLibraryDetailedOverview(betType, id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((r: any) => {
        this.loader.hide();
        this.alertService.showToaster(r.message, '', 'success');
        this.bookDetailsSubject.next(r.result);
      });
  }
  /* ./my library overview */

  // side Menu MyLibrary
  getIsSideMenuForMyLibrary(data: any) {
    this.sideMenuMyLibrary.next(data);
  }

  //notepad
  getNotepadList(
    betType: string,
    consolidated: boolean,
    pageSize: number,
    pagenum: number,
    searchCriteria: string,
    sortBy: string
  ) {
    return this.soltuionDictionaryEndpointService.getNotepadList(
      betType,
      consolidated,
      pageSize,
      pagenum,
      searchCriteria,
      sortBy
    );
  }

  getGlobalSearch(searchText: string, betType: string, pageNumber: number, pageSize: number) {
    this.soltuionDictionaryEndpointService
      .getGlobalSearch(searchText, betType, pageNumber, pageSize)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.searchData.next(res);
      });
  }

  setNslBookDetails(data: any) {
    this.nslBookDetails.next(data);
  }

  // changeOfTab(nextTab: string) {
  //   this.changeOfTabSubject.next(nextTab);
  // }

  saveHtmlData(data: any) {
    this.htmlData.next(data);
  }

  payloadForSaveNotedPad(data: any) {
    this.payloadForNotedPad.next(data);
  }

  prevPayloadForSaveNotedPad(data: any) {
    this.prevPayloadForNotedPad.next(data);
  }

  minimizeNotepadWindow(data: any) {
    this.minimizeWindow.next(data);
  }

  saveNotepad(payload: any) {
    // create new roles
    this.loader.show();
    this.soltuionDictionaryEndpointService
      .saveNotepad(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.alertService.showToaster(res.message, '', 'success');
      });
  }

  uploadFile(payload: any) {
    // create new roles
    this.loader.show();
    this.soltuionDictionaryEndpointService
      .uploadFile(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.uploadedFile.next(res);
        this.loader.hide();
        this.alertService.showToaster(res.message, '', 'success');
      });
  }

  getNotepadContent(contentId: string) {
    this.soltuionDictionaryEndpointService
      .getNotepadContent(contentId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        if (res?.result?.content) {
          this.notepadHtmldata.next(JSON.parse(res.result.content));
        }
      });
  }

  sendBetType(type: any) {
    this.betType.next(type);
  }

  send_onboarding_select_solutions_selectedBooksAndSolutions(data: any) {
    this.onboarding_select_solutions_selectedBooksAndSolutions.next(data);
  }

  getMyCartData() {
    this.soltuionDictionaryEndpointService
      .getMyCartData()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore next */
        this.myCartData.next(res?.result);
        /* istanbul ignore next */
        this.cartId = res?.result?.dsdId;
      });
  }

  selectedItemFromCart(data: any) {
    this.selectedCartItem = data;
  }
}

let transform = require('node-json-transform').transform;

export function changeUnitListMapper(data: any) {
  var map = {
    /*
      node json mapper default item
      used to map one key to another
    */
    item: {
      content: 'data',
      total: 'totalResults',
    },
    operate: [
      {
        run: function (val: any[]) {
          /* istanbul ignore next */
          return val?.map((x: any) => {
            return {
              tfId: x.id,
              dsdId: x.dsdId,
              id: x.dsdId,
              name: x.name,
              status: x.status,
            };
          });
        },
        on: 'content',
      },
    ],
  };
  const result = transform(data, map);
  console.log(result);
  return result;
}

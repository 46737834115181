<div class="main-wrapper">
  <div class="main-content">
    <div class="middle-content">
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-12 col-xl-9 ml-auto pl-xl-5">
            <section class="page-header">
              <div class="row align-items-center">
                <div class="col-12">
                  <app-bread-crumb *ngIf="isUserDelegation" [type]="'userdelegation'"></app-bread-crumb>
                  <app-bread-crumb *ngIf="isRoleDelegation" [type]="'roledelegation'"></app-bread-crumb>
                </div>
                <div class="col">
                  <h2 class="page-title">{{ labels?.List_Delegation }}</h2>
                </div>
              </div>
            </section>
            <div class="row align-items-center">
              <div class="col-auto ml-auto gsi-search view-search">
                <button
                  class="search-ico sprite"
                  [ngClass]="searchOpen ? 'search-open' : ''"
                  (click)="openSearch = 'true'; searchOpen = !searchOpen"
                >
                  {{ labels?.Open_Search }}
                </button>
                <div class="search-bar gsi-search-bar">
                  <input
                    type="text"
                    class="search-input"
                    [(ngModel)]="searchString"
                    [placeholder]="labels?.Search"
                    (keyup)="searchInput.next()"
                  />
                  <button class="search-ico sprite search-btn">{{ labels?.Search }}</button>
                  <button class="clear-ico sprite search-close" (click)="searchClose()">
                    {{ labels?.Close }}
                  </button>
                </div>
              </div>

              <span class="material-icons action-plus" (click)="addDelegation()">
                add
              </span>
            </div>
            <div id="nsl-usertable">
              <div class="manage-roles-table">
                <table mat-table [dataSource]="dataSource" matSort class="table-design" aria-describedby="">
                  <ng-container matColumnDef="sourceName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header id="source">
                      <strong>{{
                        isUserDelegation ? labels?.Source_User : isRoleDelegation ? labels?.Source_Role : ''
                      }}</strong>
                    </th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.sourceName }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="targetName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header id="target">
                      <strong>{{
                        isUserDelegation ? labels?.Target_User : isRoleDelegation ? labels?.Target_Role : ''
                      }}</strong>
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element.targetName }}</td>
                  </ng-container>

                  <ng-container matColumnDef="time">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header id="time">
                      <strong>{{ labels?.Time }}</strong>
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element.time }}</td>
                  </ng-container>

                  <ng-container matColumnDef="delegationType">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header id="delegation">
                      <strong>{{ labels?.Delegation }}</strong>
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element.delegationType }}</td>
                  </ng-container>

                  <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef class="lastTh" id="action">{{ labels?.Action }}</th>
                    <td mat-cell *matCellDef="let element">
                      <div class="admin-actions">
                        <mat-form-field appearance="fill">
                          <mat-icon>more_vert</mat-icon>
                          <mat-select
                            panelClass="mobile-panel"
                            (selectionChange)="menuClosed()"
                            (openedChange)="menuOpened()"
                          >
                            <mat-option>
                              <label class="d-block" (click)="updateDelegation(element)">{{ labels?.Update }}</label>
                            </mat-option>
                            <mat-option>
                              <label class="d-block" (click)="deleteDelegation(element)">{{ labels?.Delete }}</label>
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
              </div>
              <mat-paginator
                class="mt-4"
                [length]="totalCount"
                [pageSize]="pageSize"
                [pageSizeOptions]="[5, 10, 25, 100]"
                (page)="changePage($event)"
              >
              </mat-paginator>
            </div>
          </div>
          <div class="col-auto col-xl-3">
            <!-- <div class="ad-box d-flex text-center align-items-center justify-content-center p-4">
              <strong>{{ labels?.Ad }}</strong>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

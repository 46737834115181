export interface AppConfigModel {
  api: string;
  production: boolean;
  hmr: boolean;
  version: string;
  firebase: FirebaseModel;
  name: string;
  defaultLanguage: string;
  supportedLanguages: Array<string>;
  dsdOrchUrl: string;
  cdmServerAPI: string;
  serverIp: string;
  loginApiKey: string;
  loginAuth: string;
  aadsApplicationUrl: string;
  googleAnalyticsTag: string;
  googleTagManager: string;
  googleMaps: string;
  paasLiveChatAgent: string;
  cdnUrl: string;
  defaultTenantName: string;
  xAuthToken: string;
  xUserId: string;
}

export interface FirebaseModel {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
}

export function endPoints(environment: AppConfigModel) {
  // if(!environment){
  //   return;
  // }
  environment.serverIp = '';
  if (window.location.host?.includes('localhost') || window.location.host?.includes('10.')) {
    environment.serverIp = '/api';
  }
  if (environment.dsdOrchUrl.indexOf(environment?.serverIp) === -1) {
    environment.dsdOrchUrl = environment?.serverIp + environment.dsdOrchUrl;
  }
  if (environment.cdmServerAPI.indexOf(environment.dsdOrchUrl) === -1) {
    environment.cdmServerAPI = environment.dsdOrchUrl + environment.cdmServerAPI;
  }

  const aadsApplicationUrl = `${environment.aadsApplicationUrl}`;
  const cdmTenantUrl = `${environment.cdmServerAPI}/api/cdm`;
  const serverIp = `${environment?.serverIp}`;
  const nslOTP = `${environment?.serverIp}/nsl-iam`;
  const tfCoreUrl = `${environment.dsdOrchUrl}/core`;
  const dsdBetsStoreUrl = `${environment.dsdOrchUrl}/dsd-bets-store`;
  const tfTransactionUrl = `${environment.dsdOrchUrl}/txn/execute`;
  const tfDebugValue = `${environment.dsdOrchUrl}/execute`;
  const searchUrl = `${environment.dsdOrchUrl}/search`;
  const dldServiceUrl = `${environment.dsdOrchUrl}/dld`;
  const customTenantUI = `${environment.dsdOrchUrl}/design`;
  const transactionUrl = `${environment.dsdOrchUrl}/txn/transactions`;
  const secmcc = `${environment.dsdOrchUrl}/secmcc/transaction`;
  const translator = `${environment.dsdOrchUrl}/translator`;
  const dashboardUrl = `${environment.dsdOrchUrl}/query/`;
  const importexport = `${environment.dsdOrchUrl}/importexport`;
  const analytics = `${environment.dsdOrchUrl}/analytics`;
  const notificationsUrl = `${environment.dsdOrchUrl}/v1/notifications/api`;
  const globalSearchUrl = `${environment.dsdOrchUrl}/query/nsl-search`;
  const ocrUrl = `${environment.dsdOrchUrl}/v2_dpe`;
  const chatBot = `${environment.dsdOrchUrl}/siaorch`;
  const metering = `${environment.dsdOrchUrl}/metering`;
  const auditUrl = `${environment.dsdOrchUrl}/audit`;
  const query = `${environment.dsdOrchUrl}/query`;
  const specialFeatures = `${environment.dsdOrchUrl}/special-features`;
  const dsdFileStore = `${environment.dsdOrchUrl}/dsd-files-store/`;
  const dsdTransactionUrl = `${environment.dsdOrchUrl}/execute`;
  const globalBetsStore = `${dsdBetsStoreUrl}/global`;
  const nslIAMUrl = `${environment.dsdOrchUrl}/nsl-iam/api`;
  const iamUserBaseUrl = `${nslIAMUrl}/user`;
  const iamRolesBaseUrl = `${nslIAMUrl}/roles`;
  const iamOrganisationUnitBaseUrl = `${nslIAMUrl}/orgUnits`;
  const iamGroupsBaseUrl = `${nslIAMUrl}/groups`;
  const iamLdapGroupsBaseUrl = `${nslIAMUrl}/ldapgroups`;
  const iamOwnershipBaseUrl = `${nslIAMUrl}/owner-ship`;
  const cdmOrchBaseUrl = `${environment.dsdOrchUrl}/cdm`;
  const tenantBaseUrl = `${cdmOrchBaseUrl}/api/cdm`;
  const cdmApiUrl = `${cdmOrchBaseUrl}/api`;
  const dsdOrch = `${environment.dsdOrchUrl}`;
  const globalSearch = `${environment.dsdOrchUrl}/nsl/`;
  const globalSearchforNotepad = `${environment.dsdOrchUrl}/dsd-bets-store/global/browse`;
  const googleTagManager = 'https://www.googletagmanager.com/gtag/js?id=';
  const predectiveAnalytics = `${environment.dsdOrchUrl}/pa`;
  const nslbetAnalyzer = `${environment?.serverIp}/nsl-bet-analyzer/bet/`;
  const geEntitycard = `${environment.dsdOrchUrl}/design/entity-card/`;
  const scheduledJobs = `${environment.dsdOrchUrl}/async`;
  return {
    // apiUrl: 'http://10.5.7.203:1337',
    notificationsUrl: notificationsUrl,
    analytics: analytics,
    importexport: importexport,
    customTenantUI: customTenantUI,
    metering: metering,
    aadsUrl: `${aadsApplicationUrl}/aads/wizard`,
    dsdBetsStoreUrl: dsdBetsStoreUrl,
    tfCoreUrl: tfCoreUrl,
    cdmTenantUrl: cdmTenantUrl,
    googleTagManager: googleTagManager,
    googleGeoLocation: `${environment.dsdOrchUrl}/advertisement/getLocation?latlng=`,

    /** Bulk Import Users */
    getUsersList: `${tenantBaseUrl}/getAssignedEnvUsers`,
    getUnassignedUsersList: `${tenantBaseUrl}/getUnassignedEnvUsers`,
    getAddUsersList: `${tenantBaseUrl}/getUnassignedEnvUsers`,
    unassignUsers: `${tenantBaseUrl}/users/update`,
    assignUsers: `${tenantBaseUrl}/assignUsersToEnv`,

    /** Translator */
    saveStaticLabels: `${translator}/tags/add/Global/`,
    getLanguages: `${translator}/language`,
    getStaticLabels: `${translator}/tags/Global/`,
    getStringTranslate: `${translator}/translations`,
    updateMultipleWords: `${translator}/translations/updateMultipleWords`,
    addStaticLabels: `${translator}/label/add`,
    updateStaticLabels: `${translator}/label/update`,
    deleteStaticLabel: `${translator}/label/delete`,

    /**Auth */
    loginAuth: `${environment.loginAuth}`,
    logOut: `${nslIAMUrl}/login/logout-action`,
    getAllTenants: `${cdmTenantUrl}/getAll`,
    tenantInfo: `${cdmTenantUrl}/getTenantByName`,
    envBasicInfo: `${cdmTenantUrl}/envBasicInfo?`,
    envByName: `${cdmTenantUrl}/envByName/`,
    forgotPassword: `${nslIAMUrl}/user/resetPasswordByEmail`,
    forgotCustomerId: `${nslOTP}/api/user/getCustomID`,
    generateOTP: `${nslOTP}/login/otp/generateOtp?mobileNumber=`,
    validateOTP: `${nslOTP}/login/otp/validateOtp?otpNumber=`,
    tokenGenerate: `${nslIAMUrl}/login/login-action?`,
    tokenGenerateV2: `${nslIAMUrl}/login/v2/login-action`,
    refreshTokenGeneration: `${nslIAMUrl}/login/tokenFromRefreshToken?`,
    // getAllLabels: `http://10.16.2.170:9090/nsl-iam/api/roles/getAllLabels`,
    getAllLabels: `${nslIAMUrl}/roles/getAllLabels`,
    // getRolesByLabels:  `http://10.16.2.170:9090/nsl-iam/api/roles/getAllRolesInLabel?label=`,
    getRolesByLabels: `${nslIAMUrl}/roles/getAllRolesInLabel?label=`,

    /**
     * B2C Login
     */

    getIdentityProvidersList: `${nslIAMUrl}/client/getIdentityProviders?tenantName=`,
    fat2flat: `${environment.dsdOrchUrl}/nsl2text/batch_fat2flat`,
    getEntityIdByName: `${environment.dsdOrchUrl}/dsd-bets-store/tenant/flat`,
    nestedEntity: `${environment.dsdOrchUrl}/nsl2text/nested_entity`,
    compositeExtraction: `${environment.dsdOrchUrl}/nslgrammar/composite_extraction`,
    informationLayerExtraction: `${environment.dsdOrchUrl}/nslgrammar/info_layer_extraction`,
    triggerCESLayerExtraction: `${environment.dsdOrchUrl}/nslgrammar/trigger_ces_extraction`,
    cuValidate: `${environment.dsdOrchUrl}/nslgrammar/cu_validate`,
    /**
     * DLD
     */
    dldCU: `${dldServiceUrl}/DLDCUSmartFullTF3`,
    dldGsi: `${dldServiceUrl}/DLDGSISmartTF3`,
    dldNestedCU: `${dldServiceUrl}/DLDNestedCUSmartFullTF3`,
    dldExceptionCU: `${dldServiceUrl}/DLDExceptionCUSmartFullTF3`,
    dldEntity: `${dldServiceUrl}/DLDEntitySmartFullTF3`,
    dldAttribute: `${dldServiceUrl}/DLDAttributesSmartTF3`,
    dldGsiFull: `${dldServiceUrl}/DLDGSISmartFullTF3`,
    dldGsiRoles: `${dldServiceUrl}/DLDGSIRoleSmartTF3`,
    dldCURoles: `${dldServiceUrl}/DLDCURoleSmartTF3`,
    dldEntityRoles: `${dldServiceUrl}/DLDEntityRoleSmartTF3`,
    dldAttributeRoles: `${dldServiceUrl}/DLDAttributeRoleSmartTF3`,
    dldDCDEntity: `${dldServiceUrl}/DLDDCDForEntityTF3`,
    dldDCDAttribute: `${dldServiceUrl}/DLDDCDForAttributeTF3`,
    dldFeedback: `${dldServiceUrl}/DLDFeedbackServiceTF3`,
    compositeSaveCU: `${tfCoreUrl}/CU/compositesave`,

    /**
     * Global Bets Store
     */
    globalBets: globalBetsStore,
    globalBetsSearch: `${globalBetsStore}/browse/`,
    compareBets: `${globalBetsStore}/browse/compare-bets?betType=`,
    saveBetsComposite: `${dsdBetsStoreUrl}`,

    /**
     * OCR API
     */
    ocrExtractionUrl: `${ocrUrl}/extraction`,
    ocrSetMappingUrl: `${dsdBetsStoreUrl}/cdec/set_mappings`,
    ocrGetMappingUrl: `${ocrUrl}/get_mappings`,
    ocrManualMapping: `${ocrUrl}/manual_learning`,
    /**chat bot */

    chatBot: `${chatBot}/get_answer`,
    chatBotGreetMessage: `${chatBot}/greet_user`,
    chatBotVoiceInput: `${chatBot}/get_answer_by_asr`,
    chatBotFeedBack: `${chatBot}/save_user_feedback`,
    paasLivechatUrl: `${environment.paasLiveChatAgent}/livechat`,
    feedbackLivechat: `${environment.paasLiveChatAgent}/chatsession/feedback`,
    setDepartment: `${environment.paasLiveChatAgent}/chatsession/addDepartment`,
    chatbotFileUpload: `${environment.paasLiveChatAgent}/api/v1/livechat-visitor/uploadfile`,

    /** Transaction Api */

    debug: `${tfTransactionUrl}/fetchtxndata`,
    debugValue: `${tfDebugValue}/lifecycle/comment/triggerState/`,
    transactionUrl: `${tfTransactionUrl}/change-unit`,
    transactionresumeUrl: `${dsdTransactionUrl}/resume`,
    setChangeUnitEvents: `${dsdTransactionUrl}/event?baseTCUId=`,
    getDependentDropdownDetails: `${dsdTransactionUrl}/reference-source-values?`,
    saveFCMToken: `${notificationsUrl}/token/device-token`,
    deleteFCMToken: `${notificationsUrl}/token/device-token`,
    getAllNotifications: `${notificationsUrl}/notification?`,
    changeOfNotificationStatus: `${notificationsUrl}/notification/`,
    markAllNotificationsAsRead: `${notificationsUrl}/notification/update-all-status-read`,
    searchNotofications: `${notificationsUrl}/search`,
    getUnreadNotificationCount: `${notificationsUrl}/notification/get-unread-count`,

    // globalAutocomplete: `${environment.dsdOrchUrl}/query/nsl-search/autocomplete?`,
    // globalSearch: `${environment.dsdOrchUrl}/query/nsl-search/search?`,
    globalAutocomplete: `${globalSearchUrl}/autocomplete?`,
    globalSearch: `${globalSearchUrl}/search?`,
    googleAPI: `https://www.googleapis.com/customsearch/v1?`,

    previousCuData: `${dsdTransactionUrl}/previousCU/`,
    reAssign: `${secmcc}/reAssign?`,
    escalateTxn: `${secmcc}/escalate?`,
    cancelAutoSubmitTxn: `${secmcc}/cancel-autoSubmit?`,
    getCreatedTxn: `${secmcc}/createdBy?`,
    getAssignedTxn: `${secmcc}/reAssign?`,
    getEscalatedTxn: `${secmcc}/escalate?`,
    pendingTransactionUrl: `${transactionUrl}/all?`,
    getTransactionHistoryCuList: `${secmcc}/history/`,
    getCuTransactionHistory: `${secmcc}/cuTxnData/`,
    getReservedActionsList: `${transactionUrl}/all/reservedActionNames?`,
    getAllTransactions: `${dashboardUrl}transaction/txn-general-search?`,
    advancedSearchTxn: `${dashboardUrl}transaction/filtered-transactions`,
    txnStatusChange: `${secmcc}/status?`,
    txnStatusall: `${secmcc}`,
    getReservedActionsHistory: `${secmcc}/reserved-actions`,

    /** dashboard api */
    saveDataset: `${dashboardUrl}datasets/dataset`,
    groupList: `${dashboardUrl}dashboards/groups/list?`,
    getWidgets: `${dashboardUrl}datasets/dataset/groupName/`,
    getAttributes: `${dashboardUrl}dynamic-search/txn-report-custom`,
    getxandyValues: `${dashboardUrl}dynamic-search/txn-xy-report`,
    saveWidget: `${dashboardUrl}dashboards/widget-all`,
    deleteWidget: `${dashboardUrl}dashboards/widget/`,
    saveCollection: `${dashboardUrl}collections/collection`,
    getCollectionDetails: `${dashboardUrl}collections/collection/`,
    getdashboardDetailsOnCollection: `${dashboardUrl}dashboards/dashbaord-list/`,
    getDashboardsByName: `${dashboardUrl}dashboards/dashboard/name/`,
    getAttributeValues: `${dashboardUrl}dynamic-search/list-attributeValues`,
    getAggrigateValues: `${dashboardUrl}dynamic-search/txn-report`,
    getCollections: `${dashboardUrl}collections/list?`,
    getDatasetDetails: `${dashboardUrl}datasets/dataset/name/`,
    getDashboardDetails: `${dashboardUrl}dashboards/dashboard/`,
    publishDashboard: `${dashboardUrl}dashboards/collection/`,
    getWidgetDetails: `${dashboardUrl}dashboards/widget/`,
    getAllDashboardWidgets: `${dashboardUrl}dashboards/widgets/`,
    getAllDashboards: `${dashboardUrl}dashboards/dashboard?`,
    getAllDatasets: `${dashboardUrl}datasets/dataset`,
    deleteDatasets: `${dashboardUrl}datasets/dataset/`,
    getcuExecutionData: `${dashboardUrl}dynamic-search/cu-execution-report`,
    deleteDashboard: `${dashboardUrl}dashboards/dashboard/delete/`,

    getGEAttributes: `${dashboardUrl}txn-ge-search/ge-report-custom`,
    getGExandyValues: `${dashboardUrl}txn-ge-search/ge-xy-report`,
    getGEAggrigateValues: `${dashboardUrl}txn-ge-search/ge-report`,
    getGEAttributeValues: `${dashboardUrl}txn-ge-search/attributeValues/name/`,

    getTopTransactedSolutions: `${analytics}/analytics/topSolutions?`,
    getTopEditedSolutions: `${analytics}/analytics/topEditedSolutions?`,
    getTransactionsCount: `${analytics}/analytics/transactionsCount?`,
    getSolutionsCount: `${analytics}/analytics/solutionsCount?`,
    loadUsersCount: `${iamUserBaseUrl}/getUsersCount`,
    getTenentByName: `${tenantBaseUrl}/getTenantByName/`,
    getBillingData: `${cdmOrchBaseUrl}/api/billing/bills/`,
    featureUsageBill: `${tenantBaseUrl}/api/billing/bills/usage`,
    getUsersCountByTenantName: `${iamUserBaseUrl}/getUsersCountByTenantName?`,
    exportBillingPdf: `${cdmOrchBaseUrl}/api/billing/bills/`,
    getEntityCount: `${analytics}/analytics/entitiesCount?`,
    getAllCdmUsers: `${tenantBaseUrl}/users`,
    getPaginateUsersTenantAdmin: `${tenantBaseUrl}/userspage`,
    updateUserAsTenantAdmin: `${tenantBaseUrl}/update/user`,
    specialFeatureAnalytics: `${dsdOrch}/special-features/analytics`,
    specialFeatureSave: `${dsdOrch}/special-features/`,
    /**manage Data API */
    importEntitiesFromCSV: `${importexport}/import/entities`,
    importEntity: `${importexport}/import/entity`,
    downloadTemplate: `${importexport}/import/entity/template?`,
    saveNewEntity: `${importexport}/import/entity/save`,
    exportEntity: `${importexport}/export/entity`,
    getBooksByRights: `${nslIAMUrl}/rights/getBooksByRights?`,
    getSolutionsByRights: `${nslIAMUrl}/rights/getSolutionsByRights?`,
    getRelatedBetsData: `${importexport}/dependency`,
    fetchsingleLevelDependency: `${importexport}/singleLevelDependency/`,
    importHistoryData: `${importexport}/import-status/getAllStatus`,
    /**manage configuration API */
    configurableCPEntities: `${environment.dsdOrchUrl}/core/Entity/matching/criteria`,
    configurableEntityData: `${environment.dsdOrchUrl}/execute/entity-records`,
    configurableSaveEntity: `${environment.dsdOrchUrl}/execute/save/entity-records`,

    /* paginated Search API */
    getPaginatedRecords: `${environment.dsdOrchUrl}/execute/paginated-search`,

    /**manage Organisation Unit API */
    getAllOrgUnits: `${iamOrganisationUnitBaseUrl}/getAllOrgUnits`,
    getPaginateOrgUnits: `${iamOrganisationUnitBaseUrl}/getAllOrgUnits?`,
    createOrgUnit: `${iamOrganisationUnitBaseUrl}/save`,
    getOrgUnitById: `${iamOrganisationUnitBaseUrl}/getById`,
    getOrgUnitByName: `${iamOrganisationUnitBaseUrl}/getByName`,
    // addRoleInOrgUnit: `${iamOrganisationUnitBaseUrl}/roles?`,
    // deleteRoleInOrgUnit: `${iamOrganisationUnitBaseUrl}/roles?`,
    addUserInOrgUnit: `${iamOrganisationUnitBaseUrl}/users?`,
    deleteUserInOrgUnit: `${iamOrganisationUnitBaseUrl}/users?`,
    getUnassignUsersInOrgUnit: `${iamOrganisationUnitBaseUrl}/page/matching/unassignedusers?`,
    getAssignUsersInOrgUnit: `${iamOrganisationUnitBaseUrl}/page/matching/assignedusers?`,
    getUnassignRolesInOrgUnit: `${iamOrganisationUnitBaseUrl}/page/matching/unassignedroles?`,
    getAssignRolesInOrgUnit: `${iamOrganisationUnitBaseUrl}/page/matching/assignedroles?`,
    getUsersInOrgUnit: `${iamOrganisationUnitBaseUrl}/users?`,
    loadUsersInOrgUnit: `${iamOrganisationUnitBaseUrl}/getById`,
    //updateOrgUnit: `${iamOrganisationUnitBaseUrl}/save`,

    /** Roles API */
    getAllRoles: `${iamRolesBaseUrl}/getAllRoles`,
    getPaginateRoles: `${iamRolesBaseUrl}/getAllRoles?`,
    createRole: `${iamRolesBaseUrl}/save`,
    updateRole: `${iamRolesBaseUrl}/save-basic`,
    deleteRole: `${iamRolesBaseUrl}/delete/`,
    importRoleFromCSV: `${iamRolesBaseUrl}/import/roles/csv`,
    getRolesCount: `${iamRolesBaseUrl}/getRolesCount`,
    unAssignUserToRole: `${iamRolesBaseUrl}/unAssignUserToRole/`,
    unAssignGroupToRole: `${iamRolesBaseUrl}/unAssignGroupToRole/`,
    assignUserToRole: `${iamRolesBaseUrl}/assignUserToRole`,
    assignGroupToRole: `${iamRolesBaseUrl}/assignGroupToRole`,
    getAllRolesByBookId: `${iamRolesBaseUrl}/getBookRoles?`,
    getAllRolesBySolutionId: `${iamRolesBaseUrl}/getSolutionRoles?`,
    getInformationLayer: `${nslIAMUrl}`,
    getDetailsByTargetRoleId: `${iamRolesBaseUrl}/getDelegationBetweenRoles/`,
    getEntitiesForDelegation: `${nslIAMUrl}/rights/fetchEntitiesFromBookOrSolution?`,
    getAttributesForDelegation: `${nslIAMUrl}/rights/fetchAttributesForEntity?`,
    createDelegation: `${iamRolesBaseUrl}/saveDelegation`,
    loadRoleDelegations: `${iamRolesBaseUrl}/getDelegations?`,
    deleteDelegation: `${iamRolesBaseUrl}/delegation?`,
    getDelegationById: `${iamRolesBaseUrl}/getDelegation?`,
    getAllUnassignedLDAPGroupsForRoles: `${iamRolesBaseUrl}/getAllUnassignedLDAPGroups?`,
    getAllAssignedLDAPGroupsForRoles: `${iamRolesBaseUrl}/getAllLDAPGroups?`,
    unassignLdapGroupForRoles: `${iamRolesBaseUrl}/unassignLdapGroup?`,
    assignLdapGroupsForRoles: `${iamRolesBaseUrl}/assignLdapGroups`,
    getLocalRoles: `${iamRolesBaseUrl}/getAllBookRoles?`,
    getAllBookTreePageable: `${iamRolesBaseUrl}/getAllBookTreePageable?`,
    getBookTreeById: `${iamRolesBaseUrl}/getBookTreeById?`,
    saveBookRole: `${iamRolesBaseUrl}/saveBookRole`,
    deleteBookRole: `${iamRolesBaseUrl}/deleteBookRole/`,
    getAssignedOwnersPageable: `${iamRolesBaseUrl}/getAssignedOwnersPageable?`,
    assignBookOwners: `${iamRolesBaseUrl}/assignOwners`,
    getAllRolesHierarchy: `${iamRolesBaseUrl}/getAllRolesHierarchy`,
    getBookRoleTree: `${iamRolesBaseUrl}/getBookRoleTree/`,
    saveIRDR: `${iamUserBaseUrl}/saveIRDRs`,
    getIRDRForBookOrSolution: `${iamRolesBaseUrl}/getIRDRForBookOrSolution?`,
    getIRDRForRolePageable: `${iamRolesBaseUrl}/getIRDRForRolePageable?`,
    getBookTreePageable: `${iamRolesBaseUrl}/getBookTreePageable?`,
    getAllOwners: `${iamRolesBaseUrl}/getAllOwners?`,
    //new role api
    getRoleWithBooks: `${iamRolesBaseUrl}/getRoleWithBooks`,
    createRoleb2c: `${iamRolesBaseUrl}/createRoleAndAttachToBooks`,
    getB2cRoles: `${iamRolesBaseUrl}/getB2cRoles`,
    deleteRoleb2c: `${iamRolesBaseUrl}/delete`,
    getRolesAttachedToBook: `${iamRolesBaseUrl}/getRolesAttachedToBook`,
    getRolesforBook: `${iamRolesBaseUrl}/getAllBookRoles`,
    getRolesByName: `${iamRolesBaseUrl}/getByName`,
    getPublicRoles: `${iamRolesBaseUrl}/getPublicRoles?`,

    deleteCustomLayout: `${dsdOrch}/design/tenant/v2/site/page/layout/`,
    /**Saas API */
    getPaginateSaasApplications: `${tenantBaseUrl}/saassolutionspage?`,
    createSaasApplication: `${tenantBaseUrl}/saassolution`,
    validateSaasApplication: `${tenantBaseUrl}/saassolutions/validate`,
    upgradeAllAssociatedTenants: `${tenantBaseUrl}/upgradeTenantSaasSolutionBySolUUId/`,
    associateAllAssociatedTenant: `${tenantBaseUrl}/assocforsolution/`,
    postSaaSApplications: `${tenantBaseUrl}/assoctenantsforsolution/`,

    /**subscription */
    createPlan: `${cdmApiUrl}/subscriptions/plans`,
    viewPlans: `${cdmApiUrl}/subscriptions/plans`,
    createViewFeature: `${cdmApiUrl}/subscriptions/features`,
    ViewFeature: `${cdmApiUrl}/subscriptions/featuresAsAddons/`,
    mapFeaturesToPlan: `${cdmApiUrl}/subscriptions/featureMappings`,
    getFeaturesUnmappedToPlan: `${cdmApiUrl}/subscriptions/featuresAsAddons/`,
    getPlanByName: `${cdmApiUrl}/subscriptions/plans/name/`,
    featuresMappedToPlan: `${cdmApiUrl}/subscriptions/plans/name/`,
    updatePlan: `${cdmApiUrl}/subscriptions/plans`,
    updateFeature: `${cdmApiUrl}/subscriptions/features`,
    deletePlan: `${cdmApiUrl}/subscriptions/plans/name/`,
    deleteFeatur: `${cdmApiUrl}/subscriptions/features/name/`,
    deleteFeatureMapping: `${cdmApiUrl}/subscriptions/featureMappings`,
    updateFeatureMapping: `${cdmApiUrl}/subscriptions/featureMappings`,
    meteringUrl: `${cdmApiUrl}/subscriptions/tenantsSubscriptions/featureMeteringData`,

    /* self onvoarding APIs */
    selfonboardTenantCreate: `${tenantBaseUrl}/saveSelfOnboardTenant`,
    selfonboardGetAllTenant: `${cdmOrchBaseUrl}/api/selfOnboard/allTenants`,
    selfonboardSignup: `${tenantBaseUrl}/signup`,
    selfonboardUpdateTenant: `${tenantBaseUrl}/updateSelfOnboardTenant/`,
    selfonboarddisableTenant: `${tenantBaseUrl}/disable/`,
    collaboratorAssign: `${cdmTenantUrl}/assign/tenantAdminUser?email=`,
    onboardClientUser: `${tenantBaseUrl}/subtenants/paged/tenant/`,
    selfSignup: `${iamUserBaseUrl}/createSelfSignUpUser`,
    selfSignupOtp: `${environment?.serverIp}/dsd-orch/nsl-iam/login/otp/generateOTPForSelfSignup`,
    verifySignupOtp: `${environment?.serverIp}/dsd-orch/nsl-iam/login/otp/validateOTPForSelfSignup`,
    getAdminsList: `${cdmTenantUrl}/environment`,

    /* Signup Configuration */
    getSignupConfig: `${cdmOrchBaseUrl}/api/config/tenantconfiguration/`,
    fetchSignupProperties: `${cdmOrchBaseUrl}/api/cdm/envConfigInfo?`,
    fetchGsiMappings: `${cdmOrchBaseUrl}/api/config/publicRoles/gsimappings`,
    deleteGsiMappings: `${cdmOrchBaseUrl}/api/config/tenantconfiguration/gsimappings/role/`,

    /**tenants API */
    getPaginateTenants: `${tenantBaseUrl}/tenants/all?`,
    createTenant: `${tenantBaseUrl}/savetenant?`,
    saveTenantTheme: `${environment.dsdOrchUrl}/design/tenant/design`,
    getTenantTheme: `${environment.dsdOrchUrl}/design/tenant/design`,
    updateTenant: `${tenantBaseUrl}/updatetenant/`,
    getAllAssignSolution: `${tenantBaseUrl}/saassolutionspage?`,
    getTenentAssociatedSolutions: `${tenantBaseUrl}/assocfortenant/`,
    mapFeaturesToTenant: `${cdmApiUrl}/subscriptions/addonMappings`,
    featuresMappedToTenant: `${cdmApiUrl}/subscriptions/addonMappings/tenantName/`,
    deleteFeatureMapp: `${cdmApiUrl}/subscriptions/addonMappings`,
    updateFeatureMapp: `${cdmApiUrl}/subscriptions/addonMappings`,
    /**environment related api's */
    environmentsForTenant: `${tenantBaseUrl}/environments?tenantId=`,
    remainingEnv: `${tenantBaseUrl}/envRemaining?tenantId=`,
    createEnvironment: `${tenantBaseUrl}/environment`,
    /** book */
    getPaginateBooks: `${environment.dsdOrchUrl}/dsd-bets-store/tenant/book/matching`,
    getPaginateGSIsBasedOnROle: `${environment.dsdOrchUrl}/dsd-bets-store/tenant/gsi/matching`,
    getGsiIdFromMasterId: `${environment.dsdOrchUrl}/dsd-bets-store/tenant/gsi/masterId`,

    /** users API */
    getAllUser: `${iamUserBaseUrl}/getAllUsers?type=graph`,
    getPaginateUser: `${iamUserBaseUrl}/getAllUsers?`,
    getPaginateUsers: `${iamUserBaseUrl}/getAllUsersBySearchTerm?`,
    getPaginateUnAssignUsers: `${iamUserBaseUrl}/getUnAssignedManagerUsers?`,
    getUsersByRights: `${nslIAMUrl}/rights/fetchUsersForTriggerCu?`,
    createUser: `${tenantBaseUrl}/create/user`,
    updateUser: `${iamUserBaseUrl}/update/user?`,
    updateUserPassword: `${iamUserBaseUrl}/update/password?`,
    deleteUser: `${tenantBaseUrl}/delete/user?`,
    createSocialUser: `${iamUserBaseUrl}/createSocialUser`,
    confirmDeleteModal: `${iamUserBaseUrl}/delete/user?`,
    importUserFromCSV: `${nslIAMUrl}/user/import/userstoenv/csv`,
    getActiveUser: `${iamUserBaseUrl}/getActiveUser`,
    getUsersCount: `${iamUserBaseUrl}/getUsersCount`,
    loadUnassignedRoleUsers: `${iamUserBaseUrl}/getUnAssignedRoleUsers?`,
    loadAssignedRoleUsers: `${iamRolesBaseUrl}/getRoleUsersById?`,
    loadUnassignedGroupUsers: `${iamUserBaseUrl}/getUnAssignedGroupUsers?`,
    loadAssignedGroupUsers: `${iamUserBaseUrl}/getUsersByFilter?`,
    loadUserDelegations: `${iamUserBaseUrl}/getDelegations?`,
    createDelega: `${iamUserBaseUrl}/saveDelegation`,
    getDetailsByTargetUserId: `${iamUserBaseUrl}/getDelegationBetweenUsersWithRule/`,
    deleteDelegat: `${iamUserBaseUrl}/delegation?`,
    getDelegatById: `${iamUserBaseUrl}/getDelegation?`,
    getTimeone: `${iamUserBaseUrl}/getAvailableTimezones`,
    //getTenantDesignData: `${environment.dsdOrchUrl}/design/tenant/public/design/`,
    getPasswordPolicyByAdmin: `${iamUserBaseUrl}/getPasswordPolicyByAdmin?`,
    setPasswordPolicyByAdmin: `${iamUserBaseUrl}/setPasswordPolicyByAdmin`,
    ////api/realm/setAccountLockParameters
    getAccountLockParameter: `${nslOTP}/api/realm/fetchAccountLockParameters?`,
    setAccountLockParameter: `${nslOTP}/api/realm/setAccountLockParameters`,

    getInvitationHistory: `${nslIAMUrl}/b2c/getB2CSharedNotifications`,
    invitingUsers: `${iamRolesBaseUrl}/addUserstoSpace`,
    getGsiDetail: `${environment.dsdOrchUrl}/dsd-bets-store/tenant/gsi/`,

    /** GE board */
    getAllGsisForgeBorad: `${nslbetAnalyzer}action-gsi/id?id=`,
    saveEntityCard: `${geEntitycard}save`,
    getAllEntitiesForRole: `${geEntitycard}role/`,
    getEntityCardData: `${geEntitycard}card/`,
    getEntityData: `${dashboardUrl}txn-ge-search/facet-search?`,
    getSearchRecords: `${dsdOrch}/query/txn-ge-search/query`,
    /** FileExplorer API*/
    getAllBooks: `${customTenantUI}/assets/book-cover?`,
    getCardIcons: `${customTenantUI}/assets/card-icon?`,
    getCuIcons: `${customTenantUI}/assets/cu-icon?`,
    getSolutionIcons: `${customTenantUI}/assets/gsi-icon?`,
    getEntityIcons: `${customTenantUI}/assets/entity-icon?`,
    addBookCover: `${customTenantUI}/assets/book-cover`,
    addCardCover: `${customTenantUI}/assets/card-icon`,
    solutionAddIcon: `${customTenantUI}/assets/gsi-icon`,
    cuAddIcon: `${customTenantUI}/assets/cu-icon`,
    entityAddIcon: `${customTenantUI}/assets/entity-icon`,
    withFolder: `${dsdFileStore}folder/`,
    withoutFolder: `${dsdFileStore}folder`,
    deleteFolderImage: `${dsdFileStore}manage`,
    withFileFolder: `${dsdFileStore}folder/`,
    withoutFileFolder: `${dsdFileStore}folder?`,
    browseFile: `${dsdFileStore}browse?query=`,
    singleFileUpload: `${dsdFileStore}upload/file`,
    multipleFileUpload: `${dsdFileStore}upload/files`,
    base64FileUpload: `${dsdFileStore}upload/content`,

    /** ERD API */
    uploadERDfile: `${environment.dsdOrchUrl}/extract/biz_notation`,
    saveImportedERDEntity: `${environment.dsdOrchUrl}/dsd-bets-store/tenant/save/erd-entities`,
    allExtractedEntity: `${environment.dsdOrchUrl}/dsd-bets-store/erd/view/erd-entities?`,
    viewExtracedEntity: `${environment.dsdOrchUrl}/dsd-bets-store/erd/view/extracted-entity?`,
    deleteExtracedEntity: `${environment.dsdOrchUrl}/dsd-bets-store/erd/delete/extracted-entity?`,

    /** Gruop API*/
    getAllGroups: `${iamGroupsBaseUrl}/getAll`,
    getPaginateGroups: `${iamGroupsBaseUrl}/getAllGroups?`,
    createUpdateGroup: `${iamGroupsBaseUrl}/add`,
    loadUnassignedRoleGroups: `${iamGroupsBaseUrl}/getAllUnAssigned?`,
    loadAssignedRoleGroups: `${iamGroupsBaseUrl}/getAllAssigned?`,
    loadUnassignedLdapGroups: `${iamLdapGroupsBaseUrl}/getAllUnAssigned?`,
    loadAssignedLdapGroups: `${iamLdapGroupsBaseUrl}/getAllAssigned?`,
    loadAllLdapGroups: `${iamLdapGroupsBaseUrl}/getAll?`,
    assignLdapGroupToGroup: `${iamGroupsBaseUrl}/assignLdapGroups/`,
    unAssignLdapGroupToGroup: `${iamGroupsBaseUrl}/unassignLdapGroup?`,
    assignUsersToManager: `${nslOTP}/api/user/addUsersToManager?managerID=`,
    unAssignUserToManager: `${nslOTP}/api/user/unAssignUserToManager/`,

    assignedUsersList: `${nslOTP}/api/user/getReportees?userId=`,
    getActiveSessionsCount: `${nslOTP}/api/user/getActiveSessionsCount?clientId=`,
    getActiveUserSessionDetails: `${nslOTP}/api/user/getActiveUserSessionDetails?clientId=`,
    /**Ldap API */
    createLDAP: `${nslIAMUrl}/ldap/add`,
    syncLDAPUsers: `${nslIAMUrl}/ldap/sync/users`,
    syncLDAPGroups: `${nslIAMUrl}/ldap/sync/groups`,
    syncLDAP: `${nslIAMUrl}/ldap/sync/usersgroups`,
    getLdapNames: `${nslIAMUrl}/ldap/getAll?`,
    ldapDetailsByName: `${nslIAMUrl}/ldap/get/`,
    getReportsForSolution: `${environment.dsdOrchUrl}/query/v3/report/txns/report`,

    /** manage audit API */
    getAuditData: `${auditUrl}/audit/getAuditData?`,

    /** EQL entities relation API */
    getEqlEntitiesRelation: `${query}/entity_relation/`,
    setEqlEntitiesRelation: `${query}/entity_relation/save`,

    elasticsearch: `${searchUrl}/v3/nsl/transactions/`,
    googleSearchAll: `${searchUrl}/google_search/all`,
    transactionUrlsecmecc: `${secmcc}`,
    eventsUrl: `${tfTransactionUrl}/event`,

    versionFinishURL: `${environment.dsdOrchUrl}/extsolns/api/version`,
    extsend: `${environment.dsdOrchUrl}/extsolns/api/ext_api/process`,
    solutionversioining: `${environment.dsdOrchUrl}/solution-versioning/api`,

    /** Marketplace Apis */
    ontologyMaster: `${dsdBetsStoreUrl}/ontology/master`,
    browseDetailed: `${globalBetsStore}/browse/detailed`,
    // browseDetailedOverview: `${globalBetsStore}/browse/detailed/overview/`,
    // browseOverview: `${globalBetsStore}/browse/overview/`,
    betsStoreImport: `${dsdBetsStoreUrl}/import/`,
    betStoreBookImport: `${dsdBetsStoreUrl}/import/book`,
    betsStorePublish: `${dsdBetsStoreUrl}/publish/`,
    importAndValidateFromBetStore: `${dsdBetsStoreUrl}/import/validate`,
    betsStorePublishtoNSL: `${dsdBetsStoreUrl}/publish/review/book/`,
    getBookEntityList: `${dsdBetsStoreUrl}/publish/fetch/entity/`,
    getBooksDetailList: `${dsdBetsStoreUrl}/publish/review/`,
    nslBookOverview: `${dsdBetsStoreUrl}/global/browse/book/overview`,
    nslBookOverviewDetailed: `${dsdBetsStoreUrl}/global/browse/book/overview/detailed`,
    nslAddReview: `${dsdBetsStoreUrl}/review/library/review/add`,
    nslGetReviews: `${dsdBetsStoreUrl}/review/library/reviews`,

    // Get dashboard list for book
    getBookDashboardList: `${importexport}/bookId`,

    /** Marketplace notepad Apis */
    getNotepadList: `${dsdBetsStoreUrl}/browse/notepad`,

    /** Library overview Apis */
    myLibraryDetailedOverview: `${dsdBetsStoreUrl}/my-library/browse/detailed/overview/`,
    myLibraryOverview: `${dsdBetsStoreUrl}/my-library/browse/overview/`,

    /** Solution Versioning Apis */
    betsStoreTenant: `${dsdBetsStoreUrl}/tenant/`,
    editPublishedBET: `${dsdBetsStoreUrl}/tenant/edit/`,
    solutionDiffGSI: `${environment.dsdOrchUrl}/solution-versioning/api/diff/GSI?draftId=`,
    solutionDiffCU: `${environment.dsdOrchUrl}/solution-versioning/api/diff/CU?draftId=`,
    solutionDiffEntity: `${environment.dsdOrchUrl}/solution-versioning/api/diff/entity?draftId=`,
    versionsListGsi: `${environment.dsdOrchUrl}/extsolns/api/version/v3/gsi`,
    versionsListInitiate: `${environment.dsdOrchUrl}/extsolns/api/version/v3/initiate`,
    versionFinishGSI: `${dsdBetsStoreUrl}/tenant/finish/gsi`,
    versionFinishCU: `${dsdBetsStoreUrl}/tenant/finish/change-unit`,
    versionFinishEntity: `${dsdBetsStoreUrl}/tenant/finish/entity`,
    versionPublishGSI: `${dsdBetsStoreUrl}/tenant/publish/gsi`,
    versionPublishCU: `${dsdBetsStoreUrl}/tenant/publish/change-unit`,
    versionPublishEntity: `${dsdBetsStoreUrl}/tenant/publish/entity`,
    versionReviewerGSI: `${environment.dsdOrchUrl}/solution-versioning/api/version/reviewer/`,
    versionReviewerCU: `${environment.dsdOrchUrl}/solution-versioning/api/cu/version/reviewer/`,
    versionReviewerEntity: `${environment.dsdOrchUrl}/solution-versioning/api/entity/version/reviewer/`,
    sendNotifications: `${notificationsUrl}/notification/send`,
    sendfilterNotifications: `${notificationsUrl}/notification/filter?page=0&size=10`,

    skipVersionPublishGSI: `${dsdBetsStoreUrl}/tenant/publish/gsi?skipApproval=true`,
    skipVersionPublishCU: `${dsdBetsStoreUrl}/tenant/publish/change-unit?skipApproval=true`,
    skipVersionPublishEntity: `${dsdBetsStoreUrl}/tenant/publish/entity?skipApproval=true`,
    detectReviewInfo: `${environment.dsdOrchUrl}/nsl-approvals/api/review/details/`,
    showReviewStatus: `${environment.dsdOrchUrl}/nsl-approvals/api/review/details/`,
    cancelReview: `${dsdBetsStoreUrl}/tenant/reject/`,

    /** A2A api */
    extractionApi: `${environment.dsdOrchUrl}/a2aOrchestrator/extractFromFile`,
    extractionList: `${environment.dsdOrchUrl}/dsd-track/status-track`,
    extractedSolutionListApi: `${environment.dsdOrchUrl}/dsd-track/status/`,

    /** Entities*/
    reservedEntities: `${dsdBetsStoreUrl}/global/entity/reserved-action`,
    entityCount: `${tfCoreUrl}/Entity/matching`,
    getAllEntities: `${environment.dsdOrchUrl}/dsd-bets-store/tenant/entity`,
    getExactEntityByName: `${environment.dsdOrchUrl}/core/Entity/`,
    getAttributeByEntityId: `${environment.dsdOrchUrl}/dsd-bets-store/tenant/entity`,
    getAttributeByEntityIdForReservedActions: `${globalBetsStore}/entity/reserved-action/`,
    multipleEntitySave: `${dsdBetsStoreUrl}/tenant/multiple/entity`,
    /**attributes */
    listFunctions: `${tfCoreUrl}/attributeTypes/list/functions`,
    uiControls: `${tfCoreUrl}/UIAttribute`,
    attrTypes: `${tfCoreUrl}/attributeTypesWithDetails`,
    getAllAttributes: `${environment.dsdOrchUrl}/dsd-bets-store/custom-attribute/fetch`,
    getAttributesByName: `${environment.dsdOrchUrl}/dsd-bets-store/custom-attribute/fetch/name/`,
    getAttributesByID: `${environment.dsdOrchUrl}/dsd-bets-store/custom-attribute/fetch/id/`,

    /**labels */
    staticLabels: `${tfCoreUrl}/translator/tags/`,
    /**changeunit */
    ReservedCuList: `${dsdBetsStoreUrl}/change-unit/reserved/browse`,
    ReservedEntityList: `${dsdBetsStoreUrl}/entity/reserved/browse`,
    gsiLight: `${dsdBetsStoreUrl}/tenant/gsi/basic/`,
    savecu: `${environment.dsdOrchUrl}/dsd-bets-store/tenant/change-unit`,
    getBasicCu: `${dsdBetsStoreUrl}/tenant/change-unit`,
    convertCuToGsi: `${tfCoreUrl}/CU/convert`,
    /** GSI API url's */

    addSeqCu: `${tfCoreUrl}/CU`,
    getAllGsis: `${dsdBetsStoreUrl}/tenant/gsi/matching`,
    getAllTenantBookB2c: `${dsdOrch}/nsl-iam/api/roles/CU`,
    getAllTenantBooks: `${dsdBetsStoreUrl}/tenant/book`,
    saveGsi: `${dsdBetsStoreUrl}/tenant/gsi`,
    multipleGsiSave: `${dsdBetsStoreUrl}/tenant/multiple/gsi`,
    saveBook: `${dsdBetsStoreUrl}/tenant/book`,
    getBookStatus: `${dsdBetsStoreUrl}/tenant/book/matching?`,
    getBookDesign: `${dsdOrch}/design/book/design`,
    gsiVersion: `${tfCoreUrl}/version/`,
    gsiDraft: `${tfCoreUrl}/GSI-Draft`,
    updateGsiRights: `${tfCoreUrl}/GSI/`,
    exportGsi: `${importexport}/Export/GSI`,
    exportApplication: `${importexport}/Export/Application`,
    exportApplicationToEnv: `${importexport}/Export/ApplicationToEnvironment?envType=`,
    importExportHistory: `${importexport}/application`,
    importGsi: `${importexport}/Import/GSI/File`,
    validateGsiFile: `${importexport}/validate/GSI/File`,
    saveDraftCu: `${dsdOrch}/dsd-bets-store/tenant/change-unit`,
    fetchTriggerCu: `${dsdOrch}/dsd-bets-store/tenant/triggerCU`,
    saveCompositeGsi: `${dsdBetsStoreUrl}/tenant/gsi/composite-save`,
    convertGsiToJsonToXml: `${dsdOrch}/reverse_bpmn`,
    filteredTransactions: `${dsdOrch}/query/transaction/transactions-by-values`,
    /** Design Data */
    getBooksWithDesignData: `${dsdBetsStoreUrl}/tenant/book/matching?`,
    storeBookData: `${customTenantUI}/book/design`,
    storeCardData: `${customTenantUI}/book/card/design`,
    getIndustries: `${dsdBetsStoreUrl}/ontology/root`,
    getColors: `${customTenantUI}/assets/color?`,
    getWallpapers: `${customTenantUI}/assets/background-theme?`,
    getBookDetails: `${customTenantUI}/book/v2/design/`,
    getGsiDetails: `${customTenantUI}/book/v2/design/gsis/`,
    getCuDetails: `${customTenantUI}/book/v2/design/cus/`,
    getEntityDetails: `${customTenantUI}/book/v2/design/entities/`,
    // save gsi in a book  //
    saveGsiInBook: `${dsdBetsStoreUrl}/tenant/book/`,
    // layout customise apis //
    tenantPageLayout: `${customTenantUI}/tenant/v2/site/page/layout`,
    setRoleTOLayout: `${customTenantUI}/tenant/v2/site/config`,

    /** Ads Service */
    getAds: `${environment.dsdOrchUrl}/advertisement/getAds`,
    adImpression: `${environment.dsdOrchUrl}/ads-collection/analytics/impression`,
    adClick: `${environment.dsdOrchUrl}/ads-collection/analytics/click`,

    /**Global Search */

    searchBooks: `${globalSearch}/book/`,
    searchGsi: `${globalSearch}/gsi/`,
    searchCu: `${globalSearch}/cu/`,
    searchEntity: `${globalSearch}/entity/`,
    searchAttributes: `${globalSearch}/attributes/`,
    googleSearch: `${dsdOrch}/search/google_search/all`,

    /** Notepad API*/

    globalSearchforNotepad: `${globalSearchforNotepad}`,
    saveNotepad: `${dsdBetsStoreUrl}/save/notepad`,
    getNotepadContent: `${dsdBetsStoreUrl}/notepad`,

    // Tenant Custom UI //
    fileUpload: `${environment.dsdOrchUrl}/dsd-files-store/upload/file`,
    customTenantUi: `${environment.dsdOrchUrl}/design/tenant/v2/public/design/`,
    sendTenantDesign: `${environment.dsdOrchUrl}/design/tenant/v2/design`,
    uploadContent: `${environment.dsdOrchUrl}/dsd-files-store/upload/content`,

    // MCC //
    mccRoles: `${environment.dsdOrchUrl}/design/tenant/v2/site/`,

    // Admin BlockChain //
    accesskeys: `${environment.dsdOrchUrl}/blockchain-auth/auth/keys/search`,
    generateAccess: `${environment.dsdOrchUrl}/blockchain/execute-chaincode-lifecycle`,
    newChannel: `${environment.dsdOrchUrl}/blockchain/create-new-channel`,
    createAccessKey: `${environment.dsdOrchUrl}/blockchain-auth/auth/key`,
    deleteAccessKey: `${environment.dsdOrchUrl}/blockchain-auth/auth/key/delete`,

    /** Carnival API urls */
    addUserProfile: `${environment.dsdOrchUrl}/design/user/profile`,
    getCarnivalFeatureFlag: `${cdmOrchBaseUrl}/api/featureflags/featureFlag?featureName=`,
    getCarnivalMatchingFeatureFlag: `${cdmOrchBaseUrl}/api/featureflags/featureFlagState?featureName=`,
    getAllFeatureFlags: `api/cdm/api/featureflags/allFeatureFlags`,
    getGSIsWithExecutionStatus: `${environment.dsdOrchUrl}/execute/executionStatus/`,
    generateOtpForLogin: `${environment.dsdOrchUrl}/nsl-iam/login/otp/generateOtpWithAuthentication?mobileNumber=`,
    validateGivenOtp: `${environment.dsdOrchUrl}/nsl-iam/login/otp/validateOtpWithAuthentication?mobileNumber=`,

    //authentication policy
    getAuthPolicy: `${cdmApiUrl}/authconfig/get/current`,
    addAuthPolicy: `${cdmApiUrl}/authconfig/create`,
    updateAuthPolicy: `${cdmApiUrl}/authconfig/update`,

    // external configuration //
    getCategoriesURL: `${environment.dsdOrchUrl}/extsolns/ext_config/fetchCategories`,
    getSubCategoriesURL: `${environment.dsdOrchUrl}/extsolns/ext_config/fetchSubCategories/categoryName/`,
    getConfigurationURL: `${environment.dsdOrchUrl}/associate/reserved-entity/`,
    updateConfigurationURL: `${environment.dsdOrchUrl}/execute/update/entity-records`,

    //Calendar
    txnSearch: `${environment.dsdOrchUrl}/txn/search/entity-records`,
    calendarScheduler: `${environment.dsdOrchUrl}/adapter/calendar/schedule`,
    calendarSlots: `${environment.dsdOrchUrl}/adapter/calendar/entity-records`,
    //Eql api
    getEqlFilter: `${tfCoreUrl}/eql/FILTER`,
    getEqlOperator: `${tfCoreUrl}/eql/OPERATOR`,
    getEqlPredicate: `${tfCoreUrl}/eql/PREDICATE`,
    geteqlFunction: `${tfCoreUrl}/eql/function`,

    // EQL WhiteBoard
    eqlCanvasValidator: `${environment.dsdOrchUrl}/report/query-canvas/validator`,
    eqlBuilderValidator: `${environment.dsdOrchUrl}/report/query-validator/nsl-query`,

    //Adaptors endpoints
    testConnection: `${serverIp}/adapter/api/v1/connect/name/`,
    addAdapter: `${environment.dsdOrchUrl}/adapter/api/v1/integration/`,
    addAdapterv2: `${environment.dsdOrchUrl}/adapter/api/v2/integration/`,
    addAdapterv3: `${environment.dsdOrchUrl}/adapter/api/v3/integration/`,
    saveFileAsEntity: `${environment.dsdOrchUrl}/adapter/api/v1/converters/File`,
    add2file: `${environment.dsdOrchUrl}/adapter/api/v1/integration/file`,
    addinputfile: `${environment.dsdOrchUrl}/adapter/api/v1/integration/fileInput`,
    addoutputfile: `${environment.dsdOrchUrl}/adapter/api/v1/integration/fileOutput`,
    addSwagger: `${environment.dsdOrchUrl}/adapter/api/v1/resource/swagger-resources`,
    entityList: `${environment.dsdOrchUrl}/dsd-bets-store/tenant/entity?`,
    listConnection: `${environment.dsdOrchUrl}/adapter/ext_config/fetchSubCategories/categoryName/Adapter`,
    viewAdapter: `${environment.dsdOrchUrl}/adapter/api/v3/integration/list?pageNumber=`,
    filterConnection: `${environment.dsdOrchUrl}/associate/reserved-entity/`,
    postrest: `${environment.dsdOrchUrl}/adapter/api/v1/connect/rest`,
    postsftp: `${environment.dsdOrchUrl}/adapter/connect/sftp`,
    aimlSubmit: `${environment.dsdOrchUrl}/aiml/api/v1/connect/`,
    addAdapteraiml: `${environment.dsdOrchUrl}/aiml/api/v1/integration`,
    aimlStatus: `${environment.dsdOrchUrl}/aiml/api/v1/status/connect`,
    // postSSHsftp: `${environment.dsdOrchUrl}/adapter/api/v1/connect/sftp`,
    postS3: `${environment.dsdOrchUrl}/adapter/connect/s3`,
    postGoogle: `${environment.dsdOrchUrl}/adapter/connect/google`,
    postZoom: `${environment.dsdOrchUrl}/adapter/connect/zoom`,
    postCisco: `${environment.dsdOrchUrl}/adapter/connect/cisco`,
    postGraph: `${environment.dsdOrchUrl}/adapter/connect/graph`,
    postKafka: `${environment.dsdOrchUrl}/adapter/api/v1/connect/kafka/`,
    postSMTP: `${environment.dsdOrchUrl}/adapter/connect/smtp/`,
    postIMAP: `${environment.dsdOrchUrl}/adapter/connect/imap`,
    postPOP3: `${environment.dsdOrchUrl}/adapter/connect/pop3`,
    postDB: `${environment.dsdOrchUrl}/adapter/connect/db`,
    connectionCheck: `${environment.dsdOrchUrl}/adapter/connect/sftp/test`,
    connectionDetails: `${environment.dsdOrchUrl}/adapter/api/v3/connections?`,

    //Permission Sets APIs
    fetchPermissionSets: `${nslIAMUrl}/po/get-all-features?`,
    addPermissionSets: `${nslIAMUrl}/po/save-bulk-permission-mapping`,

    //FE757
    //Elastic search
    saveTypeAheadSearchConfiguration: `${dsdOrch}/query/context-search/config`,
    fetchTypeAheadAttributeValues: `${dsdOrch}/query/txn-ge-search/attr_search`,
    getentityName: `${environment.dsdOrchUrl}/dsd-bets-store/tenant/entity/`,
    //subtransactional Entity
    getSubtransactionaEntity: `${dsdBetsStoreUrl}/subtransaction/subtransactionalEntity`,
    saveSubtransactionalGsi: `${dsdBetsStoreUrl}/subtransaction/subtransactionalCU`,
    //edit gsi
    publishedgsi: `${dsdBetsStoreUrl}/tenant/all-published-gsis`,
    publishGsiById: `${dsdBetsStoreUrl}/tenant/gsi/slot-item-properties`,
    editGsiDtrtApi: `${dsdOrch}/solution-versioning/solcustomization/save-customization`,
    getIdDtrtApi: `${dsdOrch}/solution-versioning/solcustomization/get-customization-by-targetid`,
    getReviewDtrtApi: `${dsdOrch}/solution-versioning/solcustomization/review`,
    revertChangesDtrtApi: `${dsdOrch}/solution-versioning/solcustomization/revert-changes`,
    applyChangesDtrtApi: `${dsdOrch}/solution-versioning/solcustomization/apply-changes-to-Bet-store`,
    //dtrt milestone
    getReviewMileStoneDtrtApi: `${dsdOrch}/solution-versioning/solcustomization/milestones/review`,
    revertMileStoneDtrtApi: `${dsdOrch}/solution-versioning/solcustomization/milestones/revert-changes`,
    applyMileStoneDtrtApi: `${dsdOrch}/solution-versioning/solcustomization/milestones/apply-changes`,
    //dtrt irdr
    fetchDtrtIRDR: `${dsdOrch}/solution-versioning/solcustomization/irdr/get-gsidto`,
    getReviewDtrtIRDR: `${dsdOrch}/solution-versioning/solcustomization/irdr/review`,
    revertChangesDtrtIRDR: `${dsdOrch}/solution-versioning/solcustomization/irdr/revert-changes`,
    applyChangesDtrtIRDR: `${dsdOrch}/solution-versioning/solcustomization/irdr/apply-changes`,
    //dtrt reserved action
    getReviewDtrtReservedAction: `${dsdOrch}/solution-versioning/solcustomization/reservedAction/review`,
    revertChangesDtrtReservedAction: `${dsdOrch}/solution-versioning/solcustomization/reserved-action/revert-changes`,

    //Node System Multiple Cu Save
    saveMultipleCu: `${environment.dsdOrchUrl}/dsd-bets-store/tenant/multiple/change-unit`,
    saveTriggerCu: `${environment.dsdOrchUrl}/dsd-bets-store/tenant/triggerCU`,
    updateCu: `${environment.dsdOrchUrl}/dsd-bets-store/tenant/change-unit`,
    //Exceptional Category
    getExceptionalCategory: `${dsdOrch}/core/exception/categories`,

    // Ad Dashboard API
    getAdDashboard: `${dsdOrch}/ads-analytics/analytics/admin`,
    // For Milestone
    getMilestone: `${dsdOrch}/milestones/v1/id/`,
    getAllMilestone: `${dsdOrch}/milestones/v1/milestones?`,
    saveMilestone: `${dsdOrch}/milestones/v1/milestone`,
    deleteMilestone: `${dsdOrch}/milestones/v1/delete/`,
    updateMilestone: `${dsdOrch}/milestones/v1/update`,

    //special feature dashboard
    specialFeatures: `${specialFeatures}/admin/dashboard/jobs`,
    scheduledJobs: `${specialFeatures}/admin/dashboard/view/jobs`,
    jobHistory: `${specialFeatures}/admin/dashboard/job/history`,
    rerunData: `${specialFeatures}/admin/dashboard/execute/job`,
    jobSuspension: `${specialFeatures}/admin/dashboard/job/suspend/`,
    jobResume: `${specialFeatures}/admin/dashboard/job/resume/`,
    jobDelete: `${specialFeatures}/admin/dashboard/job/delete/`,
    // geolocation
    geolocationurl: `http://ip-api.com/json`,
    getAllStatus: `${specialFeatures}/admin/dashboard/job/status`,
    getallspecialfeatures: `${dsdOrch}/core/specialFeatureTypes`,
    // Publisher Dashboard
    getPublisherDashboards: `${dsdOrch}/ads-analytics/analytics/publisher`,
    // Advertiser Dashboard
    getAdvertiserDashboards: `${dsdOrch}/ads-analytics/analytics/advertiser`,

    //milestone transactions
    notifyMilestone: `${dsdOrch}/milestones/v1/transaction/notify`,
    getParticularMilestone: `${dsdOrch}/milestones/v1/transaction/details?gsiId=`,
    getpercentMilestoneList: `${dsdOrch}/milestones/v1/transactions/percentage?gsiId=`,
    getMilestoneList: `${dsdOrch}/milestones/v1/transactions?gsiId=`,

    //gsi blocking
    getAllGsiInTenant: `${environment.dsdOrchUrl}/dsd-bets-store/tenant/gsi/all`,
    updategsiConfigurationURL: `${environment.dsdOrchUrl}/execute/update/entity-records`,
    getGsiConfigurationURL: `${environment.dsdOrchUrl}/associate/reserved-entity/name/NSL_GSIMaintenance_Configuration`,
    saveGsiConfigurationURL: `${environment.dsdOrchUrl}/execute/save/entity-records`,
    // Pushback feature - FE757
    corePushbacks: `${dsdOrch}/core/pushbacks`,
    transPushbacks: `${dsdOrch}/txn/transaction/lifecycle/pushback`,
    pushbackChangeUnit: `${dsdOrch}/execute/pushback`,
    transPushbackComment: `${dsdOrch}/txn/transaction/lifecycle/comment`,

    //Scheduled jobs dash board APIs:
    getScheduledJobs: `${scheduledJobs}/v1/scheduled-job-details?`,
    getJobTriggers: `${scheduledJobs}/scheduled-gsi-triggers?`,
    getAllTriggers: `${scheduledJobs}/scheduled-triggers?`,
    gsiExecutions: `${scheduledJobs}/scheduled-gsi-executions?`,
    getUnscheduleJobs: `${scheduledJobs}/trigger/unschedule`,
    getUnscheduleTriggers: `${scheduledJobs}/trigger/unschedule`,
    pauseTrigger: `${scheduledJobs}/trigger/pause`,
    resumeTrigger: `${scheduledJobs}/trigger/resume`,
    pauseJob: `${scheduledJobs}/job/pause`,
    resumeJob: `${scheduledJobs}/job/resume`,

    /** Predective Analytics */
    paListUseCases: `${predectiveAnalytics}/ListUseCases`,
    paListFeatures: `${predectiveAnalytics}/ListFeatures`,
    fetchGsidetails: `${predectiveAnalytics}/gsi_attributes`,
    saveConfig: `${predectiveAnalytics}/SaveConfig`,
    updateConfig: `${predectiveAnalytics}/UpdateConfig`,
    tenantUsecases: `${predectiveAnalytics}/TenantUsecases`,
    deleteConfig: `${predectiveAnalytics}/delete_configid`,
    reservedRecomendation: `${environment.dsdOrchUrl}/nslgrammar/recommend_rcu`,
    populateReservedCU: `${environment.dsdOrchUrl}/nslgrammar/populate_rcu`,
    getMethodName: `${environment.dsdOrchUrl}/nslgrammar/get_method_names`,
    featureValues: `${predectiveAnalytics}/FeatureValues`,
    predictionsData: `${predectiveAnalytics}/DashboardPredictions`,
    chartSelection: `${predectiveAnalytics}/ChartSelection`,
    fetchFeatureDetail: `${dsdOrch}/api/po/get-all-features`,
    downloadfileApi: `${dsdOrch}/adapter/execute/swagger-json/`,

    //Dymamic schedule job
    scheduleJob: `${dsdOrch}/special-features/dynamic/schedule/jobs`,
    saveScheduleJob: `${dsdOrch}/special-features/dynamic/schedule/job`,
    getEntityByName: `${tfCoreUrl}/Entity`,
    getSubscriptionList: `${cdmApiUrl}/subscriptions/tenantsSubscriptions/tenantName/`,
    activateNow: `${cdmApiUrl}/subscriptions/tenantsSubscriptions/tenantName/`,
    getAllPlans: `${cdmApiUrl}/subscriptions/plans/planAndFeatures/`,
    getPlans: `${cdmApiUrl}/subscriptions/plans/paginated/planAndFeatures`,
    unsubscribePlan: `${cdmApiUrl}ext_api/process`,
    rearrangeCU: `${environment.dsdOrchUrl}/dsd-bets-store/tenant`,
    StripeRedirectBuyPlan: `${cdmApiUrl}/payments/createSession/subscriptionPurchase`,
    StripeRedirectBuyAddOn: `${cdmApiUrl}/payments/createSession/addon/`,

    //Key management
    saveManageKey: `${dsdOrch}/adapter/api/v1/keys`,
    //My Library
    libraryConfig: `${environment.dsdOrchUrl}/design/tenant/v2/config`,
    libraryLayout: `${environment.dsdOrchUrl}/design/tenant/v2/site/page/layout`,
    //get All campaign List for Advertiser dashboard
    getAllCampaignList: `${dsdOrch}/advertisement/adsdata/campaign/campaignList`,
    getAllSolutionList: `${dsdOrch}/ads-analytics/analytics/solutionList`,
    download: `${dsdOrch}/query/dashboards/export/`,
    subscribedsh: `${environment.dsdOrchUrl}/query/dashboards/export/subscribe/`,
    // Related GSIs list
    relatedGsis: `${specialFeatures}/related/connectors`,
    //RearrangeEntity
    reArrangeEntity: `${environment.dsdOrchUrl}/dsd-bets-store/tenant/insert/entity`,

    //business details
    saveBusiness: `${environment.cdmServerAPI}/api/config/businessDetails/`,
    saveHolidayDetails: `${environment.cdmServerAPI}/api/config/holidays/`,
    saveKeyDetails: `${environment.cdmServerAPI}/api/config/keyManagementPersonnel/`,
    getKeyDetails: `${environment.cdmServerAPI}/api/config/KeyManagementPersonnel/`,
    //batch-processing api Import/Export
    batchAPI: `${dsdOrch}/batch-processor/batch-api/`,
    versionList: `${dsdOrch}/milestones/v1/transactions/versions?gsiId=`,
    assignAsTenant: `${environment.cdmServerAPI}/api/cdm/assign/tenantAdminUser?email=`,
    unassignAsTenant: `${environment.cdmServerAPI}/api/cdm/unassign/tenantAdminUser?email=`,
    assignAsEnv: `${environment.cdmServerAPI}/api/cdm/assign/`,
    unassignAsEnv: `${environment.cdmServerAPI}/api/cdm/unassign/`,

    CUToGSIConversion: `${dsdOrch}/core/CU/convert`,
    deleteCu: `${environment.dsdOrchUrl}/dsd-bets-store/tenant/remove/CU`,

    // onboarding tenant admin configuration
    cdmCompanyProfile: `${cdmOrchBaseUrl}/api/config/companyProfiles`,
    saveBooksAndSolutionsInCart: `${dsdBetsStoreUrl}/cart/update/items`,
    cartDetails: `${dsdBetsStoreUrl}/cart/details`,
    updateCartDetails: `${dsdBetsStoreUrl}/cart/update/status`,
    bdpInfo: `${cdmOrchBaseUrl}/api/config/bdpinfo`,
    freelancerInfo: `${cdmOrchBaseUrl}/api/config/freelancerinfo`,
    tenantconfiguration: `${cdmOrchBaseUrl}/api/config/tenantconfiguration`,
    gsiMappingsByRole: `${cdmOrchBaseUrl}/api/config/tenantconfiguration/gsimappings/`,

    //file otp validation
    fileOtpValidation: `${environment.dsdOrchUrl}/dsd-files-store/secureotp`,
    refundProcess: `${cdmApiUrl}/subscriptions/cancellation/refund/`,
    updateProfile: `${tenantBaseUrl}/update/selfuser?originalEmail=`,
    passwordReseting: `${nslOTP}/api/user/update/password/v2?type=graph`,
    botbasic: `${cdmApiUrl}/chatbotConfiguration/chatbotDetails/`,
    faqSave: `${dsdOrch}/chatbot/api/v1/`,
    unassignedTenant: `${environment.cdmServerAPI}/api/cdm/unassociated/saas/tenants/`,
    assignedTenant: `${environment.cdmServerAPI}/api/cdm/assocforsolution/`,
    availableUser: `${iamOwnershipBaseUrl}/page/available/users`,
    getBooksByOwner: `${iamOwnershipBaseUrl}/filter/books`,
    getSolutionsByOwner: `${iamOwnershipBaseUrl}/filter/gsis`,
    assignOwnerToBookSol: `${iamOwnershipBaseUrl}/assign-users`,
    unassignOwnerToBookSol: `${iamOwnershipBaseUrl}/unassign-users`,
    assignedOwnerToBookSol: `${iamOwnershipBaseUrl}/page/assigned/users`,
    viewClients: `${cdmApiUrl}/cdm/subtenants/paged/tenant/`,
    createClient: `${cdmApiUrl}/cdm/subtenants/`,
    onAssigningSubTenant: `${nslIAMUrl}/tenantspace/sharebookstosubtenants`,
    mapRoleToParent: `${nslIAMUrl}/rolemapping/save?isParent=true`,
    mappingRoles: `${nslIAMUrl}/rolemapping/mapped-unmapped/roles/?`,
    deleteAssigned: `${nslIAMUrl}/rolemapping/subtenantrolemappings/`,
    getGSI: `${nslIAMUrl}/owner-ship/filter/gsis?`,

    /* AR */
    detectARImage: `https://taas-dev.nslhub.com/nsl_ar/detect`,
    trainARGsi: `https://taas-dev.nslhub.com/nsl_ar/train`,
  };
}

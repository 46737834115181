// `.env.ts` is generated by the `npm run env` command
// `npm run env` exposes environment variables as JSON for any usage you might
// want, like displaying the version or getting extra config from your CI bot, etc.
// This is useful for granularity you might need beyond just the environment.
// Note that as usual, any environment variables you expose through it will end up in your
// bundle, and you should not use it for any sensitive information like passwords or keys.
// import { env } from './.env';

const serverIp = '';
const dsdOrchServerIp = serverIp + '/dsd-orch';
const cdmServerAPI = dsdOrchServerIp + '/cdm';
const dsdFileStore = 'https://int3.nslhub.com/dsd-files-store/';
const elasticAPM = 'https://apm.nslhub.com';
const aadsUrl = 'https://t360-dev.nslhub.com';
const googleMaps = 'https://maps.googleapis.com';
const paasLiveChatAgent = 'https://qachatbot.nslhub.com';
const cdnUrl = '';
const defaultTenantName = '';
export const environment = {
  production: true,
  hmr: false,
  version: '1.0-dev',
  firebase: {
    apiKey: 'AIzaSyDBnQAxVAse7-PkPw5O9_CjcJRVwIZgcsA',
    authDomain: 'brane-nslhub.firebaseapp.com',
    databaseURL: 'https://brane-nslhub.firebaseio.com',
    projectId: 'brane-nslhub',
    storageBucket: 'brane-nslhub.appspot.com',
    messagingSenderId: '1003392715797',
    appId: '1:1003392715797:web:b6a544c1f5be3ab535921c',
    measurementId: 'G-2TNQ5M5ZH4',
  },
  name: 'Production',
  defaultLanguage: 'en',
  supportedLanguages: ['en'],
  elasticAPMUrl: elasticAPM,
  dsdOrchUrl: dsdOrchServerIp,
  cdmServerAPI: cdmServerAPI,
  serverIp: serverIp,
  loginApiKey: '',
  loginAuth: 'https://iam.nslhub.com/auth',
  aadsApplicationUrl: aadsUrl,
  googleAnalyticsTag: 'G-ZR6B8T6YGD',
  googleTagManager: 'GTM-TKFHKXW',
  googleMaps: googleMaps,
  paasLiveChatAgent: paasLiveChatAgent,
  cdnUrl,
  defaultTenantName,
};

<!-- <div class="login-container login-main-wrapper">
  <div class="login-box">
    <div class="middle-content">
      <div class="w-100">
        <div>
          <h4 class="card-title">Forgot Password ?</h4>
        </div>
        <form>
          <div class="example-container">
            <mat-form-field appearance="legacy" class="d-block mat-custom-input">
              <mat-label>Enter Registered Email /Mobile Number</mat-label>
              <input
                type="email"
                id="registeredEmailId"
                email
                matInput
                [(ngModel)]="email"
                name="email"
                class="mt-3"
                required
                placeholder="Enter Registered Email"
                class="mt-3"
              />
            </mat-form-field>
          </div>
          <div class="forgot-btns">
            <button class="forgot-submit" id="forgotSubmit" (click)="clickSubmit()">{{ labels?.Submit }}</button>
            <button class="forgot-cancel" id="forgotPswdCancel" (click)="cancelClick()">{{ labels?.Cancel }}</button>
          </div>
        </form>
      </div>
    </div>
  </div> -->

<div class="main" *ngIf="isCustomLoginTenant">
  <div class="login">
    <figure class="logo-top" *ngIf="isLmsTenant">
      <img [src]="'../../assets/img/login/NG-logo.png' | CDNUrlRewritePipe" alt="" />
    </figure>
    <figure class="banner-img">
      <img [src]="'../../assets/images/login-screen/Login Graphic.png' | CDNUrlRewritePipe" alt="" />
    </figure>
    <form>
      <div class="login-details">
        <h1>Forgot Password</h1>
        <input
          type="email"
          id="registeredEmailId"
          [(ngModel)]="email"
          name="email"
          required
          placeholder="Enter Registered Email /Mobile Number"
          class="forgot-password-input"
        />
        <div class="forgot-btns">
          <button id="forgotSubmit" (click)="clickSubmit()" class="btn-submit">
            <p>{{ labels?.Submit }}</p>
          </button>
          <button id="forgotPswdCancel" (click)="cancelClick()" class="btn-cancel">
            <p>{{ labels?.Cancel }}</p>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="login-container login-main-wrapper" *ngIf="!isCustomLoginTenant">
  <div class="login-box login-wrapper">
    <div class="middle-content">
      <div class="login-left-content">
        <ng-lottie [options]="lottieOptions" class="login-globe"></ng-lottie>
      </div>
      <div class="login-right-content">
        <div class="w-100">
          <div>
            <h4 class="card-title">Forgot Password ?</h4>
          </div>
          <form>
            <div class="form-field">
              <div class="icon-filed">
                <img [src]="'../../assets/img/login/icon-email.svg' | CDNUrlRewritePipe" />
              </div>
              <input
                type="email"
                class="form-control"
                id="registeredEmailId"
                email
                [(ngModel)]="email"
                name="email"
                required
                placeholder="Enter Registered Email /Mobile Number"
              />
            </div>

            <!-- <div class="example-container">
              <mat-form-field appearance="legacy" class="d-block mat-custom-input">
                <mat-label>Enter Registered Email /Mobile Number</mat-label>
                <input
                  type="email"
                  id="registeredEmailId"
                  email
                  matInput
                  [(ngModel)]="email"
                  name="email"
                  class="mt-3"
                  required
                  placeholder="Enter Registered Email"
                  class="mt-3"
                />
              </mat-form-field>
            </div> -->
            <div class="footer-cls">
              <button class="btn-custom" id="forgotSubmit" (click)="clickSubmit()">{{ labels?.Submit }}</button>
              <button class="btn-custom btn-cancel" id="forgotPswdCancel" (click)="cancelClick()">
                {{ labels?.Cancel }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { pluck } from 'rxjs/operators';
import { CduiBaseService } from '@app/auth/cdui-base.service';
@Injectable({
  providedIn: 'root',
})
export class TransactionEndpointService extends CduiBaseService {
  constructor(private injector: Injector, private http: HttpClient) {
    super(injector);
  }

  getNextChangeUnitByGSIId(postData: Object) {
    return this.http.post(this.api?.transactionUrl, postData).pipe(pluck('result'));
  }

  getListChangeUnitByGSIId(postData: Object, gsiDraft: boolean, isDebug: boolean, isSubtransaction?: any) {
    if (isDebug) {
      const headerDict = {
        'Debug-Mode': 'true',
      };
      const requestOptions = {
        headers: new HttpHeaders(headerDict),
      };
      return this.http.post(this.environment.dsdOrchUrl + '/execute/change-unit', postData, requestOptions);
    }
    if (gsiDraft) {
      // Save draft data of change-unit
      return this.http.post(this.environment.dsdOrchUrl + '/execute/execute/txn-draft', postData);
    } else if (isSubtransaction) {
      // Fetch change unit data
      return this.http.post(
        this.environment.dsdOrchUrl +
          `/execute/change-unit/subTransactionalCU?triggerContextualID=${isSubtransaction?.triggerContextualID}`,
        postData
      );
    } else {
      // Fetch change unit data
      return this.http.post(this.environment.dsdOrchUrl + '/execute/change-unit', postData);
    }
  }

  // conditional potentiality
  attrConditionalPotentiality(postData: Object) {
    return this.http
      .post(this.environment.dsdOrchUrl + '/execute/refresh/dynamic-attributes', postData)
      .pipe(pluck('result'));
  }

  getResumeChangeUnitByGSIId(postData: Object) {
    return this.http.post(this.api?.transactionresumeUrl, postData).pipe();
  }
  getPaginatedRecords(postData: Object, pageNumber: number, pageSize: number) {
    return this.http.post(this.api.getPaginatedRecords + `?pageNumber=${pageNumber}&pageSize=${pageSize}`, postData);
  }
  reAssign(txnid: any, userid: any, assignComment: any, payload?: any) {
    return this.http.post(
      this.api?.reAssign + 'transactionId=' + txnid + '&userId=' + userid + '&comment=' + assignComment,
      payload
    );
  }
  escalateTxn(txnid: any, escalateComment?: any, payload?: any) {
    return this.http.post(this.api?.escalateTxn + 'comment=' + escalateComment, payload);
  }
  cancelAutoSubmit(txnid: any, payload?: any) {
    return this.http.post(this.api?.cancelAutoSubmitTxn + 'transactionId=' + txnid, payload);
  }
  getCreatedTxn(pagenum: any, pageSize: any, searchText: any, originatingBookId?: any) {
    originatingBookId = originatingBookId ? originatingBookId : '';
    return this.http.get(
      this.api?.getCreatedTxn +
        'page=' +
        pagenum +
        '&size=' +
        pageSize +
        '&containerCuName=' +
        searchText +
        '&originatingBookId=' +
        originatingBookId
    );
  }

  IsJsonString(str: any) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  downloadFile(downloadUrl: string, fileToBeDownloaded?: string) {
    let credentials;
    /* istanbul ignore next */
    if (this.IsJsonString(localStorage.getItem('credentials'))) {
      credentials = JSON.parse(localStorage.getItem('credentials'));
    }
    const options: {
      headers?: HttpHeaders;
      responseType: 'blob';
    } = {
      headers: new HttpHeaders({
        Authorization: `Bearer ` + credentials?.token,
      }),
      responseType: 'blob',
    };
    let blob: any;
    let link = document.createElement('a');

    this.http.get(`${downloadUrl}`, options).subscribe((res: any) => {
      /* istanbul ignore next */
      if (res) {
        console.log('file downloaded', res);
        blob = res;
        link.download = fileToBeDownloaded;
        link.href = URL.createObjectURL(blob);
        link.click();
        URL.revokeObjectURL(link.href);
      }
    });
  }
  getAssignedTxn(pagenum: any, pageSize: any, searchText: any, originatingBookId?: any) {
    originatingBookId = originatingBookId ? originatingBookId : '';
    return this.http.get(
      this.api?.getAssignedTxn +
        'page=' +
        pagenum +
        '&size=' +
        pageSize +
        '&containerCuName=' +
        searchText +
        '&originatingBookId=' +
        originatingBookId
    );
  }
  getEscalatedTxn(pagenum: any, pageSize: any, searchText: any, originatingBookId?: any) {
    originatingBookId = originatingBookId ? originatingBookId : '';
    return this.http.get(
      this.api?.getEscalatedTxn +
        'page=' +
        pagenum +
        '&size=' +
        pageSize +
        '&containerCuName=' +
        searchText +
        '&originatingBookId=' +
        originatingBookId
    );
  }
  getPendingTransactions(pagenum: any, pageSize: any, searchText: any) {
    return this.http.get(
      this.api?.pendingTransactionUrl +
        'containerCUName=' +
        searchText +
        '' +
        '&pageNo=' +
        pagenum +
        '&pageSize=' +
        pageSize
    );
  }

  getTransactionHistoryCuList(transactionId: string, gsiId: string) {
    return this.http.get(this.api?.getTransactionHistoryCuList + transactionId + '/' + gsiId);
  }

  getCuTransactionHistory(transactionId: string, cuContextualId: string) {
    return this.http.get(this.api?.getCuTransactionHistory + transactionId + '/' + cuContextualId);
  }

  getTransactionHistoryEmbeddedCuList(transactionId: string, gsiId: string) {
    return this.http.get(this.api?.getTransactionHistoryCuList + transactionId + '/' + gsiId);
  }

  getCuEmbeddedTransactionHistory(transactionId: string, cuContextualId: string) {
    return this.http.get(this.api?.getCuTransactionHistory + transactionId + '/' + cuContextualId);
  }

  ///get reserved actions ///
  getReservedActionsList(body: any) {
    return this.http.get(
      this.api?.getReservedActionsList +
        'gsiId=' +
        body.gsiId +
        '&transactionId=' +
        body.transID +
        '&triggerCUId=' +
        body.triggerCuId
    );
  }
  ///advanced search ///
  advancedSearchTxn(postData: any) {
    return this.http.post(this.api?.advancedSearchTxn, postData);
  }
  getFilteredTransactions(body: any) {
    return this.http.post(this.api?.filteredTransactions, body);
  }
  // getAllTransactions(pagenum: any, pageSize: any, searchText: any) {
  //   return this.http.post(
  //     this.api?.getAllTransactions + 'limit=' + pageSize + '&pageNumber=' + pagenum + '&searchText=' + searchText,
  //     ''
  //   );
  // }
  txnStatusChange(status: any, txnId: any, payload?: any) {
    if (status == 'SUSPENDED' || status == 'ABORTED' || status == 'RESUMED' || status == 'WITHDRAWN') {
      return this.http.put(this.api?.txnStatusChange + 'triggerStateType=' + status, payload);
    } else {
      return this.http.post(this.api?.txnStatusall + '/' + status + '/' + txnId, payload);
    }
  }

  /**
   * to get ads based on gsi id and user information
   * @param gsiData
   */
  getTransactionAds(gsiData: any) {
    return this.http.post(`${this.api?.getAds}`, gsiData).pipe(pluck('response'));
  }

  /**
   * impression call when ad displayed on UI
   * @param impressionData
   */
  makeImpression(impressionData: any) {
    return this.http.post(`${this.api?.adImpression}`, impressionData).pipe(pluck('response'));
  }

  /**
   * Click this.api call when user clicks on specific ad
   * @param clickedData
   */
  adClick(clickedData: any) {
    return this.http.post(`${this.api?.adClick}`, clickedData).pipe(pluck('response'));
  }
  //this.api call to get reserved actions hostory
  getReservedActionsHistory(transactionId: string, cuContextualId: string) {
    return this.http.get(this.api?.getReservedActionsHistory + '/' + transactionId + '/' + cuContextualId);
  }
  /**
   * to get location details based on IP
   */
  getLocation() {
    return this.http.get(`${this.api?.geolocationurl}`);
  }

  //Sub-transactional entity
  getSubtransactionalEntity(postData: Object) {
    const headers = { Accept: 'application/json' };
    return this.http.post(`${this.api?.getSubtransactionaEntity}`, postData, { headers }).pipe(pluck('result'));
  }
  getPreviousCUData(queryParamsData: any, isDebug: boolean) {
    const { txnId, triggerCUId, rootGSIId, previousCUContextualId } = queryParamsData;
    let body = new URLSearchParams();
    body.set('rootGSIId', rootGSIId);
    body.set('previousCUContextualId', previousCUContextualId);
    body.set('bookId', '1');

    console.log(txnId);
    console.log(typeof txnId);

    // ${triggerCUId}?`+ body.toString()
    const url = this.api?.previousCuData + txnId + `/${triggerCUId}?` + body.toString();
    if (isDebug) {
      const headerDict = {
        'Debug-Mode': 'true',
      };
      const requestOptions = {
        headers: new HttpHeaders(headerDict),
      };
      return this.http.get(url, requestOptions);
    }

    return this.http.get(url);
  }

  getGmapLocation(latitude: any, longitude: any) {
    return this.http.get(`${this.api?.googleGeoLocation}${latitude},${longitude}`);
  }

  // Pushback transaction API - FE757
  postTransPushbackData(data: any) {
    return this.http.post(this.api?.pushbackChangeUnit, data);
  }

  debugData(postData: any) {
    const headerDict = {
      'Debug-Mode': 'true',
    };
    const payLoad = postData;
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return this.http.post(this.api?.debug, payLoad, requestOptions);
  }

  debugValue(postData: any) {
    return this.http.get(
      this.api?.debugValue +
        postData.transactionId +
        '?cuId=' +
        postData.cuId +
        '&cuContextualId=' +
        postData.cuContextualId
    );
  }
  /**
   * Dashboard for scheduled jobs
   */
  /*gets all scheduled jobs list */
  getJobdetails(pagenum: any, pageSize: any, searchText: any) {
    return this.http.get(
      this.api?.getScheduledJobs + 'pageNumber=' + pagenum + '&pageSize=' + pageSize + '&searchText=' + searchText
    );
  }

  /*gets list of all triggers based on gsiname and gsiId */
  getJobtriggers(gsiId: number, gsiName: any, pagenum: any, pageSize: any) {
    return this.http.get(
      this.api?.getJobTriggers +
        'gsiId=' +
        gsiId +
        '&gsiName=' +
        gsiName +
        '&pageNumber=' +
        pagenum +
        '&pageSize=' +
        pageSize
    );
  }

  /*gets list of all paginated triggers */
  getAlltriggers(pagenum: any, pageSize: any) {
    return this.http.get(this.api?.getAllTriggers + 'pageNumber=' + pagenum + '&pageSize=' + pageSize);
  }
  /*Scheduled GSI executions */
  gsiExecutions(pagenum: any, pageSize: any, data: any) {
    return this.http.post(this.api?.gsiExecutions + 'pageNumber=' + pagenum + '&pageSize=' + pageSize, data);
  }

  /*unschedules a jobs */
  getUnscheduleJobs(data: Object) {
    return this.http.post(this.api?.getUnscheduleJobs, data);
  }

  /*unschedules a trigger */
  getUnscheduleTriggers(data: Object) {
    return this.http.post(this.api?.getUnscheduleTriggers, data);
  }

  /*pauses trigger */
  pauseTrigger(data: Object) {
    return this.http.post(this.api?.pauseTrigger, data);
  }
  /*resume trigger */
  resumeTrigger(data: Object) {
    return this.http.post(this.api?.resumeTrigger, data);
  }

  /*pauses job */
  pauseJob(data: Object) {
    return this.http.post(this.api?.pauseJob, data);
  }
  /* resumes job */
  resumeJob(data: Object) {
    return this.http.post(this.api?.resumeJob, data);
  }

  // Save pushback comment data
  postTransPushbackCommentData(transId: any, data: any) {
    return this.http.post(this.api?.transPushbackComment + `?txnId=${transId}`, data);
  }
}

import { HttpRequest, HttpResponse, HttpInterceptor, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';

abstract class HttpCache {
  abstract get(req: HttpRequest<any>): HttpResponse<any> | null;
  abstract put(req: HttpRequest<any>, resp: HttpResponse<any>): void;
}

@Injectable()
export class HttpCacheService implements HttpCache {
  private cahce = {};

  put(req: HttpRequest<any>, resp: HttpResponse<any>): void {
    /* istanbul ignore next */
    this.cahce[req?.urlWithParams] = resp;
  }

  get(req: HttpRequest<any>): HttpResponse<any> | null {
    /* istanbul ignore next */
    return this.cahce[req?.urlWithParams];
  }
}

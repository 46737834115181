import { forEach } from 'cypress/types/lodash';
import { Injectable } from '@angular/core';
import { EventsEndpointService } from './events-endpoint.service';
import { Subject, BehaviorSubject } from 'rxjs';
import { LoaderService } from '../loader/loader.service';
import { takeUntil } from 'rxjs/operators';
import { Validators, ValidationErrors, FormControl } from '@angular/forms';
import { FieldConfig } from '@app/@shared/models/field.interface';

@Injectable({
  providedIn: 'root',
})
export class EventsFacadeService {
  currentEntInd: any;
  tempArray: any = [];
  scormStatus = new BehaviorSubject('');
  scormStatus$ = this.scormStatus.asObservable();
  public scormScore = new BehaviorSubject('');
  scormScore$ = this.scormScore.asObservable();
  dependentDropdownResponse = new Subject();
  dependentDropdownResponse$ = this.dependentDropdownResponse.asObservable();
  // ELastic Search - FE757
  private typeAHeadResponse = new Subject();
  typeAHeadResponse$ = this.typeAHeadResponse.asObservable();

  typeAHeadEvent = new Subject();
  typeAHeadEvent$ = this.typeAHeadEvent.asObservable();

  cuEvents = new Subject();
  ngUnsubscribe = new Subject();
  eventTrigger = new Subject();
  eventTriggerCondition = new Subject();
  cuEventsResponse$ = this.cuEvents.asObservable();
  triggeredEvents: any[] = [];
  selectedCuData: any;
  gsiName: any;
  delegationId: any;
  formData: any;
  entityList: any;
  accordianIndex: any = -1;
  tableScrollEvent = new Subject();
  tableScrollEvent$ = this.tableScrollEvent.asObservable();
  tableScrollPosition: any = [];
  disableContextualSearchSubejct: Subject<boolean> = new Subject<boolean>();
  disbaleContextualSearch$ = this.disableContextualSearchSubejct.asObservable();

  disableEntitRef: Subject<boolean> = new Subject<boolean>();
  disableEntityRef$ = this.disableEntitRef.asObservable();

  isPhysicalLayerVideo = new Subject();
  isPhysicalLayerVideo$ = this.isPhysicalLayerVideo.asObservable();
  attributeCount: any;
  attrData: any;

  constructor(private eventEndpointService: EventsEndpointService, private loader: LoaderService) {}

  /**
   * Sets delegation id for the update delegation screen
   * @param value
   */
  setDelegationId(value: any) {
    this.delegationId = value;
  }

  /**
   * Gets delegation id of the delegation that needs to be updated.
   * @returns
   */
  getDelegationId() {
    return this.delegationId;
  }

  changeUnitEvents(data: any, cuId: any, entityDetails: any, layers: any) {
    this.loader.show();
    this.tableScrollEvent.next();
    layers.forEach((layer: any) => {
      layer.entityList.sort((entity1: any, entity2: any) => {
        return entity1.slot - entity2.slot;
      });
    });
    this.eventEndpointService
      .setChangeUnitEvents(data, cuId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        let index: any;
        /*istanbul ignore next*/
        if (entityDetails?.isInfo) {
          index = layers?.findIndex((i: any) => i.type == 'information');
        } else {
          index = layers?.findIndex((i: any) => i.type == 'physical');
        }
        /*istanbul ignore next*/
        if (index !== -1) {
          let slotNumber = entityDetails?.slotNumber
            ? entityDetails?.slotNumber
            : Number(entityDetails.entityName.split('$')[1]);
          let entityDet = data.currentCU.layers[index].entityList.find((e: any) => e.slot == slotNumber);
          this.findTargetEntity(layers, index, entityDetails, entityDet);
          if (layers[index].entityList[slotNumber - 1].type === 'entity') {
            let nestedEntityIndex = Number(entityDetails.entityName.split('$')[1]);
            // this.tempArray.push(nestedEntityIndex);
            let eventEntity: any = res.result.currentCU.layers[index]?.entityList.filter(
              (e: any) => e.slot == slotNumber
            );
            // let layerData: any;
            eventEntity = eventEntity[0]?.nslAttributes[nestedEntityIndex - 1];
            this.updateArrayObject(
              layers[index]?.entityList[slotNumber - 1],
              0,
              eventEntity,
              slotNumber,
              layers,
              entityDetails,
              res
            );
          }
          res.result.currentCU.layers = layers;
        }
        this.cuEvents.next({ res: res, entityDetails: entityDetails });
      });
  }

  updateArrayObject(
    entityData: any,
    index: number,
    eventEntity: any,
    slotNumber: number,
    layers: any,
    entityDetails: any,
    res: any
  ) {
    /* istanbul ignore next */
    if (this.tempArray.length > 0) {
      /* istanbul ignore next */
      if (entityData?.nslAttributes[this.tempArray[index]] && this.tempArray.length - 1 !== index) {
        this.updateArrayObject(
          entityData?.nslAttributes[this.tempArray[index]],
          index + 1,
          eventEntity,
          slotNumber,
          layers,
          entityDetails,
          res
        );
      } else {
        // let indexNumber = this.tempArray[index]/this.attributeCount;
        entityData.nslAttributes.splice(this.tempArray[index], 1, eventEntity);
      }
    } else {
      let entityName = entityDetails.entityName.split('$')[0];
      let ind = Number(entityDetails.entityName.split('$')[1]);
      let attributeIndex: any = parseInt(entityDetails?.attrName?.split('$')[1]);
      let indexNumber = Math.floor(attributeIndex / entityData?.nslAttributes.length);
      let currentEntInd = entityDetails.isTableConfig ? ind + indexNumber - 1 : ind - 1;
      let findReplaceEntity = res.result.currentCU.layers[index]?.entityList.find((x: any) => x.name === entityName);
      layers[index]?.entityList.splice(currentEntInd, 1, findReplaceEntity);
      layers[index].entityList = layers[index]?.entityList.flat();
    }
  }

  getDependentDropdownDetails(payload: any, eventType: string, changeDriver: any, entityDetails: any) {
    this.loader.show();
    this.tableScrollEvent.next();
    this.eventEndpointService
      .getDependentDropdownDetails(payload, eventType, changeDriver)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.dependentDropdownResponse.next({ res: res, entityDetails: entityDetails });
      });
  }

  findTargetEntity(layers: any, index: any, entityDetails: any, data: any) {
    this.tempArray = [];
    /* istanbul ignore next */
    layers[index].entityList?.every((ent: any, index: any) => {
      if (ent.name.split('$')[0] == entityDetails.entityName.split('$')[0]) {
        ent.nslAttributes.forEach((attr: any) => {
          if (attr.type == 'entity') {
            attr.nslAttributes.forEach((attribute: any, nestedAttrIndex: number) => {
              if (attribute.name + '$' + attribute.attr_order_num === entityDetails.attrName) {
                this.tempArray.push(nestedAttrIndex);
                this.currentEntInd = nestedAttrIndex;
                // this.attributeCount = data.nslAttributes[index].nslAttributes.length
              }
            });
          }
        });
        return false;
      } else {
        this.getNestedEntityData(ent, entityDetails, data);
      }
      return true;
    });
  }

  /** Get the target entity from nested entities*/
  getNestedEntityData(ent: any, entityDetails: any, data: any) {
    /* istanbul ignore next */
    ent.nslAttributes?.every((val: any, ind: any) => {
      if (val.type == 'entity') {
        /* istanbul ignore next */
        if (val.name.split('$')[0] == entityDetails.entityName.split('$')[0]) {
          ent.nslAttributes.forEach((attr: any, nestedAttrIndex: number) => {
            if (attr.type == 'entity') {
              attr.nslAttributes.forEach((attribute: any) => {
                if (attribute.name + '$' + attribute.attr_order_num === entityDetails.attrName) {
                  this.tempArray.push(nestedAttrIndex);
                  this.currentEntInd = nestedAttrIndex;
                  // this.attributeCount = data.nslAttributes[nestedAttrIndex].nslAttributes.length
                }
              });
            }
          });
          return false;
        } else {
          this.getNestedEntityData(val, entityDetails, data);
        }
      }
      return true;
    });
  }

  updateScormData(data: any) {
    this.scormStatus.next(data);
  }
  updateScormScore(data: any) {
    this.scormScore.next(data);
  }
  /* conditional potentiality start  */
  setEvent(data: any) {
    this.eventTrigger.next(data);
  }

  isPhysicalLayerVideos(isPhysicalLayerVideo: boolean) {
    this.isPhysicalLayerVideo.next(isPhysicalLayerVideo);
  }

  getEvent() {
    return this.eventTrigger.asObservable();
  }
  /* conditional potentiality ends  */

  setTriggerEvent(data: any) {
    this.eventTriggerCondition.next(data);
  }

  getTriggerEvent() {
    return this.eventTriggerCondition.asObservable();
  }
  getDisablePropertiesList(propertyName: any) {
    let propertiesArr: any = [];
    switch (propertyName) {
      case 'notAfterDate':
        propertiesArr = ['notAfterDays'];
        return propertiesArr;

      case 'notAfterDays':
        propertiesArr = ['notAfterDate'];
        return propertiesArr;

      case 'notBeforeDate':
        propertiesArr = ['notBeforeDays'];
        return propertiesArr;

      case 'notBeforeDays':
        propertiesArr = ['notBeforeDate'];
        return propertiesArr;
    }
  }
  getDisableValidatorsList(constraintName: any) {
    let constraintsArr: any = [];
    switch (constraintName) {
      case 'positive':
        constraintsArr = ['negative', 'positiveorzero', 'zero', 'min', 'max', 'notzero'];
        return constraintsArr;

      case 'notzero':
        constraintsArr = ['positive', 'positiveorzero', 'zero', 'min', 'max', 'negativeorzero'];

        return constraintsArr;

      case 'zero':
        constraintsArr = ['positive', 'positiveorzero', 'negative', 'min', 'max', 'notzero'];
        return constraintsArr;

      case 'min':
      case 'max':
        constraintsArr = ['negativeorzero', 'positive', 'positiveorzero', 'zero', 'notzero'];
        return constraintsArr;
      case 'negativeorzero':
      case 'negative':
        constraintsArr = ['positive', 'positiveorzero', 'zero', 'min', 'max', 'notzero'];
        return constraintsArr;

      case 'asserttrue':
      case 'required':
      case 'assertfalse':
        constraintsArr = [];
        return constraintsArr;

      case 'notempty':
        constraintsArr = ['empty'];
        return constraintsArr;

      case 'empty':
        constraintsArr = ['length'];
        return constraintsArr;

      case 'length':
        constraintsArr = ['notempty'];
        return constraintsArr;

      case 'positiveorzero':
        constraintsArr = ['negative', 'positive', 'zero', 'min', 'max', 'notzero'];
        return constraintsArr;

      case 'pastorpresent':
        constraintsArr = ['present', 'pastorfuture', 'presentorfuture', 'past'];
        return constraintsArr;
      case 'present':
        constraintsArr = ['past', 'pastorfuture', 'presentorfuture', 'pastorpresent'];
        return constraintsArr;
      case 'past':
      case 'future':
        constraintsArr = ['present', 'pastorfuture', 'presentorfuture', 'pastorpresent'];
        return constraintsArr;
      case 'presentorfuture':
        constraintsArr = ['present', 'pastorfuture', 'past', 'pastorpresent'];
        return constraintsArr;
      case 'pastorfuture':
        constraintsArr = ['present', 'past', 'future', 'presentorfuture', 'pastorpresent'];
        return constraintsArr;
    }
  }

  clearOnLoadEvents() {
    this.triggeredEvents = [];
  }

  getValidations(constraints: any, uielement: any = null) {
    let validationsArray: any = [];
    /*istanbul ignore next*/
    constraints?.forEach((obj: any) => {
      if (this.addValidations(obj, uielement)) {
        validationsArray.push(this.addValidations(obj, uielement));
      }
    });
    return validationsArray;
  }

  addValidations(obj: any, uielement: any) {
    let validObject: any;
    switch (obj.name) {
      case 'positive':
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject = {
            name: 'positive',
            validator: Validators.pattern('^[0-9]+[0-9.]*$'),
            message: obj.properties.errorMessage,
            requiredPattern: '^[0-9]+[0-9.]*$',
            type: obj.properties.type,
          };
        } else {
          validObject = {
            name: 'positive',
            message: obj.properties.errorMessage,
            requiredPattern: '^[0-9]+[0-9.]*$',
            type: obj.properties.type,
          };
        }
        return validObject;

      case 'notzero':
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject = {
            name: 'notzero',
            validator: Validators.pattern('^((?!0+$)[0-9.]*$)|-[1-9]+[0-9.]*$'),
            message: obj.properties.errorMessage,
            requiredPattern: '^((?!0+$)[0-9.]*$)|-[1-9]+[0-9.]*$',
            type: obj.properties.type,
          };
        } else {
          validObject = {
            name: 'notzero',
            message: obj.properties.errorMessage,
            requiredPattern: '^((?!0+$)[0-9.]*$)|-[1-9]+[0-9.]*$',
            type: obj.properties.type,
          };
        }
        break;

      case 'zero':
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject = {
            name: 'zero',
            validator: Validators.pattern('^[0-0]+[0-0]*$'),
            message: obj.properties.errorMessage,
            requiredPattern: '^[0-0]+[0-0]*$',
            type: obj.properties.type,
          };
        } else {
          validObject = {
            name: 'zero',
            message: obj.properties.errorMessage,
            requiredPattern: '^[0-0]+[0-0]*$',
            type: obj.properties.type,
          };
        }
        break;

      case 'min':
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject = {
            name: 'min',
            validator: Validators.min(obj.properties.value),
            message: obj.properties.errorMessage,
            value: obj.properties.value,
            type: obj.properties.type,
          };
        } else {
          validObject = {
            name: 'min',
            message: obj.properties.errorMessage,
            value: obj.properties.value,
            type: obj.properties.type,
          };
        }
        break;

      case 'max':
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject = {
            name: 'max',
            validator: Validators.max(obj.properties.value),
            message: obj.properties.errorMessage,
            value: obj.properties.value,
            type: obj.properties.type,
          };
        } else {
          validObject = {
            name: 'max',
            message: obj.properties.errorMessage,
            value: obj.properties.value,
            type: obj.properties.type,
          };
        }
        break;
      case 'smallerThan':
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject = {
            name: obj?.name,
            validator: Validators.required,
            message: obj.properties.errorMessage,
            dependentExpression: obj.properties.dependentExpression,
            type: obj.properties.type,
          };
        } else {
          validObject = {
            name: obj.name,
            validator: Validators.required,
            message: obj.properties.errorMessage,
            dependentExpression: obj.properties.dependentExpression,
            type: obj.properties.type,
          };
        }
        break;

      case 'negative':
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject = {
            name: 'negative',
            validator: Validators.pattern('^-[1-9]+[0-9.]*$'),
            message: obj.properties.errorMessage,
            requiredPattern: '^-[1-9]+[0-9.]*$',
            type: obj.properties.type,
          };
        } else {
          validObject = {
            name: 'negative',
            message: obj.properties.errorMessage,
            requiredPattern: '^-[1-9]+[0-9.]*$',
            type: obj.properties.type,
          };
        }
        break;

      case 'asserttrue':
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject = {
            name: 'asserttrue',
            validator: Validators.pattern('^(true|1)$'),
            message: obj.properties.errorMessage,
            requiredPattern: '^(true|1)$',
            type: obj.properties.type,
          };
        } else {
          validObject = {
            name: 'asserttrue',
            message: obj.properties.errorMessage,
            requiredPattern: '^(true|1)$',
            type: obj.properties.type,
          };
        }
        break;

      case 'assertfalse':
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject = {
            name: 'assertfalse',
            validator: Validators.pattern('^(false|0)$'),
            message: obj.properties.errorMessage,
            requiredPattern: '^(false|0)$',
            type: obj.properties.type,
          };
        } else {
          validObject = {
            name: 'assertfalse',
            message: obj.properties.errorMessage,
            requiredPattern: '^(false|0)$',
            type: obj.properties.type,
          };
        }
        break;

      case 'notempty':
        /*istanbul ignore next*/
        if (uielement == 'textarea') {
          if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
            validObject = {
              name: 'notempty',
              validator: this.checkNotemptyValidation,
              message: obj.properties.errorMessage,
              requiredPattern: '',
              type: obj.properties.type,
            };
          } else {
            validObject = {
              name: 'notempty',
              message: obj.properties.errorMessage,
              requiredPattern: '',
              type: obj.properties.type,
            };
          }
        } else {
          if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
            validObject = {
              name: 'notempty',
              validator: Validators.pattern('^(?!^ +$)^.+$'),
              message: obj.properties.errorMessage,
              requiredPattern: '^(?!^ +$)^.+$',
              type: obj.properties.type,
            };
          } else {
            validObject = {
              name: 'notempty',
              message: obj.properties.errorMessage,
              requiredPattern: '^(?!^ +$)^.+$',
              type: obj.properties.type,
            };
          }
        }
        break;
      case 'afterValidation':
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject = {
            name: obj.name,
            validator: Validators.required,
            message: obj.properties.errorMessage,
            dependentExpression: obj.properties.dependentExpression,
            type: obj.properties.type,
          };
        } else {
          validObject = {
            name: obj.name,
            message: obj.properties.errorMessage,
            dependentExpression: obj.properties.dependentExpression,
            type: obj.properties.type,
          };
        }
        break;
      case 'empty':
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject = {
            name: 'empty',
            validator: Validators.pattern('/^s*$/g'),
            message: obj.properties.errorMessage,
            requiredPattern: '/^s*$/g',
            type: obj.properties.type,
          };
        } else {
          validObject = {
            name: 'empty',
            message: obj.properties.errorMessage,
            requiredPattern: '/^s*$/g',
            type: obj.properties.type,
          };
        }
        break;

      case 'length':
        let lenpattern = '^.{' + obj.properties.value + '}$';
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject = {
            name: 'length',
            validator: Validators.pattern(lenpattern), //Validators.maxLength(Number(obj.properties.value)), //Validators.pattern(lenpattern),
            message: obj.properties.errorMessage,
            requiredPattern: lenpattern,
            type: obj.properties.type,
          };
        } else {
          validObject = {
            name: 'length',
            message: obj.properties.errorMessage,
            requiredPattern: lenpattern,
            type: obj.properties.type,
          };
        }
        break;
      case 'equalTo':
        let equalspattern = '^' + obj.properties.value + '$';
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject = {
            name: 'equalTo',
            validator: Validators.pattern(obj.properties.value),
            message: obj.properties.errorMessage,
            value: obj.properties.value,
            requiredPattern: equalspattern,
            type: obj.properties.type,
          };
        } else {
          validObject = {
            name: 'equalTo',
            message: obj.properties.errorMessage,
            value: obj.properties.value,
            requiredPattern: equalspattern,
            type: obj.properties.type,
          };
        }
        break;
      case 'startWith':
        let startspattern = '^' + obj.properties.startWith + '.{0,}$';
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject = {
            name: 'startWith',
            validator: Validators.pattern(startspattern),
            message: obj.properties.errorMessage,
            requiredPattern: startspattern,
            type: obj.properties.type,
          };
        } else {
          validObject = {
            name: 'startWith',
            message: obj.properties.errorMessage,
            requiredPattern: startspattern,
            type: obj.properties.type,
          };
        }
        break;

      case 'endsWith':
        let endspattern = '^.{0,}' + obj.properties.endsWith + '$';
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject = {
            name: 'endsWith',
            validator: Validators.pattern(endspattern),
            message: obj.properties.errorMessage,
            requiredPattern: endspattern,
            type: obj.properties.type,
          };
        } else {
          validObject = {
            name: 'endsWith',
            message: obj.properties.errorMessage,
            requiredPattern: endspattern,
            type: obj.properties.type,
          };
        }
        break;

      case 'pattern':
        /*istanbul ignore next*/
        if (uielement == 'textarea' || uielement == 'text') {
          if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
            validObject = {
              name: 'pattern',
              validator: Validators.pattern('^' + obj.properties.regExp + '$'),
              message: obj.properties.errorMessage,
              requiredPattern: '^' + obj.properties.regExp + '$',
              type: obj.properties.type,
            };
          } else {
            validObject = {
              name: 'pattern',
              message: obj.properties.errorMessage,
              requiredPattern: '^' + obj.properties.regExp,
              type: obj.properties.type,
            };
          }
        }
        break;
      case 'required':
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject = {
            name: 'required',
            validator: Validators.required,
            message: obj.properties.errorMessage,
            type: obj.properties.type,
          };
        } else {
          validObject = {
            name: 'required',
            message: obj.properties.errorMessage,
            type: obj.properties.type,
          };
        }
        break;

      case 'negativeorzero':
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject = {
            name: 'negativeorzero',
            validator: Validators.pattern('^(-(d)*)|0$'),
            message: obj.properties.errorMessage,
            requiredPattern: '^(-(d)*)|0$',
            type: obj.properties.type,
          };
        } else {
          validObject = {
            name: 'negativeorzero',
            message: obj.properties.errorMessage,
            requiredPattern: '^(-(d)*)|0$',
            type: obj.properties.type,
          };
        }
        break;

      case 'positiveorzero':
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject = {
            name: 'positiveorzero',
            validator: Validators.pattern('^[0-9]*[1-9][0-9]*|0$'),
            message: obj.properties.errorMessage,
            requiredPattern: '^[0-9]*[1-9][0-9]*|0$',
            type: obj.properties.type,
          };
        } else {
          validObject = {
            name: 'positiveorzero',
            message: obj.properties.errorMessage,
            requiredPattern: '^[0-9]*[1-9][0-9]*|0$',
            type: obj.properties.type,
          };
        }
        break;

      case 'pastorpresent':
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject = {
            name: 'pastorpresent',
            validator: Validators.required,
            message: obj.properties.errorMessage,
            notBeforeDate: obj.properties.notBeforeDate,
            notBeforeDays: obj.properties.notBeforeDays,
            notBeforeYear: obj.properties.notBeforeYear,
            type: obj.properties.type,
          };
        } else {
          validObject = {
            name: 'pastorpresent',
            message: obj.properties.errorMessage,
            notBeforeDate: obj.properties.notBeforeDate,
            notBeforeDays: obj.properties.notBeforeDays,
            notBeforeYear: obj.properties.notBeforeYear,
            type: obj.properties.type,
          };
        }
        break;
      case 'present':
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject = {
            name: 'present',
            validator: Validators.required,
            message: obj.properties.errorMessage,
            type: obj.properties.type,
          };
        } else {
          validObject = {
            name: 'present',
            message: obj.properties.errorMessage,
            type: obj.properties.type,
          };
        }
        break;
      case 'past':
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject = {
            name: 'past',
            validator: Validators.required,
            message: obj.properties.errorMessage,
            notBeforeDate: obj.properties.notBeforeDate,
            notBeforeDays: obj.properties.notBeforeDays,
            notBeforeYear: obj.properties.notBeforeYear,
            type: obj.properties.type,
          };
        } else {
          validObject = {
            name: 'past',
            message: obj.properties.errorMessage,
            notBeforeDate: obj.properties.notBeforeDate,
            notBeforeDays: obj.properties.notBeforeDays,
            notBeforeYear: obj.properties.notBeforeYear,
            type: obj.properties.type,
          };
        }
        break;
      case 'future':
        // pattern('^\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d(?:\.\d+)Z$')
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject = {
            name: 'future',
            validator: Validators.required,
            message: obj.properties.errorMessage,
            notAfterDate: obj.properties.notAfterDate,
            notAfterDays: obj.properties.notAfterDays,
            notAfterYear: obj.properties.notAfterYear,
            type: obj.properties.type,
          };
        } else {
          validObject = {
            name: 'future',
            message: obj.properties.errorMessage,
            notAfterDate: obj.properties.notAfterDate,
            notAfterDays: obj.properties.notAfterDays,
            notAfterYear: obj.properties.notAfterYear,
            type: obj.properties.type,
          };
        }
        break;
      case 'presentorfuture':
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject = {
            name: 'presentorfuture',
            validator: Validators.required,
            message: obj.properties.errorMessage,
            notAfterDate: obj.properties.notAfterDate,
            notAfterDays: obj.properties.notAfterDays,
            notAfterYear: obj.properties.notAfterYear,
            type: obj.properties.type,
          };
        } else {
          validObject = {
            name: 'presentorfuture',
            message: obj.properties.errorMessage,
            notAfterDate: obj.properties.notAfterDate,
            notAfterDays: obj.properties.notAfterDays,
            notAfterYear: obj.properties.notAfterYear,
            type: obj.properties.type,
          };
        }
        break;
      case 'pastorfuture':
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject = {
            name: 'pastorfuture',
            validator: Validators.required,
            message: obj.properties.errorMessage,
            notAfterDate: obj.properties.notAfterDate,
            notAfterDays: obj.properties.notAfterDays,
            notBeforeDate: obj.properties.notBeforeDate,
            notBeforeDays: obj.properties.notBeforeDays,
            notBeforeYear: obj.properties.notBeforeYear,
            notAfterYear: obj.properties.notAfterYear,
            type: obj.properties.type,
          };
        } else {
          validObject = {
            name: 'pastorfuture',
            message: obj.properties.errorMessage,
            notAfterDate: obj.properties.notAfterDate,
            notAfterDays: obj.properties.notAfterDays,
            notBeforeDate: obj.properties.notBeforeDate,
            notBeforeDays: obj.properties.notBeforeDays,
            notBeforeYear: obj.properties.notBeforeYear,
            notAfterYear: obj.properties.notAfterYear,
            type: obj.properties.type,
          };
        }
        break;
      case 'filenamelengthtype':
        validObject = {
          name: 'filenamelengthtype',
          message: obj.properties.errorMessage,
          maxLength: obj.properties.maxLength,
          type: obj.properties.type,
        };
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject.validator = Validators.required;
        }
        break;
      case 'maxfilesizetype':
        validObject = {
          name: 'maxfilesizetype',
          message: obj.properties.errorMessage,
          value: obj.properties.value,
          unit: obj.properties.unit,
          type: obj.properties.type,
        };
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject.validator = Validators.required;
        }
        break;
      case 'minfilesizetype':
        validObject = {
          name: 'minfilesizetype',
          message: obj.properties.errorMessage,
          value: obj.properties.value,
          unit: obj.properties.unit,
          type: obj.properties.type,
        };
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject.validator = Validators.required;
        }
        break;
      case 'assertextensiontype':
        validObject = {
          name: 'assertextensiontype',
          message: obj.properties.errorMessage,
          extension: obj.properties.extension,
          type: obj.properties.type,
        };
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject.validator = Validators.required;
        }
        break;
      case 'assertnotextensiontype':
        validObject = {
          name: 'assertnotextensiontype',
          message: obj.properties.errorMessage,
          extension: obj.properties.extension,
          type: obj.properties.type,
        };
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject.validator = Validators.required;
        }
        break;
      case 'filenamepatterntype':
        /*istanbul ignore next*/
        validObject = {
          name: 'filenamepatterntype',
          message: obj.properties.errorMessage,
          requiredPattern: obj.properties.regExp,
          type: obj.properties.type,
        };
        if (obj.properties.type == 'ERROR' || obj.properties.type == 'BLOCK_WARN') {
          validObject.validator = Validators.required;
        }
        break;
    }
    return validObject;
  }

  checkNotemptyValidation(control: FormControl) {
    /*istanbul ignore next*/
    let text = control?.value;
    /*istanbul ignore next*/
    if (!text?.replace(/\s/g, '').length) {
      return true;
    }
    return false;
  }

  getExactErrorMessage(field: FieldConfig, validation: any, group: any) {
    let isError;
    this.validateSmallerThan(field, validation, this.entityList);
    /*istanbul ignore next*/
    const controlErrors: ValidationErrors = group?.controls[field.attribute?.name]?.errors;
    let keyName;
    /*istanbul ignore next*/
    if (controlErrors != null) {
      Object.keys(controlErrors)?.forEach((keyError) => {
        keyName = keyError;
      });
      if (keyName == 'pattern') {
        isError = controlErrors.pattern?.requiredPattern === validation.requiredPattern ? true : false;
      } else if (keyName == 'incorrect') {
        isError = true;
      } else {
        isError = validation?.name === keyName ? true : false;
      }
    }

    return isError;
  }

  // Elastic Search - FE757
  // Fetch contextual search data for attribute at transaction side
  // (i.e. values for typeahead attribute)
  postContextualSearchData(data: any) {
    this.loader.show();
    this.eventEndpointService
      .fetchSearchData(data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.typeAHeadResponse.next(res);
      });
  }
  // passing event to transaction
  addTypeAheadEvent(eventData: any) {
    this.typeAHeadEvent.next(eventData);
  }
  //toggle disable Contextual Search Configuration
  disableContextualSearchConfiguration(val: boolean) {
    this.disableContextualSearchSubejct.next(val);
  }
  // toggle disable Entity Reference
  disableEntityRefRadio(val: boolean) {
    this.disableEntitRef.next(val);
  }
  validateSmallerThan(field: any, validator: any, entityList: any) {
    if (entityList) {
      let ent = validator?.dependentExpression?.split('.');
      Object.entries(entityList).forEach((Entity: any) => {
        if (Entity[1]?.name?.includes(ent[0])) {
          Entity[1]?.nslAttributes?.forEach((attr: any) => {
            if (attr?.name?.includes(ent[1])) {
              this.attrData = attr.values[0];
            }
          });
        }
      });
    }
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}

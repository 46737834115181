import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { GlobalSearchTypeaheadFacadeService } from '@app/@shared/services/global-search-typeahead/global-search-typeahead-facade.service';
import { TranslatorService } from '@app/shell/translator.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-global-search-typeahead',
  templateUrl: './global-search-typeahead.component.html',
  styleUrls: ['./global-search-typeahead.component.scss'],
})
export class GlobalSearchTypeaheadComponent implements OnInit, OnDestroy {
  @Input() searchGSIdata: any;
  @Input() searchEntitydata: any;
  @Input() searchCUdata: any;
  @Input() searchAttributedata: any;
  @Input() searchTextAutocomplete: any;
  @Input() screen = 1;
  private ngUnsubscribe: Subject<any> = new Subject<any>();
  labels: any;
  betTypeSelection: any;
  searchSelected = 'All';
  viewAllSearchOption = '';
  isGSI: boolean = true;
  isCU: boolean = true;
  isEntity: boolean = true;
  isAttribute: boolean = true;
  paginatedGSI: any;
  paginatedCU: any;
  paginatedEntity: any;
  paginatedAttribute: any;
  pageNumber = 1;
  pageSize = 10;
  totalGSIcount = 0;
  totalCUcount = 0;
  totalEntitycount = 0;
  totalAttributecount = 0;
  constructor(
    private globalSearchFacadeService: GlobalSearchTypeaheadFacadeService,
    private translator: TranslatorService
  ) {
    this.betTypeSelection = ['All', 'GSI', 'ChangeUnit', 'Entity', 'Attribute'];
    this.detectLanguageChange();
  }

  ngOnInit(): void {
    console.log(this.searchGSIdata, this.searchCUdata, this.searchEntitydata, this.searchAttributedata);
  }

  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }
  /*changes dropdown options */
  changeOption(e: any) {
    this.screen = 1;
    console.log(e);
    switch (e.value) {
      case 'All':
        this.isGSI = true;
        this.isCU = true;
        this.isEntity = true;
        this.isAttribute = true;
        break;
      case 'GSI':
        this.isGSI = true;
        this.isCU = false;
        this.isEntity = false;
        this.isAttribute = false;
        this.searchCUdata = {};
        this.searchEntitydata = {};
        this.searchAttributedata = {};
        break;
      case 'CHANGE_UNIT':
        this.isGSI = false;
        this.isCU = true;
        this.isEntity = false;
        this.isAttribute = false;
        this.searchGSIdata = {};
        this.searchEntitydata = {};
        this.searchAttributedata = {};
        break;
      case 'Entity':
        this.isGSI = false;
        this.isCU = false;
        this.isEntity = true;
        this.isAttribute = false;
        this.searchCUdata = {};
        this.searchGSIdata = {};
        this.searchAttributedata = {};
        break;
      case 'Attribute':
        this.isGSI = false;
        this.isCU = false;
        this.isEntity = false;
        this.isAttribute = true;
        this.searchCUdata = {};
        this.searchEntitydata = {};
        this.searchGSIdata = {};
        break;
    }
    this.screen = 1;
    //this.getGlobalSearch();
  }
  /*API call to Global search result */
  getGlobalSearch() {
    if (this.isGSI) {
      this.globalSearchFacadeService.getGlobalSearch(this.searchTextAutocomplete, 'GSI');
      this.globalSearchFacadeService.searchData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
        /*istanbul ignore else */
        if (res && res.result) {
          /*istanbul ignore next */
          if (res.result?.type === 'GSI') this.searchGSIdata = res.result;
        }
      });
    }
    if (this.isCU) {
      this.globalSearchFacadeService.getGlobalSearch(this.searchTextAutocomplete, 'CHANGE_UNIT');
      this.globalSearchFacadeService.searchData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
        /*istanbul ignore else */
        if (res && res.result) {
          /*istanbul ignore next */
          if (res.result?.type === 'CHANGE_UNIT') this.searchCUdata = res.result;
        }
      });
    }
    if (this.isEntity) {
      this.globalSearchFacadeService.getGlobalSearch(this.searchTextAutocomplete, 'ENTITY');
      this.globalSearchFacadeService.searchData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
        /*istanbul ignore else */
        if (res && res.result) {
          /*istanbul ignore next */
          if (res.result?.type === 'ENTITY') this.searchEntitydata = res.result;
        }
      });
    }
    if (this.isAttribute) {
      this.globalSearchFacadeService.getGlobalSearch(this.searchTextAutocomplete, 'ATTRIBUTE');
      this.globalSearchFacadeService.searchData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
        /*istanbul ignore else */
        if (res && res.result) {
          /*istanbul ignore next */
          if (res.result?.type === 'ATTRIBUTE') this.searchAttributedata = res.result;
        }
      });
    }
  }
  /*opens different BETs on View All */
  changeSearchOption() {
    switch (this.viewAllSearchOption) {
      case 'GSI':
        this.isGSI = true;
        this.isCU = false;
        this.isEntity = false;
        this.isAttribute = false;
        break;
      case 'CHANGE_UNIT':
        this.isGSI = false;
        this.isCU = true;
        this.isEntity = false;
        this.isAttribute = false;
        break;
      case 'Entity':
        this.isGSI = false;
        this.isCU = false;
        this.isEntity = true;
        this.isAttribute = false;
        break;
      case 'Attribute':
        this.isGSI = false;
        this.isCU = false;
        this.isEntity = false;
        this.isAttribute = true;
        break;
    }
    this.screen = 2;
    //this.getPaginatedGlobalSearch();
  }
  /*API call to paginated global search result */
  getPaginatedGlobalSearch() {
    if (this.isGSI) {
      this.globalSearchFacadeService.getPaginatedGlobalSearch(
        this.searchTextAutocomplete,
        'GSI',
        this.pageNumber,
        this.pageSize
      );
      this.globalSearchFacadeService.searchPaginatedData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
        /*istanbul ignore else */
        if (res && res.result) {
          /*istanbul ignore next */
          if (res.result?.type === 'GSI') {
            this.paginatedGSI = res.result;
            this.totalGSIcount = res.result.totalHits;
          }
        }
      });
    }
    if (this.isCU) {
      this.globalSearchFacadeService.getPaginatedGlobalSearch(
        this.searchTextAutocomplete,
        'CHANGE_UNIT',
        this.pageNumber,
        this.pageSize
      );
      this.globalSearchFacadeService.searchPaginatedData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
        /*istanbul ignore else */
        if (res && res.result) {
          /*istanbul ignore next */
          if (res.result?.type === 'CHANGE_UNIT') {
            this.paginatedCU = res.result;
            this.totalCUcount = res.result.totalHits;
          }
        }
      });
    }
    if (this.isEntity) {
      this.globalSearchFacadeService.getPaginatedGlobalSearch(
        this.searchTextAutocomplete,
        'ENTITY',
        this.pageNumber,
        this.pageSize
      );
      this.globalSearchFacadeService.searchPaginatedData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
        /*istanbul ignore else */
        if (res && res.result) {
          /*istanbul ignore next */
          if (res.result?.type === 'ENTITY') {
            this.paginatedEntity = res.result;
            this.totalEntitycount = res.result.totalHits;
          }
        }
      });
    }
    if (this.isAttribute) {
      this.globalSearchFacadeService.getPaginatedGlobalSearch(
        this.searchTextAutocomplete,
        'ATTRIBUTE',
        this.pageNumber,
        this.pageSize
      );
      this.globalSearchFacadeService.searchPaginatedData$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
        /*istanbul ignore else */
        if (res && res.result) {
          /*istanbul ignore next */
          if (res.result?.type === 'ATTRIBUTE') {
            this.paginatedAttribute = res.result;
            this.totalAttributecount = res.result.totalHits;
          }
        }
      });
    }
  }

  /*changes page No on mat paginator */
  changePage(event: any) {
    this.pageNumber = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    //this.getPaginatedGlobalSearch();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}

import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { formatDate } from '@angular/common';
import { ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { ChangeUnitTypes } from '@app/@shared/enums/changeunit-types.enum';
import { Entity } from '@app/@shared/models/entity';
import {
  AttributeType,
  nslAttribute,
  uiElementObj,
} from '@app/node-system-canvas/components/nsl/node-display-tags/node-display-tags.component';
import { IDesignRights, IRecommendations } from '@app/node-system-canvas/models/node-canvas.model';
import { Shelf } from '@app/tenant-cus/assets-popup/assets-popup.component';
import {
  Agents,
  AttributeDict,
  AttributeFlat,
  CUFlat,
  Entities,
  EntityFlat,
  EntityFlatKey,
  Flat2Flat,
} from './FLat2Flat';
import { changeUnitMapper } from './mappers/change-unit/cu-mapper';
import { entityMapper } from './mappers/entity/entity-mapper';
import { gsiMapper } from './mappers/gsi/gsi-mapper';

// used to pass (field/key) and value and original Data and get  Filtered Data Bases on the (value/input) you give
export function getObjectByValue(field: string, value: string, originalData: any) {
  /* istanbul ignore next */
  if (
    field !== '' &&
    field !== undefined &&
    value !== '' &&
    value !== undefined &&
    originalData !== undefined &&
    originalData?.length > 0
  ) {
    const data = originalData.filter((x: any) => {
      /* istanbul ignore else */
      if (x[field] === value) {
        return x;
      }
    });
    return data[0];
  }
}
// used to pass  (field/key) and id and original Data and get  Filtered Data Bases on the (id/input) you give
export function getObjectById(field: string, id: any, originalData: any) {
  /* istanbul ignore next */
  if (
    field !== '' &&
    field !== undefined &&
    id !== '' &&
    id !== undefined &&
    originalData !== undefined &&
    originalData?.length > 0
  ) {
    const data = originalData.filter((x: any) => {
      /* istanbul ignore else */
      if (parseInt(x[field]) === parseInt(id)) {
        return x;
      }
    });
    return data[0];
  }
}
// pass (field/key) and assigned value from the object
export function getValueFromObjectByKey(field: string, object: any) {
  /* istanbul ignore else */
  if (field !== '' && field !== undefined && object !== undefined && object !== null) {
    return object[field];
  }
}
// pass the (field/key) and (idField) where to Match with the  (id/input) from Original Data and particular value of key after Filter
export function getValueFromArrayOfObjectById(field: string, idField: string, id: any, originalData: any) {
  /* istanbul ignore next */
  if (
    field !== '' &&
    field !== undefined &&
    idField !== '' &&
    idField !== undefined &&
    id !== '' &&
    id !== undefined &&
    originalData !== undefined &&
    originalData?.length > 0
  ) {
    console.log(field, idField, id, originalData);

    const data = originalData.filter((x: any) => {
      /* istanbul ignore next */
      if (parseInt(x[idField]) === parseInt(id)) {
        return x;
      }
    });

    return data[0] != undefined ? data[0][field] : '-';
  }
}

export function getArrayValueByIndex(positionIndex: number, field: string, originalData: any) {
  /* istanbul ignore next */
  if (
    positionIndex !== null &&
    positionIndex !== undefined &&
    field !== '' &&
    field !== undefined &&
    originalData !== undefined &&
    originalData?.length > 0
  ) {
    const data = originalData[positionIndex];
    return data != undefined ? data[field] : '';
  }
}

export function getValueFromCUObjectfromGSI(
  positionIndex: number,
  field: string,
  typeOfKeyInObject: string,
  originalGSIData: any
) {
  /* istanbul ignore next */
  if (
    positionIndex !== null &&
    positionIndex !== undefined &&
    field !== '' &&
    field !== undefined &&
    typeOfKeyInObject !== '' &&
    typeOfKeyInObject !== undefined &&
    originalGSIData !== undefined
  ) {
    const data = getArrayValueByIndex(
      positionIndex,
      field,
      getValueFromObjectByKey(typeOfKeyInObject, originalGSIData)
    );
    /* istanbul ignore next */
    return data != undefined ? data : '';
  }
}

export function FilterDataNotEquals(field: string, object: any, originalData: any) {
  /* istanbul ignore next */
  if (
    field !== '' &&
    field !== undefined &&
    object !== undefined &&
    object !== null &&
    originalData !== undefined &&
    originalData?.length > 0
  ) {
    return originalData.reduce((acc: any, obj: any) => {
      return acc.filter((x: any) => x[field] !== object[field]);
    }, originalData);
  }
}

export function timeDiff_CalculateInMinutes(currentTime: Date, previousTime: Date) {
  /* istanbul ignore next */
  var diff = (currentTime?.getTime() - previousTime?.getTime()) / 1000;
  diff /= 60;
  return Math.abs(Math.round(diff));
}

export function convertToEntityJSONModel(data: any) {
  /* istanbul ignore next */
  if (data !== undefined) {
    return { ...data, TYPE: 'GeneralEntity' };
  }
}
export function getHostUrl() {
  /* istanbul ignore next */
  if (window.location.host?.includes('localhost') || window.location.host?.includes('10.')) {
    let loginApiKey = localStorage.getItem('loginApiKey');
    let defaultTenantName = localStorage.getItem('TenantName');
    if (!loginApiKey) {
      return `https://${defaultTenantName}.dev3.nslhub.com`;
    } else if (loginApiKey === 'qa') {
      return `https://${defaultTenantName}.${loginApiKey}3.nslhub.com`;
    }
    return `https://${defaultTenantName}.${loginApiKey}.nslhub.com`;
  }
  /* istanbul ignore next */
  // if (window.location.origin.includes('dev3')) {
  //   let tenantName = getTenantName();
  //   return `https://${tenantName}.dev3.nslhub.com`;
  // }
  else {
    return window.location.origin;
  }
}

export function APMServiceActivationConfig() {
  return false;
}

// Get envronment client id
export function getEnvClientId() {
  const credentials = JSON.parse(localStorage.getItem('credentials'));
  /* istanbul ignore next */
  if (credentials) {
    return credentials?.client_id;
  }
}

// get tenant name
export function getTenantName() {
  let tenantName = localStorage.getItem('TenantName');
  return tenantName;
}

export function getCurrentUserName() {
  const credentials = JSON.parse(localStorage.getItem('credentials'));
  /* istanbul ignore next */
  if (credentials) {
    return credentials?.username;
  }
}

export function checkTokenExistsorNot() {
  const credentials = localStorage.getItem('credentials');
  /* istanbul ignore next */
  if (credentials) {
    return credentials;
  } else {
    return false;
  }
}

export function getLocalStorageValue(value: any) {
  const existingValue = localStorage.getItem(value);
  /* istanbul ignore next */
  if (existingValue) {
    return existingValue;
  } else {
    return '';
  }
}

export function removeLocalStorageValue(value: any) {
  const existingValue = localStorage.getItem(value);
  /* istanbul ignore next */
  if (existingValue) {
    localStorage.removeItem(value);
  }
}

export function addLocalStorageValue(key: string, value: any) {
  localStorage.setItem(key, value);
}

export function dynamicGroupBy(data: any, property: string) {
  /* istanbul ignore next */
  if (data && property) {
    return data.reduce((acc: any, obj: any) => {
      const key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  }
}
// need to work on thsi functionality
// export function deleteCuFromCuList(parentCu:any,originalCulist:any,currentCu:any){

//   let tempNextTriggerSetList: any = [];
//   originalCulist?.forEach((cu:any) => {
//     tempNextTriggerSetList.push({
//       cu: cu,
//       cuname: cu.name,
//       nextTriggerSet: cu.nextTriggerSet,
//       index: cu.index
//     });
//   });

//   let indexToDelete = currentCu?.index;

//   if(parentCu && parentCu.nextTriggerSet.length > 0){
//     tempNextTriggerSetList?.forEach((cuItem: any) =>{
//       if(cuItem.index == indexToDelete){

//       }
//     });
//   }
//   if(parentCu && parentCu.nextTriggerSet.length > 0){
//     let foundNextTriggerSetIndex = parentCu?.nextTriggerSet?.findIndex((x: any)=>x.index===currentCu.index);
//     let deleteCuIndex = originalCulist.findIndex((x: any)=>x.index === currentCu.index);
//     originalCulist.splice(deleteCuIndex,1);
//     parentCu?.nextTriggerSet.splice(foundNextTriggerSetIndex,1);
//   }
//   for(let i=0;i<originalCulist.length;i++){
//     if(originalCulist[i].index===parentCu.index){
//       if(originalCulist.length)
//       originalCulist[i] = parentCu;
//     }
//     // if(originalCulist.length===originalCulist[i].index){
//     //   parentcuIndex =
//     // }
//   }
//   return originalCulist;
// }

//here
export function nodeAddChangeUnitInCUList(parentCU: any, originalCUList: any, changeUnitData: any, type?: any) {
  let isParallelValue = false;
  /* istanbul ignore next */
  if (changeUnitData?.isParallel) {
    isParallelValue = changeUnitData.isParallel;
  }
  //  Used to Nested CU Add Feature
  originalCUList = returnNestedorExceptionCUList(parentCU, originalCUList, changeUnitData, type);
  // will generated the cu index based on the conditions
  let maxIndex: number = 1;
  /* istanbul ignore next */
  if (originalCUList?.length > 0) {
    /* istanbul ignore next */
    originalCUList?.forEach((cu: any) => {
      maxIndex = Math.max(maxIndex, cu.index);
    });
    maxIndex = maxIndex + 1;
  }
  /* istanbul ignore next */
  if (!changeUnitData.nextTriggerSet) {
    changeUnitData.nextTriggerSet = [];
  }
  /* istanbul ignore next */
  let CUIndex = originalCUList.findIndex((x: any) => x.index === parentCU?.index);
  /* istanbul ignore next */
  let parrallelCU: any = [];

  if (
    originalCUList[CUIndex]?.nextTriggerSet?.length > 0 &&
    !changeUnitData?.isAlternate &&
    type != 'nested' &&
    type != 'exception'
  ) {
    originalCUList[CUIndex].nextTriggerSet.forEach((x: any, index: number) => {
      if (!x.isParallel) {
        changeUnitData.nextTriggerSet.push(x);
      } else {
        parrallelCU.push(x);
      }
    });

    originalCUList[CUIndex].nextTriggerSet = parrallelCU;
    // changeUnitData.nextTriggerSet = originalCUList[CUIndex]?.nextTriggerSet;
    // originalCUList[CUIndex].nextTriggerSet = [];
  }
  // will find the change unit and update the next trigger set in the parent cu
  /* istanbul ignore next */
  if (parentCU && originalCUList.length > 0 && changeUnitData && changeUnitData?.isEdit !== true) {
    let findIndexMatchedCU = originalCUList?.findIndex((x: any) => x?.index === parentCU?.index);

    // let findIndexMatchedCUName = originalCUList?.findIndex((x: any) => x?.name === parentCU?.name);
    /* istanbul ignore next */
    if (findIndexMatchedCU !== -1 && originalCUList[findIndexMatchedCU]?.nextTriggerSet) {
      /* istanbul ignore next */
      if (type === ChangeUnitTypes.Embedded) {
        // changeUnitData.nextTriggerSet = [...parentCU?.nextTriggerSet];
        originalCUList[findIndexMatchedCU].nextTriggerSet = [
          { nextCUName: changeUnitData.name, index: maxIndex, isParallel: isParallelValue },
        ];
      } else {
        if (type !== ChangeUnitTypes.Nested && type !== ChangeUnitTypes.Exception) {
          let nextCuName = changeUnitData?.name;
          let Index = maxIndex;
          originalCUList[findIndexMatchedCU]?.nextTriggerSet.push({
            nextCUName: nextCuName,
            index: Index,
            isParallel: isParallelValue,
          });
        }
      }
      originalCUList = [...originalCUList, { ...changeUnitData, index: maxIndex }];
      return originalCUList;
    }
  } else if (originalCUList?.length > 0 && changeUnitData) {
    let id = changeUnitData?.parentcuId == undefined ? changeUnitData?.id : changeUnitData?.parentcuId;
    // used to update the cu data with the id / reference change unit
    originalCUList = returnOriginalCUList(originalCUList, changeUnitData, id);
  } else if (originalCUList?.length === 0 && changeUnitData) {
    // used to push the cu when empty cu list is present
    originalCUList = [...originalCUList, { ...changeUnitData, index: maxIndex, isParallel: isParallelValue }];
    return originalCUList;
  }
}

/* istanbul ignore next */
function returnOriginalCUList(originalCUList: any, changeUnitData: any, id: any) {
  return originalCUList?.map((x: any) => {
    if (x && id == x.referencedChangeUnit) {
      /* istanbul ignore next */
      const cuData = {
        ...changeUnitData,
        referencedChangeUnit: x.referencedChangeUnit,
        tfReferencedChangeUnit: x.tfReferencedChangeUnit,
        id: x?.id,
        tfId: x.tfId,
        index: (changeUnitData?.isEdit === true || changeUnitData?.parentcuId) && x.index,
        nextTriggerSet: x?.nextTriggerSet,
        dcd: x?.dcd,
        specialFeatureProperties: x?.specialFeatureProperties,
        constraint: changeUnitData?.constraint,
        constraintName: changeUnitData?.constraintName,
        condition: x?.condition,
        conditionName: x?.conditionName,
        exceptionCUList: x?.exceptionCUList,
        source: x?.source,
      };
      if (x?.eventCUList) {
        cuData['eventCUList'] = x?.eventCUList;
      }
      return cuData;
    } else {
      return x;
    }
  });
}

function returnNestedorExceptionCUList(parentCU: any, originalCUList: any, changeUnitData: any, type?: any) {
  /* istanbul ignore next */
  if (type === ChangeUnitTypes.Nested) {
    /* istanbul ignore next */
    let findIndexMatchedCU = originalCUList?.findIndex(
      (x: any) => x?.referencedChangeUnit === parentCU?.referencedChangeUnit
    );
    if (findIndexMatchedCU !== -1) {
      if (!originalCUList[findIndexMatchedCU].mindCUList) {
        originalCUList[findIndexMatchedCU].mindCUList = [];
      }
      if (Array.isArray(changeUnitData)) {
        originalCUList[findIndexMatchedCU].mindCUList = [...changeUnitData];
      } else {
        originalCUList[findIndexMatchedCU].mindCUList.push({ ...changeUnitData });
      }
    }
    return originalCUList;
  } else if (type === ChangeUnitTypes.Exception) {
    /* istanbul ignore next */
    // used for Exception CU Feature
    let findIndexMatchedCU = originalCUList?.findIndex((x: any) => x?.id === parentCU?.id);
    if (findIndexMatchedCU !== -1) {
      if (!originalCUList[findIndexMatchedCU].exceptionCUList) {
        originalCUList[findIndexMatchedCU].exceptionCUList = [];
      }
      originalCUList[findIndexMatchedCU].exceptionCUList.push(...changeUnitData);
    }
    return originalCUList;
  } else {
    return originalCUList;
  }
}

export function addChangeUnitInCUList(parentCU: any, originalCUList: any, changeUnitData: any, type?: any) {
  //  Used to Nested CU Add Feature
  originalCUList = returnNestedorExceptionCUList(parentCU, originalCUList, changeUnitData, type);
  // will generated the cu index based on the conditions
  let maxIndex = 1;
  /* istanbul ignore next */
  if (originalCUList?.length > 0) {
    /* istanbul ignore next */
    originalCUList.forEach((x: any) => {
      if (maxIndex <= x.index) {
        maxIndex = x.index + 1;
      }
    });
  }

  // will find the change unit and update the next trigger set in the parent cu

  /* istanbul ignore next */
  if (
    parentCU &&
    originalCUList?.length > 0 &&
    changeUnitData &&
    changeUnitData?.isEdit !== true &&
    changeUnitData?.oldVersionId === undefined
  ) {
    /* istanbul ignore next */
    let findIndexMatchedCU = originalCUList?.findIndex((x: any) => x?.id === parentCU?.id);
    /* istanbul ignore next */
    if (findIndexMatchedCU !== -1 && originalCUList[findIndexMatchedCU]?.nextTriggerSet) {
      originalCUList[findIndexMatchedCU].nextTriggerSet = originalCUList[findIndexMatchedCU]?.nextTriggerSet
        ? originalCUList[findIndexMatchedCU]?.nextTriggerSet
        : [];
      originalCUList[findIndexMatchedCU]?.nextTriggerSet.push({
        nextCUName: changeUnitData?.name,
        index: maxIndex,
      });
      originalCUList = [...originalCUList, { ...changeUnitData, index: maxIndex }];
      return originalCUList;
    }
  } else if (originalCUList?.length > 0 && changeUnitData) {
    let id = changeUnitData?.oldVersionId == undefined ? changeUnitData?.id : changeUnitData?.oldVersionId;
    // used to update the cu data with the id / reference change unit
    return originalCUList?.map((x: any) => {
      if (x && id == x.referencedChangeUnit && x.index === changeUnitData?.index) {
        const cuData = {
          ...changeUnitData,
          referencedChangeUnit:
            changeUnitData?.oldVersionId == undefined ? x.referencedChangeUnit : changeUnitData?.referencedChangeUnit,
          tfReferencedChangeUnit:
            changeUnitData?.oldVersionId == undefined
              ? x.tfReferencedChangeUnit
              : changeUnitData?.tfReferencedChangeUnit,
          id: x?.id,
          tfId: x.tfId,
          index: (changeUnitData?.isEdit === true || changeUnitData?.oldVersionId) && x.index,
          nextTriggerSet: x?.nextTriggerSet,
          dcd: x?.dcd,
          specialFeatureProperties: x?.specialFeatureProperties,
          constraint: changeUnitData?.constraint,
          constraintName: changeUnitData?.constraintName,
          condition: x?.condition,
          conditionName: x?.conditionName,
          exceptionCUList: x?.exceptionCUList,
          slotItemProperties:
            changeUnitData.slotItemProperties &&
            Object.keys(changeUnitData.slotItemProperties)?.length > 0 &&
            x?.index == changeUnitData?.index
              ? changeUnitData.slotItemProperties
              : x?.slotItemProperties,
          source: x?.source,
        };
        if (x?.eventCUList) {
          cuData['eventCUList'] = x?.eventCUList;
        }
        return cuData;
      } else {
        return x;
      }
    });
  } else if (originalCUList?.length === 0 && changeUnitData) {
    // used to push the cu when empty cu list is present
    originalCUList = [...originalCUList, { ...changeUnitData, index: maxIndex }];
    return originalCUList;
  }
}

export function constructRightsObj(rights: any, type: any) {
  let decisionRights, informationRights, executionRights;
  /* istanbul ignore next */
  if (type === 'design') {
    decisionRights = rights?.designTimeRights?.decisionRight ? rights?.designTimeRights.decisionRight : false;
  } else if (rights?.txnTimeRights?.decisionRight) {
    /* istanbul ignore next */
    decisionRights = rights?.txnTimeRights.decisionRight;
  } else {
    decisionRights = false;
  }
  /* istanbul ignore next */
  if (type === 'design') {
    informationRights = rights?.designTimeRights?.informationRight ? rights?.designTimeRights.informationRight : false;
  } else if (rights?.txnTimeRights?.informationRight) {
    /* istanbul ignore next */
    informationRights = rights?.txnTimeRights.informationRight;
  } else {
    informationRights = false;
  }
  /* istanbul ignore next */
  if (type === 'design') {
    executionRights = false;
  } else {
    /* istanbul ignore next */
    executionRights = rights?.txnTimeRights?.executionRight ? rights?.txnTimeRights.executionRight : false;
  }
  return {
    name:
      /* istanbul ignore next */
      rights?.rightHolderName ? rights?.rightHolderName : rights?.name,
    /* istanbul ignore next */
    rightHolderId:
      /* istanbul ignore next */
      rights?.rightHolderId ? rights?.rightHolderId : rights?.id,
    /* istanbul ignore next */
    rightHolderType:
      /* istanbul ignore next */
      rights?.rightHolderType ? rights?.rightHolderType : 'ROLE',
    /* istanbul ignore next */
    rightHolderName:
      /* istanbul ignore next */
      rights?.rightHolderName ? rights?.rightHolderName : rights?.name,
    decisionRight: decisionRights,
    informationRight: informationRights,
    executionRight: executionRights,
  };
}

export function addChangeUnitOnlyInParentCUNextTriggerSet(parentCU: any, originalCUList: any, changeUnitData: any) {
  // add change unit to the cuList , it will not update the parent cu next triggerset used for link / unlink cu's
  /* istanbul ignore next */
  if (parentCU && originalCUList?.length > 0 && changeUnitData && changeUnitData?.index) {
    let findIndexMatchedCU = originalCUList?.findIndex((x: any) => x?.id === parentCU?.id);
    if (findIndexMatchedCU !== -1 && originalCUList[findIndexMatchedCU]?.nextTriggerSet) {
      originalCUList[findIndexMatchedCU]?.nextTriggerSet.push({
        nextCUName: changeUnitData?.name,
        index: changeUnitData.index,
      });
      return originalCUList;
    }
  } else {
    return originalCUList;
  }
}

export function selectedCUModelForDLD(changeUnit: any) {
  /* istanbul ignore next */
  if (changeUnit) {
    /* istanbul ignore next */
    return {
      name: changeUnit?.name,
      cuId: changeUnit?.id,
      index: changeUnit.index,
      nextTriggerSet: changeUnit.nextTriggerSet?.map((x: any) => {
        return getnextTriggerSet(x);
      }),
      source: changeUnit?.source,
    };
  }
}

export function getnextTriggerSet(triggerSet: any) {
  /* istanbul ignore next */
  if (triggerSet) {
    return {
      index: triggerSet.index,
      nextCUName: triggerSet.nextCUName,
    };
  }
}

export function generateDLDRequestBasedonTempGsiObject(tempGsiObject: any) {
  console.log(tempGsiObject);
  /* istanbul ignore next */
  return {
    ...tempGsiObject?.appDetails,
    cus: tempGsiObject?.cuList?.map((x: any) => {
      return selectedCUModelForDLD(x);
    }),
    actualCuIndex: getValueFromObjectByKey('index', tempGsiObject?.cuList[tempGsiObject?.activeCuIndex]),
    // activeCuIndex: tempGsiObject.activeCuIndex,
  };
}

export function generateDLDRequestBasedonTempGsiObjectInNode(tempGsiObject: any, cuIndex: number) {
  console.log(tempGsiObject);
  /* istanbul ignore next */
  return {
    // ...tempGsiObject?.appDetails,
    gsiName: tempGsiObject?.name,
    cus: tempGsiObject?.solutionLogic?.map((x: any) => {
      return selectedCUModelForDLD(x);
    }),
    actualCuIndex: getValueFromObjectByKey('index', tempGsiObject?.solutionLogic?.[cuIndex]),
    // activeCuIndex: tempGsiObject.activeCuIndex,
  };
}

/**
 *
 * @param previousState
 * @param data
 * @param typeOfMessage
 * @returns  updated messageList
 * Used to push the latest message or question in to the same array
 *
 */

export function chatBotModelFormat(previousState: any, data: any, typeOfMessage: string) {
  /* istanbul ignore next */
  if (data) {
    /* istanbul ignore next */
    const previousStateData = previousState?.messagesList;
    if (typeOfMessage === 'GreetingMessage') {
      /* istanbul ignore next */
      previousStateData?.push({ greetMessage: data });
    } else if (typeOfMessage === 'Update_Question') {
      /* istanbul ignore next */
      previousStateData?.push({ question: data?.quest, read: data?.read ? data?.read : true });
    } else if (typeOfMessage === 'Update_Answer') {
      /* istanbul ignore next */
      previousStateData?.push({ answer: data });
    } else if (typeOfMessage === 'Send_Voice_Input') {
      /* istanbul ignore next */
      previousStateData?.push({ voice: data });
    } else if (typeOfMessage === 'Update_ProfanityQuestion') {
      /* istanbul ignore next */
      let previousQuestionIndex = previousState?.messagesList?.length - 1;
      previousStateData?.splice(previousQuestionIndex, 1, { question: data?.quest });
    } else if (typeOfMessage === 'Update_VoiceWithTextQuestionAndAnswer') {
      /* istanbul ignore next */
      let previousQuestionIndex = previousState?.messagesList?.length - 1;
      previousStateData?.splice(previousQuestionIndex, 1, {
        question: data?.ans?.profanityStatus === true ? data?.ans?.maskText : data?.ans?.text,
      });
      previousStateData?.push({ answer: data?.ans?.answerData });
    } else if (typeOfMessage === 'UpdateEvent') {
      previousStateData?.push({ event: data });
    } else if (typeOfMessage === 'UpdateFile') {
      previousStateData?.push({ file: data });
    }

    return {
      messagesList: previousStateData,
    };
  }
}

// Adds cloned cu to the current gsi
export function clonePublishedCuToGSI(cuList: any, cuData: any) {
  /* istanbul ignore next */
  return cuList?.map((changeUnit: any) => {
    if (changeUnit.referencedChangeUnit === cuData?.oldVersionId) {
      return {
        ...cuData,
        referencedChangeUnit: cuData.id,
        tfReferencedChangeUnit: cuData?.tfId,
        id: null,
      };
    } else {
      return changeUnit;
    }
  });
}

export function isNumber(val: any) {
  return !isNaN(val);
}

export function createEntityObj(entity: Entity) {
  var Attributes: any = [];
  /* istanbul ignore next */
  entity?.nslAttributes?.forEach((attribute: any) => {
    let attrSourceValues = [];
    /* istanbul ignore next */
    let sourceValues = attribute?.attributeType?.properties?.sourceValues;
    if (sourceValues) {
      // attrSourceValues = JSON.parse(sourceValues);
      // console.log(attrSourceValues);
      /* istanbul ignore next */
      sourceValues = sourceValues?.map((x: any) => x?.DATA?.actualValue);
    }
    /* istanbul ignore next */
    Attributes.push({
      name: attribute['name'],
      attributeType: {
        type: attribute['attributeType']['type'],
        uiElement: attribute?.uiElement?.uiElement
          ? attribute?.uiElement?.uiElement
          : attribute['attributeType']['uiElement'],
        values: sourceValues ? sourceValues : [],
      },
    });
  });
  return { name: entity['name'], nslAttributes: Attributes };
}

// Validate EQL query modal data
export function validatedModalData(validateQueryParams: any) {
  let isValidateModal = true;
  /* istanbul ignore next */
  validateQueryParams?.forEach((params: any) => {
    /* istanbul ignore next */
    params?.attrValue?.forEach((attrValue: any) => {
      /* istanbul ignore next */
      if (!attrValue?.value) {
        isValidateModal = false;
      }
    });
  });
  return isValidateModal;
}
// check deleted entities from i/o array for EQL component
/* istanbul ignore next */
export function getDuplicateAttr(value: any, transEntityRecords: any, isAttrValue: boolean) {
  let isAvailable = true;
  /* istanbul ignore next */
  if (isAttrValue && value?.includes('PL.SL00')) {
    return isAvailable;
  }
  for (let j = 0; j < transEntityRecords.attributesList.length; j++) {
    if (transEntityRecords.attributesList[j] !== value) {
      isAvailable = false;
    } else {
      isAvailable = true;
      break;
    }
  }
  return isAvailable;
}

/* istanbul ignore next */
export function getFilterData(txnNslAttribute: any, transEntityRecords: any) {
  /* istanbul ignore next */
  transEntityRecords.filterData = [];
  let transEntityRecordsForFilter = txnNslAttribute?.[2]?.txnGeneralEntity?.transEntityRecords;
  /* istanbul ignore next */
  let filterData: any;
  filterData = [];
  for (let i = 0; i < transEntityRecordsForFilter?.length; i++) {
    let txnNslAttributeForFilter = transEntityRecordsForFilter[i].txnNslAttribute;
    filterData.push({ type: '', value: '', filterAttributeValue: '' });
    for (let j = 0; j < txnNslAttributeForFilter.length; j++) {
      /* istanbul ignore next */
      if (txnNslAttributeForFilter[j].values) {
        // Filter Type
        /* istanbul ignore next */
        if (j === 0 && txnNslAttributeForFilter[j].values.length > 0) {
          filterData[i].type = txnNslAttributeForFilter[j].values?.[0];
        }
        // Filter Value
        /* istanbul ignore next */
        if (j === 1 && txnNslAttributeForFilter[j].values.length > 0) {
          transEntityRecords.showFilter = true;
          filterData[i].value = txnNslAttributeForFilter[j].values?.[0];
        }
        // Filter Attribute Value
        /* istanbul ignore next */
        if (j === 2 && txnNslAttributeForFilter[j].values.length > 0) {
          transEntityRecords.showFilter = true;
          filterData[i].filterAttributeValue = txnNslAttributeForFilter[j].values?.[0];
        }
      }
    }
  }

  /* istanbul ignore next */
  if (filterData?.length > 0) {
    let flagCnt = 3;
    let tempFilterData: any;
    tempFilterData = [];
    filterData?.forEach((filter: any) => {
      /* istanbul ignore next */
      if (flagCnt > 0) {
        tempFilterData.push(filter);
        flagCnt -= 1;
      }
      /* istanbul ignore next */
      if (flagCnt === 0) {
        transEntityRecords.filterData.push(tempFilterData);
        tempFilterData = [];
        flagCnt = 3;
      }
    });
    /* istanbul ignore next */
    if (tempFilterData.length > 0) {
      transEntityRecords.filterData.push(tempFilterData);
      tempFilterData = [];
    }
  }

  /* istanbul ignore next */
  transEntityRecords = checkGroupByFilter(transEntityRecords);

  return transEntityRecords;
}

export function checkGroupByFilter(transEntityRecords: any) {
  let groupByData: any = [];
  groupByData = getIsGroupAvailable(transEntityRecords);
  /* istanbul ignore next */
  if (groupByData?.requiredAttributesForGroupFilter > 0) {
    if (groupByData?.requiredAttributesForGroupFilter < groupByData?.availableGroupByFilters) {
      let tempFilterData: any = [];
      /* istanbul ignore next */
      transEntityRecords?.attributesListForGroupFilter.forEach((groupfilters: any, i: any) => {
        transEntityRecords?.filterData.forEach((filterData: any, j: any) => {
          filterData.forEach((filter: any, k: any) => {
            if (filter.type === 'GROUP') {
              if (filter.filterAttributeValue === groupfilters) {
                tempFilterData.push(filter);
              }
            }
          });
        });
      });
      transEntityRecords.filterData = [];
      transEntityRecords.filterData[0] = tempFilterData;
    }
  } else {
    transEntityRecords.filterData = [];
    transEntityRecords.showFilter = false;
  }

  return transEntityRecords;
}

/* istanbul ignore next */
export function getIsGroupAvailable(transEntityRecords: any) {
  let groupByData = { allAttributesForAllentities: 0, requiredAttributesForGroupFilter: 0, availableGroupByFilters: 0 };
  groupByData.allAttributesForAllentities = transEntityRecords?.attributesListForQueryLayer?.length;

  transEntityRecords.attributesListForGroupFilter = [];
  let allAttr: any = [];
  let availableAttr = 0;
  transEntityRecords?.queryInputOutputArray?.forEach((attr: any) => {
    if (attr?.sourceAttribute?.length > 0) {
      availableAttr++;
      if (attr?.function?.length > 0) {
        allAttr.push(attr.sourceAttribute);
      }
    }
  });
  /* istanbul ignore next */
  let extraAttrFound = transEntityRecords?.attributesListForQueryLayer?.filter((x: any) => !allAttr?.includes(x));

  if (availableAttr === allAttr?.length) {
    extraAttrFound = transEntityRecords?.attributesListForQueryLayer;
    transEntityRecords.attributesListForGroupFilter = allAttr;
    groupByData.requiredAttributesForGroupFilter = allAttr?.length;
    transEntityRecords.requiredGroupByFilters = 0;
  } else {
    transEntityRecords.attributesListForGroupFilter = extraAttrFound;
    groupByData.requiredAttributesForGroupFilter = transEntityRecords?.attributesListForGroupFilter?.length;
    transEntityRecords.requiredGroupByFilters = availableAttr;
  }

  groupByData.availableGroupByFilters = 0;
  transEntityRecords?.filterData.forEach((filterData: any, i: any) => {
    filterData?.forEach((filter: any, j: any) => {
      if (filter.type === 'GROUP') {
        groupByData.availableGroupByFilters++;
      }
    });
  });
  return groupByData;
}

/* istanbul ignore next */
export function addAllEntities(transEntityRecords: any, entityFacadeService: any, entityList: any) {
  /* istanbul ignore next */
  entityList?.forEach((entityName: string, i: any) => {
    /* istanbul ignore next */
    if (entityName) {
      entityFacadeService.getEntityDataForEQLQueryLayer(entityName);
    } else {
      /* istanbul ignore next */
      entityList?.splice(i, 1);
    }
  });

  // transEntityRecords.attributesListForGroupFilter = transEntityRecords.attributesListForQueryLayer;
  return transEntityRecords;
}

export function getTargetAttrForQueryParams(
  transEntityRecords: any,
  validateQueryParams: any,
  eqlCuDetails: any,
  EQLType?: any
) {
  let layer = findLayer(eqlCuDetails, 'physical');
  transEntityRecords?.queryData?.forEach((query: any) => {
    getNestedTargetAttrForQueryParamsInQueryData(query, validateQueryParams, layer, EQLType);
  });
  transEntityRecords?.nslFormula?.forEach((formula: any) => {
    formula?.forEach((formula: any) => {
      getNestedTargetAttrForQueryParamsInNslFormula(formula, validateQueryParams, EQLType);
    });
  });
  transEntityRecords?.nslCase?.forEach((nslCase: any) => {
    nslCase?.forEach((cases: any) => {
      getNestedTargetAttrForQueryParamsInNslCase(cases, validateQueryParams, EQLType);
    });
  });
  return validateQueryParams;
}

/* istanbul ignore next */
export function getNestedTargetAttrForQueryParamsInQueryData(
  query: any,
  validateQueryParams: any,
  layer: any,
  EQLType?: any
) {
  /* istanbul ignore next */
  if (!query?.length) {
    /* istanbul ignore next */
    if (query?.attributeValue?.includes('PL.SL')) {
      let tempSplitted = query.attributeValue.split('.', 4);
      let tempAttrName = tempSplitted[2] + '.' + tempSplitted[3];
      let tempAttributeNameForDataType = tempSplitted[3];
      tempAttributeNameForDataType = layer?.participatingItems?.[EQLType === 'EQL-Report' ? 2 : 1]?.nslAttributes.find(
        (attr: any) => attr.name === tempAttributeNameForDataType
      );
      let tempAttrType = tempAttributeNameForDataType.attributeType.type;
      /* istanbul ignore next */
      let tempAttrUiElement = tempAttributeNameForDataType?.attributeType?.uiElement?.uiElement;
      /* istanbul ignore next */
      let tempAttrDataType = tempAttributeNameForDataType?.attributeType?.uiElement?.dataType;
      let isMultiValue = false;
      if (tempAttrType == 'list') {
        tempAttrType = tempAttributeNameForDataType.attributeType.nestedNslDataTypeProperties.item_type.type;
      }
      if (query.queryOperator == 'BETWEEN' || query.queryOperator == 'IN') {
        isMultiValue = true;
      }
      validateQueryParams.push({
        attr: query.attributeValue,
        attrName: tempAttrName,
        attrValue: isMultiValue ? [{ value: '' }, { value: '' }] : [{ value: '' }],
        attrType: tempAttrType,
        isMultiValue: isMultiValue,
        attrUiElement: tempAttrUiElement,
        attrDataType: tempAttrDataType,
      });
    }
  } else {
    query.forEach((subQuery: any) => {
      getNestedTargetAttrForQueryParamsInQueryData(subQuery, validateQueryParams, layer, EQLType);
    });
  }
  return validateQueryParams;
}

/* istanbul ignore next */
export function getNestedTargetAttrForQueryParamsInNslCase(query: any, validateQueryParams: any, EQLType: any) {
  /* istanbul ignore next */
  if (
    query?.nslQueryCondition?.attributeName?.charAt(0) !== "'" &&
    query?.nslQueryCondition?.attributeName?.charAt(query?.nslQueryCondition?.attributeName?.length - 1) !== "'"
  ) {
    /* istanbul ignore next */
    if (query?.nslQueryCondition?.attributeName?.includes('PL.SL')) {
      let tempSplitted = query.nslQueryCondition.attributeName.split('.', 4);
      let tempAttrName = tempSplitted[2] + '.' + tempSplitted[3];
      validateQueryParams.push({
        attr: query.nslQueryCondition.attributeName,
        attrName: tempAttrName,
        attrValue: [{ value: '' }],
        isMultiValue: false,
      });
    }
  }

  /* istanbul ignore next */
  if (
    query?.nslQueryCondition?.attributeValue?.charAt(0) !== "'" &&
    query?.nslQueryCondition?.attributeValue?.charAt(query?.nslQueryCondition?.attributeValue?.length - 1) !== "'"
  ) {
    /* istanbul ignore next */
    if (query?.nslQueryCondition?.attributeValue?.includes('PL.SL')) {
      let tempSplitted = query.nslQueryCondition.attributeValue.split('.', 4);
      let tempAttrName = tempSplitted[2] + '.' + tempSplitted[3];

      validateQueryParams.push({
        attr: query.nslQueryCondition.attributeValue,
        attrName: tempAttrName,
        attrValue: [{ value: '' }],
        isMultiValue: false,
      });
    }
  }

  /* istanbul ignore next */
  if (
    query?.nslThen?.attributeName?.charAt(0) !== "'" &&
    query?.nslThen?.attributeName?.charAt(query?.nslThen?.attributeName?.length - 1) !== "'"
  ) {
    /* istanbul ignore next */
    if (query?.nslThen?.attributeName?.includes('PL.SL')) {
      let tempSplitted = query.nslThen.attributeName.split('.', 4);
      let tempAttrName = tempSplitted[2] + '.' + tempSplitted[3];
      validateQueryParams.push({
        attr: query.nslThen.attributeName,
        attrName: tempAttrName,
        attrValue: [{ value: '' }],
        isMultiValue: false,
      });
    }
  }

  /* istanbul ignore next */
  if (
    query?.nslElse?.attributeName?.charAt(0) !== "'" &&
    query?.nslElse?.attributeName?.charAt(query?.nslElse?.attributeName?.length - 1) !== "'"
  ) {
    /* istanbul ignore next */
    if (query?.nslElse?.attributeName?.includes('PL.SL')) {
      let tempSplitted = query.nslElse.attributeName.split('.', 4);
      let tempAttrName = tempSplitted[2] + '.' + tempSplitted[3];
      validateQueryParams.push({
        attr: query.nslElse.attributeName,
        attrName: tempAttrName,
        attrValue: [{ value: '' }],
        isMultiValue: false,
      });
    }
  }
  return validateQueryParams;
}

/* istanbul ignore next */
export function getNestedTargetAttrForQueryParamsInNslFormula(query: any, validateQueryParams: any, EQLType: any) {
  /* istanbul ignore next */
  if (!query?.length) {
    /* istanbul ignore next */
    if (query?.attributeName?.includes('PL.SL')) {
      let tempSplitted = query.attributeName.split('.', 4);
      let tempAttrName = tempSplitted[2] + '.' + tempSplitted[3];
      // let tempAttributeNameForDataType = tempSplitted[3];
      // tempAttributeNameForDataType = layer?.participatingItems?.[
      //   EQLType === 'EQL-Report' ? 2 : 1
      // ]?.nslAttributes.find((attr: any) => attr.name === tempAttributeNameForDataType);
      // let tempAttrType = tempAttributeNameForDataType.attributeType.type;
      let isMultiValue = false;
      // if (tempAttrType == 'list') {
      //   tempAttrType = tempAttributeNameForDataType.attributeType.nestedNslDataTypeProperties.item_type.type;
      // }
      if (query.queryOperator == 'BETWEEN' || query.queryOperator == 'IN') {
        isMultiValue = true;
      }
      validateQueryParams.push({
        attr: query.attributeName,
        attrName: tempAttrName,
        attrValue: isMultiValue ? [{ value: '' }, { value: '' }] : [{ value: '' }],
        isMultiValue: isMultiValue,
      });
    }
  } else {
    query.forEach((subQuery: any) => {
      getNestedTargetAttrForQueryParamsInNslFormula(subQuery, validateQueryParams, EQLType);
    });
  }
  return validateQueryParams;
}

export function getSelectedOperation(transEntityRecords: any) {
  /* istanbul ignore next */
  transEntityRecords?.selectedOperation?.forEach((operation: any, i: any) => {
    /* istanbul ignore next */
    /* istanbul ignore else */
    if (operation === 'formula' || operation === 'case') {
      transEntityRecords.isSelectedOperation[i] = true;
    } else {
      transEntityRecords.isSelectedOperation[i] = false;
    }
  });
  return transEntityRecords;
}
/* istanbul ignore next */
export function getDuplicatesEql(transEntityRecords: any) {
  /* istanbul ignore next */
  for (let i = 0; i < transEntityRecords?.queryData?.length; i++) {
    let isAttrNameAvailable = true;
    let tempAttributeName = transEntityRecords.queryData[i].attributeName;
    isAttrNameAvailable = getDuplicateAttr(tempAttributeName, transEntityRecords, false);
    /* istanbul ignore next */
    if (!isAttrNameAvailable) {
      transEntityRecords.queryData[i].attributeName = '';
    }

    let isAttrValueAvailable = true;
    let tempAttributeValue = transEntityRecords.queryData[i].attributeValue;
    isAttrValueAvailable = getDuplicateAttr(tempAttributeValue, transEntityRecords, true);
    /* istanbul ignore next */
    if (!isAttrValueAvailable) {
      transEntityRecords.queryData[i].attributeValue = '';
    }
  }
  return transEntityRecords;
}

/* istanbul ignore next */
export function pushAllAttrInDDFromEntities(
  transEntityRecords: any,
  itemAttributeValueDropDown: any,
  attributeValueDropDown: any
) {
  if (transEntityRecords?.attributesListForQueryLayer && transEntityRecords?.attributesListForQueryLayer?.length > 0) {
    for (let j = 0; j < transEntityRecords.attributesListForQueryLayer.length; j++) {
      let item = {
        display: transEntityRecords.attributesListForQueryLayer[j],
        item: transEntityRecords.attributesListForQueryLayer[j],
      };
      // attributeValueDropDownForJoin.push(item);
      /* istanbul ignore next */
      if (!itemAttributeValueDropDown?.includes(item?.item) && transEntityRecords?.entityList?.length > 1) {
        attributeValueDropDown.push(item);
      }
    }
  }

  attributeValueDropDown = attributeValueDropDown.filter(
    (arr: any, index: any, self: any) =>
      index === self.findIndex((t: any) => t.item === arr.item && t.display === arr.display)
  );
  return attributeValueDropDown;
}

/* istanbul ignore next */
export function loadNSLFormula(tempVarForNslFormula: any) {
  let tempNSLTransRecord: any = [];

  if (
    !tempVarForNslFormula?.txnNslAttribute[3]?.txnGeneralEntity ||
    !tempVarForNslFormula?.txnNslAttribute[3]?.txnGeneralEntity?.transEntityRecords
  ) {
    let formulae = {
      functionName: '',
      attributeName: '',
      arithmeticOperator: '',
      nslQuery: '',
      nslFormula: '',
      nslCase: '',
    };
    tempNSLTransRecord = formulae;

    tempNSLTransRecord.functionName = tempVarForNslFormula?.txnNslAttribute?.[0]?.values[0];

    tempNSLTransRecord.attributeName = tempVarForNslFormula?.txnNslAttribute?.[1]?.values[0];

    if (tempVarForNslFormula?.txnNslAttribute?.[2]?.txnGeneralEntity) {
      let tempIOFormula =
        tempVarForNslFormula?.txnNslAttribute?.[2]?.txnGeneralEntity?.transEntityRecords?.[0].txnNslAttribute?.[0]
          .txnGeneralEntity.transEntityRecords?.[0].txnNslAttribute;
      tempNSLTransRecord.nslQuery = { sourceAttribute: '', targetAttribute: '', function: '' };
      tempNSLTransRecord.nslQuery.sourceAttribute = tempIOFormula?.[0].values?.[0];
      tempNSLTransRecord.nslQuery.targetAttribute = tempIOFormula?.[1].values?.[0];
      tempNSLTransRecord.nslQuery.function = tempIOFormula?.[2].values?.[0];
    } else {
      tempNSLTransRecord.nslQuery = tempVarForNslFormula?.txnNslAttribute[2]?.values?.[0];
    }
    tempNSLTransRecord.nslFormula = tempVarForNslFormula?.txnNslAttribute[3]?.txnGeneralEntity?.transEntityRecords;

    tempNSLTransRecord.nslCase = tempVarForNslFormula?.txnNslAttribute[4]?.values?.[0];

    tempNSLTransRecord.arithmeticOperator = tempVarForNslFormula?.txnNslAttribute[5]?.values?.[0];
  } else {
    for (let i = 0; i < tempVarForNslFormula.txnNslAttribute?.[3]?.txnGeneralEntity?.transEntityRecords?.length; i++) {
      tempNSLTransRecord.push(
        loadNSLFormula(tempVarForNslFormula?.txnNslAttribute?.[3]?.txnGeneralEntity?.transEntityRecords?.[i])
      );
    }
  }
  return tempNSLTransRecord;
}

/* istanbul ignore next */
export function addPLSLAttributesToQueryLayer(transEntityRecords: any, mapSource: any, attributeValueDropDown: any) {
  /* istanbul ignore next */
  if (transEntityRecords?.attributesListForQueryLayer) {
    /* istanbul ignore next */
    let tempPLattributes: any = [];
    attributeValueDropDown?.forEach((attr: any) => {
      tempPLattributes.push(attr.item);
    });
    transEntityRecords?.attributesListForQueryLayer?.push(...tempPLattributes);
    /* istanbul ignore next */
    transEntityRecords.attributesListForQueryLayer = transEntityRecords.attributesListForQueryLayer?.filter(function (
      elem: any,
      index: any,
      self: string | any[]
    ) {
      return index === self.indexOf(elem);
    });
  }
  return transEntityRecords;
}

/* istanbul ignore next */
export function loadNSLCase(tempVarForNslCase: any) {
  let tempNSLCaseTransRecord: any = [];
  let NslCase = {
    nslQueryCondition: {
      functionName: '',
      nslFormula: '',
      attributeName: '',
      queryOperator: '',
      attributeValue: '',
      nslQuery: '',
      nslQueryCondition: '',
      nslCase: '',
      predicate: '',
    },
    nslThen: { functionName: '', attributeName: '', nslQuery: '', nslFormula: '', nslCase: '' },
    nslElse: { functionName: '', attributeName: '', nslQuery: '', nslFormula: '', nslCase: '' },
  };
  tempNSLCaseTransRecord = NslCase;

  tempNSLCaseTransRecord.nslQueryCondition.functionName =
    tempVarForNslCase?.txnNslAttribute[0]?.txnGeneralEntity?.transEntityRecords?.[0]?.txnNslAttribute[0].values[0];
  tempNSLCaseTransRecord.nslQueryCondition.nslFormula =
    tempVarForNslCase?.txnNslAttribute[0]?.txnGeneralEntity?.transEntityRecords?.[0].txnNslAttribute[1].values[0];
  tempNSLCaseTransRecord.nslQueryCondition.attributeName =
    tempVarForNslCase?.txnNslAttribute[0]?.txnGeneralEntity?.transEntityRecords?.[0].txnNslAttribute[2].values[0];
  tempNSLCaseTransRecord.nslQueryCondition.queryOperator =
    tempVarForNslCase?.txnNslAttribute[0]?.txnGeneralEntity?.transEntityRecords?.[0].txnNslAttribute[3].values[0];
  tempNSLCaseTransRecord.nslQueryCondition.attributeValue =
    tempVarForNslCase?.txnNslAttribute[0]?.txnGeneralEntity?.transEntityRecords?.[0].txnNslAttribute[4].values[0];
  tempNSLCaseTransRecord.nslQueryCondition.nslQuery =
    tempVarForNslCase?.txnNslAttribute[0]?.txnGeneralEntity?.transEntityRecords?.[0].txnNslAttribute[5].values[0];
  tempNSLCaseTransRecord.nslQueryCondition.nslQueryCondition =
    tempVarForNslCase?.txnNslAttribute[0]?.txnGeneralEntity?.transEntityRecords?.[0].txnNslAttribute[6].values[0];
  tempNSLCaseTransRecord.nslQueryCondition.nslCase =
    tempVarForNslCase?.txnNslAttribute[0]?.txnGeneralEntity?.transEntityRecords?.[0].txnNslAttribute[7].values[0];
  tempNSLCaseTransRecord.nslQueryCondition.predicate =
    tempVarForNslCase?.txnNslAttribute[0]?.txnGeneralEntity?.transEntityRecords?.[0].txnNslAttribute[8].values[0];

  tempNSLCaseTransRecord.nslThen.functionName =
    tempVarForNslCase?.txnNslAttribute[1]?.txnGeneralEntity?.transEntityRecords?.[0].txnNslAttribute[0].values[0];
  tempNSLCaseTransRecord.nslThen.attributeName =
    tempVarForNslCase?.txnNslAttribute[1]?.txnGeneralEntity?.transEntityRecords?.[0].txnNslAttribute[1].values[0];
  tempNSLCaseTransRecord.nslThen.nslQuery =
    tempVarForNslCase?.txnNslAttribute[1]?.txnGeneralEntity?.transEntityRecords?.[0].txnNslAttribute[2].values[0];
  tempNSLCaseTransRecord.nslThen.nslFormula =
    tempVarForNslCase?.txnNslAttribute[1]?.txnGeneralEntity?.transEntityRecords?.[0].txnNslAttribute[3].values[0];
  tempNSLCaseTransRecord.nslThen.nslCase =
    tempVarForNslCase?.txnNslAttribute[1]?.txnGeneralEntity?.transEntityRecords?.[0].txnNslAttribute[4].values[0];

  tempNSLCaseTransRecord.nslElse.functionName =
    tempVarForNslCase?.txnNslAttribute[2]?.txnGeneralEntity?.transEntityRecords?.[0]?.txnNslAttribute?.[0]?.values?.[0];
  tempNSLCaseTransRecord.nslElse.attributeName =
    tempVarForNslCase?.txnNslAttribute[2]?.txnGeneralEntity?.transEntityRecords?.[0]?.txnNslAttribute?.[1]?.values?.[0];
  tempNSLCaseTransRecord.nslElse.nslQuery =
    tempVarForNslCase?.txnNslAttribute[2]?.txnGeneralEntity?.transEntityRecords?.[0]?.txnNslAttribute?.[2]?.values?.[0];
  tempNSLCaseTransRecord.nslElse.nslFormula =
    tempVarForNslCase?.txnNslAttribute[2]?.txnGeneralEntity?.transEntityRecords?.[0]?.txnNslAttribute?.[3]?.values?.[0];
  tempNSLCaseTransRecord.nslElse.nslCase =
    tempVarForNslCase?.txnNslAttribute[2]?.txnGeneralEntity?.transEntityRecords?.[0]?.txnNslAttribute?.[4]?.values?.[0];

  return tempNSLCaseTransRecord;
}

/* istanbul ignore next */
export function checkEQLExist(reservedCuIndex: number, tempGsiObj: any, type: string) {
  var txnNslAttribute;
  var transEntityRecords;
  if (reservedCuIndex >= 0 && tempGsiObj?.cuList?.[reservedCuIndex]?.slotItemProperties) {
    if (type === 'EQL') {
      txnNslAttribute =
        tempGsiObj?.cuList?.[reservedCuIndex]?.slotItemProperties?.PL_SL001?.propertyData?.CONSTANT?.changeDriverData
          ?.DATA?.transEntityRecords?.[0]?.txnNslAttribute;
    }
    if (type === 'EQLReport') {
      txnNslAttribute =
        tempGsiObj?.cuList?.[reservedCuIndex]?.slotItemProperties?.PL_SL002?.propertyData?.CONSTANT?.changeDriverData
          ?.DATA?.transEntityRecords?.[0]?.txnNslAttribute;
    }
  }
  return txnNslAttribute;
}

/* istanbul ignore next */
export function fillConditionBlock(
  transEntityRecords: any,
  txnNslAttribute: any,
  level: number,
  query1: any,
  EQLVersion: any
) {
  for (let j = 0; j < txnNslAttribute?.[1].txnGeneralEntity?.transEntityRecords?.length; j++) {
    transEntityRecords.queryData.push(JSON.parse(JSON.stringify(query1)));

    let tempVarForTransEntityRecords = txnNslAttribute?.[1]?.txnGeneralEntity?.transEntityRecords;

    // Attribute Name
    transEntityRecords.queryData[j].attributeName =
      EQLVersion === '2.0'
        ? tempVarForTransEntityRecords?.[j]?.txnNslAttribute?.[1]?.txnGeneralEntity
          ? 'formula'
          : tempVarForTransEntityRecords?.[j]?.txnNslAttribute?.[7]?.txnGeneralEntity
          ? 'case'
          : tempVarForTransEntityRecords?.[j]?.txnNslAttribute?.[2]?.values?.[0]
        : tempVarForTransEntityRecords?.[j]?.txnNslAttribute?.[0]?.values?.[0];

    //NSL Formula in Condition
    let tempReturnValue = fillNSLFormulae(transEntityRecords, tempVarForTransEntityRecords, j, 'condition');
    transEntityRecords = tempReturnValue.transEntityRecords;
    tempVarForTransEntityRecords = tempReturnValue.tempVarForTransEntityRecords;

    //NSL Case in Condition
    tempReturnValue = fillNSLCase(transEntityRecords, tempVarForTransEntityRecords, j, 'condition');
    transEntityRecords = tempReturnValue.transEntityRecords;
    tempVarForTransEntityRecords = tempReturnValue.tempVarForTransEntityRecords;

    // Query Operator
    transEntityRecords.queryData[j].queryOperator =
      EQLVersion === '2.0'
        ? tempVarForTransEntityRecords?.[j]?.txnNslAttribute?.[3]?.values?.[0]
        : tempVarForTransEntityRecords?.[j]?.txnNslAttribute?.[1]?.values?.[0];

    // Attribute Value
    transEntityRecords.queryData[j].attributeValue =
      EQLVersion === '2.0'
        ? tempVarForTransEntityRecords?.[j]?.txnNslAttribute?.[4]?.values?.[0]
        : tempVarForTransEntityRecords?.[j]?.txnNslAttribute?.[2].values?.[0];

    let queryConditionIndex = EQLVersion === '2.0' ? 5 : 3;
    // For SubQuery
    if (
      !tempVarForTransEntityRecords?.[j]?.txnNslAttribute?.[queryConditionIndex]?.txnGeneralEntity ||
      !tempVarForTransEntityRecords?.[j]?.txnNslAttribute?.[queryConditionIndex]?.txnGeneralEntity?.transEntityRecords
    ) {
      transEntityRecords.queryData[j].nslQuery = {};
    } else {
      transEntityRecords.queryData[j].nslQuery = this.loadtempObjData(
        tempVarForTransEntityRecords?.[j]?.txnNslAttribute?.[queryConditionIndex]?.txnGeneralEntity
          ?.transEntityRecords?.[0]?.txnNslAttribute,
        false,
        level + 1
      );
      transEntityRecords.queryData[j].nslQuery.showLibraryBtn = true;
    }

    // Query Condition
    transEntityRecords.queryData[j].queryCondition =
      EQLVersion === '2.0'
        ? tempVarForTransEntityRecords?.[j]?.txnNslAttribute?.[6]?.txnGeneralEntity
        : tempVarForTransEntityRecords?.[j]?.txnNslAttribute?.[4]?.txnGeneralEntity;

    // Predicate
    transEntityRecords.queryData[j].predicate =
      EQLVersion === '2.0'
        ? tempVarForTransEntityRecords?.[j]?.txnNslAttribute?.[8]?.values?.[0]
        : tempVarForTransEntityRecords?.[j]?.txnNslAttribute?.[5]?.values?.[0];

    // Set toggle for transEntityRecords based on Predicate
    if (transEntityRecords?.queryData?.[j]?.predicate) {
      /* istanbul ignore next */
      if (transEntityRecords.queryData[j].predicate === 'AND') {
        transEntityRecords.queryData.toggle.push(false);
      }
      /* istanbul ignore next */
      if (transEntityRecords?.queryData?.[j]?.predicate === 'OR') {
        transEntityRecords.queryData.toggle.push(true);
      }
    } else {
      transEntityRecords.queryData.toggle.push(false);
    }

    // Query Condition for RuleSet Block
    if (transEntityRecords?.queryData[j]?.queryCondition) {
      let nslQueryData = transEntityRecords?.queryData?.[j]?.nslQuery;
      let queryConditionData = transEntityRecords?.queryData?.[j]?.queryCondition;
      // transEntityRecords.queryData[j] = [];
      let ruleSetindex = EQLVersion == '2.0' ? 6 : 4;
      transEntityRecords.queryData[j] = fetchRuleSetData(
        queryConditionData,
        tempVarForTransEntityRecords?.[j]?.txnNslAttribute?.[ruleSetindex]?.txnGeneralEntity?.transEntityRecords,
        0,
        query1,
        EQLVersion
      );

      // For single subquery of whole RuleSet
      /* istanbul ignore next */
      if (!transEntityRecords.queryData?.[j]?.nslQuery) {
        transEntityRecords.queryData[j].nslQuery = {};
      }
      transEntityRecords.queryData[j].nslQuery = nslQueryData;

      transEntityRecords.queryData.isGroupQuery[j] = true;
    } else {
      transEntityRecords.queryData.isGroupQuery[j] = false;
    }
  }
  return transEntityRecords;
}

/* istanbul ignore next */
export function fillInputOutputBlock(
  transEntityRecords: any,
  txnNslAttribute: any,
  queryInputOutputEntityParams: any,
  EQLVerison: any
) {
  let tempVarForTransEntityRecords = txnNslAttribute?.[0]?.txnGeneralEntity?.transEntityRecords;
  for (let j = 0; j < txnNslAttribute?.[0]?.txnGeneralEntity?.transEntityRecords?.length; j++) {
    transEntityRecords.queryInputOutputArray.push(JSON.parse(JSON.stringify(queryInputOutputEntityParams)));

    //for Source Attribute at 0 location (NSL_formula Not Available)
    if (EQLVerison !== '2.0') {
      // Source Attribute
      /* istanbul ignore next */
      transEntityRecords.queryInputOutputArray[j].sourceAttribute =
        tempVarForTransEntityRecords?.[j]?.txnNslAttribute?.[0]?.values[0];

      /* istanbul ignore next */
      transEntityRecords.attributesList.push(tempVarForTransEntityRecords?.[j].txnNslAttribute[0]?.values[0]);

      // Target Attribute
      /* istanbul ignore next */
      transEntityRecords.queryInputOutputArray[j].targetAttribute =
        tempVarForTransEntityRecords[j].txnNslAttribute[1]?.values[0];

      // Function
      /* istanbul ignore next */
      transEntityRecords.queryInputOutputArray[j].function =
        tempVarForTransEntityRecords[j].txnNslAttribute[2]?.values[0];
      transEntityRecords.nslFormula.push(null);
    } else {
      //for NSL Formula at 0 location (NSL_formula Available)
      if (!transEntityRecords.isSelectedOperation) {
        transEntityRecords.isSelectedOperation = [];
      }
      transEntityRecords.isSelectedOperation.push(false);
      transEntityRecords.selectedOperation.push('');
      transEntityRecords.queryInputOutputArray[j].sourceAttribute =
        tempVarForTransEntityRecords?.[j]?.txnNslAttribute?.[2]?.values?.[0];
      /* istanbul ignore next */
      transEntityRecords.attributesList.push(tempVarForTransEntityRecords?.[j]?.txnNslAttribute?.[0]?.values?.[0]);

      // Target Attribute
      /* istanbul ignore next */
      transEntityRecords.queryInputOutputArray[j].targetAttribute =
        tempVarForTransEntityRecords?.[j]?.txnNslAttribute?.[3]?.values?.[0];

      // Function
      /* istanbul ignore next */
      transEntityRecords.queryInputOutputArray[j].function =
        tempVarForTransEntityRecords?.[j]?.txnNslAttribute?.[4]?.values?.[0];

      let tempReturnValue = fillNSLFormulae(transEntityRecords, tempVarForTransEntityRecords, j, 'inputoutput');
      transEntityRecords = tempReturnValue.transEntityRecords;
      tempVarForTransEntityRecords = tempReturnValue.tempVarForTransEntityRecords;

      // NSL Case
      tempReturnValue = fillNSLCase(transEntityRecords, tempVarForTransEntityRecords, j, 'inputoutput');
      transEntityRecords = tempReturnValue.transEntityRecords;
      tempVarForTransEntityRecords = tempReturnValue.tempVarForTransEntityRecords;
    }
  }
}

/* istanbul ignore next */
export function fetchRuleSetData(
  queryCondition: any,
  transEntityRecords: any,
  level: number,
  query1: any,
  EQLVersion: any
) {
  /* istanbul ignore next */
  let RuleSet: any;
  RuleSet = [];
  RuleSet.toggle = [];
  RuleSet.isGroupQuery = [];
  RuleSet.nslQuery = {};

  /* istanbul ignore next */
  if (queryCondition) {
    var groupQueryLength = queryCondition?.transEntityRecords?.length;

    // Loop for each queryData/Rule of RuleSet
    for (let k = 0; k < groupQueryLength; k++) {
      RuleSet.push(JSON.parse(JSON.stringify(query1)));

      // Attribute Name
      RuleSet[k].attributeName =
        EQLVersion === '2.0'
          ? transEntityRecords[k].txnNslAttribute[2].values[0]
          : transEntityRecords[k].txnNslAttribute[0].values[0];

      // Query Operator
      RuleSet[k].queryOperator =
        EQLVersion === '2.0'
          ? transEntityRecords[k].txnNslAttribute[3].values[0]
          : transEntityRecords[k].txnNslAttribute[1].values[0];

      // Attribute Value
      RuleSet[k].attributeValue =
        EQLVersion === '2.0'
          ? transEntityRecords[k].txnNslAttribute[4].values[0]
          : transEntityRecords[k].txnNslAttribute[2].values[0];

      let queryConditionIndex = EQLVersion === '2.0' ? 5 : 3;
      // For SubQuery
      if (
        !transEntityRecords[k].txnNslAttribute[queryConditionIndex].txnGeneralEntity ||
        !transEntityRecords[k].txnNslAttribute[queryConditionIndex].txnGeneralEntity.transEntityRecords
      ) {
        RuleSet[k].nslQuery = {};
      } else {
        RuleSet[k].nslQuery = this.loadtempObjData(
          transEntityRecords[k].txnNslAttribute[queryConditionIndex].txnGeneralEntity.transEntityRecords[0]
            .txnNslAttribute,
          false,
          level + 1
        );
        RuleSet[k].nslQuery.showLibraryBtn = true;
      }

      // Query Condition
      RuleSet[k].queryCondition =
        EQLVersion === '2.0'
          ? transEntityRecords[k].txnNslAttribute[6].txnGeneralEntity
          : transEntityRecords[k].txnNslAttribute[4].txnGeneralEntity;

      // Predicate
      RuleSet[k].predicate =
        EQLVersion === '2.0'
          ? transEntityRecords[k].txnNslAttribute[8].values[0]
          : transEntityRecords[k].txnNslAttribute[5].values[0];

      // Set predicate for RulSet
      /* istanbul ignore next */
      if (RuleSet[k].predicate === 'AND') {
        RuleSet.toggle.push(false);
      }
      /* istanbul ignore next */
      if (RuleSet[k].predicate === 'OR') {
        RuleSet.toggle.push(true);
      }

      // Query Condition for RuleSet Block
      if (RuleSet[k].queryCondition) {
        let nslQueryData = RuleSet[k].nslQuery;
        let queryConditionData = RuleSet[k].queryCondition;
        let queryConditionIndex = EQLVersion === '2.0' ? 6 : 4;
        RuleSet[k] = fetchRuleSetData(
          queryConditionData,
          transEntityRecords[k].txnNslAttribute[queryConditionIndex].txnGeneralEntity.transEntityRecords,
          level + 1,
          query1,
          EQLVersion
        );

        // For single subquery of whole RuleSet
        if (!RuleSet[k].nslQuery) {
          RuleSet[k].nslQuery = {};
        }
        RuleSet[k].nslQuery = nslQueryData;

        RuleSet.isGroupQuery.push(true);
      } else {
        RuleSet.isGroupQuery.push(false);
      }
    }
  }
  return RuleSet;
}

// Add new Rule block
/* istanbul ignore next */
export function addNewQuery(transEntityRecords: any, query1: any) {
  /* istanbul ignore next */
  if (!transEntityRecords.queryData) {
    transEntityRecords.queryData = [];
  }
  /* istanbul ignore next */
  if (!transEntityRecords.queryData.toggle) {
    transEntityRecords.queryData.toggle = [];
  }
  /* istanbul ignore next */
  if (!transEntityRecords.queryData.isGroupQuery) {
    transEntityRecords.queryData.isGroupQuery = [];
  }

  transEntityRecords.queryData.push(JSON.parse(JSON.stringify(query1)));
  transEntityRecords.queryData.toggle.push(false);
  let index = transEntityRecords.queryData.length - 1;
  /* istanbul ignore next */
  if (index > 0) {
    transEntityRecords.queryData[index - 1].predicate = 'AND';
    transEntityRecords.queryData.toggle[index - 1] = false;
  }
  transEntityRecords.queryData.isGroupQuery.push(false);
  transEntityRecords?.nslFormulaForCondition?.push([]);
  transEntityRecords?.nslCaseForCondition?.push([]);
  return transEntityRecords;
}

//Add new RuleSet block
/* istanbul ignore next */
export function addNewGroupQuery(transEntityRecords: any) {
  /* istanbul ignore next */
  if (!transEntityRecords.queryData) {
    transEntityRecords.queryData = [];
  }
  /* istanbul ignore next */
  if (!transEntityRecords.queryData.toggle) {
    transEntityRecords.queryData.toggle = [];
  }
  /* istanbul ignore next */
  if (!transEntityRecords.queryData.isGroupQuery) {
    transEntityRecords.queryData.isGroupQuery = [];
  }

  transEntityRecords.queryData.push([]);
  let index = transEntityRecords.queryData.length - 1;
  transEntityRecords.queryData.toggle.push(false);
  /* istanbul ignore next */
  if (index > 0) {
    transEntityRecords.queryData[index - 1].predicate = 'AND';
    transEntityRecords.queryData.toggle[index - 1] = false;
  }
  transEntityRecords.queryData.isGroupQuery.push(true);
  return transEntityRecords;
}

// Add new rule block within RuleSet block
/* istanbul ignore next */
export function addNewQueryForGroup(RuleSet: any, query1: any) {
  /* istanbul ignore next */
  if (!RuleSet.toggle) {
    RuleSet.toggle = [];
  }
  /* istanbul ignore next */
  if (!RuleSet.isGroupQuery) {
    RuleSet.isGroupQuery = [];
  }

  RuleSet.push(JSON.parse(JSON.stringify(query1)));
  let index = RuleSet.length - 1;
  // Set previous rule predicate as AND
  /* istanbul ignore next */
  if (index > 0) {
    RuleSet[index - 1].predicate = 'AND';
  }

  RuleSet.toggle.push(false);
  RuleSet.isGroupQuery.push(false);
  return RuleSet;
}

// Add new RuleSet block within RuleSet block
/* istanbul ignore next */
export function addNewGroupQueryForGroup(RuleSet: any) {
  /* istanbul ignore next */
  if (!RuleSet.toggle) {
    RuleSet.toggle = [];
  }
  /* istanbul ignore next */
  if (!RuleSet.isGroupQuery) {
    RuleSet.isGroupQuery = [];
  }

  RuleSet.push([]);
  RuleSet.toggle.push(false);
  RuleSet.isGroupQuery.push(true);
  let index = RuleSet.length - 1;
  /* istanbul ignore next */
  if (index > 1) {
    RuleSet[index - 1].predicate = 'AND';
  }
  return RuleSet;
}

// Add SubQuery Block within a Rule/RuleSet Block
/* istanbul ignore next */
export function addSubQuery(queryBlock: any, level: number, transEntityRecordsparams: any) {
  /* istanbul ignore next */
  if (!queryBlock.nslQuery) {
    queryBlock.nslQuery = {};
  }
  /* istanbul ignore next */
  if (!queryBlock.nslQuery.queryData) {
    queryBlock.nslQuery = JSON.parse(JSON.stringify(transEntityRecordsparams));
    queryBlock.nslQuery.queryData = [];
    queryBlock.nslQuery.queryData.isGroupQuery = [];
    queryBlock.nslQuery.queryData.toggle = [];
    queryBlock.nslQuery.queryInputOutputArray = [];
    queryBlock.nslQuery.attributesList = [];
    queryBlock.nslQuery.filterData = [];
    queryBlock.nslQuery.currentLevel = level + 1;
  }
  queryBlock.nslQuery.showLibraryBtn = true;
  return queryBlock;
}

export function validateQuery(
  tempGsiObj: any,
  reservedCuIndex: any,
  validateQueryParams: any,
  EQLVersion: any,
  ccdRequest: any,
  entityForTargetDropDown?: any,
  type?: any
) {
  var validateQueryFormat: any = [];
  let outputGeneralEntity;
  if (type == 'EQL-Report') {
    outputGeneralEntity = entityForTargetDropDown;
  } else {
    outputGeneralEntity = tempGsiObj?.cuList?.[reservedCuIndex]?.layers.find(
      (layer: any) => layer.type === 'triggerCES'
    );
  }
  let tempQueryParams: any = {};
  for (let i = 0; i < validateQueryParams.length; i++) {
    if (validateQueryParams?.[i]?.attrValue?.[0] !== '' || validateQueryParams?.[i]?.attrValue?.[1] !== '') {
      tempQueryParams[validateQueryParams[i].attr] = [];
      validateQueryParams[i].attrValue.forEach((value: any, j: any) => {
        tempQueryParams[validateQueryParams[i].attr].push(validateQueryParams[i].attrValue[j].value);
      });
    }
  }

  validateQueryFormat = {
    version: EQLVersion,
    nslQuery:
      type == 'EQL-Report'
        ? ccdRequest.PL_SL002.propertyData.CONSTANT.changeDriverData.DATA
        : ccdRequest.PL_SL001.propertyData.CONSTANT.changeDriverData.DATA,
    outputGeneralEntity: type == 'EQL-Report' ? outputGeneralEntity : outputGeneralEntity?.participatingItems?.[0],
    queryParameters: tempQueryParams,
  };
  return validateQueryFormat;
}
//To check data type of source attribute and provide condition accordingly
export function checkDataTypeInCondition(cpArray: any) {
  /* istanbul ignore next */
  if (
    cpArray?.sourceAttributeType == 'number' ||
    cpArray?.sourceAttributeType == 'decimalnumber' ||
    cpArray?.sourceAttributeType == undefined ||
    cpArray?.sourceAttributeType == ''
  ) {
    /* istanbul ignore else */
    if (isNaN(cpArray.targetAttributeInput)) {
      cpArray.targetAttributeInput = `'${cpArray.targetAttributeInput}'`;
    }
  } else {
    cpArray.targetAttributeInput = `'${cpArray.targetAttributeInput}'`;
  }
  return cpArray;
}
export function getFlatData(cunit: any) {
  let dataToSend: Flat2Flat[] = [];
  /*istanbul ignore else*/
  let changeUnit: CUFlat = new CUFlat(
    cunit?.name,
    cunit?.referencedChangeUnit ? cunit?.referencedChangeUnit : cunit?.id,
    '<cu TF ID>',
    cunit?.isReserved ? 'true' : 'false'
  );
  if (cunit.isReserved) {
    changeUnit.isReserved = 'true';
  }
  let agentName: string = '';
  /*istanbul ignore else*/
  if (cunit?.agents && cunit?.agents[0]?.agentType == 'machine') {
    agentName = 'machine';
  }
  /*istanbul ignore else*/
  if (cunit?.designTimeRights && cunit?.designTimeRights?.length > 0) {
    cunit?.designTimeRights?.forEach((agent: any) => {
      agentName = agentName?.length > 0 ? agentName + ',' + agent?.rightHolderName : agent?.rightHolderName;
    });
  }
  /*istanbul ignore else*/
  let machineAgent = cunit?.sentenceTags?.physical?.find((tag: any) => tag.tag == 'Mch.');
  if (machineAgent) {
    agentName = machineAgent?.token;
  }

  let layers: Entities = {};
  let agents: Agents = new Agents(agentName, '<agents DSD ID>', '<agents TF ID>');
  cunit?.layers?.forEach((layer: any) => {
    /*istanbul ignore else*/
    let entites: EntityFlatKey = {};
    layer?.participatingItems?.forEach((entity: any) => {
      let attributes: AttributeDict = {};
      if (entity?.nslAttributes && entity?.nslAttributes.length > 0) {
        entity?.nslAttributes?.forEach((attribute: any) => {
          attributes[attribute?.name] = new AttributeFlat('<attr 1.1 DSD ID>', '<attr 1.1 TF ID>');
        });
      }
      entites[entity?.name] = new EntityFlat('<entity1 TF ID>', entity?.id, attributes);
    });
    layers[layer?.type] = entites;
  });
  let data: Flat2Flat = new Flat2Flat(changeUnit, agents, layers);
  dataToSend.push(data);
  return dataToSend;
}

export function callfat2flatGSI(gsiData: any) {
  let dataToSend: Flat2Flat[] = [];

  let changeUnit: CUFlat = new CUFlat(
    gsiData?.name,
    gsiData?.referencedChangeUnit ? gsiData?.referencedChangeUnit : '',
    '<cu TF ID>',
    'false'
  );
  let agentName: string = '';
  if (gsiData?.designTimeRights && gsiData?.designTimeRights?.length > 0) {
    gsiData?.designTimeRights?.forEach((agent: any) => {
      agentName = agent?.rightHolderName;
    });
  }

  let layers: Entities = {};
  let agents: Agents = new Agents(agentName, '<agents DSD ID>', '<agents TF ID>');
  gsiData?.layers?.forEach((layer: any) => {
    /*istanbul ignore else*/
    let entites: EntityFlatKey = {};
    layer?.participatingItems?.forEach((entity: any) => {
      let attributes: AttributeDict = {};
      if (entity?.nslAttributes && entity?.nslAttributes.length > 0) {
        entity?.nslAttributes?.forEach((attribute: any) => {
          attributes[attribute?.name] = new AttributeFlat('<attr 1.1 DSD ID>', '<attr 1.1 TF ID>');
        });
      }
      entites[entity?.name] = new EntityFlat('<entity1 TF ID>', entity?.id, attributes);
    });
    layers[layer?.type] = entites;
  });
  let data: Flat2Flat = new Flat2Flat(changeUnit, agents, layers);
  dataToSend.push(data);

  return dataToSend;
}

export function callfat2flat(gsiData: any) {
  let dataToSend: Flat2Flat[] = [];
  /*istanbul ignore next*/
  gsiData?.solutionLogic?.forEach((cunit: any) => {
    let changeUnit: CUFlat = new CUFlat(
      cunit?.name,
      cunit?.referencedChangeUnit ? cunit.referencedChangeUnit : '',
      '<cu TF ID>',
      cunit.isReserved ? 'true' : 'false'
    );
    let agentName: string = '';
    if (cunit?.agents && cunit?.agents[0]?.agentType == 'machine') {
      agentName = 'machine';
    }
    if (cunit?.designTimeRights && cunit?.designTimeRights?.length > 0) {
      cunit?.designTimeRights?.forEach((agent: any) => {
        agentName = agentName?.length > 0 ? agentName + ',' + agent?.rightHolderName : agent?.rightHolderName;
      });
    }
    let machineAgent = cunit?.sentenceTags?.physical?.find((tag: any) => tag.tag == 'Mch.');
    if (machineAgent) {
      agentName = machineAgent?.token;
    }
    let layers: Entities = {};
    let agents: Agents = new Agents(agentName, '<agents DSD ID>', '<agents TF ID>');
    cunit?.layers?.forEach((layer: any) => {
      /*istanbul ignore else*/
      let entites: EntityFlatKey = {};
      layer?.participatingItems?.forEach((entity: any) => {
        let attributes: AttributeDict = {};
        if (entity?.nslAttributes && entity?.nslAttributes?.length > 0) {
          entity?.nslAttributes?.forEach((attribute: any) => {
            attributes[attribute?.name] = new AttributeFlat('<attr 1.1 DSD ID>', '<attr 1.1 TF ID>');
          });
        }
        entites[entity?.name] = new EntityFlat('<entity1 TF ID>', entity?.id, attributes);
      });
      layers[layer?.type] = entites;
    });
    let data: Flat2Flat = new Flat2Flat(changeUnit, agents, layers);
    dataToSend.push(data);
  });
  return dataToSend;
}
export function wrapperDrop(event: CdkDragDrop<string[]>) {
  /* istanbul ignore next */
  if (event.previousContainer === event.container) {
    moveItemInArray(event.container?.data, event.previousIndex, event.currentIndex);
  } else {
    transferArrayItem(event.previousContainer?.data, event.container?.data, event.previousIndex, event.currentIndex);
  }
}

/* istanbul ignore next */
export function keyIdentifierGetter(fieldObj: any, selectortype: any) {
  let keyIdentifier = '';
  let field = fieldObj.value;
  if (field.type === 'attribute' && field.entAttributeName !== '') {
    keyIdentifier += '{${' + field.entAttributeName + '}';
    if (field.entAttributeString !== '') {
      keyIdentifier += '.' + field.entAttributeString + '.' + field.entAttributeStringValue;
    }
    keyIdentifier += '}';
  } else if (field.type === 'separator' && selectortype) {
    keyIdentifier += '{$SEP}';
  } else if (field.type === 'increment') {
    keyIdentifier += '{$INC_ID}';
  } else if (field.type === 'date') {
    keyIdentifier += '{$' + field.dateFormat + '}';
  } else if (field.type === 'format') {
    keyIdentifier += '{' + field.format + '}';
  }
  return keyIdentifier;
}

// to add new condition array
export function addNewCondition(conditionalPotentiality: any, queries: any, toggleCondition: any) {
  conditionalPotentiality.push(JSON.parse(JSON.stringify(queries)));
  toggleCondition.push(false);
  /* istanbul ignore else  */
  if (conditionalPotentiality.length > 1) {
    conditionalPotentiality[conditionalPotentiality.length - 2].predicate = '&&';
  }
  return {
    conditionalPotentiality: conditionalPotentiality,
    toggleCondition: toggleCondition,
  };
}

// changing predicate of conditions  to conditional potentiality array
export function predicateChanged(predicate: any, index: any, conditionalPotentiality: any, toggleCondition: any) {
  conditionalPotentiality[index - 1].predicate = predicate;
  /* istanbul ignore next  */
  if (predicate == '&&') {
    /* istanbul ignore else  */
    if (toggleCondition[index]) {
      toggleCondition[index] = false;
    }
  } else if (predicate == '||') {
    /* istanbul ignore else  */
    if (!toggleCondition[index]) {
      toggleCondition[index] = true;
    }
  }
  return {
    conditionalPotentiality: conditionalPotentiality,
    toggleCondition: toggleCondition,
  };
}

// deleting the query of conditions from conditional potentiality array
export function deleteCondition(rowIndex: number, conditionalPotentiality: any, toggleCondition: any) {
  /* istanbul ignore else  */
  conditionalPotentiality.splice(rowIndex, 1);
  toggleCondition.splice(rowIndex, 1);
  /* istanbul ignore next  */
  if (conditionalPotentiality[rowIndex - 1]?.predicate) {
    conditionalPotentiality[rowIndex - 1].predicate = '';
  }
  return {
    conditionalPotentiality: conditionalPotentiality,
    toggleCondition: toggleCondition,
  };
}

//updating condition
export function updateConditionalPotentiality(conditionalPotentiality: any) {
  let condition = '';
  let conditionalPotentialityAttr = conditionalPotentiality;
  for (let conditonAltobj of conditionalPotentialityAttr) {
    /* istanbul ignore else */
    if (conditonAltobj.targetAttributeInput) {
      conditonAltobj = checkDataTypeInCondition(conditonAltobj);
    }
    conditonAltobj.targetAttribute =
      conditonAltobj.targetAttribute.trim() !== ''
        ? conditonAltobj.targetAttribute
        : conditonAltobj.targetAttributeInput;
    if (
      !conditonAltobj.sourceAttribute &&
      !conditonAltobj.operator &&
      !conditonAltobj.targetAttribute &&
      conditionalPotentialityAttr.length == 1
    ) {
      condition = condition.trim();
    } else {
      if (!conditonAltobj.operator) {
        return false;
      } else {
        condition =
          condition +
          conditonAltobj.sourceAttribute +
          ' ' +
          conditonAltobj.operator +
          ' ' +
          conditonAltobj.targetAttribute +
          ' ' +
          conditonAltobj.predicate +
          ' ';
      }
    }
  }
  condition = condition.trim();
  return condition;
}

//creates Recursive Conditons
export function createConditionalArray(condition: any, conditionAttr: any): any {
  let predicateConditionIndex: any;
  if (condition.indexOf('&&') > condition.indexOf('||')) {
    if (condition.indexOf('||') == -1) {
      predicateConditionIndex = condition.indexOf('&&');
    } else {
      predicateConditionIndex = condition.indexOf('||');
    }
  } else if (condition.indexOf('&&') < condition.indexOf('||')) {
    if (condition.indexOf('&&') == -1) {
      predicateConditionIndex = condition.indexOf('||');
    } else {
      predicateConditionIndex = condition.indexOf('&&');
    }
  } else {
    conditionAttr.push(getSourceTargetArray(condition, ''));
    return conditionAttr;
  }
  /* istanbul ignore next */
  if (condition[predicateConditionIndex] === '&') {
    let tempCondition = condition.substring(0, predicateConditionIndex);
    conditionAttr.push(getSourceTargetArray(tempCondition, '&&'));
    condition = condition.substring(predicateConditionIndex + 2, condition.length);
    /* istanbul ignore else */
    if (condition) {
      return createConditionalArray(condition.trim(), conditionAttr);
    }
  } else if (condition[predicateConditionIndex] === '|') {
    let tempCondition = condition.substring(0, predicateConditionIndex);
    conditionAttr.push(getSourceTargetArray(tempCondition, '||'));
    condition = condition.substring(predicateConditionIndex + 2, condition.length);
    /* istanbul ignore else */
    if (condition) {
      return createConditionalArray(condition.trim(), conditionAttr);
    }
  }
}
// gets source and target
export function getSourceTargetArray(tempCondition: string, predicate: string) {
  let operators = [' < ', ' > ', '!=', ' = ', '==', '<=', '>=', 'IN POTENTIALITY', 'IN REALITY'];
  let splitedTemp = [];
  for (let operator of operators) {
    /* istanbul ignore next */
    if (tempCondition?.includes(operator)) {
      splitedTemp = tempCondition.split(operator);
      return {
        sourceAttribute: splitedTemp?.[0].trim(),
        operator: operator.trim(),
        targetAttribute: splitedTemp?.[1].trim(),
        targetAttributeInput: '',
        predicate: predicate.trim(),
      };
    }
  }
}

export function loadEntityAttributeData(
  res: any,
  transEntityRecords: any,
  transEntityRecordsparams: any,
  queryInputOutputEntityParams: any
) {
  /* istanbul ignore next */
  if (!transEntityRecords) {
    transEntityRecords = [];
  }
  /* istanbul ignore next */
  if (!transEntityRecords?.queryData) {
    // transEntityRecords = JSON.parse(JSON.stringify(transEntityRecordsparams));
    transEntityRecords.queryData = [];
    transEntityRecords.queryData.isGroupQuery = [];
    transEntityRecords.queryData.toggle = [];
    transEntityRecords.nslFormula = [];
    transEntityRecords.nslCase = [];
    if (!transEntityRecords.queryInputOutputArray) {
      transEntityRecords.queryInputOutputArray = [];
    }
    transEntityRecords.filterData = [];
    transEntityRecords.currentLevel = 0;
  }
  /* istanbul ignore next */
  transEntityRecords = addQueryAttributes(res, res?.name, transEntityRecords, queryInputOutputEntityParams);
  /* istanbul ignore next */
  let entityName = res?.name;
  let checkEntityAvailable = false;
  /* istanbul ignore next */
  transEntityRecords?.entityList?.forEach((entity: any) => {
    /* istanbul ignore next */
    if (entity === entityName) {
      checkEntityAvailable = true;
    }
  });

  /* istanbul ignore next */
  if (!checkEntityAvailable) {
    transEntityRecords?.entityList?.push(entityName);
  }

  return transEntityRecords;
}

/* istanbul ignore next */
export function addQueryAttributes(
  entityData: any,
  entityname: string,
  transEntityRecords: any,
  queryInputOutputEntityParams: any
) {
  let entityName = entityname;
  let sourceAttribute = entityData?.nslAttributes;
  let newAttrName: string;
  /* istanbul ignore next */
  for (var i = 0; i < sourceAttribute?.length; i++) {
    newAttrName = entityName + '.' + sourceAttribute[i]?.name;
    /* istanbul ignore next */
    if (transEntityRecords?.attributesList?.length > 0) {
    } else {
      transEntityRecords.attributesList = [];
      transEntityRecords.entityList = [];
    }
    let tempSourceAttr: any = [];
    transEntityRecords?.queryInputOutputArray?.forEach((element: any) => {
      if (element?.sourceAttribute) {
        tempSourceAttr.push(element.sourceAttribute);
      }
    });
    /* istanbul ignore next */
    if (!tempSourceAttr?.includes(newAttrName)) {
      /* istanbul ignore next */
      if (!sourceAttribute[i]?.generalEntity) {
        transEntityRecords?.queryInputOutputArray?.push(JSON.parse(JSON.stringify(queryInputOutputEntityParams)));
        transEntityRecords.queryInputOutputArray[
          transEntityRecords?.queryInputOutputArray?.length - 1
        ].sourceAttribute = newAttrName;
        /* istanbul ignore next */
        if (!transEntityRecords?.attributesList?.includes(newAttrName)) {
          transEntityRecords?.attributesList?.push(newAttrName);
        }
      } /* istanbul ignore next */ else if (sourceAttribute[i]?.generalEntity) {
        transEntityRecords = addQueryAttributes(
          sourceAttribute[i].generalEntity,
          newAttrName,
          transEntityRecords,
          queryInputOutputEntityParams
        );
      }
    }
    /* istanbul ignore next */
    transEntityRecords?.nslFormula?.push([]);
    transEntityRecords?.nslCase?.push([]);
  }
  return transEntityRecords;
}

// Find layer in reserved cu
/* istanbul ignore next */
export function findLayer(reservedCu: any, type: string) {
  /* istanbul ignore next */
  return reservedCu.layers?.find((layer: any) => layer.type === type);
}

/* istanbul ignore next */
export function setEntityId(reservedCu: any, ccdRequest: any, type: string) {
  let layer = findLayer(reservedCu, 'physical');
  if (type === 'EQL') {
    let entityId = layer.participatingItems?.[0].id;
    ccdRequest.PL_SL001.propertyData.CONSTANT.changeDriverData.DATA.dsdId = entityId;
  } else {
    let entityId = layer.participatingItems[1].id;
    ccdRequest.PL_SL002.propertyData.CONSTANT.changeDriverData.DATA.dsdId = entityId;
  }
  return ccdRequest;
}

//transform form values to txnGeneralEntity
/* istanbul ignore next */
export function transformQueryRecords(
  transEntityRecords: any,
  fields: any,
  entityIndex: number,
  reservedCu: any,
  type: any
) {
  let main: any = [];
  /* istanbul ignore next */
  transEntityRecords?.forEach((record: any) => {
    if (record) {
      let tempEnt: any = { txnNslAttribute: [] };
      /* istanbul ignore next */
      fields?.forEach((field: any, i: any) => {
        /* istanbul ignore next */
        let rec = generateTransEntityRecord(field, i, record[field?.name], entityIndex, reservedCu, type);
        tempEnt.txnNslAttribute.push(rec);
      });
      main.push(tempEnt);
    }
  });
  return main;
}

//generatetransentity record from input fields
/* istanbul ignore next */
export function generateTransEntityRecord(
  field: any,
  index: number,
  value: any,
  entityIndex: number,
  reservedCu: any,
  type: string
) {
  let physicalLayer = findLayer(reservedCu, 'physical');
  /* istanbul ignore next */
  let rec: any = {
    name: field.display,
    dsdId:
      type === 'EQL'
        ? physicalLayer?.participatingItems?.[0]?.nslAttributes?.[entityIndex]?.generalEntity?.nslAttributes?.[index]
            ?.dsdId
        : physicalLayer?.participatingItems?.[1]?.nslAttributes?.[entityIndex]?.generalEntity?.nslAttributes?.[index]
            ?.dsdId,
    values: [],
  };
  /* istanbul ignore next */
  if (value) {
    if (field.name === 'nslQuery') {
      rec.values = [];
    } else {
      rec.values.push(value);
    }
  }
  return rec;
}

/* istanbul ignore next */
export function transformNSLCaseRecords(transEntityRecords: any, fields: any, entityIndex: number, reservedCu: any) {
  let main: any = [];
  transEntityRecords?.forEach((record: any) => {
    if (record) {
      let tempEnt: any = { txnNslAttribute: [] };
      /* istanbul ignore next */
      fields?.forEach((field: any, i: any) => {
        /* istanbul ignore next */
        let rec = generateNSLCaseTransEntityRecord(field, i, record?.[field?.name], entityIndex, reservedCu);
        tempEnt.txnNslAttribute.push(rec);
      });
      main.push(tempEnt);
    } else if (record.length) {
      transformNSLCaseRecords(record, fields, entityIndex, reservedCu);
    }
  });
  return main;
}

/* istanbul ignore next */
export function generateNSLCaseTransEntityRecord(
  field: any,
  index: number,
  value: any,
  entityIndex: number,
  reservedCu: any
) {
  let physicalLayer = findLayer(reservedCu, 'physical');

  let rec: any = {
    name: field.display,
    dsdId:
      entityIndex === 0
        ? physicalLayer?.participatingItems?.[0].nslAttributes?.[1]?.generalEntity?.nslAttributes?.[index]?.dsdId
        : physicalLayer?.participatingItems?.[0].nslAttributes?.[0]?.generalEntity?.nslAttributes?.[1]?.generalEntity
            ?.nslAttributes?.[index]?.dsdId,
    values: [],
  };
  /* istanbul ignore next */
  if (value) {
    if (field.name === 'nslQuery') {
      rec.values = [];
    } else {
      rec.values.push(value);
    }
  }
  return rec;
}

//get sub entity id from reserved cu
/* istanbul ignore next */
export function extractSubEntityId(reservedCu: any, name: string, input: any, type: any) {
  var entity;
  /* istanbul ignore else */
  if (name === 'NSL_Formula') {
    entity = getNSLFormulaEntityData(name, reservedCu, type);
  } else if (name === 'NSL_Then' || name === 'NSL_Else') {
    entity = getNSLThenElseEntityData(name, reservedCu, type);
  } else if (name === 'NSL_Case') {
    entity = getNSLCaseEntityData(name, reservedCu, type);
  } else {
    entity = getEntityData(name, reservedCu, type);
  }
  /* istanbul ignore next */
  return {
    name: name,
    dsdId: entity?.id,
    values: [] as any,
    properties: ['CONSTANT'],
    txnGeneralEntity: { name: name, dsdId: entity?.generalEntity?.dsdId, transEntityRecords: input },
  };
}

//get NSL_Formula entity data from reserved cu
/* istanbul ignore next */
export function getNSLFormulaEntityData(name: string, reservedCu: any, type: any) {
  let physicalLayer = findLayer(reservedCu, 'physical');
  if (type === 'EQL') {
    return physicalLayer?.participatingItems?.[0].nslAttributes?.[0]?.generalEntity?.nslAttributes.find((ent: any) => {
      return ent.name === name;
    });
  } else {
    return physicalLayer?.participatingItems?.[1].nslAttributes?.[0]?.generalEntity?.nslAttributes.find((ent: any) => {
      return ent.name === name;
    });
  }
}

/* istanbul ignore next */
export function getNSLThenElseEntityData(name: string, reservedCu: any, type: any) {
  let physicalLayer = findLayer(reservedCu, 'physical');
  if (type === 'EQL') {
    return physicalLayer?.participatingItems?.[0].nslAttributes?.[0]?.generalEntity?.nslAttributes[1]?.generalEntity?.nslAttributes.find(
      (ent: any) => {
        return ent.name === name;
      }
    );
  } else {
    return physicalLayer?.participatingItems?.[1].nslAttributes?.[0]?.generalEntity?.nslAttributes[1]?.generalEntity?.nslAttributes.find(
      (ent: any) => {
        return ent.name === name;
      }
    );
  }
}

/* istanbul ignore next */
export function getNSLCaseEntityData(name: string, reservedCu: any, type: any) {
  let physicalLayer = findLayer(reservedCu, 'physical');
  if (type === 'EQL') {
    return physicalLayer?.participatingItems?.[0].nslAttributes?.[0]?.generalEntity?.nslAttributes.find((ent: any) => {
      return ent.name === name;
    });
  } else {
    return physicalLayer?.participatingItems?.[1].nslAttributes?.[0]?.generalEntity?.nslAttributes.find((ent: any) => {
      return ent.name === name;
    });
  }
}

//get entity data from reserved cu
/* istanbul ignore next */
export function getEntityData(name: string, reservedCu: any, type: any) {
  let physicalLayer = findLayer(reservedCu, 'physical');
  /* istanbul ignore next */
  if (type === 'EQL') {
    return physicalLayer?.participatingItems?.[0].nslAttributes?.find((ent: any) => {
      return ent.name === name;
    });
  } else {
    return physicalLayer?.participatingItems?.[1].nslAttributes?.find((ent: any) => {
      return ent.name === name;
    });
  }
}

/* istanbul ignore next */
export function transformNSLFormulaRecords(
  transEntityRecords: any,
  fields: any,
  entityIndex: number,
  reservedCu: any,
  type: any
) {
  let main: any = [];
  transEntityRecords?.forEach((record: any) => {
    if (record) {
      let tempEnt: any = { txnNslAttribute: [] };
      /* istanbul ignore next */
      fields?.forEach((field: any, i: any) => {
        /* istanbul ignore next */
        let rec = generateNSLFormulaTransEntityRecord(field, i, record?.[field?.name], entityIndex, reservedCu, type);
        tempEnt.txnNslAttribute.push(rec);
      });
      main.push(tempEnt);
    } else if (record.length) {
      transformNSLFormulaRecords(record, fields, entityIndex, reservedCu, type);
    }
  });
  return main;
}

//generatetransentity Formula record from input fields
/* istanbul ignore next */
export function generateNSLFormulaTransEntityRecord(
  field: any,
  index: number,
  value: any,
  entityIndex: number,
  reservedCu: any,
  type: any
) {
  let physicalLayer = findLayer(reservedCu, 'physical');

  let rec: any = {
    name: field.display,
    dsdId:
      type === 'EQL'
        ? physicalLayer?.participatingItems?.[0].nslAttributes?.[0]?.generalEntity?.nslAttributes?.[0]?.generalEntity
            ?.nslAttributes?.[index]?.dsdId
        : physicalLayer?.participatingItems?.[1].nslAttributes?.[0]?.generalEntity?.nslAttributes?.[0]?.generalEntity
            ?.nslAttributes?.[index]?.dsdId,
    values: [],
  };
  /* istanbul ignore next */
  if (value) {
    if (field.name === 'nslQuery') {
      rec.values = [];
    } else {
      rec.values.push(value);
    }
  }
  return rec;
}

/* istanbul ignore next */
export function transformGroupFormulaData(Formula: any, reservedCu: any, arithmeticFields: any, type: any) {
  var tempGroupFormulaIndex = [];
  let tempGroupFormulaData = [];
  let tempGroupFormulaRecords: any;
  let tempGroupNslFormula: any;

  // Check weather any rule or ruleset exists or not
  /* istanbul ignore next */
  if (Formula?.length > 0) {
    /* istanbul ignore next */
    for (let i = 0; i < Formula?.length; i++) {
      // For Rule
      /* istanbul ignore next */
      if (!Formula?.[i]?.length) {
        tempGroupFormulaData.push(Formula?.[i]);
      }
      // For RuleSet Block
      else {
        let tempGroupFormula = {
          functionName: '',
          attributeName: '',
          nslQuery: '',
          nslFormula: '',
          nslCase: '',
          arithmeticOperator: '',
        };

        tempGroupFormula.nslFormula = transformGroupFormulaData(Formula?.[i], reservedCu, arithmeticFields, type);
        tempGroupFormulaIndex.push(i);
        tempGroupFormulaData.push(tempGroupFormula);
      }
    }
  }

  // Transform data to API JSON format
  tempGroupNslFormula = transformNSLFormulaRecords(tempGroupFormulaData, arithmeticFields, 0, reservedCu, type);

  tempGroupFormulaRecords = extractSubEntityId(reservedCu, 'NSL_Formula', tempGroupNslFormula, type);

  // For RuleSet inside a RuleSet
  /* istanbul ignore next */
  // Rules of RuleSet Block

  if (tempGroupFormulaIndex?.length > 0) {
    if (tempGroupFormulaIndex?.length > 0) {
      tempGroupFormulaRecords.txnGeneralEntity.transEntityRecords = returnFormulaRecords(
        tempGroupFormulaRecords?.txnGeneralEntity?.transEntityRecords,
        tempGroupFormulaIndex
      );
    }
  }

  // Return only txnGeneralEntity from NSL_Query_Condition
  return tempGroupFormulaRecords.txnGeneralEntity;
}

/* istanbul ignore next */
export function transformGroupQueryData(queryData: any, reservedCu: any, EQLVersion: any, queryFields: any, type: any) {
  var tempGroupQueryIndex = [];
  var tempGroupQueryData = [];
  let tempGroupTransEntityRecords: any;
  let tempGroupQuery: any;

  // Check weather any rule or ruleset exists or not
  /* istanbul ignore next */
  if (queryData.length > 0) {
    for (let i = 0; i < queryData.isGroupQuery.length; i++) {
      // For Rule
      if (!queryData?.isGroupQuery?.[i]) {
        tempGroupQueryData.push(queryData?.[i]);
      }
      // For RuleSet Block
      else {
        let tempGroupTransEntityRecord = {
          attributeName: '',
          attributeValue: '',
          nslQuery: '',
          predicate: queryData.toggle[i] === true && i > 0 ? 'OR' : 'AND',
          queryCondition: {},
          queryOperator: '',
        };

        // QueryCondition for RuleSet inside a RuleSet
        tempGroupTransEntityRecord.queryCondition = transformGroupQueryData(
          queryData?.[i],
          reservedCu,
          EQLVersion,
          queryFields,
          type
        );

        tempGroupQueryIndex.push(i);
        tempGroupQueryData.push(tempGroupTransEntityRecord);
      }
    }
  }

  // Transform data to API JSON format
  tempGroupQuery = transformQueryRecords(tempGroupQueryData, queryFields, 1, reservedCu, type);
  tempGroupTransEntityRecords = extractSubEntityId(reservedCu, 'NSL_Query_Condition', tempGroupQuery, type);

  let queryConditionIndex = EQLVersion == '2.0' ? 6 : 4;
  // For RuleSet inside a RuleSet
  /* istanbul ignore next */
  if (tempGroupQueryIndex?.length > 0) {
    let transEntityRecords = tempGroupTransEntityRecords?.txnGeneralEntity?.transEntityRecords;
    for (let i = 0; i < transEntityRecords?.length; i++) {
      for (let value of tempGroupQueryIndex) {
        /* istanbul ignore next */
        if (i === value) {
          transEntityRecords[i].txnNslAttribute[queryConditionIndex].txnGeneralEntity =
            transEntityRecords?.[i]?.txnNslAttribute?.[queryConditionIndex]?.values?.[0];

          transEntityRecords[i].txnNslAttribute[queryConditionIndex].values = [];
        }
      }
    }
  }

  // Return only txnGeneralEntity from NSL_Query_Condition
  return tempGroupTransEntityRecords.txnGeneralEntity;
}

export function convertFromToTransEntity(
  transEntityRecords: any,
  reservedCu: any,
  EQLVersion: any,
  queryInputOutputFields: any,
  queryFields: any,
  arithmeticFields: any,
  filterFields: any,
  caseFields: any,
  ccdRequest: any,
  type: string
) {
  let txnNslAttribute = [];
  var tempTransEntityRecords = [];
  var tempGroupQueryIndex = [];
  var tempGroupQueryData = [];

  let caseTxnNslAttributeForIO: any = [];
  let nslCaseForIO = [];

  let caseTxnNslAttributeForCondition: any = [];
  let nslCaseForCondition = [];

  let formulaTxnNslAttributeForIO: any = [];
  let nslFormulaForIO = [];

  let formulaTxnNslAttributeForCodition: any = [];
  let nslFormulaForCondition = [];

  ccdRequest = setEntityId(reservedCu, ccdRequest, type);
  var inputoutput = [];
  // For Input Output Block
  /* istanbul ignore next */
  if (EQLVersion !== '2.0') {
    inputoutput = transformQueryRecords(
      transEntityRecords.queryInputOutputArray,
      queryInputOutputFields,
      0,
      reservedCu,
      type
    );
  } else {
    queryInputOutputFields = [
      { name: 'nslFormula', display: 'NSL_Formula' },
      { name: 'nslCase', display: 'NSL_Case' },
      { name: 'sourceAttribute', display: 'Source_Attribute' },
      { name: 'targetAttribute', display: 'Target_Attribute' },
      { name: 'function', display: 'Function' },
    ];

    inputoutput = transformQueryRecords(
      transEntityRecords.queryInputOutputArray,
      queryInputOutputFields,
      0,
      reservedCu,
      type
    );
  }

  // For NSL Case in Query Input Output
  let caseForInputOutput = createNSLCase(transEntityRecords, queryFields, reservedCu, type, 'inputoutput', caseFields);
  transEntityRecords = caseForInputOutput.transEntityRecords;
  caseTxnNslAttributeForIO = caseForInputOutput.caseTxnNslAttribute;
  nslCaseForIO = caseForInputOutput.nslCaseRecords;

  let caseForCondition = createNSLCase(transEntityRecords, queryFields, reservedCu, type, 'condition', caseFields);
  transEntityRecords = caseForCondition.transEntityRecords;
  caseTxnNslAttributeForCondition = caseForCondition.caseTxnNslAttribute;
  nslCaseForCondition = caseForCondition.nslCaseRecords;

  let formulaForInputOutput = createNSLFormula(transEntityRecords, arithmeticFields, reservedCu, type, 'inputoutput');
  transEntityRecords = formulaForInputOutput.transEntityRecords;
  formulaTxnNslAttributeForIO = formulaForInputOutput.formulaTxnNslAttribute;
  nslFormulaForIO = formulaForInputOutput.nslFormula;

  let formulaForCondition = createNSLFormula(transEntityRecords, arithmeticFields, reservedCu, type, 'condition');
  transEntityRecords = formulaForCondition.transEntityRecords;
  formulaTxnNslAttributeForCodition = formulaForCondition.formulaTxnNslAttribute;
  nslFormulaForCondition = formulaForCondition.nslFormula;

  // For QueryData of transEntityRecords
  /* istanbul ignore next */
  for (var i = 0; i < transEntityRecords?.queryData?.isGroupQuery?.length; i++) {
    // For Rule
    if (!transEntityRecords?.queryData?.isGroupQuery?.[i]) {
      tempTransEntityRecords.push(transEntityRecords.queryData?.[i]);
    }
    // For RuleSet Block
    else {
      var tempGroupTransEntityRecord = {
        attributeName: '',
        attributeValue: '',
        nslQuery: '',
        predicate:
          transEntityRecords.queryData.isGroupQuery.length - 1 !== i
            ? transEntityRecords.queryData.toggle[i] === true
              ? 'OR'
              : 'AND'
            : '',
        queryCondition: '',
        queryOperator: '',
      };
      tempTransEntityRecords.push(tempGroupTransEntityRecord);
      tempGroupQueryIndex.push(i);
      tempGroupQueryData.push(transEntityRecords?.queryData?.[i]);
    }
  }

  var query = [];
  /* istanbul ignore next */
  if (EQLVersion == '2.0') {
    queryFields = [
      { name: 'functionName', display: 'Function_Name' },
      { name: 'nslFormula', display: 'NSL_Formula' },
      { name: 'attributeName', display: 'Attribute_Name' },
      { name: 'queryOperator', display: 'Query_Operator' },
      { name: 'attributeValue', display: 'Attribute_Value' },
      { name: 'nslQuery', display: 'NSL_Query' },
      { name: 'queryCondition', display: 'NSL_Query_Condition' },
      { name: 'nslCase', display: 'NSL_Case' },
      { name: 'predicate', display: 'Predicate' },
    ];
  } /* istanbul ignore next */ else {
    queryFields = [
      { name: 'attributeName', display: 'Attribute_Name' },
      { name: 'queryOperator', display: 'Query_Operator' },
      { name: 'attributeValue', display: 'Attribute_Value' },
      { name: 'nslQuery', display: 'NSL_Query' },
      { name: 'queryCondition', display: 'NSL_Query_Condition' },
      { name: 'predicate', display: 'Predicate' },
    ];
  }
  //remove predicate from last index of query data
  /* istanbul ignore next */
  if (tempTransEntityRecords?.length > 0) {
    tempTransEntityRecords[tempTransEntityRecords?.length - 1].predicate = null;
    query = transformQueryRecords(tempTransEntityRecords, queryFields, 1, reservedCu, type);
  }

  // For Filter
  let filterData: any;
  filterData = [];
  /* istanbul ignore next */
  if (transEntityRecords.filterData) {
    transEntityRecords.filterData?.forEach((filterDataArray: any) => {
      filterDataArray?.forEach((filter: any) => {
        /* istanbul ignore next */
        if (filter.type === 'LIMIT' || filter.type === 'GROUP') {
          filter.value = '';
        }
        /* istanbul ignore next */
        if (filter.filterAttributeValue !== '') {
          filterData.push(filter);
        }
      });
    });
  }

  /* istanbul ignore next */
  /* istanbul ignore else */
  if (EQLVersion == '2.0') {
    filterFields = [
      { name: 'type', display: 'Type' },
      { name: 'value', display: 'Value' },
      { name: 'filterAttributeValue', display: 'Filter_Attribute_Value' },
      { name: 'nslCase', display: 'NSL_Case' },
    ];
  }

  var filter = transformQueryRecords(filterData, filterFields, 2, reservedCu, type);

  txnNslAttribute = [];
  txnNslAttribute.push(extractSubEntityId(reservedCu, 'NSL_Query_Input_Output', inputoutput, type));

  //push formula input output in the final json
  /* istanbul ignore next */
  for (i = 0; i < nslFormulaForIO.length; i++) {
    if (nslFormulaForIO?.[i]?.length > 0) {
      txnNslAttribute[0].txnGeneralEntity.transEntityRecords[i].txnNslAttribute[0] = formulaTxnNslAttributeForIO[i];
    }
  }

  //push case input output in the final json
  /* istanbul ignore next */
  for (i = 0; i < caseTxnNslAttributeForIO?.length; i++) {
    if (caseTxnNslAttributeForIO?.[i]?.txnGeneralEntity?.transEntityRecords?.length > 0) {
      txnNslAttribute[0].txnGeneralEntity.transEntityRecords[i].txnNslAttribute[1] = caseTxnNslAttributeForIO[i];
    }
  }

  txnNslAttribute.push(extractSubEntityId(reservedCu, 'NSL_Query_Condition', query, type));

  //push formula Condition in the final json
  /* istanbul ignore next */
  for (i = 0; i < nslFormulaForCondition.length; i++) {
    if (nslFormulaForCondition?.[i]?.length > 0) {
      txnNslAttribute[1].txnGeneralEntity.transEntityRecords[i].txnNslAttribute[1] =
        formulaTxnNslAttributeForCodition[i];
      txnNslAttribute[1].txnGeneralEntity.transEntityRecords[i].txnNslAttribute[2].values = [];
    }
  }

  //push case input output in the final json
  /* istanbul ignore next */
  for (i = 0; i < caseTxnNslAttributeForCondition?.length; i++) {
    if (caseTxnNslAttributeForCondition?.[i]?.txnGeneralEntity?.transEntityRecords?.length > 0) {
      txnNslAttribute[1].txnGeneralEntity.transEntityRecords[i].txnNslAttribute[7] = caseTxnNslAttributeForCondition[i];
    }
  }

  let queryConditionIndex = EQLVersion == '2.0' ? 6 : 4;
  // Rules of RuleSet Block
  /* istanbul ignore next */
  for (let i = 0; i < query.length; i++) {
    for (let j = 0; j < tempGroupQueryIndex.length; j++) {
      /* istanbul ignore next */
      if (i === tempGroupQueryIndex[j]) {
        txnNslAttribute[1].txnGeneralEntity.transEntityRecords[i].txnNslAttribute[
          queryConditionIndex
        ].txnGeneralEntity = '';
        txnNslAttribute[1].txnGeneralEntity.transEntityRecords[i].txnNslAttribute[
          queryConditionIndex
        ].txnGeneralEntity = transformGroupQueryData(
          tempGroupQueryData?.[j],
          reservedCu,
          EQLVersion,
          queryFields,
          type
        );
      }
    }
  }

  txnNslAttribute.push(extractSubEntityId(reservedCu, 'NSL_Filter', filter, type));

  // For SubQuery
  /* istanbul ignore next */
  if (transEntityRecords.queryData) {
    for (let i = 0; i < transEntityRecords.queryData.length; i++) {
      var queryData = transEntityRecords?.queryData?.[i];
      /* istanbul ignore next */
      if (queryData?.nslQuery && queryData?.nslQuery?.queryData) {
        var nestedEntity: any;
        nestedEntity = convertFromToTransEntity(
          queryData?.nslQuery,
          reservedCu,
          EQLVersion,
          queryInputOutputFields,
          queryFields,
          arithmeticFields,
          filterFields,
          caseFields,
          ccdRequest,
          type
        );

        var nslNestedQuery = null;
        nslNestedQuery = {
          name: ccdRequest.PL_SL001.propertyData.CONSTANT.changeDriverData.DATA.name,
          dsdId: ccdRequest.PL_SL001.propertyData.CONSTANT.changeDriverData.DATA.dsdId,
          transEntityRecords: [{ txnNslAttribute: nestedEntity }],
        };

        txnNslAttribute[1].txnGeneralEntity.transEntityRecords[i].txnNslAttribute[3].txnGeneralEntity = nslNestedQuery;
      }
    }
  }

  return txnNslAttribute;
}

function createNSLCase(
  transEntityRecords: any,
  queryFields: any,
  reservedCu: any,
  type: any,
  stepper: any,
  caseFields: any
) {
  let caseQueryCondition = [];
  let caseThen = [];
  let caseElse = [];
  let caseTxnNslAttribute: any = [];
  let nslCaseRecords: any = [];
  /* istanbul ignore next */
  if (stepper === 'inputoutput') {
    for (let i = 0; i < transEntityRecords?.nslCase?.length; i++) {
      caseTxnNslAttribute.push([]);
      caseQueryCondition.push([]);
      caseThen.push([]);
      caseElse.push([]);
      for (let j = 0; j < transEntityRecords?.nslCase?.[i]?.length; j++) {
        let tempCase = [];
        tempCase.push(transEntityRecords.nslCase[i][j].nslQueryCondition);
        caseQueryCondition[caseQueryCondition.length - 1].push([]);
        caseQueryCondition[i][j] = transformNSLCaseRecords(tempCase, queryFields, 0, reservedCu);

        let caseThenFields = [
          { name: 'functionName', display: 'Function_Name' },
          { name: 'attributeName', display: 'Attribute_Name' },
          { name: 'nslQuery', display: 'NSL_Query' },
          { name: 'nslFormula', display: 'NSL_Formula' },
          { name: 'nslCase', display: 'NSL_Case' },
        ];
        tempCase = [];
        tempCase.push(transEntityRecords.nslCase[i][j].nslThen);
        caseThen[caseThen.length - 1].push([]);
        caseThen[i][j] = transformNSLCaseRecords(tempCase, caseThenFields, 0, reservedCu);

        tempCase = [];
        tempCase.push(transEntityRecords.nslCase[i][j].nslElse);
        caseElse[caseElse.length - 1].push([]);
        caseElse[i][j] = transformNSLCaseRecords(tempCase, caseThenFields, 0, reservedCu);

        caseTxnNslAttribute[i].push([]);
        caseTxnNslAttribute[i][j] = { txnNslAttribute: [] };
        caseTxnNslAttribute[i][j].txnNslAttribute.push(
          extractSubEntityId(reservedCu, 'NSL_Query_Condition', caseQueryCondition[i][j], type)
        );
        caseTxnNslAttribute[i][j].txnNslAttribute.push(
          extractSubEntityId(reservedCu, 'NSL_Then', caseThen[i][j], type)
        );
        caseTxnNslAttribute[i][j].txnNslAttribute.push(
          extractSubEntityId(reservedCu, 'NSL_Else', caseElse[i][j], type)
        );

        caseTxnNslAttribute[i][j].txnNslAttribute[2].values = [];
      }
    }

    var tempCaseTxnNslAttribute: any = [];
    /* istanbul ignore next */
    for (let i = 0; i < transEntityRecords?.nslCase?.length; i++) {
      nslCaseRecords.push([]);
      tempCaseTxnNslAttribute.push([]);
      for (let j = 0; j < caseTxnNslAttribute[i].length; j++) {
        nslCaseRecords[i].push(transformNSLCaseRecords(caseTxnNslAttribute[i], caseFields, 0, reservedCu));
        tempCaseTxnNslAttribute[i] = extractSubEntityId(reservedCu, 'NSL_Case', nslCaseRecords[i][j], type);
        tempCaseTxnNslAttribute[i].txnGeneralEntity.transEntityRecords = caseTxnNslAttribute[i];
      }
    }
  } else {
    for (let i = 0; i < transEntityRecords?.nslCaseForCondition?.length; i++) {
      caseTxnNslAttribute.push([]);
      caseQueryCondition.push([]);
      caseThen.push([]);
      caseElse.push([]);
      for (let j = 0; j < transEntityRecords?.nslCaseForCondition?.[i]?.length; j++) {
        let tempCase = [];
        tempCase.push(transEntityRecords.nslCaseForCondition[i][j].nslQueryCondition);
        caseQueryCondition[caseQueryCondition.length - 1].push([]);
        caseQueryCondition[i][j] = transformNSLCaseRecords(tempCase, queryFields, 0, reservedCu);

        let caseThenFields = [
          { name: 'functionName', display: 'Function_Name' },
          { name: 'attributeName', display: 'Attribute_Name' },
          { name: 'nslQuery', display: 'NSL_Query' },
          { name: 'nslFormula', display: 'NSL_Formula' },
          { name: 'nslCase', display: 'NSL_Case' },
        ];
        tempCase = [];
        tempCase.push(transEntityRecords.nslCaseForCondition[i][j].nslThen);
        caseThen[caseThen.length - 1].push([]);
        caseThen[i][j] = transformNSLCaseRecords(tempCase, caseThenFields, 0, reservedCu);

        tempCase = [];
        tempCase.push(transEntityRecords.nslCaseForCondition[i][j].nslElse);
        caseElse[caseElse.length - 1].push([]);
        caseElse[i][j] = transformNSLCaseRecords(tempCase, caseThenFields, 0, reservedCu);

        caseTxnNslAttribute[i].push([]);
        caseTxnNslAttribute[i][j] = { txnNslAttribute: [] };
        caseTxnNslAttribute[i][j].txnNslAttribute.push(
          extractSubEntityId(reservedCu, 'NSL_Query_Condition', caseQueryCondition[i][j], type)
        );
        caseTxnNslAttribute[i][j].txnNslAttribute.push(
          extractSubEntityId(reservedCu, 'NSL_Then', caseThen[i][j], type)
        );
        caseTxnNslAttribute[i][j].txnNslAttribute.push(
          extractSubEntityId(reservedCu, 'NSL_Else', caseElse[i][j], type)
        );

        caseTxnNslAttribute[i][j].txnNslAttribute[2].values = [];
      }
    }

    var tempCaseTxnNslAttribute: any = [];
    /* istanbul ignore next */
    for (let i = 0; i < transEntityRecords?.nslCaseForCondition?.length; i++) {
      nslCaseRecords.push([]);
      tempCaseTxnNslAttribute.push([]);
      for (let j = 0; j < caseTxnNslAttribute[i].length; j++) {
        nslCaseRecords[i].push(transformNSLCaseRecords(caseTxnNslAttribute[i], caseFields, 0, reservedCu));
        tempCaseTxnNslAttribute[i] = extractSubEntityId(reservedCu, 'NSL_Case', nslCaseRecords[i][j], type);
        tempCaseTxnNslAttribute[i].txnGeneralEntity.transEntityRecords = caseTxnNslAttribute[i];
      }
    }
  }

  let tempReturnValue = {
    transEntityRecords: transEntityRecords,
    caseTxnNslAttribute: tempCaseTxnNslAttribute,
    nslCaseRecords: nslCaseRecords,
  };
  return tempReturnValue;
}
function createNSLFormula(transEntityRecords: any, arithmeticFields: any, reservedCu: any, type: any, stepper: any) {
  var tempFormulaRecords = [];
  var tempGroupFormulaIndex = [];
  var tempGroupFormulaData = [];
  let formulaTxnNslAttribute: any = [];
  let tempGroupNslFormula = [];
  // For NSL Formula in Query Input Output
  var nslFormula = [];
  /* istanbul ignore next */
  if (stepper === 'inputoutput') {
    for (var i = 0; i < transEntityRecords?.nslFormula?.length; i++) {
      if (transEntityRecords?.nslFormula[i] !== null || transEntityRecords?.nslFormula?.[i]?.length !== 0) {
        nslFormula.push([]);
        nslFormula[i] = transformNSLFormulaRecords(
          transEntityRecords?.nslFormula?.[i],
          arithmeticFields,
          0,
          reservedCu,
          type
        );
      } else {
        transEntityRecords.nslFormula.push();
      }
    }

    // For Formula Data of transEntityRecords
    /* istanbul ignore next */
    for (let i = 0; i < transEntityRecords?.nslFormula?.length; i++) {
      tempFormulaRecords.push([]);
      tempGroupFormulaIndex.push([]);
      tempGroupFormulaData.push([]);
      for (let j = 0; j < transEntityRecords?.nslFormula?.[i]?.length; j++) {
        // For Formula
        if (!transEntityRecords?.nslFormula[i]?.[j].length) {
          tempFormulaRecords[i].push(transEntityRecords.nslFormula?.[i]?.[j]);
        }
        // For FormulaSet Block
        else {
          var tempGroupFormula = {
            functionName: '',
            attributeName: '',
            nslQuery: '',
            nslFormula: '',
            nslCase: '',
            arithmeticOperator: '',
          };

          tempFormulaRecords[i].push(tempGroupFormula);
          tempGroupFormulaIndex[i].push(j);
          tempGroupFormulaData[i] = transEntityRecords?.nslFormula?.[i]?.[j];
        }

        tempGroupNslFormula[i] = transformNSLFormulaRecords(
          tempFormulaRecords[i],
          arithmeticFields,
          0,
          reservedCu,
          type
        );
        formulaTxnNslAttribute.push([]);
        formulaTxnNslAttribute[i] = extractSubEntityId(reservedCu, 'NSL_Formula', tempGroupNslFormula[i], type);
      }
    }

    /* istanbul ignore next */
    for (let i = 0; i < tempGroupNslFormula?.length; i++) {
      for (let j = 0; j < tempGroupNslFormula?.[i]?.length; j++) {
        for (let k = 0; k < tempGroupFormulaIndex?.[i]?.length; k++) {
          /* istanbul ignore next */
          if (j === tempGroupFormulaIndex[i][k]) {
            formulaTxnNslAttribute[i].txnGeneralEntity.transEntityRecords[j].txnNslAttribute[3].txnGeneralEntity = '';
            formulaTxnNslAttribute[i].txnGeneralEntity.transEntityRecords[
              j
            ].txnNslAttribute[3].txnGeneralEntity = transformGroupFormulaData(
              tempGroupFormulaData?.[i],
              reservedCu,
              arithmeticFields,
              type
            );
          }
        }
      }
    }
  } else {
    for (var i = 0; i < transEntityRecords?.nslFormulaForCondition?.length; i++) {
      if (
        transEntityRecords?.nslFormulaForCondition[i] !== null ||
        transEntityRecords?.nslFormulaForCondition?.[i]?.length !== 0
      ) {
        nslFormula.push([]);
        nslFormula[i] = transformNSLFormulaRecords(
          transEntityRecords?.nslFormulaForCondition?.[i],
          arithmeticFields,
          0,
          reservedCu,
          type
        );
      } else {
        transEntityRecords.nslFormulaForCondition.push();
      }
    }

    // For Formula Data of transEntityRecords
    /* istanbul ignore next */
    for (let i = 0; i < transEntityRecords?.nslFormulaForCondition?.length; i++) {
      tempFormulaRecords.push([]);
      tempGroupFormulaIndex.push([]);
      tempGroupFormulaData.push([]);
      for (let j = 0; j < transEntityRecords?.nslFormulaForCondition?.[i]?.length; j++) {
        // For Formula
        if (!transEntityRecords?.nslFormulaForCondition[i]?.[j].length) {
          tempFormulaRecords[i].push(transEntityRecords.nslFormulaForCondition?.[i]?.[j]);
        }
        // For FormulaSet Block
        else {
          var tempGroupFormula = {
            functionName: '',
            attributeName: '',
            nslQuery: '',
            nslFormula: '',
            nslCase: '',
            arithmeticOperator: '',
          };

          tempFormulaRecords[i].push(tempGroupFormula);
          tempGroupFormulaIndex[i].push(j);
          tempGroupFormulaData[i] = transEntityRecords?.nslFormulaForCondition?.[i]?.[j];
        }

        tempGroupNslFormula[i] = transformNSLFormulaRecords(
          tempFormulaRecords[i],
          arithmeticFields,
          0,
          reservedCu,
          type
        );
        formulaTxnNslAttribute.push([]);
        formulaTxnNslAttribute[i] = extractSubEntityId(reservedCu, 'NSL_Formula', tempGroupNslFormula[i], type);
      }
    }

    /* istanbul ignore next */
    for (let i = 0; i < tempGroupNslFormula?.length; i++) {
      for (let j = 0; j < tempGroupNslFormula?.[i]?.length; j++) {
        for (let k = 0; k < tempGroupFormulaIndex?.[i]?.length; k++) {
          /* istanbul ignore next */
          if (j === tempGroupFormulaIndex[i][k]) {
            formulaTxnNslAttribute[i].txnGeneralEntity.transEntityRecords[j].txnNslAttribute[3].txnGeneralEntity = '';
            formulaTxnNslAttribute[i].txnGeneralEntity.transEntityRecords[
              j
            ].txnNslAttribute[3].txnGeneralEntity = transformGroupFormulaData(
              tempGroupFormulaData?.[i],
              reservedCu,
              arithmeticFields,
              type
            );
          }
        }
      }
    }
  }
  let tempReturnValue = {
    transEntityRecords: transEntityRecords,
    formulaTxnNslAttribute: formulaTxnNslAttribute,
    nslFormula: nslFormula,
  };
  return tempReturnValue;
}

/* istanbul ignore next */
export function deleteInputOutputRow(transEntityRecords: any, rowIndex: number, mapsource: boolean) {
  /* istanbul ignore next */
  // let attrToDelete = transEntityRecords?.queryInputOutputArray?.[rowIndex]?.sourceAttribute;
  /* istanbul ignore next */
  // let actualAttrIndex = transEntityRecords?.attributesList?.findIndex((x: any) => x === attrToDelete);
  /* istanbul ignore next */
  // let tempAttr = transEntityRecords.queryInputOutputArray[rowIndex].sourceAttribute;
  // let noEntityFound;
  transEntityRecords.queryInputOutputArray.splice(rowIndex, 1);

  // if (tempAttr) {
  //   let tempSplittedAttr = tempAttr.split('.', 1);

  //   noEntityFound = transEntityRecords.queryInputOutputArray.find((element: any) => {
  //     if (element?.sourceAttribute?.includes(tempSplittedAttr)) {
  //       return true;
  //     }
  //   });

  //   if (!noEntityFound) {
  //     let entityIndex = transEntityRecords.entityList.findIndex((x: any) => x === tempSplittedAttr[0]);
  //     transEntityRecords.entityList.splice(entityIndex, 1);
  //   }
  // }
  // if (transEntityRecords?.queryInputOutputArray?.length == 0) {
  //   transEntityRecords.attributesList = [];
  //   transEntityRecords.attributesListForGroupFilter = [];
  //   transEntityRecords.attributesListForQueryLayer = [];
  //   transEntityRecords.entityList = [];
  // }
  /* istanbul ignore next */
  // transEntityRecords.attributesList.splice(actualAttrIndex > -1 ? actualAttrIndex : rowIndex, 1);
  // transEntityRecords.attributesList = [];
  transEntityRecords = getEntityList(transEntityRecords, false, mapsource);
  /* istanbul ignore next */
  transEntityRecords?.nslFormula?.splice(rowIndex, 1);
  /* istanbul ignore next */
  transEntityRecords?.nslCase?.splice(rowIndex, 1);
  /* istanbul ignore next */
  transEntityRecords?.isSelectedOperation?.splice(rowIndex, 1);
  /* istanbul ignore next */
  transEntityRecords?.selectedOperation?.splice(rowIndex, 1);
}

/* istanbul ignore next */
export function getEntityList(transEntityRecords: any, createEntityList: any, mapsource: boolean) {
  var splitted = [];
  let attributesList: any = [];
  /* istanbul ignore next */
  if (mapsource == true) {
    for (let i = 0; i < transEntityRecords.queryInputOutputArray.length; i++) {
      if (transEntityRecords?.queryInputOutputArray?.[i]?.sourceAttribute) {
        attributesList.push(transEntityRecords?.queryInputOutputArray?.[i]?.sourceAttribute);
      }
    }

    for (let i = 0; i < transEntityRecords?.nslCase?.length; i++) {
      for (let j = 0; j < transEntityRecords?.nslCase?.[i].length; j++) {
        /* istanbul ignore next */
        if (
          transEntityRecords?.nslCase?.[i]?.[j]?.nslQueryCondition?.attributeName?.charAt(0) !== "'" &&
          transEntityRecords?.nslCase?.[i]?.[j]?.nslQueryCondition?.attributeName?.charAt(
            transEntityRecords?.nslCase?.[i]?.[j]?.nslQueryCondition?.attributeName?.length - 1
          ) !== "'" &&
          !transEntityRecords?.nslCase?.[i]?.[j]?.nslQueryCondition?.attributeName?.includes('PL.SL00')
        ) {
          attributesList.push(transEntityRecords.nslCase[i][j].nslQueryCondition.attributeName);
        }

        /* istanbul ignore next */
        if (
          transEntityRecords?.nslCase?.[i]?.[j]?.nslQueryCondition?.attributeValue?.charAt(0) !== "'" &&
          transEntityRecords?.nslCase?.[i]?.[j]?.nslQueryCondition?.attributeValue?.charAt(
            transEntityRecords?.nslCase?.[i]?.[j]?.nslQueryCondition?.attributeValue?.length - 1
          ) !== "'" &&
          !transEntityRecords?.nslCase?.[i]?.[j]?.nslQueryCondition?.attributeValue?.includes('PL.SL00')
        ) {
          attributesList.push(transEntityRecords.nslCase[i][j].nslQueryCondition.attributeValue);
        }

        /* istanbul ignore next */
        if (
          transEntityRecords?.nslCase?.[i]?.[j]?.nslThen?.attributeName?.charAt(0) !== "'" &&
          transEntityRecords?.nslCase?.[i]?.[j]?.nslThen?.attributeName?.charAt(
            transEntityRecords?.nslCase?.[i]?.[j]?.nslThen?.attributeName?.length - 1
          ) !== "'" &&
          !transEntityRecords?.nslCase?.[i]?.[j]?.nslThen?.attributeName?.includes('PL.SL00')
        ) {
          attributesList.push(transEntityRecords.nslCase[i][j].nslThen.attributeName);
        }

        /* istanbul ignore next */
        if (
          transEntityRecords?.nslCase?.[i]?.[j]?.nslElse?.attributeName?.charAt(0) !== "'" &&
          transEntityRecords?.nslCase?.[i]?.[j]?.nslElse?.attributeName?.charAt(
            transEntityRecords?.nslCase?.[i]?.[j]?.nslElse?.attributeName?.length - 1
          ) !== "'" &&
          !transEntityRecords?.nslCase?.[i]?.[j]?.nslElse?.attributeName?.includes('PL.SL00')
        ) {
          attributesList.push(transEntityRecords.nslCase[i][j].nslElse.attributeName);
        }
      }
    }

    for (let i = 0; i < transEntityRecords?.nslFormula?.length; i++) {
      for (let j = 0; j < transEntityRecords?.nslFormula?.[i]?.length; j++) {
        /* istanbul ignore next */
        if (
          transEntityRecords?.nslFormula?.[i]?.[j]?.attributeName?.charAt(0) !== "'" &&
          transEntityRecords?.nslFormula?.[i]?.[j]?.attributeName?.charAt(
            transEntityRecords?.nslFormula?.[i]?.[j]?.attributeName?.length - 1
          ) !== "'" &&
          !transEntityRecords?.nslFormula?.[i]?.[j]?.attributeName?.includes('PL.SL00')
        ) {
          attributesList.push(transEntityRecords.nslFormula[i][j].attributeName);
        }
      }
    }

    attributesList.push(...transEntityRecords.attributesList);
    var uniqueAttributesList = new Set(attributesList);
    attributesList = [...uniqueAttributesList];

    for (let k = 0; k < attributesList?.length; k++) {
      var attrSplitted = attributesList?.[k]?.split('.', 1);
      splitted.push(attrSplitted?.[0]);
    }
  }

  if (createEntityList) {
    if (mapsource == true) {
      for (let index = 0; index < attributesList?.length; index++) {
        /* istanbul ignore next */
        if (attributesList[index]) {
          transEntityRecords.attributesList.push(attributesList[index]);
          /* istanbul ignore next */
          var tempSplitted = attributesList?.[index]?.split('.', 1);
          splitted.push(tempSplitted?.[0]);
        }
      }
    } else {
      for (let index = 0; index < transEntityRecords?.queryInputOutputArray?.length; index++) {
        /* istanbul ignore next */
        if (transEntityRecords?.queryInputOutputArray?.[index]?.sourceAttribute) {
          transEntityRecords.attributesList.push(transEntityRecords?.queryInputOutputArray?.[index]?.sourceAttribute);
          /* istanbul ignore next */
          var tempSplitted = transEntityRecords?.queryInputOutputArray?.[index]?.sourceAttribute?.split('.', 1);
          splitted.push(tempSplitted?.[0]);
        }
      }
    }

    /* istanbul ignore next */
    transEntityRecords.entityList = splitted?.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    });
  }
  transEntityRecords.attributesListForQueryLayer = [];
  /* istanbul ignore next */
  if (transEntityRecords?.attributesList && transEntityRecords?.attributesList?.length > 0) {
    transEntityRecords.attributesListForQueryLayer.push(...transEntityRecords?.attributesList);
  }

  /* istanbul ignore next */
  transEntityRecords.attributesList = transEntityRecords.attributesList?.filter(function (
    elem: any,
    index: any,
    self: string | any[]
  ) {
    return index === self.indexOf(elem);
  });

  /* istanbul ignore next */
  transEntityRecords.attributesListForQueryLayer = transEntityRecords.attributesListForQueryLayer?.filter(function (
    elem: any,
    index: any,
    self: string | any[]
  ) {
    return index === self.indexOf(elem);
  });

  /* istanbul ignore next */
  transEntityRecords.attributesListForGroupFilter = transEntityRecords.attributesListForGroupFilter?.filter(function (
    elem: any,
    index: any,
    self: string | any[]
  ) {
    return index === self.indexOf(elem);
  });

  return transEntityRecords;
}

/* istanbul ignore next */
export function returnFormulaRecords(entityRecord: any, tempGroupFormulaIndex: any) {
  let transEntityRecords = entityRecord;
  for (let i = 0; i < transEntityRecords?.length; i++) {
    for (let value of tempGroupFormulaIndex) {
      /* istanbul ignore next */
      if (i === value) {
        transEntityRecords[i].txnNslAttribute[3].txnGeneralEntity =
          transEntityRecords?.[i]?.txnNslAttribute?.[3]?.values?.[0];

        transEntityRecords[i].txnNslAttribute[3].values = [];
      }
    }
  }
  return transEntityRecords;
}

export function addArithmeticOperation(nslFormula: any, transEntityRecords: any, mapSource: any) {
  /* formula for input output stepper */
  /* istanbul ignore next */
  if (mapSource == true) {
    if (transEntityRecords?.nslFormula?.length) {
      /* istanbul ignore next */
      for (let i = 0; i < transEntityRecords.nslFormula.length; i++) {
        /* istanbul ignore next */
        if (transEntityRecords.nslFormula.length > 0) {
          transEntityRecords.nslFormula[nslFormula.index] = nslFormula.value;
        } else if (transEntityRecords.nslFormula.length == 0) {
          transEntityRecords.nslFormula = [];
          transEntityRecords.nslFormula.push(nslFormula.value);
        }
      }
    } else {
      transEntityRecords.nslFormula = [];
      transEntityRecords.nslFormula.push(nslFormula.value);
    }
  } else {
    /* formula for condition stepper */
    /* istanbul ignore next */
    if (transEntityRecords?.nslFormulaForCondition?.length) {
      /* istanbul ignore next */
      for (let i = 0; i < transEntityRecords.nslFormulaForCondition.length; i++) {
        /* istanbul ignore next */
        if (transEntityRecords.nslFormulaForCondition.length > 0) {
          transEntityRecords.nslFormulaForCondition[nslFormula.index] = nslFormula.value;
        } else if (transEntityRecords.nslFormulaForCondition.length == 0) {
          transEntityRecords.nslFormulaForCondition = [];
          transEntityRecords.nslFormulaForCondition.push(nslFormula.value);
        }
      }
    } else {
      transEntityRecords.nslFormulaForCondition = [];
      transEntityRecords.nslFormulaForCondition.push(nslFormula.value);
    }
  }
  return transEntityRecords;
}

/* istanbul ignore next */
export function addNslCase(nslCase: any, transEntityRecords: any, mapSource: any) {
  /* istanbul ignore next */
  if (mapSource == true) {
    if (transEntityRecords?.nslCase?.length > 0) {
      transEntityRecords.nslCase[nslCase.index] = nslCase.value;
    } else {
      transEntityRecords.nslCase = [];
      transEntityRecords.nslCase.push(nslCase.value);
    }
  } else {
    if (transEntityRecords?.nslCaseForCondition?.length > 0) {
      transEntityRecords.nslCaseForCondition[nslCase.index] = nslCase.value;
    } else {
      transEntityRecords.nslCaseForCondition = [];
      transEntityRecords.nslCaseForCondition.push(nslCase.value);
    }
  }
  return transEntityRecords;
}

/* istanbul ignore next */
export function selectSortFilter(
  filterType: any,
  filterValue: any,
  filterIndex: number,
  innerFilterIndex: number,
  transEntityRecords: any,
  alertService: any,
  labels: any
) {
  /* istanbul ignore next */
  transEntityRecords?.filterData?.forEach((filterData: any, i: any) => {
    /* istanbul ignore next */
    for (let j = 0; j < filterData.length; j++) {
      if (filterType === filterData[j].type && i !== filterIndex) {
        /* istanbul ignore else */
        if (filterData[j].filterAttributeValue === filterValue) {
          alertService.showToaster(labels?.entity_already_filtered_please_select_different_entity, '', 'error');
          setTimeout(() => {
            transEntityRecords.filterData[filterIndex][innerFilterIndex].filterAttributeValue = '';
          }, 10);
        }
      }
    }
  });

  return transEntityRecords;
}

export function selectGroupFilter(
  filterType: any,
  filterValue: any,
  filterIndex: number,
  innerFilterIndex: number,
  transEntityRecords: any,
  alertService: any,
  labels: any
) {
  /* istanbul ignore next */
  transEntityRecords?.filterData?.forEach((filterData: any, i: any) => {
    /* istanbul ignore next */
    for (let j = 0; j < filterData?.length; j++) {
      /* istanbul ignore next */
      if (filterType === filterData[j]?.type && i !== filterIndex) {
        /* istanbul ignore next */
        if (filterData[j]?.filterAttributeValue === filterValue) {
          alertService.showToaster(labels.entity_already_filtered_please_select_different_entity, '', 'error');
          /* istanbul ignore next */
          setTimeout(() => {
            transEntityRecords.filterData[filterIndex][innerFilterIndex].filterAttributeValue = '';
          }, 10);
        }
      }
    }
  });

  return transEntityRecords;
}

/* istanbul ignore next */
export function closeDialogEql(event: any, transEntityRecords: any) {
  /* istanbul ignore next */
  if (
    event?.type === 'case' &&
    (transEntityRecords?.nslCase?.[event?.index]?.[0]?.nslQueryCondition?.attributeName === '' ||
      transEntityRecords?.nslCase?.[event?.index]?.[0]?.nslQueryCondition?.attributeName === undefined)
  ) {
    transEntityRecords.nslCase[event.index] = [];
  }
  /* istanbul ignore next */
  if (
    event?.type === 'formula' &&
    (transEntityRecords?.nslFormula?.[event?.index]?.[0]?.attributeName === '' ||
      transEntityRecords?.nslFormula?.[event?.index]?.[0]?.attributeName === undefined)
  ) {
    transEntityRecords.nslFormula[event.index] = [];
  }

  return transEntityRecords;
}

export function removeAttrForGroupFilter(transEntityRecords: any, index: any) {
  /* istanbul ignore next */
  if (transEntityRecords?.attributesListForGroupFilter?.[index]) {
    transEntityRecords.attributesListForGroupFilter.splice(index, 1);
  } /* istanbul ignore next */ else {
    transEntityRecords.attributesListForGroupFilter = [];
    transEntityRecords.attributesListForGroupFilter.splice(index, 1);
  }

  return transEntityRecords;
}

/* istanbul ignore next */
export function addOperationLine(transEntityRecords: any, queryInputOutputEntityParams: any) {
  /* istanbul ignore next */
  transEntityRecords.queryInputOutputArray.push(JSON.parse(JSON.stringify(queryInputOutputEntityParams)));
  /* istanbul ignore next */
  if (!transEntityRecords.isSelectedOperation) {
    transEntityRecords.isSelectedOperation = [];
  }
  /* istanbul ignore next */
  if (!transEntityRecords.selectedOperation) {
    transEntityRecords.selectedOperation = [];
  }
  /* istanbul ignore next */
  for (let i = 0; i < transEntityRecords.queryInputOutputArray.length; i++) {
    /* istanbul ignore next */
    if (!transEntityRecords.isSelectedOperation[i]) {
      transEntityRecords.isSelectedOperation[i] = false;
    }
  }
  /* istanbul ignore next */
  transEntityRecords.isSelectedOperation[transEntityRecords.queryInputOutputArray.length - 1] = true;

  return transEntityRecords;
}

/* istanbul ignore next */
export function checkGroupByFilterToAddInQueryCanvas(transEntityRecords: any) {
  /* istanbul ignore next */
  let groupByCount = 0;
  let tempGroupByAttr: any = [];
  let indexofGroupBy = -1;
  /* istanbul ignore next */
  transEntityRecords?.filterData?.forEach((filter: any, i: any) => {
    /* istanbul ignore next */
    transEntityRecords?.filterData?.[i]?.forEach((filterData: any, j: any) => {
      /* istanbul ignore next */
      if (filterData?.type === 'GROUP' && filterData?.filterAttributeValue?.length > 0) {
        indexofGroupBy = tempGroupByAttr.indexOf(filterData.filterAttributeValue);
        /* istanbul ignore next */
        if (indexofGroupBy == -1) {
          tempGroupByAttr.push(filterData.filterAttributeValue);
          groupByCount++;
        }
      }
    });
  });

  let groupFilterCount = 0;
  /* istanbul ignore next */
  transEntityRecords?.attributesListForGroupFilter?.forEach((attr: any) => {
    /* istanbul ignore else */
    if (attr) {
      groupFilterCount++;
    }
  });

  let functionsCountinIO = 0;
  /* istanbul ignore next */
  transEntityRecords?.queryInputOutputArray?.forEach((queryIO: any, j: any) => {
    /* istanbul ignore next */
    /* istanbul ignore else */
    if (queryIO?.sourceAttribute?.length > 0 && queryIO?.function?.length > 0) {
      functionsCountinIO++;
    }
  });

  return false;
}

export function isReservedCU(changeUnit: any) {
  /* istanbul ignore next */
  if (changeUnit?.name?.includes('NSL_')) {
    return true;
  } else {
    return false;
  }
}

// To get proper display value for date types UI controls
export function displayDateDefaultValue(
  value: any,
  attributeUiElement: any,
  dateFormatType: string,
  timeFormatType: string,
  yearMonthFormatType: string
) {
  let dispalyValue;
  if (attributeUiElement === 'date' && !isNaN(Date.parse(value))) {
    dispalyValue = formatDate(value, dateFormatType, 'en-US');
  }
  if (attributeUiElement === 'datetime' && !isNaN(Date.parse(value))) {
    if (timeFormatType === '24-hr') {
      dispalyValue = formatDate(value, `${dateFormatType} HH:mm`, 'en-US');
    } else {
      dispalyValue = formatDate(value, `${dateFormatType} hh:mm a`, 'en-US');
    }
  }
  if (attributeUiElement === 'dateRange') {
    dispalyValue =
      formatDate(JSON.parse(value)[0], dateFormatType, 'en-US') +
      ' - ' +
      formatDate(JSON.parse(value)[1], dateFormatType, 'en-US');
  }
  if (attributeUiElement === 'time' && !isNaN(Date.parse(value))) {
    if (timeFormatType === '24-hr') {
      dispalyValue = formatDate(value, 'HH:mm', 'en-US');
    } else {
      dispalyValue = formatDate(value, 'hh:mm a', 'en-US');
    }
  }
  if (attributeUiElement === 'yearMonth' && !isNaN(Date.parse(value))) {
    dispalyValue = formatDate(value, yearMonthFormatType, 'en-US');
  }
  if (attributeUiElement === 'year') {
    dispalyValue = value;
  }
  if (attributeUiElement === 'duration') {
    let durationDefaultValue = durationTime(value);
    dispalyValue = getDurationDisplayValue(durationDefaultValue);
  }
  return dispalyValue;
}

// To get duration object
export function durationTime(String: string) {
  let duration = {
    days: 0,
    hours: 0,
    minutes: 0,
  };
  const REGEX = /P(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)W)?(?:(\d+)D)?(?:T(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?)?$/;
  /* istanbul ignore next */
  const [, , , , day, hour, min] = String.match(REGEX) || [];
  /* istanbul ignore next */
  duration.days = parseInt(day) || null;
  /* istanbul ignore next */
  duration.hours = parseInt(hour) || null;
  /* istanbul ignore next */
  duration.minutes = parseInt(min) || null;
  return duration;
}

// To get duration display value
export function getDurationDisplayValue(value: any) {
  let durationDisplayValue = '';
  /* istanbul ignore next */
  if (value?.days !== null) {
    /* istanbul ignore next */
    durationDisplayValue += `${value?.days} Days `;
  }
  /* istanbul ignore next */
  if (value?.hours !== null) {
    /* istanbul ignore next */
    durationDisplayValue += `${value?.hours} Hours `;
  }
  /* istanbul ignore next */
  if (value?.minutes !== null) {
    /* istanbul ignore next */
    durationDisplayValue += `${value?.minutes} Minutes`;
  }
  return durationDisplayValue;
}

// Validate default value based on datatype and UI control
export function validateDefaultValue(
  dateTypeUiControls: any,
  uiControl: any,
  dataType: any,
  value: any,
  sourceValues: any,
  allDefaultValues: any
) {
  let res = {
    isValid: false,
    message: new Array(),
  };

  /* istanbul ignore next */
  if (dateTypeUiControls?.includes(uiControl)) {
    res.isValid = true;
    return res;
  }

  // For empty value
  /* istanbul ignore next */
  if (value === '') {
    res.isValid = false;
    return res;
  }

  // For Boolean
  if (dataType === 'boolean') {
    if (value === 'true' || value === 'false') {
      res.isValid = true;
      return res;
    } else {
      res.isValid = false;
      res.message.push({
        type: 'info',
        msg: 'Default value for boolean can only be true or false',
      });
      return res;
    }
  }

  // For Decimal, Metric, Curreny
  if (dataType === 'decimalnumber' || uiControl === 'currency') {
    if (!isNaN(Number(value))) {
      res.isValid = true;
      return res;
    } else {
      res.isValid = false;
      res.message.push({
        type: 'error',
        msg: `${value} is not a number`,
      });
      return res;
    }
  }

  // For Number, Mobile number, Progress,
  if (
    dataType === 'number' ||
    uiControl === 'mobilenumber' ||
    uiControl === 'progressbar' ||
    uiControl === 'clickable'
  ) {
    /* istanbul ignore next */
    if (!isNaN(Number(value)) && !value?.includes('.')) {
      if (uiControl === 'progressbar' && (value < 0 || value > 100)) {
        res.isValid = false;
        res.message.push({
          type: 'info',
          msg: 'Default value should be present in between 0 and 100',
        });
        return res;
      }
      res.isValid = true;
      return res;
    } else {
      res.isValid = false;
      res.message.push({
        type: 'error',
        msg: `${value} is not a number`,
      });
      return res;
    }
  }

  // For Checkbox, Dropdown, Multiselectdropdown, Radio Button, Typeahead
  if (
    uiControl === 'checkbox' ||
    uiControl === 'dropdown' ||
    uiControl === 'multiselectdropdown' ||
    uiControl === 'radioButton' ||
    uiControl === 'typeahead'
  ) {
    /* istanbul ignore next */
    if (sourceValues.findIndex((x: any) => x?.DATA?.actualValue === value) >= 0 && !allDefaultValues?.includes(value)) {
      res.isValid = true;
      return res;
    } else {
      res.isValid = false;
      res.message.push({
        type: 'info',
        msg: `${value} is not present in default options.`,
      });
      return res;
    }
  }

  // For Duration
  if (uiControl === 'duration') {
    let regExp = /P(?:(\d+)Y)?(?:(\d+)M)?(?:(\d+)W)?(?:(\d+)D)?(?:T(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?)?$/;
    res.isValid = regExp.test(value);
    return res;
  }

  // For Email
  if (uiControl === 'email') {
    let regExp = /\S+@\S+\.\S+/;
    if (regExp.test(value)) {
      res.isValid = true;
      return res;
    } else {
      res.isValid = false;
      res.message.push({
        type: 'error',
        msg: `${value} is not a valid email address`,
      });
      return res;
    }
  }

  // For HTML, Payment, Rich Text
  if (uiControl === 'html' || uiControl === 'payment' || uiControl === 'editor') {
    /* istanbul ignore next */
    const isHTML = (str: string) =>
      !(str || '')
        .replace(/<([^>]+?)([^>]*?)>(.*?)<\/\1>/gi, '')
        .replace(/(<([^>]+)>)/gi, '')
        .trim();
    if (isHTML(value)) {
      res.isValid = true;
      return res;
    } else {
      res.isValid = false;
      res.message.push({
        type: 'error',
        msg: 'Given default value is not a valid HTML text!',
      });
      return res;
    }
  }

  // For Hyperlink, Preview Document, Redirection, Scorm Video
  if (
    uiControl === 'hyperlink' ||
    uiControl === 'previewdocument' ||
    uiControl === 'redirection' ||
    uiControl === 'scormVideo'
  ) {
    let regExp = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
    if (regExp.test(value)) {
      res.isValid = true;
      return res;
    } else {
      res.isValid = false;
      res.message.push({
        type: 'error',
        msg: `${value} is not a valid hyperlink!`,
      });
      return res;
    }
  }

  // For label type UI control
  res.isValid = true;
  return res;
}

export function getFileNameFromImgUrl(imgUrl: string) {
  if (!imgUrl) {
    return '';
  }
  /* istanbul ignore next */
  if (imgUrl?.includes("url('")) {
    imgUrl = imgUrl.substring(5, imgUrl.length - 2);
  }
  let imgUrlArr = imgUrl.split('/');
  let name = imgUrlArr[imgUrlArr.length - 1];
  return name;
}

export function getAttributeObj(
  attr: any,
  dataType: any = {
    name: 'text',
    dataType: 'string',
    uiElement: 'text',
    isMulti: false,
    properties: [],
  }
) {
  /* istanbul ignore next */
  let uiElement: uiElementObj = {
    ...dataType,
  };
  let attributeType: AttributeType = {
    uiElement,
    type: uiElement.dataType,
    properties: {},
    nestedNslDataTypeProperties: {
      item_type: {},
    },
    extendedProperties: {},
  };
  /* istanbul ignore next */
  let attribute: nslAttribute = {
    name: attr?.trim(),
    displayName: attr?.trim(),
    attributeType,
  };
  return attribute;
}

export function nestedEntityPayload(entity: any, cu?: any) {
  let dataToSend = {};
  dataToSend[entity.name] = {
    tf_id: '',
    dsd_id: '',
    type: 'nested',
    attribute_dict: {},
  };
  /* istanbul ignore next */
  entity?.nslAttributes?.forEach((attr: any) => {
    /* istanbul ignore next */
    if (attr?.generalEntity) {
      let payload = nestedEntityPayload(attr.generalEntity);
      let key = Object.keys(payload)[0];
      dataToSend[entity.name].attribute_dict[key] = payload[key];
      //dataToSend[entity.name].attribute_dict = {...nestedEntityPayload(attr.generalEntity)}
    } else {
      /* istanbul ignore next */
      dataToSend[entity.name].attribute_dict[attr.name] = {
        name: attr?.name,
        dsd_id: '<2.4 DSD ID>',
        tf_id: '<2.4 TF ID>',
      };
    }
  });
  return dataToSend;
}

export function getDummyDataForTemplate(template: string) {
  return {
    sectionName: 'Title Name',
    betType: 'GSI/Book/Collection',
    contentType: '',
    templateType: template,
    betData: [
      {
        betId: '123',
        betName: 'Title Name',
        betImage: 'url',
        betType: 'GSI/Book/Collection',
      },
    ],
    hideTitle: false,
  };
}

export function getIsOnBoarding() {
  if (localStorage.getItem('onBoarding') === 'true') {
    return true;
  } else {
    return false;
  }
}

export function getOnboardPersona(userDetails: any) {
  let realmname = localStorage.getItem('TenantName');
  /* istanbul ignore next */
  if (realmname === 'b2c') {
    return 'individualdeveloper';
  } else if (userDetails?.groups?.length > 0) {
    /* istanbul ignore next */
    return userDetails.groups[0]?.name;
  }
}

export function validateProviderImageExistOrNot(alias: string) {
  if (
    alias === 'linkedin' ||
    alias === 'github' ||
    alias === 'facebook' ||
    alias === 'google' ||
    alias === 'apple' ||
    alias === 'microsoft'
  ) {
    return true;
  } else {
    return false;
  }
}
export function getImage(item: any) {
  /*istanbul ignore next*/
  if (item?.betImage && item?.betImage !== 'url') {
    return item?.betImage;
  }
}

export function moveToTransactions(item: any, mode: any, betId: number, router?: Router) {
  /*istanbul ignore next*/
  if (mode == 'transaction') {
    if (item.betType == 'gsi' || item.betType == 'GSI' || item.betType == 'banner_type_gsis') {
      const solutionData = btoa(
        JSON.stringify({
          gsiId: item.betMasterId ? item.betMasterId : item.betId,
          bookId: betId,
        })
      );
      router.navigate(['cdui/transaction/view/' + solutionData]);
    }
    // const solutionData = btoa(JSON.stringify({ gsiId: this.bet?.betData[0].betId }));
    // this.router.navigate(['transaction/view/' + solutionData]);
    // this.router.navigate(['cdui/transaction/view/' + solutionData]);
    /*istanbul ignore next*/
    if (item.betType == 'book') {
      const solutionData = btoa(
        JSON.stringify({
          bookId: item.betId,
        })
      );
      router.navigate(['cdui/transaction/view/' + solutionData]);
    }
  }
}
export function getBetDataLength(bet: any) {
  /* istanbul ignore next */
  for (let i = 0; i < bet?.length - 1; i++) {
    /* istanbul ignore next */
    bet?.betData?.push(bet?.betData?.[0]);
  }
}
// excellent example of pass byreference in non primitives in Typescript by me, Thankyou

export function getEntityByDsdId(cu: any, tag: any, layerType: any) {
  /*istanbul ignore next*/
  if (cu) {
    let layer = cu?.layers?.find((layer: any) => layer?.type == layerType);
    return layer?.participatingItems?.find((_ent: any) => _ent?.name == tag?.token);
  }
}
export function formatCuRights(reqData: any) {
  /*istanbul ignore next*/
  if (reqData && reqData?.designTimeRights && reqData?.designTimeRights?.length > 0) {
    reqData?.designTimeRights?.map((obj: any) => {
      if (obj?.condition && obj?.condition?.fromDateTime == '' && obj?.condition?.toDateTime == '') {
        delete obj.condition;
      }
    });

    reqData?.txnTimeRights.map((obj: any) => {
      if (obj?.condition && obj?.condition?.fromDateTime == '' && obj?.condition?.toDateTime == '') {
        delete obj.condition;
      }
    });
  }
  return reqData;
}

export function checkInputField(event: any, query: any) {
  /*istanbul ignore next*/
  if (query.sourceAttributeuiElement === 'number' || query.sourceAttributeuiElement === 'mobilenumber') {
    return event.charCode == 8 || event.charCode == 0
      ? null
      : event.charCode == 45 || (event.charCode > 46 && event.charCode <= 57);
  }
}

export function prepareGEDashboardPayload(query: any, pageNo: any, pageSize: any) {
  /* istanbul ignore next */
  let req = {
    entityName: query?.geFilters?.[0]?.entityName,
    outputEntityName: 'Result',
    inputOutputMapping: {},
    limit: pageSize,
    offset: pageNo,
  };

  /*istanbul ignore next */
  query?.geFilters?.[0]?.attributeNames?.forEach((attr: any) => {
    /*istanbul ignore next */
    if (attr?.split('.')[0] === 'Result') {
      /*istanbul ignore next */
      req.inputOutputMapping[query?.geFilters?.[0]?.entityName + '.' + attr?.split('.')[1]] = attr;
    } else {
      req.inputOutputMapping[query?.geFilters?.[0]?.entityName + '.' + attr] = 'Result.' + attr;
    }
  });

  return req;
}

export function prepareGEDashboardPayloadForCharts(query: any, selectedAttribute: any, pageNo: any, pageSize: any) {
  /*istanbul ignore next*/
  let req: any = {
    entityName: query?.geFilters?.[0].entityName,
    outputEntityName: 'Result',
    inputOutputMapping: {
      nsl_system_txn_id: 'Result.Name_count',
    },
    groupBy: [],
    functions: [
      {
        fieldName: 'nsl_system_txn_id',
        functionType: 'COUNT',
      },
    ],
    limit: pageSize,
    offset: pageNo,
  };
  /*istanbul ignore else*/
  if (selectedAttribute?.split('.')[0] === 'Result') {
    /*istanbul ignore next*/
    req.inputOutputMapping[
      query?.geFilters?.[0]?.entityName + '.' + selectedAttribute?.split('.')[1]
    ] = selectedAttribute;
    /*istanbul ignore next*/
    req.groupBy.push({ fieldName: query?.geFilters?.[0]?.entityName + '.' + selectedAttribute?.split('.')[1] });
  } else {
    /*istanbul ignore next*/
    req.inputOutputMapping[query?.geFilters?.[0]?.entityName + '.' + selectedAttribute] = 'Result.' + selectedAttribute;
    /*istanbul ignore next*/
    req.groupBy.push({ fieldName: query?.geFilters?.[0]?.entityName + '.' + selectedAttribute });
  }

  return req;
}

export function convertToPercentage(y: any, sum: any) {
  return (parseInt(y) * 100) / sum;
}
export function stringToColour(str: any) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = '#';
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xff;
    colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour;
}
export function setPropertyInDivLayout(elRef: ElementRef, propertyName: string, property: any) {
  elRef?.nativeElement?.style?.setProperty(propertyName, property);
}

export function addSequentialCuInCuList(parentCU: any, originalCUList: any, changeUnitData: any) {
  let maxIndex: number = 0;
  /* istanbul ignore next */
  if (!changeUnitData.nextTriggerSet) {
    changeUnitData.nextTriggerSet = [];
  }
  /* istanbul ignore next */
  if (originalCUList?.length > 0) {
    originalCUList?.forEach((cu: any) => {
      maxIndex = Math.max(maxIndex, cu.index);
    });
    maxIndex = maxIndex + 1;
  }
  /* istanbul ignore next */
  let findIndexMatchedCU = originalCUList?.findIndex((x: any) => x?.index === parentCU?.index);

  /* istanbul ignore else */
  let nonParrallelCU: any = [];
  if (originalCUList[findIndexMatchedCU].nextTriggerSet.length > 0) {
    originalCUList[findIndexMatchedCU].nextTriggerSet.forEach((x: any, index: number) => {
      if (!x.isParallel) {
        changeUnitData.nextTriggerSet.push(x);
      } else {
        nonParrallelCU.push(x);
      }
    });

    originalCUList[findIndexMatchedCU].nextTriggerSet = nonParrallelCU;
    // originalCUList[findIndexMatchedCU].nextTriggerSet.filter((x:any)=>x.isParallel)
  }

  /* istanbul ignore next */
  if (findIndexMatchedCU !== -1 && originalCUList[findIndexMatchedCU]?.nextTriggerSet) {
    originalCUList[findIndexMatchedCU]?.nextTriggerSet.push({
      nextCUName: changeUnitData.name,
      index: maxIndex,
    });
  }
  originalCUList = [...originalCUList, { ...changeUnitData, index: maxIndex }];
  return originalCUList;
}

export function trimArray(item: string = null) {
  let elements: string[] = [];
  /* istanbul ignore next */
  if (item.trim()) {
    elements = item
      .split('.')
      .map((m) => m.trim())
      .filter((n) => n !== '');
  }
  return elements;
}
export function setStyles(bet: Shelf, elRef: ElementRef) {
  if (bet?.properties) {
    let properties = JSON.parse(bet.properties);
    if (properties?.backgroundColor) {
      setPropertyInDivLayout(elRef, '--container-color', properties?.backgroundColor);
    }
    if (properties?.containerColor) {
      setPropertyInDivLayout(elRef, '--card-color', properties?.containerColor);
    }
    if (properties?.containerHoverColor) {
      setPropertyInDivLayout(elRef, '--card-hover', properties?.containerHoverColor);
    }
    if (properties?.titleColor) {
      setPropertyInDivLayout(elRef, '--title-color', properties?.titleColor);
    }
    if (properties?.containerHeadingColor) {
      setPropertyInDivLayout(elRef, '--font-color', properties?.containerHeadingColor);
    }
    if (properties?.containerHeadingHoverColor) {
      setPropertyInDivLayout(elRef, '--font-hover-color', properties?.containerHeadingHoverColor);
    }
  }
}
export function removeEmptyCu(gsiData: any) {
  /*istanbul ignore next*/
  let emptyCuFound = gsiData?.solutionLogic?.findIndex((x: any) => !x.name || !x.id);
  /*istanbul ignore next*/
  if (emptyCuFound != -1) {
    gsiData?.solutionLogic?.splice(emptyCuFound, 1);
  }
  /*istanbul ignore next*/
  gsiData?.solutionLogic?.forEach((cu: any) => {
    let emptyNexttriggerSetElementFound = cu?.nextTriggerSet?.findIndex((x: any) => !x.nextCUName);
    if (emptyNexttriggerSetElementFound !== -1) {
      cu?.nextTriggerSet?.splice(emptyNexttriggerSetElementFound, 1);
    }
  });
  return gsiData;
}
/* istanbul ignore next */
export function betMapper(type: any, res: any) {
  if (type == 'gsi') {
    /* istanbul ignore next */
    const gsiRes = gsiMapper(res?.result);
    return gsiRes;
  } else if (type == 'change-unit') {
    /* istanbul ignore next */
    const cuRes = changeUnitMapper(res?.result);
    return cuRes;
  } else {
    /* istanbul ignore next */
    const entRes = entityMapper(res?.result);
    return entRes;
  }
}
export function handleSelectAgent(
  event: IRecommendations,
  appendAgentName: boolean,
  index: any,
  gsiData: any,
  agent: any
) {
  /* istanbul ignore next */
  if (index?.agents?.[0]) {
    index.agents[0].agentType = agent;
  }
  /* istanbul ignore next */
  let agentName = event?.name;
  /* istanbul ignore next */
  let agentId = event?.id;
  let designRights: IDesignRights = {
    decisionRight: false,
    disableParentRoleAccess: false,
    executionRight: false,
    informationRight: false,
    rightHolderType: 'ROLE',
    rightHolderName: agentName,
    rightHolderId: agentId,
  };
  let txnRights: IDesignRights = {
    decisionRight: false,
    disableParentRoleAccess: false,
    executionRight: true,
    informationRight: false,
    rightHolderType: 'ROLE',
    rightHolderName: agentName,
    rightHolderId: agentId,
  };
  /* istanbul ignore next */
  index.designTimeRights = index.designTimeRights?.length > 0 ? index.designTimeRights : [];
  /* istanbul ignore next */
  index.txnTimeRights = index.txnTimeRights?.length > 0 ? index.txnTimeRights : [];
  /* istanbul ignore next */
  let availableIndex = index.designTimeRights.findIndex(
    (designTimeRight: any) => designTimeRight.rightHolderName === designRights.rightHolderName
  );
  /* istanbul ignore next */
  if (availableIndex == -1 && !agentName?.includes('$')) {
    /* istanbul ignore next */
    index?.designTimeRights?.push(designRights);
    /* istanbul ignore next */
    index?.txnTimeRights?.push(txnRights);
  }
  /* istanbul ignore next */
  if (appendAgentName && !index?.description?.includes(event?.name)) {
    /* istanbul ignore next */
    index.description = event?.name + ' ' + index?.description;
    /* istanbul ignore next */
    // this.cuSentence = index?.description;
  }
  /* istanbul ignore next */
  gsiData?.solutionLogic?.forEach((element: any) => {
    /* istanbul ignore next */
    if (element?.referencedChangeUnit == index?.referencedChangeUnit) {
      /* istanbul ignore next */
      element.designTimeRights = index?.designTimeRights;
      /* istanbul ignore next */
      element.txnTimeRights = index?.txnTimeRights;
    }
  });
  return { index: index, gsiData: gsiData };
}
export function fillRights(res: any, index: any) {
  if (res?.extraction.CU_list?.[0]?.agent.length === 0) {
    index.designTimeRights = [];
    index.txnTimeRights = [];
  } else {
    if (index?.designTimeRights?.length > 0) {
      for (let dtRights = 0; dtRights < index.designTimeRights.length; dtRights++) {
        let dtRightsIndex = res?.extraction.CU_list?.[0]?.agent.findIndex((agent: any) => {
          return agent.name === index.designTimeRights[dtRights].rightHolderName;
        });
        if (dtRightsIndex === -1) {
          index.designTimeRights.splice(dtRights, 1);
          dtRights--;
        }
      }
    }
    if (index?.txnTimeRights?.length > 0) {
      for (let ttRights = 0; ttRights < index.txnTimeRights.length; ttRights++) {
        let dtRightsIndex = res?.extraction.CU_list?.[0]?.agent.findIndex((agent: any) => {
          return agent.name === index.txnTimeRights[ttRights].rightHolderName;
        });
        if (dtRightsIndex === -1) {
          index.txnTimeRights.splice(ttRights, 1);
          ttRights--;
        }
      }
    }
  }
  return index;
}
export function getSchedulerDetails(attrData: any) {
  let data = {
    incrementValue: '',
    day: '',
    time: '',
    interval: '',
    recurrenceExpression: '',
    startDate: '',
    endDate: '',
  };
  /*istanbul ignore next*/
  if (attrData && attrData?.properties) {
    /*istanbul ignore next*/
    data.incrementValue = attrData?.properties?.incrementValue;
    /*istanbul ignore next*/
    data.day = attrData?.properties?.day;
    /*istanbul ignore next*/
    data.time = attrData?.properties?.time;
    /*istanbul ignore next*/
    data.interval = attrData?.properties?.interval;
    /*istanbul ignore next*/
    data.recurrenceExpression = attrData?.properties?.recurrenceExpression;
    /*istanbul ignore next*/
    data.startDate = attrData?.properties?.startDate;
    /*istanbul ignore next*/
    data.endDate = attrData?.properties?.endDate;
  }
  return data;
}
function fillNSLFormulae(transEntityRecords: any, tempVarForTransEntityRecords: any, j: any, stepper: any) {
  let stepperVar = stepper === 'inputoutput' ? 0 : 1;
  //for NSL Formula
  let tempVarForNslFormula =
    tempVarForTransEntityRecords?.[j]?.txnNslAttribute?.[stepperVar]?.txnGeneralEntity?.transEntityRecords;
  if (stepper === 'inputoutput') {
    transEntityRecords.nslFormula.push([]);
  } else {
    transEntityRecords.nslFormulaForCondition.push([]);
  }
  if (tempVarForNslFormula && stepper === 'inputoutput') {
    transEntityRecords.selectedOperation[j] = 'formula';
    transEntityRecords.isSelectedOperation[j] = true;
  }
  for (let k = 0; k < tempVarForNslFormula?.length; k++) {
    if (
      !tempVarForNslFormula?.[k]?.txnNslAttribute?.[3]?.txnGeneralEntity ||
      !tempVarForNslFormula?.[k]?.txnNslAttribute?.[3]?.txnGeneralEntity?.transEntityRecords
    ) {
      let formulae = {
        functionName: '',
        attributeName: '',
        arithmeticOperator: '',
        nslQuery: '',
        nslFormula: '',
        nslCase: '',
      };
      if (stepper === 'inputoutput') {
        transEntityRecords.nslFormula?.[j].push(JSON.parse(JSON.stringify(formulae)));
        transEntityRecords.nslFormula[j][k] = loadNSLFormula(tempVarForNslFormula?.[k]);
      } else {
        transEntityRecords.nslFormulaForCondition?.[j].push(JSON.parse(JSON.stringify(formulae)));
        transEntityRecords.nslFormulaForCondition[j][k] = loadNSLFormula(tempVarForNslFormula?.[k]);
      }
    } else {
      if (stepper === 'inputoutput') {
        transEntityRecords.nslFormula[j].push([]);
      } else {
        transEntityRecords.nslFormulaForCondition[j].push([]);
      }
      for (
        let l = 0;
        l < tempVarForNslFormula?.[k]?.txnNslAttribute?.[3]?.txnGeneralEntity?.transEntityRecords?.length;
        l++
      ) {
        if (stepper === 'inputoutput') {
          transEntityRecords.nslFormula[j][k].push(
            loadNSLFormula(tempVarForNslFormula?.[k]?.txnNslAttribute?.[3]?.txnGeneralEntity?.transEntityRecords?.[l])
          );
        } else {
          transEntityRecords.nslFormulaForCondition[j][k].push(
            loadNSLFormula(tempVarForNslFormula?.[k]?.txnNslAttribute?.[3]?.txnGeneralEntity?.transEntityRecords?.[l])
          );
        }
      }
    }
  }
  let tempReturnValue = {
    transEntityRecords: transEntityRecords,
    tempVarForTransEntityRecords: tempVarForTransEntityRecords,
  };
  return tempReturnValue;
}

function fillNSLCase(transEntityRecords: any, tempVarForTransEntityRecords: any, j: any, stepper: any) {
  let stepperVar = stepper === 'inputoutput' ? 1 : 7;
  /* istanbul ignore next */
  let tempVarForNslCase =
    tempVarForTransEntityRecords?.[j]?.txnNslAttribute?.[stepperVar]?.txnGeneralEntity?.transEntityRecords;
  if (stepper === 'inputoutput') {
    transEntityRecords.nslCase.push([]);
  } else {
    transEntityRecords.nslCaseForCondition.push([]);
  }

  if (tempVarForNslCase && stepper === 'inputoutput') {
    transEntityRecords.selectedOperation[j] = 'case';
    transEntityRecords.isSelectedOperation[j] = true;
  }
  for (let k = 0; k < tempVarForNslCase?.length; k++) {
    if (
      !tempVarForNslCase?.[k]?.txnNslAttribute?.[4]?.txnGeneralEntity ||
      !tempVarForNslCase?.[k]?.txnNslAttribute?.[4]?.txnGeneralEntity?.transEntityRecords
    ) {
      let NslCase = {
        nslQueryCondition: { nslFormula: '', attributeName: '', nslCase: '', queryOperator: '', attributeValue: '' },
        nslThen: { functionName: '', attributeName: '', nslQuery: '', nslFormula: '', NSL_Case: '' },
        nslElse: { functionName: '', attributeName: '', nslQuery: '', nslFormula: '', NSL_Case: '' },
      };
      if (stepper === 'inputoutput') {
        transEntityRecords.nslCase?.[j].push(JSON.parse(JSON.stringify(NslCase)));
        transEntityRecords.nslCase[j][k] = loadNSLCase(tempVarForNslCase?.[k]);
      } else {
        transEntityRecords.nslCaseForCondition?.[j].push(JSON.parse(JSON.stringify(NslCase)));
        transEntityRecords.nslCaseForCondition[j][k] = loadNSLCase(tempVarForNslCase?.[k]);
      }
    } else {
      if (stepper === 'inputoutput') {
        transEntityRecords.nslCase[j].push([]);
      } else {
        transEntityRecords.nslCaseForCondition[j].push([]);
      }
    }
  }

  let tempReturnValue = {
    transEntityRecords: transEntityRecords,
    tempVarForTransEntityRecords: tempVarForTransEntityRecords,
  };
  return tempReturnValue;
}

export function displayValue(label: any) {
  let value = label;
  if (value) {
    if (!isNaN(Date.parse(value)) && value.includes('T') && value.includes('Z')) {
      if (formatDate(value, 'HH:mm:ss', 'en-US') === '00:00:00') {
        value = formatDate(value, 'MM/dd/yyyy', 'en-US');
      } else {
        value = formatDate(value, 'MM/dd/yyyy HH:mm:ss', 'en-US');
      }
    }
    if (IsJsonString(value)) {
      let dateArr = JSON.parse(value);
      /* istanbul ignore next */
      if (
        Array.isArray(dateArr) &&
        dateArr?.length === 2 &&
        !isNaN(Date.parse(dateArr?.[0])) &&
        dateArr?.[0]?.includes('T') &&
        dateArr?.[0]?.includes('Z') &&
        !isNaN(Date.parse(dateArr?.[1])) &&
        dateArr?.[1]?.includes('T') &&
        dateArr?.[1]?.includes('Z')
      ) {
        value = formatDate(dateArr[0], 'MM/dd/yyyy', 'en-US') + ' - ' + formatDate(dateArr[1], 'MM/dd/yyyy', 'en-US');
      }
    }
  }
  return value;
}

function IsJsonString(str: any) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
export function genericvoiceToTextInit(voiceToText: any) {
  voiceToText.interimResults = true;
  voiceToText.lang = 'en-US';
  voiceToText.addEventListener('result', (e: any) => {
    console.log(e);
    const transcript = Array.from(e.results)
      .map((result) => result[0])
      .map((result) => result.transcript)
      .join(' ');

    return transcript;
  });
  //return words;
}

export function startVoiceToText(voiceToText: any, voiceToTextFlag = false) {
  voiceToText.start();
  console.log('Started voice to text');
  voiceToText.addEventListener('end', (condition: any) => {
    if (voiceToTextFlag) {
      voiceToText.stop();
      console.log('Stopped voice to text');
    }
    // else {
    //   voiceToText.start();
    // }
  });
}

// Return display value if UI element is date range
export function returnAttrDisplayValue(value: any) {
  let dispalyValue = '';
  if (value && IsJsonString(value)) {
    dispalyValue =
      formatDate(JSON.parse(value)[0], 'MM/dd/yyyy', 'en-US') +
      ' - ' +
      formatDate(JSON.parse(value)[1], 'MM/dd/yyyy', 'en-US');
  }
  return dispalyValue;
}

export function validateEmptySlotItem(cu: any) {
  /* istanbul ignore next */
  if (cu?.slotItemProperties != undefined || cu?.slotItemProperties != null) {
    Object?.keys(cu?.slotItemProperties).forEach((slot: any) => {
      cu?.slotItemProperties[slot]?.propertyData?.CONSTANT?.changeDriverData?.DATA?.transEntityRecords?.forEach(
        (txnEnt: any) => {
          txnEnt?.txnNslAttribute?.forEach((txnAttr: any, attrIndex: any) => {
            if (Object?.keys(txnAttr).length === 0) {
              txnEnt?.txnNslAttribute.splice(attrIndex, 1);
            }
          });
        }
      );
    });
  }
  return cu;
}

export function isTemplate(rCu: any) {
  /* istanbul ignore next */
  if (rCu?.includes('NSL_')) {
    /* istanbul ignore next */
    if (rCu?.includes('NSL_Template')) {
      return true;
    } else {
      return false;
    }
  }
}

//This method is to remove the data type of an attribute in query sentence before saving the CU or GSI
export function removeDataType(querySentence: any) {
  Object?.entries(querySentence).forEach((layer: any) => {
    if (Array.isArray(layer[1])) {
      layer[1].forEach((token: any) => {
        /* istanbul ignore next */
        if (token?.data_type) {
          delete token.data_type;
        }
      });
    }
  });
  return querySentence;
}

import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { LoaderService } from '@app/@shared/services/loader/loader.service';
import { takeUntil } from 'rxjs/operators';
import { AlertService } from '@app/@core/toaster/alert.service';
import { ManageConfigurationEndpointService } from './manage-configuration-endpoint.service';

@Injectable({
  providedIn: 'root',
})
export class ManageConfigurationFacadeService implements OnDestroy {
  private ngUnsubscribe = new Subject();

  private ConfigurableCPEntities = new Subject();
  ConfigurableCPEntities$ = this.ConfigurableCPEntities.asObservable();

  private ConfigurableEntityData = new Subject();
  ConfigurableEntityData$ = this.ConfigurableEntityData.asObservable();

  private ConfigurableEntityTableData = new Subject();
  ConfigurableEntityTableData$ = this.ConfigurableEntityTableData.asObservable();

  private getSignupProperties = new BehaviorSubject('');
  getSignupProperties$ = this.getSignupProperties.asObservable();

  /*istanbul ignore next */
  get getsignupProperties() {
    return this.getSignupProperties;
  }

  constructor(
    private manageConfigurationEndpointService: ManageConfigurationEndpointService,
    private loader: LoaderService,
    private alertService: AlertService
  ) {}

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  getConfigurableCPEntities(pageSize?: any) {
    this.loader.show();
    this.manageConfigurationEndpointService
      .getConfigurableCPEntities(pageSize)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore else  */
        if (res) {
          this.loader.hide();
          this.ConfigurableCPEntities.next(res);
        }
      });
  }

  getConfigurableEntityData(generalEntityID: any) {
    this.loader.show();
    this.manageConfigurationEndpointService
      .getConfigurableEntityData(generalEntityID)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.ConfigurableEntityData.next(res);
      });
  }

  saveConfigurableEntityData(postData: any) {
    this.loader.show();
    this.manageConfigurationEndpointService
      .saveConfigurableEntityData(postData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.alertService.showToaster(res.message, '', res.status == '200' ? 'success' : 'error');
        this.loader.hide();
      });
  }

  tenantBotBasicSave(payload: any) {
    return this.manageConfigurationEndpointService.tenantBotBasicSave(payload);
  }
  fetchBotDetails() {
    return this.manageConfigurationEndpointService.fetchBotDetails();
  }
  faqSave(formData: any) {
    return this.manageConfigurationEndpointService.faqSave(formData);
  }
  fetchSignupConfig() {
    return this.manageConfigurationEndpointService.fetchSignupConfig();
  }
  saveSignupConfig(payload: any) {
    return this.manageConfigurationEndpointService.saveSignupConfig(payload);
  }
  deleteSignupConfig() {
    return this.manageConfigurationEndpointService.deleteSignupConfig();
  }
  // atqSave(formData:any){
  //   return this.manageConfigurationEndpointService.atqSave(formData);
  // }

  fetchSignupProperties(pageSize?: any) {
    this.loader.show();
    this.manageConfigurationEndpointService
      .fetchSignupProperties()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore else  */
        if (res) {
          this.loader.hide();
          this.getSignupProperties.next(res);
        }
      });
  }
  fetchGsiMappings() {
    return this.manageConfigurationEndpointService.fetchGsiMappings();
  }
  deleteGsiMapping(role: any) {
    return this.manageConfigurationEndpointService.deleteGsiMapping(role);
  }
}

import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { takeUntil } from 'rxjs/operators';
import { CduiBaseService } from '@app/auth/cdui-base.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalSearchTypeaheadEndpointService extends CduiBaseService {
  constructor(public injector: Injector, private http: HttpClient) {
    super(injector);
  }

  /*Gets Autocomplete global result */
  getAutoCompleteSearch(searchText: string, betType: string) {
    return this.http.get(`${this.api?.globalAutocomplete}searchText=${searchText}&betsType=${betType}`);
  }
  /*Gets Globals search result */
  getGlobalSearch(searchText: string, betType: string) {
    return this.http.get(`${this.api?.globalSearch}searchText=${searchText}&betsType=${betType}`);
  }
  /*Gets paginated Global search result */
  getPaginatedGlobalSearch(searchText: string, betType: string, pageNo: number, pageSize: number) {
    return this.http.get(
      `${this.api?.globalSearch}searchText=${searchText}&betsType=${betType}&pageNumber=${pageNo}&size=${pageSize}`
    );
  }
  /*Gets Google web search result */
  googleApi(searchText: string) {
    const apiKey = 'AIzaSyAKMmeAU8hd85zXCkYfnZPOjz56zs51yms';
    const apiCx = 'ab5f22567d3110db3';
    return this.http.get(`${this.api?.googleAPI}key=${apiKey}&cx=${apiCx}&q=${searchText}`);
  }
  /*Gets google image search result */
  googleImageApi(searchText: string) {
    const apiKey = 'AIzaSyAKMmeAU8hd85zXCkYfnZPOjz56zs51yms';
    const apiCx = 'ab5f22567d3110db3';
    return this.http.get(`${this.api?.googleAPI}key=${apiKey}&cx=${apiCx}&searchType=image&q=${searchText}`);
  }
}

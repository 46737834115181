import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UsersFacadeService } from '@app/admin/services/users/users-facade.service';
import { Logger } from '@core';
import { UserIdleService } from 'angular-user-idle';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga'; // Analytics Tracker
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm'; // Tag Manager Tracker
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { checkTokenExistsorNot, getEnvClientId } from './@core/generic-functions';
import { AlertService } from './@core/toaster/alert.service';
import {
  DynamicConfirmationDialogComponent,
  ConfirmDialogModel,
} from './@shared/components/dynamic-confirmation-dialog/dynamic-confirmation-dialog.component';
import { ChatBotIcons } from './@shared/enums/chatbot-icons.enums';
import { ChatbotFacadeService } from './@shared/services/chatbot/chatbot-facade.service';
import { EventsFacadeService } from './@shared/services/events/events-facade.service';
import { NotificationFacadeService } from './@shared/services/notification/notification-facade.service';
import { ManageConfigurationFacadeService } from './admin/services/manage-configuration/manage-configuration-facade.service';
import { AuthenticationService } from './auth';
import { CduiBaseComponent } from './cdui-base.component';
import { A2aFacadeService } from './create/shared/services/a2a/a2a-facade.service';
// import { DeveloperLessDevelopmentFacadeService } from './create/shared/services/developer-less-development/developer-less-development-facade.service';
import { GsiFacadeService } from './create/shared/services/gsi/gsi-facade.service';
import { LibraryFacadeService } from './create/shared/services/library/library-facade.service';
import { SolutionVersioningFacadeService } from './create/shared/services/versioning/solution-versioning-facade.service';
import { TranslatorService } from './shell/translator.service';

const log = new Logger('App');
//const googleTagManagerScriptSource = '../assets/googleTagManager.js'; // src for Google Tag Manager Script // needs to be changed

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends CduiBaseComponent implements OnInit, OnDestroy {
  chatBotIcons = ChatBotIcons;
  showDialog: boolean = false;
  ngSubscribe = new Subject();
  countDownTimer = this.userIdle.getConfigValue()?.timeout;
  favIcon: HTMLLinkElement = document.querySelector('#appIcon');
  dialogData: ConfirmDialogModel;
  labels: any;
  headerDesignData: any;
  public isLoggedIn = false;
  public userProfile: KeycloakProfile | null = null;
  private unsubscribe$: Subject<any> = new Subject<any>();
  chatBotIconType: ChatBotIcons;
  googleAnalyticsScript = document.createElement('script');
  environmentName: string = '';
  //googleTagManagerScript = document.createElement('script'); // this needs to be changed

  constructor(
    private injector: Injector,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private alertService: AlertService,
    private userIdle: UserIdleService,
    public dialog: MatDialog,
    private libraryFacadeService: LibraryFacadeService,
    private authService: AuthenticationService,
    private gsiFacadeService: GsiFacadeService,
    private translator: TranslatorService,
    private notificationFacadeService: NotificationFacadeService,
    private eventFacadeService: EventsFacadeService,
    private userFacade: UsersFacadeService,
    private readonly keycloak: KeycloakService,
    // private dldFacadeService: DeveloperLessDevelopmentFacadeService,
    private chatBotFacadeService: ChatbotFacadeService,
    private a2aFacadeService: A2aFacadeService,
    private versioningFacade: SolutionVersioningFacadeService,
    private manageconfigurationservice: ManageConfigurationFacadeService
  ) {
    super(injector);
    console.log('Constructor');
    /* istanbul ignore next */
    router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(takeUntil(this.ngSubscribe))
      .subscribe((event: NavigationEnd) => {
        if (event.url === '/cdui/mylibrary-dashboard') {
          console.log(event);
          this.resetData();
        }

        this.restart();
        if (event.urlAfterRedirects !== '/' && event.urlAfterRedirects !== '/login') {
          this.userIdle.startWatching();
        }
      });

    this.chatBotIconDecision();

    // this.googleAnalyticsScript.async = true;
    // this.googleAnalyticsScript.src = this.api?.googleTagManager + this.environment.googleAnalyticsTag;
    // document.head.prepend(this.googleAnalyticsScript); // injecting script

    this.detectLanguageChange();
    this.detectTenantDesign();
  }

  chatBotIconDecision() {
    /* istanbul ignore next */
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(takeUntil(this.ngSubscribe))
      .subscribe((event: NavigationEnd) => {
        if (event.url.includes('carnival')) {
          this.chatBotIconType = this.chatBotIcons.Robot;
        } else {
          this.chatBotIconType = this.chatBotIcons.Messenger;
        }
      });
    // chatBotIcons.Robot
  }

  checkTokenExists() {
    return checkTokenExistsorNot();
  }

  getTenantDetails() {
    return getEnvClientId();
  }

  resetData() {
    this.gsiFacadeService.updateTempGsiObject('');
    this.gsiFacadeService.updateGSIObject(null);
    this.a2aFacadeService.setExtractedSolutionList(null);
    this.eventFacadeService.updateScormScore(0);
    this.gsiFacadeService.resetBook();
    this.versioningFacade.resetFinishData();
  }

  detectLanguageChange() {
    console.log('test 109910');

    this.translator.languageLables$.pipe(takeUntil(this.ngSubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }
  detectTenantDesign() {
    this.userFacade.tenantDesign$.pipe(takeUntil(this.ngSubscribe)).subscribe((res: any) => {
      if (res) {
        this.favIcon.href = res?.fevicon?.desktop;
        localStorage.tenantDesignData = JSON.stringify(res);
      }
    });
  }

  ngOnInit() {
    // dynamic favicon
    if (localStorage.tenantDesignData && localStorage.tenantDesignData !== '') {
      this.applyHeaderDesign();
    }
    // Setup logger
    /* istanbul ignore else */
    if (this.environment.production) {
      Logger.enableProductionMode();
    }

    log.debug('init');

    // Start watching when user idle is starting.
    this.userIdle
      .onTimerStart()
      .pipe(takeUntil(this.ngSubscribe))
      .subscribe((count: any) => {
        /* istanbul ignore else */
        if (count) {
          const leftTimetoAutoLogout = this.countDownTimer - count;
          this.libraryFacadeService.updateCurrentTimeleftForSession(leftTimetoAutoLogout);
          const credentials =
            localStorage.getItem('credentials') !== null ? JSON.parse(localStorage.getItem('credentials')) : null;
          /* istanbul ignore else */
          if (this.showDialog === false && credentials !== null) {
            this.confirmDialog();
          }
        }
      });

    // Start watch when time is up.
    this.userIdle
      .onTimeout()
      .pipe(takeUntil(this.ngSubscribe))
      .subscribe(() => {
        this.logOut(false);
      });

    /* istanbul ignore next */
  }

  applyHeaderDesign() {
    this.headerDesignData = JSON.parse(localStorage.tenantDesignData);
    /* istanbul ignore next */
    this.favIcon.href = this.headerDesignData?.fevicon?.desktop;
  }

  public login() {
    /* istanbul ignore next */
    this.keycloak?.login();
  }

  public logout() {
    this.keycloak.logout();
  }

  confirmDialog(): any {
    this.showDialog = true;
    // const message = `You're being timed out due to inactivity. Please choose to stay signed in or to logoff.otherwise,you will logged off automatically.`;
    // const btn1 = 'Logout';
    // const btn2 = 'Stay Logged In';
    // const typeOfDialog = this.labels?.SessionTimeOut;
    const typeOfDialog = 'SessionTimeOut';
    /* istanbul ignore next */
    const message = this.labels?.SessionTimeOutMessage;
    /* istanbul ignore next */
    const buttonName1 = this.labels?.Logout;
    /* istanbul ignore next */
    const buttonName2 = this.labels?.Stay_Logged_In;

    this.dialogData = {
      title: this.labels?.Session_Timeout,
      message,
      buttonName1,
      buttonName2,
      typeOfDialog,
    };

    const dialogRef = this.dialog.open(DynamicConfirmationDialogComponent, {
      maxWidth: '400px',
      data: this.dialogData,
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((dialogResult: any) => {
      if (dialogResult === false) {
        this.logOut(true);
      } else if (dialogResult === true) {
        this.restart(); // it should be above of all
        this.dialog.closeAll();
        this.showDialog = false;
      }
    });
  }

  restart() {
    this.userIdle.resetTimer();
  }
  stop() {
    this.userIdle.stopTimer();
  }

  logOut(isUserLoggedOut: any) {
    this.restart();
    this.userFacade.updateActiveUserInfo(null);
    // this.notificationFacadeService.deRegisterToken();
    this.authService
      .logout()
      ?.pipe(takeUntil(this.unsubscribe$))
      .subscribe((res: any) => {
        if (localStorage.getItem('isSocialLogin') === 'true') {
          if (this.keycloak.getKeycloakInstance()?.authenticated) {
            this.keycloak.logout();
          }
        }
        this.showDialog = false;
        this.dialog.closeAll();
        console.log('Logout Btn');
        this.router.navigate(['/login']);
        // this.notificationFacadeService.deleteFCMToken();
        sessionStorage.clear();
        localStorage.clear();
        this.chatBotFacadeService.resetStore();
        this.notificationFacadeService.updateTokenValidateForPushNotification(false);
        if (isUserLoggedOut !== true) {
          this.alertService.showToaster(this.labels?.Session_Time_Up, '', 'info');
        }
      });
  }

  parentEventEmitter(event: any) {
    if (!event?.target?.id?.includes('notification')) {
      this.notificationFacadeService.clearNotificationComponent(true);
    }
  }
  // chatBotEnabled: boolean;
  // fetchDetails() {
  //   this.manageconfigurationservice
  //     .fetchBotDetails()
  //     .pipe(takeUntil(this.ngSubscribe))
  //     .subscribe((res: any) => {
  //       if (res.result == null) {
  //         this.chatBotEnabled = false;
  //       } else {
  //         this.chatBotEnabled = true;
  //       }
  //     });
  // }
  addingCDNURL(staticPath: any) {
    /*istanbul ignore next */
    if (this.environment.cdnUrl) {
      let staticUrl = staticPath.split('assets');
      return this.environment.cdnUrl + '/assets' + staticUrl[1];
    } else {
      return staticPath;
    }
  }
  ngOnDestroy() {
    this.ngSubscribe.next();
    this.ngSubscribe.complete();
  }
}

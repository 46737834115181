import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { pluck } from 'rxjs/operators';
import { RearrangeData } from '../../components/change-unit-list/change-unit-list.component';
import { CduiBaseService } from '@app/auth/cdui-base.service';
@Injectable({
  providedIn: 'root',
})
export class ChangeUnitEndpointService extends CduiBaseService {
  constructor(private injector: Injector, private http: HttpClient) {
    super(injector);
  }
  getAllCU(pagenum: number, pageSize: number, searchCriteria: string, isPublished: boolean) {
    const ontology = '';
    const publisherId = '';
    return this.http
      .get(
        `${this.api?.savecu}?limit=${pageSize}&ontology=${ontology}&page=${pagenum}&publisherIdOrName=${publisherId}&query=${searchCriteria}&isPublished=${isPublished}`
      )
      .pipe(pluck('result'));
  }

  getCuCount(pagenum: number, pageSize: number, searchCriteria: string) {
    return this.http
      .get(
        this.api?.tfCoreUrl +
          '/CU/matching?pageNo=' +
          pagenum +
          '&pageSize=' +
          pageSize +
          '&searchCriteria=' +
          searchCriteria
      )
      .pipe(pluck('result', 'total'));
  }

  getCuDetailsById(id: string) {
    return this.http.get(`${this.api?.savecu}/${id}`);
  }

  getTriggerCU(id: any) {
    return this.http.get(this.api.fetchTriggerCu + `/${id}`);
  }

  saveCU(cu: any) {
    const { isFinal, ...rest } = cu;
    if (isFinal) {
      return this.http.post(`${this.api?.savecu}?isFinal=${isFinal}`, rest);
    } else {
      return this.http.post(`${this.api?.savecu}`, rest);
    }
  }
  getCusByGsiId(gsiId: number, triggerCuId?: any, bookid?: any) {
    let access = '';
    if (location.pathname.includes('transaction/view')) {
      access = 'EXECUTION_RIGHTS';
    } else if (location.pathname.includes('newsolution') || location.pathname.includes('canvas')) {
      access = 'DESIGN_RIGHTS';
    }
    if (triggerCuId) {
      if (bookid) {
        return this.http.get(
          `${this.api?.gsiLight}${gsiId}?bookId=${bookid}&triggerCuId=${triggerCuId}&userRights=${access}`
        );
      } else {
        return this.http.get(`${this.api?.gsiLight}${gsiId}?triggerCuId=${triggerCuId}&userRights=${access}`);
      }
    } else if (bookid) {
      return this.http.get(`${this.api?.gsiLight}${gsiId}?bookId=${bookid}&userRights=${access}`);
    } else {
      return this.http.get(`${this.api?.gsiLight}${gsiId}?userRights=${access}`);
    }
  }

  getDesignByUrl(url: string) {
    url = url.replace('/dsd-orch', '');
    let env = this.api?.dsdBetsStoreUrl;
    env = env.replace('/dsd-bets-store', '');
    return this.http.get(`${env}${url}`);
  }

  getCuDataByGsiId(gsiId: number, cuId: number) {
    return this.http.get(`${this.environment.dsdOrchUrl}/execute/${gsiId}/changeunit/${cuId}`);
  }

  updateChangeUnitRights(cu: any, body: any) {
    return this.http.put(this.api?.tfCoreUrl + '/CU/' + cu.id + '/irdr?isDesignRights=' + cu.flag, body);
  }

  getReservedCuList(pagenum: number, pageSize: number, searchCriteria: string) {
    const publisher = '';
    const ontology = '';
    return this.http
      .get(
        `${this.api?.ReservedCuList}?limit=${pageSize}&ontology=${ontology}&page=${pagenum}&publisherIdOrAuthor=${publisher}&query=${searchCriteria}`
      )
      .pipe(pluck('result'));
  }

  getReservedEntityList(pagenum: number, pageSize: number, searchCriteria: string) {
    const publisher = '';
    const ontology = '';
    return this.http
      .get(
        `${this.api?.ReservedEntityList}?limit=${pageSize}&ontology=${ontology}&page=${pagenum}&publisherIdOrAuthor=${publisher}&query=${searchCriteria}`
      )
      .pipe(pluck('result'));
  }

  uiElementSave(data: any) {
    return this.http.post(this.api?.uiControls, data);
  }
  staticLabelsSave(staticLabels: any, lang: any) {
    return this.http.post(this.api?.staticLabels + 'add/' + lang, staticLabels);
  }
  getStaticLabels(lang: any) {
    return this.http.get(this.api?.staticLabels + lang);
  }

  loadUIControls(cuId: any) {
    return this.http.get(`${this.api?.uiControls}?cuId=${cuId}&cuType=CHANGEUNIT`).pipe(pluck('result'));
  }
  setChangeUnitEvents(data: any) {
    return this.http.post(this.api?.eventsUrl, data);
  }

  getListMethodNames() {
    return this.http.get(this.api?.listFunctions).pipe(pluck('result'));
  }

  getReservedEntities() {
    return this.http.get(this.api?.reservedEntities).pipe(pluck('result'));
  }

  saveConstantChangeDriver(ccdObj: any, triggerCUId: any) {
    return this.http
      .post(this.api?.tfCoreUrl + `/trigger-cu/${triggerCUId}/slot-item-data`, ccdObj)
      .pipe(pluck('result'));
  }
  getConstantChangeDrivers(triggerCuId: any, slotId: any) {
    return this.http.get(this.api?.tfCoreUrl + `/trigger-cu/${triggerCuId}/slot-item-data/${slotId}`);
  }

  getReservedCuDetailsById(id: string) {
    return this.http.get(`${this.environment.dsdOrchUrl}/dsd-bets-store/change-unit/reserved/fetch/${id}`);
  }

  getRelatedBetsData(id: any, type: any) {
    return this.http.get(`${this.api?.getRelatedBetsData}/${id}/${type}`);
  }

  validateEQLWhiteboardQuery(data: any) {
    return this.http.post(this.api?.eqlCanvasValidator, data);
  }
  validateEQLBuilderQuery(data: any) {
    return this.http.post(this.api?.eqlBuilderValidator, data);
  }

  previewRearrageCU(rearrageData: RearrangeData) {
    return this.http.put(
      this.api?.rearrangeCU +
        `/preview/CU/${rearrageData.gsiId}?currentCUId=${rearrageData.mainCUId}&previousTriggerCUIds=${rearrageData.previousTriggerCUId}&nextTriggerCUIds=${rearrageData.nextTriggerCUIds}`,
      {}
    );
  }

  rearrangeCU(rearrageData: RearrangeData, cu: any) {
    return this.http.put(
      this.api?.rearrangeCU +
        `/insert/CU/${rearrageData.gsiId}?previousTriggerCUIds=${rearrageData.previousTriggerCUId}&nextTriggerCUIds=${rearrageData.nextTriggerCUIds}`,
      cu
    );
  }
}

import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { LoaderService } from '@app/@shared/services/loader/loader.service';
import { mergeMap, takeUntil, map } from 'rxjs/operators';
import { CarnivalEndpointService } from './carnival-endpoint.service';
import { basicQnsList, basicQuestions } from '@app/carnival/carnival-questions';
import { AlertService } from '@app/@core/toaster/alert.service';
import { AuthenticationService } from '@app/auth';
import JSEncrypt from 'jsencrypt';

@Injectable({
  providedIn: 'root',
})
export class CarnivalFacadeService implements OnDestroy {
  private ngUnsubscribe = new Subject();

  private basicQuestionsSubject = new Subject();
  basicQuestions$ = this.basicQuestionsSubject.asObservable();

  private basicQnsListSubject = new Subject();
  basicQnsList$ = this.basicQnsListSubject.asObservable();

  private activeFeatureFlagSubject = new BehaviorSubject('');
  activeFeatureFlag$ = this.activeFeatureFlagSubject.asObservable();

  private openMenuPanelSubject = new BehaviorSubject(false);
  openMenuPanel$ = this.openMenuPanelSubject.asObservable();

  userProfileSubject = new Subject();
  userProfile$ = this.userProfileSubject.asObservable();

  userProfileInfoSubject = new BehaviorSubject(null);
  userProfileInfo$ = this.userProfileInfoSubject.asObservable();

  solutionDetailsSubject = new Subject();
  solutionDetails$ = this.solutionDetailsSubject.asObservable();

  private allAvatarsInfoSubject = new BehaviorSubject(null);
  allAvatarsInfo$ = this.allAvatarsInfoSubject.asObservable();

  private selectedAvatarsInfoSubject = new BehaviorSubject(null);
  selectedAvatarsInfo$ = this.selectedAvatarsInfoSubject.asObservable();

  private carnivalMenu = new Subject();
  carnivalMenu$ = this.carnivalMenu.asObservable();

  constructor(
    private carnivalEndpointService: CarnivalEndpointService,
    private alertService: AlertService,
    private authService: AuthenticationService,
    private loader: LoaderService
  ) {}

  login(userLoginDetails: any, isOnboarding?: boolean) {
    // const { isRepresentative, institueName, ...rest } = userLoginDetails;
    const loginDetails = {
      userName: userLoginDetails.username,
      encryptedPassword: this.encrypt(userLoginDetails.password),
      tenantName: userLoginDetails.tenant,
      clientId: userLoginDetails.client_id,
    };
    /* istanbul ignore next */
    return this.authService?.login(loginDetails, userLoginDetails.remember, isOnboarding);
  }

  encrypt(password: string) {
    const publicKey =
      'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCLU0whc9gpsHOER0wCrE/j13OASD1h/fL9lFXgrDT1HynMhxwIhCJPja7ww06wA3B0uZ8kxEfCavohvbmjMph3CoPRXWynWuvenDGB2ek1nbCg2aHJvmb3Zg1Coea0gmGkBorVWtqkwmLpuX/2Jq+W+UoWSMfePuyPC3kY4VwFpQIDAQAB';
    let $encrypt = new JSEncrypt();
    const text = `${password}`.trim();
    /* istanbul ignore else */
    if (text.length < 117) {
      $encrypt.setPublicKey(publicKey);
      return String($encrypt.encrypt(text));
    }
  }

  updateMenuType(carnival: boolean) {
    this.carnivalMenu.next(carnival);
  }

  addUserProfile(data: any, isTour?: any) {
    this.carnivalEndpointService
      .addUserProfile(data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        if (res) {
          this.userProfileSubject.next(res);
          /* istanbul ignore else */
          if (!isTour) {
            this.alertService.showToaster(res.message, '', 'success');
          }
        }
      });
  }

  getUserProfile() {
    this.carnivalEndpointService
      .getUserProfile()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        if (res) {
          /* istanbul ignore next */
          this.userProfileInfoSubject.next(res?.result);
        }
      });
  }

  resetUserProfileInfo() {
    this.userProfileInfoSubject.next(null);
  }

  getMatchingFeatureflag(flag: any) {
    return this.carnivalEndpointService.getMatchingFeatureflag(flag);
    // .pipe(takeUntil(this.ngUnsubscribe))
    // .subscribe((res: any) => {
    //   if (res) {
    //     // this.activeFeatureFlagSubject.next(res?.result);
    //     return res?.result
    //   }
    // });
  }

  getFeatureflag(flag: any) {
    return this.carnivalEndpointService.getFeatureflag(flag);
  }

  toggleMenuPanel(flag: boolean) {
    this.openMenuPanelSubject.next(flag);
  }

  getToggleMenuPanel() {
    return this.openMenuPanelSubject.asObservable();
  }

  getBasicQnsList() {
    this.basicQnsListSubject.next(basicQnsList);
  }

  getBasicQuestions() {
    this.basicQuestionsSubject.next(basicQuestions);
  }

  getBookDetails(searchCriteria?: string) {
    this.loader.show();
    let result: any;
    this.carnivalEndpointService
      .getBookDetails(searchCriteria)
      .pipe(
        map((book: any) => {
          /* istanbul ignore next */
          result = { bookId: book?.result?.content[0]?.id };
          /* istanbul ignore next */
          if (book?.result?.content?.length == 0) {
            this.solutionDetailsSubject.next(null);
            this.loader.hide();
          }
          sessionStorage.setItem('bookData', JSON.stringify(book.result.content[0]));
          /* istanbul ignore next */
          return result?.bookId;
        }),
        mergeMap((bookId: any) => this.carnivalEndpointService.getGSIsWithExecutionStatus(bookId))
      )
      .subscribe(
        (res) => {
          this.loader.hide();
          result = { ...result, content: res };
          this.solutionDetailsSubject.next(result);
        },
        (error: any) => {
          this.loader.hide();
        }
      );
  }

  updateSolutionDeatils(data: any) {
    this.solutionDetailsSubject.next(data);
  }

  getAllAvatars() {
    this.carnivalEndpointService
      .getAllAvatars()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        if (res) {
          /* istanbul ignore next */
          this.allAvatarsInfoSubject.next(res?.result);
        }
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { AlertService } from '@app/@core/toaster/alert.service';
import { CduiBaseService } from '@app/auth/cdui-base.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable()
export class TranslatorService extends CduiBaseService {
  jsonURL = 'assets/i18n/en.json';
  languageLabels = new BehaviorSubject('');
  languageLables$ = this.languageLabels.asObservable();
  isLanguageTranslationFailed: boolean = true;
  isTranslatedFlag: boolean = false;
  translatedWords = new Subject();
  translatedWords$ = this.translatedWords.asObservable();
  translatedWordsResult = new Subject();
  translatedWordsResult$ = this.translatedWordsResult.asObservable();
  languages = new Subject();
  languages$ = this.languages.asObservable();
  private getAllLanguages = new BehaviorSubject('');
  getAllLanguages$ = this.getAllLanguages.asObservable();
  private ngUnsubscribe = new Subject();
  constructor(private injector: Injector, private http: HttpClient, private alertService: AlertService) {
    super(injector);
  }

  public getJSON(): Observable<any> {
    return this.http.get(this.jsonURL);
  }

  saveStaticLabels(staticLabels: any, lang: any) {
    lang = lang ? lang.toUpperCase() : 'EN';
    return this.http.post(this.api?.saveStaticLabels + lang.toUpperCase(), staticLabels);
  }

  getLanguages() {
    this.http.get(this.api?.getLanguages).subscribe((res: any) => {
      this.languages.next(res);
      this.getAllLanguages.next(res);
    });
  }

  callGetAllLanguage(languages: any) {
    this.getAllLanguages.next(languages);
  }

  getStingTranslate(sourceLanguage: string, targetLanguage: string, words: any[], tenantName: string) {
    this.http
      .post(this.api?.getStringTranslate + `/${sourceLanguage}/${targetLanguage}?tenant=${tenantName}`, words)
      .subscribe((res: any) => {
        /* istanbul ignore else */
        if (res) {
          this.translatedWords.next(res);
          for (let word in res) {
            /* istanbul ignore else */
            if (res[word] == '') {
              this.alertService.showToaster(`${word} not available`, '', 'error');
            }
          }
        }
      });
  }

  addStaticLabel(payload: any, tagName: string, langCode: string) {
    this.http.post(this.api?.addStaticLabels + `/${tagName}` + `/${langCode}`, payload).subscribe((res: any) => {
      /* istanbul ignore else */
      if (res.labels) {
        this.alertService.showToaster(`Labels added succesfully`, '', 'success');
      }
    });
  }

  updateStaticLabel(payload: any, tagName: string, langCode: string, allLang: boolean) {
    this.http
      .put(this.api?.updateStaticLabels + `/${tagName}` + `/${langCode}` + `/${allLang}`, payload)
      .subscribe((res: any) => {
        /* istanbul ignore else */
        if (res.labels) {
          this.alertService.showToaster(`Labels updated succesfully`, '', 'success');
        }
      });
  }

  deleteStaticLabel(tagName: string, word: string) {
    this.http.delete(this.api?.deleteStaticLabel + `/${tagName}` + `/${word}`).subscribe((res: any) => {
      /* istanbul ignore else */
      if (res) {
        this.alertService.showToaster(`${res}`, '', 'error');
      }
    });
  }

  updateMultipleWords(
    isTranslate: boolean,
    sourceLanguage: string,
    targetLanguage: string,
    toupdateWords: any[],
    tenantName: string
  ) {
    this.http
      .put(
        this.api?.updateMultipleWords +
          '?isTranslate=' +
          `${isTranslate}` +
          '&sourceLanguage=' +
          `${sourceLanguage}` +
          '&targetLanguage=' +
          `${targetLanguage}` +
          '&tenant=' +
          `${tenantName}`,
        toupdateWords
      )
      .subscribe((res: any) => {
        /* istanbul ignore else */
        if (res) {
          this.translatedWordsResult.next(res);
        }
      });
  }

  getStaticLabels(lang: any) {
    lang = lang ? lang.toUpperCase() : 'EN';
    this.http.get(this.api?.getStaticLabels + lang).subscribe((res) => {
      this.languageLabels.next(res['labels']);
      this.isTranslatedFlag = true;
    });
  }

  getStaticJsonFromLocale() {
    this.getJSON().subscribe((res) => {
      console.log(res);
      this.languageLabels.next(res['labels']);
    });
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}

<div class="container" *ngIf="!globalSearch">
  <div class="row home-page-content">
    <div class="col-6 h-100vh home-page-Leftcontent">
      <div class="search-wrapper">
        <div class="cloudimg">
          <figure><img [src]="cloudLogo" class="img-fluid" alt="" /></figure>
        </div>
        <div class="nslhub">
          <h1>nslhub</h1>
        </div>

        <!-- For Global Search -->
        <div class="searchere globl-search-wrap">
          <input
            class="searchbox search-input two"
            type="text"
            name="search"
            [(ngModel)]="searchTextAutocomplete"
            [placeholder]="labels?.Search"
            (keyup)="getAutoCompleteSearch()"
            autocomplete="off"
          />
          <button
            *ngIf="searchTextAutocomplete !== ''"
            class="clear-ico sprite search-close pointer"
            (click)="clearSearch()"
          >
            {{ labels?.Close }}
          </button>
          <button class="search-ico sprite search-btn four" (click)="searchOnSelect()">
            {{ labels?.Search }}
          </button>
          <button class="gseicon" mat-icon-button [matMenuTriggerFor]="sortMenu">
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
          <mat-menu #sortMenu="matMenu" xPosition="before" classList="sortby-panel">
            <button
              mat-menu-item
              [ngClass]="searchOptionSelected === true ? 'active-btn' : ''"
              (click)="searchOptionSelected = true"
            >
              <mat-icon class="glsearh">search</mat-icon>Global search
            </button>
            <button
              mat-menu-item
              [ngClass]="searchOptionSelected === false ? 'active-btn' : ''"
              (click)="searchOptionSelected = false"
            >
              <span class="gicon"><img [src]="googleLogo" /></span>
              Google search
            </button>
          </mat-menu>

          <div class="globl-search-panel" *ngIf="isSearchMenuOpen && searchOptionSelected">
            <ul>
              <li>
                <button [ngClass]="isAll === true ? 'custm-primary' : 'btn-default'" (click)="searchAll()">
                  {{ labels?.All }}
                </button>
              </li>
              <li>
                <button [ngClass]="isGSI === true ? 'custm-primary' : 'btn-default'" (click)="searchGSI()">
                  {{ labels?.GSI }}
                </button>
              </li>
              <li>
                <button [ngClass]="isCU === true ? 'custm-primary' : 'btn-default'" (click)="searchCU()">
                  {{ labels?.Change_Unit }}
                </button>
              </li>
              <li>
                <button [ngClass]="isEntity === true ? 'custm-primary' : 'btn-default'" (click)="searchEntity()">
                  {{ labels?.Entity }}
                </button>
              </li>
              <li>
                <button [ngClass]="isAttribute === true ? 'custm-primary' : 'btn-default'" (click)="searchAttribute()">
                  {{ labels?.Attribute }}
                </button>
              </li>
            </ul>
            <div class="search-hints gbl-search-relt">
              <ul *ngFor="let autoType of autoCompleteData">
                <li>{{ (autoType?.hits)[0]?.name }}</li>
              </ul>
            </div>
            <div class="gsis-srach gbl-search-relt">
              <ul *ngFor="let autoType of autoGSIdata">
                <h6>{{ autoType?.type }}</h6>
                <li *ngFor="let res of autoType?.hits">
                  <span (click)="updateSearchData(res)">{{ res?.name }}</span>
                </li>
              </ul>
              <ul *ngFor="let autoType of autoCUdata">
                <h6>{{ autoType?.type }}</h6>
                <li *ngFor="let res of autoType?.hits">
                  <span (click)="updateSearchData(res)">{{ res?.name }}</span>
                </li>
              </ul>
              <ul *ngFor="let autoType of autoEntitydata">
                <h6>{{ autoType?.type }}</h6>
                <li *ngFor="let res of autoType?.hits">
                  <span (click)="updateSearchData(res)">{{ res?.name }}</span>
                </li>
              </ul>
              <ul *ngFor="let autoType of autoAttributedata">
                <h6>{{ autoType?.type }}</h6>
                <li *ngFor="let res of autoType?.hits">
                  <span (click)="updateSearchData(res)">{{ res?.name }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6 h-100vh home-page-Rightcontent">
      <div class="book-wrapper">
        <figure><img [src]="book1Logo" class="img-fluid" alt="" /></figure>
      </div>
    </div>
  </div>
</div>

<div *ngIf="globalSearch">
  <app-global-search-typeahead
    [searchTextAutocomplete]="searchTextAutocomplete"
    [searchGSIdata]="searchGSIdata"
    [searchEntitydata]="searchEntitydata"
    [searchCUdata]="searchCUdata"
    [searchAttributedata]="searchAttributedata"
  >
  </app-global-search-typeahead>
</div>

<!-- For search (Open by using "searchOnSelect" function) -->
<ng-template #myDialog>
  <div mat-dialog-content>
    <div class="row dragNdrop">
      <div class="col text-center ggl-serch-pop">
        <div class="ggl-serch-cont">
          <mat-tab-group class="sreach-tabs" (selectedTabChange)="onChangeTab($event)">
            <mat-tab label="All">
              <span class="allreslt"
                >About {{ googleResult?.searchInformation.formattedTotalResults }} results ({{
                  googleResult?.searchInformation.formattedSearchTime
                }}
                seconds)
              </span>
              <div class="search-relts" *ngFor="let res of googleResult?.items">
                <a (click)="goToLink(res.link)">
                  <span>{{ res.formattedUrl }}</span>
                  <h3>{{ res.title }}</h3>
                </a>
                <p>
                  {{ res.snippet }}
                </p>
              </div>
            </mat-tab>
            <mat-tab label="Images" click="imageGoogle()">
              <span class="allreslt"
                >About {{ googleImageResult?.searchInformation.formattedTotalResults }} results ({{
                  googleImageResult?.searchInformation.formattedSearchTime
                }}
                seconds)
              </span>
              <div class="search-relts">
                <div class="image-box" *ngFor="let res of googleImageResult?.items">
                  <a (click)="goToLink(res.link)">
                    <img [src]="res.link" alt="" />
                  </a>
                  <a class="urltitle" (click)="goToLink(res.image.contextLink)"
                    >{{ res.title }}
                    <div class="urltext">
                      {{ res.displayLink }}
                    </div>
                  </a>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>
  </div>
  <mat-dialog-actions align="end">
    <button mat-button matDialogClose>{{ labels?.Cancel }}</button>
  </mat-dialog-actions>
</ng-template>

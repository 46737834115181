import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '@app/@core/toaster/alert.service';
import { LoaderService } from '@app/@shared/services/loader/loader.service';
import { RolesFacadeService } from '@app/admin/services/roles/roles-facade.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthenticationService } from '../authentication.service';
import { AnimationOptions } from 'ngx-lottie';
import { any } from 'cypress/types/bluebird';
import { UsersFacadeService } from '@app/admin/services/users/users-facade.service';
import { CarnivalFacadeService } from '@app/carnival/services/carnival/carnival-facade.service';
import { OnboardingFacadeService } from '@app/onboarding/services/onboarding-facade.service';
import { TranslatorService } from '@app/shell/translator.service';
import { ManageConfigurationFacadeService } from '@app/admin/services/manage-configuration/manage-configuration-facade.service';
import { NgForm } from '@angular/forms';
import { AutoIdNestedEntityComponent } from '@app/@shared/components/nested-entity/nested-entity.component';
@Component({
  selector: 'app-self-onboarding-signup',
  templateUrl: './self-onboarding-signup.component.html',
  styleUrls: ['./self-onboarding-signup.component.scss'],
})
export class SelfOnboardingSignupComponent implements OnInit, OnDestroy {
  private ngUnsubscribe = new Subject();
  @ViewChild('adapterForm') signupForm: NgForm;
  tenantName: any;
  confirmPassword: string = '';
  signupPayload = {
    email: '',
    firstName: '',
    isEnabled: true,
    lastName: '',
    mobileNumber: '',
    name: '',
    password: '',
    realm: '',
    roles: new Array(),
    customID: '',
  };
  verifyOtpPayload = {
    roles: new Array(),
  };
  signupMobile: any;
  signupOtp: any;
  password_info: any;
  labels: any;
  defaultRole: any = null;
  activateOtp: boolean = false;
  timeLeft: number = 30;
  interval: any;
  rolesList: any;
  selectedRole: any;
  emailValidator = true;
  mobileNumberValidator = true;
  passwordCheck = true;
  lottieOptions: AnimationOptions = {
    path: '../../../assets/json/login_earth.json',
  };
  onboardingTenant: any[] = ['selfonboard', 'systemintegrator', 'b2b', 'sdp', 'bdp', 'freelancer'];
  onboarding: boolean = false;
  customLabel: any;
  showRoles: boolean = false;
  showPublicRoles: any;
  isRoleMandatory: any;
  errorToaster: boolean = false;
  signupOptions: string;
  customIdGeneration: string;

  constructor(
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private router: Router,
    private loader: LoaderService,
    private rolesFacade: RolesFacadeService,
    private userFacade: UsersFacadeService,
    private carnivalFacadeService: CarnivalFacadeService,
    private onboardingFacadeService: OnboardingFacadeService,
    private translator: TranslatorService,
    private manageConfigurationFacade: ManageConfigurationFacadeService
  ) {
    this.tenantName = localStorage.getItem('TenantName');
    this.getRoles();
    this.detectActiveUserInfo();
    this.detectGsiMappingsByRoleName();
    /* istanbul ignore next */
    if (this.onboardingTenant?.includes(this.tenantName)) {
      this.onboarding = true;
    }
    this.detectLanguageChange();
    this.fetchSignupConfigurations();
  }

  ngOnInit(): void {
    this.passwordPolicy();
  }

  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }
  // get active user response
  detectActiveUserInfo() {
    this.userFacade.activeUserInfo$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore next */
      if (res) {
        if (this.selectedRole) {
          this.onboardingFacadeService.getGsiMappingsByRoleName(this.selectedRole?.name);
        } else {
          this.router.navigateByUrl('/cdui/mylibrary-dashboard');
        }
      }
    });
  }

  detectGsiMappingsByRoleName() {
    this.onboardingFacadeService.gsiListByRoleName$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      /* istanbul ignore next */
      if (res?.result) {
        const solutionData = btoa(JSON.stringify({ bookId: res?.result?.bookId, gsiId: res?.result?.gsiId }));
        this.router.navigate(['cdui/transaction/view/' + solutionData]);
      } else {
        this.router.navigateByUrl('/cdui/mylibrary');
      }
    });
  }

  getRoles() {
    this.rolesFacade
      .getPublicRoles(this.tenantName)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore else */
        if (res) {
          this.rolesList = res;
          /*istanbul ignore else */
          if (this.rolesList?.length != 0) {
            this.showRoles = true;
          }
        }
      });
  }

  signup() {
    if (this.signupPayload.password == this.confirmPassword) {
      this.errorToaster = true;
      /*istanbul ignore else*/
      if (this.signupPayload.customID == '') {
        this.signupPayload.customID = null;
      }
      this.signupPayload.name = this.signupPayload.firstName + '' + this.signupPayload.lastName;
      this.signupPayload.realm = this.tenantName;
      if (this.defaultRole != null) {
        this.signupPayload.roles = this.rolesList?.filter((role: any) => {
          return role.name == this.defaultRole;
        });
      } else {
        this.signupPayload.roles = this.selectedRole ? [this.selectedRole] : [];
      }

      this.signupPayload.mobileNumber = this.signupPayload.mobileNumber ? this.signupPayload.mobileNumber : null;
      this.loader.show();
      this.authenticationService
        .selfSignup(this.signupPayload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((res: any) => {
          this.loader.hide();
          /* istanbul ignore else */
          if (res?.message == 'User created successfully') {
            this.alertService.showToaster('You ve successfully signed-up', '', 'success');
            // this.router.navigateByUrl('/login');
            /* istanbul ignore next */
            const userInfo = {
              username: this.signupPayload.email,
              password: this.signupPayload.password,
              client_id: this.signupPayload.realm,
              tenant: this.signupPayload.realm,
              remember: true,
            };
            this.validateLogin(userInfo);
          } else {
            this.alertService.showToaster('Sign-up failed!', 'Please try again', 'error');
          }
        });
    } else {
      this.alertService.showToaster('Passwords must be same', '', 'error');
      this.confirmPassword = '';
    }
  }
  ValidateEmail() {
    let email = this.signupPayload.email;
    let pattern = /^([a-zA-Z0-9.])+@[a-z]+\.(com|in)$/;
    if (pattern.test(email)) {
      this.emailValidator = true;
    } else {
      this.emailValidator = false;
    }
  }
  ValidateMobileNumber() {
    let number = this.signupPayload.mobileNumber;
    this.mobileNumberValidator = true;
    let pattern = /^[0-9]{10}$/;
    if (pattern.test(number)) {
      this.mobileNumberValidator = true;
    } else {
      this.mobileNumberValidator = false;
    }
  }
  ValidateConfirmPassword() {
    let password = this.signupPayload.password;
    let confirmPassword = this.confirmPassword;
    if (password === confirmPassword) {
      this.passwordCheck = true;
    } else {
      this.passwordCheck = false;
    }
  }
  passwordPolicy() {
    this.userFacade.getPasswordPolicyByAdmin(this.tenantName);
    this.userFacade.tenantPasswordPolicy$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
      this.password_info = res.result;
    });
  }
  signin() {
    this.router.navigate(['/login']);
  }

  sendOtp() {
    this.loader.show();
    this.authenticationService
      .otpSignup(this.signupMobile, this.tenantName)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          /* istanbul ignore next */
          if (res?.status == 200) {
            this.alertService.showToaster(`${res.message}`, '', 'success');
            this.activateOtp = true;
            this.timeLeft = 30;
            this.interval = setInterval(() => {
              if (this.timeLeft > 0) {
                this.timeLeft--;
              } else {
                clearInterval(this.interval);
              }
            }, 1000);
          } else {
            this.alertService.showToaster(`${res.message}`, 'Please try again', 'error');
          }
        },
        /* istanbul ignore next */
        (error: any) => {
          const { err } = error;
          this.alertService.showToaster(`${err}`, 'Please try again', 'error');
        }
      );
  }

  verifyOtp() {
    if (this.defaultRole != null) {
      this.verifyOtpPayload.roles = this.rolesList?.filter((role: any) => {
        return role.name == this.defaultRole;
      });
    } else {
      this.verifyOtpPayload.roles = this.selectedRole ? [this.selectedRole] : [];
    }
    // this.selectedRole = this.selectedRole ? [this.selectedRole] : [];
    // this.verifyOtpPayload.roles = this.selectedRole;
    this.loader.show();
    this.authenticationService
      .verifySignupOtp(this.signupMobile, this.tenantName, this.verifyOtpPayload.roles, this.signupOtp)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          /* istanbul ignore next */
          if ((res.status = 200)) {
            this.alertService.showToaster(`${res.message}`, 'Congratulations!', 'success');
            this.router.navigateByUrl('/login');
          } else {
            this.alertService.showToaster(`${res.message}`, 'Please try again', 'error');
          }
        },
        /* istanbul ignore next */
        (error: any) => {
          const { err } = error;
          this.alertService.showToaster(`${err}`, 'Please try again', 'error');
        }
      );
  }

  acceptInteger(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar: any;
    /* istanbul ignore next */
    if (event && event?.charCode) {
      /* istanbul ignore next */
      inputChar = String.fromCharCode(event?.charCode);
    }
    /* istanbul ignore next */
    if (event?.keyCode != 8 && !pattern.test(inputChar)) {
      /* istanbul ignore next */
      try {
        event.preventDefault();
      } catch (e) {
        console.log(e);
      }
    }
  }

  async validateLogin(data: any) {
    /* istanbul ignore next */
    await this.carnivalFacadeService
      ?.login(data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response: any) => {
        /* istanbul ignore next */
        if (response?.result && response?.status === 200) {
          this.userFacade.getTenantDesignData(data?.tenant);
          sessionStorage.setItem('TenantId', localStorage.getItem('TenantId'));
          localStorage.setItem('ActiveUserFlag', 'user');
          setTimeout(() => {
            this.userFacade.loadActiveUsers('login');
          }, 100);
          this.alertService.showToaster(response?.message, '', 'success');
        } else {
          this.alertService.showToaster(response?.message, '', 'error');
        }
      });
  }

  forgotPassword() {
    this.router.navigateByUrl('/forgotPass');
  }

  fetchSignupConfigurations() {
    const res =
      localStorage.getItem('signupProperties') !== null ? JSON.parse(localStorage.getItem('signupProperties')) : null;
    /*istanbul ignore else */
    if (res != null) {
      this.customLabel = res?.result?.customIdLabel;
      this.isRoleMandatory = res?.result?.roleMandatoryWhileSignUp;
      this.showPublicRoles = res?.result?.showPublicRoles;
      this.signupOptions = res?.result?.signupOptions;
      this.customIdGeneration = res?.result?.customIdGeneration;
      this.defaultRole = res?.result?.defaultPublicRole;
      // this.defaultPublicRole =res?.result?.defaultPublicRole!=null ? true:false;
      /* istanbul ignore else*/
      // if(res?.result?.defaultPublicRole){
      //   this.rolesList?.forEach((ele: any) => {
      //     if(ele.description == res?.result?.defaultPublicRole)
      //     {
      //       this.selectedRole =ele;
      //     }
      //   })
      // }
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}

import { A11yModule } from '@angular/cdk/a11y';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/@shared';
import { AuthModule } from '@app/auth';
import { MaterialModule } from '@app/material-module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { FooterComponent } from './footer/footer.component';
import { OutsideClickDirective } from './outsideclick.directive';
import { ShellRoutingModule } from './shell-routing.module';
import { ShellComponent } from './shell.component';
import { SideMenuComponent } from './side-menu/side-menu.component';
@NgModule({
  imports: [
    CommonModule,
    AuthModule,
    RouterModule,
    FormsModule,
    MaterialModule,
    SharedModule,
    OverlayModule,
    OverlayPanelModule,
    MatInputModule,
    MatIconModule,
    A11yModule,
    InfiniteScrollModule,
    ShellRoutingModule,
  ],
  declarations: [ShellComponent, FooterComponent, SideMenuComponent, OutsideClickDirective],
  exports: [SideMenuComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ShellModule {}

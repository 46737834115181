import { Component, OnInit, forwardRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { BarcodeFormat } from '@zxing/library';
import { BehaviorSubject } from 'rxjs';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl } from '@angular/forms';
import { AlertService } from '@app/@core/toaster/alert.service';
import { BrowserQRCodeReader } from '@zxing/browser';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FileUploadPreviewComponent } from '../file-upload-preview/file-upload-preview.component';
import { TranslatorService } from '@app/shell/translator.service';

@Component({
  selector: 'app-qrcode-scanner',
  templateUrl: './qrcode-scanner.component.html',
  styleUrls: ['./qrcode-scanner.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => QrcodeScannerComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => QrcodeScannerComponent),
      multi: true,
    },
  ],
})
export class QrcodeScannerComponent implements OnInit, ControlValueAccessor, OnDestroy {
  @Input() field: any;
  availableDevices: MediaDeviceInfo[];
  deviceCurrent: MediaDeviceInfo;
  formatsEnabled: BarcodeFormat[] = [
    BarcodeFormat.CODE_128,
    BarcodeFormat.DATA_MATRIX,
    BarcodeFormat.EAN_13,
    BarcodeFormat.QR_CODE,
    BarcodeFormat.CODE_39,
    BarcodeFormat.ITF,
    BarcodeFormat.PDF_417,
    BarcodeFormat.UPC_A,
    BarcodeFormat.UPC_E,
    BarcodeFormat.UPC_EAN_EXTENSION,
  ];

  hasDevices: boolean;
  hasPermission: boolean;
  qrResultString: string;
  torchEnabled = false;
  torchAvailable$ = new BehaviorSubject<boolean>(false);
  tryHarder = false;
  deviceSelected: string;
  codeReader = new BrowserQRCodeReader();
  files: any = [];
  labels: any;
  unSubscribe = new Subject();
  isRemoveAll: boolean = false;
  @ViewChild('fileInput') fileInput: any;
  constructor(
    private alertService: AlertService,
    private translator: TranslatorService,
    private dialogService: MatDialog
  ) {}

  ngOnInit(): void {
    this.detectLanguageChange();
  }

  /**
   * Detects language change
   */
  detectLanguageChange() {
    this.translator.languageLables$.pipe(takeUntil(this.unSubscribe)).subscribe((res: any) => {
      this.labels = res;
    });
  }

  /**
   * Decodes img code
   * @param imgData
   */
  decodeImgCode(imgData: string) {
    const controls = this.codeReader.decodeFromImageUrl(imgData).then((decodedData: any) => {
      if (decodedData) {
        this.alertService.showToaster('Code decoded succsesfully!', '', 'success');
        this.propagateChange(decodedData.text);
      }
    });
  }

  /**
   * on file drop handler
   */
  onFileDropped(files: any) {
    this.files = files;
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files: any) {
    this.files = files;
    this.getBase64(files[0]).then((data: any) => {
      this.decodeImgCode(data);
    });
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.files.splice(index, 1);
  }

  /**
   * Determines whether preview click on
   * @param file file which is to be previewed
   * @param index index of the file in files
   */
  onPreviewClick(file: any, index: number) {
    this.files[index]['mimeType'] = this.files[index].mimeType || this.files[index].type;
    this.dialogService.open(FileUploadPreviewComponent, {
      height: '400px',
      width: '700px',
      data: this.files[index],
      panelClass: 'file-upload-preview-dialog',
    });
  }

  /**
   * Gets base64 from image file url
   * @param file
   * @returns
   */
  getBase64(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  /**
   * Determines whether cameras found on
   * @param devices
   */
  onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.availableDevices = devices;
    this.hasDevices = Boolean(devices && devices.length);
    /*istanbul ignore else */
    if (this.hasDevices) {
      this.deviceCurrent = this.availableDevices[0];
    }
  }

  /**
   * Determines whether code result on
   * @param resultString
   */
  onCodeResult(resultString: string) {
    /*istanbul ignore else */
    if (resultString) {
      this.qrResultString = resultString;
      this.alertService.showToaster('Code scanned succsesfully!', '', 'success');
      this.propagateChange(this.qrResultString);
    }
  }

  /**
   * Determines whether has permission on
   * @param hasPermission
   */
  onHasPermission(hasPermission: boolean) {
    this.hasPermission = hasPermission;
  }

  /**
   * Determines whether torch compatible on
   * @param isCompatible
   */
  onTorchCompatible(isCompatible: boolean): void {
    this.torchAvailable$.next(isCompatible || false);
  }

  /**
   * Toggles torch
   */
  toggleTorch(): void {
    this.torchEnabled = !this.torchEnabled;
  }

  /**
   * Toggles try harder
   */
  toggleTryHarder(): void {
    this.tryHarder = !this.tryHarder;
  }

  /**
   * Determines whether device change on
   * @param device
   * @returns
   */
  onDeviceChange(device: MediaDeviceInfo) {
    /* istanbul ignore next */
    const selectedStr = device?.deviceId || '';
    if (this.deviceSelected === selectedStr) {
      return;
    }
    this.deviceSelected = selectedStr;
    this.deviceCurrent = device || undefined;
  }

  /**
   * Writes value
   * @param value
   */
  writeValue(value: any) {}

  /**
   * Propagate change of qrcode scanner component
   */
  propagateChange = (_: any) => {};

  /**
   * Registers on change
   * @param fn
   */
  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  /**
   * Registers on touched
   */
  registerOnTouched() {}

  /**
   * Validates qrcode scanner component
   * @param { value }
   * @returns
   */
  validate({ value }: FormControl) {
    let inNotValid;
    /* istanbul ignore next */
    if (this.field?.isRequired) {
      inNotValid = this.qrResultString || (this.files && this.files.length > 0) ? false : true;
    } else {
      inNotValid = false;
    }
    return !inNotValid ? null : { valid: false };
  }

  ngOnDestroy() {}
}

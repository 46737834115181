import { Injectable, Injector } from '@angular/core';
import { Credentials } from '@app/auth';
import { Observable, of, throwError } from 'rxjs';

import { HttpHeaders, HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { AlertService } from '../toaster/alert.service';
import { Router } from '@angular/router';
import { LoaderService } from '@app/@shared/services/loader/loader.service';
import { CduiBaseService } from '@app/auth/cdui-base.service';

export interface ReLoginContext {
  tenant: string;
  refresh_token: string;
  client_id: string;
  remember?: boolean;
  username: string;
}

const credentialsKey = 'credentials';
const onboardCredentialsKey = 'onboardCredentials';

@Injectable({
  providedIn: 'root',
})
export class ReauthenticationService extends CduiBaseService {
  constructor(
    public injector: Injector,
    private http: HttpClient,
    private alertService: AlertService,
    private router: Router,
    private loader: LoaderService
  ) {
    super(injector);
  }

  reLogin(context: ReLoginContext, remember: boolean, isOnboarding?: boolean): Observable<any> {
    const loginApiUrl = `${this.api?.refreshTokenGeneration}refresh_token=${context?.refresh_token}&tenantName=${context?.tenant}&clientId=${context?.client_id}`;

    /* istanbul ignore next */
    return this.http.post(loginApiUrl, {}).pipe(
      map((res: any) => {
        const data: Credentials = {
          username: context.username,
          token: res.result.access_token,
          client_id: context?.client_id,
          refresh_token: res?.result.refresh_token,
          expires_in: res.result.expires_in,
          last_loginTime: new Date(),
          tenant: context?.tenant,
        };
        if (isOnboarding) {
          this.setOnboardingCredentials(data, remember);
        } else {
          this.setCredentials(data, remember);
        }
        return of(true);
      }),
      catchError((error: any) => {
        this.alertService.showToaster('Unauthorized', 'Invalid Login', 'error');
        console.log('Re-Auth Logout');

        this.router.navigate(['/login']);
        sessionStorage.clear();
        localStorage.clear();
        this.loader.hide();
        return throwError(error);
      })
    );
  }

  setCredentials(credentials?: Credentials, remember?: boolean) {
    const _credentials = credentials || null;
    if (credentials) {
      const storage = remember ? localStorage : sessionStorage;
      storage.setItem(credentialsKey, JSON.stringify(credentials));
    } else {
      sessionStorage.removeItem(credentialsKey);
      localStorage.removeItem(credentialsKey);
    }
  }

  setOnboardingCredentials(credentials?: Credentials, remember?: boolean) {
    if (credentials) {
      const storage = remember ? localStorage : sessionStorage;
      storage.setItem(onboardCredentialsKey, JSON.stringify(credentials));
    } else {
      sessionStorage.removeItem(onboardCredentialsKey);
      localStorage.removeItem(onboardCredentialsKey);
    }
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material-module';
import { AuthRoutingModule } from './auth-routing.module';
import { SharedModule } from '@app/@shared';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { ForgotCustomerIdComponent } from './forgot-customerid/forgot-customerid.component';
import { SelfOnboardingSignupComponent } from './self-onboarding-signup/self-onboarding-signup.component';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { OtpValidationComponent } from './otp-validation/otp-validation.component';

export function playerFactory() {
  return player;
}
@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    AuthRoutingModule,
    SharedModule,
    LottieModule.forRoot({ player: playerFactory }),
  ],
  declarations: [
    ForgotpasswordComponent,
    SelfOnboardingSignupComponent,
    OtpValidationComponent,
    ForgotCustomerIdComponent,
  ],
})
export class AuthModule {}

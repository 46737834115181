import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ChatBotStore, ChatBotStoreActions } from '@app/@shared/stores/chatbot-state';
import { CduiBaseService } from '@app/auth/cdui-base.service';
import { Stomp } from '@stomp/stompjs';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as SockJS from 'sockjs-client';

@Injectable({
  providedIn: 'root',
})
export class LivechatEndpointService extends CduiBaseService {
  /* istanbul ignore next */
  baseHttpsUrl: string = this.api?.paasLivechatUrl;
  //baseHttpsUrl = "https://qachatbot.nslhub.com/livechat";
  sockJsSocket: any;
  public chatMessage: any[] = [];
  registerData: Registration;
  tokenData: string;
  isShown: boolean;
  read: boolean = true;
  ngUnsubscribe = new Subject();

  constructor(injector: Injector, private chatBotStore: ChatBotStore, private http: HttpClient) {
    super(injector);
  }

  setChatBotState(body: any, action: any) {
    this.chatBotStore.setStateData(body, action);
  }

  public connectSockJS(token: string, roomId: any, userInfo: any, register: Registration) {
    this.tokenData = token;
    this.registerData = register;
    this.sockJsSocket = Stomp.over(new SockJS(`${this.baseHttpsUrl}`));
    console.log('Register:', register);
    /* istanbul ignore next */
    this.sockJsSocket?.connect({}, (frame?: any) => {
      this.sockJsSocket?.subscribe(`/topic/connectResponse/${this.tokenData}`, (message: any) => {
        let registere = this.registerData;
        console.log('Connected:', JSON.parse(message?.body));
        console.log('Register:', registere);
        registere.department = 'nslhub';
        this.sockJsSocket?.publish({
          destination: '/chat/register',
          body: JSON.stringify(registere),
        });
      });
      this.sockJsSocket?.subscribe(`/topic/configurationResponse/${this.tokenData}`, (message: any) => {
        console.log('Configuration:', JSON.parse(message?.body));
      });
      this.sockJsSocket?.subscribe(`/topic/registerResponse/${this.tokenData}`, (message: any) => {
        console.log('Registered:', JSON.parse(message?.body));
      });
      this.sockJsSocket?.subscribe(`/topic/messageResponse/${this.tokenData}`, (message: any) => {
        const datAndTime = new Date();
        let data = JSON.parse(message?.body);
        console.log('MessageResponse:', data, datAndTime);
        if (this.isShown === false) {
          this.read = false;
        }
        data = { ...data, read: this.read };
        if (data?.user && data?.user !== userInfo?.name) {
          this.setChatBotState(data, ChatBotStoreActions.UpdateAnswer);
        }
      });
      this.sockJsSocket?.subscribe(`/topic/messageEvent/${this.tokenData}`, (message: any) => {
        const datAndTime = new Date();
        const data = JSON.parse(message?.body);
        console.log('MessageEvent:', data, datAndTime);
      });
      console.log(frame);
    });
    this.register(token, roomId);
  }

  public register(token: string, roomId: any) {
    /* istanbul ignore next */
    const data: TokenAndId = {
      token: token,
      roomId: roomId?.value,
    };
    console.log('Connect:', data);
    /* istanbul ignore next */
    setTimeout(() => {
      this.sockJsSocket?.publish({
        destination: '/chat/connect',
        body: JSON.stringify(data),
      });
    }, 2000);
  }

  public sendMessage(token: string, roomId: any, message: string) {
    /* istanbul ignore next */
    let data: Message = {
      token: token,
      roomID: roomId?.value,
      message: message,
    };
    console.log('Message:', data);
    /* istanbul ignore next */
    this.sockJsSocket?.publish({
      destination: '/chat/message',
      body: JSON.stringify(data),
    });
  }

  public disconnect(token: string, roomId: any) {
    /* istanbul ignore next */
    let data: Dissconnect = {
      token: token,
      roomId: roomId?.value,
    };
    /* istanbul ignore next */
    this.sockJsSocket?.publish({
      destination: '/chat/disconnect',
      body: JSON.stringify(data),
    });
  }

  public transcript(token: string, roomId: any, userInfo: any) {
    /* istanbul ignore next */
    let data: Transcript = {
      token: token,
      roomId: roomId?.value,
      email: userInfo?.email,
    };
    /* istanbul ignore next */
    this.sockJsSocket?.publish({
      destination: '/chat/transcript',
      body: JSON.stringify(data),
    });
  }

  /* istanbul ignore next */
  public sendFeedback(data: IFeedback) {
    return this.http?.post(this.api?.feedbackLivechat, data);
  }

  public changeIsShown(flag: boolean) {
    this.isShown = flag;
  }

  addWelcomeMessage(message: string, roomId: any) {
    /* istanbul ignore next */
    let msg: any = {
      question: null,
      ans: {
        text: message,
        data: null,
      },
      document: null,
      score: null,
      topic: null,
      alternate: null,
      media: {
        image: [],
        video: [],
        audio: [],
      },
      response_type: 'text',
      trace: null,
      probe_mode: false,
      ans_format: null,
      timestamp: null,
      tenant_info: null,
      roomId: roomId?.value,
      user: 'yantr',
      button: null,
      url: null,
      isFaqOrAtq: false,
    };
    this.setChatBotState(msg, ChatBotStoreActions.UpdateAnswer);
  }
  /* istanbul ignore next */
  setDepartment(data: any) {
    return this.http?.post(this.api?.setDepartment, data);
  }
  /* istanbul ignore next */
  fileUpload(formdata: FormData, roomId: string) {
    return this.http?.post(`${this.api?.chatbotFileUpload}/${roomId}`, formdata);
  }

  fileDownload(fileUrl: string) {
    console.log(fileUrl, this.environment?.xAuthToken);
    console.log(this.environment?.xUserId);
    const headers = new HttpHeaders()
      .set('X-Auth-Token', this.environment?.xAuthToken)
      .set('X-User-Id', this.environment?.xUserId);
    this.http
      ?.get(`${fileUrl}`, { headers: headers })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        console.log(res);
      });
  }
}

export interface TokenAndId {
  token: any;
  roomId: string;
}

export interface Registration {
  userName: string;
  email: string;
  department?: string;
  token: any;
  jwtToken: string;
}

export interface Message {
  token: string;
  roomID: string;
  message: string;
}

export interface Dissconnect {
  token: string;
  roomId: string;
}

export interface Transcript {
  token: string;
  roomId: string;
  email: string;
}

export interface IFeedback {
  token: string;
  roomId: string;
  rating: number;
  comments: string;
  jwtToken: string;
}

import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { LoaderService } from '@app/@shared/services/loader/loader.service';
import { OnboardingEndpointService } from './onboarding-endpoint.service';
import { AlertService } from '@app/@core/toaster/alert.service';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OnboardingFacadeService implements OnDestroy {
  private ngUnsubscribe = new Subject();

  identityProviderData = new BehaviorSubject('');
  identityProviderData$ = this.identityProviderData.asObservable();

  public allRoleList = new Subject();
  allRoleList$ = this.allRoleList.asObservable();

  public SolutionTypeList = new Subject();
  SolutionTypeList$ = this.SolutionTypeList.asObservable();

  private updateUserData = new Subject();
  updateUserData$ = this.updateUserData.asObservable();

  public deptList = new Subject();
  deptList$ = this.deptList.asObservable();

  updateRoleData = new Subject();
  updateRoleData$ = this.updateRoleData.asObservable();

  companyDetails = new Subject();
  companyDetails$ = this.companyDetails.asObservable();

  changeScreenback = new Subject();
  changeScreenback$ = this.changeScreenback.asObservable();

  companyRegistrationComplete = new Subject();
  companyRegistrationComplete$ = this.companyRegistrationComplete.asObservable();

  private userData = new BehaviorSubject('');
  userData$ = this.userData.asObservable();

  collaboratorData = new BehaviorSubject('');
  collaboratorData$ = this.collaboratorData.asObservable();

  paginationChange = new Subject();
  paginationChange$ = this.paginationChange.asObservable();

  // private selectedSolutionType = new BehaviorSubject('');
  // selectedSolutionType$ = this.selectedSolutionType.asObservable();

  allUserList = new BehaviorSubject('');
  allUserList$ = this.allUserList.asObservable();

  private deleteUserSubject = new BehaviorSubject('');
  deleteUserSubject$ = this.deleteUserSubject.asObservable();

  usersCountSubject = new Subject();
  usersCount$ = this.usersCountSubject.asObservable();

  updateSuccessful = new Subject();
  updateSuccessful$ = this.updateSuccessful.asObservable();

  rolesByLabels = new Subject();
  rolesByLabels$ = this.rolesByLabels.asObservable();

  gsiListByRoleName = new Subject();
  gsiListByRoleName$ = this.gsiListByRoleName.asObservable();

  tabChangeTo = new BehaviorSubject(0);
  tabChangeTo$ = this.tabChangeTo.asObservable();

  onboarding_capture_requirements_solutionListByIndustrySubject = new BehaviorSubject([]);
  onboarding_capture_requirements_solutionListByIndustry$ = this.onboarding_capture_requirements_solutionListByIndustrySubject.asObservable();

  onboarding_capture_requirements_solutionListByBusinessFunctionSubject = new BehaviorSubject([]);
  onboarding_capture_requirements_solutionListByBusinessFunction$ = this.onboarding_capture_requirements_solutionListByBusinessFunctionSubject.asObservable();

  onboarding_select_solutions_solutionListByIndustrySubject = new BehaviorSubject([]);
  onboarding_select_solutions_solutionListByIndustry$ = this.onboarding_select_solutions_solutionListByIndustrySubject.asObservable();

  onboarding_select_solutions_solutionListByBusinessFunctionSubject = new BehaviorSubject([]);
  onboarding_select_solutions_solutionListByBusinessFunction$ = this.onboarding_select_solutions_solutionListByBusinessFunctionSubject.asObservable();

  onboarding_select_solutions_currentScreen = new Subject();
  onboarding_select_solutions_currentScreen$ = this.onboarding_select_solutions_currentScreen.asObservable();

  onboarding_select_solutions_savedBooksAndSolutions = new Subject();
  onboarding_select_solutions_savedBooksAndSolutions$ = this.onboarding_select_solutions_savedBooksAndSolutions.asObservable();

  flowType = new BehaviorSubject('');
  flowType$ = this.flowType.asObservable();

  bdpDetails = new BehaviorSubject('');
  bdpDetails$ = this.bdpDetails.asObservable();

  freelancerDetails = new BehaviorSubject('');
  freelancerDetails$ = this.freelancerDetails.asObservable();

  roleObj = new BehaviorSubject(null);
  roleObj$ = this.roleObj.asObservable();

  closeDialogBox = new BehaviorSubject(false);
  closeDialogBox$ = this.closeDialogBox.asObservable();

  betTypeForbetList = new BehaviorSubject('');
  betTypeForbetList$ = this.betTypeForbetList.asObservable();
  onboardSignupFlag = new Subject();
  onboardSignupFlag$ = this.onboardSignupFlag.asObservable();

  individualDeveloperEmail = new BehaviorSubject(false);
  individualDeveloperEmail$ = this.individualDeveloperEmail.asObservable();

  private wrongInputField = new Subject();
  wrongInputField$ = this.wrongInputField.asObservable();

  private disableAdapterTraining = new Subject();
  disableAdapterTraining$ = this.disableAdapterTraining.asObservable();

  constructor(
    private onboardingEndpointService: OnboardingEndpointService,
    public alertService: AlertService,
    public loader: LoaderService
  ) {}

  setIdentityProviderData(data: any) {
    this.identityProviderData.next(data);
  }

  // getOnboardingSolution() {
  //   this.SolutionTypeList.next(solutionType);
  // }

  // updateselectedSolutionType(data: any) {
  //   this.selectedSolutionType.next(data);
  // }

  FetchPlans() {
    return this.onboardingEndpointService.FetchPlans();
  }

  unsubscribePlan(postData: any) {
    this.loader.show();
    this.onboardingEndpointService
      .unsubscribePlan(postData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.alertService.showToaster(res.message, '', 'success');
      });
  }

  inviteClient(postData: any) {
    this.loader.show();
    this.onboardingEndpointService
      .inviteClient(postData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.alertService.showToaster(res.message, '', 'success');
      });
  }

  createUser(postData: any, selctedRoles: any) {
    this.loader.show();
    this.onboardingEndpointService
      .createUser(postData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          /* istanbul ignore next*/
          if (res?.status === 'OK') {
            this.loader.hide();
            this.alertService.showToaster(res.message, '', 'success');
            this.userData.next(res);
            this.updateUser(
              {
                email: res.result.email,
                isEnabled: true,
                mobileNumber: res.result.mobileNumber,
                name: res.result.name,
                userPreferences: { language: 'EN' },
                roles: selctedRoles,
              },
              res.result.rdfUserId
            );
          }
        },
        /* istanbul ignore next*/
        (error: any) => {
          const errorFields = ['Email', ' password', 'username'];
          errorFields.forEach((el: any) => {
            if (error?.error?.message.includes(el)) {
              this.alertService.showToaster(error.message, '', 'failure');
              this.wrongInputField.next(el);
            }
          });
        }
      );
  }

  setWrongInputField(el: any) {
    this.wrongInputField.next(el);
  }

  createNewUser(postData: any) {
    this.loader.show();
    this.onboardingEndpointService
      .createUser(postData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.alertService.showToaster(res.message, '', 'success');
        this.userData.next(res);
      });
  }

  collaboratorAssign(email: any) {
    this.loader.show();
    this.onboardingEndpointService
      .collaboratorAssign(email)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        /* istanbul ignore else*/
        if (res) {
          this.alertService.showToaster(res.message, '', 'success');
          this.collaboratorData.next(res);
        }
      });
  }

  setUpdateUserData(data: any) {
    this.updateUserData.next(data);
  }

  setUserDataforNewUser(data: any) {
    this.userData.next(data);
  }

  setUpdateRoleData(data: any) {
    this.updateRoleData.next(data);
  }

  updateUser(updateData: any, userId: any, isUpdatePassword?: boolean) {
    this.loader.show();
    this.onboardingEndpointService
      .updateUser(updateData, userId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          /* istanbul ignore next*/
          const msg = isUpdatePassword ? 'Updated password Successfully' : 'Updated User Successfully';
          this.alertService.showToaster(msg, '', 'success');
          this.userData.next(res);
        },
        /* istanbul ignore next*/
        (error) => {
          console.log(error);
          this.userData.next(error);
        }
      );
  }

  getRoles(pageNumber: number, pageSize: number, data: any) {
    this.loader.show();
    this.onboardingEndpointService
      .getRoleList(pageNumber, pageSize, data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.alertService.showToaster(res.message, '', 'success');
        this.allRoleList.next(res);
      });
  }

  getDepartmentsList() {
    this.loader.show();
    this.onboardingEndpointService
      .getdeptList()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore next*/
        if (res) {
          this.loader.hide();
          this.deptList.next(res);
          console.log(res);
        }
      });
  }

  loadPaginateUsersbySearchforTenantAdmin(pageNumber: number, pageSize: number, searchString: string) {
    this.loader.show();
    this.onboardingEndpointService
      .getPaginateUsersTenantAdmin(pageNumber, pageSize, searchString)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.allUserList.next(res);
      });
  }

  deleteUser(deleteUserData: any, userId: any) {
    this.loader.show();
    this.onboardingEndpointService
      .deleteUser(deleteUserData, userId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          this.alertService.showToaster(res.message, '', 'success');
          this.deleteUserSubject.next(res);
        },
        /* istanbul ignore next*/
        (error) => {
          console.log(error);
          this.deleteUserSubject.next(error);
        }
      );
  }

  loadUsersCount(selectedEnv?: any) {
    this.loader.show();
    this.onboardingEndpointService
      .loadUsersCount(selectedEnv)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.usersCountSubject.next(res);
      });
  }

  updateUserAsTenantAdmin(updateData: any, userId: any, newPassword: string) {
    this.loader.show();
    this.onboardingEndpointService
      .updateUserAsTenantAdmin(updateData, userId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.loader.hide();
        /* istanbul ignore next*/
        if (res) {
          this.alertService.showToaster('Updated User Successfully', '', 'success');
          this.updateUserPassword({ name: updateData.name, password: newPassword });
        }
      });
  }

  updateUserPassword(updateData: any) {
    this.loader.show();
    this.onboardingEndpointService
      .updateUserPassword(updateData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        /* istanbul ignore next*/
        if (res) {
          this.alertService.showToaster(res.message, '', 'success');
          this.updateSuccessful.next(true);
        }
      });
  }

  loadPaginateRoles(pageNumber: number, pageSize: number, searchString: string, isActive?: boolean) {
    this.loader.show();
    this.onboardingEndpointService
      .getPaginateRoles(pageNumber, pageSize, searchString, isActive)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.allRoleList.next(res);
        /* istanbul ignore next*/
        if (!res) {
          this.alertService.showToaster('There are no matching records found', '', 'error');
        }
      });
  }

  importUserFromCSV(postData: any, env?: any) {
    this.loader.show();
    this.onboardingEndpointService
      .importUserFromCSV(postData, env)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.alertService.showToaster(res.message, '', 'success');
      });
  }

  update_onboarding_capture_requirements_solutionList(type: string, data: any) {
    if (type === 'Industry') {
      this.onboarding_capture_requirements_solutionListByIndustrySubject.next(data);
    } else if (type === 'Business Function') {
      this.onboarding_capture_requirements_solutionListByBusinessFunctionSubject.next(data);
    }
  }

  update_onboarding_select_solutions_solutionList(type: string, data: any) {
    if (type === 'Industry') {
      this.onboarding_select_solutions_solutionListByIndustrySubject.next(data);
    } else if (type === 'Business Function') {
      this.onboarding_select_solutions_solutionListByBusinessFunctionSubject.next(data);
    }
  }

  setOnboarding_select_solutions_currentScreen(screen: number) {
    this.onboarding_select_solutions_currentScreen.next(screen);
  }

  fetchStartOnboardCompanyProfileWithRegistrationNumber(registrationNumber: any) {
    this.loader.show();
    this.onboardingEndpointService
      .startOnboardWithRegistrationNumber(registrationNumber)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.alertService.showToaster('fetched company details successfully!', '', 'success');
        this.companyDetails.next(res);
      });
  }

  startOnboardwithCompanyDetails(data: any) {
    this.loader.show();
    this.onboardingEndpointService
      .startOnboardwithCompanyDetails(data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.alertService.showToaster('completed company registration successfully!', '', 'success');
        this.companyRegistrationComplete.next(res);
      });
  }

  saveSelectedBooksAndSolutions(data: any) {
    this.loader.show();
    this.onboardingEndpointService
      .postSelectedBooksAndSolutions(data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.onboarding_select_solutions_savedBooksAndSolutions.next(res);
      });
  }

  changeScreen() {
    this.changeScreenback.next(false);
  }

  getRolesByLabels(label: any) {
    this.loader.show();
    this.onboardingEndpointService
      .getRolesByLabels(label)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.rolesByLabels.next(res);
      });
  }

  tabChangetoUser() {
    this.tabChangeTo.next(1);
  }

  paginationChanged() {
    this.paginationChange.next(true);
  }

  saveBDPInfo(body: any) {
    this.loader.show();
    this.onboardingEndpointService
      .saveBDPInfo(body)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        /*istanbul ignore else*/
        if (res) {
          this.bdpDetails.next(res);
          this.alertService.showToaster('BDP details Successfully saved!', '', 'success');
        }
      });
  }

  saveFreelancerDetails(body: any) {
    this.loader.show();
    this.onboardingEndpointService
      .saveFreelancerDetails(body)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        /*istanbul ignore else*/
        if (res) {
          this.freelancerDetails.next(res);
          this.alertService.showToaster('Freelancer details Successfully saved!', '', 'success');
        }
      });
  }

  sendRoleObject(id: any) {
    this.roleObj.next(id);
  }

  closeDialog() {
    this.closeDialogBox.next(true);
  }

  setBetTypeForbetList(type: string) {
    this.betTypeForbetList.next(type);
  }

  onSaveTenantConfiguration(data: any) {
    this.loader.show();
    this.onboardingEndpointService
      .onSaveTenantConfiguration(data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        /* istanbul ignore else */
        if (res) {
          /* istanbul ignore next */
          this.alertService.showToaster(res?.message, '', 'success');
        }
      });
  }

  getGsiMappingsByRoleName(roleName: any) {
    this.loader.show();
    this.onboardingEndpointService
      .getGsiMappingsByRoleName(roleName)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          this.gsiListByRoleName.next(res);
        },
        (error: any) => {
          this.loader.hide();
          /* istanbul ignore next */
          this.gsiListByRoleName.next(error?.error);
        }
      );
  }

  setOnboardingSignup() {
    this.onboardSignupFlag.next(true);
  }

  setIndividualDeveloperEmail() {
    this.individualDeveloperEmail.next(true);
  }

  onDisableAdapterTraining(data: any) {
    this.disableAdapterTraining.next(data);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}

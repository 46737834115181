import { HttpClient } from '@angular/common/http';
import { Injectable, Injector, OnDestroy } from '@angular/core';
import { betMapper } from '@app/@core/generic-functions';
import { changeUnitMapper } from '@app/@core/mappers/change-unit/cu-mapper';
import { gsiDeMapper } from '@app/@core/mappers/gsi/gsi-demapper';
import { gsiMapper } from '@app/@core/mappers/gsi/gsi-mapper';
import { AlertService } from '@app/@core/toaster/alert.service';
import { Librarytypes } from '@app/@shared/enums/library-types.enum';
import { LoaderService } from '@app/@shared/services/loader/loader.service';
import { CduiBaseService } from '@app/auth/cdui-base.service';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NodeGsiFacadeService extends CduiBaseService implements OnDestroy {
  private ngUnsubscribe = new Subject();

  private gsiSuggestions = new Subject();
  gsiSuggestions$ = this.gsiSuggestions.asObservable();

  sopConnectionData = new BehaviorSubject('');
  sopConnectionData$ = this.sopConnectionData.asObservable();

  gsiDraftResponse = new Subject();
  gsiDraftResponse$ = this.gsiDraftResponse.asObservable();

  sendSentenceTags = new Subject();
  sendSentenceTags$ = this.sendSentenceTags.asObservable();

  gsiFinishResponse = new Subject();
  gsiFinishResponse$ = this.gsiFinishResponse.asObservable();

  gsiEmbFinishResponse = new Subject();
  gsiEmbFinishResponse$ = this.gsiEmbFinishResponse.asObservable();

  public multigsiDraftResponse = new Subject();
  multigsiDraftResponse$ = this.multigsiDraftResponse.asObservable();

  public gsiList = new Subject();
  gsiList$ = this.gsiList.asObservable();

  public bookList = new Subject();
  bookList$ = this.bookList.asObservable();

  public getAgents = new Subject();
  getAgents$ = this.getAgents.asObservable();

  cuDetailsByGsiId = new Subject();
  cuDetailsByGsiId$ = this.cuDetailsByGsiId.asObservable();

  private updateGSIData = new Subject();
  updateGSIData$ = this.updateGSIData.asObservable();

  private solutionDetailsGsi = new Subject();
  solutionDetailsGsi$ = this.solutionDetailsGsi.asObservable();

  private nestedCuDetailsByGsiId = new Subject();
  nestedCuDetailsByGsiId$ = this.nestedCuDetailsByGsiId.asObservable();

  public CuIdForAgent: any;

  selectedAgentinCU = new Subject();
  selectedAgentinCU$ = this.selectedAgentinCU.asObservable();

  tempGSI = new Subject();
  tempGSI$ = this.tempGSI.asObservable();

  private currentComponentConf = new Subject();
  currentComponentConf$ = this.currentComponentConf.asObservable();

  private expandGraphNode = new BehaviorSubject('');
  expandGraphNode$ = this.expandGraphNode.asObservable();

  private expandEntities = new Subject();
  expandEntities$ = this.expandEntities.asObservable();

  expandGSIEntities = new Subject();
  expandGSIEntities$ = this.expandGSIEntities.asObservable();

  private openAttribute = new Subject();
  openAttribute$ = this.openAttribute.asObservable();

  public openMyCollection = new Subject();
  openMyCollection$ = this.openMyCollection.asObservable();

  private cloneResponseGsi = new Subject();
  cloneResponseGsi$ = this.cloneResponseGsi.asObservable();

  public nestedGsiData = new Subject();
  nestedGsiData$ = this.nestedGsiData.asObservable();

  private usersSubject = new Subject();
  usersSubject$ = this.usersSubject.asObservable();

  private gsiRoles = new Subject();
  gsiRoles$ = this.gsiRoles.asObservable();

  private updateGsiData = new Subject();
  updateGsiData$ = this.updateGsiData.asObservable();

  private bookSubject = new Subject();
  bookSubject$ = this.bookSubject.asObservable();

  private publishedGsi = new Subject();
  publishedGsi$ = this.publishedGsi.asObservable();

  private savedGsiInBook = new Subject();
  savedGsiInBook$ = this.savedGsiInBook.asObservable();

  private addBookFromLibrarySubject = new Subject();
  addBookFromLibrarySubject$ = this.addBookFromLibrarySubject.asObservable();

  cusList = new BehaviorSubject('');
  cuList$ = this.cusList.asObservable();

  private dcdChangeSubject = new Subject();
  dcdChange$ = this.dcdChangeSubject.asObservable();

  private reservedActionEntities = new Subject();
  reservedActionEntities$ = this.reservedActionEntities.asObservable();

  changeNSLComponentSubject = new Subject();
  changeNSLComponentSubject$ = this.changeNSLComponentSubject.asObservable();

  saveSlotItemProperties = new Subject();
  saveSlotItemProperties$ = this.saveSlotItemProperties.asObservable();
  updateEntityInNSL = new BehaviorSubject('');
  updateEntityInNSL$ = this.updateEntityInNSL.asObservable();

  public gsiNode = new Subject();
  gsiNode$ = this.gsiNode.asObservable();
  private draftCu = new Subject();
  draftCu$ = this.draftCu.asObservable();

  private draftEntity = new Subject();
  draftEntity$ = this.draftEntity.asObservable();

  private viewLibraryData = new Subject();
  viewLibraryData$ = this.viewLibraryData.asObservable();

  cuDetailsByGsiIdforSOP = new Subject();
  cuDetailsByGsiIdforSOP$ = this.cuDetailsByGsiIdforSOP.asObservable();

  private recursiveExpression = new Subject();
  recursiveExpression$ = this.recursiveExpression.asObservable();

  embededGsi = new Subject();
  embededGsi$ = this.embededGsi.asObservable();

  openArWindow = new BehaviorSubject({});
  openArWindow$ = this.openArWindow.asObservable();

  closeArWindow = new BehaviorSubject({});
  closeArWindow$ = this.closeArWindow.asObservable();

  private embededDraft = new Subject();
  embededDraft$ = this.embededDraft.asObservable();

  private closeRightPanelSubject = new Subject();
  closeRightPanelSubject$ = this.closeRightPanelSubject.asObservable();

  updateGSIDataForNSLtoNode = new BehaviorSubject('');
  updateGSIDataForNSLtoNode$ = this.updateGSIDataForNSLtoNode.asObservable();

  updateDcdInConfData = new Subject();
  updateDcdInConfData$ = this.updateDcdInConfData.asObservable();

  gsiCompositeExtraction = new Subject();
  gsiCompositeExtraction$ = this.gsiCompositeExtraction.asObservable();

  gsiDetails = new Subject();
  gsiDetails$ = this.gsiDetails.asObservable();

  entityinGsi = new BehaviorSubject(false);
  entityinGsi$ = this.entityinGsi.asObservable();

  dummy: any;
  bookIDb2c: any;
  setParallelSubject = new BehaviorSubject('');
  setParallelSubject$ = this.setParallelSubject.asObservable();

  private activeGsi = new ReplaySubject(1);
  activeGsi$ = this.activeGsi.asObservable();

  private changeGsi = new Subject();
  changeGsi$ = this.changeGsi.asObservable();

  private convertedGsi = new Subject();
  convertedGsi$ = this.convertedGsi.asObservable();

  private changedEmbedded = new Subject();
  changedEmbedded$ = this.changedEmbedded.asObservable();

  totalErrorsCount = new Subject();
  totalErrorsCount$ = this.totalErrorsCount.asObservable();

  menuPanelSelection = new Subject();
  menuPanelSelection$ = this.menuPanelSelection.asObservable();

  private updatedEmbeddedGsi = new Subject();
  updatedEmbeddedGsi$ = this.updatedEmbeddedGsi.asObservable();

  private currentEmbComponent = new Subject();
  currentEmbComponent$ = this.currentEmbComponent.asObservable();

  openParallelComponentSubject = new Subject();
  openParallelComponentSubject$ = this.openParallelComponentSubject.asObservable();

  nestedCuExpansion = new Subject();
  nestedCuExpansion$ = this.nestedCuExpansion.asObservable();

  isExpansionClosed = new Subject();
  isExpansionClosed$ = this.isExpansionClosed.asObservable();

  private countOfEmbGsi = new Subject();
  countOfEmbGsi$ = this.countOfEmbGsi.asObservable();

  private updateEMBGsiData = new Subject();
  updateEMBGsiData$ = this.updateEMBGsiData.asObservable();

  sendCuDataToSf = new BehaviorSubject({});
  sendCuDataToSf$ = this.sendCuDataToSf.asObservable();

  openOCRSubject = new Subject();
  openOCRSubject$ = this.openOCRSubject.asObservable();

  populateOntologies = new Subject();
  populateOntologies$ = this.populateOntologies.asObservable();

  setMappingsSubject = new Subject();
  setMappingsSubject$ = this.setMappingsSubject.asObservable();

  currentOCRMappings = new Subject();
  currentOCRMappings$ = this.currentOCRMappings.asObservable();

  nestedNumber = new Subject();
  nestedNumber$ = this.nestedNumber.asObservable();

  activeEmbGsiData = new BehaviorSubject('');
  activeEmbGsiData$ = this.activeEmbGsiData.asObservable();

  previousEmbeddedGSI = new Subject();
  previousEmbeddedGSI$ = this.previousEmbeddedGSI.asObservable();

  freshStartObv = new Subject();
  freshStartObv$ = this.freshStartObv.asObservable();

  closeCUPanelObv = new Subject();
  closeCUPanelObv$ = this.closeCUPanelObv.asObservable();

  setCurrentModifiedFalse = new Subject();
  setCurrentModifiedFalse$ = this.setCurrentModifiedFalse.asObservable();
  arGsi = new Subject();
  arGsi$ = this.arGsi.asObservable();

  constructor(
    private injector: Injector,
    private http: HttpClient,
    private loader: LoaderService,
    private alertService: AlertService
  ) {
    super(injector);
  }
  setModifiedFalse() {
    this.setCurrentModifiedFalse.next(true);
  }
  openPreviousEmbedded(level: any, data: any) {
    this.previousEmbeddedGSI.next({ level: level, data: data });
  }

  freshStart() {
    this.freshStartObv.next(true);
  }
  sendCUCloseObservable() {
    this.closeCUPanelObv.next(true);
  }
  setCountOfEmbGSi(count: number) {
    this.countOfEmbGsi.next(count);
  }

  setTotalErrorsCount(count: number) {
    this.totalErrorsCount.next(count);
  }
  setParallel(isParallel: any) {
    this.setParallelSubject.next(isParallel);
  }
  setPublishEntity(data: any) {
    this.draftEntity.next(data);
  }
  updateGSIDataForNSLtoNodeFunction(data: any) {
    this.updateGSIDataForNSLtoNode.next(data);
  }
  closeRightPanel(data: any) {
    this.closeRightPanelSubject.next(data);
  }
  updateEmbeddedGsiData(data: any) {
    this.updateEMBGsiData.next(data);
  }
  finish(res: any, finishType?: any) {
    let isembed = res?.isEmbedded;
    let gsiData = gsiDeMapper(res);
    let level = res?.level;
    this.http
      .post(`${this.api?.versionFinishGSI}`, gsiData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /*istanbul ignore next*/
        if (res && res.result) {
          this.alertService.showToaster(res.message, '', 'success');
          let gsires = gsiMapper(res.result);
          if (isembed) {
            gsires.isEmbedded = isembed;
          }
          gsires.level = level;
          this.gsiFinishResponse.next(gsires);
        }
      });
  }
  getAllUsers(id: any) {
    this.http
      .get<any>(`${this.api?.loadAssignedGroupUsers}type=graph&role=${id}`)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /*istanbul ignore next*/
        if (res) {
          this.usersSubject.next(res);
        }
      });
  }

  getTempGsiData(data: any) {
    this.tempGSI.next(data);
  }

  getAllReservedActionEntities() {
    return this.http.get<any>(`${this.api?.reservedEntities}`);
  }
  saveDraftGsi(gsi: any) {
    let level = gsi?.level;
    let gsiData = gsiDeMapper(gsi);
    this.http
      .post(`${this.api?.saveGsi}`, gsiData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        if (res && res.result) {
          this.alertService.showToaster(res.message, '', 'success');
          let gsires = gsiMapper(res.result);
          gsires.isEmbedded = true;
          gsires.level = level;
          this.embededGsi.next(gsires);
        }
      });
  }

  saveGsi(gsi: any, activeCUIndex?: any, sentenceTags?: any, gotoNextCU?: boolean, slot?: boolean) {
    let isEmbedded = gsi?.isEmbedded;
    let instanceNumber = gsi?.instanceNumber;
    let gsiData = gsiDeMapper(gsi);
    let level = gsi?.level;
    /*istanbul ignore next*/
    if (gsiData.layers?.length === 0) {
      gsiData.layers = null;
    }
    this.http
      .post(`${this.api?.saveGsi}`, gsiData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /*istanbul ignore next*/
        if (res && res.result) {
          this.alertService.showToaster(res.message, '', 'success');
          /*istanbul ignore next*/
          let gsires = gsiMapper(res.result);
          /*istanbul ignore next*/
          if (activeCUIndex) {
            /*istanbul ignore next*/
            let foundIndex = gsires?.solutionLogic.findIndex((x: any) => x.index === activeCUIndex);
            /*istanbul ignore next*/
            if (foundIndex !== -1) {
              // gsires[gsiIndex].solutionLogic[foundIndex].isEditable = true;
              gsires.solutionLogic[foundIndex].sentenceTags = sentenceTags;
            }
          }
          if (slot) {
            this.saveSlotItemProperties.next(gsires);
          }
          if (isEmbedded) {
            gsires.isEmbedded = true;
            gsires.instanceNumber = instanceNumber;
            gsires.level = level;
            this.embededGsi.next(gsires);
          } else {
            gsires.instanceNumber = instanceNumber;
            this.cusList.next(gsires);
            this.gsiDraftResponse.next(gsires);
            this.multigsiDraftResponse.next({ gsires, activeCUIndex, gotoNextCU });
          }
        }
      });
  }

  /*istanbul ignore next*/
  saveARGSI(gsi: any) {
    let level = gsi?.level;
    // let gsiData = gsiDeMapper(gsi);
    this.http
      .post(`${this.api.trainARGsi}`, gsi)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        if (res && res.result) {
          this.alertService.showToaster(res?.message, '', 'success');
          let gsires = gsiMapper(res?.result);
          gsires.level = level;
          this.arGsi.next(gsires);
        }
      });
  }
  saveEmbeddedGsi(gsi: any, activeCUIndex?: any, sentenceTags?: any, gotoNextCU?: boolean, slot?: boolean) {
    let gsiData = gsiDeMapper(gsi);
    this.http
      .post(`${this.api?.saveGsi}`, gsiData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /*istanbul ignore next*/
        if (res && res.result) {
          this.alertService.showToaster(res.message, '', 'success');
          /*istanbul ignore next*/
          let gsires = gsiMapper(res.result);
          this.embededDraft.next(gsires);
        }
      });
  }
  updateDraftedEMBGsi(data: any) {
    this.embededDraft.next(data);
  }
  getGsiResponse(data: any) {
    this.gsiDraftResponse.next(data);
  }
  sendTags(res: any) {
    this.sendSentenceTags.next(res);
  }

  /*istanbul ignore next*/
  savegsi(body: any, type: any, bookId: number) {
    //this.loader.show()sub;
    let gsiData = gsiDeMapper(body);
    if (gsiData.layers?.length === 0) {
      gsiData.layers = null;
    }
    this.http
      .post(`${this.api?.saveGsi}`, gsiData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        console.log(res);
        if (res) {
          res.result.source = gsiData?.source; //source added to GSI while saving it
          this.loader.hide();
          this.alertService.showToaster(res.message, '', 'success');
          const gsiRes = gsiMapper(res?.result);
          this.updateGSIObject(gsiRes);
        }
      });
  }

  updateGSIObject(gsiData: any) {
    this.updateGSIData.next(gsiData);
  }

  selectedAgent(agent: any) {
    agent['CuId'] = this.CuIdForAgent;
    this.selectedAgentinCU.next(agent);
  }
  selectconf(data: any, isNested?: boolean) {
    if (isNested) {
      data['isNested'] = true;
    }
    this.currentComponentConf.next(data);
  }
  expandGraph(data: any, showAttriutes?: any, showAttriutesGsi?: any, isNestedCU?: any) {
    if (showAttriutes) {
      this.expandEntities.next(data);
    } else if (showAttriutesGsi) {
      this.expandGSIEntities.next(data);
    } else if (isNestedCU) {
      this.nestedCuExpansion.next(data);
    } else {
      this.expandGraphNode.next(data);
    }
  }
  setEmbComponent(data: any) {
    this.currentEmbComponent.next(data);
  }
  getExpandEntitiesGSI(data: any) {
    this.expandGSIEntities.next(data);
  }
  getExpandGraphNode(data: any) {
    this.expandGraphNode.next(data);
  }
  getExpandGraph(data: any) {
    this.expandEntities.next(data);
  }
  getAtrributeScreen(data: any, i: any) {
    this.openAttribute.next({ data, i });
  }
  getCuDetailsByGsiId(data: any) {
    this.cuDetailsByGsiId.next(data);
  }
  saveMultipleGsi(gsi: any, activeCUIndex?: number, sentenceTags?: any, gsiIndex?: number, gotoNextCU?: boolean) {
    let gsiData = gsiDeMapper(gsi);
    this.http
      .post(`${this.api?.multipleGsiSave}`, gsiData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        /*istanbul ignore next*/
        if (res && res.result) {
          this.alertService.showToaster(res.message, '', 'success');
          let gsires = res.result;
          /*istanbul ignore next*/
          if (activeCUIndex) {
            let foundIndex = gsires[gsiIndex].solutionLogic.findIndex((x: any) => x.index === activeCUIndex);
            /*istanbul ignore next*/
            if (foundIndex !== -1) {
              // gsires[gsiIndex].solutionLogic[foundIndex].isEditable = true;
              gsires[gsiIndex].solutionLogic[foundIndex].sentenceTags = sentenceTags;
            }
          }
          gsires[0].tfId = res.result[0].id;
          this.gsiDraftResponse.next(gsires[0]);
          this.multigsiDraftResponse.next({ gsires, activeCUIndex, gotoNextCU });
        }
      });
  }
  getAllGsis(pagenum: number, pageSize: number, searchCriteria: string, isPublished: any, isTXNRights: boolean) {
    const ontology = '';
    const publisherId = '';
    /*istanbul ignore next*/
    const type = isTXNRights ? 'TXN_INFO_RIGHTS' : 'SOLUTIONS_INFO_RIGHTS';
    this.http
      .get(
        `${this.api?.getAllGsis}?isPublished=${isPublished}&limit=${pageSize}&ontology=${ontology}&page=${
          pagenum + 1
        }&publisherIdOrName=${publisherId}&query=${searchCriteria}&userRights=${type}`
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.gsiList.next(res);
      });
  }
  getallGsis(res: any) {
    this.gsiList.next(res);
  }
  getAllBooks(pagenum: number, pageSize: number, searchCriteria: string) {
    searchCriteria = searchCriteria ? searchCriteria : '';
    return this.http
      .get(
        `${this.api?.getAllTenantBooks}/matching?pageNo=${
          pagenum - 1
        }&pageSize=${pageSize}&searchCriteria=${searchCriteria}`
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.bookList.next(res);
      });
  }
  getAllAgents(pageNumber: number, pageSize: number, searchString: string, isActive?: boolean) {
    const payload = {
      isActive: isActive ? isActive : 'all',
      roleName: searchString,
    };
    this.http
      .post(this.api?.getPaginateRoles + `pageNumber=${pageNumber}&pageSize=${pageSize}`, payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        if (res) {
          this.getAgents.next(res);
        }
      });
  }
  getCuDetailsForSOP(id: any) {
    this.http
      .get(`${this.api?.saveGsi}/${id}`)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        if (res) {
          /*istanbul ignore next*/
          this.cuDetailsByGsiIdforSOP.next(gsiMapper(res?.result));
        }
      });
  }
  getCuDetails(id: any, nested?: any) {
    this.http
      .get(`${this.api?.saveGsi}/${id}`)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        // let gsires = gsiMapper(res.result);
        if (nested) {
          /*istanbul ignore next*/
          this.nestedCuDetailsByGsiId.next(gsiMapper(res?.result));
        } else {
          /*istanbul ignore next*/
          if (res?.result?.status == 'PUBLISHED' || res?.result?.status === 'ARCHIVED') {
            this.editPublishedBET(id, 'gsi');
          } else if (
            /*istanbul ignore next*/
            res?.result?.status == 'READY' ||
            res?.result?.status == 'REJECTED'
          ) {
            /*istanbul ignore next*/
            this.cancelReview('gsi', res?.result);
          } else {
            /*istanbul ignore next*/
            this.cuDetailsByGsiId.next(gsiMapper(res?.result));
            /*istanbul ignore next*/
            this.cusList.next(gsiMapper(res?.result));
          }
        }

        // this.saveGsi(res);
      });
  }
  getcuDetails(res: any) {
    this.nestedCuDetailsByGsiId.next(res);
  }
  viewLibraryDataGetter(data: any) {
    this.viewLibraryData.next(data);
  }
  viewBetDetails(betId: any, betType: any, isReserved?: boolean) {
    switch (betType) {
      case Librarytypes.GSI:
        this.http
          .get(`${this.api?.saveGsi}/${betId}`)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((res: any) => {
            if (res) {
              this.viewLibraryData.next(res.result);
            }
          });
        break;
      case Librarytypes.ChangeUnit:
        this.http
          .get(`${this.api?.savecu}/${betId}`)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((res: any) => {
            if (res) {
              this.viewLibraryData.next(res.result);
            }
          });
        break;
      case Librarytypes.Entity:
        isReserved = isReserved ? isReserved : false;
        this.http
          .get(`${this.api?.getAttributeByEntityId}/${betId}?isReserved=${isReserved}`)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe((res: any) => {
            if (res) {
              this.viewLibraryData.next(res.result);
            }
          });
        break;
    }
  }
  cancelReview(betType: any, data: any) {
    this.http
      .post(this.api?.cancelReview + `${betType}/` + `${data.id}` + '?cancelReview=true', data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /*istanbul ignore next*/
        this.cuDetailsByGsiId.next(gsiMapper(res?.result));
        /*istanbul ignore next*/
        this.cusList.next(gsiMapper(res?.result));
      });
  }

  editPublishedBET(id: any, betType: any) {
    this.http
      .post(this.api?.editPublishedBET + `${betType}/${id}`, {})
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /*istanbul ignore next*/
        this.cuDetailsByGsiId.next(gsiMapper(res?.result));
        /*istanbul ignore next*/
        this.cusList.next(gsiMapper(res?.result));
      });
  }
  gsiSuggestionsGetter(data: any) {
    this.gsiSuggestions.next(data);
  }

  getGsiSuggestions(page: any, pageSize: any, searchText: any, isPublished: boolean, eventCall?: boolean) {
    this.http
      .get(
        `${this.api?.getAllGsis}?limit=${pageSize}&ontology=&page=${page}&publisherIdOrName=&query=${searchText}&isPublished=${isPublished}`
      )
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /*istanbul ignore next*/
        if (res && res.result?.data && !eventCall) {
          this.gsiSuggestions.next(res.result.data);
        }
        if (eventCall) {
          this.publishedGsi.next(res.result.data);
        }
      });
  }

  getGsi(data: any) {
    this.publishedGsi.next(data);
  }
  saveExistingGsi(dsdId: any) {
    this.http
      .get(`${this.api?.saveGsi}/${dsdId}`)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        console.log('saveGsi', res);
        /*istanbul ignore next*/
        if (res && res.result) {
          this.alertService.showToaster(res.message, '', 'success');
          let gsires = gsiMapper(res.result);
          this.gsiDraftResponse.next(gsires);
        }
      });
  }
  openLibrary(librayType: any, nestedNumber?: any) {
    this.openMyCollection.next(librayType);
    this.sendNestedNumber(nestedNumber);
  }
  sendNestedNumber(index: any) {
    this.nestedNumber.next(index);
  }

  isForGsi(isforGsi: any) {
    this.entityinGsi.next(isforGsi);
  }
  addNestedGsi(gsi: any, type: any) {
    this.http
      .get(`${this.api?.saveGsi}/${gsi.id}`)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        if (res) {
          /*istanbul ignore next*/
          if (type !== 'any') {
            this.nestedGsiData.next(gsiMapper(res?.result));
          } else if (res?.result?.status == 'PUBLISHED' || res?.result?.status === 'ARCHIVED') {
            this.editPublishedBET(gsi.id, 'gsi');
          } else if (
            /*istanbul ignore next*/
            res?.result?.status == 'READY' ||
            res?.result?.status == 'REJECTED'
          ) {
            /*istanbul ignore next*/
            this.cancelReview('gsi', res?.result);
          } else {
            /*istanbul ignore next*/
            this.cuDetailsByGsiId.next(gsiMapper(res?.result));
            /*istanbul ignore next*/
            this.cusList.next(gsiMapper(res?.result));
          }
        }
      });
  }
  saveGsiRoles(gsi: any) {
    this.gsiRoles.next(gsi);
  }
  updateGsi(gsi: any) {
    this.updateGsiData.next(gsi);
  }
  getGsiDraftResponse(gsi: any) {
    this.gsiDraftResponse.next(gsi);
  }

  /**
   * Book related api calls
   */
  saveBook(book: any) {
    this.http
      .post(`${this.api?.saveBook}`, book)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.alertService.showToaster(`${res.message}`, '', 'success');
          this.bookSubject.next(res);
        },
        /*istanbul ignore next*/
        (err: any) => {
          this.alertService.showToaster(`${err.message}`, '', 'error');
        }
      );
  }

  getsaveBook(data: any) {
    this.bookSubject.next(data);
  }

  savedGsiInBookGetter(book: any) {
    this.savedGsiInBook.next(book);
  }
  saveGsiInBook(bookId: string, data: any) {
    this.http
      .post(`${this.api?.saveGsiInBook}${bookId}/gsi`, data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.alertService.showToaster(`${res.message}`, '', 'success');
          this.savedGsiInBook.next(res.result);
        },
        /*istanbul ignore next*/
        (err: any) => {
          this.alertService.showToaster(`${err.message}`, '', 'error');
        }
      );
  }
  getSaveGsiInBook(data: any) {
    this.savedGsiInBook.next(data);
  }
  addBookFromLibrary(bookId: any) {
    this.http
      .get(this.api.saveBook + `/${bookId}`)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.addBookFromLibrarySubject.next(res?.result);
      });
  }
  dcdChange() {
    this.dcdChangeSubject.next(true);
  }
  getcloningDetails(cuId: number, isPublished: boolean, datatype: any, data: any) {
    //this.loader.show()sub;
    this.http
      .post(this.api?.betsStoreTenant + `${datatype}/clone/${cuId}`, { name: data.name })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.alertService.showToaster(res['message'], '', 'success');
        this.loader.hide();
        let returnedValue = betMapper(datatype, res);
        this.cloneResponseGsi.next(returnedValue);
      });
  }

  getClone(data: any) {
    this.cloneResponseGsi.next(data);
  }
  expandGsi(gsiData: any) {
    this.gsiNode.next(gsiData);
  }
  // getCloneDetails(id: number, isPublished: boolean, type: any, data: any) {
  //   return this.http.post(this.api?.betsStoreTenant + `${type}/clone/${id}?isPublished=${isPublished}`, data);
  // }
  draftCUGetter(data: any) {
    this.draftCu.next(data);
  }
  editPublished(id: any, betType: any, index?: any) {
    this.http
      .post(this.api?.editPublishedBET + `${betType}/${id}`, {})
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /*istanbul ignore next*/
        if (betType === 'change-unit' && res.status === 200) {
          if (index) {
            res.result.index = index;
          }
          this.draftCu.next(changeUnitMapper(res.result));
        } else if (betType === 'entity') {
          /*istanbul ignore next*/
          this.draftEntity.next(res);
        }
      });
  }
  addExpression(exp: any) {
    this.recursiveExpression.next(exp);
  }
  setEmbeddedGsi(gsi: any) {
    this.embededGsi.next(gsi);
  }
  fetchGsi(id: any) {
    this.http
      .get(this.api?.getGsiDetail + id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        res = gsiMapper(res.result);
        this.embededGsi.next(res);
      });
  }
  setEmpty(res: any) {
    this.embededGsi.next(res);
  }
  setArData(data: any) {
    this.openArWindow.next(data);
  }
  closeArPanel(data: any) {
    this.closeArWindow.next(data);
  }
  changeNSLComponent(component: any) {
    this.changeNSLComponentSubject.next(component);
  }
  changeToOCR(data: any) {
    this.openOCRSubject.next(data);
  }
  updateEntityAndSave(entity: any) {
    this.updateEntityInNSL.next(entity);
  }

  updateDcdInConfigurationData(data: any) {
    this.updateDcdInConfData.next(data);
  }

  gsiValidation(gsiName: any) {
    //this.loader.show()sub;
    this.http
      .post(this.api?.compositeExtraction, gsiName)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          this.gsiCompositeExtraction.next(res);
        },
        /*istanbul ignore next*/
        (err: any) => {
          this.loader.hide();
          this.alertService.showToaster(err.message, '', 'error');
        }
      );
  }

  getGsiDetails(id: string) {
    this.http
      .get(this.api?.getGsiDetail + id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: any) => {
        this.gsiDetails.next(data);
      });
  }
  setActiveGsi(gsi: any) {
    this.activeGsi.next(gsi);
  }
  changeActiveGsi() {
    this.changeGsi.next(true);
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  convertCuToGsi(id: number, body: any, index: number) {
    this.http
      .post(this.api?.convertCuToGsi + `/${id}`, body)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.alertService.showToaster(res.message, 'success', 'success');
        let gsi = gsiMapper(res.result);
        gsi.index = index;
        this.convertedGsi.next(gsi);
      });
  }
  setEmbCnvertedGsi(data: any) {
    this.convertedGsi.next(data);
  }
  saveInMainGsi(gsi: any, componentInstance: number) {
    this.changedEmbedded.next({ gsi: gsi, instanceNumber: componentInstance });
  }
  /*istanbul ignore next*/
  updateEmbeddedGsi(cu: any) {
    this.http
      .post(this.api?.saveGsi, cu)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.alertService.showToaster(res.message, 'success', 'success');
        let gsi = gsiMapper(res.result);
        gsi.index = cu.index;
        const tmp = {
          ...gsi,
          index: cu.index,
          oldVersionId: cu?.oldVersionId,
          isEdit: cu?.isEdit,
          isModified: true,
          slotItemProperties: cu?.slotItemProperties,
          source: cu?.source,
          tCUConditionalPotentiality: cu?.tCUConditionalPotentiality,
          tCUConditionalPotentialityNames: cu?.tCUConditionalPotentialityNames,
          constraint: cu?.constraint,
          constraintName: cu?.constraintName,
          estimatedTime: cu?.estimatedTime,
          isReservedTemplate: cu?.isReservedTemplate,
          sentenceTags: cu?.sentenceTags,
          mindCUList: cu?.mindCUList,
        };
        this.updatedEmbeddedGsi.next(tmp);
      });
  }

  openParallelComponent(data: any) {
    this.openParallelComponentSubject.next(data);
  }
  closeNestedExpansion(event: any) {
    this.isExpansionClosed.next(event);
  }

  sendCuDataToSpecialFeature(gsi: any, cu: any, allsf: any, sf: any, tag: any) {
    this.sendCuDataToSf.next({
      currentGsi: gsi,
      activeBet: cu,
      specialFeature: sf,
      vantagePoint: tag,
      allSpecialFeatures: allsf,
    });
  }
  expandGraphTest(res: any) {
    this.expandGraphNode.next(res);
  }
  finishEmbGsi(res: any) {
    /*istanbul ignore next*/
    let isEmbedded = res?.isEmbedded;
    let embGsiData = gsiDeMapper(res);
    /*istanbul ignore next*/
    let embLevel = res?.level;
    /*istanbul ignore next*/
    this.http
      .post(`${this.api?.versionFinishGSI}`, embGsiData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /*istanbul ignore next*/
        if (res && res.result) {
          this.alertService.showToaster(res.message, '', 'success');
          let gsires = gsiMapper(res.result);
          if (isEmbedded) {
            gsires.isEmbedded = isEmbedded;
          }
          gsires.level = embLevel;
          this.gsiEmbFinishResponse.next(gsires);
        }
      });
  }
  setActiveEmbGSI(data: any) {
    this.activeEmbGsiData.next(data);
  }

  fetchOntologies() {
    this.http
      .get(this.api?.ontologyMaster)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        if (res) {
          this.populateOntologies.next(res);
        }
      });
  }
  setMappings(ocrData: any) {
    this.http.post(this.api?.ocrSetMappingUrl, ocrData).subscribe((res: any) => {
      this.setMappingsSubject.next(res);
      this.alertService.showToaster('Mapped Successfully', '', 'success');
    });
  }
  getSolutionDetails(data: any, component?: any) {
    this.http
      .post(this.api.fat2flat, data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          res.component = component;
          this.solutionDetailsGsi.next(res);
        },
        /*istanbul ignore next*/
        (err: any) => {
          const obj = {
            flatApiFail: true,
          };
          this.solutionDetailsGsi.next(obj);
          this.alertService.showToaster(err.message, '', 'error');
        }
      );
  }
  getsolDetails(data: any) {
    this.solutionDetailsGsi.next(data);
  }
  getOCRMappings(data: any) {
    this.http
      .post(this.api?.ocrGetMappingUrl, data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.currentOCRMappings.next(res);
      });
  }
  setEmbGsiData(data: any) {
    this.cusList.next(data);
  }
}

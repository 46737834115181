import { Injectable } from '@angular/core';
import { ObservableStore } from '@codewithdan/observable-store';
import { AttributeList } from '../models/attribute-list';

export interface StoreState {
  attributeList: AttributeList[];
}

@Injectable({
  providedIn: 'root',
})
export class AttributeStoreService extends ObservableStore<StoreState> {
  constructor() {
    super({ trackStateHistory: true, logStateChanges: false });
  }

  getStateData() {
    return this.getState();
  }

  setStateData(data: any) {
    this.setState({ attributeList: data }, AttributeStoreActions.SetAttributes);
  }
}

export enum AttributeStoreActions {
  SetAttributes = 'SET_Attributes',
}

import { Injectable, OnDestroy } from '@angular/core';
import { UsersEndpointService } from './users-endpoint.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { RolesFacadeService } from '../roles/roles-facade.service';
import { AlertService } from '@app/@core/toaster/alert.service';
import { LoaderService } from '@app/@shared/services/loader/loader.service';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root',
})
export class UsersFacadeService implements OnDestroy {
  users: any = [];

  usersDataSubject = new BehaviorSubject(this.users);
  usersData$ = this.usersDataSubject.asObservable();

  timezoneData = new Subject();
  timezoneData$ = this.timezoneData.asObservable();

  failedImportData = new Subject();
  failedImportData$ = this.failedImportData.asObservable();

  usersAllDataSubject = new Subject();
  usersAllData$ = this.usersAllDataSubject.asObservable();

  activeUser = new Subject();
  activeUserDetails$ = this.activeUser.asObservable();
  userName: any;
  private assignedUsers = new Subject();
  assignedUsers$ = this.assignedUsers.asObservable();
  public ngUnsubscribe = new Subject();

  usersDataPaginateSubject = new Subject();
  usersDataPaginate$ = this.usersDataPaginateSubject.asObservable();

  allUsersDataSubject = new Subject();
  allUsersData$ = this.allUsersDataSubject.asObservable();

  allUsersCdmDataSubject = new Subject();
  allUsersCdmData$ = this.allUsersCdmDataSubject.asObservable();

  usersListSubject = new Subject();
  usersList$ = this.usersListSubject.asObservable();

  singleUserSubject = new BehaviorSubject('');
  singleUserData$ = this.singleUserSubject.asObservable();

  deleteUserSubject = new BehaviorSubject('');
  deleteUserData$ = this.deleteUserSubject.asObservable();

  updateUserSubject = new Subject();
  updateUser$ = this.updateUserSubject.asObservable();

  unAssignUserToManagerSubject = new Subject();
  unAssignUserToManager$ = this.unAssignUserToManagerSubject.asObservable();

  unassignedRoleUsersSubject = new Subject();
  unassignedRoleUsers$ = this.unassignedRoleUsersSubject.asObservable();

  assignedRoleUsersSubject = new Subject();
  assignedRoleUsers$ = this.assignedRoleUsersSubject.asObservable();

  unassignedGroupUsersSubject = new Subject();
  unassignedGroupUsers$ = this.unassignedGroupUsersSubject.asObservable();

  assignedGroupUsersSubject = new Subject();
  assignedGroupUsers$ = this.assignedGroupUsersSubject.asObservable();

  activeUserInfoSubject = new BehaviorSubject('');
  activeUserInfo$ = this.activeUserInfoSubject.asObservable();

  usersCountSubject = new Subject();
  usersCount$ = this.usersCountSubject.asObservable();

  unAssignUserToRoleSubject = new Subject();
  unAssignUserToRole$ = this.unAssignUserToRoleSubject.asObservable();

  // delegation
  listDelegationForUserSubject = new BehaviorSubject('');
  listDelegationForUser$ = this.listDelegationForUserSubject.asObservable();

  usersDelegationListSubject = new Subject();
  usersDelegationList$ = this.usersDelegationListSubject.asObservable();

  detailsByTargetUserIdSubject = new Subject();
  detailsByTargetUserId$ = this.detailsByTargetUserIdSubject.asObservable();

  public deleteDelegationSubject = new Subject();
  deleteDelegation$ = this.deleteDelegationSubject.asObservable();

  public listDelegationIdSubject = new BehaviorSubject(null);
  listDelegationId$ = this.listDelegationIdSubject.asObservable();

  public delegationByIdSubject = new Subject();
  delegationById$ = this.delegationByIdSubject.asObservable();

  deleteRoleSubject = new Subject();
  rdeleteRole$ = this.deleteRoleSubject.asObservable();

  public gsidetails = new Subject();
  gsidetails$ = this.gsidetails.asObservable();

  public fetchdetails = new Subject();
  fetchdetails$ = this.fetchdetails.asObservable();

  public tenantDesignSubject = new BehaviorSubject('');
  tenantDesign$ = this.tenantDesignSubject.asObservable();
  public userCdmData = new Subject();
  userCdmData$ = this.userCdmData.asObservable();
  //pas.-policy
  public tenantPasswordPolicy = new Subject();
  tenantPasswordPolicy$ = this.tenantPasswordPolicy.asObservable();

  passwordPolicySetRespose = new Subject();
  passwordPolicySetRespose$ = this.passwordPolicySetRespose.asObservable();

  //lock-policy
  tenantPasswordLockPolicy = new Subject();
  tenantPasswordLockPolicy$ = this.tenantPasswordLockPolicy.asObservable();

  passwordPolicyLockResponse = new Subject();
  passwordPolicyLockResponse$ = new Subject();

  public b2cUserSpace = new BehaviorSubject('');
  b2cUserSpace$ = this.b2cUserSpace.asObservable();

  public invitationHistory = new BehaviorSubject({});
  invitationHistory$ = this.invitationHistory.asObservable();

  public books = new BehaviorSubject('');
  books$ = this.books.asObservable();

  // observable after signup in carnival
  public signupCarnivalSubject = new Subject();
  signupCarnival$ = this.signupCarnivalSubject.asObservable();

  private userProfileSubject = new Subject();
  userProfile$ = this.userProfileSubject.asObservable();

  userProfileInfoSubject = new BehaviorSubject(null);
  userProfileInfo$ = this.userProfileInfoSubject.asObservable();

  activeUserFlagSubject = new BehaviorSubject('');
  activeUserFlag$ = this.activeUserFlagSubject.asObservable();

  updateUserList = new Subject();
  updateUserList$ = this.updateUserList.asObservable();

  loginTokenSubject = new BehaviorSubject(false);
  loginToken$ = this.loginTokenSubject.asObservable();

  booksByOwnerSubject = new Subject();
  booksByOwner$ = this.booksByOwnerSubject.asObservable();

  solutionsByOwnerSubject = new Subject();
  solutionsByOwner$ = this.solutionsByOwnerSubject.asObservable();

  constructor(
    public usersEndPointService: UsersEndpointService,
    public rolesFacadeService: RolesFacadeService,
    public alertService: AlertService,
    public loader: LoaderService,
    public readonly keycloak: KeycloakService
  ) {}

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  updateActiveUserFlag(user: any) {
    this.activeUserFlagSubject.next(user);
  }

  loadAllUsers() {
    this.loader.show();
    this.usersEndPointService
      .getAllUser()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.loader.hide();
        this.allUsersDataSubject.next(res);
      });
  }

  getAllBooks(pagenum: number, pageSize: number, searchCriteria: string) {
    this.loader.show();
    this.usersEndPointService
      .getAllBooks(pagenum, pageSize, searchCriteria)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.books.next(res);
      });
  }
  /** API Call for getting All users through cdm for Tenant Admin */
  loadAllCdmUsers() {
    this.loader.show();
    this.usersEndPointService
      .getAllCdmUser()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.allUsersCdmDataSubject.next(res);
      });
  }

  loadPaginateUsers(pageNumber: number, pageSize: number) {
    this.loader.show();
    this.usersEndPointService
      .getPaginateUser(pageNumber, pageSize)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.loader.hide();
        this.usersDataPaginateSubject.next(res);
      });
  }
  loadTimezonebysearch(pageNumber: number, pageSize: number, searchString: string, isActive?: boolean) {
    this.loader.show();
    this.usersEndPointService
      .getTimezone()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.loader.hide();
        this.timezoneData.next(res);
      });
  }
  loadPaginateUsersbySearch(pageNumber: number, pageSize: number, searchString: string, isActive?: boolean) {
    this.loader.show();
    this.usersEndPointService
      .getPaginateUsers(pageNumber, pageSize, searchString, isActive)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.loader.hide();
        this.usersDataSubject.next(res);
      });
  }

  loadPaginateUsersbySearchforTenantAdmin(pageNumber: number, pageSize: number, searchString: string) {
    this.loader.show();
    this.usersEndPointService
      .getPaginateUsersTenantAdmin(pageNumber, pageSize, searchString)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.loader.hide();
        this.userCdmData.next(res);
      });
  }

  loadUsersbyRights(triggetCuId: any) {
    this.loader.show();
    this.usersEndPointService
      .getUsersByRights(triggetCuId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.loader.hide();
        this.usersListSubject.next(res);
      });
  }

  loadAllPaginateUsersbySearch(pageNumber: number, pageSize: number, searchString: string) {
    this.usersEndPointService
      .getPaginateUsers(pageNumber, pageSize, searchString)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.usersAllDataSubject.next(res);
      });
  }

  createUser(postData: any, enableAsTenant: boolean) {
    this.loader.show();
    this.usersEndPointService
      .createUser(postData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.alertService.showToaster(res.message, '', 'success');
        this.loadAllCdmUsers();
        if (enableAsTenant) {
          this.enableAsTenant(res.result.email);
        }
        this.loader.hide();
      });
  }
  enableAsTenant(email: any) {
    this.loader.show();
    this.assignUserAsTenant(email)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response: any) => {
        this.alertService.showToaster(response.message, '', 'success');
        this.loader.hide();
      });
  }

  updateUser(updateData: any, userId: any, isUpdatePassword?: boolean) {
    this.loader.show();
    this.usersEndPointService
      .updateUser(updateData, userId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res) => {
          this.loader.hide();
          const msg = isUpdatePassword ? 'Updated password Successfully' : 'Updated User Successfully';
          this.alertService.showToaster(msg, '', 'success');
          this.loadAllUsers();
          this.updateUserSubject.next();
        },
        /* istanbul ignore next*/
        (error) => {
          console.log(error);
          this.updateUserSubject.next(error);
        }
      );
  }

  /** Facade Call for Making Update User API Call as a Tenant Admin using CDM Service */

  updateUserAsTenantAdmin(updateData: any, userId: any, isUpdatePassword?: boolean) {
    this.loader.show();
    this.usersEndPointService
      .updateUserAsTenantAdmin(updateData, userId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.loader.hide();
        const msg = isUpdatePassword ? 'Updated password Successfully' : 'Updated User Successfully';
        this.alertService.showToaster(msg, '', 'success');
        this.loadAllCdmUsers();
        this.updateUserSubject.next();
      });
  }

  updateUserPassword(updateData: any) {
    this.loader.show();
    this.usersEndPointService
      .updateUserPassword(updateData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.alertService.showToaster(res.message, '', 'success');
        this.loadAllUsers();
      });
  }
  deleteUser(deleteUserData: any, userId: any) {
    this.loader.show();
    this.usersEndPointService
      .deleteUser(deleteUserData, userId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          this.loader.hide();
          this.alertService.showToaster(res.message, '', 'success');
          this.deleteUserSubject.next(res);
        },
        /* istanbul ignore next*/
        (error) => {
          console.log(error);
          this.deleteUserSubject.next(error);
        }
      );
  }

  confirmDeleteUser(deleteUserData: any, userId: any, wishToContinue: boolean) {
    this.loader.show();
    this.usersEndPointService
      .confirmDeleteModal(deleteUserData, userId, wishToContinue)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.alertService.showToaster(res.message, '', 'success');
        this.deleteRoleSubject.next(res);
      });
  }

  createSocialUser(postData: any) {
    this.loader.show();
    this.usersEndPointService
      .createSocialUser(postData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        /* istanbul ignore next*/
        const msg = res?.status === 200 ? 'success' : 'error';
        /* istanbul ignore next*/
        this.alertService.showToaster(res?.message, '', msg);
        /* istanbul ignore next*/
        if (res?.status === 200) {
          this.signupCarnivalSubject.next({ ...res, password: postData?.password });
        }
      });
  }

  importUserFromCSV(postData: any, env?: any) {
    const time = 10000;
    this.loader.show();
    this.usersEndPointService
      .importUserFromCSV(postData, env)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (res: any) => {
          if (res.status === 200) {
            this.loader.hide();
            this.alertService.showToaster(res.message, '', 'info', time);
            /*istanbul ignore else */
            this.failedImportData.next(res);
          } else {
            this.loader.hide();
            this.failedImportData.next(res);
            this.alertService.showToaster(res.message, '', 'error');
          }
        },
        (error: any) => {
          this.loader.hide();
          this.alertService.showToaster(error.error?.message, '', 'error');
        }
      );
  }

  updateSingleUserData(userName: string) {
    this.singleUserSubject.next(userName);
  }

  getAllRoles() {
    this.rolesFacadeService.loadAllRoles();
  }

  loadActiveUsers(value?: string) {
    this.loader.show();
    this.usersEndPointService
      .getActiveUser()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        /* istanbul ignore next */
        if (res?.name) {
          this.userName = res['name'];
          console.log(res['name'], 'Active User');
          sessionStorage.setItem('UserDetailss', this.userName);
          localStorage.setItem('ActiveUserInfo', JSON.stringify(res));
          this.activeUser.next(res['name']);
          if (value == 'login') {
            this.activeUserInfoSubject.next(res);
          }
        }
      });
  }

  updateActiveUserInfo(info: any) {
    this.activeUserInfoSubject.next(info);
  }

  loadUsersCount() {
    this.loader.show();
    this.usersEndPointService
      .getUsersCount()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.loader.hide();
        this.usersCountSubject.next(res);
      });
  }

  assignedUsersInfo(data: any) {
    this.assignedUsers.next(data);
  }

  userCheckedListInfo(data: any) {
    this.updateUserList.next(data);
  }

  loadUnassignedRoleUsers(pageNumber: number, pageSize: number, searchString: string, roleId: number) {
    this.loader.show();
    this.usersEndPointService
      .loadUnassignedRoleUsers(pageNumber, pageSize, searchString, roleId)
      .pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.unassignedRoleUsersSubject.next(res);
      });
  }

  loadAssignedRoleUsers(pageNumber: number, pageSize: number, searchString: string, roleId: number) {
    this.loader.show();
    this.usersEndPointService
      .loadAssignedRoleUsers(pageNumber, pageSize, searchString, roleId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.assignedRoleUsersSubject.next(res);
      });
  }

  loadUnassignedGroupUsers(pageNumber: number, pageSize: number, searchString: string, groupId: number) {
    this.loader.show();
    this.usersEndPointService
      .loadUnassignedGroupUsers(pageNumber, pageSize, searchString, groupId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.unassignedGroupUsersSubject.next(res);
      });
  }

  loadAssignedGroupUsers(pageNumber: number, pageSize: number, searchString: string, groupId: number) {
    this.loader.show();
    this.usersEndPointService
      .loadAssignedGroupUsers(pageNumber, pageSize, searchString, groupId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.assignedGroupUsersSubject.next(res);
      });
  }

  // delegation
  listDelegationForUser(data: any) {
    this.listDelegationForUserSubject.next(data);
  }

  getListDelegationId(id: any) {
    this.listDelegationIdSubject.next(id);
  }

  loadUserDelegations(pageNumber: number, pageSize: number, searchString: string, userId: any) {
    this.loader.show();
    this.usersEndPointService
      .loadUserDelegations(pageNumber, pageSize, searchString, userId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.loader.hide();
        this.usersDelegationListSubject.next(res);
      });
  }

  createDelegation(postData: any) {
    this.loader.show();
    this.usersEndPointService
      .createDelegation(postData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.alertService.showToaster(res.message, '', 'success');
      });
  }

  getDetailsByTargetUserId(fromUserId: number, toUserId: number, delegationRule: any) {
    this.loader.show();
    this.usersEndPointService
      .getDetailsByTargetUserId(fromUserId, toUserId, delegationRule)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.detailsByTargetUserIdSubject.next(res);
      });
  }

  deleteDelegation(delegationId: any) {
    this.loader.show();
    this.usersEndPointService
      .deleteDelegation(delegationId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        /* istanbul ignore else*/
        if (res) {
          this.deleteDelegationSubject.next(res);
        }
      });
  }

  getDelegationById(delegationId: any) {
    this.loader.show();
    this.usersEndPointService
      .getDelegationById(delegationId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        /* istanbul ignore else*/
        if (res) {
          this.delegationByIdSubject.next(res);
        }
      });
  }

  getTenantDesignData(tenantName: string) {
    console.log(tenantName);
  }

  /**
   * Gets password policy associated with the tenant
   * @param tenantName
   */
  getPasswordPolicyByAdmin(tenantName: any) {
    this.loader.show();
    this.usersEndPointService
      .getPasswordPolicyByAdmin(tenantName)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        /* istanbul ignore else*/
        if (res) {
          this.tenantPasswordPolicy.next(res);
        }
      });
  }
  //lock-parameter get  tenantPasswordLockPolicy subject
  getAccountLockParameter(tenantName: any) {
    this.loader.show();
    this.usersEndPointService
      .getAccountLockParameter(tenantName)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        /* istanbul ignore else*/
        if (res) {
          this.tenantPasswordLockPolicy.next(res);
        }
      });
  }

  getInvitationHistory() {
    this.usersEndPointService
      .getInvitationHistory()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.invitationHistory.next(res);
      });
  }

  invitingUsers(postData: any) {
    this.loader.show();
    return this.usersEndPointService.invitingUsers(postData);
  }
  /**
   * Sets password policy by tenant admin
   * @param payload
   */
  PasswordPolicyByAdmin(payload: any) {
    this.loader.show();
    return this.usersEndPointService.setPasswordPolicyByAdmin(payload);
  }
  //lock-parameter passwordPolicyLockResponse subject
  setAccountLockParameter(payload: any) {
    this.loader.show();
    this.usersEndPointService
      .setAccountLockParameter(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        /* istanbul ignore else*/
        if (res) {
          this.passwordPolicyLockResponse.next(res);
          /* istanbul ignore else*/
          if (res.status === 200) {
            this.alertService.showToaster(res.message, '', 'success');
          }
        }
      });
  }
  /**
   *
   * @param gsiid
   */
  getGSIDetails(id: number) {
    this.loader.show();
    this.usersEndPointService
      .getGSIDetails(id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.loader.hide();
        this.gsidetails.next(res);
      });
  }
  /**
   *
   * @param gsiId
   */
  fetchGSIDetails(id: number) {
    this.loader.show();
    this.usersEndPointService
      .getGSIDetails(id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.loader.hide();
        this.fetchdetails.next(res);
      });
  }

  /* carnival userprofile */
  addUserProfile(data: any, isTour?: any) {
    this.usersEndPointService
      .addUserProfile(data)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore else*/
        if (res) {
          this.userProfileSubject.next(res);
          if (!isTour) {
            this.alertService.showToaster(res.message, '', 'success');
          }
        }
      });
  }

  getUserProfile() {
    this.usersEndPointService
      .getUserProfile()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        /* istanbul ignore else*/
        if (res) {
          /* istanbul ignore next */
          this.userProfileInfoSubject.next(res?.result);
        }
      });
  }

  getMatchingFeatureflag(flag: any) {
    return this.usersEndPointService.getMatchingFeatureflag(flag);
  }
  /* ./carnival userprofile */

  fetchFeatureDetail(pageIndex: number, pageSIze: number, tenantId: any) {
    return this.usersEndPointService.fetchFeatureDetail(pageIndex, pageSIze, tenantId);
  }
  addPermissionSet(payload: any) {
    return this.usersEndPointService.addPermissionSet(payload);
  }
  getAllFeatureFlags() {
    return this.usersEndPointService.getAllFeatureFlags();
  }
  assignUserAsTenant(email: any) {
    return this.usersEndPointService.assignUserAsTenant(email);
  }
  assignUserAsEnv(email: any, environmentName: any) {
    return this.usersEndPointService.assignUserAsEnv(email, environmentName);
  }
  unassignUserAsTenant(email: any) {
    return this.usersEndPointService.unassignUserAsTenant(email);
  }
  unassignUserAsEnv(email: any, environmentName: any) {
    return this.usersEndPointService.unassignUserAsEnv(email, environmentName);
  }
  fetchPersonDetail(user: any) {
    return this.usersEndPointService.getPaginateUsers(0, 1, user);
  }
  updatePersonal(payload: any, email: any) {
    return this.usersEndPointService.updatePersonal(payload, email);
  }
  passwordReseting(payload: any) {
    return this.usersEndPointService.passwordReseting(payload);
  }
  getManagerList(pagenumber: number, pagesize: number, searchString: string) {
    return this.usersEndPointService.getPaginateManager(pagenumber, pagesize, searchString);
  }
  getPaginateUnAssignedManager(pageNumber: number, pageSize: number, searchString: string, managerID: any) {
    return this.usersEndPointService.getPaginateUnAssignedManager(pageNumber, pageSize, searchString, managerID);
  }
  assignUsersToManager(payload: any, id: any) {
    return this.usersEndPointService.assignUsersToManager(payload, id);
  }
  unassignUserToManager(managerID: any, userID: any) {
    this.loader.show();
    this.usersEndPointService
      .unassignUserToManager(managerID, userID)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.unAssignUserToManagerSubject.next(res);
      });
  }
  assignedUsersList(id: any) {
    return this.usersEndPointService.assignedUsersList(id);
  }
  getActiveSessionsCount(id: any) {
    return this.usersEndPointService.getActiveSessionsCount(id);
  }
  getActiveUserSessionDetails(id: any, pageSize: number, pageIndex: number) {
    return this.usersEndPointService.getActiveUserSessionDetails(id, pageSize, pageIndex);
  }
  checkToken(value: boolean) {
    this.loginTokenSubject.next(value);
  }

  getUnassignedOwnerList(pagenumber: number, pagesize: number, searchString: string, payload: any) {
    return this.usersEndPointService.getUnassignedOwnerList(pagenumber, pagesize, searchString, payload);
  }

  getBooksByOwner(pageNumber: number, pageSize: number, searchString: string) {
    this.loader.show();
    this.usersEndPointService
      .getBooksByOwner(pageNumber, pageSize, searchString)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.booksByOwnerSubject.next(res);
      });
  }

  getSolutionsByOwner(pageNumber: number, pageSize: number, searchString: string) {
    this.loader.show();
    this.usersEndPointService
      .getSolutionsByOwner(pageNumber, pageSize, searchString)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: any) => {
        this.loader.hide();
        this.solutionsByOwnerSubject.next(res);
      });
  }

  assignOwnersToBookSol(payload: any) {
    return this.usersEndPointService.assignOwnersToBookSol(payload);
  }

  unassignOwnersToBookSol(payload: any) {
    return this.usersEndPointService.unassignOwnersToBookSol(payload);
  }

  assignedOwnersList(pageNumber: number, pageSize: number, searchString: string, payload: any) {
    return this.usersEndPointService.assignedOwnersList(pageNumber, pageSize, searchString, payload);
  }
}

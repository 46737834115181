import { removeDataType } from '@app/@core/generic-functions';
import { ChangeUnitTypes } from '@app/@shared/enums/changeunit-types.enum';
import { entityDeMapper } from '../entity/entity-demapper';

let transform = require('node-json-transform').transform;

export function solutionLogicDeMapper(data: any) {
  var map = {
    /*
      node json mapper default item
      used to map one key to another
    */
    item: {
      dsdId: 'id',
      id: 'id',
      name: 'name',
      agents: 'agents',
      cuType: 'cuType',
      actionLabel: 'actionLabel',
      layers: 'layers',
      displayName: 'displayName',
      description: 'description',
      ontology: 'ontology',
      index: 'index',
      dsdReferencedChangeUnit: 'referencedChangeUnit',
      referencedChangeUnit: 'tfReferencedChangeUnit',
      masterId: 'masterId',
      nextTriggerSet: 'nextTriggerSet',
      reservedCUType: 'reservedCUType',
      membershipList: 'membershipList',
      isReserved: 'isReserved',
      status: 'status',
      designTimeRights: 'designTimeRights',
      txnTimeRights: 'txnTimeRights',
      keywords: 'keywords',
      attachments: 'attachments',
      dcd: 'dcd',
      mindCUList: 'mindCUList',
      exceptionCUList: 'exceptionCUList',
      cuSystemProperties: 'cuSystemProperties',
      source: 'source',
      eventCUList: 'eventCUList',
      constraint: 'constraint',
      tCUConditionalPotentiality: 'tCUConditionalPotentiality',
      tCUConditionalPotentialityNames: 'tCUConditionalPotentialityNames',
      constraintName: 'constraintName',
      slotItemProperties: 'slotItemProperties',
      specialFeatureProperties: 'specialFeatureProperties',
      condition: 'condition',
      conditionName: 'conditionName',
      ownerId: 'ownerId',
      uiProperties: 'uiProperties',
      estimatedTime: 'estimatedTime',
      pathwaysCountFromCurrentCU: 'pathwaysCountFromCurrentCU',
      // querySentence: 'sentenceTags',
      tCUsentenceName: 'sentenceTags',
      mileStoneId: 'mileStoneId',
      tCUShadesOfPotentiality: 'tCUShadesOfPotentiality',
      version: 'version',
      isParallel: 'isParallel',
      attributeMapping: 'attributeMapping',
      nestedOutgoingDCDs: 'nestedOutgoingDCDs',
    },
    operate: [
      // {
      //   run: (val: any) => 'BASIC',
      //   on: 'cuType',
      // },
      {
        run: function (val: any) {
          if (val === ChangeUnitTypes.GSI) {
            return val;
          } else {
            return ChangeUnitTypes.Basic;
          }
        },
        on: 'cuType',
      },
      {
        run: function (val: any) {
          /*istanbul ignore next*/
          if (val?.length > 0) {
            /*istanbul ignore next*/
            return val?.map((y: any) => {
              /* istanbul ignore next */
              return {
                nextCUName: y.nextCUName,
                index: y.index,
                dsdNextCUId: y?.nextCUId,
                isParallel: y?.isParallel,
              };
            });
          } else {
            return [];
          }
        },
        on: 'nextTriggerSet',
      },
      // {
      //   run: function (val: any) {
      //     if (data?.dsdReferencedChangeUnit === null || data?.dsdReferencedChangeUnit === undefined) {
      //       return data?.referencedChangeUnit;
      //     }
      //   },
      //   on: 'dsdReferencedChangeUnit',
      // },
      {
        run: function (val: any) {
          let layers = transform(val, nestedMap);
          // check if layers are empty
          /* istanbul ignore next */
          return layers?.filter((layer: any) => layer.participatingItems?.length > 0);
        },
        on: 'layers',
      },
      {
        run: function (val: any) {
          if (val && val.length > 0) {
            return val
              .filter((cu: any) => {
                /* istanbul ignore next */
                return (cu.isGsi && cu.dsdGsiId) || !cu.isGsi ? true : false;
              })
              .map((ele: any) => {
                let genericObj = {
                  changeDriverId: ele.changeDriverId,
                  dsdChangeDriverId: ele.dsdChangeDriverId,
                  eventType: ele.eventType,
                  isGsi: ele.isGsi,
                  ownerId: ele.ownerId,
                };
                if (ele.isGsi) {
                  return {
                    ...genericObj,
                    dsdGsiId: ele.dsdGsiId,
                    gsiId: ele.gsiId,
                    gsiName: ele.gsiName,
                    id: ele.id,
                  };
                } else {
                  return {
                    ...genericObj,
                    referenceEntityInfo: ele?.referenceEntityInfo,
                  };
                }
              });
          }
        },
        on: 'eventCUList',
      },
      {
        run: (val: any) => {
          return val?.map((x: any) => {
            return {
              ...x,
              formula: x.formulaName ? x.formulaName : x.formula,
              sourceContextualId: x.sourceContextualName,
              targetContextualId: x.targetContextualName,
              sourceArray: setSourceArray(x.sourceArray),
            };
          });
        },
        on: 'dcd',
      },
      {
        run: function (val: any) {
          /* istanbul ignore next */
          return val ? val : undefined;
        },
        on: 'constraint',
      },
      {
        run: function (val: any) {
          return val ? val : undefined;
        },
        on: 'constraintName',
      },
      {
        run: function (val: any) {
          /* istanbul ignore next */
          return val ? val : undefined;
        },
        on: 'estimatedTime',
      },
      {
        run: (val: any) => {
          if (val !== undefined && Object.keys(val).length) {
            return val;
          } else {
            return {};
          }
        },
        on: 'uiProperties',
      },
      {
        run: (val: any) => {
          /* istanbul ignore next */
          if (val !== undefined) {
            /* istanbul ignore next */
            return JSON.stringify(val);
          } else {
            return val;
          }
        },
        on: 'querySentence',
      },
      {
        run: (val: any) => {
          /* istanbul ignore next */
          if (val !== undefined) {
            val = removeDataType(val);
            /* istanbul ignore next */
            return JSON.stringify(val);
          } else {
            return val;
          }
        },
        on: 'tCUsentenceName',
      },
    ],
  };

  function setSourceArray(sourceArray: any) {
    return sourceArray?.map((x: any) => {
      return {
        ...x,
        id: x.name,
      };
    });
  }

  const nestedMap = {
    item: {
      label: 'label',
      participatingItems: 'participatingItems',
      type: 'type',
    },
    operate: [
      {
        run: function (val: any) {
          return val?.map((x: any) => {
            return {
              id: x.participatingItemId,
              isMultiValue: x.isMultiValue,
              changeDriverMetaData: x.changeDriverMetaData, /// optional changes drivers data ///
              item: {
                DATA: entityDeMapper(x),
                TYPE: 'GeneralEntity',
              },
            };
          });
        },
        on: 'participatingItems',
      },
    ],
  };

  const result = transform(data, map);
  console.log(result);
  return result;
}

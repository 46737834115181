import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { pluck } from 'rxjs/internal/operators/pluck';
import { map } from 'rxjs/internal/operators/map';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { CduiBaseService } from '@app/auth/cdui-base.service';
@Injectable({
  providedIn: 'root',
})
export class GsiEndpointService extends CduiBaseService {
  filter: FormControl;
  bookid: any;
  constructor(private injector: Injector, private http: HttpClient) {
    super(injector);
  }

  addSeqCu(gsiId: number, index: number, body: any) {
    const params = new HttpParams().set('index', index + '');
    return this.http.put(`${this.api?.addSeqCu}/${gsiId}/triggerCU-Draft`, body, { params });
  }

  getAllEntities(page: number, limit: number, searchString: string) {
    const ontology = '';
    const publisherId = '';
    return this.http
      .get(
        this.api?.entityList +
          `query=${searchString}&publisherIdOrName=${publisherId}&ontology=${ontology}&page=${page}&limit=${limit}&isPublished=true&userRight=TXN_DECISION_RIGHTS&cardView=true`
      )
      .pipe(pluck('result'));
  }

  getAllEntitesWithRole(roleId: any, pagenum: number, pageSize: number) {
    return this.http.get<any>(`${this.api?.getAllEntitiesForRole}${roleId}?limit=${pageSize}&page=${pagenum}`);
  }

  getEntityCardDetails(id: any) {
    return this.http.get<any>(`${this.api?.getEntityCardData}${id}`);
  }

  getEntityDataDetails(req: any, pageNum: any, pageSize: any) {
    return this.http.post(this.api?.getEntityData + `pageNumber=${pageNum}&pageSize=${pageSize}`, req);
  }
  getSearchRecords(req: any) {
    return this.http.post(this.api?.getSearchRecords, req);
  }
  addNestedCu() {
    return this.http.post(`dsd-bets-store/tenant/triggerCU`, {});
  }

  getAllGsis(
    pagenum: number,
    pageSize: number,
    searchCriteria: string,
    isPublished: boolean,
    isTXNRights: boolean,
    isDesignMode = true,
    selectedWeek?: number
  ) {
    const ontology = '';
    const publisherId = '';
    const type = isTXNRights ? 'EXECUTION_RIGHTS' : 'SOLUTIONS_INFO_RIGHTS';
    let headers: any;
    if (this.bookid) {
      headers = {
        headers: new HttpHeaders({
          bookId: this.bookid,
        }),
      };
    }

    if (selectedWeek) {
      const reqTime = moment()
        .utc()
        .subtract(7 * selectedWeek, 'days')
        .format();
      let epochTime = new Date(reqTime).getTime();
      console.log(epochTime);
      return this.http.get(
        `${this.api?.getAllGsis}?isPublished=${isPublished}&limit=${pageSize}&ontology=${ontology}&page=${pagenum}&publisherIdOrName=${publisherId}&query=${searchCriteria}&userRights=${type}&designMode=${isDesignMode}&fromUpdatedTime=${epochTime}`,
        headers
      );
    } else {
      return this.http.get(
        `${this.api?.getAllGsis}?isPublished=${isPublished}&limit=${pageSize}&ontology=${ontology}&page=${pagenum}&publisherIdOrName=${publisherId}&query=${searchCriteria}&userRights=${type}&designMode=${isDesignMode}`,
        headers
      );
    }
  }

  getAllBookStatus(pagenum: number, pageSize: number, searchCriteria: string, approvalStatuses: any) {
    searchCriteria = searchCriteria ? searchCriteria : '';
    return this.http
      .get(
        this.api?.getBookStatus +
          'pageNo=' +
          pagenum +
          '&pageSize=' +
          pageSize +
          '&searchCriteria=' +
          searchCriteria +
          '&approvalStatuses=' +
          approvalStatuses
      )
      .pipe(pluck('result'));
  }

  // getAllDraftGSIs(pagenum: number, pageSize: number, searchCriteria: string) {
  //   return this.http
  //     .get(
  //       environment.tfCoreUrl +
  //         '/GSI/matching?isPublished=' +
  //         false +
  //         'pageNo=' +
  //         pagenum +
  //         '&pageSize=' +
  //         pageSize +
  //         '&searchCriteria=' +
  //         searchCriteria
  //     )
  //     .pipe(pluck('result'));
  // }

  // getGsiCount(pagenum: number, pageSize: number, searchCriteria: string) {
  //   return this.http
  //     .get(
  //       environment.tfCoreUrl +
  //       '/GSI/matching?pageNo=' +
  //       pagenum +
  //       '&pageSize=' +
  //       pageSize +
  //       '&searchCriteria=' +
  //       searchCriteria
  //     )
  //     .pipe(pluck('result', 'total'));
  // }

  getAllRoles(searchCriteria: string) {
    searchCriteria = searchCriteria ? searchCriteria : '';
    return this.http
      .get(`${this.api?.getAllRoles}`)
      .pipe(
        map((agents: any) =>
          agents.filter((agent: any) => agent.name.toLowerCase().indexOf(searchCriteria.toLowerCase()) !== -1)
        )
      );
  }

  // Get all users list
  getAllUsers(id: any) {
    return this.http.get<any>(`${this.api?.loadAssignedGroupUsers}type=graph&role=${id}`);
  }

  // Save single milestone
  saveMilestone(payload: any) {
    return this.http.post(this.api?.saveMilestone, payload);
  }

  // Update milestone
  editMilestone(payload: any) {
    return this.http.put(this.api?.updateMilestone, payload);
  }

  // Fetch Milestone
  getMilestone(id: any) {
    return this.http.get(this.api?.getMilestone + id);
  }

  // Delete Milestone
  deleteMilestone(id: any) {
    return this.http.delete(this.api?.deleteMilestone + id);
  }

  // List of all milestone accroding to GsiID and TenantID
  getAllMilestone(gsiId: any) {
    return this.http.get(this.api?.getAllMilestone + `gsiId=${gsiId}`);
  }

  getBookDetails(bookId: any) {
    this.bookid = bookId;
    localStorage.setItem('bookId', bookId);
    const headers = {
      headers: new HttpHeaders({
        bookId: bookId,
      }),
    };
    return this.http.get(`${this.api?.saveBook}/${bookId}`, headers);
  }

  getAllOrgUnits(searchCriteria: string) {
    searchCriteria = searchCriteria ? searchCriteria : '';
    return this.http
      .get(`${this.api?.getAllOrgUnits}`)
      .pipe(
        map((agents: any) =>
          agents.filter((agent: any) => agent.name.toLowerCase().indexOf(searchCriteria.toLowerCase()) !== -1)
        )
      );
  }

  getAllUsersInOrgUnit(id: any) {
    return this.http.get<any>(`${this.api?.getUsersInOrgUnit}orgUnitId=${id}`);
  }

  getAllBooks(pagenum: number, pageSize: number, searchCriteria: string) {
    searchCriteria = searchCriteria ? searchCriteria : '';
    return this.http
      .get(
        `${this.api?.getAllTenantBooks}?limit=${pageSize}&ontology=&page=${pagenum}&publisherIdOrName=&query=${searchCriteria}&designMode=true&userRight=SOLUTIONS_INFO_RIGHTS`
        // `${this.api?.getAllTenantBooks}?pageNo=${pagenum}&pageSize=${pageSize}&searchCriteria=${searchCriteria}&designMode=true&userRight=SOLUTIONS_INFO_RIGHTS`
      )
      .pipe(pluck('result'));
    // pageNo=0&pageSize=30&searchCriteria=&designMode=true&userRights=SOLUTIONS_INFO_RIGHTS
    // return this.http
    //   .get(
    //     environment.tfCoreUrl +
    //       '/CU/books?pageNo=' +
    //       pagenum +
    //       '&pageSize=' +
    //       pageSize +
    //       '&searchCriteria=' +
    //       searchCriteria
    //   )
    //   .pipe(
    //     pluck('result')
    //     // map((response: any) => {
    //     //   if (response && response.result && response.result.content && response.result.content.length > 0) {
    //     //     return response.result.content.filter(
    //     //       (agent: any) => agent.name.toLowerCase().indexOf(searchCriteria.toLowerCase()) !== -1
    //     //     );
    //     //   }
    //     // })
    //   );
  }

  // getBooksCount(pagenum: number, pageSize: number, search: any) {
  //   return this.http
  //     .get(
  //       environment.tfCoreUrl + '/CU/books?pageNo=' + pagenum + '&pageSize=' + pageSize + '&searchCriteria=' + search
  //     )
  //     .pipe(pluck('result', 'total'));
  // }

  dashBoardgetAllBooks(pagenum: number, pageSize: number, searchCriteria: string) {
    searchCriteria = searchCriteria ? searchCriteria : '';
    return this.http
      .get(
        this.api?.getBooksWithDesignData +
          'pageNo=' +
          pagenum +
          '&pageSize=' +
          pageSize +
          '&searchCriteria=' +
          searchCriteria
      )
      .pipe(pluck('result'));
  }

  saveGsi(body: any) {
    return this.http.post(`${this.api?.saveGsi}`, body);
  }
  //save conditional potentiality gsi
  saveCPInGsi(body: any) {
    return this.http.post(`${this.api?.saveGsi}?condetionalPotentiality=true`, body);
  }

  saveBook(book: any, saveCpInBook?: any) {
    if (saveCpInBook) {
      return this.http.post(`${this.api?.saveBook}?condetionalPotentiality=true`, book);
    } else {
      return this.http.post(`${this.api?.saveBook}`, book);
    }
  }
  //gets data of impacted bet
  loadSingleLevelDetailsById(id: any, type: string) {
    return this.http.get<any>(this.api?.fetchsingleLevelDependency + `${id}/${type}`);
  }

  saveGsiInBook(bookId: number, gsi: any) {
    return this.http.post(`${this.api?.saveGsiInBook}${bookId}/gsi`, {
      id: gsi.tfId,
      name: gsi.name,
      dsdId: gsi.dsdId,
    });
  }

  getBookGsis(bookId: number, pageSize: number, pageNumber: number, search?: string, isFromLibraryBook?: boolean) {
    const options = {
      headers: new HttpHeaders({ bookId: `${bookId}` }),
    };
    const url = isFromLibraryBook
      ? `${this.api?.saveBook}/${bookId}/gsi?pageNo=${pageNumber}&pageSize=${pageSize}&searchCriteria=${search}&designMode=false`
      : `${this.api?.saveBook}/${bookId}/gsi?pageNo=${pageNumber}&pageSize=${pageSize}&searchCriteria=${search}`;
    return this.http.get(url, options).pipe(pluck('result'));
  }

  getBookDesign(bookId: number) {
    return this.http.get(`${this.api?.getBookDesign}/${bookId}`).pipe(pluck('result'));
  }

  updateGSI(id: any, index: any, data: any) {
    return this.http.put(`${this.api?.addSeqCu}/` + id + '/solution/sequential?index=' + index, data);
  }
  deleteCuFromGsi(gsiId: any, cuId: any) {
    return this.http.delete(`${this.api?.addSeqCu}/` + gsiId + '/' + cuId + '/solution/sequential');
  }

  // Service this.api Calls for Versioning Draft this.api Calls //

  saveDraftVersion(body: any, type: any) {
    return this.http.post(`${this.api?.gsiVersion}` + type, body);
  }

  saveDraftEditVersion(body: any, type: any) {
    const options = { headers: this.getApplicateJsonHeaders() };
    return this.http.post(`${this.api?.gsiVersion}` + type + '/' + body, options);
  }

  saveApproveReject(body: any, type: any) {
    const options = { headers: this.getApplicateJsonHeaders() };
    return this.http.post(`${this.api?.gsiVersion}` + type + '/' + body, options);
  }

  saveDraftGSI(body: any) {
    return this.http.post(`${this.api?.gsiDraft}`, body);
  }

  updateGsiRights(gsi: any, body: any) {
    return this.http.put(`${this.api?.updateGsiRights}` + gsi.id + '/irdr?isDesignRights=' + gsi.flag, body);
  }

  getApplicateJsonHeaders() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }
  exportGsis(gsiId: number) {
    return this.http.get(`${this.api?.exportGsi}/${gsiId}/File`);
  }

  exportGsiApp(data: any) {
    return this.http.post(`${this.api?.exportApplication}`, data);
  }

  exportApplicationToEnv(data: any, envType: string) {
    return this.http.post(`${this.api?.exportApplicationToEnv}${envType}`, data);
  }

  exportHistory(searchText: any, pageSize: number, pageNumber: number) {
    let flag = false;
    return this.http.get(
      `${this.api?.importExportHistory}?imported=${flag}&pageNo=${pageNumber}&pageSize=${pageSize}&searchCriteria=${searchText}`
    );
  }
  importHistory(searchText: any, pageSize: number, pageNumber: number) {
    let flag = true;
    return this.http.get(
      `${this.api?.importExportHistory}?imported=${flag}&pageNo=${pageNumber}&pageSize=${pageSize}&searchCriteria=${searchText}`
    );
  }

  importGSIs(req: any) {
    return this.http.post(`${this.api?.importGsi}`, req);
  }
  validateGsiFile(req: any) {
    return this.http.post(`${this.api?.validateGsiFile}`, req);
  }
  saveDraftCu(cu: any) {
    return this.http.post(`${this.api?.saveDraftCu}`, cu);
  }
  saveCompositeGsi(gsiData: any) {
    console.log(gsiData);
    return this.http.post(`${this.api?.saveCompositeGsi}`, gsiData);
  }

  convertGsiToJsonToXml(payload: any) {
    return this.http.post(`${this.api?.convertGsiToJsonToXml}`, payload, {
      responseType: 'text',
    });
  }
  viewExportSolution(Id: any) {
    return this.http.get(`${this.api?.importExportHistory}/${Id}`);
  }

  getGSIDetailsById(id: any) {
    return this.http.get(`${this.api?.saveGsi}/${id}`);
  }
  editGsiDtrt(data: any) {
    return this.http.post(`${this.api?.editGsiDtrtApi}`, data);
  }
  getIdDtrt(id: any) {
    return this.http.post(`${this.api?.getIdDtrtApi}`, id);
  }
  getReviewOfDtrt() {
    return this.http.get(`${this.api?.getReviewDtrtApi}`);
  }
  getRevertChangesDtrt() {
    return this.http.post(`${this.api?.revertChangesDtrtApi}`, {});
  }
  getApplyChangesDtrt() {
    return this.http.post(`${this.api?.applyChangesDtrtApi}`, {});
  }

  getReviewMileStoneDtrt() {
    return this.http.get(`${this.api?.getReviewMileStoneDtrtApi}`);
  }
  getRevertMileStoneDtrt() {
    return this.http.post(`${this.api?.revertMileStoneDtrtApi}`, {});
  }
  getApplyMileStoneDtrt() {
    return this.http.post(`${this.api?.applyMileStoneDtrtApi}`, {});
  }

  fetchDtrtIRDR(id: any) {
    return this.http.get(`${this.api?.fetchDtrtIRDR}?gsiId=${id}`);
  }

  getReviewDtrtIRDR() {
    return this.http.get(`${this.api?.getReviewDtrtIRDR}`);
  }
  revertChangesDtrtIRDR() {
    return this.http.post(`${this.api?.revertChangesDtrtIRDR}`, {});
  }
  applyChangesDtrtIRDR() {
    return this.http.post(`${this.api?.applyChangesDtrtIRDR}`, {});
  }

  getReviewDtrtReservedAction() {
    return this.http.get(`${this.api?.getReviewDtrtReservedAction}`);
  }
  getRevertChangesDtrtReservedAction() {
    return this.http.post(`${this.api?.revertChangesDtrtReservedAction}`, {});
  }

  getCuDetailsById(id: any) {
    return this.http.get(`${this.api?.getBasicCu}/${id}`);
  }

  getpublishGsiById(id: any) {
    return this.http.get(`${this.api?.publishGsiById}/${id}`);
  }
  publishEditGsi(pageNumber: number, pageSize: number, searchString: string) {
    return this.http.get(
      `${this.api?.publishedgsi}?pageNo=${pageNumber}&pageSize=${pageSize}&searchCriteria=${searchString}`
    );
  }
  getExceptionCategory() {
    return this.http.get(`${this.api?.getExceptionalCategory}`);
  }

  // milestone transaction this.apis
  getMilestoneList(id: any) {
    return this.http.get(this.api?.getMilestoneList + id);
  }
  getpercentMilestoneList(id: any) {
    return this.http.get(this.api?.getpercentMilestoneList + id);
  }
  getParticularMilestone(gsiId: number, milestoneid: number) {
    return this.http.get(this.api?.getParticularMilestone + `${gsiId}&milestoneId=${milestoneid}`);
  }
  notifyMilestone(element: any) {
    return this.http.post(this.api?.notifyMilestone, element);
  }

  /* -------------------------- Pushback - FE757 - START -------------------------- */

  // Get all pushbacks for GSI
  getPushbacks(data: any) {
    return this.http.get(this.api?.corePushbacks + `/gsi/${data.masterId}/${data.tfId}`);
  }

  // Get all target CUs for any particular CU
  getTargetCUsForPushbacks(data: any) {
    return this.http.get(
      this.api?.corePushbacks +
        `/cus?rootGSIId=${data.gsiId}&currentTriggerCUId=${data.CUId}&currentTriggerCUContextualId=${data.CUContextualId}`
    );
  }

  // Save/Update pushback
  postPushback(data: any) {
    if (data.id) {
      return this.http.put(this.api?.corePushbacks + '/', data);
    } else {
      return this.http.post(this.api?.corePushbacks + '/', data);
    }
  }

  // Delete Pushback
  deletePushback(data: any) {
    return this.http.delete(this.api?.corePushbacks + `/${data.id}/${data.gsiMasterId}/${data.gsiId}`);
  }

  getCUsInTransForPushback(data: any) {
    return this.http.get(
      this.api?.transPushbacks +
        `/cu?txnId=${data.txnId}&rootGSIId=${data.gsiId}&currentTriggerCUId=${data.tfId}&currentTriggerCUContextualId=${data.contextualId}`
    );
  }

  /* -------------------------- Pushback - FE757 - END-------------------------- */

  //Save subtransactional GSi
  saveSubtransactionalGSI(data: any) {
    return this.http.post(this.api?.saveSubtransactionalGsi, data);
  }

  //Dymamic schedule job
  getAllscheduledjobs(gsiId: any, gsiMaterId: any, gsiVersion: any) {
    return this.http.get(this.api?.scheduleJob + `/${gsiId}?masterId=${gsiMaterId}&version=${gsiVersion}`);
  }

  saveSchedulerJob(data: any) {
    return this.http.post(this.api?.saveScheduleJob, data);
  }

  // Delete schedule job
  deleteSchedulerJob(gsiId: any, jobId: any) {
    return this.http.delete(this.api?.saveScheduleJob + `?gsiId=${gsiId}&jobId=${jobId}`);
  }
  rearrangeEntity(cuId: any, layerType: any, currentSlotIndex: any, newSlotIndex: any, gsiId: any, Data: any) {
    return this.http.put(
      this.api?.reArrangeEntity +
        `/${cuId}/${layerType}?currentSlotIndex=${currentSlotIndex}&newSlotIndex=${newSlotIndex}&gsiId=${gsiId}`,
      Data
    );
  }
  getVersionList(id: any) {
    return this.http.get(this.api?.versionList + id);
  }
}

<div class="sesion-out-wrap">
  <figure class="session-out-img">
    <img [src]="'assets/images/session.png' | CDNUrlRewritePipe" alt="session-out" />
  </figure>
  <h1 mat-dialog-title class="title-cstm">
    <span> {{ title }}</span>
    <span *ngIf="typeOfDialog === 'SessionTimeOut'">{{ leftTimetoAutoLogout }}</span>
  </h1>

  <div mat-dialog-content>
    <p>{{ message }}</p>
  </div>

  <div mat-dialog-actions class="button-align">
    <button mat-button (click)="onDismiss()">{{ buttonName1 }}</button>
    <button class="stayin" mat-button (click)="onConfirm()">{{ buttonName2 }}</button>
  </div>
</div>

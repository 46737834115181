import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Shell } from './shell.service';

const routes: Routes = Shell.addGaurds([
  {
    path: 'admin',
    loadChildren: async () => (await import('../admin/admin.module')).AdminModule,
  },
  {
    path: 'newsolution',
    loadChildren: () => import('../create/new-solution/new-solution.module').then((m) => m.NewSolutionModule),
  },
  {
    path: 'entityBoard',
    loadChildren: () => import('../create/general-entity/entity-board.module').then((m) => m.EntityBoardModule),
  },
  {
    path: 'collaborate',
    loadChildren: () =>
      import('../review-collaborate/review-collaborate.module').then((m) => m.ReviewCollaborateModule),
  },
  {
    path: 'canvas',
    loadChildren: () => import('../node-system-canvas/node-system-canvas.module').then((m) => m.NodeSystemCanvasModule),
  },
  {
    path: 'mylibrary',
    loadChildren: () => import('../create/create-solution.module').then((m) => m.CreateSolutionModule),
    data: { title: 'nsl-cdui' },
  },
  {
    path: 'mylibrary-dashboard',
    loadChildren: () => import('../create/mylibrary.module').then((m) => m.MylibraryModule),
    data: { title: 'nsl-cdui' },
  },
  {
    path: 'tenant',
    loadChildren: () => import('../tenant-cus/tenant-cus.module').then((m) => m.TenantCusModule),
  },
  {
    path: 'tenant',
    loadChildren: () => import('../tenant-cus/tenant-cus.module').then((m) => m.TenantCusModule),
  },
  {
    path: 'assetsolution',
    loadChildren: () => import('../create/assets-solution/assets-solution.module').then((m) => m.AssetsSolutionModule),
  },
  {
    path: 'chatbotsolution',
    loadChildren: () =>
      import('../create/chatbot-solution/chatbot-solution.module').then((m) => m.ChatbotSolutionModule),
  },
  {
    path: 'layoutsolution',
    loadChildren: () => import('../create/layout-solution/layout-solution.module').then((m) => m.LayoutSolutionModule),
  },
  {
    path: 'newlayoutsolution',
    loadChildren: () =>
      import('../create/new-layout-solution/new-layout-solution.module').then((m) => m.NewLayoutSolutionModule),
  },
  {
    path: 'solution-dictionary',
    loadChildren: () =>
      import('../solution-dictionary/solution-dictionary.module').then((m) => m.SolutionDictionaryModule),
  },
  {
    path: 'transaction',
    loadChildren: () => import('../transaction/transaction.module').then((m) => m.TransactionModule),
  },
  {
    path: 'preferences',
    loadChildren: () => import('../settings/settings.module').then((m) => m.SettingsModule),
  },
  {
    path: 'nsl-hub',
    loadChildren: () => import('../landing-pages/landing-pages.module').then((m) => m.LandingPagesModule),
  },
  {
    path: 'carnival',
    loadChildren: () => import('../carnival/carnival.module').then((m) => m.CarnivalModule),
  },
  {
    path: 'carnival-login',
    loadChildren: () => import('../carnival/login-carnival/login-carnival.module').then((m) => m.LoginCarnivalModule),
  },
  {
    path: 'carnival-signup',
    loadChildren: () =>
      import('../carnival/signup-carnival/signup-carnival.module').then((m) => m.SignupCarnivalModule),
  },
  {
    path: 'onboarding',
    loadChildren: () => import('../onboarding/onboarding.module').then((m) => m.OnboardingModule),
  },
  {
    path: 'custom-mylibrary',
    loadChildren: () => import('../create/mylibrary/custom-mylibrary.module').then((m) => m.CustomMylibraryModule),
  },
  {
    path: 'manage-pushbacks',
    loadChildren: () =>
      import('../create/shared/components/manage-pushbacks/manage-pushbacks.module').then(
        (m) => m.ManagePushbacksModule
      ),
  },
]);

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class ShellRoutingModule {}

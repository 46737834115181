import { Injectable } from '@angular/core';
import * as RecordRTC from 'recordrtc';
import { Observable, Subject } from 'rxjs';
import * as moment from 'moment';
import { genericvoiceToTextInit, startVoiceToText } from '@app/@core/generic-functions';

declare var webkitSpeechRecognition: any;

interface RecordedAudioOutput {
  blob: Blob;
  title: string;
}

@Injectable({
  providedIn: 'root',
})
export class RecordingFacadeService {
  private mediaConstraints = {
    audio: true,
    video: false,
  };
  private videoMediaConstraints = { video: { facingMode: 'user', width: 320 }, audio: true };

  stream: any;
  recorder: RecordRTC;
  private interval: any;
  private startTime: any;

  private _recorded = new Subject<any>();
  private _recordingTime = new Subject<any>();
  recordingTime$ = this._recordingTime.asObservable();

  private streamData = new Subject<any>();
  streamData$ = this.streamData.asObservable();

  voiceToText = new webkitSpeechRecognition();
  voiceToTextFlag: boolean = false;
  text: string = '';
  tempWords: any;

  constructor() {}

  getRecordedBlob(): Observable<RecordedAudioOutput> {
    return this._recorded.asObservable();
  }

  startRecording() {
    this._recordingTime.next('00:00');
    /* istanbul ignore next */
    navigator.mediaDevices.getUserMedia(this.mediaConstraints).then((s) => {
      this.stream = s;
      /* istanbul ignore next */
      this.record();
    });
  }

  startVideoRecording() {
    this._recordingTime.next('00:00');
    /* istanbul ignore next */
    navigator.mediaDevices.getUserMedia(this.videoMediaConstraints).then((s) => {
      this.stream = s;
      this.streamData.next(this.stream);
      /* istanbul ignore next */
      this.recordVideo();
    });
  }

  /* istanbul ignore next */
  private recordVideo() {
    /* istanbul ignore next */
    this.recorder = new RecordRTC(this.stream, {
      recorderType: RecordRTC.MediaStreamRecorder,
      type: 'video',
      mimeType: 'video/webm',
      bitsPerSecond: 44000,
    });
    this.recorder.startRecording();
    this.startTime = moment();
    this.interval = setInterval(() => {
      const currentTime = moment();
      const diffTime = moment.duration(currentTime.diff(this.startTime));
      const time = this.toString(diffTime.minutes()) + ':' + this.toString(diffTime.seconds());
      this._recordingTime.next(time);
    }, 500);
  }

  stopVideoRecording() {
    /* istanbul ignore next */
    if (this.recorder) {
      this.recorder.stopRecording(() => {
        let blob = this.recorder.getBlob();
        if (blob) {
          this._recorded.next(blob);
          this.stopMedia();
        }
      });
    }
  }

  getStopMedia() {
    this.stopMedia();
  }
  private stopMedia() {
    /* istanbul ignore next */
    if (this.recorder) {
      this.recorder = null;
      clearInterval(this.interval);
      this.startTime = null;
      if (this.stream) {
        this.stream.getAudioTracks().forEach((track: any) => track.stop());
        this.stream.getVideoTracks().forEach((track: any) => track.stop());
        this.stream.stop();
        this.stream = null;
      }
    }
  }

  /* istanbul ignore next */
  private record() {
    this.recorder = new RecordRTC(this.stream, {
      recorderType: RecordRTC.StereoAudioRecorder,
      type: 'audio',
      mimeType: 'audio/wav',
    });
    this.recorder.startRecording();
    this.startTime = moment();
    this.interval = setInterval(() => {
      const currentTime = moment();
      const diffTime = moment.duration(currentTime.diff(this.startTime));
      const time = this.toString(diffTime.minutes()) + ':' + this.toString(diffTime.seconds());
      this._recordingTime.next(time);
    }, 500);
  }

  getToString(value: any) {
    this.toString(value);
  }

  private toString(value: any) {
    let val = value;
    if (!value) {
      val = '00';
    }
    if (value < 10) {
      val = '0' + value;
    }
    return val;
  }

  stopRecording() {
    /* istanbul ignore next */
    if (this.recorder) {
      this.recorder.stopRecording(() => {
        let blob = this.recorder.getBlob();
        if (blob) {
          this._recorded.next(blob);
        }
      });
    }
  }

  voiceToTextInit() {
    this.voiceToText.interimResults = true;
    this.voiceToText.lang = 'en-IN';
    /* istanbul ignore next */
    this.voiceToText.addEventListener('result', (e: any) => {
      console.log(e);
      const transcript = Array.from(e.results)
        .map((result) => result[0])
        .map((result) => result.transcript)
        .join(' ');
      this.tempWords = transcript;
      console.log(this.tempWords);
    });
    // this.tempWords = genericvoiceToTextInit(this.voiceToText);
  }

  startVoiceToText() {
    // this.voiceToTextFlag = false;
    // this.voiceToText.start();
    // console.log('Started voice to text');
    // this.voiceToText.addEventListener('end', (condition: any) => {
    //   if(this.voiceToTextFlag) {
    //     this.voiceToText.stop();
    //     console.log('Stopped voice to text');
    //   } else {
    //     this.voiceToText.start();
    //   }
    // })
    startVoiceToText(this.voiceToText, false);
    // return;
  }

  stopVoiceToText() {
    this.voiceToTextFlag = false;
    this.voiceToText.stop();
    console.log('Stopped voice to text');
  }
}
